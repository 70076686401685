import { Form } from 'react-bootstrap';
import { useIntl } from 'react-intl';

const ThumbSize = ({ thumb_size, handleOnChange }) => {
  const intl = useIntl();
  return (
    <Form.Control as="select" custom name="thumb_size" onChange={(e) => handleOnChange(e, 'designForm')} value={thumb_size}>
      <option value="small">{intl.formatMessage({ id: 'global-small' })}</option>
      <option value="big">{intl.formatMessage({ id: 'global-big' })}</option>
      {/* <option value="auto">Auto</option> */}
    </Form.Control>
  );
};

export default ThumbSize;

import IconMobile from 'components/icons/movil';
import IconDesktop from 'components/icons/computer';
import IconTablet from 'components/icons/tablet';

import translate from 'i18n/translate';

//style
import './index.scss';

const FootInfo = (props) => {
  return (
    <div className="info-content">
      <div className="info-one">
        <span className="margin">
          <IconMobile /> {translate('statistics-form-select-mobile')}
        </span>
        <span className="margin">
          <IconDesktop /> {translate('statistics-form-select-compu')}
        </span>
        <span className="margin">
          <IconTablet /> {translate('statistics-form-select-tablet')}
        </span>
      </div>
    </div>
  );
};

export default FootInfo;

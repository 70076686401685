import { Col, Row, Form } from 'react-bootstrap';

export const Article = ({ label, value, onChange }) => {
  return (
    <FormGroup label={label}>
      <input className="form-control" type="text" name="articulo" value={value} onChange={onChange} maxLength="25" required />
    </FormGroup>
  );
};

export const TagLog = ({ label, value, onChange }) => {
  return (
    <FormGroup label={label}>
      <input className="form-control" type="text" name="tag_en_log" value={value} onChange={onChange} maxLength="10" required />
    </FormGroup>
  );
};

export const Unity = ({ label, value, onChange }) => {
  return (
    <FormGroup label={label}>
      <select className="form-control" name="unidad" value={value} onChange={onChange}>
        <option value="u">Unitario</option>
        <option value="%">Porcentaje</option>
        <option value="s">Suscripción</option>
      </select>
    </FormGroup>
  );
};

export const Description = ({ label, value, onChange }) => {
  return (
    <FormGroup label={label}>
      <input className="form-control" type="text" name="denominacion" value={value} onChange={onChange} maxLength="100" required />
    </FormGroup>
  );
};

export const PprPrice = ({ label, value, onChange }) => {
  return (
    <FormGroup label={label}>
      <input className="form-control" type="number" name="precio_default" value={value} onChange={onChange} min="0" step=".01" required />
    </FormGroup>
  );
};

export const PpaPrice = ({ label, value, onChange }) => {
  return (
    <FormGroup label={label}>
      <input className="form-control" type="number" name="precio_ppa" value={value} onChange={onChange} min="0" step=".01" required />
    </FormGroup>
  );
};

export const PplPrice = ({ label, value, onChange }) => {
  return (
    <FormGroup label={label}>
      <input className="form-control" type="number" name="precio_ppl" value={value} onChange={onChange} min="0" step=".01" required />
    </FormGroup>
  );
};

export const PvpPrice = ({ label, value, onChange }) => {
  return (
    <FormGroup label={label}>
      <input className="form-control" type="number" name="pvp" value={value} onChange={onChange} min="0" step=".01" required />
    </FormGroup>
  );
};

export const ComisionModel = ({ label, value, onChange }) => {
  return (
    <FormGroup label={label}>
      <select className="form-control" name="modelo" onChange={onChange} value={value}>
        <option value="PPR">PPR</option>
        <option value="PPA">PPA</option>
        <option value="PPL">PPL</option>
      </select>
    </FormGroup>
  );
};

const FormGroup = ({ label, children }) => {
  return (
    <Form.Group as={Row} className="mb-3">
      <label className="font-weight-bold form-label col-form-label col-sm-6">{label}</label>
      <Col sm="6">{children}</Col>
    </Form.Group>
  );
};

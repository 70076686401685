import { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { FormattedMessage, useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faSave } from '@fortawesome/free-solid-svg-icons';
import { Card, Container, Col, Form, Row, Modal } from 'react-bootstrap';

import { WHITELABELS } from 'shared/constants';

import Loading from 'components/loading';
//import translate from 'i18n/translate';
import Button from 'components/button';

import Pagination from 'components/pagination';

import WhiteLabelsService from 'shared/white-labels-service.js';

import { getLang } from 'libs/cookies';

import useValidator from 'hooks/useValidator';

import Table from './table.js';

import './index.scss';

const WhiteLabels = () => {
  const history = useHistory();
  const [validator, showValidationMessage] = useValidator({}, {}, getLang());
  const [selectedStatus, setSelectedStatus] = useState(null);
  let loading = useSelector((state) => state.loading.loading);
  const dispatch = useDispatch();
  const intl = useIntl();

  const statusOptions = [
    { value: WHITELABELS.STATUS.VALIDATED, title: 'Validada' },
    { value: WHITELABELS.STATUS.PENDING, title: 'Pendiente' },
    { value: WHITELABELS.STATUS.REJECTED, title: 'Rechazada' },
    { value: WHITELABELS.STATUS.PRODUCTION, title: 'En Producción' },
    { value: WHITELABELS.STATUS.REDIRECTED, title: 'Redireccionada' },
  ];

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [lastPage, setLastPage] = useState(1);

  const [data, setData] = useState([]);
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState('');
  const [mode, setMode] = useState(WHITELABELS.MODES.REGISTERED);
  const [filters, setFilters] = useState({
    status: '',
    search: '',
    mode: WHITELABELS.MODES.REGISTERED,
  });
  const [showModal, setShowModal] = useState(false);
  const [rejectReason, setRejectReason] = useState('');
  const [idMbRejected, setIdMbRejected] = useState(null);

  const getList = useCallback(
    (filters) => {
      setPage(1);
      dispatch({ type: 'SET_LOADING', loading: true });
      WhiteLabelsService.adminList({ page, perPage, filters })
        .then((responseData) => {
          if (responseData.status === 'Accepted') {
            setData(responseData.message);
            setLastPage(responseData.message.last_page);
          }
        })
        .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
    },
    [dispatch, setData]
  );

  useEffect(() => {
    setData([]);
    dispatch({ type: 'SET_LOADING', loading: true });
    WhiteLabelsService.adminList({ page, perPage, filters })
      .then((responseData) => {
        responseData.status === 'Failed' && toast.error('No se pudo cargar la información');
        if (responseData.status === 'Accepted') {
          setData(responseData.message);
          setLastPage(responseData.message.last_page);
        }
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
  }, [page, perPage, dispatch]);

  //useEffect(getList(filters), [getList]);
  useEffect(() => {
    getList(filters);
  }, [getList, filters]);

  const statusSelect = (id, status) => {
    switch (status) {
      case WHITELABELS.STATUS.REJECTED:
        setSelectedStatus(WHITELABELS.STATUS.REJECTED);
        rejectBtn(id);
        break;

      case WHITELABELS.STATUS.REDIRECTED:
        setSelectedStatus(WHITELABELS.STATUS.REDIRECTED);
        rejectBtn(id, WHITELABELS.STATUS.REDIRECTED);
        break;

      case WHITELABELS.STATUS.VALIDATED:
        validateBtn(id);
        break;

      case WHITELABELS.STATUS.PENDING:
        history.push('/admintools/marcas-blancas/' + id);
        break;

      case WHITELABELS.STATUS.PRODUCTION:
        productionBtn(id);
        break;
      default:
        break;
    }
  };

  const productionBtn = (id) => {
    dispatch({ type: 'SET_LOADING', loading: true });
    WhiteLabelsService.adminProduction(id)
      .then((responseData) => {
        if (responseData.status === 'Accepted') {
          toast.success(<FormattedMessage id="global-update-success" />);
          getList(filters);
        } else toast.error(<FormattedMessage id="herramientas-whitelabel_validation-error" />);
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
  };

  const validateBtn = (id) => {
    dispatch({ type: 'SET_LOADING', loading: true });
    WhiteLabelsService.adminValidate(id)
      .then((responseData) => {
        if (responseData.status === 'Accepted') {
          toast.success(<FormattedMessage id="global-update-success" />);
          getList(filters);
        } else toast.error(<FormattedMessage id="herramientas-whitelabel_validation-error" />);
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
  };

  const rejectBtn = (id, mode) => {
    setIdMbRejected(id);
    setShowModal(true);
  };

  const modalHandleClose = () => {
    setIdMbRejected(null);
    setRejectReason('');
    setShowModal(false);
  };

  const modalRejectBtn = () => {
    if (validator.allValid()) {
      // REJECT
      if (selectedStatus === WHITELABELS.STATUS.REJECTED) {
        if (window.confirm(intl.formatMessage({ id: 'promo-sure-reject-mb' }))) {
          dispatch({ type: 'SET_LOADING', loading: true });
          WhiteLabelsService.adminReject(idMbRejected, rejectReason)
            .then((responseData) => {
              if (responseData.status === 'Accepted') toast.success(<FormattedMessage id="administration-success-data" />);
              else toast.error(<FormattedMessage id="promo-error-deleting-roomlist" />);
              getList(filters);
            })
            .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
        }
      }

      // REDIRECT
      if (selectedStatus === WHITELABELS.STATUS.REDIRECTED) {
        if (window.confirm(intl.formatMessage({ id: 'promo-sure-redirect-mb' }))) {
          dispatch({ type: 'SET_LOADING', loading: true });
          WhiteLabelsService.adminRedirect(idMbRejected, rejectReason)
            .then((responseData) => {
              if (responseData.status === 'Accepted') toast.success(<FormattedMessage id="administration-success-data" />);
              else toast.error(<FormattedMessage id="promo-error-deleting-roomlist" />);
              getList(filters);
            })
            .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
        }
      }

      modalHandleClose();
    } else showValidationMessage(true);
  };
  //const modalHandleShow = () => setShowModal(true);

  const deleteBtn = (id) => {
    if (window.confirm(intl.formatMessage({ id: 'promo-sure-delete-white_brand' }))) {
      dispatch({ type: 'SET_LOADING', loading: true });
      WhiteLabelsService.adminDelete(id)
        .then((responseData) => {
          if (responseData.status === 'Accepted') toast.success(<FormattedMessage id="administration-success-data" />);
          else toast.error(<FormattedMessage id="promo-error-deleting-roomlist" />);
          getList(filters);
        })
        .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
    }
  };

  const deleteCacheBtn = (id) => {
    if (!window.confirm(intl.formatMessage({ id: 'promo-sure-delete-cache-white_brand' }))) return;

    dispatch({ type: 'SET_LOADING', loading: true });
    WhiteLabelsService.adminClearCache(id)
      .then((responseData) => {
        if (responseData.status === 'Accepted') toast.success(<FormattedMessage id="administration-success-data" />);
        else toast.error(<FormattedMessage id="promo-error-deleting-roomlist" />);
        getList(filters);
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
  };

  /* PAGINATOR */
  const paginate = (e) => setPage(e.target.value);

  const handleChangeNumberOfColum = (e) => {
    setPerPage(e.target.value);
    setPage(1);
  };

  const nextPage = (_page) => {
    _page++;
    _page <= lastPage && setPage(_page);
  };

  const prevPage = (_page) => {
    _page--;
    _page > 0 && setPage(_page);
  };
  // End Paginator

  if (loading) return <Loading />;

  return (
    <Container className="mw-100 ha-whitelabels">
      <Row>
        <Col sm="12">
          <Card className="mb-2">
            <Card.Header>Marcas Blancas</Card.Header>
            <Card.Body>
              <FilterForm
                filters={filters}
                setFilters={setFilters}
                search={search}
                setSearch={setSearch}
                status={status}
                setStatus={setStatus}
                mode={mode}
                setMode={setMode}
                statusOptions={statusOptions}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col sm="12">
          {data.data && data.total > 0 && (
            <>
              <Table
                data={data}
                rejectBtn={rejectBtn}
                validateBtn={validateBtn}
                deleteBtn={deleteBtn}
                deleteCacheBtn={deleteCacheBtn}
                statusOptions={statusOptions}
                statusSelect={statusSelect}
              />
              <Modal show={showModal} onHide={modalHandleClose} animation={false}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    {selectedStatus === WHITELABELS.STATUS.REJECTED && <FormattedMessage id="promo-reject-reason" />}
                    {selectedStatus === WHITELABELS.STATUS.REDIRECTED && <FormattedMessage id="promo-redirect-reason" />}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <Form.Control as="textarea" value={rejectReason} onChange={(e) => setRejectReason(e.target.value)} rows={4} />
                    {validator.message(intl.formatMessage({ id: 'promo-reject-redirect-reason' }), rejectReason, 'max: 300|required')}
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button rounded red variant="secondary" onClick={modalHandleClose}>
                    <FormattedMessage id="herramientas-banner-register-cancel" />
                  </Button>
                  <Button rounded variant="primary" onClick={modalRejectBtn}>
                    <FormattedMessage id="administration-data-button" />
                    <FontAwesomeIcon icon={faSave} className="ml-1" />
                  </Button>
                </Modal.Footer>
              </Modal>
              <Pagination
                handleChangeNumberOfColum={handleChangeNumberOfColum}
                pager={{ page: page, items: perPage, pages: lastPage }}
                paginate={paginate}
                prevPage={prevPage}
                nextPage={nextPage}
                total={data.total}
              />
            </>
          )}
          {data.total === 0 && <div className="text-center mt-2 h3">Sin resultados</div>}
        </Col>
      </Row>
      <hr />
      <Row>
        <Col sm="12">
          <Footer />
        </Col>
      </Row>
    </Container>
  );
};

const FilterForm = ({ filters, setFilters, search, setSearch, status, setStatus, mode, setMode, statusOptions }) => {
  const intl = useIntl();
  const modeOptions = [
    { value: '', title: 'Todas' },
    { value: WHITELABELS.MODES.REGISTERED, title: 'Registro' },
    { value: WHITELABELS.MODES.ANONYMOUS, title: 'Anónimo' },
  ];

  const searchBtn = (e) => {
    e.preventDefault();
    setFilters({
      ...filters,
      search: search,
      status: status,
      mode: mode,
    });
  };

  return (
    <form className="search-wrapper white_label" onSubmit={(e) => searchBtn(e)}>
      <div className="first-filters d-flex align-center">
        <div className="form-group mr-3">
          <label>
            <FormattedMessage id="global-search" />
          </label>
          <Form.Control value={search} name="search" placeholder="" onChange={(e) => setSearch(e.target.value)} />
        </div>
        <div className="form-group mr-3">
          <label>
            <FormattedMessage id="statistics-table-stat" />
          </label>
          <Form.Control as="select" custom onChange={(e) => setStatus(e.target.value)} name="status" defaultValue={filters.status}>
            <option value="">{intl.formatMessage({ id: 'statistics-form-select-all' })}</option>
            {statusOptions &&
              statusOptions.length > 0 &&
              statusOptions.map((item, index) => {
                return (
                  <option key={index} value={item.value}>
                    {item.title}
                  </option>
                );
              })}
          </Form.Control>
        </div>
        <div className="form-group mr-3">
          <label>
            <FormattedMessage id="global-mode" />
          </label>
          <Form.Control as="select" custom onChange={(e) => setMode(e.target.value)} name="mode" defaultValue={filters.mode}>
            {modeOptions &&
              modeOptions.length > 0 &&
              modeOptions.map((item, index) => {
                return (
                  <option key={index} value={item.value}>
                    {item.title}
                  </option>
                );
              })}
          </Form.Control>
        </div>
      </div>
      <div className="buttons-content">
        <Button rounded onClick={searchBtn}>
          <FontAwesomeIcon icon={faSearch} className="mr-2" /> <FormattedMessage id="global-send" />
        </Button>
      </div>
    </form>
  );
};

const Footer = () => {
  return (
    <Card className="mb-2">
      <Card.Body>
        <Row className="text-center justify-content-center align-items-center">
          <Col>
            <div className="alert-warning p-2">PENDIENTE: Inactiva y pendiente de pasar a validada</div>
          </Col>
          <Col>
            <div className="alert-primary p-2">VALIDADA: Preproducción</div>
          </Col>
          <Col>
            <div className="alert-success p-2">PRODUCCIÓN: En producción</div>
          </Col>
          <Col>
            <div className="alert-danger p-2">RECHAZADA: Inactiva y rechazada por un motivo</div>
          </Col>
          <Col>
            <div className="alert-redirected p-2">REDIRECCIONADA: Inactiva y redireccionada a Olecams con AFNO</div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default WhiteLabels;

import { useIntl } from 'react-intl';

const SelectDevice = ({ value, handleChangeDevice }) => {
  const { formatMessage } = useIntl();

  return (
    <div className="form-group">
      <label>{formatMessage({ id: 'global-devices' })}</label>
      <select className="form-control" name="device" onChange={handleChangeDevice} value={value}>
        <option value="">{formatMessage({ id: 'statistics-form-select-all' })}</option>
        <option value="0">{formatMessage({ id: 'statistics-form-select-compu' })}</option>
        <option value="1">{formatMessage({ id: 'statistics-form-select-tablet' })}</option>
        <option value="2">{formatMessage({ id: 'statistics-form-select-mobile' })}</option>
      </select>
    </div>
  );
};

export default SelectDevice;

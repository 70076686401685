import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router';
import { Card, Container, Col, Row, ListGroup, Accordion } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

import Button from 'components/button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChartBar,
  faUsers,
  faUserCheck,
  faFileInvoiceDollar,
  faImage,
  faGlobe,
  faWindowMaximize,
  faCreditCard,
  faThList,
  faCoins,
  faGift,
  faNewspaper,
  faAddressCard,
  faPhone,
  faCommentDots,
  faPercent,
} from '@fortawesome/free-solid-svg-icons';

import Banners from '../herramientas/banners';
import Landings from '../herramientas/landings';
import Categorias from '../herramientas/categorias';
import WhiteLabels from '../herramientas/whiteLabels';
import WhiteLabelEdit from '../herramientas/whiteLabels/edit';
import VisaEvents from '../herramientas/visaEvents';
import VisaUser from '../herramientas/visaEvents/userDetail';
import Brokers from './Brokers';
import NewBroker from './Brokers/New';
import EditBroker from './Brokers/Edit';
import StatsBrokers from './StatsBrokers';
import SummaryBrokers from './StatsBrokers/summary';
import News from '../herramientas/news';
import Affiliates from './Affiliates';
import EditAffiliate from 'templates/adminTools/Affiliates/Edit';
import AffiliatesRates from './AffiliatesRates';
import AffiliateBilling from '../herramientas/affiliateBilling';
import UsuariosOlecams from '../herramientas/usuariosOlecams';
import ActiveAfiliados from './Affiliates/activate';
import GenerateKey from './Affiliates/generateKey';
import MonthBilling from '../herramientas/FacturacionMes';
import Discounts from '../herramientas/discounts';
import NewDiscounts from '../herramientas/discounts/createUpdate';
import CreditGift from './CreditGift';
import NewCreditGift from './CreditGift/createUpdate';
import Sections from '../herramientas/adminSections';
import CreditPacks from './CreditPacks';
import NewCreditPack from './CreditPacks/New';
import EditCreditPack from './CreditPacks/Edit';
import Articles from './Articles';
import NewArticle from './Articles/New';
import EditArticle from './Articles/Edit';
import NotAllowed from 'templates/notAllowed';

import { useAuth } from 'contexts/auth';

import Layout from 'components/layout';

import { isEmpty } from 'libs/lodashAlt';
import { replaceComa, formatPrice } from 'libs/methods';

import HomeService from 'shared/home-service';

import { DEFAULT_SECTIONS } from 'shared/constants';
import { FormattedMessage } from 'react-intl';

const AdminTools = () => {
  const { admin, sections } = useAuth();
  const history = useHistory();
  const { section, part } = useParams();
  const [summaryData, setSummaryData] = useState(null);

  useEffect(() => {
    if (admin !== 1) history.push('/estadisticas');
  }, [admin, history]);

  useEffect(() => {
    HomeService.adminSummary().then((response) => {
      if (response.status === 'Accepted') setSummaryData(response.message.data);
    });
  }, []);

  let authSections = [];

  if (sections) {
    authSections = Object.keys(sections).map((key, value) => {
      return DEFAULT_SECTIONS.includes(sections[key].ID_SECTION) ? sections[key].ID_SECTION : null;
    });
  } else history.push('/');

  return (
    <Layout>
      {(() => {
        switch (section) {
          case 'gestor-banners':
            return authSections.includes(1) ? <Banners /> : <NotAllowed />;
          case 'gestor-landings':
            return authSections.includes(2) ? <Landings /> : <NotAllowed />;
          case 'gestor-categorias':
            return authSections.includes(3) ? <Categorias /> : <NotAllowed />;

          case 'marcas-blancas':
            if (authSections.includes(4)) {
              return !part ? <WhiteLabels /> : <WhiteLabelEdit />;
            } else return <NotAllowed />;

          case 'incidencias-visa':
            if (authSections.includes(6)) {
              return !part ? <VisaEvents /> : <VisaUser />;
            } else return <NotAllowed />;

          case 'stats-brokers':
            if (authSections.includes(7)) {
              return !part ? <SummaryBrokers /> : <StatsBrokers />;
            } else return <NotAllowed />;

          case 'gestor-noticias':
            return authSections.includes(8) ? <News /> : <NotAllowed />;

          case 'gestor-afiliados':
            if (authSections.includes(9)) {
              return !part ? <Affiliates /> : <EditAffiliate id={part} />;
            } else return <NotAllowed />;

          case 'tarifas-afiliados':
            return authSections.includes(9) ? <AffiliatesRates /> : <NotAllowed />;

          case 'facturacion-afiliado':
            return authSections.includes(9) ? <AffiliateBilling /> : <NotAllowed />;

          case 'gestor-usuarios-olecams':
            return authSections.includes(10) ? <UsuariosOlecams /> : <NotAllowed />;

          case 'brokers':
            if (authSections.includes(7)) {
              return !part ? <Brokers /> : part === 'nuevo' ? <NewBroker /> : <EditBroker id={part} />;
            } else return <NotAllowed />;

          case 'activacion-afiliados':
            return authSections.includes(11) ? <ActiveAfiliados /> : <NotAllowed />;

          case 'generar-afiliado-key':
            return authSections.includes(11) ? <GenerateKey /> : <NotAllowed />;

          case 'facturacion-mensual':
            return authSections.includes(12) ? <MonthBilling /> : <NotAllowed />;

          case 'promociones':
            if (authSections.includes(13)) {
              return !part ? <Discounts /> : <NewDiscounts />;
            } else return <NotAllowed />;

          case 'regalo-creditos':
            if (authSections.includes(13)) {
              return !part ? <CreditGift /> : <NewCreditGift />;
            } else return <NotAllowed />;

          case 'secciones':
            return authSections.includes(14) ? <Sections /> : <NotAllowed />;

          case 'panel-articulos':
            if (authSections.includes(16)) {
              return !part ? <Articles /> : part === 'nuevo' ? <NewArticle /> : <EditArticle id={part} />;
            } else return <NotAllowed />;

          case 'credit-packs':
            if (authSections.includes(14)) {
              return !part ? <CreditPacks /> : part === 'nuevo' ? <NewCreditPack /> : <EditCreditPack id={part} />;
            } else return <NotAllowed />;

          default:
            return <AdminToolsMain summary={summaryData} />;
        }
      })()}
    </Layout>
  );
};

export default AdminTools;

const AdminToolsMain = ({ summary }) => {
  const { sections } = useAuth();

  let authSections = [];
  authSections = Object.keys(sections).map((key, value) => {
    return DEFAULT_SECTIONS.includes(sections[key].ID_SECTION) ? sections[key].ID_SECTION : null;
  });

  return (
    <div className="inicio">
      <Container className="mw-100">
        {authSections.includes(5) && (
          <Row>
            <Col sm="12">
              <Summary summary={summary} />
            </Col>
          </Row>
        )}

        <Row>
          <Col xl="3">
            <Stats />
          </Col>
          <Col xl="3">
            <Administration />
          </Col>
          <Col xl="3">
            <Promotion />
          </Col>
          <Col xl="3">
            <Settings />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const Section = ({ title, icon, options }) => {
  const { sections } = useAuth();
  let authSections = [];
  authSections = Object.keys(sections).map((key, value) => {
    return DEFAULT_SECTIONS.includes(sections[key].ID_SECTION) ? sections[key].ID_SECTION : null;
  });
  return (
    <Card className="mb-2">
      <Card.Header className="font-weight-bold">
        <FontAwesomeIcon icon={icon} className="mr-2" />
        {title}
      </Card.Header>
      <Card.Body>
        <ListGroup>
          {options.map((object, index) => {
            return (
              authSections.includes(object.sectionId) && (
                <NavLink to={object.link} role="button" key={index}>
                  <ListGroup.Item className="font-weight-bold">
                    {object.icon && <FontAwesomeIcon icon={object.icon} className="mr-2" />} {object.title}
                  </ListGroup.Item>
                </NavLink>
              )
            );
          })}
        </ListGroup>
      </Card.Body>
    </Card>
  );
};

const Summary = ({ summary }) => {
  return (
    <Card className="mb-2">
      <Card.Header>
        <FontAwesomeIcon icon={faChartBar} className="mr-2" />
        <FormattedMessage id="statistics-resum-title" />
      </Card.Header>
      <Card.Body>
        <div className="stats">
          <span>
            <h2 className="desktop">
              <FormattedMessage id="statistics-resum-data-generate-last" />
            </h2>
            <h2 className="movil">
              <FormattedMessage id="statistics-resum-data-generate-month" />
            </h2>
            <h2 className="movil">
              <FormattedMessage id="statistics-resum-data-last" />
            </h2>
            <h1>{summary ? <>{formatPrice(summary.prev.total_amount.toString())}</> : <>--</>}</h1>
          </span>
          <span>
            <h2 className="desktop">
              <FormattedMessage id="statistics-resum-data-generate-actualy" />
            </h2>
            <h2 className="movil">
              <FormattedMessage id="statistics-resum-data-generate-current" />
            </h2>
            <h2 className="movil">
              <FormattedMessage id="statistics-resum-data-current" />
            </h2>
            <h1>{summary ? <>{formatPrice(summary.current.total_amount.toString())}</> : <>--</>}</h1>
          </span>
        </div>
        <hr />
        <div className="w-100 text-center">
          <Accordion className="metatags-accordion">
            <Accordion.Toggle eventKey="0" variant="link" as={Button}>
              Información detallada
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Row className="stats mt-4 w-100 mr-0 ml-0 justify-content-between" style={{ color: '#4b5056' }}>
                <Col xl="6" className="mb-4">
                  <div>
                    <div className="font-weight-bold">
                      <h2 style={{ fontSize: '18px' }}>Mes Anterior</h2>
                    </div>
                    <StatsItem label="Comercegate" amount={summary?.prev.data.visacg.sum} />
                    <StatsItem label="Trustpay" amount={summary?.prev.data.trustpay.sum} />
                    <StatsItem label="Segpay" amount={summary?.prev.data.segpay.sum} />
                    <StatsItem label="Inespay" amount={summary?.prev.data.inespay.sum} />
                    <StatsItem label="Epoch" amount={summary?.prev.data.epoch.sum} />
                    <StatsItem label="Directa24" amount={summary?.prev.data.directa24.sum} />
                    <StatsItem label="Smartpay" amount={summary?.prev.data.smartpay.sum} />

                    <StatsItem label="Phone" amount={summary?.prev.data.phone.sum} />
                    <StatsItem label="SMS" amount={summary?.prev.data.sms.sum} />
                  </div>
                </Col>

                <Col xl="6" className="">
                  <div>
                    <div className="font-weight-bold">
                      <h2 style={{ fontSize: '18px' }}>Mes Actual</h2>
                    </div>
                    <StatsItem label="Comercegate" amount={summary?.current.data.visacg.sum} />
                    <StatsItem label="Trustpay" amount={summary?.current.data.trustpay.sum} />
                    <StatsItem label="Segpay" amount={summary?.current.data.segpay.sum} />
                    <StatsItem label="Inespay" amount={summary?.current.data.inespay.sum} />
                    <StatsItem label="Epoch" amount={summary?.current.data.epoch.sum} />
                    <StatsItem label="Directa24" amount={summary?.current.data.directa24.sum} />
                    <StatsItem label="Smartpay" amount={summary?.current.data.smartpay.sum} />

                    <StatsItem label="Phone" amount={summary?.current.data.phone.sum} />
                    <StatsItem label="SMS" amount={summary?.current.data.sms.sum} />
                  </div>
                </Col>
              </Row>
            </Accordion.Collapse>
          </Accordion>
        </div>
      </Card.Body>
    </Card>
  );
};

const StatsItem = ({ label, amount }) => {
  return (
    <Row className="justify-content-md-center">
      <Col className="border">
        <div style={{ fontSize: '16px' }}>{label}</div>
      </Col>
      <Col className="border">
        <div style={{ fontSize: '16px', fontWeight: 'bold' }}>{formatPrice(amount)}</div>
      </Col>
    </Row>
  );
};

const Stats = () => {
  const options = [
    { title: 'VCE', link: '/estadisticas/vce', icon: faChartBar, sectionId: 5 },
    { title: 'OC', link: '/estadisticas/olecams', icon: faChartBar, sectionId: 5 },
    { title: 'OC Phone', link: '/admintools/stats/olecams/phone', icon: faPhone, sectionId: 5 },
    { title: 'OC SMS', link: '/admintools/stats/olecams/sms', icon: faCommentDots, sectionId: 5 },
    { title: 'Estadísticas Brokers', link: '/admintools/stats-brokers', icon: faChartBar, sectionId: 7 },
  ];
  return <Section title="Estadísticas" icon={faChartBar} options={options} />;
};

const Administration = () => {
  const options = [
    { title: 'Usuarios VCE', link: '/admintools/incidencias-visa', icon: faUsers, sectionId: 6 },
    { title: 'Usuarios Olecams', link: '/admintools/gestor-usuarios-olecams', icon: faUsers, sectionId: 10 },
    { title: 'Brokers', link: '/admintools/brokers', icon: faUsers, sectionId: 7 },
    { title: 'Afiliados', link: '/admintools/gestor-afiliados', icon: faUsers, sectionId: 9 },
    { title: 'Activación Afiliados', link: '/admintools/activacion-afiliados', icon: faUserCheck, sectionId: 11 },
    { title: 'Facturación Mensual', link: '/admintools/facturacion-mensual', icon: faFileInvoiceDollar, sectionId: 12 },
  ];
  return <Section title="Administración" icon={faChartBar} options={options} />;
};

const Promotion = () => {
  const options = [
    { title: 'Banners', link: '/admintools/gestor-banners', icon: faImage, sectionId: 1 },
    { title: 'Categorias Banners', link: '/admintools/gestor-categorias', icon: faImage, sectionId: 3 },
    { title: 'Landings', link: '/admintools/gestor-landings', icon: faGlobe, sectionId: 2 },
    { title: 'Marcas Blancas', link: '/admintools/marcas-blancas', icon: faWindowMaximize, sectionId: 4 },
    { title: 'Noticias', link: '/admintools/gestor-noticias', icon: faNewspaper, sectionId: 8 },
    { title: 'Promociones', link: '/admintools/promociones', icon: faPercent, sectionId: 13 },
    { title: 'Regalo de créditos (sin compra)', link: '/admintools/regalo-creditos', icon: faGift, sectionId: 13 },
  ];
  return <Section title="Promoción" icon={faChartBar} options={options} />;
};

const Settings = () => {
  const options = [
    { title: 'Secciones y permisos', link: '/admintools/secciones', icon: faAddressCard, sectionId: 14 },
    { title: 'Panel de artículos', link: '/admintools/panel-articulos', icon: faThList, sectionId: 16 }, // ToDo, update sectionID
    { title: 'Editor tarifas de afilados', link: '/admintools/tarifas-afiliados', icon: faCoins, sectionId: 9 }, // ToDo, update sectionID
    { title: 'Editor Paquetes de Créditos', link: '/admintools/credit-packs', icon: faCoins, sectionId: 16 },
  ];
  return <Section title="Configuración" icon={faChartBar} options={options} />;
};

import { Accordion, Card, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList } from '@fortawesome/free-solid-svg-icons';

import translate from 'i18n/translate';

const StatsLogs = ({ data, title, email }) => {
  return (
    <Accordion className="current-month-accordion" defaultActiveKey="0">
      <Card className="mb-2">
        <Accordion.Toggle as={Card.Header} variant="link" eventKey="0" className="pointer">
          <FontAwesomeIcon icon={faClipboardList} className="mr-2" /> {translate(title)} &nbsp; <strong>{email}</strong>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            <Table responsive="sm" striped bordered hover>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Nick</th>
                  <th>IP</th>
                  <th>{translate('statistics-table-date')}</th>
                  <th>{translate('statistics-table-time')}</th>
                  <th>{translate('global-girl')}</th>
                  <th>{translate('global-time')}</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.id}</td>
                      <td>{item.nick}</td>
                      <td>{item.ip}</td>
                      <td>{item.fecha}</td>
                      <td>{item.hora}</td>
                      <td>{item.chica}</td>
                      <td>{item.tiempo}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default StatsLogs;

import Layout from 'components/layout';
import Stats from './stats';
import RegisterDay from './registerDay';
import FraudRegisterDay from './FraudRegisterDay';
import StatsDay from './statsDay';
import StatsUser from './statsUser';
import StatsPhone from './Phone';
import StatsSms from './Sms';

const StatsOC = ({ match }) => {
  return (
    <Layout>
      {(match.path === '/estadisticas/olecams/register-day/:date' || match.path === '/estadisticas/olecams/register-day/:date/:valid') && (
        <RegisterDay date={match.params.date} valid={match.params.valid ? match.params.valid : null} />
      )}
      {match.path === '/estadisticas/olecams/fraud-register-day/:date' && <FraudRegisterDay date={match.params.date} />}
      {(match.path === '/estadisticas/olecams/stats-day/:date' || match.path === '/estadisticas/olecams/stats-day/:date/:param') && (
        <StatsDay date={match.params.date} param={match.params.param ? match.params.param : null} />
      )}
      {match.path === '/estadisticas/olecams/stats-user/:email' && <StatsUser email={match.params.email} />}
      {match.path === '/estadisticas/olecams/phone' && <StatsPhone />}
      {match.path === '/estadisticas/olecams/sms' && <StatsSms />}
      {match.path === '/estadisticas/olecams' && <Stats />}
    </Layout>
  );
};

export default StatsOC;

import { useSelector } from 'react-redux';
import es from 'date-fns/locale/es';
import DatePicker, { registerLocale } from 'react-datepicker';
import { FormattedMessage, useIntl } from 'react-intl';

//libs
import { getLang } from 'libs/cookies';

//components
import Button from 'components/button';
import Calendar from 'components/icons/calendar';
import SelectCountry from 'templates/statsOlecams/formComponents/SelectCountry';
import SelectDevice from 'templates/statsOlecams/formComponents/SelectDevice';

const Form = ({ filters, date, getStats, handleOnChange, handleChangeDate, handleChangeCountry }) => {
  const { admin } = useSelector((state) => state.auth.auth);
  const { formatMessage } = useIntl();
  registerLocale('es', es);
  return (
    <form className="form-content" onSubmit={getStats}>
      <div className="form-detall-content vce">
        <div className="content-one">
          <div className="form-group">
            <label>
              <FormattedMessage id="statistics-form-select-rang-vce" />
            </label>
            <select className="form-control" name="view" onChange={handleOnChange} value={filters.view}>
              <option value="month">{formatMessage({ id: 'statistics-form-select-month' })}</option>
              <option value="day">{formatMessage({ id: 'statistics-form-select-day' })}</option>
            </select>
          </div>
          <div className="form-group calendar sp">
            <label>
              <FormattedMessage id="statistics-form-date" />
            </label>
            <div className="form-control">
              {filters.view === 'month' ? (
                <DatePicker
                  selected={date}
                  onChange={(date) => handleChangeDate(date)}
                  locale={getLang()}
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                  showFullMonthYearPicker
                  showTwoColumnMonthYearPicker
                  id="calendar-vce"
                  popperPlacement="bottom"
                />
              ) : (
                <DatePicker
                  selected={date}
                  onChange={(date) => handleChangeDate(date)}
                  locale={getLang()}
                  dateFormat="dd/MM/yyyy"
                  id="calendar-vce"
                  popperPlacement="bottom"
                />
              )}
            </div>
            <Calendar id="calendar-vce" />
          </div>
        </div>
        <div className="content-two">
          {parseInt(admin) === 1 && (
            <>
              <div className="form-group">
                <label>
                  <FormattedMessage id="statistics-form-id-afi" />
                </label>
                <input
                  placeholder={formatMessage({ id: 'statistics-form-select-all' })}
                  type="text"
                  name="affiliate_id"
                  className="form-control"
                  onChange={handleOnChange}
                  value={filters.affiliate_id}
                />
              </div>
              <div className="form-group">
                <label>
                  <FormattedMessage id="global-afno" />
                </label>

                <input
                  placeholder={formatMessage({ id: 'statistics-form-select-all' })}
                  type="text"
                  name="afno"
                  className="form-control"
                  onChange={handleOnChange}
                  value={filters.afno}
                />
              </div>
            </>
          )}
          <SelectCountry value={filters.country} handleChangeCountry={handleChangeCountry} />
          <SelectDevice value={filters.device} handleChangeDevice={handleOnChange} />
        </div>
        <div className="buttons-content">
          <Button rounded onClick={() => window.location.reload()} align="center" className="left">
            <FormattedMessage id="statistics-form-button-return" />
          </Button>
          <Button rounded align="center" type="submit">
            <FormattedMessage id="global-send" />
          </Button>
        </div>
      </div>
    </form>
  );
};

export default Form;

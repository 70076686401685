import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { Card, Container, Col, Row, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList, faPlusSquare, faEdit, faTrash, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import Loading from 'components/loading';
import Button from 'components/button';

import RoomlistService from 'shared/roomlist-service';

import './index.scss';

const RoomLists = () => {
  const loading = useSelector((state) => state.loading.loading);
  const dispatch = useDispatch();
  const history = useHistory();
  const { formatMessage } = useIntl();

  const [savedRoomlists, setSavedRoomlists] = useState();
  const [idRoomlist, setIdRoomlist] = useState();

  const getSavedRoomlists = () => {
    dispatch({ type: 'SET_LOADING', loading: true });
    RoomlistService.get()
      .then((responseData) => {
        if (responseData.status === 'Accepted' && responseData.message.length > 0) setSavedRoomlists(responseData.message);
        else goToForm();
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
  };

  const getData = useCallback(() => {
    getSavedRoomlists();
    dispatch({ type: 'SET_ID_EDIT_ROOMLIST', id_edit_roomlist: '' });
  }, []);

  useEffect(getData, [getData]);

  const goToForm = () => {
    //dispatch({ type: 'SET_LOADING', loading: true });
    history.push('/promocion/banners-dinamicos/form');
  };

  const editRoomlist = () => {
    if (idRoomlist !== '') {
      dispatch({ type: 'SET_ID_EDIT_ROOMLIST', id_edit_roomlist: idRoomlist });
      //dispatch({ type: 'SET_LOADING', loading: true });
      history.push('/promocion/banners-dinamicos/form');
    } else toast.warning(<FormattedMessage id="global-select-option" />);
  };

  const deleteRoom = () => {
    let msg = 'promo-sure-delete-roomlist';

    if (idRoomlist !== '') {
      if (window.confirm(formatMessage({ id: msg }))) {
        setSavedRoomlists({});

        //dispatch({ type: 'SET_LOADING', loading: true });

        RoomlistService.remove(idRoomlist)
          .then((responseData) => {
            if (responseData.status === 'Accepted') {
              toast.success(<FormattedMessage id="administration-success-data" />);
            } else toast.error(<FormattedMessage id="promo-error-deleting-roomlist" />);
            getSavedRoomlists();
          })
          .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
      }
    } else toast.warning(<FormattedMessage id="global-select-option" />);
  };

  if (loading) return <Loading />;

  return (
    <Container>
      <Row>
        <Col sm="12">
          <Card className="mb-2">
            <Card.Header>
              <FontAwesomeIcon icon={faClipboardList} className="mr-2" /> <FormattedMessage id="sidebar-promo-roomlists" />
            </Card.Header>
            <Card.Body>
              <Container>
                <div>
                  <FormattedMessage id="promo-roomlist-welcome-message" />
                </div>
                <hr />
                <Row>
                  <Col sm="6">
                    <Card className="h-100">
                      <Card.Header>
                        <FontAwesomeIcon icon={faPlusSquare} className="mr-2" /> <FormattedMessage id="promo-create-new-roomlist" />
                      </Card.Header>
                      <Card.Body>
                        <Container>
                          <Row>
                            <Col sm="12" className="text-center mb-2">
                              <h5>
                                <FormattedMessage id="promo-start-creating-roomlist" />
                              </h5>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm="12" className="text-center">
                              <Button rounded onClick={(e) => goToForm()}>
                                <FontAwesomeIcon icon={faPlusSquare} className="mr-2" /> <FormattedMessage id="promo-landing-form-button" />
                              </Button>
                            </Col>
                          </Row>
                        </Container>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col sm="6">
                    <Card className="h-100">
                      <Card.Header>
                        <FontAwesomeIcon icon={faEdit} className="mr-2" /> <FormattedMessage id="promo-modify-roomlist" />
                      </Card.Header>
                      <Card.Body>
                        <Container>
                          {savedRoomlists && savedRoomlists.length === 0 && (
                            <div className="text-center">
                              <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />{' '}
                              <FormattedMessage id="promo-no-roomlist-results" />
                            </div>
                          )}
                          {savedRoomlists && savedRoomlists.length > 0 && (
                            <Form>
                              <Form.Group as={Row} className="mb-3" controlId="formDominios">
                                <Col sm="12">
                                  <Form.Control
                                    as="select"
                                    custom
                                    name="dominios"
                                    placeholder="Dominios"
                                    onChange={(e) => setIdRoomlist(e.target.value)}>
                                    <option value="">{formatMessage({ id: 'global-select-campaign' })}</option>
                                    {savedRoomlists.map((item, index) => (
                                      <option key={item.id} value={item.id}>
                                        {item.campaign} - {item.domain}
                                      </option>
                                    ))}
                                  </Form.Control>
                                </Col>
                              </Form.Group>
                              <Row>
                                {/*<Col sm="4" className="text-center"><Button variant="success" onClick={ () => this.setState({ showModal: true }) }> <FontAwesomeIcon icon={faEye} className="mr-2" />{translate('global-preview')}</Button></Col>*/}
                                <Col sm="6" className="text-center">
                                  <Button rounded blue onClick={(e) => editRoomlist()}>
                                    <FontAwesomeIcon icon={faEdit} className="mr-2" />
                                    <FormattedMessage id="login-button-enter" />
                                  </Button>
                                </Col>
                                <Col sm="6" className="text-center">
                                  <Button rounded red onClick={(e) => deleteRoom()}>
                                    <FontAwesomeIcon icon={faTrash} className="mr-2" />
                                    <FormattedMessage id="herramientas-banner-button-remove" />
                                  </Button>
                                </Col>
                              </Row>
                            </Form>
                          )}
                        </Container>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default RoomLists;

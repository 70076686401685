import axios from 'axios';

import Logger from '../libs/logger';

const logger = new Logger('ApiBBService');

export const getClient = (config) => {
  let headers = {
    Accept: 'application/json; charset=utf-8',
    'Content-Type': 'application/json; charset=utf-8',
    Authorization: `Bearer ${process.env.REACT_APP_API_BB_TOKEN}`,
  };

  return axios.create({
    baseURL: process.env.REACT_APP_API_BB_URL,
    headers,
    ...config,
  });
};

const request = function (options, config = {}) {
  const start = Date.now();
  const onSuccess = function (response) {
    logger.log(response.config.method.toUpperCase(), response.config.url, `successful in ${Date.now() - start}ms`);
    return response.data;
  };
  const onError = function (error) {
    logger.error('Request Failed:', error.config);
    if (error.response) {
      // Request was made but server responded with something
      // other than 2xx
      logger.error('Status:', error.response.status);
      logger.error('Data:', error.response.data);
      logger.error('Headers:', error.response.headers);
    } else {
      // Something else happened while setting up the request
      // triggered the error
      logger.error('Error Message:', error.message);
    }
    return Promise.reject(error.response || error.message);
  };
  return getClient(config)(options).then(onSuccess).catch(onError);
};

export default request;

import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import { useAuth } from 'contexts/auth';

//Components
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Aviso from '../aviso';
import ShowImg from '../showImg';
import InfoDetall from '../info';

//style
import './index.scss';

const Layout = (props) => {
  const modal = useSelector((state) => state.modal.modal);
  const modalImg = useSelector((state) => state.modal.img);
  const history = useHistory();
  const dispatch = useDispatch();
  const { logged, idContract } = useAuth();

  const [headerHeight, setHeaderHeight] = useState(0);
  //const [footerHeight, setFooterHeight] = useState(0);
  const [showMenu, setShowMenu] = useState(true);
  const [showUserMenu, setShowUserMenu] = useState(true);
  const [showAvist, setShowAvist] = useState(parseInt(idContract) !== 0 ? true : false);

  const getData = useCallback(() => {
    if (logged) {
      setHeaderHeight(document.getElementById('header').offsetHeight);
      //setFooterHeight(document.getElementById('footer').offsetHeight);
    } else history.push('/login');
  }, [logged, history]);

  useEffect(getData, [getData]);

  const showSidebar = () => {
    if (!showUserMenu) {
      document.getElementById('menu-user').classList.remove('active');
      setShowUserMenu(!showUserMenu);
    }
    if (showMenu) {
      document.getElementById('sideba-movil').classList.add('active');
    } else {
      document.getElementById('sideba-movil').classList.remove('active');
    }
    setShowMenu(!showMenu);
  };

  const showUser = () => {
    if (!showMenu) {
      document.getElementById('sideba-movil').classList.remove('active');
      setShowMenu(!showMenu);
    }
    if (showUserMenu) {
      document.getElementById('menu-user').classList.add('active');
    } else {
      document.getElementById('menu-user').classList.remove('active');
    }
    setShowUserMenu(!showUserMenu);
  };

  const closeModal = () => {
    setShowAvist(false);
    dispatch({ type: 'CLOSEALLMODAL' });
  };

  return (
    <div className="Layout-container">
      <ToastContainer autoClose={6000} />
      <Header showLinks={true} showSidebar={showSidebar} showMenu={showMenu} showUserMenu={showUserMenu} showUser={showUser} />
      <div style={{ height: `${headerHeight}px` }}></div>
      <div className="layout-content">
        <div id="sideba-movil" className="sidebar-container-movil">
          <Sidebar showSidebar={showSidebar} />
        </div>
        <div className="hidden-sidebar" />
        <div id="sidebar" className="sidebar-container">
          <Sidebar showSidebar={() => {}} />
        </div>
        <div className="content">{props.children}</div>
      </div>
      <Footer />
      {modal.showImg && <ShowImg img={modalImg} closeModal={closeModal} />}
      {modal.statsDetall && <InfoDetall closeModal={closeModal} />}
      {showAvist && <Aviso closeModal={closeModal} />}
    </div>
  );
};

export default Layout;

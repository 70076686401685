const Tablet = (props) => {
  return (
    <svg
      className="warning"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="22"
      height="22"
      viewBox="0 0 22 22">
      <path
        fill={props.color}
        id="prefix__a"
        d="M9 13h2v2H9v-2zm0-8h2v6H9V5zm1-5C4.47 0 0 4.5 0 10c0 2.652 1.054 5.196 2.929 7.071C4.804 18.946 7.348 20 10 20c5.523 0 10-4.477 10-10 0-2.652-1.054-5.196-2.929-7.071C15.196 1.054 12.652 0 10 0zm0 18c-4.418 0-8-3.582-8-8s3.582-8 8-8c2.122 0 4.157.843 5.657 2.343S18 7.878 18 10c0 4.418-3.582 8-8 8z"
      />
    </svg>
  );
};

export default Tablet;

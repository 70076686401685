import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
// Bootstrap
import { Card, Container, Col, Row, Table } from 'react-bootstrap';
// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList, faPlus, faPen } from '@fortawesome/free-solid-svg-icons';

import BrokerService from 'shared/broker-service.js';

//translate
import translate from 'i18n/translate';

import Loading from 'components/loading';
import Button from 'components/button';

import Modal from './modal.js';

const BrokerApps = () => {
  let loading = useSelector((state) => state.loading.loading);
  const dispatch = useDispatch();

  const appIni = {
    id: null,
    corte: '',
    pago: '',
    url_check_user_founds: '',
    dominio: '',
    email_fallo: '',
    ip: '',
  };

  const [data, setData] = useState([]);
  const [edit, setEdit] = useState(false);
  const [appData, setAppData] = useState(appIni);
  const [showAdd, setShowAdd] = useState(false);

  const getData = useCallback(() => {
    dispatch({ type: 'SET_LOADING', loading: true });
    BrokerService.getBrokerMB()
      .then((responseData) => {
        if (responseData.status === 'Accepted') setData(responseData.message);
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
  }, [dispatch, setData]);

  useEffect(getData, [getData]);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setAppData({
      ...appData,
      [name]: value,
    });
  };

  const close = (reload = false) => {
    if (reload) getData();
    setAppData(appIni);
    setShowAdd(false);
    setEdit(false);
  };

  const create = () => {
    dispatch({ type: 'SET_LOADING', loading: true });
    BrokerService.setMB(appData)
      .then((responseData) => {
        if (responseData.status === 'Accepted') {
          toast.success(translate('global-new-app-created'));
          close(true);
        } else toast.error(translate('global-new-app-creation-failed'));
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));

    /* setMB(auth.token, appData, dispatch).then((response) => {
      if (response.data.status === 'Accepted') {
        toast.success(translate('global-new-app-created'));
        close(true);
      } else toast.error(translate('global-new-app-creation-failed'));
    }); */
  };

  const update = () => {
    dispatch({ type: 'SET_LOADING', loading: true });
    BrokerService.updateMB(appData)
      .then((responseData) => {
        if (responseData.status === 'Accepted') {
          toast.success(translate('global-update-success'));
          close(true);
        } else toast.error(translate('global-update-failed'));
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));

    /* updateMB(auth.token, appData, dispatch).then((response) => {
      if (response.data.status === 'Accepted') {
        toast.success(translate('global-update-success'));
        close(true);
      } else toast.error(translate('global-update-failed'));
    }); */
  };

  const editBtn = (item) => {
    setAppData(item);
    setEdit(true);
    setShowAdd(true);
  };

  const createBtn = () => {
    setShowAdd(true);
    //setAppId(null);
    setEdit(false);
  };

  if (loading) return <Loading />;

  return (
    <Container fluid>
      <Row>
        <Col sm="12">
          <Card className="mb-2">
            <Card.Header>
              <FontAwesomeIcon icon={faClipboardList} className="mr-2" /> {translate('sidebar-broker-app')}{' '}
            </Card.Header>
            <Card.Body>
              <Container fluid className="text-center">
                {!showAdd && (
                  <>
                    <Button superBlue rounded className="mb-3 text-center" onClick={createBtn}>
                      <FontAwesomeIcon icon={faPlus} className="mr-2" /> {translate('global-create-new-app')}
                    </Button>
                    {data.result && data.result.length > 0 && (
                      <Table responsive="sm" striped bordered hover className="text-center">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>URL</th>
                            <th>{translate('global-date')}</th>
                            <th>{translate('herramientas-banner-button-update')}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.result.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>{item.id}</td>
                                <td>{item.dominio}</td>
                                <td>{item.fecha}</td>
                                <td>
                                  <Button rounded onClick={(e) => editBtn(item)}>
                                    <FontAwesomeIcon icon={faPen} className="mr-2" /> {translate('herramientas-banner-button-update')}
                                  </Button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    )}
                  </>
                )}
                {showAdd && (
                  <Modal data={appData} close={close} create={create} handleOnChange={handleOnChange} edit={edit} update={update} />
                )}
              </Container>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default BrokerApps;

import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import OcStatsService from 'shared/oc-stats-service';

//libs
import { dateFormat } from 'libs/methods';
import { isEmpty } from 'libs/lodashAlt';

//Component
import Card from 'components/card';
import Loading from 'components/loading';
import Pagination from 'components/pagination';

import IconStatsOC from 'components/icons/statsOlecams';

import Source from '../source';
import Form from './form';
import Table from './table';

// style
import '../index.scss';

const DailyRecords = (props) => {
  let loading = useSelector((state) => state.loading.loading);
  let stats = useSelector((state) => state.stats.statsRegister);
  let statsOCFilters = useSelector((state) => state.stats.statsOCFilters);
  const dispatch = useDispatch();

  const [affiliateId, setAffiliateId] = useState(statsOCFilters.affiliate_id ? statsOCFilters.affiliate_id : '');
  const [afno, setAfno] = useState(statsOCFilters.afno ? statsOCFilters.afno : '');
  const [device, setDevice] = useState(statsOCFilters.device ? statsOCFilters.device : '');
  const [country, setCountry] = useState(statsOCFilters.country ? statsOCFilters.country : '');
  const [date, setDate] = useState(props.date);
  const [emailValidated, setEmailValidated] = useState(props.valid ? props.valid : '');
  const [filters, setFilters] = useState({
    ...statsOCFilters,
    method: 'REGISTERS_DAY',
    admin: '',
    affiliate_id: statsOCFilters.affiliate_id ? statsOCFilters.affiliate_id : '',
    date: props.date,
    email_validated: props.valid ? props.valid : '',
    afno: statsOCFilters.afno ? statsOCFilters.afno : '',
    device: statsOCFilters.device ? statsOCFilters.device : '',
    country: statsOCFilters.country ? statsOCFilters.country : '',
    pager: {
      page: '1',
      items: '50',
    },
  });

  const getData = useCallback(() => {
    dispatch({ type: 'SET_LOADING', loading: true });
    OcStatsService.getStats(filters)
      .then((responseData) => {
        dispatch({ type: 'SET_STATS', statsRegister: responseData.message, statsOCFilters: filters });
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
  }, [dispatch, filters]);

  useEffect(getData, [getData]);

  const getStats = (e) => {
    e.preventDefault();
    setFilters({
      ...filters,
      date: date ? dateFormat(date) : '',
      affiliate_id: affiliateId,
      afno: afno,
      device: device,
      country: country,
      email_validated: emailValidated,
      pager: {
        ...filters.pager,
        page: 1,
      },
    });
  };

  // PAGINATION
  const paginate = (e) => {
    setFilters({
      ...filters,
      pager: { ...filters.pager, page: e.target.value },
    });
  };

  const handleChangeNumberOfColum = (e) => {
    setFilters({
      ...filters,
      pager: { ...filters.pager, items: e.target.value, page: 1 },
    });
  };

  const nextPage = (page) => {
    page++;
    if (page <= stats.pager.pages) {
      setFilters({
        ...filters,
        pager: { ...filters.pager, page: page },
      });
    }
  };

  const prevPage = (page) => {
    page--;
    if (page > 0) {
      setFilters({
        ...filters,
        pager: { ...filters.pager, page: page },
      });
    }
  };
  // END PAGINATION

  if (loading) return <Loading />;
  return (
    <div className="stats">
      {!isEmpty(stats) && (
        <div className="statsolecams-content">
          <Card icon={<IconStatsOC color="#4b5056" />} title={<FormattedMessage id="statistics-resum-title-olecams" />}>
            <div className="stats-ol">
              <Form
                filters={filters}
                getStats={getStats}
                affiliateId={affiliateId}
                afno={afno}
                setAfno={setAfno}
                setAffiliateId={setAffiliateId}
                date={date}
                setDate={setDate}
                emailValidated={emailValidated}
                setEmailValidated={setEmailValidated}
                device={device}
                setDevice={setDevice}
                country={country}
                setCountry={setCountry}
              />
            </div>
          </Card>
          <div className="table-content">
            <Table stats={stats} />
            <Pagination
              handleChangeNumberOfColum={handleChangeNumberOfColum}
              pager={stats.pager}
              paginate={paginate}
              prevPage={prevPage}
              nextPage={nextPage}
              total={stats.summary.count}
            />
          </div>
          <Source />
        </div>
      )}
    </div>
  );
};

export default DailyRecords;

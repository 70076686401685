import { useEffect, useCallback } from 'react';
import { ToastContainer } from 'react-toastify';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

//libs
import { getLang, setLang } from 'libs/cookies';

//actions
import { langFormar } from 'libs/methods';

//components
import Header from '../header';
import Footer from '../footer';
import Notice from '../notice';
import Done from '../icons/done';

//style
import './index.scss';

const LoginLayout = (props) => {
  const modal = useSelector((state) => state.modal.modal);
  const history = useHistory();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const setInitLang = useCallback(() => {
    !getLang() && setLang(langFormar(navigator.language));
  }, []);

  useEffect(setInitLang, [setInitLang]);

  //componentDidMount () { setLangInit()}
  const closeModal = () => {
    history.push('/');
    dispatch({ type: 'CLOSEALLMODAL' });
  };

  return (
    <div className="LoginLayout-container" id="layoutlogin">
      <ToastContainer autoClose={8000} />
      <Header showLinks={false} />
      <div className="content">{props.children}</div>
      <Footer />
      {modal.modalDone && (
        <Notice
          icon={<Done />}
          closeModal={closeModal}
          name={formatMessage({ id: 'notice-name-done' })}
          button={formatMessage({ id: 'notice-button-acepted' })}
          title={formatMessage({ id: 'notice-title-done' })}
          textOne={formatMessage({ id: 'notice-text-one-done' })}
          textTwo={formatMessage({ id: 'notice-text-two-done' })}
        />
      )}
    </div>
  );
};

export default LoginLayout;

import { useIntl } from 'react-intl';

import { Form, Row, Col } from 'react-bootstrap';

const CreditPacksSection = ({ data, handleCreditPacks, creditPacks, validator }) => {
  const { formatMessage } = useIntl();

  const sortedPacks = creditPacks.sort((a, b) => a.CREDITS - b.CREDITS);

  return (
    <>
      {creditPacks && creditPacks.length > 0 && (
        <Form.Group as={Row} className="mb-3 required" controlId="formCreditPack">
          <Form.Label column sm="3" className="font-weight-bold">
            Credit Packs
          </Form.Label>
          <Col sm="9">
            <select multiple size={creditPacks.length} className="form-control" onChange={handleCreditPacks} name="CREDIT_PACKS">
              <optgroup label="Todos los modos">
                {sortedPacks
                  .filter((pack) => pack.REGISTERED === 1 && pack.ANONYMOUS === 1)
                  .map((item, index) => {
                    let price = parseFloat(item.AMOUNT - (item.AMOUNT / 100) * data.DISCOUNT).toFixed(2);
                    return (
                      <option key={item.ID} value={item.ID} selected={data.CREDIT_PACKS.includes(item.ID)}>
                        {item.CREDITS} créditos {item.CREDIT_GIFT && ' - (total ' + item.CREDIT_GIFT + ' créditos)'}{' '}
                        {' precio final ' + price}
                      </option>
                    );
                  })}
              </optgroup>
              <optgroup label="Modo Registrado">
                {sortedPacks
                  .filter((pack) => pack.REGISTERED === 1 && pack.ANONYMOUS === 0)
                  .map((item, index) => {
                    let price = parseFloat(item.AMOUNT - (item.AMOUNT / 100) * data.DISCOUNT).toFixed(2);
                    return (
                      <option key={item.ID} value={item.ID} selected={data.CREDIT_PACKS.includes(item.ID)}>
                        {item.CREDITS} créditos {item.CREDIT_GIFT && ' - (total ' + item.CREDIT_GIFT + ' créditos)'}{' '}
                        {' precio final ' + price}
                      </option>
                    );
                  })}
              </optgroup>
              <optgroup label="Modo Anónimo">
                {sortedPacks
                  .filter((pack) => pack.REGISTERED === 0 && pack.ANONYMOUS === 1)
                  .map((item, index) => {
                    let price = parseFloat(item.AMOUNT - (item.AMOUNT / 100) * data.DISCOUNT).toFixed(2);
                    return (
                      <option key={item.ID} value={item.ID} selected={data.CREDIT_PACKS.includes(item.ID)}>
                        {item.CREDITS} créditos {item.CREDIT_GIFT && ' - (total ' + item.CREDIT_GIFT + ' créditos)'}{' '}
                        {' precio final ' + price}
                      </option>
                    );
                  })}
              </optgroup>
            </select>
            {validator.message(formatMessage({ id: 'global-credit-packs' }).toLowerCase(), data.CREDIT_PACKS, 'min:1,array|required')}
          </Col>
        </Form.Group>
      )}
    </>
  );
};

export default CreditPacksSection;

const initialState = {
  modal: {
    showImg: false,
    modalDone: false,
    statsDetall: false,
  },
  img: null,
};

const ModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'MODAL':
      return Object.assign({}, state, {
        modal: {
          showImg: action.showImg !== undefined ? action.showImg : false,
          modalDone: action.modalDone !== undefined ? action.modalDone : false,
          statsDetall: action.statsDetall !== undefined ? action.statsDetall : false,
        },
      });
    case 'SET_MODAL_IMG':
      return Object.assign({}, state, {
        img: action.img,
      });
    case 'CLOSEALLMODAL':
      return Object.assign({}, state, {
        modal: {
          showImg: false,
          modalDone: false,
          statsDetall: false,
        },
        img: null,
      });
    default:
      return state;
  }
};

export default ModalReducer;

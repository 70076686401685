import { useEffect, useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import OlecamsUsersService from 'shared/olecams-users-service';

const SelectCountry = ({ date, value, onChange }) => {
  const { formatMessage } = useIntl();
  const [countries, setCountries] = useState([]);

  const getData = useCallback(() => {
    OlecamsUsersService.countries({ filters: { date: date } }).then((response) => {
      setCountries(response.message.data);
      // Verificar si el valor actual está dentro de los países disponibles
      const isValidValue = response.message.data.some((country) => country.code === value);

      // Si el valor no es válido, establecer el valor por defecto
      if (!isValidValue) {
        onChange({ target: { name: 'country', value: '' } });
      }
    });
  }, [date, value, onChange]);

  useEffect(getData, [getData]);
  return (
    <>
      <div className="form-group">
        <label>
          <FormattedMessage id="statistics-form-select-country" />
        </label>
        <select className="form-control" name="country" onChange={onChange} value={value}>
          <option value="">{formatMessage({ id: 'statistics-form-select-all' })}</option>
          {countries &&
            countries.map((country, index) => {
              return (
                <option key={index} value={country.code}>
                  {country.name}
                </option>
              );
            })}
        </select>
      </div>
    </>
  );
};

export default SelectCountry;

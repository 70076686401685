import translate from 'i18n/translate';

import { formatPrice } from 'libs/methods';

//style
import '../statsOlecams/index.scss';

const Table = (props) => {
  return (
    <>
      <table className="table">
        <thead>
          <tr>
            {props.view === 'month' && (
              <>
                <th scope="col">{translate('statistics-table-day')}</th>
                <th scope="col">{translate('statistics-table-connection')}</th>
                <th scope="col">{translate('statistics-table-mid')}</th>
                <th scope="col">{translate('statistics-table-minut')}</th>
                <th scope="col">{translate('statistics-table-import')}</th>
              </>
            )}
            {props.view === 'day' && (
              <>
                <th scope="col">{translate('statistics-table-login')}</th>
                <th scope="col">{translate('statistics-table-time')}</th>
                <th scope="col">{translate('statistics-table-stat')}</th>
                <th scope="col">{translate('statistics-table-minut')}</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {props.view === 'month' &&
            props.stats.data.map((stats, index) => {
              let tipo = (index + 1) % 2 ? 'impar' : 'par';
              return (
                <tr key={index} className={tipo}>
                  <td
                    className="hover"
                    onClick={(e) => {
                      props.getStatsDay(stats.dia);
                    }}>
                    {stats.dia}
                  </td>
                  <td>{stats.totalConexiones}</td>
                  <td>{stats.mediaMinutos}</td>
                  <td>{stats.totalMinutos}</td>
                  <td>{formatPrice(stats.amount)}</td>
                </tr>
              );
            })}
          {props.view === 'day' &&
            props.stats.data.map((stats, index) => {
              let tipo = (index + 1) % 2 ? 'impar' : 'par';
              return (
                <tr key={index} className={tipo}>
                  <td>{stats.login}</td>
                  <td>{stats.hora}</td>
                  <td>{stats.status}</td>
                  <td>{stats.totalMinutos}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">{translate('statistics-table-connection')}</th>
            <th scope="col">{translate('statistics-table-mid')}</th>
            <th scope="col">{translate('statistics-table-minut')}</th>
            <th scope="col">{translate('statistics-table-import')}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{props.stats.data_summary.totalConexiones}</td>
            <td>{props.stats.data_summary.mediaMinutos}</td>
            <td>{props.stats.data_summary.totalMinutos}</td>
            <td>{formatPrice(props.stats.data_summary.amount)}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default Table;

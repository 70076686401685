import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import { Card, Col, Form, Row, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';

import { getLang } from 'libs/cookies';
import translate from 'i18n/translate';
import Button from 'components/button';
// Hooks
import useValidator from 'hooks/useValidator';

import VisaUserService from 'shared/visa-user-service';

import './index.scss';

const NewUserModal = ({ show, setShow }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [validator, showValidationMessage] = useValidator({}, {}, getLang());

  //const [modalShow, setModalShow] = useState(false);
  const [data, setData] = useState({
    email: '',
    tiempo: '',
    //suscrito_vce: '0000-00-00 00:00:00',
    suscrito_adp: '0000-00-00 00:00:00',
    locked: '0',
  });

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const saveBtn = (e) => {
    if (validator.allValid()) {
      dispatch({ type: 'SET_LOADING', loading: true });
      VisaUserService.adminSet(data)
        .then((responseData) => {
          if (responseData.status === 'Accepted') {
            toast.success(translate('global-update-success'));
            setShow(false);
          } else toast.error(translate('global-update-failed'));
        })
        .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
    } else showValidationMessage(true);
  };

  return (
    <Modal show={show} onHide={() => setShow(false)} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{translate('global-new-user')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card className="mb-2">
          <Card.Body>
            <Form id="form" className="form">
              <Row>
                <Col>
                  <Form.Group as={Row} className="mb-3 required" controlId="formEmail">
                    <Form.Label column sm="3" className="font-weight-bold">
                      Email
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={data.email} name="email" placeholder="email@email.com" onChange={handleOnChange} />
                      {validator.message('email', data.email, 'max: 255|required|email')}
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3 required" controlId="formTime">
                    <Form.Label column sm="3" className="font-weight-bold">
                      {translate('global-time-seconds')}
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={data.tiempo} name="tiempo" placeholder="" onChange={handleOnChange} type="number" min="0" />
                      {validator.message(intl.formatMessage({ id: 'global-time-seconds' }).toLowerCase(), data.tiempo, 'max: 255|required')}
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3 required" controlId="formSuscritoADP">
                    <Form.Label column sm="3" className="font-weight-bold">
                      {translate('global-subscribed-adp')}
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        value={data.suscrito_adp}
                        name="suscrito_adp"
                        placeholder="0000-00-00 00:00:00"
                        onChange={handleOnChange}
                      />
                      {validator.message(intl.formatMessage({ id: 'global-subscribed-adp' }).toLowerCase(), data.suscrito_adp, 'max: 255')}
                    </Col>
                  </Form.Group>

                  {/*
                                <Form.Group as={Row} className="mb-3 required" controlId="formSuscritoVCE">
                                        <Form.Label column sm="3" className="font-weight-bold">{translate('global-subscribed-vce')}</Form.Label>
                                    <Col sm="9">
                                        <Form.Control 
                                            value={data.suscrito_vce}
                                            name="suscrito_vce"
                                            placeholder="0000-00-00 00:00:00"
                                            onChange={handleOnChange}
                                        />
                                        { validator.message(intl.formatMessage({id: 'global-subscribed-vce'}).toLowerCase(), data.suscrito_vce, 'max: 255|required') }
                                    </Col>
                                    
                                </Form.Group>
                                */}

                  <Form.Group as={Row} className="mb-3 required" controlId="formLocked">
                    <Form.Label column sm="3" className="font-weight-bold">
                      Locked
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        as="input"
                        value={data.locked}
                        name="locked"
                        placeholder=""
                        onChange={handleOnChange}
                        htmlSize="1"
                        type="number"
                        max="1"
                        min="0"
                      />
                      {validator.message('locked', data.locked, 'max: 255|required')}
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button rounded onClick={saveBtn}>
          <FontAwesomeIcon icon={faSave} className="mr-2" /> {translate('administration-data-button')}
        </Button>
        <Button red rounded onClick={() => setShow(false)}>
          <FontAwesomeIcon icon={faTimes} className="mr-2" /> {translate('herramientas-banner-register-cancel')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NewUserModal;

import { FormattedMessage, useIntl } from 'react-intl';
import { Form } from 'react-bootstrap';
import es from 'date-fns/locale/es';
import DatePicker, { registerLocale } from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import translate from 'i18n/translate';
import Button from 'components/button';

import Calendar from 'components/icons/calendar';

// Libs
import { getLang } from 'libs/cookies';

const BrokersForm = ({ filters, handleChangeDate, getSummaryBtn, brokers, handleOnChangeBroker, test_studio, setTestStudio }) => {
  const intl = useIntl();
  registerLocale('es', es);

  return (
    <form className={`search-wrapper`}>
      <div className="first-filters d-flex align-center">
        <div className="form-group mr-3 calendar sp">
          <label>
            <FormattedMessage id="statistics-form-date" />
          </label>
          <div className="form-control">
            <DatePicker
              selected={filters.date}
              onChange={(date) => handleChangeDate(date)}
              locale={getLang()}
              dateFormat="MM/yyyy"
              showMonthYearPicker
              showFullMonthYearPicker
              showTwoColumnMonthYearPicker
              id="calendar-vce"
              popperPlacement="bottom"
              maxDate={new Date()}
              minDate={new Date('2018/11/01')}
            />
          </div>
          <Calendar id="calendar-vce" />
        </div>

        <div className="form-group mr-3">
          <label>Broker</label>
          <select className="form-control" onChange={handleOnChangeBroker} name="broker" defaultValue={filters.broker}>
            <option value="">{intl.formatMessage({ id: 'statistics-form-select-all' })}</option>
            {brokers &&
              brokers.length > 0 &&
              brokers.map((broker_item, index) => {
                return (
                  <option key={index} value={broker_item.BR}>
                    {broker_item.BR + ' - ' + broker_item.nombre}
                  </option>
                );
              })}
          </select>
        </div>
        <div className="form-group mr-3">
          <label htmlFor="test_studio">{translate('global-test-studio')}</label>
          <Form.Check
            type="checkbox"
            name="test_studio"
            id="test_studio"
            //onChange={e => this.props.handleRadioOnChange(item, 'content')}
            checked={test_studio}
            onChange={(e) => setTestStudio((current) => !current)}
          />
        </div>
      </div>
      <div className="buttons-content">
        <Button rounded onClick={getSummaryBtn}>
          <FontAwesomeIcon icon={faSearch} className="mr-2" /> <FormattedMessage id="global-send" />
        </Button>
      </div>
    </form>
  );
};

export default BrokersForm;

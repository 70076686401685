import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import es from 'date-fns/locale/es';
import DatePicker, { registerLocale } from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

// Libs
import { getLang } from 'libs/cookies';
import { dateFormat } from 'libs/methods';

import Button from 'components/button';

import IconCalendar from 'components/icons/calendar';

//components
import SelectProvider from 'templates/statsOlecams/formComponents/SelectProvider';
import SelectPaymentType from 'templates/statsOlecams/formComponents/SelectPaymentType';
import SelectDevice from 'templates/statsOlecams/formComponents/SelectDevice';
import SelectCountry from 'templates/statsOlecams/formComponents/SelectCountry';
import SelectSitemode from 'templates/statsOlecams/formComponents/SelectSitemode';
import SelectWhiteLabel from 'templates/statsOlecams/formComponents/SelectWhiteLabel';
import SelectAffiliate from 'templates/statsOlecams/formComponents/SelectAffiliate';
import SelectAnonymousFidelity from 'templates/statsOlecams/formComponents/SelectAnonymousFidelity';
import SelectStatus from 'templates/statsOlecams/formComponents/SelectStatus';
import InputAttempts from 'templates/statsOlecams/formComponents/InputAttempts';
import InputAfno from 'templates/statsOlecams/formComponents/InputAfno';
import SelectRegisteredFidelity from 'templates/statsOlecams/formComponents/SelectRegisteredFidelity';

const Form = ({
  filters,
  handleChangeInitialDate,
  handleChangeFinalDate,
  handleChangeRang,
  /* handleChangePay,
  handleChangePaymentProvider,
  handleChangeCountry,
  handleChangeFont, */
  handleOnChange,
  disabled,
  getStats,
}) => {
  const auth = useSelector((state) => state.auth.auth);
  const { formatMessage } = useIntl();
  const loading = useSelector((state) => state.loading.loading);
  registerLocale('es', es);
  return (
    <form className="form-content" onSubmit={getStats}>
      <div className="row">
        <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
          <div className="form-group calendar">
            <label>
              <FormattedMessage id="statistics-form-date" />
            </label>
            <div className="form-control">
              <DatePicker
                selected={dateFormat(filters.date_ini)}
                onChange={(date) => handleChangeInitialDate(date)}
                locale={getLang()}
                minDate={dateFormat(filters.date_min_ini)}
                maxDate={dateFormat(filters.date_max_ini)}
                dateFormat="dd/MM/yyyy"
                disabled={disabled}
                id="calendar-stats-init"
                popperPlacement="bottom"
              />
              <IconCalendar id="calendar-stats-init" />
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
          <div className="form-group calendar">
            <label>
              <FormattedMessage id="statistics-form-final-date" />
            </label>
            <div className="form-control">
              <DatePicker
                selected={dateFormat(filters.date_end)}
                onChange={(date) => handleChangeFinalDate(date)}
                locale={getLang()}
                minDate={dateFormat(filters.date_min_end)}
                maxDate={dateFormat(filters.date_max_end)}
                dateFormat="dd/MM/yyyy"
                disabled={disabled}
                id="calendar-stats-final"
                popperPlacement="bottom"
              />
              <IconCalendar id="calendar-stats-final" />
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
          <div className="form-group rng">
            <label>Rangos de fechas</label>
            <select className="form-control sp-select" onChange={handleChangeRang}>
              <option value="">{formatMessage({ id: 'statistics-form-select-rang' })}</option>
              <option value="1">{formatMessage({ id: 'statistics-form-select-today' })}</option>
              <option value="2">{formatMessage({ id: 'statistics-form-select-yesterday' })}</option>
              <option value="3">{formatMessage({ id: 'statistics-form-select-last7' })}</option>
              <option value="4">{formatMessage({ id: 'statistics-form-select-last30' })}</option>
              <option value="5">{formatMessage({ id: 'statistics-form-select-current-mont' })}</option>
              <option value="6">{formatMessage({ id: 'statistics-form-select-last-mont' })}</option>
              <option value="7">{formatMessage({ id: 'statistics-form-select-current-year' })}</option>
              <option value="8">{formatMessage({ id: 'statistics-form-select-last-year' })}</option>
            </select>
          </div>
        </div>
        <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
          <SelectCountry value={filters.country} handleChangeCountry={handleOnChange} />
        </div>
        {parseInt(auth.admin) === 1 && (
          <>
            <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
              <SelectSitemode value={filters.sitemode} handleChange={handleOnChange} />
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
              <SelectWhiteLabel whitelabelId={filters.whitelabel} handleChange={handleOnChange} />
            </div>
          </>
        )}

        <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
          <SelectPaymentType value={filters.payment_type} handleChangePay={handleOnChange} />
        </div>
        {parseInt(auth.admin) === 1 && (
          <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
            <SelectProvider value={filters.provider} onChange={handleOnChange} paymentValue={filters.payment_type} />
          </div>
        )}
        <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
          <SelectDevice value={filters.device} handleChangeDevice={handleOnChange} />
        </div>

        <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
          <InputAfno afno={filters.afno} handleChange={handleOnChange} />
        </div>
        {parseInt(auth.admin) === 1 && (
          <>
            <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
              <SelectAffiliate affiliateId={filters.affiliate_id} handleChange={handleOnChange} />
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
              <SelectStatus value={filters.status} handleChange={handleOnChange} />
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
              <SelectAnonymousFidelity value={filters.anonymous_fidelity} handleChange={handleOnChange} />
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
              <SelectRegisteredFidelity value={filters.registered_fidelity} onChange={handleOnChange} />
            </div>

            <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
              <InputAttempts value={filters.attempt} onChange={handleOnChange} />
            </div>

            <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
              <div className="form-group">
                <label htmlFor="discounts">
                  <FormattedMessage id="global-discounts-only" />
                </label>
                <input
                  type="checkbox"
                  id="discounts"
                  name="discounts"
                  className="form-control check"
                  onChange={handleOnChange}
                  checked={filters.discounts}
                />
              </div>
            </div>
          </>
        )}
      </div>
      <div className="buttons-content">
        <Button rounded onClick={getStats} align="center" type="submit" disabled={loading}>
          {loading ? <FontAwesomeIcon icon={faSpinner} spin transform="left-4" size="lg" /> : <FormattedMessage id="global-send" />}
        </Button>
      </div>
    </form>
  );
};

export default Form;

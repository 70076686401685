import request from './api-service';

const get = () => {
  return request({
    method: 'get',
    url: '/lang/get',
  });
};

const set = (idioma) => {
  return request({
    method: 'post',
    url: '/lang/set',
    data: { affiliate_profile_data: { idioma } },
  });
};

const LangService = {
  set,
  get,
};

export default LangService;

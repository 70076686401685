export const getProvider = (provider) => {
  let result = '';
  switch (provider.toUpperCase()) {
    case 'CG':
      result = 'CommerceGate';
      break;
    case 'EPOCH':
      result = 'Epoch';
      break;
    case 'TRUSTPAY':
      result = 'TrustPay';
      break;
    case 'DIRECTA24':
      result = 'Directa 24';
      break;
    case 'SOFORT':
      result = 'Sofort';
      break;
    case 'COINGATE':
      result = 'Coingate';
      break;
    case 'SEGPAY':
      result = 'SegPay';
      break;
    case 'INESPAY':
      result = 'InesPay';
      break;
    case 'SMARTPAY':
      result = 'SmartPay';
      break;
    default:
      result = 'Desconocido';
  }

  return result;
};

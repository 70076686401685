import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';

import { Form, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faPlus } from '@fortawesome/free-solid-svg-icons';

import BannerService from 'shared/banner-service';

//libs
import { isEmpty } from 'libs/lodashAlt';
import { getLang } from 'libs/cookies';

//Component
import Card from 'components/card';
import Button from 'components/button';
import Loading from 'components/loading';
import Pagination from 'components/pagination';
import Herramienta from 'components/icons/herramienta';
import Register from './register';

//style
import '../index.scss';

const Banners = (props) => {
  let loading = useSelector((state) => state.loading.loading);
  //let data = useSelector((state) => state.banners.adminBanners);
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const [data, setData] = useState([]);
  const [showAddBanner, setShowAddBanner] = useState(false);
  const [filters, setFilters] = useState({ search: '' });

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const [lastPage, setLastPage] = useState(1);

  const history = useHistory();

  useEffect(() => {
    setData([]);
    dispatch({ type: 'SET_LOADING', loading: true });
    BannerService.adminList({ page, perPage, filters })
      .then((responseData) => {
        responseData.status === 'Failed' && toast.error('No se pudo cargar la información');
        if (responseData.status === 'Accepted') {
          setData(responseData.message);
          setLastPage(responseData.message.last_page);
        }
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
  }, [page, perPage, dispatch]);

  const handleOnChange = (e) => {
    const { name, value } = e.target;

    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const sendSubmit = (e) => {
    e.preventDefault();
    getData();
  };

  const getData = () => {
    setData([]);
    setPage(1);
    dispatch({ type: 'SET_LOADING', loading: true });
    BannerService.adminList({ page: 1, perPage, filters })
      .then((responseData) => {
        responseData.status === 'Failed' && toast.error('No se pudo cargar la información');
        if (responseData.status === 'Accepted') {
          setData(responseData.message);
          setLastPage(responseData.message.last_page);
        }
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
  };

  const close = (reload = false) => {
    if (reload) getData();
    setShowAddBanner(false);
  };

  const showImgeModal = (img) => {
    dispatch({ type: 'SET_MODAL_IMG', img: img });
    dispatch({ type: 'MODAL', showImg: true });
  };

  const remove = (id) => {
    if (window.confirm(formatMessage({ id: 'herramientas-confirm-alert' }))) {
      BannerService.adminDelete(id).then((data) => {
        if (data.status !== 'Failed') getData();
      });
    }
  };

  /* PAGINATOR */
  const paginate = (e) => setPage(e.target.value);

  const handleChangeNumberOfColum = (e) => {
    setPerPage(e.target.value);
    setPage(1);
  };

  const nextPage = (_page) => {
    _page++;
    _page <= lastPage && setPage(_page);
  };

  const prevPage = (_page) => {
    _page--;
    _page > 0 && setPage(_page);
  };
  // END PAGINATOR

  if (loading) return <Loading />;
  return (
    <>
      {!isEmpty(data) && (
        <div className="herramientas-administrativas-content">
          {!showAddBanner && (
            <>
              <Card icon={<Herramienta color="#4b5056" />} title={<FormattedMessage id="herramientas-banner-name" />}>
                <Row className="justify-content-between">
                  <Col>
                    <form className={`search-wrapper`} onSubmit={sendSubmit}>
                      <div className="first-filters d-flex align-center">
                        <Form.Group className="mr-3" controlId="formSearch">
                          <Form.Label>
                            <FormattedMessage id="global-search" />
                          </Form.Label>
                          <Form.Control value={filters.search} name="search" placeholder="" onChange={handleOnChange} />
                        </Form.Group>
                      </div>
                      <div className="buttons-content">
                        <Button rounded type="submit">
                          <FontAwesomeIcon icon={faSearch} className="mr-2" /> <FormattedMessage id="global-send" />
                        </Button>
                      </div>
                    </form>
                  </Col>
                  <Col className="text-right align-self-center">
                    <Button blue rounded className="mb-2" onClick={() => setShowAddBanner(true)}>
                      <FontAwesomeIcon icon={faPlus} className="mr-2" />
                      Nuevo banner
                    </Button>
                    <br />

                    <Button blue rounded className="mb-2" onClick={() => history.push('/admintools/gestor-categorias/nuevo')}>
                      <FontAwesomeIcon icon={faPlus} className="mr-2" />
                      Nueva categoria de banner
                    </Button>
                  </Col>
                </Row>
              </Card>
              <div className="banner-table-content">
                <Table data={data.data} showImgeModal={showImgeModal} remove={remove} />
              </div>
              <Pagination
                handleChangeNumberOfColum={handleChangeNumberOfColum}
                pager={{ page: page, items: perPage, pages: lastPage }}
                paginate={paginate}
                prevPage={prevPage}
                nextPage={nextPage}
                total={data.total}
              />
            </>
          )}
          {showAddBanner && <Register close={close} />}
        </div>
      )}
    </>
  );
};
export default Banners;

const Table = ({ data, showImgeModal, remove }) => {
  return (
    <table className="table">
      <thead>
        <tr>
          <th scope="col">
            <FormattedMessage id="global-id" />
          </th>
          <th scope="col">
            <FormattedMessage id="global-name" />
          </th>
          <th scope="col">
            <FormattedMessage id="global-category" />
          </th>
          <th scope="col">
            <FormattedMessage id="herramientas-banner-table-width" />
          </th>
          <th scope="col">
            <FormattedMessage id="herramientas-banner-table-height" />
          </th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        {data.length > 0 && (
          <>
            {data.map((item, index) => {
              return (
                <tr key={index} className={(index + 1) % 2 ? 'impar' : 'par'}>
                  <td>{item.ID}</td>
                  <td>{item.NAME}</td>
                  <td>
                    {parseInt(item.ID_CATEGORY) === 0 ? (
                      <FormattedMessage id="promo-banner-banner-name" />
                    ) : getLang() === 'en' ? (
                      `Prom. sp - ${item.category.NAME_EN}`
                    ) : (
                      `Prom. Esp - ${item.category.NAME_ES}`
                    )}
                  </td>
                  <td>{item.WIDTH}</td>
                  <td>{item.HEIGHT}</td>
                  <td>
                    <div className="button-content">
                      <Button blue onClick={(e) => showImgeModal(item.URL)}>
                        <FormattedMessage id="herramientas-banner-button-show" />
                      </Button>
                      <Button red onClick={(e) => remove(item.ID)}>
                        <FormattedMessage id="herramientas-banner-button-remove" />
                      </Button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </>
        )}
      </tbody>
    </table>
  );
};

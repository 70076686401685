import { useState } from 'react';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import OlecamsUsersService from 'shared/olecams-users-service';

// components
import Card from 'components/card';
import Button from 'components/button';
import IconData from 'components/icons/data';

//style
import '../index.scss';

const UpdatePassword = (props) => {
  const [pwd, setPwd] = useState('');
  const [repeatpwd, setRepeatPwd] = useState('');
  const [errorParameter, setErrorParameter] = useState(false);
  const [errorRequest, setErrorRequest] = useState(false);
  const [errorRepeatPassword, setErrorRepeatPassword] = useState(false);
  const [errorPassworFormat, setErrorPasswordFormat] = useState(false);
  const [errorSamePassword, setErrorSamePassword] = useState(false);

  const [loading, setLoading] = useState(false);

  const changepassword = async () => {
    if (pwd.length < 8 || !/[0-9]/.test(pwd) || !/[A-Z]/.test(pwd)) setErrorPasswordFormat(true);
    else if (pwd !== repeatpwd) {
      setErrorRepeatPassword(true);
      setErrorPasswordFormat(false);
    } else {
      setErrorRepeatPassword(false);
      setLoading(true);
      OlecamsUsersService.adminGeneratePwd(props.id, pwd)
        .then((data) => {
          if (data.status === 'Failed') {
            if (data.message === 'When some parameter is missing') setErrorParameter(true);
            if (data.message === 'changePassword Password cannot be the same as previous') setErrorSamePassword(true);
            if (data.message === 'Failed Update Password') setErrorRequest(true);
          } else {
            toast.success(<FormattedMessage id="herramientas-affiliate-change-password-succes" />);
            props.close(true);
          }
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <div className="register-banner-modal">
      <Card icon={<IconData />} title={<FormattedMessage id="herramientas-affiliate-change-password" />} modal closeModal={props.close}>
        <div className="banner-body">
          {errorParameter && (
            <div className="alert alert-danger" role="alert">
              <FormattedMessage id="herramientas-affiliate-error-request-parameter" />
            </div>
          )}
          {errorRequest && (
            <div className="alert alert-danger" role="alert">
              <FormattedMessage id="herramientas-affiliate-change-password-error-request" />
            </div>
          )}
          {errorRepeatPassword && (
            <div className="alert alert-danger" role="alert">
              <FormattedMessage id="herramientas-affiliate-change-password-error-repeat" />
            </div>
          )}
          {errorPassworFormat && (
            <div className="alert alert-danger" role="alert">
              <FormattedMessage id="herramientas-affiliate-change-password-error-format" />
            </div>
          )}
          {errorSamePassword && (
            <div className="alert alert-danger" role="alert">
              <FormattedMessage id="herramientas-affiliate-change-password-error-same" />
            </div>
          )}
          <form className="banner-modal">
            <div className="form-group">
              <label>
                <FormattedMessage id="herramientas-affiliate-password-new" />
              </label>
              <input type="password" className="form-control" name="pwd" value={pwd} onChange={(e) => setPwd(e.target.value)} />
            </div>
            <div className="form-group">
              <label>
                <FormattedMessage id="herramientas-affiliate-repeat-password" />
              </label>
              <input
                type="password"
                className="form-control"
                name="repeatpwd"
                value={repeatpwd}
                onChange={(e) => setRepeatPwd(e.target.value)}
              />
            </div>
          </form>
        </div>
        <div className="button-content">
          <Button rounded onClick={(e) => changepassword()} disabled={loading}>
            {loading ? (
              <FontAwesomeIcon icon={faSpinner} spin transform="left-4" size="lg" />
            ) : (
              <FormattedMessage id="herramientas-banner-register-save" />
            )}
          </Button>
          <Button rounded onClick={(e) => props.close()} red disabled={loading}>
            {loading ? (
              <FontAwesomeIcon icon={faSpinner} spin transform="left-4" size="lg" />
            ) : (
              <FormattedMessage id="herramientas-banner-register-cancel" />
            )}
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default UpdatePassword;

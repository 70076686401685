import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class Check extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { cursorDefault, cursorPointer, ...props } = this.props;
    return (
      <svg
        {...props}
        className={classNames(
          'icon',
          {
            cursorDefault: this.props.cursorDefault,
            cursorPointer: this.props.cursorPointer,
          },
          this.props.className
        )}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        version="1.1"
        width="24"
        height="24"
        viewBox="0 0 24 24">
        <path d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
      </svg>
    );
  }
}

Check.propTypes = {
  cursorDefault: PropTypes.bool,
  cursorPointer: PropTypes.bool,
};

Check.defaultProps = {
  cursorDefault: false,
  cursorPointer: false,
};

export default Check;

import request from './api-service';
const qs = require('qs');

const adminGet = (id) => {
  return request({
    method: 'get',
    url: `/admin/visa_users/get?id=${id}`,
  });
};

const adminList = (filters) => {
  let params = { filters };
  return request({
    method: 'get',
    url: '/admin/visa_users/list',
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });
};

const adminUpdate = (data) => {
  return request({
    method: 'put',
    url: '/admin/visa_users/update',
    data: { data },
  });
};

const adminSet = (data) => {
  return request({
    method: 'post',
    url: '/admin/visa_users/set',
    data: { data },
  });
};

const adminRecoverUser = (email) => {
  return request({
    method: 'post',
    url: '/admin/visa_users/recover',
    data: { email },
  });
};

const adminGeneratePassword = (email) => {
  return request({
    method: 'post',
    url: '/admin/visa_users/generate_password',
    data: { email },
  });
};

const VisaUserService = {
  adminGet,
  adminList,
  adminSet,
  adminUpdate,
  adminRecoverUser,
  adminGeneratePassword,
};

export default VisaUserService;

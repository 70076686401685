import request from './api-service';
import { obj2FormData } from 'libs/methods';
const qs = require('qs');

const adminGet = (id) => {
  return request({
    method: 'get',
    url: `/admin/olecams/credit-gift/get?id=${id}`,
  });
};

const adminList = (filters) => {
  let params = { filters };
  return request({
    method: 'get',
    url: '/admin/olecams/credit-gift/list',
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });
};

const adminSet = (data) => {
  return request({
    method: 'post',
    url: '/admin/olecams/credit-gift/set',
    data: obj2FormData({ data: data }),
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

const adminUpdate = (data) => {
  return request({
    method: 'put',
    url: '/admin/olecams/credit-gift/update',
    data: { data },
  });
};

const adminBatch = (data, total) => {
  return request({
    method: 'post',
    url: '/admin/olecams/credit-gift/batch',
    data: obj2FormData({ total, data }),
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

const CreditGiftService = {
  adminGet,
  adminSet,
  adminList,
  adminUpdate,
  adminBatch,
};

export default CreditGiftService;

import { useState } from 'react';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';

//translate
import translate from 'i18n/translate';

import UserService from 'shared/user-service';

//Component
import Card from 'components/card';
import Button from 'components/button';
import IconHerramienta from 'components/icons/herramienta';

const GenerateKey = () => {
  const [key, setKey] = useState(null);
  const intl = useIntl();

  const generateBtn = () => {
    if (window.confirm(intl.formatMessage({ id: 'herramientas-confirm-alert' }))) {
      UserService.generateKey().then((responseData) => {
        if (responseData.status !== 'Failed') setKey(responseData.message);
        else toast.error(translate('herramientas-affiliate-key-error-request'));
      });
    }
  };

  return (
    <div className="herramientas-administrativas-content">
      <Card icon={<IconHerramienta color="#4b5056" />} title={translate('herramientas-affiliate-key-name')}>
        <div className="banner-header-content key">
          <Button superBlue onClick={generateBtn}>
            {translate('herramientas-affiliate-generate-key-button')}
          </Button>
          {key && <h2>Key: {key}</h2>}
        </div>
      </Card>
    </div>
  );
};

export default GenerateKey;

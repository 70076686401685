import { Card, Col, Row, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

import UserIcon from 'components/icons/olecams/whiteLabels/userIcon';

import { hexToHSL } from 'libs/methods';

// SCSS
import './preview.scss';
import { FormattedMessage } from 'react-intl';

const Preview = ({ logo, color, id_edit }) => {
  const barStyle = {
    borderBottom: `2px solid ${color}`,
  };

  const color_light = `hsl(${hexToHSL(color).h},${hexToHSL(color).s + '%'},calc(${hexToHSL(color).l + '%'} + 10%))`;
  const color_darker = `hsl(${hexToHSL(color).h},${hexToHSL(color).s + '%'},calc(${hexToHSL(color).l + '%'} - 30%))`;

  const buttonStyle = {
    backgroundImage: `linear-gradient( 180deg, ${color_light}, ${color} )`,
    color: color_darker,
  };
  return (
    <Row className="mb-3">
      <Col>
        <Card className="mb-2">
          <Card.Header>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id={`tooltip-top`}>
                  <FormattedMessage id="promo-wl-preview-desc" />
                </Tooltip>
              }>
              <span>
                Preview <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
              </span>
            </OverlayTrigger>
          </Card.Header>

          <Card.Body>
            <div className="body-wl-preview wl-preview">
              <div id="layout-wrapper" className="">
                <div className="sticky-header">
                  <header className="site-header" style={barStyle}>
                    <div className="wrapper">
                      <div className="main-navbar">
                        {!logo && (
                          <img
                            className="logo"
                            src="https://recursos.oletraffic.com/olecams/marcas_blancas/images/16/logo.svg"
                            alt="Logo"
                            loading="lazy"
                          />
                        )}
                        {logo && !id_edit && (
                          <img
                            style={{ maxWidth: '350px', maxHeight: '150px' }}
                            src={URL.createObjectURL(logo)}
                            className={'img-preview'}
                            alt="Logo"
                            loading="lazy"
                          />
                        )}
                        {logo && id_edit && (
                          <img
                            style={{ maxWidth: '350px', maxHeight: '150px' }}
                            src={logo}
                            className={'img-preview'}
                            alt="Logo"
                            loading="lazy"
                          />
                        )}
                        <div className="spacer"></div>
                        <UserIcon color={color} />
                        <button style={buttonStyle} className="ml-3 button button--rounded" tag="button">
                          <FormattedMessage id="promo-button-preview-wl" />
                        </button>
                      </div>
                    </div>
                  </header>
                </div>
                <div className="content-with-sidebar">
                  <div className="sidebar-space">
                    <div className="sidebar-overlay"></div>
                    <div className="sidebar">
                      <ul className="main-filters">
                        <li>
                          <header>█████████</header>
                          <ul>
                            <li className="sidebar-filter segment">
                              <div className="link">
                                <span className="label">█████████</span>
                              </div>
                            </li>
                            <li className="sidebar-filter segment">
                              <div className="link">
                                <span className="label">█████████</span>
                              </div>
                            </li>
                            <li className="sidebar-filter segment">
                              <div className="link">
                                <span className="label">█████████</span>
                              </div>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="content-space">
                    {/* Roomlist */}
                    <section className="rooms-list">
                      <div className="breadcrumb-bar">
                        <h1>█████████</h1>
                      </div>
                      <div className="row row-roomlist">
                        <div className="col-6 col-sm-4 col-lg-3 col-xxl-2">
                          <article>
                            <div title="Model" className="room-item" mode="private" href="#">
                              <div className="corner top left"></div>
                              <div className="corner top right"></div>
                              <div className="corner bottom left right">
                                <div className="room-mode" data-mode="private"></div>
                              </div>
                            </div>
                          </article>
                        </div>
                        <div className="col-6 col-sm-4 col-lg-3 col-xxl-2">
                          <article>
                            <div title="Model" className="room-item" mode="private" href="#">
                              <div className="corner top left"></div>
                              <div className="corner top right"></div>
                              <div className="corner bottom left right">
                                <div className="room-mode" data-mode="private"></div>
                              </div>
                            </div>
                          </article>
                        </div>
                        <div className="col-6 col-sm-4 col-lg-3 col-xxl-2">
                          <article>
                            <div title="Model" className="room-item" mode="private" href="#">
                              <div className="corner top left"></div>
                              <div className="corner top right"></div>
                              <div className="corner bottom left right">
                                <div className="room-mode" data-mode="private"></div>
                              </div>
                            </div>
                          </article>
                        </div>
                        <div className="col-6 col-sm-4 col-lg-3 col-xxl-2">
                          <article>
                            <div title="Model" className="room-item" mode="private" href="#">
                              <div className="corner top left"></div>
                              <div className="corner top right"></div>
                              <div className="corner bottom left right">
                                <div className="room-mode" data-mode="private"></div>
                              </div>
                            </div>
                          </article>
                        </div>
                        <div className="col-6 col-sm-4 col-lg-3 col-xxl-2">
                          <article>
                            <div title="Model" className="room-item" mode="private" href="#">
                              <div className="corner top left"></div>
                              <div className="corner top right"></div>
                              <div className="corner bottom left right">
                                <div className="room-mode" data-mode="private"></div>
                              </div>
                            </div>
                          </article>
                        </div>
                        <div className="col-6 col-sm-4 col-lg-3 col-xxl-2">
                          <article>
                            <div title="Model" className="room-item" mode="private" href="#">
                              <div className="corner top left"></div>
                              <div className="corner top right"></div>
                              <div className="corner bottom left right">
                                <div className="room-mode" data-mode="private"></div>
                              </div>
                            </div>
                          </article>
                        </div>
                      </div>
                    </section>
                    {/* Roomlist */}
                  </div>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default Preview;

import { useState } from 'react';
import { Card, Col, Form, Row, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage } from 'react-intl';

const Legal = ({ gtm, ga, setGTM, setGA }) => {
  const [selectedOption, setSelectedOption] = useState(ga ? 'GA' : 'GTM');

  const handleOptionChange = (e) => {
    const { value } = e.target;
    setSelectedOption(value);
    setGTM('');
    setGA('');
  };

  return (
    <Card className="mb-3">
      <Card.Header>
        <FormattedMessage id="herramientas-analitics" />
      </Card.Header>
      <Card.Body>
        <Row>
          <Col xl>
            <Form.Check
              type="radio"
              inline
              value="GTM"
              label="Google Tag Manager"
              name="groupAnalytics"
              checked={selectedOption === 'GTM'}
              id="gtm"
              onChange={handleOptionChange}
            />
            <Form.Check
              type="radio"
              inline
              value="GA"
              label="Google Analytics"
              name="groupAnalytics"
              checked={selectedOption === 'GA'}
              onChange={handleOptionChange}
              id="ga"
            />
          </Col>
          <Col xl>
            {selectedOption === 'GTM' && (
              <Form.Group as={Row} className="mb-3" controlId="formOwnnerName">
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-top`}>
                      <FormattedMessage id="herramientas-google-tag-manager-explanation" />
                    </Tooltip>
                  }>
                  <Form.Label column sm="6" className="font-weight-bold">
                    <span className="text">Google Tag Manager</span>
                    <span className="help-icon float-right">
                      <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
                    </span>
                  </Form.Label>
                </OverlayTrigger>
                <Col sm="6">
                  <Form.Control value={gtm} name="gtm" placeholder="GTM-XXXXXXX" onChange={(e) => setGTM(e.target.value)} />
                </Col>
              </Form.Group>
            )}

            {selectedOption === 'GA' && (
              <Form.Group as={Row} className="mb-3" controlId="formOwnerEmail">
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-top`}>
                      <FormattedMessage id="herramientas-google-analitics-explanation" />
                    </Tooltip>
                  }>
                  <Form.Label column sm="6" className="font-weight-bold">
                    <span className="text">Google Analitics 4</span>
                    <span className="help-icon float-right">
                      <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
                    </span>
                  </Form.Label>
                </OverlayTrigger>
                <Col sm="6">
                  <Form.Control value={ga} name="ga" placeholder="G-XXXXXX" onChange={(e) => setGA(e.target.value)} />
                </Col>
              </Form.Group>
            )}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default Legal;

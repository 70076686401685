import { useHistory } from 'react-router';
import { getAuth } from 'libs/cookies';

//Component
import StatsOCPhone from './Olecams/Phone';
import StatsOCSms from 'templates/adminTools/Olecams/Sms';
import Layout from 'components/layout';

const Stats = ({ match }) => {
  const history = useHistory();
  if (getAuth().admin !== 1) history.push('/');

  return (
    <Layout>
      {match.path === '/admintools/stats/olecams/phone' && <StatsOCPhone />}
      {match.path === '/estadisticas/olecams/phone' && <StatsOCPhone />}
      {match.path === '/admintools/stats/olecams/sms' && <StatsOCSms />}
    </Layout>
  );
};

export default Stats;

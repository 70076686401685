import DatePicker, { registerLocale } from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage } from 'react-intl';
import { Form } from 'react-bootstrap';

//translate
import es from 'date-fns/locale/es';

// Libs
import { getLang } from 'libs/cookies';

import Button from 'components/button';
import IconCalendar from 'components/icons/calendar';

const BrokersForm = ({ filters, handleChangeDate, getSummaryBtn, test_studio, setTestStudio }) => {
  registerLocale('es', es);

  return (
    <form className={`search-wrapper`}>
      <div className="first-filters d-flex align-center">
        <div className="form-group mr-3 calendar sp">
          <label>
            <FormattedMessage id="statistics-form-date" />
          </label>
          <div className="form-control">
            <DatePicker
              selected={filters.date}
              onChange={(date) => handleChangeDate(date)}
              locale={getLang()}
              dateFormat="MM/yyyy"
              showMonthYearPicker
              showFullMonthYearPicker
              showTwoColumnMonthYearPicker
              id="calendar-vce"
              popperPlacement="bottom"
              maxDate={new Date()}
              minDate={new Date('2018/11/01')}
            />
          </div>
          <IconCalendar id="calendar-vce" />
        </div>
        <div className="form-group mr-3">
          <label htmlFor="test_studio">
            <FormattedMessage id="global-test-studio" />
          </label>
          <Form.Check
            type="checkbox"
            name="test_studio"
            id="test_studio"
            //onChange={e => this.props.handleRadioOnChange(item, 'content')}
            checked={test_studio}
            onChange={(e) => setTestStudio((current) => !current)}
          />
        </div>
      </div>
      <div className="buttons-content">
        <Button rounded onClick={getSummaryBtn}>
          <FontAwesomeIcon icon={faSearch} className="mr-2" /> <FormattedMessage id="global-send" />
        </Button>
      </div>
    </form>
  );
};

export default BrokersForm;

import { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { FormattedMessage, useIntl } from 'react-intl';

import { isEmpty } from 'libs/lodashAlt';

import translate from 'i18n/translate';

import OlecamsUsersService from 'shared/olecams-users-service';

// components
import Card from 'components/card';
import Button from 'components/button';
import Loading from 'components/loading';

import FieldsValidated from './FieldsValidated';
import UserStatus from './UserStatus';
import UpdateCredits from './updateCredits';
import Password from './password';

// icons
import IconData from 'components/icons/data';
import IconCheck from 'components/icons/check';

//style
import '../index.scss';

const UserDetail = (props) => {
  let loading = useSelector((state) => state.loading.loading);
  const { formatMessage } = useIntl();
  //const dispatch = useDispatch();

  //const { userId } = props;

  const [showChangePassword, setShowChangePassword] = useState(false);
  const [showUpdateCredits, setShowUpdateCredits] = useState(false);
  const userData = props.data;

  /* const getData = useCallback(
    (userId) => {
      //dispatch({ type: 'SET_LOADING', loading: true });
      getUserData(auth.token, userId).then((response) => {
        response.data.status === 'Accepted' && setUserData(response.data.message);
      });
    },
    [auth.token]
  ); */

  //useEffect(() => getData(userId), [getData, userId]);

  const close = (reload = false) => {
    //if (reload) getData(userId);

    setShowChangePassword(false);
    setShowUpdateCredits(false);
  };

  const activateDeactivateUser = (id, status) => {
    let msg = 'herramientas-olecams-user-deactive-alert';
    const active = status === '0' ? -1 : 0;
    if (status !== '0') msg = 'herramientas-olecams-user-active-alert';

    if (window.confirm(formatMessage({ id: msg }))) {
      OlecamsUsersService.userManagement({ field: 'ACTIVE', value: active, user_id: id }).then((data) => {
        if (data.message === 1) {
          toast.success(formatMessage({ id: 'global-update-success' }));
          props.close(true);
        } else {
          toast.error(formatMessage({ id: 'herramientas-olecams-user-error-request-ban' }));
        }
      });
    }
  };

  const flagImage = (registerCountry) => {
    return registerCountry !== '' ? `${process.env.PUBLIC_URL}/flags/${registerCountry.toLowerCase()}.png` : '';
  };

  if (loading) return <Loading />;
  return (
    <>
      {!isEmpty(userData) && (
        <div className="oc-users-modal">
          {!showUpdateCredits && !showChangePassword && (
            <Card icon={<IconData />} title={<FormattedMessage id="global-user-details" />} modal closeModal={props.close}>
              <div className="user-detail-modal-body">
                <form className="banner-modal">
                  <div className="container">
                    <div className="row">
                      <div className="col">
                        <div className="form-group">
                          <label>ID</label>
                          <input type="text" className="form-control" name="id" disabled value={userData.ID} />
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-group">
                          <label>Nick</label>
                          <input type="text" className="form-control" name="nick" disabled value={userData.NICK} />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col">
                        <div className="form-group">
                          <label>Email</label>
                          <input type="text" className="form-control" name="email" disabled value={userData.EMAIL} />
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-group">
                          <label>
                            <FormattedMessage id="global-active" />
                          </label>
                          <UserStatus status={userData.ACTIVE} />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col">
                        <div className="form-group">
                          <label>
                            <FormattedMessage id="global-date-register" />
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="signin_date"
                            disabled
                            value={new Date(userData.SIGNIN_DATE).toLocaleDateString()}
                          />
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-group">
                          <label>
                            <FormattedMessage id="global-olecams-user-last-date" />
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="last_date"
                            disabled
                            value={new Date(userData.LAST_DATE).toLocaleDateString()}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col">
                        <div className="form-group">
                          <label>
                            <FormattedMessage id="global-validated-email" />
                          </label>
                          {userData.EMAIL_VALIDATED === 1 && <IconCheck cursorDefault />}
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-group">
                          <label>Token</label>
                          <input type="text" className="form-control" name="token" disabled value={userData.TOKEN} />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col">
                        <div className="form-group">
                          <label>
                            <FormattedMessage id="global-optin-legal" />
                          </label>
                          <FieldsValidated status={userData.OPTIN_LEGAL} />
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-group">
                          <label>
                            <FormattedMessage id="global-optin-communications" />
                          </label>
                          <FieldsValidated status={userData.OPTIN_COMMUNICATIONS} />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col">
                        <div className="form-group">
                          <label>
                            <FormattedMessage id="global-last-ip" />
                          </label>
                          <input type="text" className="form-control" name="last_ip" disabled value={userData.LAST_IP} />
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-group">
                          <label>
                            <FormattedMessage id="global-register-ip" />
                          </label>
                          <input type="text" className="form-control" name="register_ip" disabled value={userData.REGISTER_IP} />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col">
                        <div className="form-group">
                          <label>
                            <FormattedMessage id="statistics-table-credit" />
                          </label>
                          <input type="text" className="form-control" name="credits" disabled value={userData.CREDITS} />
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-group">
                          <label>
                            <FormattedMessage id="global-epoch-id" />
                          </label>
                          <input type="text" className="form-control" name="register_ip" disabled value={userData.EPOCH_ID} />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col">
                        <div className="form-group">
                          <label>
                            <FormattedMessage id="statistics-table-first" />
                          </label>
                          <FieldsValidated status={userData.FIRST_PURCHASE} />
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-group">
                          <label>
                            <FormattedMessage id="statistics-form-id-afi" />
                          </label>
                          <input type="text" className="form-control" name="affiliate_id" disabled value={userData.AFFILIATE_ID} />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col">
                        <div className="form-group">
                          <label>AFNO</label>
                          <input type="text" className="form-control" name="afno" disabled value={userData.AFNO} />
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-group">
                          <label>
                            <FormattedMessage id="global-register-country" />
                          </label>
                          {userData.REGISTER_COUNTRY !== '' && (
                            <span className="flag">
                              <img src={flagImage(userData.REGISTER_COUNTRY)} alt={userData.REGISTER_COUNTRY} />
                            </span>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col">
                        <div className="form-group">
                          <label>
                            <FormattedMessage id="global-default-payment-method" />
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="default_payment_method"
                            disabled
                            value={userData.DEFAULT_PAYMENT_METHOD}
                          />
                        </div>
                      </div>
                    </div>

                    {userData.bans.length > 0 && (
                      <div className="row">
                        <div className="col">
                          <div className="form-group">
                            <label>
                              <FormattedMessage id="global-ban-reason" />
                            </label>
                            <input type="text" className="form-control" name="historyBanReason" disabled value={userData.bans[0].REASON} />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </form>
              </div>
              <div className="button-content">
                <Button rounded onClick={() => setShowUpdateCredits(true)}>
                  <FormattedMessage id="global-update-credits" />
                </Button>
                <Button rounded onClick={() => setShowChangePassword(true)}>
                  <FormattedMessage id="herramientas-banner-table-new-password" />
                </Button>
                <Button rounded onClick={(e) => activateDeactivateUser(userData.ID, userData.ACTIVE)}>
                  {userData.ACTIVE === '0' ? <FormattedMessage id="global-deactive-user" /> : <FormattedMessage id="global-active" />}
                </Button>
                <Button rounded onClick={() => props.close()} red>
                  <FormattedMessage id="herramientas-banner-register-cancel" />
                </Button>
              </div>
            </Card>
          )}
          {showChangePassword && <Password close={close} id={userData.userId} />}
          {showUpdateCredits && <UpdateCredits close={close} id={userData.userId} />}
        </div>
      )}
    </>
  );
};

export default UserDetail;

const Pending = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="22" height="22" viewBox="0 0 22 22">
      <path
        fill={props.color}
        id="prefix__a"
        d="M10 18c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8zm0-18c2.652 0 5.196 1.054 7.071 2.929C18.946 4.804 20 7.348 20 10c0 5.523-4.477 10-10 10-5.53 0-10-4.5-10-10C0 4.477 4.477 0 10 0zm.5 5v5.25l4.5 2.67-.75 1.23L9 11V5h1.5z"
      />
    </svg>
  );
};
export default Pending;

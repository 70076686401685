import translate from 'i18n/translate';

import Layout from 'components/public-layout';

import './index.scss';

const Rates = () => {
  return (
    <Layout cssClass="rates">
      <section className="main">
        <div className="main-content">
          <div className="container">
            <div className="row">
              <div className="col">
                <h1>{translate('public-taxes-main-title')}</h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="values white-bg no_padding">
        <div className="container">
          <div className="thumbs-wrapper row">
            {/*
            <section className="pricing-boxes no_padding pago_email">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="pricing-wrapper">
                                <div className="pricing pricing-climb pago_email_tabla">
                                    <div className="pricing-title">
                                        <h3>{translate("public-taxes-countries")}</h3>
                                    </div>
                                    <div className="">
                                        <ul>
                                            <li><span className="limit">4.00€</span> <span className="price">Norway, Switzerland</span><div className="clearfix"></div></li>
                                            <li><span className="limit">3.00€</span> <span className="price">United States, Sweden, United Kingdom</span><div className="clearfix"></div></li>
                                            <li><span className="limit">2.50€</span> <span className="price">Denmark, Australia</span><div className="clearfix"></div></li>
                                            <li><span className="limit">2.00€</span> <span className="price">Canada, Germany, Czechia, Austria, Belgium, Estonia</span><div className="clearfix"></div></li>
                                            <li><span className="limit">2.00€</span> <span className="price">New Zealand, Netherlands, Italy, Slovakia</span><div className="clearfix"></div></li>
                                            <li><span className="limit">2.00€</span> <span className="price">Japan, Russia</span><div className="clearfix"></div></li>
                                            <li><span className="limit">1.00€</span> <span className="price">Ireland, Slovenia, Poland, Iceland, Latvia</span><div className="clearfix"></div></li>
                                            <li><span className="limit">1.00€</span> <span className="price">Greece, Spain</span><div className="clearfix"></div></li>
                                            <li><span className="limit">1.00€</span> <span className="price">Republic of Lithuania, Luxembourg, United Arab Emirates</span><div className="clearfix"></div></li>
                                            <li><span className="limit">0.50€</span> <span className="price">Romania, Hungary, Turkey, Cyprus, Bulgaria</span><div className="clearfix"></div></li>
                                            <li><span className="limit">0.50€</span> <span className="price">Croatia, Portugal, North Macedonia, Singapore, South Africa, Bahrain, Hashemite Kingdom of Jordan, Serbia, Argentina, Kazakhstan</span><div className="clearfix"></div></li>
                                            <li><span className="limit">0.25€</span> <span className="price">Ukraine, Belarus</span><div className="clearfix"></div></li>
                                            <li><span className="limit">0.10€</span> <span className="price">China, Mexico, Republic of Moldova</span><div className="clearfix"></div></li>
                                            <li><span className="limit">0.05€</span> <span className="price">{translate("public-taxes-rest-countries")}</span><div className="clearfix"></div></li>
                                        </ul>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </section>
            */}
            <div className="col-12 col-md-12 thumb">
              <div className="icon animate" data-anim-type="bounceInLeft" data-anim-delay="200">
                <picture>
                  <img
                    src="/images/icon-profit.png"
                    srcSet="/images/icon-profit.png 1x, /images/icon-profit@2x.png 2x, /images/icon-profit@3x.png 3x"
                    alt="Mejora tu profit"
                    className="img-fluid"
                  />
                </picture>
              </div>
              <div className="text">
                <span className="thumb-title">{translate('public-taxes-revenue-program')}</span>

                <span className="thumb-subtitle">
                  {translate('public-taxes-revenue-program-text')}
                  <br />
                  <em>{translate('public-taxes-revenue-program-subtitle')}</em>
                </span>
              </div>
            </div>
            <div className="col-12 col-md-12 thumb">
              <div className="icon animate" data-anim-type="bounceInLeft" data-anim-delay="200">
                <picture>
                  <img
                    src="/images/icon-motivate.png"
                    srcSet="/images/icon-motivate.png 1x, /images/icon-motivate@2x.png 2x, /images/icon-motivate@3x.png 3x"
                    alt="Ultimas tecnoloogías"
                    className="img-fluid"
                  />
                </picture>
              </div>
              <div className="text">
                <span className="thumb-title">{translate('public-taxes-recomendation-program')}</span>

                <span className="thumb-subtitle">
                  {translate('public-taxes-recomendation-program-text')}
                  <br />
                  <em>{translate('public-taxes-recomendation-program-subtitle')}</em>
                </span>
              </div>
            </div>

            <div className="col-12 col-md-12 thumb">
              <div className="icon animate" data-anim-type="bounceInLeft" data-anim-delay="200">
                <picture>
                  <img
                    src="/images/icon-effort.png"
                    srcSet="/images/icon-effort.png 1x, /images/icon-effort@2x.png 2x, /images/icon-effort@3x.png 3x"
                    alt="A toda máquina"
                    className="img-fluid"
                  />
                </picture>
              </div>
              <div className="text">
                <span className="thumb-title">{translate('public-taxes-program-email')}</span>

                <span className="thumb-subtitle">
                  {translate('public-taxes-program-email-text')}
                  <br />
                  <em>{translate('public-taxes-program-email-text-subtitle')}</em>
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default Rates;

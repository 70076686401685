import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Table, Form, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faEdit, faEraser, faShieldAlt } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage } from 'react-intl';

import Loading from 'components/loading';
import Button from 'components/button';

import NotifSuccess from 'components/icons/notificationSucces';
import CheckIcon from 'components/icons/check';
import CloseIcon from 'components/icons/close';

import './index.scss';

const WhiteLabelsTable = ({ data, statusSelect, statusOptions, deleteBtn, deleteCacheBtn }) => {
  const history = useHistory();
  let loading = useSelector((state) => state.loading.loading);

  const [showModal, setShowModal] = useState(false);
  const [validationURL, setValidationURL] = useState('');

  const editBtn = (id) => {
    history.push('/admintools/marcas-blancas/' + id);
  };

  const validationURLBtn = (url) => {
    setValidationURL(url);
    setShowModal(true);
    navigator.clipboard.writeText(validationURL);
  };

  const modalHandleClose = () => {
    setValidationURL('');
    setShowModal(false);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(validationURL);
    toast.success(
      <span>
        <NotifSuccess color="#ffffff" />
        <FormattedMessage id="global-copied" />
      </span>,
      { position: toast.POSITION.TOP_CENTER }
    );
  };

  if (loading) return <Loading />;
  return (
    <>
      <Table responsive="sm" striped bordered hover>
        <thead>
          <tr>
            <th>ID</th>
            <th>
              <FormattedMessage id="global-name" />
            </th>
            <th>
              <FormattedMessage id="global-domain" />
            </th>
            <th>
              <FormattedMessage id="statistics-form-id-afi" />
            </th>
            <th>
              <FormattedMessage id="statistics-table-afiliate" />
            </th>
            <th>Tracking ID</th>
            <th>
              <FormattedMessage id="global-active" />
            </th>
            <th>
              <FormattedMessage id="global-status" />
            </th>
            <th className="text-center">Hash de Validación</th>
            <th className="text-center">
              <FormattedMessage id="herramientas-banner-button-update" />
            </th>
            <th className="text-center">
              <FormattedMessage id="herramientas-banner-button-remove" />
            </th>
            <th className="text-center">Borrar Cache</th>
          </tr>
        </thead>
        <tbody>
          {data.data.map((item, index) => {
            let disabledBtns = false;
            //if (item.ID === 16) disabledBtns = true;
            return (
              <tr key={index} className={item.STATUS.toLowerCase()}>
                <td>{item.ID}</td>
                <td>{item.NAME}</td>
                <td>
                  <Link to={{ pathname: 'https://' + item.DOMAINS[0].URL }} target="_blank" rel="noopener noreferrer">
                    {item.DOMAINS[0].URL}
                  </Link>
                </td>
                <td>{item.AFFILIATE_ID}</td>
                <td>{item.AFFILIATE}</td>
                <td>{item.AFNO}</td>
                <td>{item.ACTIVE ? <CheckIcon cursorDefault /> : <CloseIcon cursorDefault />}</td>
                <td>
                  <Form.Control
                    as="select"
                    disabled={disabledBtns}
                    custom
                    onChange={(e) => statusSelect(item.ID, e.target.value)}
                    name="STATUS"
                    defaultValue={item.STATUS}>
                    {statusOptions &&
                      statusOptions.length > 0 &&
                      statusOptions.map((option, index) => {
                        return (
                          <option key={index} value={option.value}>
                            {option.title}
                          </option>
                        );
                      })}
                  </Form.Control>
                </td>
                <td className="text-center">
                  <Button
                    rounded
                    minWidth={false}
                    onClick={(e) => validationURLBtn('https://' + item.DOMAINS[0].URL + '?hash=' + item.VALIDATION_HASH)}>
                    <FontAwesomeIcon icon={faShieldAlt} className="ml-1" />
                  </Button>
                </td>
                <td className="text-center">
                  <Button disabled={disabledBtns} rounded minWidth={false} onClick={(e) => editBtn(item.ID)}>
                    <FontAwesomeIcon icon={faEdit} className="ml-1" />
                  </Button>
                </td>

                <td className="text-center">
                  <Button disabled={disabledBtns || item.ID === 16} rounded red minWidth={false} onClick={(e) => deleteBtn(item.ID)}>
                    <FontAwesomeIcon icon={faEraser} className="ml-1" />
                  </Button>
                </td>
                <td className="text-center">
                  <Button
                    disabled={item.STATUS === 'PRODUCTION' ? true : false}
                    rounded
                    red
                    minWidth={false}
                    onClick={(e) => deleteCacheBtn(item.ID)}>
                    <FontAwesomeIcon icon={faEraser} className="ml-1" />
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Modal show={showModal} onHide={modalHandleClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>URL de Validación</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Control as="textarea" disabled value={validationURL} rows={4} />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button rounded red variant="secondary" onClick={modalHandleClose}>
            <FormattedMessage id="herramientas-banner-register-cancel" />
          </Button>
          <Button rounded green variant="primary" onClick={copyToClipboard}>
            <FormattedMessage id="promo-copy-code-clipboard" />
            <FontAwesomeIcon icon={faSave} className="ml-1" />
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default WhiteLabelsTable;

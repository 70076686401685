import { useEffect, useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import AsyncSelect from 'react-select/async';
import { Col, Form, Row } from 'react-bootstrap';

import { debounce } from 'libs/lodashAlt';

import OlecamsUsersService from 'shared/olecams-users-service';

const UserAssignment = ({ data, handleChange }) => {
  const { formatMessage } = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const debouncedPromiseOptions = useCallback(
    debounce((inputValue, callback) => {
      promiseOptions(inputValue).then((options) => callback(options));
    }, 250),
    []
  );

  const promiseOptions = (inputValue) => {
    return new Promise((resolve) => {
      setIsLoading(true);

      let filters = { active: '', search: inputValue };
      let results = [];
      OlecamsUsersService.adminList({ page: 1, perPage: 20, filters })
        .then((responseData) => {
          if (responseData.status === 'Accepted') {
            responseData.message.data.map((item, index) => {
              const nick = item.NICK ? item.NICK : 'sin nick';
              return results.push({
                value: item.ID,
                label: item.ID + ' - ' + nick + ' (' + item.EMAIL + ')',
              });
            });
            resolve(results);
          } else resolve([]);
        })
        .finally(() => setIsLoading(false));
    });
  };

  const onChange = (option) => {
    setSelectedOption(option);
    handleChange({
      ...data,
      PROFILE_ID: option ? option.value : '',
    });
  };

  useEffect(() => {
    if (data.PROFILE_ID > 0) {
      OlecamsUsersService.adminGet(data.PROFILE_ID).then((responseData) => {
        if (responseData.status === 'Accepted') {
          const nick = responseData.message.data.NICK ? responseData.message.data.NICK : 'sin nick';
          setSelectedOption({
            label: responseData.message.data.ID + ' - ' + nick + ' (' + responseData.message.data.EMAIL + ')',
            value: responseData.message.data.ID,
          });
        }
      });
    }
  }, [data.PROFILE_ID]);

  const LoadingMessage = (props) => {
    return (
      <div>
        <div {...props.innerProps} style={props.getStyles('loadingMessage', props)}>
          <FormattedMessage id="global-loading" />
        </div>
      </div>
    );
  };

  return (
    <Form.Group as={Row} className="mb-3 required chips" controlId="formUseLimit">
      <Form.Label column sm="3" className="font-weight-bold">
        <FormattedMessage id="global-olecams-user" />
      </Form.Label>
      <Col sm="9">
        <AsyncSelect
          styles={{
            control: (styles) => ({
              ...styles,
              borderColor: '#4b5056',
              color: '#495057',
              fontWeight: '400',
              fontSize: '1rem',
            }),
          }}
          value={selectedOption}
          defaultValue={selectedOption}
          placeholder="Selecciona ..."
          loadOptions={debouncedPromiseOptions}
          onChange={onChange}
          isClearable
          noOptionsMessage={() => formatMessage({ id: 'promo-no-results-filters' })}
          isLoading={isLoading}
          components={{ LoadingMessage }}
        />
      </Col>
    </Form.Group>
  );
};

export default UserAssignment;

import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Select from 'react-select';

import WhiteLabelsService from 'shared/white-labels-service';

const SelectWhiteLabel = ({ whitelabelId, handleChange }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [whiteLabels, setWhiteLabels] = useState([]);
  const { formatMessage } = useIntl();

  const selectOnChange = (option) => {
    handleChange({
      target: {
        name: 'whitelabel',
        value: option ? option.value : '',
      },
    });

    setSelectedOption(option);
  };

  useEffect(() => {
    // Get All Whitelabels and if there is one asign the selected option
    WhiteLabelsService.adminList({ page: 1, perPage: 9999 }).then((responseData) => {
      if (responseData.status === 'Accepted') {
        const wl = responseData.message.data.map((item) => {
          return { value: item.ID, label: item.ID + ' - ' + item.NAME + ' (' + item.MODE + ')', affiliate: item.AFFILIATE_ID };
        });
        setWhiteLabels(wl);
        if (whitelabelId > 0) setSelectedOption(wl.find((item) => item.value === whitelabelId));
      }
    });
  }, [whitelabelId]);

  return (
    <div className="form-group">
      <label>
        <FormattedMessage id="promo-white-brand-title" />
      </label>
      <Select
        styles={{
          control: (styles) => ({
            ...styles,
            borderColor: '#4b5056',
            color: '#495057',
            fontWeight: '400',
            fontSize: '1rem',
          }),
        }}
        placeholder="Selecciona ..."
        value={selectedOption}
        defaultValue={selectedOption}
        onChange={selectOnChange}
        options={whiteLabels}
        isClearable
        noOptionsMessage={() => formatMessage({ id: 'promo-no-results-filters' })}
      />
    </div>
  );
};

export default SelectWhiteLabel;

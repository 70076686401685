import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { Container, Col, Row, Card, Table } from 'react-bootstrap';
import { faSearch, faSpinner, faPlus, faTools, faEdit, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';

import CreditPacksService from 'shared/credit-packs-service';

import Pagination from 'components/pagination';
import Button from 'components/button';

const CreditPacks = () => {
  const loading = useSelector((state) => state.loading.loading);
  const history = useHistory();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const [lastPage, setLastPage] = useState(1);
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({
    search: '',
  });

  useEffect(() => {
    setData([]);
    dispatch({ type: 'SET_LOADING', loading: true });
    CreditPacksService.adminList({ page, perPage, filters })
      .then((responseData) => {
        responseData.status === 'Failed' && toast.error('No se pudo cargar la información');
        if (responseData.status === 'Accepted') {
          setData(responseData.message);
          setLastPage(responseData.message.last_page);
        }
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
  }, [page, perPage, dispatch]);

  /* PAGINATOR */
  const paginate = (e) => setPage(e.target.value);

  const handleChangeNumberOfColum = (e) => {
    setPerPage(e.target.value);
    setPage(1);
  };

  const nextPage = (_page) => {
    _page++;
    _page <= lastPage && setPage(_page);
  };

  const prevPage = (_page) => {
    _page--;
    _page > 0 && setPage(_page);
  };
  // END PAGINATOR

  const handleOnChange = (e) => {
    const { name, value } = e.target;

    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const sendSubmit = (e) => {
    e.preventDefault();
    setData([]);
    setPage(1);
    dispatch({ type: 'SET_LOADING', loading: true });
    CreditPacksService.adminList({ page: 1, perPage, filters })
      .then((responseData) => {
        responseData.status === 'Failed' && toast.error('No se pudo cargar la información');
        if (responseData.status === 'Accepted') {
          setData(responseData.message);
          setLastPage(responseData.message.last_page);
        }
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
  };

  return (
    <Container className="mw-100 ha-discounts">
      <Row>
        <Col sm="12">
          <Card className="mb-2">
            <Card.Header>Credit Packs</Card.Header>
            <Card.Body>
              <Row className="justify-content-between">
                <Col>
                  <form className={`search-wrapper`} onSubmit={sendSubmit}>
                    <div className="first-filters d-flex align-center">
                      <div className="form-group mr-3">
                        <label>
                          <FormattedMessage id="global-search" />
                        </label>
                        <input className="form-control" name="search" value={filters.search} onChange={handleOnChange} />
                      </div>
                      <div className="form-group mr-3">
                        <label>Negocio</label>
                        <select className="form-control" name="business_model" value={filters.business_model} onChange={handleOnChange}>
                          <option value="">Todos</option>
                          <option value="anonymous">Anónimo</option>
                          <option value="registered">Registrado</option>
                        </select>
                      </div>
                    </div>
                    <div className="buttons-content">
                      <Button rounded type="submit" disabled={loading}>
                        {loading ? (
                          <FontAwesomeIcon icon={faSpinner} spin transform="left-4" size="lg" />
                        ) : (
                          <>
                            <FontAwesomeIcon icon={faSearch} className="mr-2" /> <FormattedMessage id="global-send" />
                          </>
                        )}
                      </Button>
                    </div>
                  </form>
                </Col>
                <Col className="text-right align-self-center">
                  <Button blue rounded className="mb-2" onClick={() => history.push('/admintools/credit-packs/nuevo')}>
                    <FontAwesomeIcon icon={faPlus} className="mr-2" />
                    <FormattedMessage id="global-new" />
                  </Button>
                  <br />
                </Col>
              </Row>
              {!loading && (
                <>
                  <hr />
                  <Row>
                    <Col sm="12">
                      {data && data.data && data.total > 0 ? <DataTable data={data.data} /> : <>No hay packs de créditos</>}
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col>
                      <Pagination
                        handleChangeNumberOfColum={handleChangeNumberOfColum}
                        pager={{ page: page, items: perPage, pages: lastPage }}
                        paginate={paginate}
                        prevPage={prevPage}
                        nextPage={nextPage}
                        total={data.total}
                      />
                    </Col>
                  </Row>
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

const DataTable = ({ data }) => {
  const history = useHistory();
  return (
    <div className="table-content">
      <Table responsive="sm" striped bordered hover>
        <thead>
          <tr>
            <th>Id</th>
            <th>
              <FormattedMessage id="global-description" />
            </th>
            <th>
              <FormattedMessage id="statistics-table-import" />
              (€)
            </th>
            <th>
              <FormattedMessage id="statistics-table-import" /> ($)
            </th>
            <th>Importe Especial (€)</th>
            <th>Importe Especial ($)</th>
            <th>ID Artículo</th>
            <th>Anónimo</th>
            <th>Registrado</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => {
            return (
              <tr
                key={index}
                className="cursorPointer"
                onClick={(e) => {
                  e.preventDefault();
                  history.push('/admintools/credit-packs/' + item.ID);
                }}>
                <td>{item.ID}</td>
                <td>{'Páquete de ' + item.CREDITS + ' créditos'}</td>
                <td>{item.AMOUNT + ' €'}</td>
                <td>{item.AMOUNT_USD + ' $'} </td>
                <td>{item.AMOUNT_SPECIAL && item.AMOUNT_SPECIAL + ' €'}</td>
                <td>{item.AMOUNT_USD_SPECIAL && item.AMOUNT_USD_SPECIAL + ' $'}</td>
                <td>{item.ARTICLE_ID}</td>
                <td>{item.ANONYMOUS === 1 && <FontAwesomeIcon icon={faCheck} />}</td>
                <td>{item.REGISTERED === 1 && <FontAwesomeIcon icon={faCheck} />}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default CreditPacks;

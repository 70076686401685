import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import Select from 'react-select';

const SelectRegisteredFidelity = ({ value, onChange, ...props }) => {
  const fidelityLevels = [];
  for (let i = 1; i <= 10; i++) {
    fidelityLevels.push({ value: i, label: <>Nivel {i}</> });
  }

  const options = [
    //{ value: '', label: <FormattedMessageWrapper id="common.all-values" /> },
    /* {
      label: 'Sin fidelidad',
      options: [
        {
          value: 0,
          label: <FormattedMessageWrapper id="common.no-fidelity" />,
        },
      ], // Agrupación de los niveles de fidelidad
    }, */
    {
      label: 'Niveles de fidelidad',
      options: fidelityLevels, // Agrupación de los niveles de fidelidad
    },
    {
      value: 'select_all',
      label: 'Seleccionar todos los niveles',
    },
  ];

  const [selectedOptions, setSelectedOptions] = useState(null);

  useEffect(() => {
    if (Array.isArray(value) && value.length > 0) {
      const selected = options
        .flatMap((option) => (option.options ? option.options : option)) // Asegurarse de incluir opciones de grupos
        .filter((option) => value.includes(option.value));
      setSelectedOptions(selected);
    }
  }, [value]);

  const selectOnChange = (selectedArray) => {
    const hasSelectAll = selectedArray.some((opt) => opt.value === 'select_all');

    let updatedSelection = [...selectedArray];

    if (hasSelectAll) {
      // Añadir todos los niveles de fidelidad si no están ya en las opciones seleccionadas
      fidelityLevels.forEach((level) => {
        if (!updatedSelection.find((opt) => opt.value === level.value)) {
          updatedSelection.push(level);
        }
      });

      // Remover la opción "select_all" de la selección actual
      updatedSelection = updatedSelection.filter((opt) => opt.value !== 'select_all');
    }

    // Actualizar el valor en el onChange y el estado
    onChange({
      target: {
        name: 'registered_fidelity',
        value: updatedSelection.map((opt) => opt.value),
      },
    });
    setSelectedOptions(updatedSelection);
  };

  return (
    <div className="form-group">
      <label>Tipo de Fidelidad Registrado</label>
      <Select
        value={selectedOptions}
        options={options}
        onChange={selectOnChange}
        isMulti
        {...props}
        placeholder="Selecciona ..."
        styles={{
          control: (styles) => ({
            ...styles,
            borderColor: '#4b5056',
            color: '#495057',
            fontWeight: '400',
            fontSize: '1rem',
          }),
        }}
      />
    </div>
  );
};

export default SelectRegisteredFidelity;

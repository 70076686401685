const initialState = {
  noticias: {},
  adminNoticias: {},
};

const NewsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_NOTICIAS':
      return Object.assign({}, state, {
        noticias: action.noticias,
      });
    case 'SET_ADMIN_NOTICIAS':
      return Object.assign({}, state, {
        adminNoticias: action.adminNoticias,
      });
    default:
      return state;
  }
};
export default NewsReducer;

import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'libs/lodashAlt';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Col, Row } from 'react-bootstrap';

import HomeService from 'shared/home-service';

//libs
import { replaceComa, formatPrice } from 'libs/methods';

//Component
import Layout from 'components/layout';
import StatsOlecamsIcon from 'components/icons/statsOlecams';
import OlecamsIcon from 'components/icons/olecams';
import Loading from 'components/loading';
import VideoChatIcon from 'components/icons/videoChat';
import SummaryIcon from 'components/icons/resum';
import CustomCard from 'components/card';

// style
import './index.scss';

const Home = () => {
  const dispatch = useDispatch();
  let loading = useSelector((state) => state.loading.loading);
  const { auth } = useSelector((state) => state.auth);
  const [summary, setSummary] = useState([]);

  const getData = useCallback(() => {
    dispatch({ type: 'SET_LOADING', loading: true });
    HomeService.summary()
      .then((responseData) => {
        responseData.status === 'Accepted' && setSummary(responseData.message);
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
  }, [dispatch]);

  useEffect(getData, [getData]);

  if (loading) return <Loading />;
  return (
    <Layout>
      <div className="inicio">
        {!isEmpty(summary) &&
          (auth.id_broker ? (
            <BrokersSection summary={summary} />
          ) : (
            <>
              <StatsSummary summary={summary} />
              <div className="stats-content">
                <OlecamsSection summary={summary} />
                {/* <VceSection summary={summary} /> */}
              </div>
            </>
          ))}
      </div>
    </Layout>
  );
};

export default Home;

const StatsSummary = ({ summary }) => {
  const formatDate = (dateString) => {
    const [year, month] = dateString.split('-');
    return `${month}-${year}`;
  };

  return (
    <CustomCard icon={<StatsOlecamsIcon color="#4b5056" />} title={<FormattedMessage id="statistics-resum-title" />}>
      <div className="stats">
        <span>
          <h2 className="desktop">
            <FormattedMessage id="global-generated-month" /> {formatDate(summary.affiliate_profile_data.prev_month)}
          </h2>
          <h2 className="movil">
            <FormattedMessage id="global-generated-month" />
          </h2>
          <h2 className="movil">{formatDate(summary.affiliate_profile_data.prev_month)}</h2>
          <h1>{formatPrice(summary.affiliate_profile_data.prev_month_total)}</h1>
        </span>
        <span>
          <h2 className="desktop">
            <FormattedMessage id="statistics-resum-data-generate-actualy" />
          </h2>
          <h2 className="movil">
            <FormattedMessage id="statistics-resum-data-generate-current" />
          </h2>
          <h2 className="movil">
            <FormattedMessage id="statistics-resum-data-current" />
          </h2>
          <h1>{formatPrice(summary.affiliate_profile_data.current_month_total)}</h1>
        </span>
      </div>
    </CustomCard>
  );
};

const BrokersSection = ({ summary }) => {
  return (
    <CustomCard title={<FormattedMessage id="statistics-brokers-stats-summary-title" />}>
      {summary.broker && (
        <>
          <Row className="mb-2 stats-broker">
            <Col className="text-center">
              <h2>
                803 <FormattedMessage id="global-total-minutes" />
              </h2>
              <h1 className="font-weight-bold">{summary.broker.adsl.total_minutes}</h1>
            </Col>
            {/*<Col className="text-center">
                <h2>SMS</h2>
                <h1 className="font-weight-bold">{summary.broker.sms.total_sms}</h1>
            </Col>*/}

            <Col className="text-center">
              <h2>
                <FormattedMessage id="statistics-table-credit" />
              </h2>
              <h1 className="font-weight-bold">{summary.broker.oc_credits.total_credits}</h1>
            </Col>
          </Row>
          <Row className="mb-2 stats-broker">
            <Col className="text-center">
              <h2>Extras</h2>
              <h1 className="font-weight-bold">0.00</h1>
            </Col>
            <Col className="text-center">
              <h2>
                <FormattedMessage id="global-tips" />
              </h2>
              <h1 className="font-weight-bold">{summary.broker.tips.total_credits}</h1>
            </Col>
          </Row>
          <Row>
            <Col className="text-center">
              <NavLink to="/broker/estadisticas" className="button button--softblue home--alignBottom" role="button">
                <FormattedMessage id="statistics-resum-button" />
              </NavLink>
            </Col>
          </Row>
        </>
      )}
    </CustomCard>
  );
};

const OlecamsSection = ({ summary }) => {
  return (
    <CustomCard icon={<SummaryIcon />} title={<FormattedMessage id="statistics-resum-mont-title" />}>
      <div className="olecams-content">
        {/* <div className="header-content">
          <OlecamsIcon />
        </div> */}
        <div className="main-content">
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col">
                <p>
                  <FormattedMessage id="statistics-title-phone" />
                </p>
                <h1>{formatPrice(summary.oc_phone.amount)}</h1>
                <NavLink to="/estadisticas/olecams/phone" className="button" role="button">
                  <FormattedMessage id="statistics-resum-button" />
                </NavLink>
              </div>
              <div className="col">
                <p>SMS</p>
                <h1>{formatPrice(summary.oc_sms.totalAmount)}</h1>
                <NavLink to="/estadisticas/olecams/sms" className="button" role="button">
                  <FormattedMessage id="statistics-resum-button" />
                </NavLink>
              </div>
            </div>
          </div>
          <hr />
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col">
                <p>
                  <FormattedMessage id="statistics-resum-register" />
                </p>
                <h1>{summary.olecams_summary_stats.signin}</h1>
                <p>
                  <FormattedMessage id="statistics-resum-first-buy" />
                </p>
                <h1>{formatPrice(summary.olecams_summary_stats.firstPurchasesAmount)}</h1>
              </div>
              <div className="col">
                <p>
                  <FormattedMessage id="statistics-resum-billing" />
                </p>
                <h1>{formatPrice(summary.olecams_summary_stats.upsellPurchasesAmount)}</h1>
                <p>
                  <FormattedMessage id="statistics-resum-profit" />
                </p>
                <h1 className="price-total">{formatPrice(summary.olecams_summary_stats.totalPurchaseAndPhone)}</h1>
              </div>
            </div>
          </div>
          {/* <p>{translate('statistics-resum-register')}</p>
          <h1>{summary.olecams_summary_stats.signin}</h1>
          <p>{translate('statistics-resum-first-buy')}</p>
          <h1>{formatPrice(summary.olecams_summary_stats.firstPurchasesAmount)}</h1>
          <p>{translate('statistics-resum-billing')}</p>
          <h1>{formatPrice(summary.olecams_summary_stats.upsellPurchasesAmount)}</h1> */}
        </div>
        <div className="footer-content">
          {/* <p>{translate('statistics-resum-profit')}</p>
          <h1 className="price-total">{formatPrice(summary.olecams_summary_stats.totalPurchaseAndPhone)}</h1> */}
          <NavLink to="/estadisticas/olecams" className="button button--full-width" role="button">
            <FormattedMessage id="statistics-resum-button" />
          </NavLink>
        </div>
      </div>
    </CustomCard>
  );
};

const VceSection = ({ summary }) => {
  const total = (videochat) => {
    const result1 = parseFloat(replaceComa(videochat.ADSL)) + parseFloat(replaceComa(videochat.VISA));
    const result2 = parseFloat(replaceComa(videochat.MOBILEINT)) + parseFloat(replaceComa(videochat.SMS));
    return formatPrice(`${result1 + result2}`);
  };

  return (
    <CustomCard icon={<SummaryIcon />} title={<FormattedMessage id="statistics-resum-mont-title" />}>
      <div className="mow-content">
        <div className="header-content">
          <VideoChatIcon />
        </div>
        <div className="main-content">
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col">
                <p>
                  <FormattedMessage id="statistics-resum-phone" />
                </p>
                <h1>{formatPrice(summary.vce_summary_stats.ADSL)}</h1>
                <p>
                  <FormattedMessage id="statistics-resum-visa" />
                </p>
                <h1>{formatPrice(summary.vce_summary_stats.VISA)}</h1>
              </div>
              <div className="col">
                <p>
                  <FormattedMessage id="statistics-resum-sms" />
                </p>
                <h1>{formatPrice(summary.vce_summary_stats.SMS)}</h1>
                <p>
                  <FormattedMessage id="statistics-resum-profit" />
                </p>
                <h1 className="softblue">{total(summary.vce_summary_stats)}</h1>
              </div>
            </div>
          </div>

          {/* <div className="main-content">
          <p>{translate('statistics-resum-phone')}</p>
          <h1>{formatPrice(summary.vce_summary_stats.ADSL)}</h1>
          <p>{translate('statistics-resum-visa')}</p>
          <h1>{formatPrice(summary.vce_summary_stats.VISA)}</h1>
          <p>{translate('statistics-resum-sms')}</p>
          <h1>{formatPrice(summary.vce_summary_stats.SMS)}</h1>
        </div> */}
        </div>
        <div className="footer-content">
          {/* <p>{translate('statistics-resum-profit')}</p>
          <h1 className="softblue">{total(summary.vce_summary_stats)}</h1> */}
          <NavLink to="/estadisticas/vce" className="button button--full-width button--softblue home--alignBottom" role="button">
            <FormattedMessage id="statistics-resum-button" />
          </NavLink>
        </div>
      </div>
    </CustomCard>
  );
};

import { useIntl } from 'react-intl';

const InputAfno = ({ afno, handleChange }) => {
  const { formatMessage } = useIntl();
  return (
    <div className="form-group">
      <label>Tracking ID</label>
      <input
        placeholder={formatMessage({ id: 'statistics-form-select-all' })}
        type="text"
        name="afno"
        className="form-control"
        onChange={handleChange}
        value={afno}
      />
    </div>
  );
};

export default InputAfno;

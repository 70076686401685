import request from './api-service';

const login = ({ email, password }) =>
  request({
    method: 'post',
    url: '/login',
    data: { email, password },
  });

const logout = () =>
  request({
    method: 'get',
    url: '/logout',
    //data: { token },
    /* headers: {
      Accept: 'application/json; charset=utf-8',
      'Content-Type': 'application/json; charset=utf-8',
    } */
  });

const checkUsertoken = (token) =>
  request({
    method: 'get',
    url: '/checkusertoken',
    //data: { token },
  });

const AuthService = {
  login,
  logout,
  checkUsertoken,
};

export default AuthService;

const initialState = {
  adminBanners: {},
  banners: {},
};

const BannersReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ADMIN_BANNERS':
      return Object.assign({}, state, {
        adminBanners: action.adminBanners,
      });
    case 'SET_BANNERS':
      return Object.assign({}, state, {
        banners: action.banners,
      });
    default:
      return state;
  }
};

export default BannersReducer;

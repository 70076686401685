import { useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { Container, Col, Row, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft, faSave, faSpinner } from '@fortawesome/free-solid-svg-icons';

import BrokerService from 'shared/broker-service';

import Button from 'components/button';
import {
  Name,
  Email,
  User,
  Password,
  Url803,
  Status,
  Audio,
  Toy,
  CreditsAudio,
  CreditsSpy,
  CreditsGroup,
  CreditsVip,
} from 'components/Brokers/Form';

const NewCreditPack = () => {
  const { goBack } = useHistory();

  return (
    <Container className="mw-100">
      <Row>
        <Col sm="12" className="mb-2">
          <Button rounded onClick={goBack}>
            <FontAwesomeIcon icon={faArrowCircleLeft} className="mr-2" />
            <FormattedMessage id="statistics-form-button-return" />
          </Button>
        </Col>
        <Col sm="12">
          <Card className="mb-2">
            <Card.Header>
              <FormattedMessage id="global-new" />
            </Card.Header>
            <Card.Body>
              <Form />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

const Form = () => {
  const { goBack } = useHistory();
  const dispatch = useDispatch();
  let loading = useSelector((state) => state.loading.loading);

  const [data, setData] = useState({
    nombre: '',
    email: '',
    usuario: '',
    password: '',
    status: 2,
    url_peticiones_803_default: '',
    audio: 1,
    toy: 1,
    credits_spy: 12,
    credits_group: 24,
    credits_vip: 48,
    credits_audio: 0,
  });

  const onChange = (e) => {
    const { name, value, type, checked } = e.target;
    let fieldValue = value;

    if (type === 'checkbox') fieldValue = checked ? 1 : 0;

    setData({
      ...data,
      [name]: fieldValue,
    });
  };

  const submit = (e) => {
    e.preventDefault();

    // data convert number to decimals with .
    dispatch({ type: 'SET_LOADING', loading: true });
    BrokerService.adminSet(data)
      .then((responseData) => {
        responseData.status === 'Failed' && toast.error(<FormattedMessage id="global-error-try-later" />);
        if (responseData.status === 'Accepted') {
          toast.success(<FormattedMessage id="global-success-operation" />);
          goBack();
        }
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
  };

  return (
    <form onSubmit={submit}>
      <Row>
        <Col xl="6">
          <Name label={<FormattedMessage id="global-name" />} value={data.nombre} onChange={onChange} />
        </Col>
        <Col xl="6">
          <Email label="Email" value={data.email} onChange={onChange} />
        </Col>
      </Row>
      <Row>
        <Col xl="6">
          <User label={<FormattedMessage id="global-user" />} value={data.usuario} onChange={onChange} />
        </Col>
        <Col xl="6">
          <Password label={<FormattedMessage id="login-password" />} value={data.password} onChange={onChange} />
        </Col>
      </Row>
      <Row>
        <Col xl="6">
          <Status label="Status" value={data.status} onChange={onChange} />
        </Col>
        <Col xl="6">
          <Url803 label="URL 803" value={data.url_peticiones_803_default} onChange={onChange} />
        </Col>
      </Row>

      <hr />
      <Row>
        <Col xl="3">
          <Audio label="Audio" value={data.audio} onChange={onChange} />
        </Col>
        <Col xl="3">
          <Toy label="Toy" value={data.toy} onChange={onChange} />
        </Col>
        <Col xl="6">
          <CreditsAudio label="Credits Audio" value={data.credits_audio} onChange={onChange} />
        </Col>
      </Row>
      <Row>
        <Col xl="6">
          <CreditsSpy label="Credits Spy" value={data.credits_spy} onChange={onChange} />
        </Col>
        <Col xl="6">
          <CreditsGroup label="Credits Group" value={data.credits_group} onChange={onChange} />
        </Col>
      </Row>
      <Row>
        <Col xl="6">
          <CreditsVip label="Credits Vip" value={data.credits_vip} onChange={onChange} />
        </Col>
      </Row>
      <hr />
      <Row>
        <Col className="text-center">
          <Button rounded type="submit" disabled={loading}>
            {loading ? (
              <FontAwesomeIcon icon={faSpinner} spin transform="left-4" size="lg" />
            ) : (
              <>
                <FontAwesomeIcon icon={faSave} className="mr-2" />
                <FormattedMessage id="administration-data-button" />
              </>
            )}
          </Button>
        </Col>
      </Row>
    </form>
  );
};

export default NewCreditPack;

import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { toast } from 'react-toastify';

//translate
import translate from 'i18n/translate';
import { getLang } from 'libs/cookies';

import UserService from 'shared/user-service';

import useValidator from 'hooks/useValidator';

//componentd
import Button from 'components/button';
import LoginLayout from 'components/login-layout';

import IconDataOfAccess from 'components/icons/dataOfAcces';

const RecoveryAccount = () => {
  const history = useHistory();
  const params = useParams();
  const intl = useIntl();
  const lang = getLang();
  const [validator, showValidationMessage] = useValidator({}, {}, lang);

  const token = params.token;

  const [pwd, setPwd] = useState('');
  const [repeatPwd, setRepeatPwd] = useState('');
  //const [submit, setSubmit] = useState(false);
  // Errors
  const [errorRepeatPwd, setErrorRepeatPwd] = useState(false);
  /* const [errorRequest, setErrorRequest] = useState(false);
  const [errorParam, setErrorParam] = useState(false);
  const [errorSamePwd, setErrorSamePwd] = useState(false);*/
  const [errorPwdFormat, setErrorPwdFormat] = useState(false);
  //const [error, setError] = useState(false);

  const recoveryPassword = (e) => {
    e.preventDefault();
    resetErrors();
    if (validator.allValid()) {
      if (formPassword(pwd) || formPassword(repeatPwd)) setErrorPwdFormat(true);
      else {
        if (pwd === repeatPwd) {
          setErrorRepeatPwd(false);

          UserService.recovery(pwd, token).then((responseData) => {
            if (responseData.status !== 'Failed') {
              toast.success(translate('login-regeneration-password-success'));
              history.push('/login');
            } else {
              let error_msg = 'global-error-try-later';
              if (responseData.message === 'restorePass KO - Token Expired') {
                error_msg = 'login-token-expired';
                history.push('/email-recovery');
              } else if (responseData.message === 'restorePass KO - Password cannot be the same as email or user') {
                error_msg = 'login-new-password-cannot-be-same-user';
              } else if (responseData.message === 'restorePass KO - Password cannot be the same as previous') {
                error_msg = 'login-new-password-cannot-be-same-prev';
              } else if (responseData.message.validation_errors) {
                error_msg = 'login-parameters-not-provided';
              }
              toast.error(translate(error_msg));
            }
          });

          /* recovery(pwd, token).then((res) => {
            if (res.data.status !== 'Failed') {
              toast.success(translate('login-regeneration-password-success'));
              history.push('/login');
            } else {
              //setError(true);
              //this.setState({ error: true });
              let error_msg = 'global-error-try-later';
              if (res.data.message === 'restorePass KO - Token Expired') {
                error_msg = 'login-token-expired';
                history.push('/email-recovery');
              } else if (res.data.message === 'restorePass KO - Password cannot be the same as email or user') {
                error_msg = 'login-new-password-cannot-be-same-user';
              } else if (res.data.message === 'restorePass KO - Password cannot be the same as previous') {
                error_msg = 'login-new-password-cannot-be-same-prev';
              } else if (res.data.message.validation_errors) {
                error_msg = 'login-parameters-not-provided';
              }
              toast.error(translate(error_msg));
            }
          }); */
        } else setErrorRepeatPwd(true);
      }
    } else showValidationMessage(true);
  };

  const formPassword = (password) => password.length < 8 || !/[0-9]/.test(password) || !/[A-Z]/.test(password);

  const resetErrors = () => {
    /* setErrorOldPwd(false);
    setErrorParam(false);
    setErrorRequest(false);
    setErrorSamePwd(false); */
    setErrorPwdFormat(false);
  };

  return (
    <LoginLayout>
      <div className="card-container">
        <div className="card">
          <div className="card-header">
            <IconDataOfAccess />
            <h1>{translate('login-recovery-title')}</h1>
          </div>
          <div className="card-body">
            {errorRepeatPwd && <Alert text="login-recovery-error-password" />}
            {errorPwdFormat && <Alert text="herramientas-affiliate-change-password-error-format" />}
            {/* {errorParam && <Alert text="herramientas-affiliate-error-request-parameter" />}
            {errorRequest && <Alert text="herramientas-affiliate-change-password-error-request" />}
            
            {errorSamePwd && <Alert text="herramientas-affiliate-change-password-error-same" />}
            {errorOldPwd && <Alert text="herramientas-affiliate-error-request-old-password" />} */}
            <form onSubmit={recoveryPassword}>
              <div className="form-group">
                <label>{translate('login-recovery-label-one')}</label>
                <input
                  type="password"
                  name="password"
                  className={classNames('form-control', { 'error-input': errorPwdFormat })}
                  onChange={(e) => setPwd(e.target.value)}
                  value={pwd}
                />
                {validator.message(intl.formatMessage({ id: 'login-recovery-label-one' }).toLowerCase(), pwd, 'min: 8|max: 100|required')}
              </div>
              <div className="form-group">
                <label>{translate('login-recovery-label-two')}</label>
                <input
                  type="password"
                  name="repeatPassword"
                  className={classNames('form-control', { 'error-input': errorRepeatPwd })}
                  onChange={(e) => setRepeatPwd(e.target.value)}
                  value={repeatPwd}
                />
                {validator.message(
                  intl.formatMessage({ id: 'login-recovery-label-two' }).toLowerCase(),
                  repeatPwd,
                  'min: 8|max: 100|required'
                )}
              </div>
              <div className="form-group button-content">
                <Button rounded onClick={recoveryPassword} align="center" type="submit">
                  {translate('login-recovery-button')}
                </Button>
              </div>
              <div className="text-suport-content">
                <p>{translate('login-recovery-support')}</p>
                <a href="mailto:soporte@promocionesweb.com">soporte@promocionesweb.com</a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </LoginLayout>
  );
};

const Alert = ({ text, alertTypeCss = 'alert-danger' }) => {
  return (
    <div className={`${alertTypeCss} alert`} role="alert">
      {translate(text)}
    </div>
  );
};

export default RecoveryAccount;

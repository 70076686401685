const Home = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="22" height="22" viewBox="0 0 22 22">
      <path
        fill="#A2A9B0"
        d="M17.071 2.931c-1.85-1.93-4.41-2.97-7.07-2.93-2.66-.04-5.21 1-7.06 2.93-1.94 1.85-2.98 4.41-2.94 7.07-.04 2.66 1 5.21 2.93 7.06 1.85 1.94 4.41 2.98 7.07 2.94 2.66.04 5.21-1 7.06-2.93 1.94-1.85 2.98-4.41 2.94-7.07.04-2.66-1-5.22-2.93-7.07zm-2.07 7.07v6h-3.5v-5h-3v5h-3.5v-6h-2l7-7 7.5 7h-2.5z"
      />
    </svg>
  );
};

export default Home;

import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import es from 'date-fns/locale/es';
import DatePicker, { registerLocale } from 'react-datepicker';
import { FormattedMessage, useIntl } from 'react-intl';

//libs
import { getLang } from 'libs/cookies';
import { dateFormat } from 'libs/methods';

//components
import Button from 'components/button';
import IconCalendar from 'components/icons/calendar';

import SelectAffiliate from 'templates/statsOlecams/formComponents/SelectAffiliate';
import SelectDevice from 'templates/statsOlecams/formComponents/SelectDevice';
import InputAfno from 'templates/statsOlecams/formComponents/InputAfno';
import SelectUserCountry from 'templates/statsOlecams/formComponents/SelectUserCountry';

const Form = (props) => {
  const auth = useSelector((state) => state.auth.auth);
  let history = useHistory();
  const { formatMessage } = useIntl();
  registerLocale('es', es);

  return (
    <form className="form-content" onSubmit={props.getStats}>
      <div className="form-detall-content register-detall">
        <div className="row">
          <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
            <div className="form-group calendar">
              <label>
                <FormattedMessage id="statistics-form-date" />
              </label>
              <div className="form-control">
                <DatePicker
                  selected={dateFormat(props.date)}
                  onChange={(date) => props.setDate(date ? dateFormat(date) : '')}
                  locale={getLang()}
                  dateFormat="dd/MM/yyyy"
                  id="calendar-register"
                  popperPlacement="bottom"
                />
                <IconCalendar id="calendar-register" />
              </div>
            </div>
          </div>
          {parseInt(auth.admin) === 1 && (
            <>
              <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
                <div className="form-group">
                  <label>
                    <FormattedMessage id="statistics-form-select-doi" />
                  </label>
                  <select
                    className="form-control"
                    name="email_validated"
                    onChange={(e) => props.setEmailValidated(e.target.value)}
                    value={props.emailValidated}>
                    <option value="">{formatMessage({ id: 'statistics-form-select-all' })}</option>
                    <option value="1">{formatMessage({ id: 'statistics-form-select-valid' })}</option>
                  </select>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
                <SelectAffiliate affiliateId={props.affiliateId} handleChange={(e) => props.setAffiliateId(e.target.value)} />
              </div>
            </>
          )}
          <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
            <InputAfno afno={props.afno} handleChange={(e) => props.setAfno(e.target.value)} />
          </div>
          <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
            <SelectDevice value={props.device} handleChangeDevice={(e) => props.setDevice(e.target.value)} />
          </div>
          <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
            <SelectUserCountry value={props.country} onChange={(e) => props.setCountry(e.target.value)} date={props.date} />
          </div>
        </div>
        <div className="buttons-content">
          <Button rounded onClick={(e) => history.goBack()} align="center" className="left">
            <FormattedMessage id="statistics-form-button-return" />
          </Button>
          <Button rounded align="center" type="submit">
            <FormattedMessage id="global-send" />
          </Button>
        </div>
      </div>
    </form>
  );
};

export default Form;

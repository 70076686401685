const PayPal = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="22" height="22" viewBox="0 0 22 22">
      <path
        fill="#A2A9B0"
        id="prefix__a"
        d="M17.286 5c.723.824.864 1.964.571 3.325-.729 3.388-3.224 4.56-6.412 4.56h-.493c-.388 0-.72.257-.78.605l-.04.196-.622 3.557-.031.152c-.06.348-.392.605-.782.605H6l1.241-7.167h1.663c4.586 0 7.476-2.018 8.382-5.833zM4.553 16H.556c-.341 0-.602-.263-.55-.555L2.704.675C2.775.287 3.163 0 3.619 0h6.475c2.231 0 3.975.407 4.939 1.358.877.862 1.121 1.813.878 3.215 0 0-.04.216-.066.328-.853 3.787-3.774 5.097-7.507 5.097H6.44c-.456 0-.842.287-.913.675l.007-.049L4.553 16z"
      />
    </svg>
  );
};

export default PayPal;

import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Col, Row } from 'react-bootstrap';
import { useParams, useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';

import translate from 'i18n/translate';
import Loading from 'components/loading';
import Button from 'components/button';

import VisaUserService from 'shared/visa-user-service';

import UserForm from './form';
import StatsVisa from './stats_visa';
import StatsSms from './stats_sms';
import StatsLogs from './stats_logs';
import StatsPasswords from './stats_passwords';

import '../index.scss';

const VisaUser = () => {
  const { part } = useParams();
  const history = useHistory();
  let loading = useSelector((state) => state.loading.loading);
  const dispatch = useDispatch();

  const [user_data, setUserData] = useState(null);
  const [log_day, setLogDay] = useState(null);
  const [log_prev_month, setLogPrevMonth] = useState(null);
  const [log_current_month, setLogCurrentMonth] = useState(null);
  const [stats_visa, setStatsVisa] = useState(null);
  const [stats_sms, setStatsSms] = useState(null);
  const [pwd_logs, setPwdLogs] = useState(null);
  const id_visa_user = part;

  useEffect(() => {
    if (id_visa_user) {
      // Get MB Data
      dispatch({ type: 'SET_LOADING', loading: true });
      VisaUserService.adminGet(id_visa_user)
        .then((responseData) => {
          if (responseData.status === 'Accepted') {
            setUserData(responseData.message.user_data);
            setStatsSms(responseData.message.stats_sms);
            setStatsVisa(responseData.message.stats_visa);
            setLogDay(responseData.message.log_day);
            setLogPrevMonth(responseData.message.log_prev_month);
            setLogCurrentMonth(responseData.message.log_current_month);
            setPwdLogs(responseData.message.password_logs);
          } else {
            //this.goToForm();
            toast.error('No se encontró el usuario de visa');
            history.push('/admintools/incidencias-visa');
          }
        })
        .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
    } else history.push('/admintools/incidencias-visa');
  }, [dispatch, id_visa_user, history]);

  if (loading) return <Loading />;
  return (
    <Container className="mw-100 ha-visa-incidents">
      <Row>
        <Col sm="12" className="mb-2">
          <Button rounded onClick={(e) => history.push('/admintools/incidencias-visa')}>
            <FontAwesomeIcon icon={faArrowCircleLeft} className="mr-2" />
            {translate('statistics-form-button-return')}
          </Button>
        </Col>
        <Col sm="12">
          {user_data && <UserForm data={user_data} setData={setUserData} />}
          {stats_visa && stats_visa.length > 0 && <StatsVisa data={stats_visa} email={user_data.email} />}
          {stats_sms && stats_sms.length > 0 && <StatsSms data={stats_sms} email={user_data.email} />}
          {log_day && log_day.length > 0 && <StatsLogs data={log_day} title="global-log-day-conns" email={user_data.email} />}
          {log_prev_month && log_prev_month.length > 0 && (
            <StatsLogs data={log_prev_month} title="global-prev-month-conns" email={user_data.email} />
          )}
          {log_current_month && log_current_month.length > 0 && (
            <StatsLogs data={log_current_month} title="global-current-month-conns" email={user_data.email} />
          )}

          {pwd_logs && pwd_logs.length > 0 && <StatsPasswords data={pwd_logs} email={user_data.email} />}
        </Col>
      </Row>
    </Container>
  );
};

export default VisaUser;

const Data = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
      <path
        fill="#4B5056"
        fillRule="nonzero"
        d="M18 2.979h-4.18c-.57-1.56-2.29-2.36-3.82-1.8-.86.3-1.5.96-1.82 1.8H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-14a2 2 0 0 0-2-2zm-7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm-5 4h10v-2h2v14H4v-14h2v2zm10 4H6v-2h10v2zm-2 4H6v-2h8v2z"
      />
    </svg>
  );
};

export default Data;

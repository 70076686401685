import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { Accordion, Card, Col, Form, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faKey, faClipboardList } from '@fortawesome/free-solid-svg-icons';

import { getLang } from 'libs/cookies';
import translate from 'i18n/translate';
// Hooks
import useValidator from 'hooks/useValidator';
import Button from 'components/button';

import VisaUserService from 'shared/visa-user-service';

const UserForm = ({ data, setData }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [validator, showValidationMessage] = useValidator({}, {}, getLang());

  const saveBtn = (e) => {
    if (validator.allValid()) {
      dispatch({ type: 'SET_LOADING', loading: true });
      VisaUserService.adminUpdate(data)
        .then((responseData) => {
          if (responseData.status === 'Accepted') toast.success(translate('global-update-success'));
          else toast.error(translate('global-update-failed'));
        })
        .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
    } else showValidationMessage(true);
  };

  const generateBtn = (e) => {
    dispatch({ type: 'SET_LOADING', loading: true });
    VisaUserService.adminGeneratePassword(data.email)
      .then((responseData) => {
        if (responseData.status === 'Accepted') toast.success(translate('global-password-generated-success'));
        else toast.error(translate('global-password-generated-fail'));
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
    //showModal+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  return (
    <Accordion className="current-pwd-logs-accordion" defaultActiveKey="0">
      <Card className="mb-2">
        <Accordion.Toggle as={Card.Header} variant="link" eventKey="0" className="pointer">
          <FontAwesomeIcon icon={faClipboardList} className="mr-2" /> {translate('global-user-data')} &nbsp; <strong>{data.email}</strong>{' '}
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            <Form id="form" className="form">
              <Row>
                <Col xl="6">
                  <Form.Group as={Row} className="mb-3 required" controlId="formId">
                    <Form.Label column sm="3" className="font-weight-bold">
                      ID
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={data.id} name="id" placeholder="" disabled />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3 required" controlId="formEmail">
                    <Form.Label column sm="3" className="font-weight-bold">
                      {translate('global-email')}
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={data.email} name="email" placeholder="" disabled />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3 required" controlId="formUsername">
                    <Form.Label column sm="3" className="font-weight-bold">
                      {translate('administration-data-register-acces-user')}
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={data.username} name="username" placeholder="" disabled />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3 required" controlId="formLastAccess">
                    <Form.Label column sm="3" className="font-weight-bold">
                      Último access
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={data.ultimo_acceso} name="ultimo_acceso" placeholder="" disabled />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3 required" controlId="formGeneratedPassword">
                    <Form.Label column sm="3" className="font-weight-bold">
                      Password Generado
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={data.generated_password_date} name="generated_password_date" placeholder="" disabled />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3 required" controlId="formIP">
                    <Form.Label column sm="3" className="font-weight-bold">
                      IP
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={data.ip} name="ip" placeholder="" disabled />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3 required" controlId="formLogin">
                    <Form.Label column sm="3" className="font-weight-bold">
                      Login
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={data.login} name="login" placeholder="" disabled />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3 required" controlId="formSesion">
                    <Form.Label column sm="3" className="font-weight-bold">
                      Sesión
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={data.sesion} name="sesion" placeholder="" disabled />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3 required" controlId="formActiveChat">
                    <Form.Label column sm="3" className="font-weight-bold">
                      Chat Activo
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={data.chat_activo} name="chat_activo" placeholder="" disabled />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3 required" controlId="formShowSaboom">
                    <Form.Label column sm="3" className="font-weight-bold">
                      Show Saboom
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={data.saboom_shows} name="saboom_shows" placeholder="" disabled />
                    </Col>
                  </Form.Group>
                </Col>

                <Col xl="6">
                  <Form.Group as={Row} className="mb-3 required" controlId="formName">
                    <Form.Label column sm="3" className="font-weight-bold">
                      Tiempo (segundos)
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={data.tiempo} name="tiempo" placeholder="" onChange={handleOnChange} />
                      {validator.message(intl.formatMessage({ id: 'global-time-seconds' }).toLowerCase(), data.tiempo, 'required')}
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3 required" controlId="formSuscritoADP">
                    <Form.Label column sm="3" className="font-weight-bold">
                      Suscrito ADP
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={data.suscrito_adp} name="suscrito_adp" placeholder="" onChange={handleOnChange} />
                      {validator.message(intl.formatMessage({ id: 'global-subscribed-adp' }).toLowerCase(), data.suscrito_adp, 'max: 255')}
                    </Col>
                  </Form.Group>

                  {/*
                                    <Form.Group as={Row} className="mb-3 required" controlId="formSuscritoVCE">
                                            <Form.Label column sm="3" className="font-weight-bold">Suscrito VCE</Form.Label>
                                        <Col sm="9">
                                            <Form.Control 
                                                value={data.suscrito_vce}
                                                name="suscrito_vce"
                                                placeholder=""
                                                onChange={handleOnChange}
                                            />
                                            { validator.message(intl.formatMessage({id: 'global-subscribed-vce'}).toLowerCase(), data.suscrito_vce, 'max: 255|required') }
                                        </Col>
                                        
                                    </Form.Group>
                                    */}

                  <Form.Group as={Row} className="mb-3 required" controlId="formLocked">
                    <Form.Label column sm="3" className="font-weight-bold">
                      Locked
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        as="input"
                        value={data.locked}
                        name="locked"
                        placeholder=""
                        onChange={handleOnChange}
                        htmlSize="1"
                        type="number"
                        max="1"
                        min="0"
                      />
                      {validator.message('locked', data.locked, 'required')}
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="text-center">
                <Col>
                  <Button rounded onClick={saveBtn}>
                    <FontAwesomeIcon icon={faSave} className="mr-2" /> {translate('administration-button-save')}
                  </Button>
                </Col>
                <Col>
                  <Button rounded blue onClick={generateBtn}>
                    <FontAwesomeIcon icon={faKey} className="mr-2" /> {translate('global-generate-password')}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default UserForm;

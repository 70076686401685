import translate from 'i18n/translate';

import { formatPrice } from 'libs/methods';

//style
import '../statsOlecams/index.scss';

const Table = (props) => {
  return (
    <table className="table">
      <thead>
        {props.view === 'month' && (
          <tr>
            <th scope="col">{translate('statistics-table-date')}</th>
            <th scope="col">{translate('statistics-table-sale')}</th>
            <th scope="col">{translate('statistics-table-profits')}</th>
          </tr>
        )}
        {props.view === 'day' && (
          <tr>
            <th scope="col">{translate('statistics-table-time')}</th>
            <th scope="col">{translate('statistics-table-sale')}</th>
            <th scope="col">{translate('statistics-table-import')}</th>
          </tr>
        )}
      </thead>
      <tbody>
        {props.view === 'month' &&
          props.stats.data.map((stats, index) => {
            let tipo = (index + 1) % 2 ? 'impar' : 'par';
            return (
              <tr key={index} className={tipo}>
                <td className="vce-td" onClick={(e) => props.getStatsDay(stats.date)}>
                  {stats.date}
                </td>
                <td>{stats.sells}</td>
                <td>{formatPrice(stats.amount)}</td>
              </tr>
            );
          })}
        {props.view === 'day' &&
          props.stats.data.map((stats, index) => {
            let tipo = (index + 1) % 2 ? 'impar' : 'par';
            return (
              <tr key={index} className={tipo}>
                <td>{stats.time}</td>
                <td>
                  {stats.payment_type === 'visa'
                    ? translate('statistics-form-select-visa')
                    : stats.payment_type === 'adsl'
                    ? translate('statistics-form-select-phone')
                    : stats.payment_type}
                </td>
                <td>{formatPrice(stats.amount)}</td>
              </tr>
            );
          })}
      </tbody>
      <tfoot>
        <tr>
          <th scope="col">{translate('statistics-table-total')}</th>
          <th>{props.stats.data_summary.sells}</th>
          <th>{formatPrice(props.stats.data_summary.amount)}</th>
        </tr>
      </tfoot>
    </table>
  );
};

export default Table;

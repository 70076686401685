import BannerItem from './item';

const SpecialBanners = ({ data, category }) => {
  return (
    <>
      {data
        .filter((item) => item.ID_CATEGORY === category)
        .map((item, index) => (
          <BannerItem key={index} data={item} />
        ))}
    </>
  );
};

export default SpecialBanners;

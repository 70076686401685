const Paid = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="22" height="22" viewBox="0 0 22 16">
      <path
        fill="#0B652B"
        id="prefix__a"
        d="M4 0h18v12H4V0zm9 3c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3zM8 2c0 1.105-.895 2-2 2v4c1.105 0 2 .895 2 2h10c0-1.105.895-2 2-2V4c-1.105 0-2-.895-2-2H8zM0 4h2v10h16v2H0V4z"
      />
    </svg>
  );
};

export default Paid;

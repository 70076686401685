export const TYPES = {
  ALL: '',
  GLOBAL: 'GLOBAL',
  CAMPAIGN: 'CAMPAIGN',
  INDIVIDUAL: 'INDIVIDUAL',
};

export const PROMO_TYPE = {
  DISCOUNT: 'discount',
  CREDIT_FACTOR: 'factor',
  CREDIT_GIFT: 'credit_gift',
};

export const STATUS = {
  INACTIVE: 'INACTIVE',
  ACTIVE: 'ACTIVE',
  PREPRODUCTION: 'PREPRODUCTION',
};

export const BATCH_OPTIONS = {
  ALL: '',
  YES: 1,
  NO: 0,
};

export const DATE_RANGE_OPTIONS = {
  ALL: '',
  ON_RANGE: 'onRange',
  FINISHED: 'finished',
  UPCOMING: 'upcoming',
};

import translate from 'i18n/translate';

//style
import './index.scss';

const Table = ({ data }) => {
  return (
    <div className="table-content">
      <table className="table">
        <thead>
          <tr>
            <th>{translate('statistics-table-afiliate')}</th>
            <th>{translate('herramientas-banner-table-company')}</th>
            <th>{translate('global-ADSL')}</th>
            <th>{translate('global-VISA')}</th>
            <th>{translate('global-suscription')}</th>
            <th>{translate('statistics-resum-sms')}</th>
            <th>{translate('global-mobile-int')}</th>
            <th>{translate('administration-billing-import-referred')}</th>
            <th>{translate('global-oc-referred')}</th>
            <th>{translate('administration-billing-import-olecams')}</th>
            <th>{translate('global-oc-phone')}</th>
            <th>SMS OC</th>
            <th>{translate('global-total-global')}</th>
            {/* <th scope="col">{translate('herramientas-banner-table-billing')}</th> */}
          </tr>
        </thead>
        <tbody>
          {data.summary.length > 0 && (
            <>
              {data.summary.map((item, index) => {
                return (
                  <tr key={index} className={(index + 1) % 2 ? 'impar' : 'par'}>
                    <td>{item.id_afiliado}</td>
                    <td>{item.empresa}</td>
                    <td>{item.ADSL}</td>
                    <td>{item.VISA}</td>
                    <td>{item.SUSC}</td>
                    <td>{item.SMS}</td>
                    <td>{item.MINT}</td>
                    <td>{item.REF}</td>
                    <td>{item.REF_OC}</td>
                    <td>{item.OC}</td>
                    <td>{item.PHONE_OC}</td>
                    <td>{item.SMS_OC}</td>
                    <td>{item.TOTAL}</td>
                  </tr>
                );
              })}
            </>
          )}
        </tbody>
      </table>
      <table className="table">
        <thead>
          <tr>
            <th>{translate('global-total-affiliates')}</th>
            <th>{translate('global-ADSL')}</th>
            <th>{translate('global-VISA')}</th>
            <th>{translate('global-suscription')}</th>
            <th>{translate('statistics-resum-sms')}</th>
            <th>{translate('global-mobile-int')}</th>
            <th>{translate('administration-billing-import-referred')}</th>
            <th>{translate('global-oc-referred')}</th>
            <th>{translate('administration-billing-import-olecams')}</th>
            <th>{translate('global-oc-phone')}</th>
            <th>SMS OC</th>
            <th>{translate('global-total-global')}</th>
            {/* <th scope="col">{translate('herramientas-banner-table-billing')}</th> */}
          </tr>
        </thead>
        <tbody>
          {data.totalSummary && (
            <tr className={'par'}>
              <td>{data.totalSummary.count}</td>
              <td>{data.totalSummary.ADSL}</td>
              <td>{data.totalSummary.VISA}</td>
              <td>{data.totalSummary.SUSC}</td>
              <td>{data.totalSummary.SMS}</td>
              <td>{data.totalSummary.MINT}</td>
              <td>{data.totalSummary.REF}</td>
              <td>{data.totalSummary.REF_OC}</td>
              <td>{data.totalSummary.OC}</td>
              <td>{data.totalSummary.PHONE_OC}</td>
              <td>{data.totalSummary.SMS_OC}</td>
              <td>{data.totalSummary.TOTAL}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Table;

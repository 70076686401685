import { useState, useEffect } from 'react';
import { Card, Container, Col, Row, Modal } from 'react-bootstrap';
import ReactJsonView from '@microlink/react-json-view';

import { getPaymentProviderTableName } from 'libs/methods';
import LogsBBService from 'shared/logs-bb-service';

const DiscountsModal = ({ show, setShow, item, date }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!show) return;

      const { message } = await LogsBBService.getCascadePurchases({
        id: item.ID,
        tableName: getPaymentProviderTableName({ provider: item.PROVIDER }),
        // id: 103569,
        // tableName: 'STATS_VISA_CG',
        month: date.split('-')[1],
        year: date.split('-')[2],
      });

      setData(message.data);
    };

    fetchData();
  }, [show, item, date]);

  return (
    <Container className="mw-100 ha-discounts">
      <Row>
        <Col sm="12">
          <Card className="mb-2">
            <Card.Header>Pagos en cascada</Card.Header>
            <Card.Body>
              <ModalForm show={show} setShow={setShow} data={data} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

const ModalForm = ({ show, setShow, data }) => {
  return (
    <Modal size="xl" show={show} onHide={() => setShow(false)} centered>
      <Modal.Header closeButton>
        <Modal.Title>Pagos en cascada</Modal.Title>
      </Modal.Header>

      <Modal.Body className="ha-discounts-new">
        <div className="w-100">{data && <ReactJsonView src={data} theme="monokai" />}</div>
      </Modal.Body>

      <Modal.Footer className="justify-content-center">Pagos en cascada</Modal.Footer>
    </Modal>
  );
};

export default DiscountsModal;

import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faTelegram, faSkype } from '@fortawesome/free-brands-svg-icons';
import { Form, InputGroup } from 'react-bootstrap';

import useValidator from 'hooks/useValidator';

import UserService from 'shared/user-service';

//Component
import Button from 'components/button';
import Card from 'components/card';
import Loading from 'components/loading';

import IconData from 'components/icons/data';
import IconWarning from 'components/icons/warning';
import IconCheck from 'components/icons/check';
import IconClose from 'components/icons/close';
import IconDownload from 'components/icons/download';
import IconDetail from 'components/icons/detall';

import Modal from './modal';
import Api from './api';
import UpdatePassword from './updatePassword';
import BankData from './bankData';

import { getLang } from 'libs/cookies';
import { isEmpty } from 'libs/lodashAlt';

// style
import './index.scss';

const Profile = () => {
  let loading = useSelector((state) => state.loading.loading);
  const lang = getLang();
  //let datos = useSelector((state) => state.administracion.datos);
  const [validator, showValidationMessage] = useValidator({}, {}, lang);
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const [data, setData] = useState(null);
  const [contracts, setContracts] = useState(null);
  const [showUrl, setShowUrl] = useState(true);

  const [submit, setSubmit] = useState(false);
  const [oldPwd, setOldPwd] = useState('');
  const [pwd, setPwd] = useState('');
  const [repeatPwd, setRepeatPwd] = useState('');

  const [idContract, setIdContract] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showAccept, setShowAccept] = useState(false);

  const [telegram, setTelegram] = useState('');
  const [skype, setSkype] = useState('');

  const [contactInfo, setContactInfo] = useState([
    {
      contact_type: 'telegram',
      value: '',
    },
    {
      contact_type: 'skype',
      value: '',
    },
  ]);

  const [disableBtn, setDisableBtn] = useState(false);

  const traffic_sources = [
    { es: 'Página web', en: 'Website', value: 'website' },
    { es: 'Compra de tráfico', en: 'Traffic purchase', value: 'traffic_purchase' },
    { es: 'Redes sociales', en: 'Social media', value: 'social_media' },
    { es: 'Otro', en: 'Other', value: 'other' },
  ];

  const paymentTypes = [
    { es: 'Transferencia', en: 'Transfer', value: 'trans' },
    { es: 'Cheque', en: 'Check', value: 'cheque' },
  ];

  const getData = useCallback(() => {
    dispatch({ type: 'SET_LOADING', loading: true });
    UserService.getProfile()
      .then((response) => {
        // Hacer una copia de response.message.data excluyendo contact_info
        const { contact_info, ...dataWithoutContactInfo } = response.message.affiliate_data;
        setData(dataWithoutContactInfo);
        //setData(response.message.affiliate_data);
        setContactInfo(response.message.affiliate_data.contact_info);
        setContracts(response.message.contracts);
        setShowUrl(response.message.affiliate_data.traffic_source_type === 'website' ? true : false);
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
  }, [dispatch]);

  useEffect(getData, [getData]);

  useEffect(() => {
    if (contactInfo && contactInfo.length > 0) {
      // Filtrar datos válidos
      const filteredData = contactInfo.filter((item) => item.contact_type && item.value);

      // Establecer valores iniciales para telegram y skype si existen
      const telegramItem = filteredData.find((item) => item.contact_type === 'telegram');
      if (telegramItem) setTelegram(telegramItem.value);

      const skypeItem = filteredData.find((item) => item.contact_type === 'skype');
      if (skypeItem) setSkype(skypeItem.value);
    }
  }, [contactInfo]);

  const saveData = () => {
    setSubmit(true);
    if (validator.allValid()) {
      setDisableBtn(true);

      const contact_info = [
        {
          contact_type: 'telegram',
          value: telegram,
        },
        {
          contact_type: 'skype',
          value: skype,
        },
      ];

      UserService.updateProfile({ data, contact_info })
        .then((response) => {
          if (response.status === 'Accepted') toast.success(formatMessage({ id: 'administration-success-data' }));
          else toast.error(formatMessage({ id: 'global-error-try-later' }));
        })
        .finally(() => setDisableBtn(false));
    } else showValidationMessage(true);
  };

  const handleOnChange = (e) => {
    const { value, name } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleChecked = () => {
    setData({
      ...data,
      boletin: data.boletin !== 'no' ? 'no' : 'si',
    });
  };

  const showModalEvt = (id, status) => {
    setShowModal(true);
    setIdContract(id);
    setShowAccept(status);
  };

  const closeModal = async (reload = false) => {
    if (reload) {
      await UserService.getProfile().then((responseData) => {
        setContracts(data.contracts);
      });
    }
    setShowModal(false);
    dispatch({ type: 'CLOSEALLMODAL' });
  };

  const handleTSChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
    if (value === 'website') setShowUrl(true);
    else {
      setShowUrl(false);
      setData({
        ...data,
        [name]: value,
        url: '',
      });
    }
  };

  if (loading) return <Loading />;
  return (
    <>
      {!isEmpty(data) && (
        <div className="data-content">
          <div className="left">
            <Card icon={<IconData />} title={formatMessage({ id: 'administration-data-general' })}>
              <form>
                <DefaultFormGroup
                  submit={submit}
                  validator={validator}
                  label="administration-data-company"
                  name="empresa"
                  value={data.empresa}
                  handleOnChange={handleOnChange}
                />
                <DefaultFormGroup
                  submit={submit}
                  validator={validator}
                  label="administration-data-cif"
                  name="cif"
                  value={data.cif}
                  handleOnChange={handleOnChange}
                />
                <DefaultFormGroup
                  submit={submit}
                  validator={validator}
                  label="administration-data-contact"
                  name="contacto"
                  value={data.contacto}
                  handleOnChange={handleOnChange}
                />
                <DefaultFormGroup
                  submit={submit}
                  validator={validator}
                  label="administration-data-residence"
                  value={data.domicilio}
                  name="domicilio"
                  handleOnChange={handleOnChange}
                />
                <DefaultFormGroup
                  submit={submit}
                  validator={validator}
                  label="administration-data-population"
                  value={data.poblacion}
                  name="poblacion"
                  handleOnChange={handleOnChange}
                />
                <DefaultFormGroup
                  submit={submit}
                  validator={validator}
                  label="administration-data-province"
                  value={data.provincia}
                  name="provincia"
                  handleOnChange={handleOnChange}
                />
                <DefaultFormGroup
                  submit={submit}
                  validator={validator}
                  label="administration-data-cp"
                  value={data.cp}
                  name="cp"
                  handleOnChange={handleOnChange}
                />
                <DefaultFormGroup
                  submit={submit}
                  validator={validator}
                  label="administration-data-country"
                  value={data.pais}
                  name="pais"
                  handleOnChange={handleOnChange}
                />
                <DefaultFormGroup
                  submit={submit}
                  validator={validator}
                  label="administration-data-phone"
                  value={data.telefono}
                  name="telefono"
                  handleOnChange={handleOnChange}
                />
                <Form.Group>
                  <label>
                    <FontAwesomeIcon icon={faTelegram} className="mr-1" />
                    Telegram
                  </label>
                  <InputGroup>
                    <InputGroup.Text style={{ fontSize: '14px' }}>@</InputGroup.Text>
                    <Form.Control
                      value={telegram}
                      name="telegram"
                      placeholder=""
                      onChange={(e) => setTelegram(e.target.value)}
                      minLength="3"
                      maxLength="36"
                    />
                  </InputGroup>
                </Form.Group>

                <Form.Group>
                  <label>
                    <FontAwesomeIcon icon={faSkype} className="mr-1" />
                    Skype
                  </label>

                  <input
                    className="form-control"
                    type="text"
                    name="skype"
                    value={skype}
                    minLength="3"
                    maxLength="36"
                    onChange={(e) => setSkype(e.target.value)}
                  />
                </Form.Group>

                <DefaultFormGroup
                  submit={submit}
                  validator={validator}
                  label="global-email"
                  value={data.email}
                  name="email"
                  handleOnChange={handleOnChange}
                />
                <DefaultFormGroup
                  submit={submit}
                  validator={validator}
                  label="global-email_facturacion"
                  value={data.email_facturacion}
                  name="email_facturacion"
                  handleOnChange={handleOnChange}
                />
                <div className="form-group">
                  <label>{formatMessage({ id: 'global-traffic-source-label' })}</label>
                  <select className="form-control" name="traffic_source_type" value={data.traffic_source_type} onChange={handleTSChange}>
                    {traffic_sources.map((item, index) => {
                      return (
                        <option key={index} value={item.value}>
                          {item[lang]}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {showUrl && (
                  <DefaultFormGroup
                    submit={submit}
                    validator={validator}
                    label="global-url"
                    value={data.url}
                    name="url"
                    handleOnChange={handleOnChange}
                  />
                )}
                <div className="form-group">
                  <label>{formatMessage({ id: 'administration-data-description' })}</label>
                  <textarea
                    className={classNames('form-control', {
                      'form-input-success': submit && validator.check(data.descripcion, 'max: 255'),
                      'form-input-error': submit && !validator.check(data.descripcion, 'max: 255'),
                    })}
                    name="descripcion"
                    value={data.descripcion}
                    onChange={handleOnChange}
                  />
                  {validator.message(formatMessage({ id: 'administration-data-description' }), data.descripcion, 'max: 255')}
                </div>
                <div className="form-group">
                  <label>{formatMessage({ id: 'administration-data-pay-method' })}</label>
                  <select className="form-control" name="tipo_pago" value={data.tipo_pago} onChange={handleOnChange}>
                    {paymentTypes.map((item, index) => {
                      return (
                        <option key={index} value={item.value}>
                          {item[lang]}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="form-group">
                  <label>{formatMessage({ id: 'administration-data-bot' })}</label>
                  <input
                    type="checkbox"
                    name="boletin"
                    className="input-checkbox"
                    checked={data.boletin !== 'no'}
                    onChange={handleChecked}
                  />
                </div>
                <div className="button-content">
                  <Button rounded onClick={saveData} align="center" disabled={disableBtn}>
                    {disableBtn ? (
                      <FontAwesomeIcon icon={faSpinner} spin transform="left-4" size="lg" />
                    ) : (
                      formatMessage({ id: 'administration-data-button' })
                    )}
                  </Button>
                </div>
              </form>
            </Card>
          </div>
          <div className="right">
            <BankData
              data={{ id: data.id, bnombre: data.bnombre, bdireccion: data.bdireccion, bcuenta: data.bcuenta, swift: data.swift }}
            />
            <UpdatePassword
              oldPwd={oldPwd}
              setOldPwd={setOldPwd}
              pwd={pwd}
              setPwd={setPwd}
              repeatPwd={repeatPwd}
              setRepeatPwd={setRepeatPwd}
            />
            <Contracts data={contracts} showModal={showModalEvt} />
            {/* <Api data={data} setData={setData} /> */}
          </div>
        </div>
      )}
      {showModal && <Modal closeModal={closeModal} idContract={idContract} showAcceptContract={showAccept} />}
    </>
  );
};

const DefaultFormGroup = ({ submit, validator, label, name, value, handleOnChange }) => {
  const { formatMessage } = useIntl();
  const validRules = {
    empresa: 'min: 2|max: 80|required',
    cif: 'min: 6|max: 12|required',
    contacto: 'min: 6|max: 100|required',
    domicilio: 'min: 6|max:200|required',
    poblacion: 'min: 6|max:100|required',
    provincia: 'min: 6|max:100|required',
    cp: 'min: 3|max:10|required',
    pais: 'min: 3|max:100|required',
    telefono: 'min: 3|max:20|required',
    email: 'email|required',
    email_facturacion: 'email|required',
    url: 'url|required',
  };

  return (
    <div className="form-group">
      <label>{formatMessage({ id: label })}</label>
      <input
        type="text"
        className={classNames('form-control', {
          'form-input-success': submit && validator.check(value, validRules[name]),
          'form-input-error': submit && !validator.check(value, validRules[name]),
        })}
        name={name}
        value={value}
        onChange={handleOnChange}
      />
      {validator.message(formatMessage({ id: label }).toLowerCase(), value, validRules[name])}
    </div>
  );
};

const Contracts = ({ data, showModal }) => {
  const { formatMessage } = useIntl();
  return (
    !isEmpty(data) && (
      <Card icon={<IconData />} title={formatMessage({ id: 'global-contracts' })}>
        <div className="banner-body">
          {data.length > 0 && (
            <div className="table-content">
              <table className="table">
                <thead>
                  <tr>
                    <th>{formatMessage({ id: 'global-concept-contract' })}</th>
                    <th>{formatMessage({ id: 'global-signed' })}</th>
                    <th>{formatMessage({ id: 'global-signed-date' })}</th>
                    <th>{formatMessage({ id: 'global-actions' })}</th>
                  </tr>
                </thead>
                <tbody>
                  {data.length > 0 &&
                    data.map((item, index) => {
                      return (
                        <tr key={index} className={(index + 1) % 2 ? 'impar' : 'par'}>
                          <td>{item.id_contrato + ' - ' + item.concepto}</td>
                          <td>{item.signed === 1 ? <IconCheck cursorDefault /> : <IconClose cursorDefault />}</td>
                          <td>
                            {item.signed === 1 ? (
                              new Date(item.fecha).toLocaleDateString()
                            ) : (
                              <div>
                                <IconWarning /> <FormattedMessage id="administration-billing-resum-pending-name" />
                              </div>
                            )}
                          </td>
                          <td>
                            <Button rounded onClick={(e) => showModal(item.id_contrato, item.signed)}>
                              {item.signed === 1 ? (
                                <>
                                  <IconDownload /> {formatMessage({ id: 'promo-banner-download' })}
                                </>
                              ) : (
                                <>
                                  <IconDetail color="white" />
                                  {formatMessage({ id: 'notice-button-contract' })}
                                </>
                              )}
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </Card>
    )
  );
};

export default Profile;

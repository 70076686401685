import request from './api-bb-service';
const qs = require('qs');

const getCascadePurchases = ({ month, year, id, tableName }) => {
  let params = {
    extraData: {
      id,
      tableName,
    },
  };
  return request({
    method: 'get',
    url: `/cascade_purchases?month=${month}&year=${year}`,
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    }, // ToDo why paramsSerializer need it?
  });
};

const LogsBBService = {
  getCascadePurchases,
};

export default LogsBBService;

import { useState } from 'react';
import SimpleReactValidator from 'simple-react-validator';

import validator_locale_es from '../assets/simple_react_validator_locale/es.json';

const useValidator = (customMessage = {}, customValidator = {}, customLocale = 'es') => {
  SimpleReactValidator.addLocale('es', validator_locale_es);
  const [show, setShow] = useState(false);

  // Add Custom Validator Global Rules
  customValidator.twitter = {
    // name the rule
    message: ':attribute must be a valid twitter username account, for example @olecams1',
    rule: (val, params, validator) => {
      return validator.helpers.testRegex(val, /^@(\w){1,15}/g) && params.indexOf(val) === -1;
    },
    messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)), // optional
  };
  customValidator.instagram = {
    message: ':attribute must be a valid instagram account, for example @olecams.oficial',
    rule: (val, params, validator) => {
      return validator.helpers.testRegex(val, /^@(\w){1,30}/g) && params.indexOf(val) === -1;
    },
    messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)), // optional
  };
  customValidator.telegram = {
    message: ':attribute must be a valid telegram account, for example @olecamstv',
    rule: (val, params, validator) => {
      return validator.helpers.testRegex(val, /^@(\w){1,30}/g) && params.indexOf(val) === -1;
    },
    messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)), // optional
  };

  customValidator.skype = {
    message: ':attribute must be a valid skype account',
    rule: (val, params, validator) => {
      return validator.helpers.testRegex(val, /^[a-zA-Z][a-zA-Z0-9.,\-_]{5,31}$/g) && params.indexOf(val) === -1;
    },
    messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)), // optional
  };

  customValidator.ip = {
    message: ':attribute must be a valid IP',
    rule: (val, params, validator) => {
      return (
        validator.helpers.testRegex(
          val,
          /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
        ) && params.indexOf(val) === -1
      );
    },
    messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)), // optional
  };

  customValidator.domain = {
    message: ":attribute must be a valid domain. Domain don't need to have http or https",
    rule: (val, params, validator) => {
      return (
        validator.helpers.testRegex(val, /^[-a-z0-9@:%_\+~#=]{1,256}\.[a-z0-9()]{2,256}\b([-a-z0-9()@:%_\+.~#?&//=]{2,13})$/i) &&
        params.indexOf(val) === -1
      );
    },
    messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)), // optional
  };

  customValidator.password = {
    message: ':attribute must have a minimum of 8 characters, one lowercase, one uppercase and one digit.',
    rule: (val, params, validator) => {
      return validator.helpers.testRegex(val, /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/i) && params.indexOf(val) === -1;
    },
    messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)), // optional
  };

  const validator = new SimpleReactValidator({
    messages: customMessage,
    validators: customValidator,
    locale: customLocale,
  });

  if (show) validator.showMessages();

  return [validator, setShow];
};

export default useValidator;

import es from 'date-fns/locale/es';
import DatePicker, { registerLocale } from 'react-datepicker';
import { FormattedMessage, useIntl } from 'react-intl';

//libs
import { getLang } from 'libs/cookies';

//components
import Button from 'components/button';
import IconCalendar from 'components/icons/calendar';

import SelectCountry from 'templates/statsOlecams/formComponents/SelectCountry';

const Form = (props) => {
  const { formatMessage } = useIntl();
  registerLocale('es', es);
  return (
    <form className="form-content" onSubmit={props.getStats}>
      <div className="form-detall-content vce">
        <div className="content-one">
          <div className="form-group">
            <label>
              <FormattedMessage id="statistics-form-select-rang-vce" />
            </label>
            <select className="form-control" name="view" onChange={props.handleOnChange} value={props.filters.view}>
              <option value="month">{formatMessage({ id: 'statistics-form-select-month' })}</option>
              <option value="day">{formatMessage({ id: 'statistics-form-select-day' })}</option>
            </select>
          </div>
          <div className="form-group calendar sp">
            <label>
              <FormattedMessage id="statistics-form-date" />
            </label>
            <div className="form-control">
              {props.filters.view === 'month' ? (
                <DatePicker
                  selected={props.date}
                  onChange={(date) => props.handleChangeDate(date)}
                  locale={getLang()}
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                  showFullMonthYearPicker
                  showTwoColumnMonthYearPicker
                  id="calendar-vce"
                  popperPlacement="bottom"
                />
              ) : (
                <DatePicker
                  selected={props.date}
                  onChange={(date) => props.handleChangeDate(date)}
                  locale={getLang()}
                  dateFormat="dd/MM/yyyy"
                  id="calendar-vce"
                  popperPlacement="bottom"
                />
              )}
            </div>
            <IconCalendar id="calendar-vce" />
          </div>
        </div>
        <div className="content-two">
          <div className="form-group">
            <label>
              <FormattedMessage id="global-afno" />
            </label>
            <input
              placeholder={formatMessage({ id: 'statistics-form-select-all' })}
              type="text"
              name="afno"
              className="form-control"
              onChange={props.handleOnChange}
              value={props.filters.afno}
            />
          </div>
          <SelectCountry value={props.filters.country} handleChangeCountry={props.handleOnChange} />
        </div>
        <div className="buttons-content">
          <Button rounded onClick={(e) => window.location.reload()} align="center" className="left">
            <FormattedMessage id="statistics-form-button-return" />
          </Button>
          <Button rounded onClick={props.getStats} align="center" type="submit">
            <FormattedMessage id="global-send" />
          </Button>
        </div>
      </div>
    </form>
  );
};

export default Form;

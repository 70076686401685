import { useState, useEffect, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Card, Container, Col, Row, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList, faSave } from '@fortawesome/free-solid-svg-icons';

import { getLang } from 'libs/cookies';

import Loading from 'components/loading';
import Button from 'components/button';

import BrokerService from 'shared/broker-service';

import { isEmpty } from 'libs/lodashAlt';

// Hooks
import useValidator from 'hooks/useValidator';

//translate
import translate from 'i18n/translate';

const BrokerProfile = () => {
  let loading = useSelector((state) => state.loading.loading);
  const dispatch = useDispatch();
  const [validator, showValidationMessage] = useValidator({}, {}, getLang());

  const intl = useIntl();

  const [data, setData] = useState({
    BR: '',
    nombre: '',
    email: '',
    url_peticiones_803_default: '',
    secret: '',
  });

  const getData = useCallback(() => {
    dispatch({ type: 'SET_LOADING', loading: true });
    BrokerService.getBrokerData()
      .then((responseData) => {
        if (responseData.status === 'Accepted') {
          setData({
            BR: responseData.message.BR,
            nombre: responseData.message.nombre,
            email: responseData.message.email,
            url_peticiones_803_default: responseData.message.url_peticiones_803_default,
            secret: responseData.message.secret,
          });
        }
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
  }, [dispatch, setData]);

  useEffect(getData, [getData]);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const updateBtn = (e) => {
    if (validator.allValid()) {
      dispatch({ type: 'SET_LOADING', loading: true });
      BrokerService.updateBrokerData(data)
        .then((responseData) => {
          if (responseData.status === 'Accepted') toast.success(translate('global-update-success'));
          else toast.error(translate('global-update-failed'));
        })
        .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
    } else showValidationMessage(true);
  };

  if (loading && isEmpty(data)) return <Loading />;

  return (
    <Container fluid>
      <Row>
        <Col sm="12">
          <Card className="mb-2">
            <Card.Header>
              <FontAwesomeIcon icon={faClipboardList} className="mr-2" /> {translate('sidebar-adm-data')}{' '}
            </Card.Header>
            <Card.Body>
              <Container fluid>
                <Form>
                  <Row>
                    <Col sm="6">
                      <Form.Group as={Row} className="mb-3 required" controlId="formName">
                        <Form.Label column sm="3" className="font-weight-bold">
                          ID
                        </Form.Label>
                        <Col sm="9">
                          <Form.Control value={data.BR} name="BR" placeholder="" onChange={handleOnChange} disabled />
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col sm="6">
                      <Form.Group as={Row} className="mb-3 required" controlId="formName">
                        <Form.Label column sm="3" className="font-weight-bold">
                          {translate('global-name')}
                        </Form.Label>
                        <Col sm="9">
                          <Form.Control value={data.nombre} name="nombre" placeholder="" onChange={handleOnChange} />
                          {validator.message(intl.formatMessage({ id: 'global-name' }), data.nombre, 'max: 50|required')}
                        </Col>
                      </Form.Group>
                    </Col>

                    <Col sm="6">
                      <Form.Group as={Row} className="mb-3 required" controlId="formName">
                        <Form.Label column sm="3" className="font-weight-bold">
                          {translate('global-email')}
                        </Form.Label>
                        <Col sm="9">
                          <Form.Control value={data.email} name="email" placeholder="" onChange={handleOnChange} />
                          {validator.message(intl.formatMessage({ id: 'global-email' }), data.email, 'max: 100|required|email')}
                        </Col>
                      </Form.Group>
                    </Col>

                    <Col sm="6">
                      <Form.Group as={Row} className="mb-3 required" controlId="formName">
                        <Form.Label column sm="3" className="font-weight-bold">
                          URL 803
                        </Form.Label>
                        <Col sm="9">
                          <Form.Control
                            value={data.url_peticiones_803_default}
                            name="url_peticiones_803_default"
                            placeholder=""
                            onChange={handleOnChange}
                          />
                          {validator.message('url 803', data.url_peticiones_803_default, 'max: 512|url')}
                        </Col>
                      </Form.Group>
                    </Col>

                    <Col sm="6">
                      <Form.Group as={Row} className="mb-3 required" controlId="formName">
                        <Form.Label column sm="3" className="font-weight-bold">
                          API SECRET
                        </Form.Label>
                        <Col sm="9">
                          <Form.Control value={data.secret} name="secret" placeholder="" disabled />
                          {validator.message('api secret', data.secret, 'max: 32')}
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="text-center">
                    <Col sm="12">
                      <Button fullWidth rounded onClick={updateBtn}>
                        <FontAwesomeIcon icon={faSave} className="mr-2" /> {translate('global-update')}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Container>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default BrokerProfile;

import Layout from 'components/layout';
import { useParams } from 'react-router';

//Component
import Profile from './profile';
import App from './app';
import Docs from './docs';
import Stats from './stats';
import StatsDetail from './stats/statsDetail.js';

const Broker = () => {
  const { section, part } = useParams();

  return (
    <Layout>
      {section === 'perfil' && <Profile />}
      {section === 'app' && <App />}
      {section === 'estadisticas' && !part && <Stats />}
      {section === 'estadisticas' && part && <StatsDetail />}
      {section === 'documentacion' && <Docs />}
    </Layout>
  );
};

export default Broker;

const CloseDesple = (props) => {
  return (
    <svg
      className="closedesple"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="22"
      height="23"
      viewBox="0 0 22 23">
      <path fill="currentColor" transform="rotate(-180 8 7)" d="M0 0L5 5 10 0z" />
    </svg>
  );
};

export default CloseDesple;

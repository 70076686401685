import IconCheck from 'components/icons/check';
import IconClose from 'components/icons/close';

const FieldsValidated = ({ status }) => {
  let result;

  status === 1 ? (result = <IconCheck cursorDefault />) : (result = <IconClose cursorDefault />);
  return result;
};

export default FieldsValidated;

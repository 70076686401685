const logout = (props) => {
  return (
    <svg
      onClick={(e) => props.logout()}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="22"
      height="22"
      viewBox="0 0 22 22">
      <path
        fill={props.color}
        id="out"
        d="M10 0c5.5 0 10 4.47 10 10a10 10 0 0 1-20 0C0 4.477 4.477 0 10 0zM7.353 5.872c0-1.046-1.263-1.6-2.08-.93-1.64 1.344-2.623 3.296-2.623 5.372 0 3.976 3.46 7.171 7.617 7.032 3.783-.127 6.873-3.03 7.073-6.657.12-2.18-.815-4.241-2.492-5.656-.813-.687-2.096-.132-2.096.925 0 .361.166.702.45.937a4.382 4.382 0 0 1 1.611 3.419c0 2.52-2.15 4.576-4.802 4.576-2.656 0-4.821-2.06-4.821-4.576 0-1.357.636-2.63 1.708-3.503.287-.234.455-.576.455-.939zM10 1.65A1.35 1.35 0 0 0 8.65 3v5a1.35 1.35 0 0 0 2.7 0V3A1.35 1.35 0 0 0 10 1.65z"
      />
    </svg>
  );
};

export default logout;

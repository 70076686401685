import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

//actions
//import { getReferidos } from 'actions/administracion';

import ManagementService from 'shared/management-service';

//Component
import Card from 'components/card';
import Loading from 'components/loading';
import Button from 'components/button';

import IconData from 'components/icons/data';
import IconWarning from 'components/icons/warning';

// style
import './index.scss';
import { formatPrice, replaceComa } from 'libs/methods';

const Referidos = () => {
  let loading = useSelector((state) => state.loading.loading);
  const dispatch = useDispatch();
  const intl = useIntl();
  const auth = useSelector((state) => state.auth.auth);
  const referidos = useSelector((state) => state.administracion.referidos);

  const date = new Date();
  let _currentMonth = `${date.getMonth() + 1}`.padStart(2, '0');
  let _currrentYear = `${date.getFullYear()}`;

  const [month, setMonth] = useState(_currentMonth);
  const [year, setYear] = useState(_currrentYear);

  let years = [];
  let date_2015 = Number(new Date('2015-01-01').getFullYear());
  let i;
  for (i = Number(_currrentYear); i >= date_2015; i--) {
    years.push({ title: i, value: i });
  }

  let total = 0;
  const url = `/registro-afiliado/?r=${auth.id}`;
  const urltext = `https://www.promocionesweb.com/registro-afiliado/?r=${auth.id}`;

  const getData = useCallback(
    (month, year) => {
      dispatch({ type: 'SET_LOADING', loading: true });
      ManagementService.referrals(month, year)
        .then((data) => {
          if (data.status === 'Accepted') dispatch({ type: 'SET_REFERIDOS', referidos: data.message });
        })
        .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
      //getReferidos(auth.token, month, year, dispatch);
    },
    [dispatch]
  );

  //useEffect(getData, [getData]);
  useEffect(() => {
    getData(month, year);
  }, [getData, month, year]);

  const setFilter = () => {
    dispatch({ type: 'SET_LOADING', loading: true });
    ManagementService.referrals(month, year)
      .then((data) => {
        dispatch({ type: 'SET_REFERIDOS', referidos: data.message });
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
  }; //getReferidos(auth.token, month, year, dispatch);

  const handleChange = (e) => {
    const { value, name } = e.target;
    name === 'month' ? setMonth(value) : setYear(value);
  };

  if (loading) return <Loading />;
  return (
    <div className="referidos-content">
      <div className="referidos-cont-top">
        <div className="text-content">
          <p>
            <IconWarning color="#4B5056" /> <FormattedMessage id="administration-referred-title" />
          </p>
          <a href={url}>{urltext}</a>
        </div>
      </div>
      <Card icon={<IconData />} title={<FormattedMessage id="administration-referred-consult-name" />}>
        <div className="filter-content">
          <div className="form-group select">
            <label>
              <FormattedMessage id="administration-referred-mont" />
            </label>
            <select name="month" className="form-control" onChange={handleChange} value={month}>
              <option value="01">{intl.formatMessage({ id: 'administration-referred-mont-january' })}</option>
              <option value="02">{intl.formatMessage({ id: 'administration-referred-mont-february' })}</option>
              <option value="03">{intl.formatMessage({ id: 'administration-referred-mont-march' })}</option>
              <option value="04">{intl.formatMessage({ id: 'administration-referred-mont-april' })}</option>
              <option value="05">{intl.formatMessage({ id: 'administration-referred-mont-may' })}</option>
              <option value="06">{intl.formatMessage({ id: 'administration-referred-mont-june' })}</option>
              <option value="07">{intl.formatMessage({ id: 'administration-referred-mont-july' })}</option>
              <option value="08">{intl.formatMessage({ id: 'administration-referred-mont-august' })}</option>
              <option value="09">{intl.formatMessage({ id: 'administration-referred-mont-september' })}</option>
              <option value="10">{intl.formatMessage({ id: 'administration-referred-mont-octuber' })}</option>
              <option value="11">{intl.formatMessage({ id: 'administration-referred-mont-november' })}</option>
              <option value="12">{intl.formatMessage({ id: 'administration-referred-mont-december' })}</option>
            </select>
          </div>
          <div className="form-group select">
            <label>
              <FormattedMessage id="administration-referred-year" />
            </label>
            <select className="form-control" name="year" onChange={handleChange} value={year}>
              {years.map((option, index) => {
                return (
                  <option key={index} value={option.value}>
                    {option.title}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="form-group">
            <Button rounded onClick={(e) => setFilter()} align="center">
              <FormattedMessage id="global-send" />
            </Button>
          </div>
        </div>
      </Card>
      <Card icon={<IconData />} title={<FormattedMessage id="administration-referred-name" />} table>
        <div className="table-content">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">{<FormattedMessage id="administration-referred-company" />}</th>
                <th scope="col">{<FormattedMessage id="administration-referred-user" />}</th>
                <th scope="col">{<FormattedMessage id="administration-referred-data" />}</th>
                <th scope="col">{<FormattedMessage id="administration-referred-generate" />}</th>
              </tr>
            </thead>
            <tbody>
              {referidos.length > 0 && (
                <>
                  {referidos.map((item, index) => {
                    total = total + parseFloat(replaceComa(`${item.euros}`));
                    return (
                      <tr key={index} className={(index + 1) % 2 ? 'impar' : 'par'}>
                        <td>{item.empresa}</td>
                        <td>{item.usuario}</td>
                        <td>{item.fecha}</td>
                        <td>{formatPrice(`${item.euros}`)}</td>
                      </tr>
                    );
                  })}
                  <tr className="total">
                    <td colSpan="5">TOTAL GENERADO: {formatPrice(`${total}`)}</td>
                  </tr>
                </>
              )}
              {referidos.length <= 0 && (
                <tr className="empty">
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Card>
    </div>
  );
};

export default Referidos;

import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { isEmpty } from 'libs/lodashAlt';
import NewsService from 'shared/news-service';

//Component
import Layout from 'components/layout';
import Card from 'components/card';
import Notice from 'components/icons/notice';
import Loading from 'components/loading';
import Pagination from 'components/pagination';

//style
import './index.scss';

const News = () => {
  let loading = useSelector((state) => state.loading.loading);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [lastPage, setLastPage] = useState(1);
  const [total, setTotal] = useState(0);

  const loadData = useCallback(() => {
    dispatch({ type: 'SET_LOADING', loading: true });
    NewsService.list({ page, perPage, filters: [] })
      .then((data) => {
        if (data.status !== 'Failed') {
          setData(data.message.data);
          setLastPage(data.message.last_page);
          setTotal(data.message.total);
        }
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
    //getNoticiaslist(filters, auth.token, dispatch);
  }, [page, perPage, dispatch]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  /* PAGINATOR */
  const paginate = (e) => setPage(e.target.value);

  const handleChangeNumberOfColum = (e) => {
    setPerPage(e.target.value);
    setPage(1);
  };

  const nextPage = (_page) => {
    _page++;
    _page <= lastPage && setPage(_page);
  };

  const prevPage = (_page) => {
    _page--;
    _page > 0 && setPage(_page);
  };
  // END PAGINATOR

  if (loading) return <Loading />;

  return (
    <Layout>
      {!isEmpty(data) && (
        <div className="noticias-container">
          <div className="noticias-content">
            <div className="left">
              {data.map((item, index) => {
                let content = (index + 1) % 2 ? 'left' : 'right';
                if (content === 'left') {
                  return <NewsItem key={index} item={item} />;
                } else {
                  return null;
                }
              })}
            </div>
            <div className="right">
              {data.map((item, index) => {
                let content = (index + 1) % 2 ? 'left' : 'right';
                if (content === 'right') {
                  return <NewsItem key={index} item={item} />;
                } else {
                  return null;
                }
              })}
            </div>
          </div>

          <div className="pt-4">
            <Pagination
              handleChangeNumberOfColum={handleChangeNumberOfColum}
              pager={{ page: page, items: perPage, pages: lastPage }}
              paginate={paginate}
              prevPage={prevPage}
              nextPage={nextPage}
              total={total}
            />
          </div>
        </div>
      )}
    </Layout>
  );
};

const NewsItem = ({ index, item }) => {
  const dateFormat = (inputDate) => {
    return new Date(inputDate).toLocaleDateString('es-ES', { year: 'numeric', month: '2-digit', day: '2-digit' }).split('/').join('-');
  };
  return (
    <Card key={index} icon={<Notice color="#4B5056" />} title={dateFormat(item.fecha)}>
      <div className="noticia">
        <div className="text-content">
          <h1>{item.titulo}</h1>
          <p dangerouslySetInnerHTML={{ __html: item.texto }} />
        </div>
        {item.imagen && (
          <div className="img-content">
            <img src={item.url_imagen} alt="imagen" />
          </div>
        )}
      </div>
    </Card>
  );
};

export default News;

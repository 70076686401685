import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { Container, Col, Row, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';

import { datetimeToString } from 'libs/methods';
import translate from 'i18n/translate';

import CreditsGiftService from 'shared/credit-gift-service';
import { CREDIT_GIFT } from 'shared/constants';

import Loading from 'components/loading';
import Button from 'components/button';
import Form from './form';

const CreateUpdateCreditGift = () => {
  const { part } = useParams();
  const history = useHistory();
  let loading = useSelector((state) => state.loading.loading);
  const [data, setData] = useState({
    CODE: null,
    START_DATE: datetimeToString(new Date()),
    END_DATE: datetimeToString(new Date()),
    CREDITS: 0,
    DESCRIPTION_ES: '',
    USE_LIMIT: false,
    LIMIT_NUMBER: 0, // TODO: TOTAL LIMIT
    LIMIT_PER_USER: 1,
    TYPE: CREDIT_GIFT.TYPES.CAMPAIGN,
    USER_TYPE_PURCHASE: CREDIT_GIFT.USER_TYPE_PURCHASE.ALL,
    PROFILE_ID: null,
  });

  const [active, setActive] = useState(false);
  //const [checkLimit, setCheckLimit] = useState(false);
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const getDiscount = useCallback(
    (id) => {
      dispatch({ type: 'SET_LOADING', loading: true });
      CreditsGiftService.adminGet(id)
        .then((responseData) => {
          if (responseData.status === 'Accepted' && responseData.message.creditgift) {
            let _data = responseData.message.creditgift;

            setStartDate(new Date(_data.START_DATE));
            setEndDate(new Date(_data.END_DATE));

            _data.START_DATE = datetimeToString(new Date(_data.START_DATE));
            _data.END_DATE = datetimeToString(new Date(_data.END_DATE));

            //_data.CREDIT_PACKS = _data.CREDIT_PACKS ? JSON.parse(_data.CREDIT_PACKS) : [];
            setData(_data);

            //setActive(_discountData.STATUS === 'ACTIVE' ? true : false);
            _data.USE_LIMIT = _data.USE_LIMIT === 1 ? true : false;
          } else {
            toast.error('No encontrado');
            history.push('/admintools/regalo-creditos');
          }
        })
        .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
    },
    [dispatch, history]
  );

  //useEffect(() => getCreditPacksList([]), [getCreditPacksList]);

  useEffect(() => {
    if (part === 'nuevo') return;
    getDiscount(part);
  }, [getDiscount, part]);

  const handleStartDate = (date) => {
    setStartDate(date ? date : '');
    setData({
      ...data,
      START_DATE: datetimeToString(date) ? datetimeToString(date) : '',
    });
  };

  const handleEndDate = (date) => {
    setEndDate(date ? date : '');
    setData({
      ...data,
      END_DATE: datetimeToString(date) ? datetimeToString(date) : '',
    });
  };

  const createUpdate = () => {
    let _data = data;

    //_data.GLOBAL = type === 'global' ? 1 : 0;
    //_data.USER_TYPE_PURCHASE = _data.USER_TYPE_PURCHASE ? 1 : 0;

    _data.USE_LIMIT = _data.USE_LIMIT ? 1 : 0;
    if (_data.TYPE === CREDIT_GIFT.TYPES.GLOBAL) _data.CODE = null;

    //active ? (_data.STATUS = 'ACTIVE') : (_data.STATUS = 'INACTIVE');

    if (part === 'nuevo') {
      CreditsGiftService.adminSet(_data).then(({ status, message }) => {
        if (status === 'Accepted') {
          toast.success(translate('global-success-operation'));
          history.push(`/admintools/regalo-creditos/`);
        } else if (status === 'Failed' && message[0] === 'The data. c o d e field ALREADY EXISTS') {
          toast.warn(translate('global-discount-code-exists'));
        } else if (status === 'Failed' && message['validation_errors'][0] === 'The data. c o d e has already been taken.') {
          toast.warn(translate('global-discount-code-exists'));
        } else if (status === 'Failed' && message === 'Discount overlaped') {
          toast.warn(translate('global-discount-overlaped'));
        } else toast.error(translate('global-error-try-later'));
      });
    } else {
      _data.ID = part;
      dispatch({ type: 'SET_LOADING', loading: true });
      CreditsGiftService.adminUpdate(_data)
        .then(({ status, message }) => {
          if (status === 'Accepted') {
            toast.success(translate('global-success-operation'));
            history.push(`/admintools/regalo-creditos/`);
          } else if (status === 'Failed' && message[0] === 'The data. c o d e field ALREADY EXISTS') {
            toast.warn(translate('global-discount-code-exists'));
          } else if (status === 'Failed' && message['validation_errors'][0] === 'The data. c o d e has already been taken.') {
            toast.warn(translate('global-discount-code-exists'));
          } else if (status === 'Failed' && message === 'Discount overlaped') {
            toast.warn(translate('global-discount-overlaped'));
          } else toast.error(translate('global-error-try-later'));
        })
        .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
    }
  };

  if (loading) return <Loading />;

  return (
    <Container className="mw-100 ha-discounts-new">
      <Row>
        <Col sm="12" className="mb-2">
          <Button rounded onClick={(e) => history.push('/admintools/regalo-creditos')}>
            <FontAwesomeIcon icon={faArrowCircleLeft} className="mr-2" />
            {translate('statistics-form-button-return')}
          </Button>
        </Col>
        <Col sm="12">
          <Card className="mb-2">
            <Card.Header>{part === 'nuevo' ? 'Nuevo regalo de créditos' : 'Regalo de créditos ' + part}</Card.Header>
            <Card.Body>
              <Form
                data={data}
                setData={setData}
                startDate={startDate}
                endDate={endDate}
                active={active}
                setActive={setActive}
                handleStartDate={handleStartDate}
                handleEndDate={handleEndDate}
                createUpdate={createUpdate}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default CreateUpdateCreditGift;

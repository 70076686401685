import { useIntl } from 'react-intl';

const SelectPaymentType = ({ value, handleChangePay }) => {
  const { formatMessage } = useIntl();

  return (
    <div className="form-group">
      <label>{formatMessage({ id: 'statistics-form-select-method' })}</label>
      <select className="form-control" name="payment_type" onChange={handleChangePay} value={value}>
        <option value="">{formatMessage({ id: 'statistics-form-select-all' })}</option>
        <option value="CC">{formatMessage({ id: 'statistics-form-select-visa' })}</option>
        <option value="PP">{formatMessage({ id: 'statistics-form-select-paypal' })}</option>
        <option value="BANK">{formatMessage({ id: 'statistics-form-select-tranfer' })}</option>
        <option value="LOCAL_PAYMENT">{formatMessage({ id: 'statistics-form-select-local-payment' })}</option>
        <option value="CRYPTO">{formatMessage({ id: 'statistics-form-select-crypto' })}</option>
      </select>
    </div>
  );
};

export default SelectPaymentType;

import { Link } from 'react-router-dom';

import Layout from 'components/public-layout';

const LegalAdvice = () => {
  return (
    <Layout cssClass="contact">
      <section className="main">
        <div className="main-content">
          <div className="container"></div>
        </div>
      </section>
      <section>
        <div className="landing-sample white-bg container">
          <div className="content-wrapper">
            <div className="sample-header clearfix"></div>

            <div>
              <h1 className="text-center">AVISO LEGAL</h1>
              <div className="">
                <p>
                  Este Aviso Legal (en adelante, “el Aviso Legal”) rige el acceso y la utilización del sitio web accesible a través del
                  nombre de dominio{' '}
                  <Link to={{ pathname: 'https://oletraffic.com' }} role="button" target="_blank" rel="noopener noreferrer">
                    https://oletraffic.com
                  </Link>{' '}
                  y sus subdominios (en adelante, “el Sitio Web”). El simple acceso al Sitio Web atribuye a quien lo realiza la condición de
                  usuario del mismo (en adelante, “el Usuario”). En caso de no estar de acuerdo con este Aviso Legal, el Usuario debe
                  abandonar inmediatamente el Sitio Web sin utilizarlo.
                </p>
                <p>Por medio de la aceptación de este Aviso Legal el Usuario manifiesta:</p>
                <ul>
                  <li>a) Que ha leído, entiende y acepta lo aquí expuesto.</li> <li>b) Que es mayor de edad.</li>
                </ul>
                <h2>1. Información general del Sitio Web</h2>
                <p>A continuación se ofrece la información general del Sitio Web:</p>
                <p>
                  <strong>Titular:</strong> PROMOCIONESWEB 2016, S.L. (en adelante referido por su nombre comercial, “PROMOCIONESWEB”).
                </p>
                <p>
                  <strong>Domicilio social:</strong> C/ Bethencourt Alfonso 23, piso 7, 38002 de Santa Cruz de Tenerife (España).
                </p>{' '}
                <p>
                  <strong>N.I.F.:</strong> B76694918
                </p>
                <p>
                  <strong>E-mail:</strong>
                  <Link to={{ pathname: 'mailto:soporte@promocionesweb.com' }} role="button" target="_blank" rel="noopener noreferrer">
                    soporte@promocionesweb.com
                  </Link>
                </p>{' '}
                <p>
                  <strong>Teléfono:</strong> 922 875 971
                </p>
                <p>
                  <strong>Datos registrales:</strong> Inscrita en el Registro Mercantil de Tenerife, tomo 3429, folio 155, hoja 56813,
                  inscripción 1ª.
                </p>{' '}
                <h2>2. Acceso al Sitio Web</h2>{' '}
                <p>
                  El simple acceso al Sitio Web es gratuito salvo en lo relativo al coste de la conexión a través de la red de
                  telecomunicaciones suministrada por el proveedor de acceso contratado por el Usuario.
                </p>
                <h2>3. Menores de edad</h2>
                <p>
                  El contenido y los servicios que ofrece PROMOCIONESWEB está exclusivamente destinado a mayores de edad y se prohíbe su uso
                  y consulta de menores de edad. Solicitamos expresamente a los padres o tutores, que tomen las medidas oportunas para
                  evitar que los menores de edad puedan darse de alta, utilizando programas de control parental o sistemas análogos, e
                  informarnos en caso de tener algún indicio de que el menor se ha dado de alta para tomar las medidas oportunas en cada
                  caso. PROMOCIONESWEB se reserva el derecho de solicitar al Usuario la documentación que acredite su identidad en los casos
                  que existan dudas sobre la veracidad de sus datos, negándose la prestación de servicios si el Usuario no atiende dicha
                  solicitud.
                </p>
                <p>
                  PROMOCIONESWEB ha establecido los mecanismos de control razonables para evitar que los menores de edad utilicen los
                  servicios ofrecidos.
                </p>{' '}
                <h2>4. Normas de utilización del Sitio Web</h2>
                <p>
                  El Usuario se obliga a utilizar el Sitio Web y todo su contenido conforme a lo establecido en la ley, la moral, el orden
                  público y al presente Aviso Legal, no empleándolo para realizar actividades ilícitas o constitutivas de delito que atenten
                  contra los derechos de terceros y/o que infrinjan cualesquiera normas del ordenamiento jurídico aplicable.{' '}
                </p>
                <p>El Usuario se compromete a:</p>
                <ul>
                  <li>
                    I. No introducir o difundir contenidos o propaganda de carácter racista, xenófobo, de apología del terrorismo o que
                    atenten contra los derechos humanos.
                  </li>
                  <li>
                    II. No introducir o difundir en la red programas de datos (virus y software nocivo) susceptibles de provocar daños en
                    los sistemas informáticos del proveedor de acceso, sus proveedores o terceros Usuarios de la red Internet.
                  </li>
                  <li>
                    III. No difundir, transmitir o poner a disposición de terceros cualquier tipo de información, elemento o contenido que
                    atente contra los derechos fundamentales y las libertades públicas reconocidos constitucionalmente y en los tratados
                    internacionales.
                  </li>{' '}
                  <li>
                    IV. No difundir, transmitir o poner a disposición de terceros cualquier tipo de información, elemento o contenido que
                    constituya publicidad ilícita o desleal.
                  </li>{' '}
                  <li>
                    V. No transmitir publicidad no solicitada o autorizada, material publicitario, "correo basura", "cartas en cadena",
                    "estructuras piramidales", o cualquier otra forma de solicitud, excepto en aquellas áreas (tales como espacios
                    comerciales) que hayan sido exclusivamente concebidas para ello.
                  </li>{' '}
                  <li>
                    VI. No introducir o difundir cualquier información y contenidos falsos, ambiguos o inexactos de forma que induzca a
                    error a los receptores de la información.{' '}
                  </li>
                  <li>
                    VII. No suplantar a otros Usuarios utilizando sus claves de registro a los distintos servicios y/o contenidos del Sitio
                    Web.{' '}
                  </li>
                  <li>
                    VIII. No difundir, transmitir o poner a disposición de terceros cualquier tipo de información, elemento o contenido que
                    suponga una violación de los derechos de propiedad intelectual e industrial, patentes, marcas o copyright que
                    correspondan a los titulares del Sitio Web o a terceros.
                  </li>{' '}
                  <li>
                    IX. No difundir, transmitir o poner a disposición de terceros cualquier tipo de información, elemento o contenido que
                    suponga una violación del secreto de las comunicaciones y la legislación de datos de carácter personal.{' '}
                  </li>
                  <li>
                    X. El Usuario se obliga a mantener indemne a PROMOCIONESWEB ante cualquier posible reclamación, multa, pena o sanción
                    que pueda venir obligada a soportar como consecuencia del incumplimiento por parte del Usuario de cualquiera de las
                    normas de utilización antes indicadas, reservándose, además, PROMOCIONESWEB el derecho a solicitar la indemnización por
                    daños y perjuicios que corresponda.
                  </li>
                </ul>{' '}
                <h2>5. Contenidos y servicios enlazados a través del Sitio Web</h2>
                <p>
                  El Sitio Web puede contener dispositivos técnicos de enlace, directorios e incluso instrumentos de búsqueda que permitan
                  al Usuario acceder a otras páginas y portales de Internet (en adelante, “Sitios Enlazados”). En estos casos,
                  PROMOCIONESWEB sólo será responsable de los contenidos y servicios suministrados en los Sitios Enlazados en la medida en
                  que tenga conocimiento efectivo de su ilicitud y no haya desactivado el enlace a los mismos con la diligencia debida. En
                  el supuesto de que el Usuario considere que existe un Sitio Enlazado con contenidos ilícitos o inadecuados, puede
                  comunicarlo a PROMOCIONESWEB.
                </p>{' '}
                <p>
                  En ningún caso la existencia de Sitios Enlazados debe presuponer la formalización de acuerdos entre PROMOCIONESWEB y los
                  responsables o titulares de los mismos, así como tampoco la recomendación o promoción de los Sitios Enlazados y/o sus
                  contenidos por parte de PROMOCIONESWEB. A menos que se indique expresamente lo contrario en el Sitio Web, PROMOCIONESWEB
                  no conoce los contenidos y servicios de los Sitios Enlazados y, por tanto, no se hace responsable por los daños y
                  perjuicios que éstos puedan producir al Usuario o a cualquier tercero.
                </p>{' '}
                <h2>6. Propiedad intelectual e industrial </h2>
                <p>
                  Todos los contenidos del Sitio Web entendiendo por éstos, a título meramente enunciativo pero no exhaustivo, los textos,
                  fotografías, gráficos, imágenes, iconos, tecnología, software, links y demás contenidos audiovisuales o sonoros, así como
                  su diseño gráfico y códigos fuente, son propiedad intelectual de PROMOCIONESWEB o de terceros licenciadores, sin que
                  puedan entenderse cedidos al Usuario ninguno de los derechos de explotación reconocidos por la normativa vigente en
                  materia de propiedad intelectual sobre los mismos.
                </p>{' '}
                <p>
                  Las marcas, nombres comerciales y demás signos distintivos publicados en el Sitio Web son titularidad de PROMOCIONESWEB o
                  de terceros cedentes y/o licenciadores, sin que pueda entenderse cedido al Usuario ningún derecho sobre los mismos.
                </p>{' '}
                <h2>7. Contraseña </h2>
                <p>
                  El Usuario es responsable en todo momento de la custodia de su contraseña para acceder a su cuenta, asumiendo en
                  consecuencia cualesquiera daños y perjuicios que pudieran derivarse de su uso indebido, así como de la cesión, revelación
                  o extravío de la misma. A estos efectos, el acceso a áreas restringidas y/o el uso de los servicios y contenidos del Sitio
                  Web realizado bajo su contraseña se reputarán realizados por el Usuario.{' '}
                </p>
                <p>
                  PROMOCIONESWEB se reserva el derecho a anular discrecionalmente el acceso de algún usuario cuando concurran circunstancias
                  de las que se presuman utilizaciones ilícitas.
                </p>{' '}
                <h2>8. Reserva</h2>{' '}
                <p>
                  PROMOCIONESWEB se reserva la facultad en todo momento, sin necesidad de previo aviso, de efectuar modificaciones y
                  actualizaciones de la información contenida en su Web o en su configuración y presentación. Asimismo, se reserva el
                  derecho a suspender temporalmente, y sin que sea necesario previo aviso, la accesibilidad a la Web por razones de eventual
                  necesidad y para efectuar operaciones de mantenimiento, reparación o mejora.
                </p>{' '}
                <p>
                  PROMOCIONESWEB se reserva el derecho a incluir o retirar total o parcialmente de la Web, cualquier información a su
                  exclusiva discreción.
                </p>{' '}
                <h2>9. Quejas, reclamaciones y solicitudes de información </h2>
                <p>
                  El Usuario puede dirigir sus quejas, reclamaciones y/o solicitudes de información a PROMOCIONESWEB, utilizando para ello
                  cualquiera de las siguientes vías:
                </p>{' '}
                <ul>
                  <li>a) Enviando un escrito a C/ Bethencourt Alfonso 23, piso 7, 38002 de Santa Cruz de Tenerife (España).</li>{' '}
                  <li>
                    b) Enviando un correo electrónico a la dirección{' '}
                    <Link to={{ pathname: 'mailto:soporte@promocionesweb.com' }} role="button" target="_blank" rel="noopener noreferrer">
                      soporte@promocionesweb.com
                    </Link>
                    .
                  </li>{' '}
                  <li>c) Llamando al teléfono 922 875 971 en horario de 9:00 a 17:00, de lunes a viernes, excepto festivos.</li>
                </ul>
                <h2>10. Nulidad e ineficacia de las cláusulas</h2>
                <p>
                  Si cualquier cláusula incluida en este Aviso Legal fuese declarada total o parcialmente nula o ineficaz, tal nulidad o
                  ineficacia tan sólo afectará a dicha disposición o a la parte de la misma que resulte nula o ineficaz, subsistiendo este
                  Aviso Legal en todo lo demás y considerándose tal disposición total o parcialmente por no incluida.
                </p>
                <h2>11. Legislación aplicable y jurisdicción competente</h2>{' '}
                <p>
                  Este Aviso Legal se regirá e interpretará conforme a la legislación española. En caso de controversia, los usuarios, con
                  renuncia a su propio fuero, y con independencia del lugar donde se suscite cualquier disputa, se someterán expresamente a
                  la competencia y jurisdicción de los juzgados y tribunales de Santa Cruz de Tenerife.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default LegalAdvice;

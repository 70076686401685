import { createContext, useContext, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import AuthService from 'shared/auth-service';
import LangService from 'shared/lang-service';

import { setAuth, getAuth, removeAuth, setLang, getLang } from 'libs/cookies';
import { langFormar } from 'libs/methods';

//import Logger from 'shared/logger';
//const logger = new Logger('AuthContext');

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  //const [auth, setAuth] = useState(null);
  const [logged, setLogged] = useState(getAuth() ? true : false);
  const [token, setToken] = useState(null);
  const [idContract, setIdContract] = useState(0);
  const [name, setName] = useState(null);
  const [admin, setAdmin] = useState(getAuth()?.admin === 1 ? 1 : 0);
  const [sections, setSections] = useState(getAuth()?.sections);
  const [id, setId] = useState(null);
  const [idAffiliate, setIdAffiliate] = useState(null);
  // const [nick, setNick] = useState(null);
  // const [type, setType] = useState(null);

  const [loading, setLoading] = useState(false);

  const [pwdExpired, setPwdExpired] = useState(false);
  const [blocked, setBlocked] = useState(false);
  const [notValidated, setNotValidated] = useState(false);
  const [tooManyAttempts, setTooManyAttempts] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    (async () => {
      const cookieAuth = getAuth();
      if (!getLang()) setLang(langFormar(navigator.language));

      if (cookieAuth) {
        AuthService.checkUsertoken().then((data) => {
          if (data.status === 'Failed') {
            removeAuth();
            setLogged(false);
            setToken(null);
            setId(null);
            setIdAffiliate(null);
            setIdContract(null);
            setName(null);
            setAdmin(null);
            setSections(null);
          } else {
            LangService.set(getLang());
            dispatch({ type: 'SET_AUTH', auth: { auth: data.message, logged: true } });
            setLogged(true);
            setToken(data.message.token);
            setId(data.message.id);
            setIdAffiliate(data.message.id_affiliate);
            setIdContract(data.message.id_contract);
            setName(data.message.name);
            setAdmin(data.message.admin);
            setSections(data.message.sections);
          }
        });
      } else {
        removeAuth();
        setLogged(false);
        setToken(null);
        setId(null);
        setIdAffiliate(null);
        setIdContract(null);
        setName(null);
        setAdmin(null);
        setSections(null);
      }
    })();
  }, [dispatch]);

  const login = (email, pwd) => {
    setBlocked(false);
    setNotValidated(false);
    setTooManyAttempts(false);
    setError(false);

    setLoading(true);
    AuthService.login({ email: email, password: pwd })
      .then((data) => {
        if (data.status !== 'Failed') {
          if (parseInt(data.message.expired_pwd) === 1) {
            setPwdExpired(true);
            setToken(data.message.token);
          } else {
            dispatch({ type: 'SET_AUTH', auth: { auth: data.message, logged: true } });
            setAuth(data.message);
            LangService.set(getLang());

            setLogged(true);
            setToken(data.message.token);
            setId(data.message.id);
            setIdAffiliate(data.message.id_affiliate);
            setIdContract(data.message.id_contract);
            setName(data.message.name);
            setAdmin(data.message.admin);
            setSections(data.message.sections);
          }
        } else {
          if (data.message === 'login KO User blocked') {
            setBlocked(true);
            setNotValidated(false);
            setTooManyAttempts(false);
            setError(false);
          } else if (data.message === 'login KO Too many attempts to login') {
            setBlocked(true);
            setNotValidated(false);
            setTooManyAttempts(true);
            setError(false);
          } else if (data.message === 'login KO User not validated') {
            setBlocked(false);
            setNotValidated(true);
            setTooManyAttempts(false);
            setError(false);
          } else {
            setBlocked(false);
            setNotValidated(false);
            setTooManyAttempts(false);
            setError(true);
          }
        }
      })
      .finally(() => setLoading(false));
  };

  const checkUserToken = () => {
    AuthService.checkUsertoken().then((data) => {
      if (data.status === 'Failed') removeAuth();
      else {
        dispatch({ type: 'SET_AUTH', auth: { auth: data.message, logged: true } });
        setAuth(data.message);
        setLogged(true);
        setToken(data.message.token);
        setId(data.message.id);
        setIdAffiliate(data.message.id_affiliate);
        setIdContract(data.message.id_contract);
        setName(data.message.name);
        setAdmin(data.message.admin);
        setSections(data.message.sections);
      }
    });
  };

  const logout = () => {
    AuthService.logout().then((data) => {
      if (data.status !== 'Failed' || (data.status === 'Failed' && data.message === 'Unauthorized - Token not exists or expired')) {
        removeAuth();
        clearReduxStore();
        setLogged(false);
        setToken(null);
        setIdContract(null);
        setName(null);
        setAdmin(null);
        setSections(null);
      }
    });
  };

  const clearReduxStore = () => {
    dispatch({ type: 'SET_AUTH', auth: { auth: {}, logged: false } }); //Auth
    dispatch({ type: 'SET_STATS', statsOCFilters: {} });
  };

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        checkUserToken,
        id,
        idAffiliate,
        name,
        admin,
        token,
        logged,
        idContract,
        setIdContract,
        pwdExpired,
        setPwdExpired,
        blocked,
        notValidated,
        tooManyAttempts,
        error,
        loading,
        setError,
        sections,
        // nick,
        // type,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

const Right = (props) => {
  return (
    <svg
      onClick={(e) => props.nextPage(props.page)}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="22"
      height="22"
      viewBox="0 0 22 22">
      <defs>
        <path id="right__a" d="M1.41 0.58L6 5.17 10.59 0.58 12 2 6 8 0 2z" />
      </defs>
      <g fill="none" fillRule="evenodd" transform="rotate(-90 12 5)">
        <mask id="right__b" fill="#fff">
          <use xlinkHref="#right__a" />
        </mask>
        <use fill="#000" fillRule="nonzero" xlinkHref="#right__a" />
        <g fill="#4B5056" mask="url(#right__b)">
          <path d="M0 0H22V22H0z" transform="translate(-5 -7)" />
        </g>
      </g>
    </svg>
  );
};

export default Right;

import { Link } from 'react-router-dom';

import Layout from 'components/public-layout';

const PrivacyPolicy = () => {
  return (
    <Layout cssClass="contact">
      <section className="main">
        <div className="main-content">
          <div className="container"></div>
        </div>
      </section>
      <section>
        <div className="landing-sample white-bg container">
          <div className="content-wrapper">
            <div className="sample-header clearfix"></div>

            <div>
              <h1 className="text-center">Política de privacidad</h1>
              <div className="">
                <h2>1. Responsable</h2>
                <p>El Responsable del tratamiento de los datos recabados por medio de este Sitio Web es:</p>
                <ul>
                  <li>PROMOCIONESWEB 2016, S.L. (en adelante, “PROMOCIONESWEB”)</li>{' '}
                  <li>C/ Bethencourt Alfonso 23, piso 7, 38002 de Santa Cruz de Tenerife (España). </li>
                  <li>
                    <Link to={{ pathname: 'mailto:dpo@3data.net' }} role="button" target="_blank" rel="noopener noreferrer">
                      dpo@3data.net
                    </Link>
                  </li>
                </ul>
                <h2>2. Finalidades</h2>
                <p>Los datos personales del usuario de este Sitio Web se tratarán para las siguientes finalidades:</p>
                <ul>
                  <li>
                    Posibilitar el mantenimiento, desarrollo y gestión de la relación negocial formalizada con los Usuarios que solicitan
                    recibir los productos y/o servicios de PROMOCIONESWEB. Esta finalidad incluye el hecho de registrarse en el Sitio Web,
                    así como los inicios de sesión posteriores. Los datos tratados con esta finalidad se conservarán mientras se mantenga
                    dicha relación y, una vez finalizada ésta, durante los plazos de conservación y de prescripción de responsabilidades
                    legalmente previstos. La base jurídica del tratamiento es la ejecución de un contrato en el que el interesado es parte.
                  </li>{' '}
                  <li>
                    Atender solicitudes de información y/o consultas efectuadas por el Usuario. Los datos tratados con esta finalidad se
                    conservarán hasta haber dado respuesta a la solicitud de información y/o consulta y, tras ello, durante los plazos de
                    conservación y de prescripción de responsabilidades legalmente previstos. La base jurídica del tratamiento es: a) el
                    consentimiento del Usuario en caso de que utilice el formulario de contacto existente en este Sitio Web; o b) el interés
                    legítimo de PROMOCIONESWEB en dar una respuesta al Usuario en caso de que éste no utilice dicho formulario (envío de
                    e-mails espontáneos, llamadas telefónicas, envío de solicitudes escritas por correo postal).
                  </li>{' '}
                  <li>
                    Mantener informado al Usuario, incluso por medios electrónicos, acerca de los productos, servicios y novedades de
                    PROMOCIONESWEB. Los datos tratados con esta finalidad se conservarán hasta la revocación del consentimiento dado para la
                    recepción de dichas comunicaciones y, tras ello, durante los plazos de conservación y de prescripción de
                    responsabilidades legalmente previstos. La base jurídica del tratamiento es el consentimiento del Usuario manifestado a
                    través de las vías puestas a su disposición en este Sitio Web.
                  </li>
                </ul>
                <h2>3. Destinatarios</h2>{' '}
                <p>
                  PROMOCIONESWEB podrá comunicar los datos a Administraciones Públicas para el cumplimiento de obligaciones legales y a
                  Fuerzas y Cuerpos de Seguridad del Estado y/o los Juzgados y Tribunales que los requieran en el marco de una
                  investigación, instrucción o procedimiento. También podrá comunicar los datos a las siguientes categorías de encargados:
                  Proveedores de comunicaciones electrónicas, ofimática, hosting, housing, mantenimiento informático, gestoría,
                  contabilidad, auditoría, asesoría y representación legal. Algunos de dichos encargados pueden estar ubicados fuera del
                  Espacio Económico Europeo, en cuyo caso PROMOCIONESWEB habrá adoptado previamente las garantías adecuadas en materia de
                  protección de datos.
                </p>
                <h2>4. Derechos</h2>
                <p>
                  Los interesados pueden ejercer sus derechos de acceso, rectificación, supresión, limitación del tratamiento, portabilidad
                  de los datos, oposición, derecho a no ser objeto de una decisión basada únicamente en el tratamiento automatizado
                  -incluida la elaboración de perfiles- que produzca efectos jurídicos en él o le afecte significativamente de modo similar,
                  así como retirar en cualquier momento el consentimiento sin que ello afecte a la licitud del tratamiento previo a su
                  retirada, enviando su solicitud a PROMOCIONESWEB (A/A DPD), C/ Bethencourt Alfonso 23, piso 7, 38002 de Santa Cruz de
                  Tenerife (España); o a la dirección de correo electrónico{' '}
                  <Link to={{ pathname: 'mailto:dpo@3data.net' }} role="button" target="_blank" rel="noopener noreferrer">
                    dpo@3data.net
                  </Link>
                  En todo caso, los interesados tienen derecho a presentar una reclamación ante la correspondiente autoridad de control si
                  lo estiman oportuno.
                </p>
                <p>
                  PROMOCIONESWEB ha designado un Delegado de Protección de Datos con el que se puede contactar a través de las vías antes
                  mencionadas.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default PrivacyPolicy;

import { useSelector } from 'react-redux';

import translate from 'i18n/translate';

import { formatPrice } from 'libs/methods';

import { getProvider } from '../payment_provider';
import FormatAfno from '../FormatAfno';

import IconCard from 'components/icons/tarjeta';
import IconPayPal from 'components/icons/payPal';
import IconMobile from 'components/icons/movil';
import IconComputer from 'components/icons/computer';
import IconTablet from 'components/icons/tablet';
import IconPlus from 'components/icons/plus';
import IconTransfer from 'components/icons/transfer';
import IconLocalPay from 'components/icons/localpay';

//style
import '../index.scss';

const Table = ({ stats, getStatDetall }) => {
  const auth = useSelector((state) => state.auth.auth);
  return (
    <table className="table">
      <thead>
        <tr>
          <th scope="col">{translate('statistics-table-date')}</th>
          <th scope="col">{translate('statistics-table-time')}</th>
          <th scope="col">{translate('statistics-table-pay')}</th>
          {parseInt(auth.admin) === 1 && <th scope="col">{translate('statistics-provider')}</th>}
          <th scope="col">{translate('statistics-table-import')}</th>
          {parseInt(auth.admin) === 1 && <th scope="col">{translate('statistics-table-user')}</th>}
          <th scope="col">{translate('statistics-table-afiliate')}</th>
          <th scope="col">{translate('statistics-table-country')}</th>
          <th scope="col">{translate('statistics-table-font')}</th>
        </tr>
      </thead>
      <tbody>
        {stats.results.map((stats, index) => {
          let tipo = (index + 1) % 2 ? 'impar' : 'par';
          let src = stats.COUNTRY ? `${process.env.PUBLIC_URL}/flags/${stats.COUNTRY.toLowerCase()}.png` : '';
          return (
            <tr key={index} className={tipo}>
              <td>{stats.DATE_ONLY}</td>
              <td>{stats.TIME_ONLY}</td>
              <td>
                {stats.PAYMENT_TYPE === 'CC' ? (
                  <IconCard />
                ) : stats.PAYMENT_TYPE === 'PP' ? (
                  <IconPayPal />
                ) : stats.PAYMENT_TYPE === 'BANK' ? (
                  <IconTransfer />
                ) : (
                  <IconLocalPay />
                )}
              </td>
              {parseInt(auth.admin) === 1 && <td>{getProvider(stats.PROVIDER)}</td>}
              <td>{formatPrice(stats.AMOUNT)}</td>
              {parseInt(auth.admin) === 1 && <td>{stats.EMAIL}</td>}
              <td>
                <FormatAfno afno={stats.AFNO} isAdmin={parseInt(auth.admin) === 1} />
              </td>
              <td>
                <span className="flag">
                  {stats.COUNTRY ? (
                    <>
                      <img src={src} alt="flag" /> {stats.COUNTRY.toUpperCase()}
                    </>
                  ) : (
                    <> -- </>
                  )}
                </span>
              </td>
              <td>
                {stats.DEVICE === 2 ? <IconMobile /> : stats.DEVICE === 0 ? <IconComputer /> : <IconTablet />}
                <IconPlus getStatDetall={getStatDetall} id={stats.ID} provider={stats.PROVIDER} />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Table;

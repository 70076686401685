const Tablet = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="22" height="22" viewBox="0 0 18 22">
      <path
        fill="#A2A9B0"
        id="prefix__a"
        d="M16.25 17H1.75V2h14.5v15zM11 20H7v-1h4v1zm4-20H3C1.343 0 0 1.343 0 3v16c0 1.657 1.343 3 3 3h12c1.657 0 3-1.343 3-3V3c0-1.657-1.343-3-3-3z"
      />
    </svg>
  );
};

export default Tablet;

import { useHistory } from 'react-router';
import { NavLink } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';

import { useAuth } from 'contexts/auth';
import { setLang, getLang } from 'libs/cookies';

//components
import Logo from 'components/icons/logo';
import Logout from 'components/icons/logout';
import UserMovil from 'components/icons/userMovil';
import UserDesktop from 'components/icons/userDesktop';
import Menu from 'components/icons/menu';
import Close from 'components/icons/close';

//style
import './index.scss';

const Header = ({ showLinks, showMenu, showSidebar, showUser, showUserMenu }) => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { name, admin, logout } = useAuth();

  const backHome = () => history.push('/');

  const logoutUser = () => {
    if (window.confirm(formatMessage({ id: 'global-logout-confirm' }))) {
      logout();
    }
  };

  const changeLang = (lang) => {
    setLang(lang);
    window.location.reload();
  };

  const spa_src = `${process.env.PUBLIC_URL}/lang_flags/ES.png`;
  const eng_src = `${process.env.PUBLIC_URL}/lang_flags/EN.png`;

  return (
    <>
      {showLinks && (
        <header className="header-container" id="header">
          {showMenu ? (
            <Menu showSidebar={showSidebar} />
          ) : (
            <span className="close-menu" onClick={(e) => showSidebar()}>
              <Close color="#fff" />
            </span>
          )}
          <Logo backHome={backHome} />
          <UserMovil showUser={showUser} />
          <div id="menu-user" className="bottons-content">
            <span onClick={logoutUser} id="logout">
              <Logout color={showUserMenu ? '#fff' : '#A2A9B0'} />
              <FormattedMessage id="sidebar-close" />
            </span>
            <NavLink to="/administracion/datos">
              <span>
                <UserDesktop color={showUserMenu ? '#fff' : '#A2A9B0'} />
                {name} {parseInt(admin) === 1 ? '(Admin)' : ''}
              </span>
            </NavLink>
            <span className="admin-lang-selector">
              <span className={classNames('en', { active: getLang() === 'en' })} onClick={(e) => changeLang('en')}>
                <img src={eng_src} alt="flag" />
              </span>
              <span className={classNames('es', { active: getLang() === 'es' })} onClick={(e) => changeLang('es')}>
                <img src={spa_src} alt="flag" />
              </span>
            </span>
          </div>
        </header>
      )}
      {!showLinks && (
        <header className="header-login-container">
          <Logo backHome={backHome} />
        </header>
      )}
    </>
  );
};

export default Header;

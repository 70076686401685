import { useIntl } from 'react-intl';
import { Form, Row, Col } from 'react-bootstrap';

const Descriptions = ({ data, handleData, validator, langs = ['es', 'en', 'de', 'it', 'fr', 'pt'] }) => {
  const { formatMessage } = useIntl();
  const base_flag_src = process.env.PUBLIC_URL;

  return (
    <>
      {langs.map((lang, index) => {
        return (
          <Form.Group key={index} as={Row} className="mb-3 required" controlId={'formDescription' + lang.toUpperCase()}>
            <Form.Label column sm="3" className="font-weight-bold">
              {lang.toLowerCase() === 'es' && <span>{formatMessage({ id: 'global-description' })}</span>}
              <img
                src={base_flag_src + '/flags/' + lang.toLowerCase() + '.png'}
                alt={'flag_' + lang.toLowerCase()}
                className="mr-1 mt-1 float-right"
              />
            </Form.Label>

            <Col sm="9">
              <Form.Control
                value={data[`DESCRIPTION_${lang.toUpperCase()}`]}
                name={'DESCRIPTION_' + lang.toUpperCase()}
                placeholder=""
                onChange={handleData}
                className="mb-2"
              />
              {lang.toLowerCase() === 'es' &&
                validator.message(
                  formatMessage({ id: 'global-description' }).toLowerCase(),
                  data[`DESCRIPTION_${lang.toUpperCase()}`],
                  'max: 255|required'
                )}
            </Col>
          </Form.Group>
        );
      })}
    </>
  );
};

export default Descriptions;

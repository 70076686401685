import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';

import OlecamsUserService from 'shared/olecams-users-service';

// components
import Card from 'components/card';
import Data from 'components/icons/data';
import Loading from 'components/loading';

const FidelityHistoryModal = ({ close, id, nick }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    setLoading(true);
    OlecamsUserService.adminGetFidelityHistory(id)
      .then(({ message, status }) => {
        console.log(message);
        if (status === 'Failed') toast.error(<FormattedMessage id="global-error-try-later" />);
        else setData(message);
      })
      .finally(() => setLoading(false));
  }, [id, close]);

  if (loading) return <Loading />;

  return (
    <div className="oc-users-modal">
      <Card icon={<Data />} title={`Histórico de fidelidad de ${nick}`} modal closeModal={close}>
        {data.length > 0 ? (
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Level</th>
                <th scope="col">
                  <FormattedMessage id="global-date" />
                </th>
              </tr>
            </thead>

            <tbody>
              {data.map((item, index) => {
                return (
                  <tr key={index} className={(index + 1) % 2 ? 'impar' : 'par'}>
                    <td>{item.LEVEL}</td>
                    <td>{item.DATE}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <div className="text-center">
            <FormattedMessage id="global-no-fidelity-results" />
          </div>
        )}
      </Card>
    </div>
  );
};

export default FidelityHistoryModal;

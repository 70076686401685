import { useState } from 'react';
import { toast } from 'react-toastify';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

//translate
import translate from 'i18n/translate';

import OlecamsUserService from 'shared/olecams-users-service';

import { getLang } from 'libs/cookies';

// components
import Card from 'components/card';
import Button from 'components/button';
import Data from 'components/icons/data';

// Hooks
import useValidator from 'hooks/useValidator';

//style
import '../index.scss';

const BanUserModal = ({ close, id, nick, status }) => {
  const [reason, setReason] = useState('');
  const [validator, showValidationMessage] = useValidator({}, {}, getLang());
  const intl = useIntl();

  const [loading, setLoading] = useState(false);

  const banUserMethod = async () => {
    if (validator.allValid()) {
      const ban_type = status === '0' ? '1' : '0';

      setLoading(true);
      OlecamsUserService.adminBanUser(id, reason, ban_type, nick)
        .then((data) => {
          if (data.status === 'Failed') toast.error(translate('herramientas-olecams-user-banned-error'));
          else {
            toast.success(translate('global-update-success'));
            close(true);
          }
        })
        .finally(() => setLoading(false));
    } else {
      toast.error(translate('global-form-errors'));
      showValidationMessage(true);
    }
  };

  let title =
    status === '0'
      ? intl.formatMessage({ id: 'herramientas-ban-user-title' })
      : intl.formatMessage({ id: 'herramientas-unban-user-title' });

  return (
    <div className="register-banner-modal">
      <Card icon={<Data />} title={title + ' - ' + nick} modal closeModal={close}>
        <div className="banner-body">
          <Form>
            <FormattedMessage id="global-ban-reason">
              {(msg) => <Form.Control value={reason} name="reason" placeholder={msg} onChange={(e) => setReason(e.target.value)} />}
            </FormattedMessage>
            {validator.message(intl.formatMessage({ id: 'global-reason' }), reason, 'min: 2|max: 255|required')}
          </Form>
        </div>
        <div className="button-content">
          <Button rounded onClick={banUserMethod} disabled={loading}>
            {loading ? (
              <FontAwesomeIcon icon={faSpinner} spin transform="left-4" size="lg" />
            ) : (
              translate('herramientas-banner-register-save')
            )}
          </Button>
          <Button rounded onClick={close} red disabled={loading}>
            {loading ? (
              <FontAwesomeIcon icon={faSpinner} spin transform="left-4" size="lg" />
            ) : (
              translate('herramientas-banner-register-cancel')
            )}
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default BanUserModal;

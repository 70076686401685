import request from './api-service';

const adminUserList = (id_user) => {
  return request({
    method: 'get',
    url: `/admin/sections/user_list?id_user=${id_user}`,
  });
};

const adminUpdate = (data, id_user) => {
  return request({
    method: 'put',
    url: '/admin/sections/user_update',
    data: { data, id_user },
  });
};

const adminDelete = (id) => {
  return request({
    method: 'delete',
    url: '/admin/sections/delete',
    data: { ID: id },
  });
};

const SectionsService = {
  adminUserList,
  adminUpdate,
  adminDelete,
};

export default SectionsService;

import { Col, Row, Table } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

const DayTable = ({ data, stats_type }) => {
  return (
    <Row>
      <Col sm="12">
        {data && (
          <Table responsive="sm" striped bordered hover className="text-center">
            {stats_type === 'adsl' && (
              <>
                <thead>
                  <tr>
                    <th>
                      <FormattedMessage id="statistics-table-time" />
                    </th>
                    <th>
                      <FormattedMessage id="statistics-table-minut" />
                    </th>
                    <th>
                      <FormattedMessage id="global-status" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(data.RECORDS).map((key, item) => {
                    let status = data.RECORDS[key].estado !== 0 ? 'global-in-progress' : 'global-completed';
                    return (
                      <tr>
                        <td>{data.RECORDS[key].hora}</td>
                        <td>{data.RECORDS[key].minutos}</td>
                        <td>
                          <FormattedMessage id={status} />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </>
            )}

            {/*stats_type === "sms" && 
                            <>
                                <thead>
                                    <tr>
                                        <th><FormattedMessage id='statistics-table-day')}</th>
                                        <th><FormattedMessage id='global-amount')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { Object.keys(data.SUMM_RECORDS).map((key, item ) => {
                                        return (
                                            <tr>
                                                <td>{key}</td>
                                                <td>{ data.SUMM_RECORDS[key].sms }</td>
                                            </tr>
                                            )
                                        })
                                    }
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th>Total</th>
                                        <th>{data.COUNT_ROWS}</th>
                                    </tr>
                                </tfoot>
                            </> 
                                */}
            {stats_type === 'vce_minutes' && (
              <>
                <thead>
                  <tr>
                    <th>
                      <FormattedMessage id="statistics-table-time" />
                    </th>
                    <th>App</th>
                    <th>Nick</th>
                    <th>
                      <FormattedMessage id="global-model" />
                    </th>
                    <th>
                      <FormattedMessage id="statistics-table-minut" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(data.RECORDS).map((key, item) => {
                    return (
                      <tr>
                        <td>{data.RECORDS[key].hora}</td>
                        <td>{data.RECORDS[key].mb}</td>
                        <td>{data.RECORDS[key].nick}</td>
                        <td>{data.RECORDS[key].chica}</td>
                        <td>{data.RECORDS[key].minutos}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </>
            )}

            {stats_type === 'vcp_minutes' && (
              <>
                <thead>
                  <tr>
                    <th>
                      <FormattedMessage id="statistics-table-time" />
                    </th>
                    <th>App</th>
                    <th>Nick</th>
                    <th>
                      <FormattedMessage id="global-model" />
                    </th>
                    <th>
                      <FormattedMessage id="statistics-table-credit" />
                    </th>
                    <th>
                      <FormattedMessage id="global-type" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(data.RECORDS).map((key, item) => {
                    return (
                      <tr>
                        <td>{data.RECORDS[key].hora}</td>
                        <td>{data.RECORDS[key].mb}</td>
                        <td>{data.RECORDS[key].nick}</td>
                        <td>{data.RECORDS[key].chica}</td>
                        <td>{data.RECORDS[key].credits}</td>
                        <td>{data.RECORDS[key].tipo}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </>
            )}

            {stats_type === 'tips' && (
              <>
                <thead>
                  <tr>
                    <th>
                      <FormattedMessage id="statistics-table-time" />
                    </th>
                    <th>App</th>
                    <th>
                      <FormattedMessage id="global-room" />
                    </th>
                    <th>
                      <FormattedMessage id="global-model" />
                    </th>
                    <th>
                      <FormattedMessage id="login-user" />
                    </th>
                    <th>
                      <FormattedMessage id="global-amount" />
                    </th>
                    <th>
                      <FormattedMessage id="global-type" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(data.DATA).map((key, item) => {
                    return (
                      <tr>
                        <td>{data.DATA[key].hora}</td>
                        <td>{data.DATA[key].mb}</td>
                        <td>{data.DATA[key].roomName}</td>
                        <td>{data.DATA[key].nick_emisor}</td>
                        <td>{data.DATA[key].username}</td>
                        <td>{data.DATA[key].amount}</td>
                        <td>{data.DATA[key].type}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </>
            )}
            {stats_type === 'extras' && (
              <>
                <thead>
                  <tr>
                    <th>
                      <FormattedMessage id="statistics-table-time" />
                    </th>
                    <th>App</th>
                    <th>
                      <FormattedMessage id="global-room" />
                    </th>
                    <th>
                      <FormattedMessage id="global-model" />
                    </th>
                    <th>
                      <FormattedMessage id="login-user" />
                    </th>
                    <th>
                      <FormattedMessage id="global-time-seconds" />
                    </th>
                    <th>
                      <FormattedMessage id="global-type" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(data).map((key, item) => {
                    return (
                      <tr key={key}>
                        <td>{data[key].time}</td>
                        <td>
                          {data[key].appId} - {data[key].dominio}
                        </td>
                        <td>{data[key].sala}</td>
                        <td>{data[key].chica}</td>
                        <td>{data[key].nick}</td>
                        <td>{data[key].seconds}</td>
                        <td>{data[key].type}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </>
            )}
          </Table>
        )}
      </Col>
    </Row>
  );
};

export default DayTable;

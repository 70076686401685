import { useSelector } from 'react-redux';
import es from 'date-fns/locale/es';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useIntl } from 'react-intl';

//libs
import { getLang } from 'libs/cookies';

//components
import Button from 'components/button';
import translate from 'i18n/translate';

import IconCalendar from 'components/icons/calendar';

const Form = ({ date, handleChangeDate, rang, handleChangeRang, filters, handleOnChange, getStats }) => {
  const auth = useSelector((state) => state.auth.auth);
  const intl = useIntl();
  registerLocale('es', es);
  return (
    <form className="form-content">
      <div className="form-detall-content vce">
        <div className="content-one">
          <div className="form-group">
            <label>{translate('statistics-form-select-rang-vce')}</label>
            <select className="form-control" name="view" onChange={handleChangeRang} value={rang}>
              <option value="month">{intl.formatMessage({ id: 'statistics-form-select-month' })}</option>
              <option value="day">{intl.formatMessage({ id: 'statistics-form-select-day' })}</option>
            </select>
          </div>
          <div className="form-group calendar sp">
            <label>{translate('statistics-form-date')}</label>
            <div className="form-control">
              {rang === 'month' ? (
                <DatePicker
                  selected={date}
                  onChange={(date) => handleChangeDate(date)}
                  locale={getLang()}
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                  showFullMonthYearPicker
                  showTwoColumnMonthYearPicker
                  id="calendar-vce"
                  popperPlacement="bottom"
                />
              ) : (
                <DatePicker
                  selected={date}
                  onChange={(date) => handleChangeDate(date)}
                  locale={getLang()}
                  dateFormat="dd/MM/yyyy"
                  id="calendar-vce"
                  popperPlacement="bottom"
                />
              )}
            </div>
            <IconCalendar id="calendar-vce" />
          </div>
          <div className="form-group">
            <label>Operador</label>
            <select className="form-control" name="carrier" onChange={handleOnChange} value={filters.carrier}>
              <option value="">{intl.formatMessage({ id: 'statistics-form-select-all' })}</option>
              <option value="Movistar">Movistar</option>
              <option value="Vodafone">Vodafone</option>
              <option value="Yoigo">Yoigo</option>
              <option value="Orange">Orange</option>
            </select>
          </div>
          {parseInt(auth.admin) === 1 && (
            <div className="form-group">
              <label>{translate('statistics-form-id-afi')}</label>
              <input
                placeholder={intl.formatMessage({ id: 'statistics-form-select-all' })}
                type="text"
                name="affiliate_id"
                className="form-control"
                value={filters.affiliate_id}
                onChange={handleOnChange}
              />
            </div>
          )}
          <div className="form-group">
            <label>Tracking ID</label>
            <input
              placeholder={intl.formatMessage({ id: 'statistics-form-select-all' })}
              type="text"
              name="afno"
              className="form-control"
              onChange={handleOnChange}
              value={filters.afno}
            />
          </div>
        </div>
        <div className="buttons-content">
          <Button rounded onClick={(e) => window.location.reload()} align="center" className="left">
            {translate('statistics-form-button-return')}
          </Button>
          <Button
            rounded
            onClick={(e) => {
              e.preventDefault();
              getStats(filters);
            }}
            align="center">
            {translate('global-send')}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default Form;

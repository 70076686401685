import requestOCV2 from './api-oc-v2-service';
import request from './api-service';
const qs = require('qs');

const prefixUrl = '/olecams/user';
const prefixAdminUrl = '/admin';

const countries = ({ filters }) => {
  let params = { filters };
  return request({
    method: 'get',
    url: `${prefixUrl}/countries`,
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });
};

const adminGet = (id) => {
  return request({
    method: 'get',
    url: `${prefixAdminUrl}${prefixUrl}/get?id=${id}`,
  });
};

const adminList = ({ page, perPage, filters }) => {
  let params = { filters };
  return request({
    method: 'get',
    url: `${prefixAdminUrl}${prefixUrl}/list?page=${page}&per_page=${perPage}`,
    //data: { filters },
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });
};

const userManagement = ({ field, value, user_id }) => {
  return requestOCV2({
    method: 'PUT',
    url: '/usermanagement',
    data: { field, value, user_id },
  });
};

const adminUpdate = (data) => {
  return request({
    method: 'post',
    url: `${prefixAdminUrl}${prefixUrl}/update`,
    data: { data },
  });
};

const adminBanUser = (user_id, reason, ban_type, user_nick) => {
  return request({
    method: 'post',
    url: `${prefixAdminUrl}${prefixUrl}/ban`,
    data: { user_id, reason, ban_type, user_nick },
  });
};

const adminGeneratePwd = (user_id, pwd) => {
  return request({
    method: 'post',
    url: `${prefixAdminUrl}${prefixUrl}/generatepwd`,
    data: { user_id, pwd },
  });
};

const adminUpdateCredits = (user_id, credits) => {
  return request({
    method: 'post',
    url: `${prefixAdminUrl}${prefixUrl}/updatecredits`,
    data: { user_id, credits },
  });
};

const adminUpdateTime = (user_id, time) => {
  return request({
    method: 'post',
    url: `${prefixAdminUrl}${prefixUrl}/updatetime`,
    data: { user_id, time },
  });
};

const adminGetFidelityHistory = (id) => {
  return request({
    method: 'get',
    url: `${prefixAdminUrl}${prefixUrl}/fidelity/history?id=${id}`,
  });
};

const OlecamsUsersService = {
  countries,
  adminGet,
  adminList,
  userManagement,
  adminUpdate,
  adminBanUser,
  adminGeneratePwd,
  adminUpdateCredits,
  adminUpdateTime,
  adminGetFidelityHistory,
};

export default OlecamsUsersService;

import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// Bootstrap
import { Card, Container, Col, Row, Form, Table } from 'react-bootstrap';

import Loading from 'components/loading';
import translate from 'i18n/translate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faSave, faCheckSquare, faSquare } from '@fortawesome/free-solid-svg-icons';

import Button from 'components/button';
import { useIntl, FormattedMessage } from 'react-intl';

//import Pagination from 'components/pagination';

import { getAuth } from 'libs/cookies';

import AffiliatesService from 'shared/affiliates-service';
import SectionsService from 'shared/sections-service';

import './index.scss';
import { toast } from 'react-toastify';

const SectionList = () => {
  let loading = useSelector((state) => state.loading.loading);
  const intl = useIntl();
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState(null);
  const [sections, setSections] = useState(null);

  const getData = useCallback(() => {
    dispatch({ type: 'SET_LOADING', loading: true });
    AffiliatesService.adminList({ page: 1, perPage: 20, filters: { admin: 1 } })
      .then((responseData) => {
        if (responseData.status === 'Accepted') setUsers(responseData.message.data);
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
  }, [dispatch, setUsers]);

  useEffect(getData, [getData]);

  const getSectionsBtn = () => {
    if (!user || user === '') {
      toast.warn(translate('global-select-an-option'));
    } else {
      dispatch({ type: 'SET_LOADING', loading: true });
      SectionsService.adminUserList(user)
        .then((responseData) => {
          if (responseData.status === 'Accepted') setSections(responseData.message);
        })
        .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
    }
  };

  const handleCheckSection = (item) => {
    var _sections = [...sections];
    var index = _sections.findIndex((obj) => obj.ID === item.ID);
    _sections[index].allowed = !item.allowed;
    setSections(_sections);
  };

  const updateSectionsBtn = () => {
    dispatch({ type: 'SET_LOADING', loading: true });
    SectionsService.adminUpdate(sections, user)
      .then((responseData) => {
        responseData.status === 'Accepted'
          ? toast.success(translate('administration-success-data'))
          : toast.error(translate('global-error-try-later'));
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
  };

  const checkAll = (optionType) => {
    let _sections = sections;
    _sections.forEach((section) => {
      optionType === 'check' ? (section.allowed = true) : (section.allowed = false);
    });

    setSections([..._sections]);
  };

  if (loading) return <Loading />;
  return (
    <Container className={`mw-100 ha-sections`}>
      <Row>
        <Col sm="12">
          <Card className="mb-2">
            <Card.Header>{translate('sidebar-setting-sections')}</Card.Header>
            <Card.Body>
              <form className={`search-wrapper`}>
                <div className="first-filters d-flex align-center">
                  <div className="form-group mr-3 calendar sp">
                    <label>{translate('global-user')}</label>
                    <select
                      className="form-control"
                      onChange={(e) => {
                        setUser(e.target.value);
                        setSections(null);
                      }}
                      name="user"
                      defaultValue={user}>
                      <option value="">{intl.formatMessage({ id: 'global-select-an-option' })}</option>
                      {users &&
                        users.length > 0 &&
                        users.map((_user, index) => {
                          return (
                            _user.id !== getAuth().id && (
                              <option key={index} value={_user.id}>
                                {_user.id + ' - ' + _user.usuario}
                              </option>
                            )
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div className="buttons-content">
                  <Button rounded onClick={getSectionsBtn}>
                    <FontAwesomeIcon icon={faSearch} className="mr-2" /> <FormattedMessage id="global-send" />
                  </Button>
                </div>
              </form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {sections && (
        <Row>
          <Col sm="12">
            <Card className="mb-2">
              <Card.Header>
                {translate('global-sections-permissions-user')} {user}
              </Card.Header>
              <Card.Body>
                <div className="float-right">
                  <Button rounded blue onClick={(e) => checkAll('check')} className="mb-2">
                    <FontAwesomeIcon icon={faCheckSquare} className="mr-2" />
                    {translate('global-check-all')}
                  </Button>
                  <Button rounded red onClick={(e) => checkAll('uncheck')} className="mb-2">
                    <FontAwesomeIcon icon={faSquare} className="mr-2" />
                    {translate('global-uncheck-all')}
                  </Button>
                </div>
                <Table responsive="sm" striped bordered hover className="text-center">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>{translate('global-section')}</th>
                      <th>{translate('global-permissions')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sections.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.ID}</td>
                          <td>{item.TRANSLATE_CODE ? translate(item.TRANSLATE_CODE) : item.NAME_ES}</td>
                          <td>
                            <Form.Check type="checkbox" id={item.ID} checked={item.allowed} onChange={(e) => handleCheckSection(item)} />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>

                <Button fullWidth onClick={updateSectionsBtn}>
                  <FontAwesomeIcon icon={faSave} className="mr-2" /> <FormattedMessage id="global-apply-permissions" />
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default SectionList;

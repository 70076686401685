import { FormattedMessage, useIntl } from 'react-intl';

const SelectProvider = ({ value, onChange, paymentValue }) => {
  const { formatMessage } = useIntl();

  const providers = [
    {
      value: 'CG',
      title: 'CommerceGate',
      types: ['CC'],
    },
    {
      value: 'EPOCH',
      title: 'Epoch',
      types: ['CC', 'PP'],
    },
    {
      value: 'SOFORT',
      title: 'Bank Sofort',
      types: ['BANK'],
    },
    {
      value: 'INESPAY',
      title: 'InesPay',
      types: ['BANK'],
    },
    {
      value: 'DIRECTA24',
      title: 'Directa24',
      types: ['LOCAL_PAYMENT'],
    },
    {
      value: 'TRUSTPAY',
      title: 'TrustPay',
      types: ['CC'],
    },
    {
      value: 'SEGPAY',
      title: 'SegPay',
      types: ['PP', 'CC'],
    },
    {
      value: 'SMARTPAY',
      title: 'SmartPay',
      types: ['CRYPTO'],
    },
  ];

  return (
    <div className="form-group">
      <label>
        <FormattedMessage id="statistics-form-select-payment-provider" />
      </label>
      <select className="form-control" name="provider" onChange={onChange} value={value}>
        <option value="">{formatMessage({ id: 'statistics-form-select-all' })}</option>
        {providers
          .filter((o) => o.types.includes(paymentValue) || paymentValue === '')
          .map((provider, index) => {
            return (
              <option key={index} value={provider.value}>
                {provider.title}
              </option>
            );
          })}
      </select>
    </div>
  );
};

export default SelectProvider;

import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { Container, Col, Row, Card } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';

import countries_json from 'libs/countries.json';
import { getLang } from 'libs/cookies';
import { datetimeToString } from 'libs/methods';
import translate from 'i18n/translate';

import { TYPES } from 'libs/promotions';

import CreditPacksService from 'shared/credit-packs-service';
import DiscountsService from 'shared/discounts-service';

import Loading from 'components/loading';
import Button from 'components/button';
import Form from './form';

const Discounts = () => {
  const { part } = useParams();
  const intl = useIntl();
  const history = useHistory();
  let loading = useSelector((state) => state.loading.loading);
  const [data, setData] = useState({
    CODE: null,
    START_DATE: datetimeToString(new Date()),
    END_DATE: datetimeToString(new Date()),
    DISCOUNT: 1,
    WITH_MODAL: false,
    DESCRIPTION_ES: '',
    DESCRIPTION_EN: '',
    DESCRIPTION_DE: '',
    DESCRIPTION_IT: '',
    DESCRIPTION_FR: '',
    DESCRIPTION_PT: '',
    CONTENT: 'all',
    COUNTRIES: [],
    USE_LIMIT: false,
    LIMIT_NUMBER: 0, // TODO: TOTAL LIMIT
    //TOTAL_LIMIT: null,
    LIMIT_PER_USER: 1,
    TYPE: 'GLOBAL',
    //STATUS: 'INACTIVE',
    USERS_WITHOUT_PURCHASES_ONLY: false,
    PROFILE_ID: null,
    CREDIT_PACKS: [],
    CREDIT_GIFT: 0,
    CREDIT_FACTOR: 1,
    WHITELABELS: [],
    AFFILIATES: [],
  });

  const [active, setActive] = useState(false);
  //const [checkLimit, setCheckLimit] = useState(false);
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [creditPacks, setCreditPacks] = useState([]);

  const [moreCountries, setMoreCountries] = useState([]);

  const [countries, setCountries] = useState([
    { label_es: 'España', label_en: 'Spain', label: 'España', value: 'es', iso2: 'ES', iso3: 'ESP', phone_code: 34, checked: false },

    {
      label_es: 'Reino Unido',
      label_en: 'United Kingdom',
      label: 'Reino Unido',
      value: 'gb',
      iso3: 'GBR',
      phone_code: 44,
      checked: false,
    },
    { label_es: 'Francia', label_en: 'France', label: 'Francia', value: 'fr', iso3: 'FRA', phone_code: 33, checked: false },
    { label_es: 'Portugal', label_en: 'Portugal', label: 'Portugal', value: 'pt', iso3: 'PRT', phone_code: 351, checked: false },
    { label_es: 'Alemania', label_en: 'Germany', label: 'Alemania', value: 'de', iso3: 'DEU', phone_code: 49, checked: false },
    { label_es: 'Italia', label_en: 'Italy', label: 'Italia', value: 'it', iso3: 'ITA', phone_code: 39, checked: false },
    {
      label_es: 'Estados Unidos de América',
      label_en: 'United States of America',
      label: 'Estados Unidos de América',
      value: 'us',
      iso2: 'US',
      iso3: 'USA',
      phone_code: 1,
      checked: false,
    },
  ]);

  // METHODS
  const getCreditPacksList = useCallback(() => {
    dispatch({ type: 'SET_LOADING', loading: true });
    CreditPacksService.adminList({ page: 1, perPage: 999, filters: [] })
      .then((responseData) => {
        responseData.status === 'Accepted' && setCreditPacks(responseData.message.data.filter((item) => item.ID !== 10));
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
  }, [dispatch, setCreditPacks]);

  const getMoreCountries = () => {
    let countries_list = [];
    let label;

    countries_json.forEach((country, index) => {
      getLang() === 'en' ? (label = country.en) : (label = country.es);

      if (
        country.iso2 !== 'DE' &&
        country.iso2 !== 'ES' &&
        country.iso2 !== 'FR' &&
        country.iso2 !== 'IT' &&
        country.iso2 !== 'GB' &&
        country.iso2 !== 'US' &&
        country.iso2 !== 'PT'
      ) {
        countries_list.push({
          label: label,
          label_es: country.es,
          label_en: country.en,
          value: country.iso2.toLowerCase(),
          checked: false,
        });
      }
    });

    return countries_list;
  };

  const getCountries = useCallback(() => {
    setMoreCountries(getMoreCountries());
  }, []);

  const getDiscount = useCallback(
    (id) => {
      dispatch({ type: 'SET_LOADING', loading: true });
      DiscountsService.adminGet(id)
        .then((responseData) => {
          if (responseData.status === 'Accepted' && responseData.message.discount) {
            let _discountData = responseData.message.discount;

            let _countries = JSON.parse(_discountData.COUNTRIES);
            let moreCountriesList = getMoreCountries();

            setStartDate(new Date(_discountData.START_DATE));
            setEndDate(new Date(_discountData.END_DATE));

            _discountData.START_DATE = datetimeToString(new Date(_discountData.START_DATE));
            _discountData.END_DATE = datetimeToString(new Date(_discountData.END_DATE));

            _discountData.CREDIT_PACKS = _discountData.CREDIT_PACKS ? JSON.parse(_discountData.CREDIT_PACKS) : [];
            _discountData.WHITELABELS = _discountData.WHITELABELS ? JSON.parse(_discountData.WHITELABELS) : [];
            _discountData.AFFILIATES = _discountData.AFFILIATES ? JSON.parse(_discountData.AFFILIATES) : [];
            setData(_discountData);

            //setActive(_discountData.STATUS === 'ACTIVE' ? true : false);
            _discountData.USE_LIMIT = _discountData.USE_LIMIT === 1 ? true : false;

            if (_countries && _countries.length > 0) {
              _countries.forEach((element) => {
                countries.forEach((country) => {
                  if (country.value === element) {
                    country.checked = true;
                  }
                });

                moreCountriesList.forEach((country) => {
                  if (country.value === element) {
                    country.checked = true;
                  }
                });
              });
            }
            setCountries([...countries]);
            setMoreCountries(moreCountriesList);
          } else {
            toast.error('El descuento no existe');
            history.push('/admintools/promociones');
          }
        })
        .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
    },
    [dispatch, history]
  );

  useEffect(() => getCountries(), [getCountries]);

  useEffect(() => getCreditPacksList([]), [getCreditPacksList]);

  useEffect(() => {
    if (part === 'nuevo') return;
    getDiscount(part);
  }, [getDiscount, part]);

  useEffect(() => {
    setCreditPacks((currentCreditPacks) =>
      currentCreditPacks.map((pack) => {
        return { ...pack, CREDIT_GIFT: parseInt(pack.CREDITS * data.CREDIT_FACTOR) + parseInt(data.CREDIT_GIFT) };
      })
    );
  }, [data.CREDIT_FACTOR, data.CREDIT_GIFT]);

  const handleStartDate = (date) => {
    setStartDate(date ? date : '');
    setData({
      ...data,
      START_DATE: datetimeToString(date) ? datetimeToString(date) : '',
    });
  };

  const handleEndDate = (date) => {
    setEndDate(date ? date : '');
    setData({
      ...data,
      END_DATE: datetimeToString(date) ? datetimeToString(date) : '',
    });
  };

  const createUpdate = () => {
    let _data = data;

    //_data.GLOBAL = type === 'global' ? 1 : 0;
    _data.USERS_WITHOUT_PURCHASES_ONLY = _data.USERS_WITHOUT_PURCHASES_ONLY ? 1 : 0;
    _data.USE_LIMIT = _data.USE_LIMIT ? 1 : 0;
    if (_data.TYPE === TYPES.GLOBAL) _data.CODE = null;

    if (_data.TYPE !== TYPES.GLOBAL) _data.WITH_MODAL = false;

    // Countries
    let _countries = [];
    countries.forEach((element) => {
      element.checked && _countries.push(element.value);
    });

    moreCountries.forEach((element) => {
      element.checked && _countries.push(element.value);
    });
    _data.COUNTRIES = _countries;
    //active ? (_data.STATUS = 'ACTIVE') : (_data.STATUS = 'INACTIVE');

    if (part === 'nuevo') {
      DiscountsService.adminSet(_data).then(({ status, message }) => {
        if (status === 'Accepted') {
          toast.success(translate('global-success-operation'));
          history.push(`/admintools/promociones/`);
        } else if (status === 'Failed' && message[0] === 'The data. c o d e field ALREADY EXISTS') {
          toast.warn(translate('global-discount-code-exists'));
        } else if (status === 'Failed' && message['validation_errors'][0] === 'The data. c o d e has already been taken.') {
          toast.warn(translate('global-discount-code-exists'));
        } else if (status === 'Failed' && message === 'Discount overlaped') {
          toast.warn(translate('global-discount-overlaped'));
        } else toast.error(translate('global-error-try-later'));
      });
    } else {
      _data.ID = part;
      dispatch({ type: 'SET_LOADING', loading: true });
      DiscountsService.adminUpdate(_data)
        .then(({ status, message }) => {
          if (status === 'Accepted') {
            toast.success(translate('global-success-operation'));
            history.push(`/admintools/promociones/`);
          } else if (status === 'Failed' && message[0] === 'The data. c o d e field ALREADY EXISTS') {
            toast.warn(translate('global-discount-code-exists'));
          } else if (status === 'Failed' && message['validation_errors'][0] === 'The data. c o d e has already been taken.') {
            toast.warn(translate('global-discount-code-exists'));
          } else if (status === 'Failed' && message === 'Discount overlaped') {
            toast.warn(translate('global-discount-overlaped'));
          } else toast.error(translate('global-error-try-later'));
        })
        .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
    }
  };

  if (loading) return <Loading />;

  return (
    <Container className="mw-100 ha-discounts-new">
      <Row>
        <Col sm="12" className="mb-2">
          <Button rounded onClick={(e) => history.push('/admintools/promociones')}>
            <FontAwesomeIcon icon={faArrowCircleLeft} className="mr-2" />
            {translate('statistics-form-button-return')}
          </Button>
        </Col>
        <Col sm="12">
          <Card className="mb-2">
            <Card.Header>
              {part === 'nuevo' ? translate('global-new-discount') : intl.formatMessage({ id: 'global-discount' }) + ' ' + part}
            </Card.Header>
            <Card.Body>
              <Form
                data={data}
                setData={setData}
                startDate={startDate}
                endDate={endDate}
                active={active}
                setActive={setActive}
                handleStartDate={handleStartDate}
                handleEndDate={handleEndDate}
                countries={countries}
                setCountries={setCountries}
                moreCountries={moreCountries}
                setMoreCountries={setMoreCountries}
                createUpdate={createUpdate}
                creditPacks={creditPacks}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Discounts;

import request from './api-service';

const sendContact = (contactform) =>
  request({
    method: 'post',
    url: '/support/contact_form',
    data: { contactform },
  });

const sendPublicContact = (contactform, recaptcha_response) =>
  request({
    method: 'post',
    url: '/public/contact',
    data: { contactform, recaptcha_response },
  });

const ContactService = {
  sendContact,
  sendPublicContact,
};

export default ContactService;

import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { Card, Col, Row, Form, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faEye, faClipboardList, faCode } from '@fortawesome/free-solid-svg-icons';

import { getLang } from 'libs/cookies';
import { validateURL, cleanString } from 'libs/methods';

import Loading from 'components/loading';
import CustomButton from 'components/button';

import ConfigForm from './configForm.js';
import FiltersForm from './filtersForm.js';
import DesignForm from './designForm.js';
import DemoIframe from './demo_iframe.js';

import useValidator from 'hooks/useValidator';

//libs
import countries_json from 'libs/countries.json';

import WhiteLabelsService from 'shared/white-labels-service.js';
import RoomlistService from 'shared/roomlist-service.js';

import './index.scss';

const MainForm = () => {
  const auth = useSelector((state) => state.auth.auth);
  const loading = useSelector((state) => state.loading.loading);
  //const demo_roomlist = useSelector((state) => state.roomlists.demo_roomlist);
  const id_edit_roomlist = useSelector((state) => state.roomlists.id_edit_roomlist);

  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();

  const lang = getLang();
  const [validator, showValidationMessage] = useValidator({}, {}, lang);

  const [filters, setFilters] = useState({
    segment: 0,
    category: '',
    country: '',
    age: '',
    chat: '',
  });

  const [showModal, setShowModal] = useState(false);
  //const [idEditRoomlist, setIdEditRoomList] = useState('');
  const [prevCampaign, setPrevCampaign] = useState('');
  const [prevDomain, setPrevDomain] = useState('');
  const [configForm, setConfigForm] = useState({
    campaign: '',
    domain: '',
    prefix_afno: '1-' + auth.id_affiliate + '-',
    afno: '',
    access_url: 'home',
    whitelabel_url: validateURL('www.olecams.com'),
  });
  const [designForm, setDesignForm] = useState({
    pager: false,
    roomlist_limit: false,
    roomlist_limit_count: 24,
    iframe_size: 'adaptive',
    iframe_width: null,
    iframe_height: 140,
    thumb_size: 'small',
    page_bg_color: '#282828',
    nick_color: '#ffffff',
    nick_bg_color: '#282828',
    button_bg_color: '#282828',
    button_text_color: '#ffffff',
  });
  const [languagesList, setLanguagesList] = useState([
    { label: 'Auto', label_es: 'Auto', label_en: 'Auto', value: 'auto', checked: true },
    { label: 'Español', label_es: 'Español', label_en: 'Spanish', value: 'es', checked: false },
    { label: 'Inglés', label_es: 'Inglés', label_en: 'English', value: 'en', checked: false },
    { label: 'Alemán', label_es: 'Alemán', label_en: 'German', value: 'de', checked: false },
    { label: 'Italiano', label_es: 'Italiano', label_en: 'Italian', value: 'it', checked: false },
    { label: 'Portugués', label_es: 'Portugués', label_en: 'Portuguese', value: 'pt', checked: false },
    { label: 'Francés', label_es: 'Francés', label_en: 'French', value: 'fr', checked: false },
  ]);
  const [countries, setCountries] = useState([]);
  const [chat, setChat] = useState([
    { label: 'Todos', label_es: 'Todos', label_en: 'All', value: '', checked: true, chat: '' },
    { label: 'Free', label_es: 'Free', label_en: 'Free', value: 'free', checked: false, chat: 'free' },
    { label: 'Group Chat', label_es: 'Group Chat', label_en: 'Group Chat', value: 'private', checked: false, chat: 'private' },
    { label: 'VIP', label_es: 'Vip', label_en: 'Vip', value: 'vip', checked: false, chat: 'vip' },
  ]);
  const [contents, setContents] = useState([
    { label: 'Todos', label_es: 'Todos', label_en: 'All', value: '0', checked: true, segment: '0' },
    { label: 'Chicas', label_es: 'Chicas', label_en: 'Girls', value: 'girls', checked: false, segment: '1' },
    { label: 'Trans', label_es: 'Trans', label_en: 'Trans', value: 'trans', checked: false, segment: '2' },
    //{ label: 'Chicos', label_es: 'Chicos', label_en: 'Boys', value: 'boys', checked: false, segment: '3' },
    { label: 'Parejas', label_es: 'Parejas', label_en: 'Couples', value: 'couples', checked: false, segment: '4' },
  ]);
  const [ageCategories, setAgeCategories] = useState([
    { label: 'Todos', label_es: 'Todos', label_en: 'All', value: '', checked: true, age: '' },
    { label: '18-22', value: '18-22', checked: false, age: { ini: 18, end: 22 } },
    { label: '23-30', value: '23-30', checked: false, age: { ini: 23, end: 30 } },
    { label: '31-40', value: '31-40', checked: false, age: { ini: 31, end: 40 } },
    { label: '40+', value: '40+', checked: false, age: { ini: 40, end: 150 } },
  ]);
  const [categories, setCategories] = useState([]);
  const [maleCategories, setMaleCategories] = useState(['']);
  const [resultUrl, setResultUrl] = useState(null);
  const [copyButtonText, setCopyButtonText] = useState(<FormattedMessage id="promo-copy-code-clipboard" />);
  const [randomIframe, setRandomIframe] = useState(1);
  const [iframeStyleRaw, setIframeStyleRaw] = useState('width: 100%; min-width: 70px;');
  const [iframeRawCode, setIframeRawCode] = useState('');
  const [whiteLabels, setWhiteLabels] = useState(null);

  const getData = useCallback(async () => {
    WhiteLabelsService.get({}).then((responseData) => {
      if (responseData.status === 'Accepted') setWhiteLabels(responseData.message);
    });

    let countries_list = [];
    let categories_list = [];
    let label;

    await RoomlistService.countriesList({ segment: 'all' }).then((responseData) => {
      countries_list.push({ label: 'Todos', label_es: 'Todos', label_en: 'All', value: '', checked: true });
      responseData.message.forEach((country, index) => {
        countries_json.forEach((item, index1) => {
          if (country.COUNTRY === item.iso2) {
            getLang() === 'en' ? (label = item.en) : (label = item.es);
            countries_list.push({ label: label, label_es: item.es, label_en: item.en, value: country.COUNTRY, checked: false });
          }
        });
      });

      setCountries(countries_list);
    });

    RoomlistService.categoryList({ segment: 0 }).then((responseData) => {
      //categories_list.unshift({'label': 'Todos', 'label_es': 'Todos', 'label_en': 'All', value: '', checked: true, show: true});
      responseData.message.forEach((element, index) => {
        getLang() === 'en' ? (label = element.names.en) : (label = element.names.es);
        categories_list.push({
          label: label,
          label_es: element.names.es,
          label_en: element.names.en,
          value: element.id,
          checked: false,
          show: true,
        });
      });
      categories_list.unshift({ label: 'Todos', label_es: 'Todos', label_en: 'All', value: '', checked: true, show: true });
      //res.data.message.unshift({'label': 'Todos', 'label_es': 'Todos', 'label_en': 'All', value: '', checked: true, show: true});
      setCategories(categories_list);
    });

    if (id_edit_roomlist !== '') {
      // Get the roomlist data and enter on mode Edit
      RoomlistService.get(id_edit_roomlist).then((responseData) => {
        if (responseData.status === 'Accepted') {
          //let { categories } = this.state;
          let age_value = '';

          countries_list.forEach((element) => {
            element.checked = element.value === responseData.message[0].countries ? true : false;
          });

          categories_list.forEach((element) => {
            element.checked = element.value === parseInt(responseData.message[0].categories) ? true : false;
          });

          contents.forEach((element) => {
            if (element.segment === responseData.message[0].content) element.checked = true;
            else element.checked = false;
          });

          ageCategories.forEach((element) => {
            if (element.value === responseData.message[0].age_categories) {
              element.checked = true;
              age_value = element.age;
            } else element.checked = false;
          });

          chat.forEach((element) => {
            element.checked = element.chat === responseData.message[0].chat ? true : false;
          });

          setIframeStyleRaw(responseData.message[0].iframe_size === 'adaptive' ? 'width: 100%; min-width: 70px;' : '');

          setPrevCampaign(responseData.message[0].campaign);
          setPrevDomain(responseData.message[0].domain);

          setConfigForm({
            ...configForm,
            campaign: responseData.message[0].campaign,
            domain: responseData.message[0].domain,
            prefix_afno: '1-' + auth.id_affiliate + '-',
            afno: responseData.message[0].afno,
            access_url: responseData.message[0].access_url,
            whitelabel_url: responseData.message[0].whitelabel_url,
          });

          setDesignForm({
            ...designForm,
            page_bg_color: responseData.message[0].page_bg_color,
            nick_color: responseData.message[0].nick_color,
            nick_bg_color: responseData.message[0].nick_bg_color,
            button_text_color: responseData.message[0].button_text_color,
            button_bg_color: responseData.message[0].button_bg_color,
            pager: responseData.message[0].pager,
            pager_limit: responseData.message[0].pager_limit,
            thumb_size: responseData.message[0].thumb_size,
            iframe_size: responseData.message[0].iframe_size,
            iframe_height: responseData.message[0].iframe_height,
            iframe_width: responseData.message[0].iframe_width,
            roomlist_limit: Boolean(responseData.message[0].roomlist_limit),
            roomlist_limit_count: responseData.message[0].roomlist_limit_count,
          });

          setFilters({
            ...filters,
            segment: responseData.message[0].content,
            category: responseData.message[0].categories,
            country: responseData.message[0].countries,
            age: age_value,
            chat: responseData.message[0].chat,
          });

          setResultUrl(responseData.message[0].url);
        }
        //getDemoDataRoomlist(local_filters);
      });
    } /* else getDemoDataRoomlist(local_filters); */

    let _male_categories = [''];
    RoomlistService.categoryList({ segment: 3 }).then((responseData) => {
      responseData.message.forEach((element, index) => {
        _male_categories.push(element.id);
      });
      setMaleCategories(_male_categories);
    });
  }, []);

  //useEffect(() => {async getData }, [getData]);

  useEffect(() => {
    async function fetchData() {
      getData();
    }
    fetchData();
  }, [getData]); // Or [] if effect doesn't need props or state

  const resetIframe = () => setRandomIframe(randomIframe + 1);

  const defaultDesigns = (type) => {
    let _designForm = designForm;

    switch (type) {
      case 'design1':
        _designForm = {
          ...designForm,
          page_bg_color: '#ffffff',
          nick_color: '#ffffff',
          nick_bg_color: '#000000',
          button_text_color: '#ffffff',
          button_bg_color: '#15EB37',
        };
        break;

      case 'design2':
        _designForm = {
          ...designForm,
          page_bg_color: '#ffffff',
          nick_color: '#ffffff',
          nick_bg_color: '#000000',
          button_text_color: '#ffffff',
          button_bg_color: '#282828',
        };
        break;

      case 'design3':
        _designForm = {
          ...designForm,
          page_bg_color: '#ffffff',
          nick_color: '#ffffff',
          nick_bg_color: '#000000',
          button_text_color: '#ffffff',
          button_bg_color: '#e21414',
        };
        break;

      case 'design4':
        _designForm = {
          ...designForm,
          page_bg_color: '#eeeeee',
          nick_color: '#ffffff',
          nick_bg_color: '#ffffff',
          button_text_color: '#000000',
          button_bg_color: '#ffffff',
        };
        break;
      case 'olecams':
      default:
        _designForm = {
          ...designForm,
          page_bg_color: '#282828',
          nick_color: '#ffffff',
          nick_bg_color: '#ffffff',
          button_text_color: '#ffffff',
          button_bg_color: '#FF7700',
        };
        break;
    }

    setDesignForm(_designForm);
    resetIframe();
  };

  const handleOnChange = (e, type) => {
    let { value, name } = e.target;
    if (type === 'configForm') {
      let _configForm = configForm;

      // Clean AFNO
      if (name === 'afno') value = cleanString(value);
      setConfigForm({ ..._configForm, [name]: value });
    } else if (type === 'designForm') {
      let _designForm = designForm;
      if (name === 'limit') {
        // Limit between 1 and 200
        if (value < 1) value = 1;
        else if (value > 200) value = 200;
      }

      if (value !== 'custom' && name === 'iframe_size') _designForm.iframe_width = null;

      if (value !== 'custom' && value !== 'adaptive' && name === 'iframe_size') {
        let array = value.split('x');
        _designForm.iframe_width = parseInt(array[0]);
        _designForm.iframe_height = parseInt(array[1]);
      }

      if ([name] === 'iframe_width' || [name] === 'iframe_height') value = parseInt(value);

      let _iframeStyleRaw = iframeStyleRaw;

      if (value === 'adaptive' && name === 'iframe_size')
        _iframeStyleRaw = 'width: 100%; min-width: 70px; height: ' + designForm.iframe_height + 'px;';
      else if (value !== 'adaptative' && name === 'iframe_size') _iframeStyleRaw = '';

      setDesignForm({ ..._designForm, [name]: value });
      setIframeStyleRaw(_iframeStyleRaw);
    }

    resetIframe();
  };

  const handleCheckbox = (e) => {
    let { name } = e.target;

    let _designForm = designForm;
    if (name === 'pager') {
      if (_designForm.pager) _designForm.pager = false;
      else {
        _designForm.pager = true;
        _designForm.roomlist_limit = true;
      }
    } else if (name === 'roomlist_limit' && !_designForm.pager) _designForm.roomlist_limit = !designForm.roomlist_limit;

    setDesignForm(_designForm);

    resetIframe();
  };

  const handleRadioDesignOnChange = (item) => {
    let _languagesList = languagesList;
    _languagesList.forEach((element) => (element.checked = element.value === item.value ? true : false));
    setLanguagesList(_languagesList);
    resetIframe();
  };

  const handleRadioOnChange = (item, type) => {
    // Get Roomlists data
    let local_filter = filters;
    let prev_segment = filters.segment;

    let _contents = contents;
    let _countries = countries;
    let _categories = categories;
    let _ageCategories = ageCategories;
    let _chat = chat;

    switch (type) {
      case 'content':
        _contents.forEach((element) => {
          if (element.value === item.value) {
            element.checked = true;
            local_filter.segment = element.segment;
          } else element.checked = false;
        });

        setContents(_contents);
        setFilters({ ...filters, segment: local_filter.segment });
        break;
      case 'countries':
        _countries.forEach((element) => {
          if (element.value === item.value) {
            element.checked = true;
            local_filter.country = element.value;
          } else element.checked = false;
        });

        setCountries(_countries);
        setFilters({ ...filters, country: local_filter.country });
        break;

      case 'categories':
        _categories.forEach((element) => {
          if (element.value === item.value) {
            element.checked = true;
            local_filter.category = element.value;
          } else element.checked = false;
        });

        setCategories(_categories);
        setFilters({ ...filters, category: local_filter.category });
        break;

      case 'age_categories':
        _ageCategories.forEach((element) => {
          if (element.value === item.value) {
            element.checked = true;
            //age_filters = element.age
            local_filter.age = element.age;
          } else element.checked = false;
        });

        setAgeCategories(_ageCategories);
        setFilters({ ...filters, age: local_filter.age });
        break;

      case 'chat_type':
        _chat.forEach((element) => {
          if (element.value === item.value) {
            element.checked = true;
            local_filter.chat = element.value;
          } else element.checked = false;
        });

        setChat(_chat);
        setFilters({ ...filters, chat: local_filter.chat });
        break;

      default:
    }

    let categories_list = [];

    switch (parseInt(local_filter.segment)) {
      case 0:
        categories.forEach((element, index) => {
          element.show = true;
          categories_list.push(element);
        });
        break;
      case 1:
      case 2:
      case 4:
        if (prev_segment === local_filter.segment) {
          categories.forEach((element, index) => {
            !maleCategories.includes(element.value) ? (element.show = true) : (element.show = false);
            if (element.value === '') element.show = true;
            categories_list.push(element);
          });
        } else {
          categories.forEach((element, index) => {
            !maleCategories.includes(element.value) ? (element.show = true) : (element.show = false);
            element.checked = false;
            if (element.value === '') {
              element.show = true;
              element.checked = true;
            }
            categories_list.push(element);
          });
        }

        break;

      case 3:
        if (prev_segment === local_filter.segment) {
          categories.forEach((element, index) => {
            maleCategories.includes(element.value) ? (element.show = true) : (element.show = false);
            categories_list.push(element);
          });
        } else {
          categories.forEach((element, index) => {
            maleCategories.includes(element.value) ? (element.show = true) : (element.show = false);
            element.checked = false;

            if (element.value === '') {
              element.show = true;
              element.checked = true;
            }
            categories_list.push(element);
          });
        }

        break;
      default:
    }

    setCategories(categories_list);
    resetIframe();
  };

  const saveRoomList = () => {
    let msg = 'promo-sure-create-roomlist';
    if (id_edit_roomlist !== '') msg = 'promo-sure-update-roomlist';

    let width = '';
    if (designForm.iframe_size !== 'adaptive') width = designForm.iframe_width;

    let categories_list = [];
    let contents_list = [];
    let age_categories_list = [];
    let countries_list = [];
    let chat_list = [];

    if (validator.allValid()) {
      if (window.confirm(intl.formatMessage({ id: msg }))) {
        //dispatch({ type: 'SET_LOADING', loading: true });
        let { campaign, afno, domain, access_url, whitelabel_url } = configForm;
        // Check if everything is OK

        let data = {};
        data = designForm;
        // Config Form
        data.afno = afno;
        data.campaign = campaign;
        data.domain = domain;
        data.access_url = access_url;
        data.whitelabel_url = whitelabel_url;
        data.categories = categories_list;
        data.age_categories = age_categories_list;
        data.content = contents_list;
        data.countries = countries_list;
        data.chat = chat_list;

        // Contents
        contents.forEach((element) => element.checked && data.content.push(element.segment));
        // Categories
        categories.forEach((element) => element.checked && data.categories.push(element.value));
        // Ages Categories
        ageCategories.forEach((element) => element.checked && data.age_categories.push(element.value));
        // Countries
        countries.forEach((element) => element.checked && data.countries.push(element.value));
        // Chat Type
        chat.forEach((element) => element.checked && data.chat.push(element.value));

        if (id_edit_roomlist !== '') {
          data.id = id_edit_roomlist;

          dispatch({ type: 'SET_LOADING', loading: true });
          RoomlistService.update(data)
            .then((responseData) => {
              if (responseData.status === 'Accepted') {
                toast.success(<FormattedMessage id="promo-roomlist-created-success" />);

                setIframeRawCode(
                  "<iframe scrolling='no' frameborder='0' style='" +
                    iframeStyleRaw +
                    "' height='" +
                    designForm.iframe_height +
                    "' width='" +
                    width +
                    "' src='" +
                    responseData.message.url +
                    "'></iframe>"
                );
                setResultUrl(responseData.message.url);
                setShowModal(true);
              } else toast.error(<FormattedMessage id="promo-roomlist-created-error" />);

              //this.getSavedRoomlists()
            })
            .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));

          /* update(auth.token, data)
            .then((response) => {
              if (response.data.status === 'Accepted') {
                toast.success(translate('promo-roomlist-created-success'));

                setIframeRawCode(
                  "<iframe scrolling='no' frameborder='0' style='" +
                    iframeStyleRaw +
                    "' height='" +
                    designForm.iframe_height +
                    "' width='" +
                    width +
                    "' src='" +
                    response.data.message.url +
                    "'></iframe>"
                );
                setResultUrl(response.data.message.url);
                setShowModal(true);
              } else toast.error(translate('promo-roomlist-created-error'));

              //this.getSavedRoomlists()
            })
            .finally(() => dispatch({ type: 'SET_LOADING', loading: false })); */
        } else {
          dispatch({ type: 'SET_LOADING', loading: true });
          RoomlistService.set(data)
            .then((responseData) => {
              if (responseData.status === 'Accepted') {
                toast.success(<FormattedMessage id="promo-roomlist-created-success" />);

                setIframeRawCode(
                  "<iframe scrolling='no' frameborder='0' style='" +
                    iframeStyleRaw +
                    "' height='" +
                    designForm.iframe_height +
                    "' width='" +
                    width +
                    "' src='" +
                    responseData.message.url +
                    "'></iframe>"
                );
                setResultUrl(responseData.message.url);
                setShowModal(true);
              } else toast.error(<FormattedMessage id="promo-roomlist-created-error" />);

              //this.getSavedRoomlists()
            })
            .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));

          /* set(auth.token, data)
            .then((response) => {
              if (response.data.status === 'Accepted') {
                toast.success(translate('promo-roomlist-created-success'));

                setIframeRawCode(
                  "<iframe scrolling='no' frameborder='0' style='" +
                    iframeStyleRaw +
                    "' height='" +
                    designForm.iframe_height +
                    "' width='" +
                    width +
                    "' src='" +
                    response.data.message.url +
                    "'></iframe>"
                );
                setResultUrl(response.data.message.url);
                setShowModal(true);
              } else toast.error(translate('promo-roomlist-created-error'));

              //this.getSavedRoomlists()
            })
            .finally(() => dispatch({ type: 'SET_LOADING', loading: false })); */
        }
      }
    } else {
      toast.error(<FormattedMessage id="global-form-errors" />);
      showValidationMessage(true);
    }
  };

  const goToHome = () => {
    dispatch({ type: 'SET_LOADING', loading: true });
    setShowModal(false);
    history.push('/promocion/banners-dinamicos');
  };

  /* const goToDemo = () => {
    removeRoomlistData();
    let data = {
      filters: filters,
      designForm: designForm,
    };
    setRoomlistData(data);
    // route to new page by changing window.location
    window.open('/promocion/banners-dinamicos/demo', '_blank'); //to open new page
  }; */

  const copyButtonClick = () => {
    navigator.clipboard.writeText(iframeRawCode);
    setCopyButtonText(<FormattedMessage id="global-copied" />);
  };

  if (loading) return <Loading />;

  return (
    <>
      <Row className="main-roomlist">
        <Col sm="12">
          <Card className="roomlist-setup">
            <Card.Header>
              <FontAwesomeIcon icon={faClipboardList} className="mr-2" />
              {id_edit_roomlist !== '' ? (
                <div>
                  <FormattedMessage id="global-editing" />{' '}
                  <b>
                    {prevCampaign} - {prevDomain}
                  </b>
                </div>
              ) : (
                <div>
                  {' '}
                  <FormattedMessage id="promo-setup-roomlist" />
                </div>
              )}
            </Card.Header>
            <Card.Body>
              <Col sm="12">
                <ConfigForm handleOnChange={handleOnChange} configForm={configForm} white_labels={whiteLabels} validator={validator} />
              </Col>
              <div className="data-content">
                <div className="left">
                  <FiltersForm
                    handleOnChange={handleOnChange}
                    contents={contents}
                    countries={countries}
                    categories={categories}
                    age_categories={ageCategories}
                    chat_type={chat}
                    handleRadioOnChange={handleRadioOnChange}
                    validator={validator}
                  />
                </div>
                <div className="right">
                  <DesignForm
                    handleOnChange={handleOnChange}
                    handleRadioOnChange={handleRadioDesignOnChange}
                    designForm={designForm}
                    defaultDesigns={defaultDesigns}
                    afno={configForm.prefix_afno + configForm.afno}
                    handleCheckbox={handleCheckbox}
                    languages_list={languagesList}
                  />
                </div>
              </div>
              <hr />
              <Card>
                <Card.Header>
                  <FontAwesomeIcon icon={faEye} className="mr-2" /> <FormattedMessage id="promo-roomlist-preview" />
                </Card.Header>
                <DemoIframe
                  random={randomIframe}
                  designForm={designForm}
                  configForm={configForm}
                  filters={filters}
                  id_edit_roomlist={id_edit_roomlist}
                  set_result_url={resultUrl}
                  iframe_style_raw={iframeStyleRaw}
                  languages_list={languagesList}
                />
              </Card>
              <Row className="mt-3">
                <Col>
                  <CustomButton rounded className="w-100" onClick={(e) => saveRoomList()}>
                    <FontAwesomeIcon icon={faSave} className="mr-2" />
                    <span>
                      {id_edit_roomlist !== '' ? (
                        <FormattedMessage id="global-update" />
                      ) : (
                        <FormattedMessage id="administration-data-button" />
                      )}
                    </span>
                  </CustomButton>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModal}
        onHide={(e) => setShowModal(false)}
        dialogClassName="">
        <Modal.Header closeButton>
          <Modal.Title>
            {' '}
            {id_edit_roomlist !== '' ? (
              <FormattedMessage id="promo-roomlist-updated-successful" />
            ) : (
              <FormattedMessage id="promo-roomlist-created-successful" />
            )}{' '}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card>
            <Card.Header>
              <FontAwesomeIcon icon={faCode} className="mr-2" /> <FormattedMessage id="promo-iframe-code-result" />{' '}
            </Card.Header>
            <Card.Body>
              <Form.Control rows={3} as="textarea" disabled value={iframeRawCode} />
            </Card.Body>
          </Card>
        </Modal.Body>
        <Modal.Footer>
          <CustomButton rounded onClick={() => copyButtonClick()}>
            {copyButtonText}
          </CustomButton>
          <CustomButton rounded blue onClick={() => goToHome()}>
            <FormattedMessage id="global-close-return" />
          </CustomButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MainForm;

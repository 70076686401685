import { Helmet } from 'react-helmet';

const CustomHelmet = ({ lang }) => {
  let title = 'Oletraffic - El mejor programa de afiliación para adultos';
  let description =
    'Únete a Oletraffic - el mejor programa de afiliados para adultos de Olecams.com. Maximiza tus ingresos, crea marcas blancas y gestiona facilmente banners dinámicos y útiles herramientas promocionales!';
  let keywords =
    'oletraffic, olecams, programa de afiliados de olecams.com, programa asociado de bongacams, programa de pago por venta, pago por referencia de clientes potenciales, pago gratuito por email, pps, reparto de ingresos, ganar dinero, cpa, revenue share';
  if (lang === 'en') {
    title = 'Oletraffic - The best affiliate program for adults';
    description =
      'Oletraffic - the best adult affiliate program. Maximize your income, create white labels and easily manage dynamic banners and useful promotional tools!';
    keywords =
      'oletraffic, olecams, olecams.com affiliate program, pay per sale program, pay per lead referral, free pay by email, pps, revenue share, make money, cpa, revenue share';
  }

  let twitter_creator = '@olecams1';
  return (
    <Helmet>
      <title>{title}</title>
      <link rel="canonical" href="https://oletraffic.com" />
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta name="og:title" content={title} />
      <meta name="twitter:title" content={title} />
      <met name="twitter:creator" content={twitter_creator} />
      <met name="twitter:site" content={twitter_creator} />
      <meta property="og:image" content="/images/opengraph.jpg" />
      <meta name="twitter:image" content="/images/opengraph.jpg" />
      <meta itemprop="image" content="/images/opengraph.jpg" />
    </Helmet>
  );
};

export default CustomHelmet;

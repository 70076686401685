import { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import es from 'date-fns/locale/es';
import { Form, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import DatePicker, { registerLocale } from 'react-datepicker';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faPlus, faMinus, faCheckSquare, faSquare, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

import Calendar from 'components/icons/calendar';

// Libs
import { getLang } from 'libs/cookies';
import { TYPES, PROMO_TYPE } from 'libs/promotions';
import { cleanString } from 'libs/methods';

import CustomButton from 'components/button';

// Hooks
import useValidator from 'hooks/useValidator';

import Countries from 'components/Discounts/Countries';
import Descriptions from 'components/Discounts/Descriptions';
import CreditPacksSection from 'components/Discounts/CreditPacks';
import WhiteLabelSelector from 'components/Discounts/WhiteLabelSelector';
import UserAssignment from 'components/Discounts/UserAssignment';
import AffiliateSelector from 'components/Discounts/AffiliateSelector';

const DiscountsForm = ({
  data,
  setData,
  creditPacks,
  createUpdate,
  startDate,
  handleStartDate,
  endDate,
  handleEndDate,
  countries,
  setCountries,
  moreCountries,
  setMoreCountries,
}) => {
  const { formatMessage } = useIntl();
  registerLocale('es', es);

  const [validator, showValidationMessage] = useValidator({}, {}, getLang());

  const [promoType, setPromoType] = useState(PROMO_TYPE.DISCOUNT);

  const createUpdateBtn = (e) => {
    validator.allValid() ? createUpdate() : showValidationMessage(true);
  };

  const handleData = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setData({
      ...data,
      [name]: checked,
    });
  };

  const checkCode = (e) => {
    setData({
      ...data,
      CODE: cleanString(e.target.value.toUpperCase()),
    });
  };

  const handleCreditPacks = (e) => {
    const { selectedOptions } = e.target;
    //Array.from(selectedOptions, (item) => item.value);
    setData({
      ...data,
      CREDIT_PACKS: Array.from(selectedOptions, (item) => item.value),
    });
  };

  const handlePromoType = (e) => {
    const { value } = e.target;
    setPromoType(value);

    if (value === PROMO_TYPE.DISCOUNT) {
      setData({
        ...data,
        DISCOUNT: 1,
        CREDIT_FACTOR: 1,
        CREDIT_GIFT: 0,
      });
    }

    if (value !== PROMO_TYPE.DISCOUNT) {
      setData({
        ...data,
        DISCOUNT: 0,
        CREDIT_FACTOR: 1,
        CREDIT_GIFT: 0,
      });
    }
  };

  useEffect(() => {
    if (parseInt(data.DISCOUNT) > 0) {
      setPromoType(PROMO_TYPE.DISCOUNT);
    }
    if (data.CREDIT_FACTOR > 1) {
      setPromoType(PROMO_TYPE.CREDIT_FACTOR);
    }
    if (parseInt(data.CREDIT_GIFT) > 0) {
      setPromoType(PROMO_TYPE.CREDIT_GIFT);
    }
  }, [data.DISCOUNT, data.CREDIT_FACTOR, data.CREDIT_GIFT, promoType]);

  return (
    <Row className="justify-content-between">
      <Col>
        <Form id="form" className="form">
          <Row>
            <Col xl="6">
              <Row>
                <Col xl="6">
                  <Form.Group as={Row} className="mb-3 required" controlId="formDiscount">
                    <Form.Label column sm="6" className="font-weight-bold">
                      Descuento (%) factor o regalo créditos
                    </Form.Label>
                    <Col sm="6">
                      <select className="form-control" value={promoType} onChange={handlePromoType}>
                        <option value={PROMO_TYPE.DISCOUNT}>Descuento</option>
                        <option value={PROMO_TYPE.CREDIT_FACTOR}>Factor de multiplicación</option>
                        <option value={PROMO_TYPE.CREDIT_GIFT}>Regalo de créditos</option>
                      </select>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col xl="6">
                  <DiscountType data={data} setData={setData} />
                </Col>
              </Row>

              <Row>
                {promoType === PROMO_TYPE.DISCOUNT && (
                  <Col xl="6">
                    <Form.Group as={Row} className="mb-3 required" controlId="formDiscount">
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id={`tooltip-top`}>Descuento en el precio de los paquetes por porcentaje</Tooltip>}>
                        <Form.Label column sm="6" className="font-weight-bold">
                          {formatMessage({ id: 'global-discount' })} (%) <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
                        </Form.Label>
                      </OverlayTrigger>
                      <Col sm="6">
                        <Form.Control
                          type="number"
                          min="0"
                          max="60"
                          value={data.DISCOUNT}
                          name="DISCOUNT"
                          placeholder=""
                          onChange={handleData}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                )}
                {promoType === PROMO_TYPE.CREDIT_FACTOR && (
                  <Col xl="6">
                    <Form.Group as={Row} className="mb-3 required" controlId="formDiscount">
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id={`tooltip-top`}>
                            Factor de multiplicación en el regalo de créditos, por ejemplo para el paquete de 60 con factor 2 sería un total
                            de 120 créditos
                          </Tooltip>
                        }>
                        <Form.Label column sm="6" className="font-weight-bold">
                          Factor (*) <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
                        </Form.Label>
                      </OverlayTrigger>

                      <Col sm="6">
                        <input
                          className="form-control"
                          type="number"
                          name="CREDIT_FACTOR"
                          value={data.CREDIT_FACTOR}
                          onChange={handleData}
                          min="1"
                          step=".01"
                          required
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                )}

                {promoType === PROMO_TYPE.CREDIT_GIFT && (
                  <Col xl="6">
                    <Form.Group as={Row} className="mb-3 required" controlId="formDiscount">
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id={`tooltip-top`}>Regalo de una cantidad de créditos fija con cada paquete</Tooltip>}>
                        <Form.Label column sm="6" className="font-weight-bold">
                          Créditos a regalar <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
                        </Form.Label>
                      </OverlayTrigger>
                      <Col sm="6">
                        <Form.Control
                          type="number"
                          min="0"
                          value={data.CREDIT_GIFT}
                          name="CREDIT_GIFT"
                          placeholder=""
                          onChange={handleData}
                          required
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                )}
              </Row>

              {data.TYPE !== TYPES.GLOBAL && (
                <Form.Group as={Row} className="mb-3 required" controlId="formName">
                  <Form.Label column sm="3" className="font-weight-bold">
                    {formatMessage({ id: 'global-code' })}
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control value={data.CODE} name="CODE" placeholder="" onChange={handleData} onBlur={checkCode} />
                    {validator.message(formatMessage({ id: 'global-code' }).toLowerCase(), data.CODE, 'max: 255|required')}
                  </Col>
                </Form.Group>
              )}

              {data.TYPE === TYPES.GLOBAL && <WithModalSection handleCheck={handleCheck} data={data} />}
              <Descriptions data={data} validator={validator} handleData={handleData} />
              {data.TYPE === TYPES.CAMPAIGN && <UserNoPurchasesSection handleCheck={handleCheck} data={data} />}
              {data.TYPE !== TYPES.GLOBAL && (
                <LimitSection data={data} handleData={handleData} setData={setData} handleCheck={handleCheck} validator={validator} />
              )}
              {data.TYPE === TYPES.INDIVIDUAL && <UserAssignment data={data} handleChange={setData} />}
              {/*props.type === 'individual' && (
                                <Form.Group
                                    as={Row}
                                    className="mb-3 required"
                                    controlId="formUseLimit">
                                    <Form.Label
                                        column
                                        sm="3"
                                        className="font-weight-bold">
                                        Usuario Olecams
                                    </Form.Label>
                                    <Col sm="9">
                                        <Form.Control
                                            value={data.PROFILE_ID}
                                            name="PROFILE_ID"
                                            placeholder=""
                                            onChange={handleData}
                                        />
                                    </Col>
                                </Form.Group>
                            )*/}
              {/*<Form.Group as={Row} className="mb-3 required" controlId="formContent">
                <Form.Label column sm="3" className="font-weight-bold">
                  {translate('global-content')}
                </Form.Label>
                <Col sm="9">
                  <select className="form-control" onChange={handleData} name="CONTENT" defaultValue={data.CONTENT}>
                    <FormattedMessage id="global-all">
                      {(msg) => (
                        <option value="all" selected={data.CONTENT === 'all'}>
                          {msg}
                        </option>
                      )}
                    </FormattedMessage>
                    <option value="media" selected={data.CONTENT === 'media'}>
                      Media
                    </option>
                  </select>
                </Col>
              </Form.Group>
              */}
              {/*<Form.Group as={Row} className="mb-3 required" controlId="formActive">
                <Form.Label column sm="3" className="font-weight-bold">
                  {translate('global-active')}
                </Form.Label>
                <Col sm="9">
                  <Form.Check type="checkbox" name="ACTIVE" checked={props.active} onChange={(e) => props.setActive(e.target.checked)} />
                </Col>
              </Form.Group>*/}
            </Col>
            <Col xl="6">
              <WhiteLabelSelector data={data} setData={setData} />
              {data.TYPE !== TYPES.GLOBAL && <AffiliateSelector data={data} setData={setData} />}
              <CreditPacksSection data={data} handleCreditPacks={handleCreditPacks} creditPacks={creditPacks} validator={validator} />
              <DateRangeSection startDate={startDate} handleStartDate={handleStartDate} endDate={endDate} handleEndDate={handleEndDate} />
              {/*<Form.Group
                                as={Row}
                                className="mb-3 required"
                                controlId="formGlobal">
                                <Form.Label
                                    column
                                    sm="3"
                                    className="font-weight-bold">
                                    Global
                                </Form.Label>
                                <Col sm="9">
                                    <Form.Check
                                        type="checkbox"
                                        name="GLOBAL"
                                        checked={data.GLOBAL}
                                        onChange={handleCheck}
                                    />
                                </Col>
                            </Form.Group>*/}
              <CountriesSection
                countries={countries}
                setCountries={setCountries}
                moreCountries={moreCountries}
                setMoreCountries={setMoreCountries}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <CustomButton rounded className="w-100" onClick={createUpdateBtn}>
                <FontAwesomeIcon icon={faSave} className="mr-2" />
                <span>{formatMessage({ id: 'administration-data-button' })}</span>
              </CustomButton>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};

const DiscountType = ({ data, setData }) => {
  const { formatMessage } = useIntl();
  const changeType = (e) => {
    const { name, value } = e.target;
    let useLimit = true;
    let limitPerUser = 1;
    let affiliates = data.AFFILIATES;

    if (value === TYPES.GLOBAL) {
      useLimit = false;
      limitPerUser = 0;
      affiliates = [];
    }

    setData({
      ...data,
      [name]: value,
      AFFILIATES: affiliates,
      USE_LIMIT: useLimit,
      USERS_WITHOUT_PURCHASES_ONLY: false,
      LIMIT_NUMBER: 0,
      LIMIT_PER_USER: limitPerUser,
      WITH_MODAL: false,
    });
  };

  return (
    <Form.Group as={Row} className="mb-3 required" controlId="formType">
      <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-top`}>Tipo de Descuento, Global no tiene límites</Tooltip>}>
        <Form.Label column sm="6" className="font-weight-bold">
          {formatMessage({ id: 'global-type' })} <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
        </Form.Label>
      </OverlayTrigger>
      <Col sm="6">
        <select className="form-control" onChange={changeType} name="TYPE" value={data.TYPE}>
          <option value={TYPES.GLOBAL}>Global</option>
          <FormattedMessage id="promo-campaign-name">{(msg) => <option value={TYPES.CAMPAIGN}>{msg}</option>}</FormattedMessage>
          <option value={TYPES.INDIVIDUAL}>Individual</option>
        </select>
      </Col>
    </Form.Group>
  );
};

const LimitSection = ({ data, handleData, setData, validator }) => {
  const { formatMessage } = useIntl();

  const handleLimitCheck = (e) => {
    const { name, checked } = e.target;
    if (!checked) {
      setData({
        ...data,
        [name]: checked,
        LIMIT_NUMBER: 0,
        LIMIT_PER_USER: 0,
      });
    } else {
      setData({
        ...data,
        [name]: checked,
        LIMIT_NUMBER: 0,
        LIMIT_PER_USER: 1,
      });
    }
  };

  return (
    <>
      <Form.Group as={Row} className="mb-3 required" controlId="formCheckLimit">
        <Form.Label column sm="3" className="font-weight-bold">
          Limitar
        </Form.Label>
        <Col sm="9">
          <Form.Check type="checkbox" name="USE_LIMIT" checked={data.USE_LIMIT} onChange={handleLimitCheck} />
        </Col>
      </Form.Group>

      {data.USE_LIMIT && (
        <>
          <Row>
            {data.TYPE !== 'INDIVIDUAL' && (
              <Col>
                <Form.Group as={Row} className="mb-3 required" controlId="formUseLimit">
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id={`tooltip-top`}>{formatMessage({ id: 'global-total-limit-explanation' })}</Tooltip>}>
                    <Form.Label column sm="6" className="font-weight-bold">
                      {formatMessage({ id: 'global-total-limit' })} <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
                    </Form.Label>
                  </OverlayTrigger>

                  <Col sm="6">
                    <Form.Control type="number" value={data.LIMIT_NUMBER} name="LIMIT_NUMBER" placeholder="" onChange={handleData} />
                    {validator.message(
                      formatMessage({ id: 'global-limit' }).toLowerCase(),
                      data.LIMIT_NUMBER,
                      'numeric|min:0,num|required'
                    )}
                  </Col>
                </Form.Group>
              </Col>
            )}
            <Col>
              <Form.Group as={Row} className="mb-3 required" controlId="formUseLimit">
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id={`tooltip-top`}>{formatMessage({ id: 'global-limit-per-user-explanation' })}</Tooltip>}>
                  <Form.Label column sm="6" className="font-weight-bold">
                    {formatMessage({ id: 'global-limit-per-user' })} <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
                  </Form.Label>
                </OverlayTrigger>
                <Col sm="6">
                  <Form.Control type="number" value={data.LIMIT_PER_USER} name="LIMIT_PER_USER" placeholder="" onChange={handleData} />
                  {validator.message(
                    formatMessage({ id: 'global-limit-per-user' }).toLowerCase(),
                    data.LIMIT_PER_USER,
                    'numeric|min:0,num|required'
                  )}
                </Col>
              </Form.Group>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

const UserNoPurchasesSection = ({ handleCheck, data }) => {
  const { formatMessage } = useIntl();
  return (
    <Form.Group as={Row} className="mb-3 required" controlId="formUsersWOPurchases">
      <Form.Label column sm="3" className="font-weight-bold">
        {formatMessage({ id: 'global-discount-only-purchase-users' })}
      </Form.Label>
      <Col sm="9">
        <Form.Check
          type="checkbox"
          name="USERS_WITHOUT_PURCHASES_ONLY"
          checked={data.USERS_WITHOUT_PURCHASES_ONLY}
          onChange={handleCheck}
        />
      </Col>
    </Form.Group>
  );
};

const WithModalSection = ({ handleCheck, data }) => {
  return (
    <Form.Group as={Row} className="mb-3" controlId="formWithModal">
      <Form.Label column sm="3" className="font-weight-bold">
        <FormattedMessage id="promo-with-modal-window" />
      </Form.Label>
      <Col sm="9">
        <Form.Check type="checkbox" name="WITH_MODAL" checked={data.WITH_MODAL} onChange={handleCheck} />
      </Col>
    </Form.Group>
  );
};

const DateRangeSection = ({ startDate, handleStartDate, endDate, handleEndDate }) => {
  return (
    <>
      <Row className="mb-3 required form-group calendar sp">
        <Form.Label column sm="3" className="font-weight-bold">
          <FormattedMessage id="global-start-date" />
        </Form.Label>
        <Col sm="9">
          <div className="form-control">
            <DatePicker
              selected={startDate}
              onChange={(date) => handleStartDate(date)}
              showTimeSelect
              locale={getLang()}
              dateFormat="dd/MM/yyyy HH:mm"
              id="calendar-date-start"
              popperPlacement="bottom"
              minDate={new Date()}
            />
          </div>
          <Calendar id="calendar-date-start" />
        </Col>
      </Row>

      <Row className="mb-3 required form-group calendar sp">
        <Form.Label column sm="3" className="font-weight-bold">
          <FormattedMessage id="global-end-date" />
        </Form.Label>
        <Col sm="9">
          <div className="form-control">
            <DatePicker
              selected={endDate}
              onChange={(date) => handleEndDate(date)}
              showTimeSelect
              locale={getLang()}
              dateFormat="dd/MM/yyyy HH:mm"
              id="calendar-date-end"
              popperPlacement="bottom"
              minDate={new Date()}
            />
          </div>
          <Calendar id="calendar-date-end" />
        </Col>
      </Row>
    </>
  );
};

const CountriesSection = ({ countries, setCountries, moreCountries, setMoreCountries }) => {
  const { formatMessage } = useIntl();
  const [showMoreCountries, setShowMoreCountries] = useState(false);

  const checkAll = (option) => {
    let _countries = countries;
    let _moreCountries = moreCountries;
    _countries.forEach((country) => {
      option === 'check' ? (country.checked = true) : (country.checked = false);
    });
    _moreCountries.forEach((country) => {
      option === 'check' ? (country.checked = true) : (country.checked = false);
    });

    setCountries([..._countries]);
    setMoreCountries([..._moreCountries]);
  };
  return (
    <>
      <Form.Group as={Row} className="mb-3" controlId="formCountries">
        <Form.Label column sm="4" className="font-weight-bold">
          {formatMessage({ id: 'global-countries' })}
        </Form.Label>
        <Col sm="8">
          <div className="">
            <Countries countries={countries} setCountries={setCountries} />
          </div>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3" controlId="formCountries">
        <Form.Label column sm="4" className="font-weight-bold">
          {!showMoreCountries ? (
            <CustomButton blue rounded onClick={(e) => setShowMoreCountries(true)} className="mb-2">
              <FontAwesomeIcon icon={faPlus} className="mr-2" />
              {formatMessage({ id: 'global-view-more' })}
            </CustomButton>
          ) : (
            <CustomButton blue rounded onClick={(e) => setShowMoreCountries(false)} className="mb-2">
              <FontAwesomeIcon icon={faMinus} className="mr-2" />
              {formatMessage({ id: 'global-view-less' })}
            </CustomButton>
          )}

          <CustomButton rounded blue onClick={(e) => checkAll('check')} className="mb-2">
            <FontAwesomeIcon icon={faCheckSquare} className="mr-2" />
            {formatMessage({ id: 'global-check-all' })}
          </CustomButton>
          <CustomButton rounded red onClick={(e) => checkAll('uncheck')} className="mb-2">
            <FontAwesomeIcon icon={faSquare} className="mr-2" />
            {formatMessage({ id: 'global-uncheck-all' })}
          </CustomButton>
        </Form.Label>
        <Col sm="8">
          <div>{showMoreCountries && <Countries countries={moreCountries} setCountries={setMoreCountries} />}</div>
        </Col>
      </Form.Group>
    </>
  );
};

export default DiscountsForm;

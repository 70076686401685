const Settings = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="22" height="22" viewBox="0 0 22 22">
      <path
        fill="#A2A9B0"
        id="prefix__a"
        d="M20.71 18.29l-1.42 1.42c-.39.388-1.02.388-1.41 0L6 7.85c-.325.094-.661.145-1 .15-1.292 0-2.504-.626-3.254-1.677C.996 5.27.8 3.922 1.22 2.7l2.54 2.54.53-.53 1.42-1.42.53-.53L3.7.22C4.922-.2 6.27-.004 7.323.746 8.374 1.496 8.999 2.708 9 4c-.005.339-.056.675-.15 1l11.86 11.88c.388.39.388 1.02 0 1.41zM1.29 16.88c-.388.39-.388 1.02 0 1.41l1.42 1.42c.39.388 1.02.388 1.41 0l5.47-5.46-2.83-2.83-5.47 5.46zM19 0l-4 2v2l-2.17 2.17 2 2L17 6h2l2-4-2-2z"
      />
    </svg>
  );
};

export default Settings;

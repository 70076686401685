const Plus = (props) => {
  return (
    <svg
      onClick={(e) => props.getStatDetall(props.id, props.provider)}
      className="plus"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      width="24"
      height="24"
      viewBox="0 0 24 24">
      <path fill="#A2A9B0" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
    </svg>
  );
};

export default Plus;

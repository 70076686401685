import { NavLink } from 'react-router-dom';

import translate from 'i18n/translate';

import { PUBLIC } from 'shared/panel-links';

import Layout from 'components/public-layout';
import MainValuesSection from '../MainValuesSection';

import './index.scss';

const Products = () => {
  return (
    <Layout cssClass="products">
      <section className="main">
        <div className="main-content">
          <div className="container">
            <div className="landing-sample white-bg" id="olecams">
              <div className="content-wrapper text-center">
                <picture>
                  <img
                    src="/images/logo-oc.png"
                    srcSet="/images/logo-oc.png 1x, /images/logo-oc@2x.png 2x, /images/logo-oc@3x.png 3x"
                    alt="olecams logo"
                    className="img-fluid"
                  />
                </picture>
                <p>{translate('public-home-oc-text')}</p>
                <div className="btn-wrapper text-center">
                  <NavLink to={PUBLIC.REGISTER} className="btn btn-default">
                    {translate('public-home-lets-start')}
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="multibibrate orange white-bg text-left">
        <div className="container">
          <div className="row m-bot">
            <div className="col-12 order-2 order-sm-1 col-sm-6 align-self-center">
              <picture>
                <img
                  src="/images/html-5.svg"
                  className="control-icon img-fluid animate"
                  alt="Streams de video Multibibrate"
                  data-anim-type="bounceInLeft"
                  data-anim-delay="200"
                />
              </picture>
            </div>
            <div className="col-12 col-sm-6 order-1 order-sm-2 align-self-center">
              <p>{translate('public-products-text1')}</p>
            </div>
          </div>

          <div className="row m-bot">
            <div className="col-12 col-sm-6 align-self-center">
              <p>{translate('public-products-text2')}</p>
            </div>
            <div className="col-12 col-sm-6 align-self-center">
              <picture>
                <img
                  src="/images/multibibrate.svg"
                  className="control-icon img-fluid animate float-sm-right"
                  alt="Streams de video Multibibrate"
                  data-anim-type="bounceInRight"
                  data-anim-delay="200"
                />
              </picture>
            </div>
          </div>
        </div>
      </section>

      <section className="description grad-bg">
        <div className="container">
          <div className="row m-bot">
            <h1>{translate('public-products-description-title')}</h1>
          </div>
          <div className="row m-bot">
            <div className="col-12 col-sm-4 align-self-center">
              <picture>
                <img src="/images/free-icon.svg" alt="Contenido Gratuito" className="img-fluid" />
              </picture>
            </div>
            <div className="col-12 col-sm-7 offset-sm-1 align-self-center">
              <h2>{translate('public-products-free-room')}</h2>
              <p>{translate('public-products-free-room-text')}</p>
            </div>
          </div>

          <div className="row m-bot">
            <div className="col-12 col-sm-7 order-2 order-sm-1 align-self-center">
              <h2>{translate('public-products-groupchat-room')}</h2>
              <p>{translate('public-products-groupchat-room-text')}</p>
            </div>
            <div className="col-12 col-sm-4 offset-sm-1 order-1 order-sm-2 align-self-center">
              <picture>
                <img src="/images/premium-icon.svg" alt="Suscripcion Premium" className="img-fluid" />
              </picture>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-sm-4 align-self-center">
              <picture>
                <img src="/images/free-icon.svg" alt="Contenido Gratuito" className="img-fluid" />
              </picture>
            </div>
            <div className="col-12 col-sm-7 offset-sm-1 align-self-center">
              <h2>{translate('public-products-vip-room')}</h2>
              <p>{translate('public-products-vip-room-text')}</p>
            </div>
          </div>
        </div>

        <div className="container" id="vce">
          <div className="landing-sample white-bg top">
            <div className="content-wrapper text-center">
              <picture>
                <img
                  src="/images/logo-vce.png"
                  srcSet="/images/logo-vce.png 1x, /images/logo-vce@2x.png 2x, /images/logo-vce@3x.png 3x"
                  alt="vce logo"
                  className="img-fluid"
                />
              </picture>
              <p>
                {translate('public-products-vce-text1')} <strong>{translate('public-products-vce-text2')} </strong>{' '}
                {translate('public-products-vce-text3')}{' '}
              </p>
              <div className="btn-wrapper text-center">
                <NavLink to={PUBLIC.REGISTER} className="btn btn-default">
                  {translate('public-home-lets-start')}
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="multibibrate blue white-bg text-left">
        <div className="container">
          <div className="row m-bot">
            <div className="col-12 col-sm-6 align-self-center">
              <h2>{translate('public-products-multibitrate')}</h2>
              <p>{translate('public-products-multibitrate-text')}</p>
            </div>
            <div className="col-12 col-sm-6 align-self-center">
              <picture>
                <img
                  src="/images/multibibrate-blue.svg"
                  className="control-icon img-fluid animate float-sm-right"
                  alt="Streams de video Multibibrate"
                  data-anim-type="bounceInRight"
                  data-anim-delay="200"
                />
              </picture>
            </div>
          </div>
          <div className="row">
            <div className="col-12 order-2 order-sm-1 col-sm-6 align-self-center">
              <picture>
                <img
                  src="/images/html-5-blue.svg"
                  className="control-icon img-fluid animate"
                  alt="Streams de video Multibibrate"
                  data-anim-type="bounceInLeft"
                  data-anim-delay="200"
                />
              </picture>
            </div>
            <div className="col-12 col-sm-6 order-1 order-sm-2 align-self-center">
              <h2>{translate('public-products-few-minutes')}</h2>
              <p>{translate('public-products-few-minutes-text')}</p>
            </div>
          </div>
        </div>
      </section>

      <section className="care grad-bg-blue">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-6">
              <h2>{translate('public-products-webcamer-preparation')}</h2>
              <br />
              <p>{translate('public-products-webcamer-preparation-text')}</p>
            </div>
            <div className="col-12 col-sm-6">
              <picture>
                <img
                  src="/images/conveyor-blue.svg"
                  className="conveyor-icon img-fluid animate"
                  alt="Streams de video Multibibrate"
                  data-anim-type="bounceInRight"
                  data-anim-delay="200"
                />
              </picture>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="btn-wrapper text-center">
                <NavLink to={PUBLIC.REGISTER} className="btn btn-default">
                  {translate('public-home-lets-start')}
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="gift white-bg text-center">
        <div className="container community-bg">
          <div className="col col-sm-8 offset-sm-2">
            <h2>
              {translate('public-products-give-visits')} <br />
              {translate('public-products-keep-users')}
            </h2>
            <p>{translate('public-products-give-visits-text')}</p>
          </div>
        </div>

        <div className="container" id="adptube">
          <div className="landing-sample white-bg bottom">
            <div className="content-wrapper text-center">
              <picture>
                <img
                  src="/images/logo-adp.png"
                  srcSet="/images/logo-adp.png 1x, /images/logo-adp@2x.png 2x, /images/logo-adp@3x.png 3x"
                  alt="vce logo"
                  className="img-fluid"
                />
              </picture>
              <p>{translate('public-products-adp')}</p>
              <div className="btn-wrapper text-center">
                <NavLink to={PUBLIC.REGISTER} className="btn btn-default">
                  {translate('public-home-lets-start')}
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="description grad-bg-pink">
        <div className="container">
          <div className="row m-bot">
            <div className="col-12 col-sm-4 align-self-center">
              <picture>
                <img src="/images/free-icon-pink.svg" alt="Contenido Gratuito" className="img-fluid" />
              </picture>
            </div>
            <div className="col-12 col-sm-7 offset-sm-1 align-self-center">
              <h2>{translate('public-products-free-content')}</h2>
              <p>{translate('public-products-free-content-text')}</p>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-sm-7 order-2 order-sm-1 align-self-center">
              <h2>{translate('public-products-premium-suscr')}</h2>
              <p>{translate('public-products-premium-suscr-text')}</p>
            </div>
            <div className="col-12 col-sm-4 offset-sm-1 order-1 order-sm-2 align-self-center">
              <picture>
                <img src="/images/premium-icon-pink.svg" alt="Suscripcion Premium" className="img-fluid" />
              </picture>
            </div>
          </div>
        </div>
      </section>

      <section className="embed text-center">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-10 offset-sm-1">
              <p>
                {translate('public-products-bottom-text1')} <strong>{translate('public-products-bottom-text2')}</strong>{' '}
                {translate('public-products-bottom-text3')}
              </p>
              <div className="btn-wrapper text-center">
                <NavLink to={PUBLIC.REGISTER} className="btn btn-default">
                  {translate('public-home-lets-start')}
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>

      <MainValuesSection />
    </Layout>
  );
};
export default Products;

import { Accordion, Card, Col, Row, Table } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList } from '@fortawesome/free-solid-svg-icons';

import translate from 'i18n/translate';
import { formatPrice } from 'libs/methods';

import ChangeStatus from './changeStatus';

const StatsTable = ({ data, title, getData }) => {
  const intl = useIntl();

  const statusFnt = (status) => {
    let css_class = '';
    switch (status) {
      case 1:
        css_class = 'pending';
        break;
      case 2:
        css_class = 'payed';
        break;
      case 3:
        css_class = 'under_min';
        break;
      case 4:
        css_class = 'received';
        break;
      default:
        css_class = '';
        break;
    }
    return css_class;
  };

  return (
    <Row>
      <Col>
        <Accordion className="current-month-accordion" defaultActiveKey="0">
          <Card className="mb-2">
            <Accordion.Toggle as={Card.Header} variant="link" eventKey="0" className="pointer">
              <FontAwesomeIcon icon={faClipboardList} className="mr-2" /> {intl.formatMessage({ id: title })}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <Table responsive striped bordered hover>
                  <thead>
                    <tr>
                      <th>{translate('global-date')}</th>
                      <th>{translate('global-period')}</th>
                      <th>VCE Phone</th>
                      <th>VCE TPV</th>
                      <th>VCE {translate('administration-billing-import-referred')}</th>
                      <th>Olecams</th>
                      <th>Olecams {translate('administration-billing-import-referred')}</th>
                      <th>Olecams Phone</th>
                      <th>Olecams SMS</th>
                      <th>Extras</th>
                      <th>SubTotal</th>
                      <th>{translate('global-status')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item, index) => {
                      return (
                        <tr className={statusFnt(item.estado)} key={item.id} id={item.id}>
                          <td>{item.fecha}</td>
                          <td>{item.periodo}</td>
                          <td>{formatPrice(item.e_adsle + item.e_movil)}</td>
                          <td>{formatPrice(item.e_int)}</td>
                          <td>{formatPrice(item.e_referidos)}</td>
                          <td>{formatPrice(item.e_olecams)}</td>
                          <td>{formatPrice(item.e_referidos_olecams)}</td>
                          <td>{formatPrice(item.e_phone_olecams)}</td>
                          <td>{formatPrice(item.e_sms_olecams)}</td>
                          <td>{item.extra}</td>
                          <td>{formatPrice(item.total_euros)}</td>
                          <td>
                            <ChangeStatus item={item} getData={getData} />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </Col>
    </Row>
  );
};

export default StatsTable;

import { FormattedMessage } from 'react-intl';

import { formatPrice } from 'libs/methods';

const Table = ({ view, stats, getStatsDay }) => {
  return (
    <>
      <table className="table">
        <thead>
          <tr>
            {view === 'month' && <MonthTableHead />}
            {view === 'day' && <DayTableHead />}
          </tr>
        </thead>
        <tbody>
          {view === 'month' && <MonthTableBody data={stats.data} getStatsDay={getStatsDay} />}
          {view === 'day' && <DayTableBody data={stats.data} />}
        </tbody>
      </table>
      {view === 'day' && <DayTableFooter summary={stats.summary} />}
      {view === 'month' && <MonthTableFooter summary={stats.summary} />}
    </>
  );
};

export default Table;

const DayTableHead = () => {
  return (
    <>
      <th scope="col">
        <FormattedMessage id="statistics-table-client" />
      </th>
      <th scope="col">
        <FormattedMessage id="statistics-table-n-sms" />
      </th>
      <th scope="col">
        <FormattedMessage id="statistics-table-country" />
      </th>
      <th scope="col">
        <FormattedMessage id="statistics-table-ope" />
      </th>
      <th scope="col">
        <FormattedMessage id="statistics-table-import" />{' '}
      </th>
    </>
  );
};

const DayTableBody = ({ data }) => {
  return data.map((stats, index) => {
    let tipo = (index + 1) % 2 ? 'impar' : 'par';
    let src = `${process.env.PUBLIC_URL}/flags/${stats.COUNTRY.toLowerCase()}.png`;
    return (
      <tr key={index} className={tipo}>
        <td>{stats.PHONE}</td>
        <td>{stats.COUNT_CALLS}</td>
        <td>
          <span className="flag">
            <img src={src} alt="flag" />
            {stats.COUNTRY}
          </span>
        </td>
        <td>{stats.CARRIER_NAME}</td>
        <td>{formatPrice(stats.PRICE)}</td>
      </tr>
    );
  });
};

const DayTableFooter = ({ summary }) => {
  return (
    <table className="table">
      <thead>
        <tr>
          <th scope="col">
            <FormattedMessage id="statistics-table-clients" />
          </th>
          <th scope="col">
            <FormattedMessage id="statistics-table-sms" />
          </th>
          <th scope="col">
            <FormattedMessage id="statistics-table-prom" />
          </th>
          <th scope="col">
            <FormattedMessage id="statistics-table-import" />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{summary.totalClients}</td>
          <td>{summary.totalSales}</td>
          <td>{summary.average}</td>
          <td>{formatPrice(summary.totalAmount)}</td>
        </tr>
      </tbody>
    </table>
  );
};

const MonthTableHead = () => {
  return (
    <>
      <th scope="col">
        <FormattedMessage id="statistics-table-day" />
      </th>
      <th scope="col">
        <FormattedMessage id="statistics-table-sale" />
      </th>
      <th scope="col">
        <FormattedMessage id="statistics-table-import" />
      </th>
    </>
  );
};

const MonthTableBody = ({ data, getStatsDay }) => {
  return data.map((stats, index) => {
    let tipo = (index + 1) % 2 ? 'impar' : 'par';
    return (
      <tr key={index} className={tipo}>
        <td
          className="hover"
          onClick={(e) => {
            getStatsDay(stats.day);
          }}>
          {stats.day}
        </td>
        <td>{stats.totalSales}</td>
        <td>{formatPrice(stats.totalAmount)}</td>
      </tr>
    );
  });
};

const MonthTableFooter = ({ summary }) => {
  return (
    <table className="table">
      <thead>
        <tr>
          <th scope="col">
            <FormattedMessage id="statistics-table-sale" />
          </th>
          <th scope="col">
            <FormattedMessage id="statistics-table-import" />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{summary.totalSales}</td>
          <td>{formatPrice(summary.totalAmount)}</td>
        </tr>
      </tbody>
    </table>
  );
};

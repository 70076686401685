import { useIntl } from 'react-intl';
import { Col, Row, Form } from 'react-bootstrap';

import translate from 'i18n/translate';

const Size = ({ designForm, setEmailValidated, emailValidated, handleOnChange }) => {
  const intl = useIntl();
  return (
    <>
      <Form.Group as={Row} className="mb-3" controlId="formSizes">
        <Form.Label column sm="6" className="font-weight-bold">
          {translate('global-size')}
        </Form.Label>
        <Col sm="6">
          <Form.Control
            as="select"
            custom
            name="iframe_size"
            onChange={(e) => handleOnChange(e, 'designForm')}
            value={designForm.iframe_size}>
            <option value="adaptive">{intl.formatMessage({ id: 'global-adaptive' })}</option>
            <option value="custom">{intl.formatMessage({ id: 'global-custom' })}</option>
            <option value="350x280">350x280</option>
            <option value="300x100">300x100</option>
            <option value="860x140">860x140</option>
            <option value="160x650">160x650</option>
            <option value="200x320">200x320</option>
            <option value="240x560">240x560</option>
            <option value="240x180">240x180</option>
            <option value="300x250">300x250</option>
            <option value="400x480">400x480</option>
            <option value="650x520">650x520</option>
            <option value="700x140">700x140</option>
            <option value="720x280">720x280</option>
            <option value="820x390">820x390</option>
            <option value="820x390">820x390</option>
          </Form.Control>
        </Col>
      </Form.Group>

      {designForm.iframe_size === 'custom' && (
        <Form.Group as={Row} className="mb-3" controlId="formSizes">
          <Form.Label column sm="6" className="font-weight-bold">
            {translate('herramientas-banner-table-width')}
          </Form.Label>
          <Col sm="6">
            <Form.Control
              type="number"
              min="160"
              max="1400"
              name="iframe_width"
              value={designForm.iframe_width}
              onChange={(e) => handleOnChange(e, 'designForm')}></Form.Control>
          </Col>
        </Form.Group>
      )}

      {(designForm.iframe_size === 'adaptive' || designForm.iframe_size === 'custom') && (
        <Form.Group as={Row} className="mb-3" controlId="formSizes">
          <Form.Label column sm="6" className="font-weight-bold">
            {translate('herramientas-banner-table-height')}
          </Form.Label>
          <Col sm="6">
            <Form.Control
              type="number"
              min="100"
              max="1400"
              name="iframe_height"
              value={designForm.iframe_height}
              onChange={(e) => handleOnChange(e, 'designForm')}></Form.Control>
          </Col>
        </Form.Group>
      )}
    </>
  );
};

export default Size;

import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons';

import BillingService from 'shared/billing-service';

import translate from 'i18n/translate';
import { isEmpty } from 'libs/lodashAlt';

//Component
import Button from 'components/button';
import Card from 'components/card';
import Loading from 'components/loading';

import BillingTable from './table';
import Form from './form';

// Icons
import IconHerramienta from 'components/icons/herramienta';
import IconDone from 'components/icons/done';

import '../index.scss';
import './index.scss';

const MonthBilling = () => {
  let loading = useSelector((state) => state.loading.loading);
  const dispatch = useDispatch();
  const intl = useIntl();

  let prevStartDate = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);

  const [date, setDate] = useState(prevStartDate);
  const [billingData, setBillingData] = useState(null);
  const [showSendEmails, setShowSendEmails] = useState(false);

  const dateFormat = (date) => {
    let month = String(date.getMonth() + 1).padStart(2, '0');
    let year = date.getFullYear();
    return year + '-' + month;
  };

  const handleChangeDate = (date) => setDate(date);

  const getData = () => {
    setBillingData(null);
    dispatch({ type: 'SET_LOADING', loading: true });
    BillingService.adminGetMonthBilling(dateFormat(date))
      .then((responseData) => {
        if (responseData.status === 'Failed') {
          if (responseData.message === 'getMonth KO Period Already Done') toast.info(translate('global-period-invoiced'));
        } else {
          setBillingData(responseData.message);
          setShowSendEmails(responseData.message.emailSent);
        }
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
  };

  const confirmBilling = () => {
    let msg = 'herramientas-confirm-billing-alert';
    if (window.confirm(intl.formatMessage({ id: msg }))) {
      dispatch({ type: 'SET_LOADING', loading: true });

      BillingService.adminConfirmMonth(dateFormat(date))
        .then((responseData) => {
          if (responseData.status === 'Failed') {
            if (responseData.message === 'confirmMonth KO Period Already Done') toast.info(translate('global-period-invoiced'));
            else if (responseData.message === 'confirmMonth KO Period Not Finished') toast.info(translate('global-period-not-finished'));
          } else {
            getData();
            toast.success(translate('global-confirmed-period'));
          }
        })
        .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));

      /* confirmMonth(dateFormat(date), auth.token, dispatch).then((res) => {
        if (res.data.status === 'Failed') {
          if (res.data.message === 'confirmMonth KO Period Already Done') toast.info(translate('global-period-invoiced'));
          else if (res.data.message === 'confirmMonth KO Period Not Finished') toast.info(translate('global-period-not-finished'));
        } else {
          getData();
          toast.success(translate('global-confirmed-period'));
        }
      }); */
    }
  };

  const sendEmails = () => {
    let msg = 'herramientas-sure-send-billing-emails';
    if (window.confirm(intl.formatMessage({ id: msg }))) {
      dispatch({ type: 'SET_LOADING', loading: true });
      BillingService.adminSendEmails(dateFormat(date))
        .then((responseData) => {
          if (responseData.status === 'Failed') toast.error(translate('global-emails-billing-error'));
          else {
            toast.success(translate('global-emails-billing-successful'));
            let today = new Date();
            setShowSendEmails(today.getDate() + '-' + parseInt(today.getMonth() + 1) + '-' + today.getFullYear());
          }
        })
        .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));

      /* sendEmailsBilling(dateFormat(date), auth.token, dispatch).then((res) => {
        if (res.data.status === 'Failed') toast.error(translate('global-emails-billing-error'));
        else {
          toast.success(translate('global-emails-billing-successful'));
          let today = new Date();
          setShowSendEmails(today.getDate() + '-' + parseInt(today.getMonth() + 1) + '-' + today.getFullYear());
        }
      }); */
    }
  };

  if (loading) return <Loading />;
  return (
    <div className="herramientas-administrativas-content">
      {
        <>
          <Card icon={<IconHerramienta color="#4b5056" />} title={translate('herramientas-billing-card-name')}>
            <div className="header-content">
              <Form handleChangeDate={handleChangeDate} date={date} getData={getData} />
            </div>
          </Card>
          {!isEmpty(billingData) && (
            <>
              {!billingData.periodDone ? (
                <div className="confirm-button">
                  <Button rounded onClick={confirmBilling} align="center">
                    <FormattedMessage id="global-confirm-period" />
                  </Button>
                </div>
              ) : (
                <Card icon={<IconDone color="#4b5056" />} title={translate('global-period-invoiced')}>
                  <div className="header-content text-center">
                    <IconDone color="#4b5056" />
                    {translate('global-period-invoiced')}{' '}
                  </div>
                  {showSendEmails ? (
                    <div className="header-content text-center">
                      <IconDone color="#4b5056" />
                      {translate('global-billing-emails-sent')} {showSendEmails}{' '}
                    </div>
                  ) : (
                    <div className="confirm-button">
                      <Button rounded onClick={sendEmails} align="center">
                        <FontAwesomeIcon icon={faEnvelopeOpenText} className="mr-2" />
                        {translate('global-send-billing-emails')} {translate('global-period')} {' ' + dateFormat(date)}
                      </Button>
                    </div>
                  )}
                </Card>
              )}

              <BillingTable data={billingData} />
              {!billingData.periodDone && (
                <div className="confirm-button">
                  <Button rounded onClick={confirmBilling} align="center">
                    <FormattedMessage id="global-confirm-period" />
                  </Button>
                </div>
              )}
            </>
          )}
        </>
      }
    </div>
  );
};

export default MonthBilling;

import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { Container, Col, Row, Card, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft, faSave } from '@fortawesome/free-solid-svg-icons';

import CreditPacksService from 'shared/credit-packs-service';

import Button from 'components/button';
import {
  Description,
  Credits,
  Amount,
  AmountUSD,
  AmountSpecial,
  AmountUSDSpecial,
  CGCode,
  EpochCode,
  DticCode,
  SofortCode,
  Type,
  ArticleID,
  Anonymous,
  Registered,
  Upsell,
  Access,
  AutoPromo,
} from 'components/CreditPacks/Form';

const EditCreditPack = ({ id }) => {
  const { formatMessage } = useIntl();
  const { goBack } = useHistory();

  return (
    <Container className="mw-100 ha-discounts-new">
      <Row>
        <Col sm="12" className="mb-2">
          <Button rounded onClick={goBack}>
            <FontAwesomeIcon icon={faArrowCircleLeft} className="mr-2" />
            {formatMessage({ id: 'statistics-form-button-return' })}
          </Button>
        </Col>
        <Col sm="12">
          <Card className="mb-2">
            <Card.Header>Editar paquete {id}</Card.Header>
            <Card.Body>
              <FormArticle id={id} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

const FormArticle = ({ id }) => {
  const { formatMessage } = useIntl();
  const { goBack } = useHistory();
  const dispatch = useDispatch();

  const [specialPrices, setSpecialPrices] = useState(false);
  const [data, setData] = useState({
    ID: id,
    DESCRIPTION: '',
    CREDITS: 0,
    AMOUNT: 0.0,
    AMOUNT_USD: 0.0,
    AMOUNT_SPECIAL: '',
    AMOUNT_USD_SPECIAL: '',
    CG_CODE: '',
    EPOCH_CODE: '',
    DTIC_CODE: '',
    SOFORT_CODE: '',
    TYPE: 1,
    ARTICLE_ID: '',
    ANONYMOUS: 1,
    REGISTERED: 1,
    UPSELL: 0,
    ACCESS: 0,
    AUTOPROMO: 0,
  });

  const handleOnChange = (e) => {
    const { name, value, type, checked } = e.target;
    let fieldValue = value;

    if (type === 'checkbox') fieldValue = checked ? 1 : 0;

    setData({
      ...data,
      [name]: fieldValue,
    });
  };

  const handleSpecialPrices = (e) => {
    setSpecialPrices(e.target.checked);
    setData({
      ...data,
      AMOUNT_SPECIAL: '',
      AMOUNT_USD_SPECIAL: '',
    });
  };

  useEffect(() => {
    dispatch({ type: 'SET_LOADING', loading: true });
    CreditPacksService.adminGet(id)
      .then((data) => {
        if (data.status === 'Accepted') {
          if (data.AMOUNT_SPECIAL > 0 || data.AMOUNT_USD_SPECIAL > 0) {
            setSpecialPrices(true);
          }
          setData(data.message);
        }
        if (data.status === 'Failed') {
          toast.error('El paquete que intentas editar no existe');
          goBack();
        }
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
  }, [id, dispatch, goBack]);

  const submit = (e) => {
    e.preventDefault();
    // data convert number to decimals with .
    dispatch({ type: 'SET_LOADING', loading: true });
    CreditPacksService.adminUpdate(data)
      .then((responseData) => {
        responseData.status === 'Failed' && toast.error(formatMessage({ id: 'global-error-try-later' }));
        if (responseData.status === 'Accepted') {
          toast.success(formatMessage({ id: 'global-success-operation' }));
          goBack();
        }
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
  };

  return (
    <form onSubmit={submit}>
      <Row>
        <Col xl="6">
          <Description label={<FormattedMessage id="global-description" />} value={data.DESCRIPTION} onChange={handleOnChange} />
        </Col>
        <Col xl="6">
          <Credits label={<FormattedMessage id="statistics-table-credit" />} value={data.CREDITS} onChange={handleOnChange} />
        </Col>
      </Row>
      <Row>
        <Col xl="6">
          <Amount
            label={
              <>
                <FormattedMessage id="statistics-table-import" /> (€)
              </>
            }
            value={data.AMOUNT}
            onChange={handleOnChange}
          />
        </Col>

        <Col xl="6">
          <AmountUSD
            label={
              <>
                <FormattedMessage id="statistics-table-import" /> ($)
              </>
            }
            value={data.AMOUNT_USD}
            onChange={handleOnChange}
          />
        </Col>
      </Row>
      <hr />
      <Row>
        <Col></Col>
        <Col xl="6">
          <Form.Group as={Row} className="mb-3">
            <label className="font-weight-bold form-label col-form-label col-sm-6">Precios Especiales para fidelizados</label>
            <Col sm="6">
              <input className="form-control" type="checkbox" name="specialPrices" checked={specialPrices} onChange={handleSpecialPrices} />
            </Col>
          </Form.Group>
        </Col>
        <Col></Col>
      </Row>
      {specialPrices && (
        <Row>
          <Col xl="6">
            <AmountSpecial label="Importe Especial (€)" value={data.AMOUNT_SPECIAL} onChange={handleOnChange} />
          </Col>

          <Col xl="6">
            <AmountUSDSpecial label="Importe Especial USD ($)" value={data.AMOUNT_USD_SPECIAL} onChange={handleOnChange} />
          </Col>
        </Row>
      )}
      <hr />
      <Row>
        <Col xl="6">
          <CGCode label="CG Code" value={data.CG_CODE} onChange={handleOnChange} />
        </Col>

        <Col xl="6">
          <EpochCode label="Epoch Code" value={data.EPOCH_CODE} onChange={handleOnChange} />
        </Col>
      </Row>
      <Row>
        <Col xl="6">
          <DticCode label="DTIC Code" value={data.DTIC_CODE} onChange={handleOnChange} />
        </Col>

        <Col xl="6">
          <SofortCode label="Sofort Code" value={data.SOFORT_CODE} onChange={handleOnChange} />
        </Col>
      </Row>
      <Row>
        <Col xl="6">
          <Type label={<FormattedMessage id="global-type" />} value={data.TYPE} onChange={handleOnChange} />
        </Col>

        <Col xl="6">
          <ArticleID label="ID Artículo" value={data.ARTICLE_ID} onChange={handleOnChange} />
        </Col>
      </Row>
      <Row>
        <Col xl="6">
          <Anonymous label="Anónimo" value={data.ANONYMOUS} onChange={handleOnChange} />
        </Col>

        <Col xl="6">
          <Registered label="Registrado" value={data.REGISTERED} onChange={handleOnChange} />
        </Col>
      </Row>
      <Row>
        <Col xl="6">
          <Upsell label="Upsell" value={data.UPSELL} onChange={handleOnChange} />
        </Col>

        <Col xl="6">
          <Access label="Access" value={data.ACCESS} onChange={handleOnChange} />
        </Col>
      </Row>
      <Row>
        <Col xl="6">
          <AutoPromo label="AutoPromo" value={data.AUTOPROMO} onChange={handleOnChange} />
        </Col>
      </Row>
      <hr />
      <Row>
        <Col className="text-center">
          <Button rounded variant="primary" type="submit">
            <FontAwesomeIcon icon={faSave} className="mr-2" />
            <span>{formatMessage({ id: 'administration-data-button' })}</span>
          </Button>
        </Col>
      </Row>
    </form>
  );
};

export default EditCreditPack;

import { NavLink, Link } from 'react-router-dom';

import translate from 'i18n/translate';

import Layout from 'components/public-layout';

import { PUBLIC, OTHERS } from 'shared/panel-links';

import MainValuesSection from '../MainValuesSection';

import './index.scss';

const Home = () => {
  return (
    <Layout cssClass="homepage">
      <section className="main">
        <div className="main-content">
          <h1>{translate('public-home-main-title')}</h1>
          <div className="btn-wrapper">
            <NavLink to={PUBLIC.REGISTER} className="btn btn-default btn-home">
              {translate('public-home-lets-start')}
            </NavLink>
          </div>
        </div>
      </section>

      <section className="maxim white-bg">
        <h2>
          {translate('public-home-maximize-income')} <br />
          {translate('public-home-with-ole')}
        </h2>
        <p className="subtitle">{translate('public-home-subtitle')}</p>
        <div className="boxes-wrapper">
          <div className="external-box">
            <div className="box animate" data-anim-type="bounceIn" data-anim-delay="200">
              <span className="top smallest">{translate('public-home-until')}</span>
              <span className="top">30%</span>
              <span className="sub">REV.SHARE</span>
            </div>
          </div>
          <div className="external-box">
            <div className="box animate" data-anim-type="bounceIn" data-anim-delay="200">
              <span className="top smallest">
                <br />
              </span>
              <span className="top">5%</span>
              <span className="sub">{translate('public-home-referred')}</span>
            </div>
          </div>
          <div className="external-box">
            <div className="box animate" data-anim-type="bounceIn" data-anim-delay="200">
              <span className="top smallest">{translate('public-home-all')}</span>
              <span className="top small">
                <span className="font-thin font-monserrat">{translate('public-home-revisions')}</span>
              </span>
              <span className="sub">{translate('public-home-for-life')}</span>
            </div>
          </div>
        </div>

        <NavLink to={PUBLIC.REGISTER} className="btn btn-default">
          {translate('public-lets-go')}
        </NavLink>
      </section>

      <section className="work-with pink">
        <div className="container">
          <div className="row">
            <div className="offset-md-6 col-md-6 offset-lg-7 col-lg-5 col-12 text-right">
              <h2 className="title">{translate('public-home-work-us-title')}</h2>
            </div>
            <div className="offset-md-6 col-md-6 offset-lg-8 col-lg-4 col-12 text-right">
              <p className="subtitle text-right">{translate('public-home-work-us-text')}</p>

              <Link
                to={{ pathname: OTHERS.WORK_WITH_US }}
                className="btn btn-default"
                role="button"
                target="_blank"
                rel="noopener noreferrer">
                {translate('public-lets-start')}
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="info">
        <div className="info1 row">
          <div className="container">
            <div className="col-12 col-sm-6">
              <div className="example-logo-wrapper">
                <picture>
                  <img
                    src="/images/logo-oc.png"
                    srcSet="/images/logo-oc.png 1x, /images/logo-oc@2x.png 2x, /images/logo-oc@3x.png 3x"
                    alt="olecams Logo"
                    className="img-fluid"
                  />
                </picture>
              </div>
              <p>{translate('public-home-oc-text')}</p>
              <div className="button-wrapper text-center">
                {/* <NavLink to={{ pathname: PUBLIC.PRODUCTS, hash: '#olecams' }} className="btn btn-default float-sm-right ">
                  + INFO
                </NavLink*/}
                <a href={PUBLIC.PRODUCTS + '#olecams'} className="btn btn-default float-sm-right ">
                  + INFO
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="info2 row">
          <div className="container">
            <div className="col-12 col-sm-6">
              <div className="example-logo-wrapper">
                <picture>
                  <img
                    src="/images/logo-vce.png"
                    srcSet="/images/logo-vce.png 1x, /images/logo-vce@2x.png 2x, /images/logo-vce@3x.png 3x"
                    alt="vce logo"
                    className="img-fluid"
                  />
                </picture>
              </div>
              <p>{translate('public-home-vce-text')}</p>
              <div className="button-wrapper text-center">
                <a href={PUBLIC.PRODUCTS + '#vce'} className="btn btn-default float-sm-right ">
                  + INFO
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="info3 row">
          <div className="container">
            <div className="col-12  col-sm-6">
              <div className="example-logo-wrapper">
                <picture>
                  <img
                    src="/images/logo-adp.png"
                    srcSet="/images/logo-adp.png 1x, /images/logo-adp@2x.png 2x, /images/logo-adp@3x.png 3x"
                    alt="adp Logo"
                    className="img-fluid"
                  />
                </picture>
              </div>
              <p>{translate('public-home-adp-text')}</p>
              <div className="button-wrapper text-center">
                <a href={PUBLIC.PRODUCTS + '#adptube'} className="btn btn-default float-sm-right ">
                  + INFO
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="info-overlay">
          <div className="info-overlay-contain">
            <img
              src="/images/icon-white-html5.png"
              alt=""
              className="icon img-fluid animate"
              data-anim-type="fadeInLeft"
              data-anim-delay="200"
            />
            <img
              src="/images/icon-white-webcamers.png"
              alt=""
              className="icon img-fluid animate"
              data-anim-type="fadeInRight"
              data-anim-delay="300"
            />
          </div>
          <div className="info-overlay-contain">
            <img
              src="/images/icon-white-trophi.png"
              alt=""
              className="icon img-fluid animate"
              data-anim-type="fadeInLeft"
              data-anim-delay="300"
            />
            <img
              src="/images/icon-white-fullhd.png"
              alt=""
              className="icon img-fluid animate"
              data-anim-type="fadeInRight"
              data-anim-delay="200"
            />
          </div>
        </div>
      </section>

      <MainValuesSection />

      <WhiteLabels />
    </Layout>
  );
};
export default Home;

const WhiteLabels = () => {
  const { WL_LINKS } = PUBLIC;
  return (
    <section className="samples">
      <div className="container">
        <h2>
          {translate('public-home-wb-title-1')} <br />
          {translate('public-home-wb-title-2')}
        </h2>
        <p className="subtitle">{translate('public-home-wb-text')}</p>

        <div className="boxes-wrapper">
          <div className="box">
            <div className="box-content animate" data-anim-type="fadeIn" data-anim-delay="200">
              <picture>
                <img
                  src="/images/lg-viciocams.png"
                  srcSet="/images/lg-viciocams.png 1x, /images/lg-viciocams@2x.png 2x, /images/lg-viciocams@3x.png 3x"
                  alt="VicioCams"
                  className="img-fluid"
                />
              </picture>

              <Link to={{ pathname: WL_LINKS.VICIOCAMS }} className="btn btn-transparent" target="_blank" rel="noopener noreferrer">
                <span>+ INFO</span>
              </Link>
            </div>
          </div>

          <div className="box">
            <div className="box-content animate" data-anim-type="fadeIn" data-anim-delay="200">
              <picture>
                <img
                  src="/images/lg-chicastorbe.png"
                  srcSet="/images/lg-chicastorbe.png 1x, /images/lg-chicastorbe@2x.png 2x, /images/lg-chicastorbe@3x.png 3x"
                  alt="Chicas de Torbe"
                  className="img-fluid"
                />
              </picture>

              <Link
                to={{ pathname: WL_LINKS.WEBCAMS_PUTALOCURA }}
                className="btn btn-transparent"
                target="_blank"
                rel="noopener noreferrer">
                <span>+ INFO</span>
              </Link>
            </div>
          </div>
          <div className="box">
            <div className="box-content animate" data-anim-type="fadeIn" data-anim-delay="200">
              <picture>
                <img
                  src="/images/muyzorras.png"
                  srcSet="/images/muyzorras.png 1x, /images/muyzorras@2x.png 2x, /images/muyzorras@3x.png 3x"
                  alt="Muy Zorras"
                  className="img-fluid"
                />
              </picture>

              <Link to={{ pathname: WL_LINKS.WEBCAMS_MUYZORRAS }} className="btn btn-transparent" target="_blank" rel="noopener noreferrer">
                <span>+ INFO</span>
              </Link>
            </div>
          </div>

          <div className="box">
            <div className="box-content animate" data-anim-type="fadeIn" data-anim-delay="200">
              <picture>
                <img
                  src="/images/lg-orgasmatrix.png"
                  srcSet="/images/lg-orgasmatrix.png 1x, /images/lg-orgasmatrix@2x.png 2x, /images/lg-orgasmatrix@3x.png 3x"
                  alt="Orgasmatrix"
                  className="img-fluid"
                />
              </picture>

              <Link to={{ pathname: WL_LINKS.CAMS_ORGASMATRIX }} className="btn btn-transparent" target="_blank" rel="noopener noreferrer">
                <span>+ INFO</span>
              </Link>
            </div>
          </div>
          <div className="box">
            <div className="box-content animate" data-anim-type="fadeIn" data-anim-delay="200">
              <picture>
                <img
                  src="/images/lg-cum-louder.png"
                  srcSet="/images/lg-cum-louder.png 1x, /images/lg-cum-louder@2x.png 2x, /images/lg-cum-louder@3x.png 3x"
                  alt="Cum Loader"
                  className="img-fluid"
                />
              </picture>

              <Link to={{ pathname: WL_LINKS.LIVE_CUMLOADER }} className="btn btn-transparent" target="_blank" rel="noopener noreferrer">
                <span>+ INFO</span>
              </Link>
            </div>
          </div>
          <div className="box">
            <div className="box-content animate" data-anim-type="fadeIn" data-anim-delay="200">
              <picture>
                <img
                  src="/images/lg-villawebcams.png"
                  srcSet="/images/lg-villawebcams.png 1x, /images/lg-villawebcams@2x.png 2x, /images/lg-villawebcams@3x.png 3x"
                  alt="Villa WebCams"
                  className="img-fluid"
                />
              </picture>

              <Link to={{ pathname: WL_LINKS.VILLAWEBCAMS }} className="btn btn-transparent" target="_blank" rel="noopener noreferrer">
                <span>+ INFO</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <NavLink to={PUBLIC.REGISTER} className="btn btn-default">
        {translate('public-see-more')}
      </NavLink>
    </section>
  );
};

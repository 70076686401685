//import { useIntl } from 'react-intl';

const InputAttempts = ({ onChange, ...props }) => {
  //const { formatMessage } = useIntl();

  const handleOnChange = (e) => {
    const event = {
      target: {
        name: 'attempt',
        value: e.target.value > 5 ? 5 : e.target.value,
      },
    };

    onChange(event);
  };

  const handleOnInput = (e) => {
    if (e.target.value < 1) {
      e.target.value = 1;
    }
  };

  return (
    <div className="form-group">
      <label>Intentos</label>
      <input
        placeholder="introduce el número de intentos de la compra, vacío para cualquier número de intentos"
        type="number"
        name="attempt"
        className="form-control"
        min="1"
        max="5"
        onChange={handleOnChange}
        {...props}
      />
    </div>
  );
};

export default InputAttempts;

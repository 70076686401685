import { useState, useEffect, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'libs/lodashAlt';

import OcStatsService from 'shared/oc-stats-service';

//Component
import Card from 'components/card';
import Loading from 'components/loading';
import Pagination from 'components/pagination';

import Source from '../source';
import Form from './form';
import Table from './table';

import IconStatsOC from 'components/icons/statsOlecams';

// style
import '../index.scss';

const StatsUser = (props) => {
  const auth = useSelector((state) => state.auth.auth);
  let loading = useSelector((state) => state.loading.loading);
  let stats = useSelector((state) => state.stats.statsUser);
  let statsOCFilters = useSelector((state) => state.stats.statsOCFilters);
  const dispatch = useDispatch();

  const [affiliateId, setAffiliateId] = useState(statsOCFilters.affiliate_id ? statsOCFilters.affiliate_id : '');
  const [paymentType, setPaymentType] = useState(statsOCFilters.payment_type ? statsOCFilters.payment_type : '');
  const [statusPurchase, setStatusPurchase] = useState(statsOCFilters.status ? statsOCFilters.status : '1');

  const [filters, setFilters] = useState({
    ...statsOCFilters,
    method: 'STATS_USER',
    admin: auth.admin,
    date_ini: statsOCFilters.date_ini,
    date_end: statsOCFilters.date_end,
    affiliate_id: statsOCFilters.affiliate_id ? statsOCFilters.affiliate_id : '',
    payment_type: statsOCFilters.payment_type,
    first_purchase: statsOCFilters.first_purchase ? statsOCFilters.first_purchase : '',
    user: props.email,
    status: statsOCFilters.status ? statsOCFilters.status : '1',
    pager: {
      page: '1',
      items: '50',
    },
  });

  const getData = useCallback(() => {
    dispatch({ type: 'SET_LOADING', loading: true });
    OcStatsService.getStats(filters)
      .then((responseData) => {
        dispatch({ type: 'SET_STATS', statsUser: responseData.message, statsOCFilters: filters });
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));

    //getStatsUser(filters, auth.token, dispatch);
  }, [dispatch, filters]);

  useEffect(getData, [getData]);

  const getStats = (e) => {
    e.preventDefault();
    setFilters({
      ...filters,
      affiliate_id: affiliateId,
      payment_type: paymentType,
      status: statusPurchase,
    });
  };

  const getStatDetail = (id, provider) => {
    let _filters = {
      ...filters,
      method: 'STATS_DETAIL',
      id: id,
      provider: provider,
      status: statusPurchase,
    };

    dispatch({ type: 'SET_LOADING', loading: true });
    OcStatsService.getStats(_filters)
      .then((responseData) => {
        dispatch({ type: 'SET_STATS_DETALL', statsDetall: responseData.message, statsOCFilters: filters });
        dispatch({ type: 'MODAL', statsDetall: true });
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));

    //getDetall(_filters, auth.token, dispatch);
  };

  // PAGINATION
  const paginate = (e) => {
    setFilters({
      ...filters,
      pager: { ...filters.pager, page: e.target.value },
    });
  };

  const handleChangeNumberOfColum = (e) => {
    setFilters({
      ...filters,
      pager: { ...filters.pager, items: e.target.value, page: 1 },
    });
  };

  const nextPage = (page) => {
    page++;
    if (page <= stats.pager.pages) {
      setFilters({
        ...filters,
        pager: { ...filters.pager, page: page },
      });
    }
  };

  const prevPage = (page) => {
    page--;
    if (page > 0) {
      setFilters({
        ...filters,
        pager: { ...filters.pager, page: page },
      });
    }
  };
  // END PAGINATION

  if (loading) return <Loading />;
  return (
    <div className="stats">
      {!isEmpty(stats) && (
        <div className="statsolecams-content">
          <Card icon={<IconStatsOC color="#4b5056" />} title={<FormattedMessage id="statistics-resum-title-olecams" />}>
            <div className="stats-ol">
              <Form
                affiliateId={affiliateId}
                setAffiliateId={setAffiliateId}
                paymentType={paymentType}
                setPaymentType={setPaymentType}
                getStats={getStats}
                filters={filters}
                statusPurchase={statusPurchase}
                setStatusPurchase={setStatusPurchase}
              />
            </div>
          </Card>

          <>
            <div className="table-content">
              <Table stats={stats} getStatDetall={getStatDetail} />
              <Pagination
                handleChangeNumberOfColum={handleChangeNumberOfColum}
                pager={stats.pager}
                paginate={paginate}
                prevPage={prevPage}
                nextPage={nextPage}
                total={stats.summary.count}
              />
            </div>
            <Source />
          </>
        </div>
      )}
    </div>
  );
};

export default StatsUser;

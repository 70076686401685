import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Container, Col, Row, Card } from 'react-bootstrap';

import { dateFormat } from 'libs/methods';
import { CREDIT_GIFT } from 'shared/constants';

import CreditsGiftService from 'shared/credit-gift-service';

import Table from './table.js';
import Form from './form.js';
import Modal from './modal';

import Pagination from 'components/pagination';

const CreditGift = () => {
  const history = useHistory();
  //let loading = useSelector((state) => state.loading.loading);
  const [data, setData] = useState(null);
  const [search, setSearch] = useState('');
  const [type, setType] = useState('');
  const [batch, setBatch] = useState(CREDIT_GIFT.BATCH_OPTIONS.NO);
  const [status, setStatus] = useState(CREDIT_GIFT.STATUS.ACTIVE);
  const [onDate, setOnDate] = useState('');
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const [date, setDate] = useState();
  const [filters, setFilters] = useState({
    type: '',
    status: CREDIT_GIFT.STATUS.ACTIVE,
    batch: CREDIT_GIFT.BATCH_OPTIONS.NO,
    on_date: '',
    pager: {
      page: '1',
      items: '50',
    },
  });

  const getList = useCallback(
    (filters) => {
      dispatch({ type: 'SET_LOADING', loading: true });
      CreditsGiftService.adminList(filters)
        .then((responseData) => responseData.status === 'Accepted' && setData(responseData.message))
        .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
    },
    [dispatch, setData]
  );

  useEffect(() => getList(filters), [getList, filters]);
  const refresh = () => getList(filters);

  const handleChangeDate = (date) => {
    setDate(date);
    setFilters({
      filters: {
        ...filters,
        date: date ? dateFormat(date) : '',
      },
    });
  };

  const sendSearch = (e) => {
    e.preventDefault();
    setFilters({
      ...filters,
      search: search,
      type: type,
      batch: batch,
      status: status,
      on_date: onDate,
      pager: {
        ...filters.pager,
        page: 1,
      },
    });
  };

  const editBtn = (id) => {
    history.push('/admintools/regalo-creditos/' + id);
  };

  /* PAGINATION METHODS */
  const paginate = (e) => {
    setFilters({
      ...filters,
      pager: {
        ...filters.pager,
        page: e.target.value,
      },
    });
  };

  const handleChangeNumberOfColum = (e) => {
    setFilters({
      ...filters,
      pager: {
        ...filters.pager,
        items: e.target.value,
        page: 1,
      },
    });
  };

  const nextPage = (page) => {
    page++;
    if (page <= data.pager.pages) {
      setFilters({
        ...filters,
        pager: {
          ...filters.pager,
          page: page,
        },
      });
    }
  };

  const prevPage = (page) => {
    page--;
    if (page > 0) {
      setFilters({
        ...filters,
        pager: {
          ...filters.pager,
          page: page,
        },
      });
    }
  };
  /* END PAGINATION METHODS */

  return (
    <Container className="mw-100 ha-discounts">
      <Row>
        <Col sm="12">
          <Card className="mb-2">
            <Card.Header>Regalos de Créditos (Sin compra)</Card.Header>
            <Card.Body>
              <Form
                date={date}
                search={search}
                type={type}
                setType={setType}
                batch={batch}
                setBatch={setBatch}
                status={status}
                setStatus={setStatus}
                onDate={onDate}
                setOnDate={setOnDate}
                filters={filters}
                handleChangeDate={handleChangeDate}
                setSearch={setSearch}
                sendSearch={sendSearch}
                setShowModal={setShowModal}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col sm="12">
          {data && data.data && data.total > 0 ? (
            <Table data={data} refresh={refresh} editBtn={editBtn} />
          ) : (
            <>No hay regalos de créditos</>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          {data && data.total > 0 && (
            <Pagination
              handleChangeNumberOfColum={handleChangeNumberOfColum}
              pager={data.pager}
              paginate={paginate}
              prevPage={prevPage}
              nextPage={nextPage}
              total={data.total}
            />
          )}
        </Col>
      </Row>
      {showModal && <Modal show={showModal} setShow={setShowModal} />}
    </Container>
  );
};

export default CreditGift;

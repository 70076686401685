import { useState } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';

import translate from 'i18n/translate';
import { getLang } from 'libs/cookies';

import AffiliatesService from 'shared/affiliates-service';

import useValidator from 'hooks/useValidator';

import Button from 'components/button';

import IconDataOfAccess from 'components/icons/dataOfAcces';

//style
import './index.scss';
const UpdatePassword = ({ pwdUpdated, token }) => {
  const intl = useIntl();
  const lang = getLang();
  const [validator, showValidationMessage] = useValidator({}, {}, lang);

  const [oldPwd, setOldPwd] = useState('');
  const [pwd, setPwd] = useState('');
  // Errors
  const [errorRequest, setErrorRequest] = useState(false);
  const [errorParam, setErrorParam] = useState(false);
  const [errorSamePwd, setErrorSamePwd] = useState(false);
  const [errorOldPwd, setErrorOldPwd] = useState(false);
  const [errorPwdFormat, setErrorPwdFormat] = useState(false);

  const [submit, setSubmit] = useState(false);

  const changExpiredPassword = (e) => {
    e.preventDefault();
    setSubmit(true);
    resetErrors();
    if (validator.allValid()) {
      if (formPassword(pwd)) {
        setErrorPwdFormat(true);
      } else {
        AffiliatesService.changePassword(token, pwd, oldPwd).then((responseData) => {
          if (responseData.status === 'Failed') {
            if (responseData.message === 'When some parameter is missing') setErrorParam(true);
            if (responseData.message === 'changeOwnPassword Password cannot be the same as previous') setErrorSamePwd(true);
            if (responseData.message === 'Failed Update Password') setErrorRequest(true);
            if (responseData.message === 'changeOwnPassword KO - Old Password MUST be correct') setErrorOldPwd(true);
          } else {
            resetErrors();
            pwdUpdated();
            //this.setState({ passwordExpired: false });
          }
        });
      }
    } else showValidationMessage(true);
  };

  const formPassword = (password) => password.length < 8 || !/[0-9]/.test(password) || !/[A-Z]/.test(password);

  const resetErrors = () => {
    setErrorOldPwd(false);
    setErrorParam(false);
    setErrorRequest(false);
    setErrorSamePwd(false);
    setErrorPwdFormat(false);
  };

  return (
    <div className="card">
      <div className="card-header">
        <IconDataOfAccess />
        <h1>{translate('login-recovery-title')}</h1>
      </div>
      <div className="card-body">
        <Alert text="login-password-expired-text" />
        {errorParam && <Alert text="herramientas-affiliate-error-request-parameter" />}
        {errorRequest && <Alert text="herramientas-affiliate-change-password-error-request" />}
        {errorSamePwd && <Alert text="herramientas-affiliate-change-password-error-same" />}
        {errorOldPwd && <Alert text="herramientas-affiliate-error-request-old-password" />}
        <form onSubmit={changExpiredPassword}>
          <div className="form-group">
            <label>{translate('login-recovery-label-two-old-pwd')}</label>
            <input
              type="password"
              name="oldPassword"
              //className={classNames('form-control', { 'error-input': errorPwdFormat })}
              className={classNames('form-control', {
                'form-input-success': submit && validator.check(oldPwd, 'max: 100|required'),
                'form-input-error': submit && !validator.check(oldPwd, 'max: 100|required'),
              })}
              onChange={(e) => setOldPwd(e.target.value)}
              value={oldPwd}
            />
            {validator.message(intl.formatMessage({ id: 'login-recovery-label-two-old-pwd' }).toLowerCase(), oldPwd, 'max: 100|required')}
          </div>
          <div className="form-group">
            <label>{translate('login-recovery-label-one')}</label>
            <input
              type="password"
              name="password"
              //className={classNames('form-control', { 'error-input': errorPwdFormat })}
              className={classNames('form-control', {
                'form-input-success': submit && !errorPwdFormat && validator.check(pwd, 'min: 8|max: 100|required'),
                'form-input-error': submit && (errorPwdFormat || !validator.check(pwd, 'min: 8|max: 100|required')),
              })}
              onChange={(e) => setPwd(e.target.value)}
              value={pwd}
            />
            {validator.message(intl.formatMessage({ id: 'login-recovery-label-one' }).toLowerCase(), pwd, 'min: 8|max: 100|required')}
            <br />
            {errorPwdFormat && <Alert text="herramientas-affiliate-change-password-error-format" />}
          </div>
          <div className="form-group button-content">
            <Button rounded onClick={changExpiredPassword} align="center" type="submit">
              {translate('login-recovery-button')}
            </Button>
          </div>
          <div className="text-suport-content">
            <p>{translate('login-recovery-support')}</p>
            <a href="mailto:soporte@promocionesweb.com">soporte@promocionesweb.com</a>
          </div>
        </form>
      </div>
    </div>
  );
};

const Alert = ({ text, alertTypeCss = 'alert-danger' }) => {
  return (
    <div className={`${alertTypeCss} alert`} role="alert">
      {translate(text)}
    </div>
  );
};

export default UpdatePassword;

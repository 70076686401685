import { Col, Row, Table } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

const MonthTable = ({ data, stats_type }) => {
  return (
    <Row>
      <Col sm="12">
        {data && (
          <Table responsive="sm" striped bordered hover className="text-center">
            {stats_type === 'adsl' && (
              <>
                <thead>
                  <tr>
                    <th>
                      <FormattedMessage id="statistics-table-day" />
                    </th>
                    <th>
                      <FormattedMessage id="statistics-table-connection" />
                    </th>
                    <th>
                      <FormattedMessage id="statistics-table-mid" />
                    </th>
                    <th>
                      <FormattedMessage id="statistics-table-minut" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(data.SUMM_RECORDS).map((key, item) => {
                    return (
                      <tr>
                        <td>{key}</td>
                        <td>{data.SUMM_RECORDS[key].conn}</td>
                        <td>{data.SUMM_RECORDS[key].print_average}</td>
                        <td>{data.SUMM_RECORDS[key].print_minutes}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </>
            )}

            {/* stats_type === "sms" && 
                            <>
                                <thead>
                                    <tr>
                                        <th><FormattedMessage id='statistics-table-day')}</th>
                                        <th><FormattedMessage id='global-amount')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { Object.keys(data.SUMM_RECORDS).map((key, item ) => {
                                        return (
                                            <tr>
                                                <td>{key}</td>
                                                <td>{ data.SUMM_RECORDS[key].sms }</td>
                                            </tr>
                                            )
                                        })
                                    }
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th>Total</th>
                                        <th>{data.COUNT_ROWS}</th>
                                    </tr>
                                </tfoot>
                            </> */}
            {stats_type === 'vce_minutes' && (
              <>
                <thead>
                  <tr>
                    <th>
                      <FormattedMessage id="statistics-table-day" />
                    </th>
                    <th>
                      <FormattedMessage id="statistics-table-connection" />
                    </th>
                    <th>
                      <FormattedMessage id="statistics-table-mid" />
                    </th>
                    <th>
                      <FormattedMessage id="statistics-table-minut" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(data.SUMM_RECORDS).map((key, item) => {
                    return (
                      <tr>
                        <td>{key}</td>
                        <td>{data.SUMM_RECORDS[key].conn}</td>
                        <td>{data.SUMM_RECORDS[key].print_average}</td>
                        <td>{data.SUMM_RECORDS[key].print_minutes}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </>
            )}

            {stats_type === 'vcp_minutes' && (
              <>
                <thead>
                  <tr>
                    <th>
                      <FormattedMessage id="statistics-table-day" />
                    </th>
                    <th>
                      <FormattedMessage id="statistics-table-connection" />
                    </th>
                    <th>
                      <FormattedMessage id="statistics-table-credit" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(data.SUMM_RECORDS).map((key, item) => {
                    return (
                      <tr>
                        <td>{key}</td>
                        <td>{data.SUMM_RECORDS[key].conn}</td>
                        <td>{data.SUMM_RECORDS[key].print_credits}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </>
            )}

            {stats_type === 'tips' && (
              <>
                <thead>
                  <tr>
                    <th>
                      <FormattedMessage id="statistics-table-day" />
                    </th>
                    <th>
                      <FormattedMessage id="global-tips" />
                    </th>
                    <th>
                      <FormattedMessage id="statistics-table-credit" />
                    </th>
                    <th>
                      <FormattedMessage id="statistics-table-credit" /> Lovense
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(data.DATA).map((key, item) => {
                    return (
                      <tr>
                        <td>{data.DATA[key].dia}</td>
                        <td>{data.DATA[key].cantidad_tips}</td>
                        <td>{data.DATA[key].creditstotal}</td>
                        <td>{data.DATA[key].lovesensetotal}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </>
            )}
            {stats_type === 'extras' && (
              <>
                <thead>
                  <tr>
                    <th>
                      <FormattedMessage id="statistics-table-day" />
                    </th>
                    <th>
                      <FormattedMessage id="global-time-seconds" />
                    </th>
                    <th>
                      <FormattedMessage id="statistics-table-credit" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(data).map((key, item) => {
                    return (
                      <tr>
                        <td>{data[key].dia}</td>
                        <td>{data[key].seconds}</td>
                        <td>{data[key].credits}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </>
            )}
          </Table>
        )}
      </Col>
    </Row>
  );
};

export default MonthTable;

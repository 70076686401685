const Movil = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="22" height="22" viewBox="0 0 14 22">
      <path
        fill="#A2A9B0"
        id="prefix__a"
        d="M11 17H2V3h9v14zm-4.5 4c-.828 0-1.5-.672-1.5-1.5S5.672 18 6.5 18s1.5.672 1.5 1.5S7.328 21 6.5 21zm4-21h-8C1.12 0 0 1.12 0 2.5v17C0 20.88 1.12 22 2.5 22h8c1.38 0 2.5-1.12 2.5-2.5v-17C13 1.12 11.88 0 10.5 0z"
      />
    </svg>
  );
};

export default Movil;

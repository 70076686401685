import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Container, Col, Row } from 'react-bootstrap';

import translate from 'i18n/translate';

import Pagination from 'components/pagination';

import BrokerService from 'shared/broker-service';

import Loading from 'components/loading';

import DetailSummary from './detailSummary';
import Form from './form';
import MonthTable from './monthTable';
import DayTable from './dayTable';

import './index.scss';

const StatsBrokers = () => {
  let loading = useSelector((state) => state.loading.loading);
  const dispatch = useDispatch();

  const [brokers, setBrokers] = useState([]);
  const [stats, setStats] = useState(null);
  const [brokerMB, setBrokerMB] = useState([]);
  const [stats_type, setStatType] = useState('adsl');
  const [filters, setFilters] = useState({
    date: new Date(),
    view: 'month',
    broker: 1,
    tipos: 'vce',
    estudio_pruebas: 0,
    id_mb: '',
    pager: {
      page: '1',
      items: '150',
    },
  });

  //const [testStudio, setTestStudio] = useState(false);

  const getList = useCallback(() => {
    dispatch({ type: 'SET_LOADING', loading: true });
    BrokerService.adminList({ page: 1, perPage: 999, filters: {} })
      .then((responseData) => {
        if (responseData.status === 'Accepted') setBrokers(responseData.message.data);
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
  }, [dispatch, setBrokers]);

  useEffect(getList, [getList]);

  const paginate = (e) => {
    let local_filters = filters;
    setFilters({
      ...filters,
      pager: {
        ...filters.pager,
        page: e.target.value,
      },
    });
    local_filters.pager.page = e.target.value;
    //getList(local_filters);
    getStats();
  };

  const handleChangeDate = (date) => {
    setFilters({
      ...filters,
      date: date,
    });
    setStats(null);
  };

  const handleOnChangeStatsType = (e) => {
    setStatType(e.target.value);
    setStats(null);
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });

    if (name === 'broker' && value !== '') {
      // get Broker MBs
      BrokerService.adminGetBrokerMB(value).then((responseData) => {
        if (responseData.status === 'Accepted') setBrokerMB(responseData.message.result);
      });
    } else if (name === 'broker' && value === '') {
      setBrokerMB([]);
    } else if (name === 'estudio_pruebas') {
      setFilters({
        ...filters,
        [name]: filters.estudio_pruebas === 0 ? 1 : 0,
      });
    }
    setStats(null);
  };

  const handleChangeNumberOfColum = (e) => {
    let local_filters = filters;
    setFilters({
      ...filters,
      pager: {
        ...filters.pager,
        items: e.target.value,
        page: 1,
      },
    });
    local_filters.pager.items = e.target.value;
    local_filters.pager.page = 1;
    //getList(local_filters);
    getStats();
  };

  const nextPage = (page) => {
    let local_filters = filters;
    page++;
    if (page <= stats.pager.pages) {
      setFilters({
        ...filters,
        pager: {
          ...filters.pager,
          page: page,
        },
      });
      local_filters.pager.page = page;
      //getList(local_filters);
      getStats();
    }
  };

  const prevPage = (page) => {
    let local_filters = filters;
    page--;
    if (page > 0) {
      setFilters({
        ...filters,
        pager: {
          ...filters.pager,
          page: page,
        },
      });
      local_filters.pager.page = page;
      //getList(local_filters);
      getStats();
    }
  };

  const getStats = () => {
    let local_filters = filters;
    local_filters.estudio_pruebas = parseInt(local_filters.estudio_pruebas);

    if (stats_type === 'vce_minutes') {
      local_filters.tipos = 'vce';
    } else if (stats_type === 'vcp_minutes') {
      local_filters.tipos = 'vcp';
    }

    switch (stats_type) {
      case 'sms':
        BrokerService.adminStatsSMS(local_filters).then((responseData) => {
          if (responseData.status === 'Accepted') setStats(responseData.message);
        });

        break;

      case 'adsl':
        BrokerService.adminStatsADSL(local_filters).then((responseData) => {
          if (responseData.status === 'Accepted') setStats(responseData.message);
        });

        break;

      case 'vce_minutes':
      case 'vcp_minutes':
        BrokerService.adminStatsMinutes(local_filters).then((responseData) => {
          if (responseData.status === 'Accepted') setStats(responseData.message);
        });

        break;

      case 'tips':
        BrokerService.adminStatsTips(local_filters).then((responseData) => {
          if (responseData.status === 'Accepted') setStats(responseData.message);
        });

        break;

      case 'extras':
        BrokerService.adminStatsExtras(local_filters).then((responseData) => {
          if (responseData.status === 'Accepted') setStats(responseData.message);
        });

        break;

      default:
        BrokerService.adminStatsMinutes(local_filters).then((responseData) => {
          if (responseData.status === 'Accepted') setStats(responseData.message);
        });
        break;
    }
  };

  if (loading) return <Loading />;
  const _class = 'brokers';
  return (
    <Container className={`mw-100 ha-brokers ${_class}`}>
      <Row>
        <Col sm="12">
          <Card className="mb-2">
            <Card.Header>{translate('global-brokers-stats')}</Card.Header>
            <Card.Body>
              <Form
                handleOnChange={handleOnChange}
                handleChangeDate={handleChangeDate}
                filters={filters}
                brokers={brokers}
                stats_type={stats_type}
                getStats={getStats}
                handleOnChangeStatsType={handleOnChangeStatsType}
                brokerMB={brokerMB}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {stats && <DetailSummary data={stats} stats_type={stats_type} />}
      {filters.view === 'month' && stats && <MonthTable data={stats} stats_type={stats_type} />}
      {filters.view === 'day' && stats && (
        <>
          <DayTable data={stats} stats_type={stats_type} />
          <Pagination
            handleChangeNumberOfColum={handleChangeNumberOfColum}
            pager={stats.pager}
            paginate={paginate}
            prevPage={prevPage}
            nextPage={nextPage}
            total={stats.COUNT_ROWS}
          />
        </>
      )}
    </Container>
  );
};

export default StatsBrokers;

import Loader from 'components/icons/loader';

//style
import './index.scss';

const Loading = () => {
  return (
    <div className="modal-content-loding">
      <div className="img-content-loading">
        <Loader />
      </div>
    </div>
  );
};

export default Loading;

import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import IconClose from 'components/icons/close';
import IconCheck from 'components/icons/check';
// icons
import IconMobile from 'components/icons/movil';
import IconDesktop from 'components/icons/computer';
import IconTablet from 'components/icons/tablet';
import IconHelp from 'components/icons/help-circle-outline';

import FormatAfno from '../FormatAfno';

//style
import '../index.scss';

const Table = ({ stats }) => {
  const auth = useSelector((state) => state.auth.auth);
  return (
    <table className="table">
      <thead>
        <tr>
          <th scope="col">
            <FormattedMessage id="statistics-table-time" />
          </th>
          {parseInt(auth.admin) === 1 && (
            <th scope="col">
              <FormattedMessage id="statistics-table-user" />
            </th>
          )}
          {parseInt(auth.admin) === 1 && (
            <th scope="col">
              <FormattedMessage id="global-nick" />
            </th>
          )}
          {parseInt(auth.admin) === 1 && (
            <th scope="col">
              <FormattedMessage id="statistics-table-doi" />
            </th>
          )}
          {parseInt(auth.admin) === 1 && (
            <th scope="col">
              <FormattedMessage id="statistics-table-ip" />
            </th>
          )}
          <th scope="col">
            <FormattedMessage id="statistics-table-country" />
          </th>
          <th scope="col">
            <FormattedMessage id="global-device" />
          </th>
          <th scope="col">
            <FormattedMessage id="promo-white-brand-title" />
          </th>
          {parseInt(auth.admin) === 1 && (
            <th scope="col">
              <FormattedMessage id="statistics-table-credit" />
            </th>
          )}
          <th scope="col">
            <FormattedMessage id="statistics-table-first-purchase" />
          </th>
          <th scope="col">Tracking ID</th>
        </tr>
      </thead>
      <tbody>
        {stats.results.map((statsItem, index) => {
          let tipo = (index + 1) % 2 ? 'impar' : 'par';
          let src = statsItem.REGISTER_COUNTRY ? `${process.env.PUBLIC_URL}/flags/${statsItem.REGISTER_COUNTRY.toLowerCase()}.png` : '';
          return (
            <tr key={index} className={tipo}>
              <td>{statsItem.TIME_ONLY}</td>
              {parseInt(auth.admin) === 1 && <td> {statsItem.EMAIL} </td>}
              {parseInt(auth.admin) === 1 && <td> {statsItem.NICK} </td>}
              {parseInt(auth.admin) === 1 && (
                <td>
                  {statsItem.EMAIL_VALIDATED === 1 ? (
                    <span title="Email validado" data-toggle="tooltip">
                      <IconCheck />
                    </span>
                  ) : (
                    <span title="Email no validado" data-toggle="tooltip">
                      <IconClose />
                    </span>
                  )}
                  {Number(statsItem.FRAUDULENT) === 1 ? (
                    <span title="Fraude" data-toggle="tooltip">
                      <IconClose />
                    </span>
                  ) : (
                    <span title="No fraude" data-toggle="tooltip">
                      <IconCheck />
                    </span>
                  )}
                </td>
              )}
              {parseInt(auth.admin) === 1 && <td>{statsItem.REGISTER_IP}</td>}
              <td>
                <span className="flag">
                  {statsItem.REGISTER_COUNTRY ? (
                    <>
                      <img src={src} alt="flag" /> {statsItem.REGISTER_COUNTRY}
                    </>
                  ) : (
                    <> -- </>
                  )}
                </span>
              </td>
              <td>
                <Device device={statsItem.REGISTER_DEVICE} />
              </td>
              <td>{statsItem.NAME_MB}</td>
              {parseInt(auth.admin) === 1 && <td>{statsItem.CREDITS}</td>}
              <td>{statsItem.FIRST_PURCHASE === 1 ? <IconCheck /> : <IconClose />}</td>
              <td>
                <FormatAfno afno={statsItem.AFNO} isAdmin={parseInt(auth.admin) === 1} />
              </td>
            </tr>
          );
        })}
      </tbody>
      <tfoot>
        <tr>
          <th scope="col" colSpan="3">
            TOTAL
          </th>
          <th colSpan="8">{stats.summary.count}</th>
        </tr>
      </tfoot>
    </table>
  );
};

const Device = ({ device }) => {
  if (device === 0) return <IconDesktop />;
  if (device === 1) return <IconTablet />;
  if (device === 2) return <IconMobile />;

  return <IconHelp />;
};

export default Table;

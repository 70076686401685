const Robot = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="22" height="22" viewBox="0 0 22 22">
      <path
        fill="#4B5056"
        d="M11 0c1.105 0 2 .895 2 2 0 .74-.4 1.39-1 1.73V5h1c3.866 0 7 3.134 7 7h1c.552 0 1 .448 1 1v3c0 .552-.448 1-1 1h-1v1c0 1.105-.895 2-2 2H4c-1.105 0-2-.895-2-2v-1H1c-.552 0-1-.448-1-1v-3c0-.552.448-1 1-1h1c0-3.866 3.134-7 7-7h1V3.73C9.4 3.39 9 2.74 9 2c0-1.105.895-2 2-2zM6.5 11C5.12 11 4 12.12 4 13.5S5.12 16 6.5 16 9 14.88 9 13.5 7.88 11 6.5 11zm9 0c-1.38 0-2.5 1.12-2.5 2.5s1.12 2.5 2.5 2.5 2.5-1.12 2.5-2.5-1.12-2.5-2.5-2.5z"
      />
    </svg>
  );
};

export default Robot;

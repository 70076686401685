const initialState = {
  whitebrand_data: {},
  id_edit_whitebrand: null,
};

const WhiteLabelReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_WHITEBRAND_DATA':
      return Object.assign({}, state, {
        whitebrand_data: action.whitebrand_data,
      });
    case 'SET_ID_EDIT_WHITEBRAND':
      return Object.assign({}, state, {
        id_edit_whitebrand: action.id_edit_whitebrand,
      });
    default:
      return state;
  }
};

export default WhiteLabelReducer;

import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

//components
import IconTarjeta from 'components/icons/tarjeta';
import IconPayPal from 'components/icons/payPal';
import IconTransfer from 'components/icons/transfer';
import IconFirstBuy from 'components/icons/firstBuy';
import IconRefactor from 'components/icons/refactor';
import IconComputer from 'components/icons/computer';
import IconTablet from 'components/icons/tablet';
import IconMovil from 'components/icons/movil';
import IconLocalPay from 'components/icons/localpay';
import IconCrypto from 'components/icons/crypto';

const Source = () => {
  return (
    <div className="info-content">
      <div className="info-one">
        <IconContent icon={<IconTarjeta />} label="statistics-form-select-visa" />
        <IconContent icon={<IconPayPal />} label="statistics-form-select-paypal" />
        <IconContent icon={<IconTransfer />} label="statistics-form-select-tranfer" />
        <IconContent icon={<IconLocalPay />} label="statistics-form-select-local-payment" />
        <IconContent margin={false} icon={<IconCrypto />} label="statistics-form-select-crypto" />
      </div>
      <div className="info-two">
        <IconContent icon={<IconFirstBuy />} label="statistics-table-first" />
        <IconContent icon={<IconRefactor />} label="statistics-table-refact" />
        <IconContent icon={<IconComputer />} label="statistics-form-select-compu" />
        <IconContent icon={<IconTablet />} label="statistics-form-select-tablet" />
        <IconContent margin={false} icon={<IconMovil />} label="statistics-form-select-mobile" />
      </div>
    </div>
  );
};

const IconContent = ({ icon, label, margin = true }) => {
  return (
    <span
      className={classNames({
        'no-margin': margin,
        margin: margin,
      })}>
      <div style={{ maxWidth: '22px', paddingRight: '2px' }}>{icon}</div> <FormattedMessage id={label} />
    </span>
  );
};

export default Source;

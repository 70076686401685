import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import OcStatsService from 'shared/oc-stats-service';

//translate
import translate from 'i18n/translate';

//libs
import { dateFormat } from 'libs/methods';
import { isEmpty } from 'libs/lodashAlt';

//Component
import Card from 'components/card';
import Loading from 'components/loading';
import Pagination from 'components/pagination';

import Form from './form';
import Table from './table';
import FootInfo from './footInfo';

import IconStatsOC from 'components/icons/statsOlecams';

// style
import './index.scss';

const StatsPhoneOC = () => {
  const loading = useSelector((state) => state.loading.loading);
  const stats = useSelector((state) => state.stats.statsVce);
  const dispatch = useDispatch();
  const [date, setDate] = useState(new Date());
  //const [dateRange, setDateRange] = useState('month');

  const initFilters = {
    date: dateFormat(new Date()),
    view: 'month',
    affiliate_id: '',
    country: '',
    pager: {
      page: '1',
      items: '50',
    },
  };

  const [localFilters, setLocalFilters] = useState(initFilters);
  const [filters, setFilters] = useState(initFilters);

  const getData = useCallback(() => {
    dispatch({ type: 'SET_LOADING', loading: true });
    OcStatsService.phoneStats(filters)
      .then((responseData) => {
        dispatch({ type: 'SET_STATS', statsVce: responseData.message });
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));

    //getOlecamsPhoneStats(filters, auth.token, dispatch);
  }, [dispatch, filters]);

  useEffect(getData, [getData]);

  const getStats = (e) => {
    e.preventDefault();
    setFilters(localFilters);
    //filters.view = dateRange === filters.view ? filters.view : dateRange;
    //getOlecamsPhoneStats(filters, auth.token, this.props.dispatch);
  };

  const getStatsDay = (date) => {
    setLocalFilters({
      ...localFilters,
      date: date,
      view: 'day',
    });
    setDate(dateFormat(date));

    setFilters({
      ...filters,
      view: 'day',
      date: date,
    });
  };

  const handleOnChange = (e) => {
    const { value, name } = e.target;
    setLocalFilters({
      ...localFilters,
      [name]: value,
    });
  };

  const handleChangeDate = (date) => {
    setLocalFilters({
      ...localFilters,
      date: date ? dateFormat(date) : '',
    });
    setDate(date);
  };

  // PAGINATION
  const paginate = (e) => {
    let _filters = {
      ...localFilters,
      pager: {
        ...localFilters.pager,
        page: e.target.value,
      },
    };
    setLocalFilters(_filters);
    setFilters(_filters);
  };

  const handleChangeNumberOfColum = (e) => {
    let _filters = {
      ...localFilters,
      pager: {
        ...localFilters.pager,
        items: e.target.value,
        page: 1,
      },
    };
    setLocalFilters(_filters);
    setFilters(_filters);
  };

  const nextPage = (page) => {
    page++;
    if (page <= stats.pager.pages) {
      let _filters = {
        ...localFilters,
        pager: { ...localFilters.pager, page: page },
      };
      setLocalFilters(_filters);
      setFilters(_filters);
    }
  };

  const prevPage = (page) => {
    page--;
    if (page > 0) {
      let _filters = {
        ...localFilters,
        pager: { ...localFilters.pager, page: page },
      };
      setLocalFilters(_filters);
      setFilters(_filters);
    }
  };
  // END PAGINATION

  if (loading) return <Loading />;
  return (
    <div className="stats">
      {!isEmpty(stats) && (
        <div className="statsolecams-content">
          <Card icon={<IconStatsOC color="#4b5056" />} title={translate('statistics-title-phone-oc')}>
            <div className="stats-ol">
              <Form
                filters={localFilters}
                date={date}
                handleOnChange={handleOnChange}
                handleChangeDate={handleChangeDate}
                /* handleChangeRang={handleChangeRang} */
                getStats={getStats}
              />
            </div>
          </Card>

          <>
            <div className="table-content">
              <Table stats={stats} getStatsDay={getStatsDay} viewType={filters.view} />
              <Pagination
                handleChangeNumberOfColum={handleChangeNumberOfColum}
                pager={stats.pager}
                paginate={paginate}
                prevPage={prevPage}
                nextPage={nextPage}
              />
            </div>
            <FootInfo />
          </>
        </div>
      )}
    </div>
  );
};

export default StatsPhoneOC;

import request from './api-service';

const prefixUrl = '/home';
const prefixAdminUrl = '/admin';

const summary = () => {
  return request({
    method: 'get',
    url: `${prefixUrl}/summarystats`,
  });
};

const adminSummary = () => {
  return request({
    method: 'get',
    url: `${prefixAdminUrl}${prefixUrl}/summarystats`,
  });
};

const HomeService = {
  summary,
  adminSummary,
};

export default HomeService;

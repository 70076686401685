import { getDevBugCookie } from './cookies';

export const formatPrice = (price) => {
  if (price) {
    return new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'EUR',
    }).format(parseFloat(replaceComa(price)));
  } else {
    return new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'EUR',
    }).format(parseFloat(0));
  }
};

export const sum = (value1, value2) => {
  const result = parseFloat(replaceComa(value1)) + parseFloat(replaceComa(value2));
  return new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
  }).format(result);
};

export const click = (id) => document.getElementById(id).click();

export const replaceComa = (value) => {
  if (Number.isInteger(value)) {
    return value;
  } else {
    return value.replace(',', '');
  }
};

export const dateFormat = (date) => {
  let result;
  if (typeof date === 'string') {
    // from String to Date
    var parts = date.split('-');
    var dt = new Date(parseInt(parts[2], 10), parseInt(parts[1], 10) - 1, parseInt(parts[0], 10));
    result = new Date(dt);
  } else {
    let dd = String(date.getDate()).padStart(2, '0');
    let mm = String(date.getMonth() + 1).padStart(2, '0');
    let yyyy = date.getFullYear();
    result = dd + '-' + mm + '-' + yyyy;
  }

  return result;
};

export const datetimeToString = (date, seconds = true) => {
  let dd = String(date.getDate()).padStart(2, '0');
  let mm = String(date.getMonth() + 1).padStart(2, '0');
  let yyyy = date.getFullYear();
  let time = String(date.getHours()).padStart(2, '0') + ':' + String(date.getMinutes()).padStart(2, '0');
  if (seconds) time = time + ':' + String(date.getSeconds()).padStart(2, '0');
  return dd + '-' + mm + '-' + yyyy + ' ' + time;
};

export const devbugData = () => {
  const devCookie = getDevBugCookie();
  let baseHref = process.env.REACT_APP_API_URL;

  if (typeof devCookie !== 'undefined') {
    if (devCookie !== undefined && devCookie !== null && devCookie !== 'default') {
      baseHref = devCookie;
    }
  }

  return baseHref;
};

export const checkIban = (acountNumber) => {
  const CODE_LENGTHS = {
    AD: 24,
    AE: 23,
    AT: 20,
    AZ: 28,
    BA: 20,
    BE: 16,
    BG: 22,
    BH: 22,
    BR: 29,
    CH: 21,
    CR: 21,
    CY: 28,
    CZ: 24,
    DE: 22,
    DK: 18,
    DO: 28,
    EE: 20,
    ES: 24,
    FI: 18,
    FO: 18,
    FR: 27,
    GB: 22,
    GI: 23,
    GL: 18,
    GR: 27,
    GT: 28,
    HR: 21,
    HU: 28,
    IE: 22,
    IL: 23,
    IS: 26,
    IT: 27,
    JO: 30,
    KW: 30,
    KZ: 20,
    LB: 28,
    LI: 21,
    LT: 20,
    LU: 20,
    LV: 21,
    MC: 27,
    MD: 24,
    ME: 22,
    MK: 19,
    MR: 27,
    MT: 31,
    MU: 30,
    NL: 18,
    NO: 15,
    PK: 24,
    PL: 28,
    PS: 29,
    PT: 25,
    QA: 29,
    RO: 24,
    RS: 22,
    SA: 24,
    SE: 24,
    SI: 19,
    SK: 24,
    SM: 27,
    TN: 24,
    TR: 26,
  };
  const iban = String(acountNumber)
    .toUpperCase()
    .replace(/[^A-Z0-9]/g, '');
  const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/);
  if (!code || iban.length !== CODE_LENGTHS[code[1]]) return false;
};

export const langFormar = (lang) => {
  if (!lang) return 'es';
  if (process.env.REACT_APP_LANGUAGES.split(',').indexOf(lang.slice(0, 2)) > -1) {
    return lang.slice(0, 2);
  } else {
    return 'es';
  }
};

// Removes Special Characters and Blank Spaces
export const cleanString = (str) => {
  str = str.replace(/[^A-Z0-9]+/gi, '');
  return str.replace(/\s/g, '');
};

export const hexToHSL = (hex) => {
  var value = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  var r = parseInt(value[1], 16);
  var g = parseInt(value[2], 16);
  var b = parseInt(value[3], 16);

  r /= 255;
  g /= 255;
  b /= 255;

  var max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  var h,
    s,
    l = (max + min) / 2;

  if (max === min) {
    h = s = 0; // achromatic
  } else {
    var d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
      default:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
    }
    h /= 6;
  }

  s = s * 100;
  s = Math.round(s);
  l = l * 100;
  l = Math.round(l);
  h = Math.round(360 * h);

  let result = { h, s, l };

  return result;
};

export const obj2FormData = (obj, formData = new FormData()) => {
  let createFormData = function (obj, subKeyStr = '') {
    for (let i in obj) {
      let value = obj[i];
      let subKeyStrTrans = subKeyStr ? subKeyStr + '[' + i + ']' : i;

      if (typeof value === 'string' || typeof value === 'number') {
        formData.append(subKeyStrTrans, value);
      } else if (typeof value === 'object') {
        createFormData(value, subKeyStrTrans);
      }
    }
  };
  createFormData(obj);
  return formData;
};

export const validateURL = (link) => {
  if (link.indexOf('http://') === 0 || link.indexOf('https://') === 0) {
    //"The link has http or https."
    if (link.indexOf('http://') === 0) {
      return link.replace(/http/g, 'https');
    } else return link;
  } else {
    // The link doesn't have http or https
    return 'https://' + link;
  }
};

export const getPaymentProviderTableName = ({ provider }) => {
  switch (provider.toUpperCase()) {
    case 'CG':
      return 'STATS_VISA_CG';
      break;
    case 'TRUSTPAY':
      return 'STATS_TRUSTPAY';
      break;
    case 'EPOCH':
      return 'STATS_VISA_EPOCH';
      break;
    case 'SEGPAY':
      return 'STATS_SEGPAY';
      break;
    case 'DIRECTA24':
      return 'STATS_DIRECTA24';
      break;
    case 'INESPAY':
      return 'STATS_INESPAY';
      break;
    case 'SOFORT':
      return 'STATS_BANK_SOFORT';
      break;
    case 'SMARTPAY':
      return 'STATS_SMARTPAY';
      break;
    default:
      return 'STATS_VISA_CG';
  }
};

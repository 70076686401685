import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { toast } from 'react-toastify';

//translate
import translate from 'i18n/translate';

//import { getContractApi, acceptContractApi } from 'actions/user';
import UserService from 'shared/user-service';

// components
import Card from 'components/card';
import Importante from 'components/icons/important';
import Button from 'components/button';

//style
import './modal.scss';

const Modal = (props) => {
  const auth = useSelector((state) => state.auth.auth);
  const dispatch = useDispatch();

  const [showContract, setShowContract] = useState(false);
  const [urlContract, setUrlContract] = useState('');
  //const [idContract, setIdContract] = useState(props.idContract);
  const idContract = props.idContract;
  const showAcceptContract = props.showAcceptContract;

  const getContract = useCallback(() => {
    UserService.getContractApi(idContract).then((data) => {
      setUrlContract(window.URL.createObjectURL(data));
      setShowContract(true);
    });
  }, [idContract]);

  useEffect(getContract, [getContract]);

  const acceptContract = async () => {
    await UserService.acceptContractApi(idContract).then((data) => {
      if (data.status !== 'Failed') {
        auth.id_contract = '0';
        dispatch({ type: 'SET_AUTH', auth: { auth: auth, logged: true } });
        UserService.getProfile().then((res) => {
          props.closeModal(true);
        });
      } else toast.error(translate('global-error-try-later'));
    });
  };

  return (
    <div className="avis-content">
      <Card icon={<Importante />} title={translate('notice-name')} modal closeModal={props.closeModal}>
        <div className={classNames('text-content', { contract: showContract })}>
          {showContract && <iframe src={urlContract} frameBorder="0" title="contract" />}
        </div>
        <div className="button-content">
          {!showAcceptContract && showContract && (
            <Button rounded onClick={(e) => acceptContract()} violet>
              {translate('notice-button-acepted')}
            </Button>
          )}
        </div>
      </Card>
    </div>
  );
};

export default Modal;

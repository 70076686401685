import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import { isEmpty } from 'libs/lodashAlt';

import NewsService from 'shared/news-service';

//Component
import Card from 'components/card';
import Search from 'components/icons/search';
import Button from 'components/button';
import Loading from 'components/loading';
import Pagination from 'components/pagination';
import Herramienta from 'components/icons/herramienta';

import CheckIcon from 'components/icons/check';
import UserBlockedIcon from 'components/icons/user/UserBlocked';

import Add from './add';
import Update from './update';

//style
import '../index.scss';

const News = () => {
  let loading = useSelector((state) => state.loading.loading);
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [lastPage, setLastPage] = useState(1);
  const [total, setTotal] = useState(0);

  const [search, setSearch] = useState('');
  const [showAdd, setShowAdd] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [filters, setFilters] = useState({
    search: '',
    active: 1,
  });

  const [noticia, setNoticia] = useState({});

  const getList = useCallback(
    (filters) => {
      dispatch({ type: 'SET_LOADING', loading: true });
      NewsService.adminList({ page, perPage, filters })
        .then((data) => {
          if (data.status === 'Accepted') {
            setData(data.message.data);
            setLastPage(data.message.last_page);
            setTotal(data.message.total);
          }
        })
        .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
    },
    [page, perPage, dispatch]
  );

  useEffect(() => getList(filters), [getList, filters]);

  const searchBtn = (e) => {
    e.preventDefault();
    setFilters({
      ...filters,
      search: search,
    });
  };

  const showUpdateBtn = async (id) => {
    dispatch({ type: 'SET_LOADING', loading: true });

    await NewsService.adminGet(id)
      .then((data) => {
        if (data.status !== 'Failed') setNoticia(data.message.data);
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
    setShowUpdate(true);
  };

  const close = (reload = false) => {
    if (reload) getList(filters);
    setShowAdd(false);
    setShowUpdate(false);
  };

  const remove = (id) => {
    if (window.confirm(formatMessage({ id: 'herramientas-confirm-alert' }))) {
      NewsService.adminDelete(id).then((data) => {
        if (data.status !== 'Failed') getList(filters);
      });
    }
  };

  /* PAGINATOR */
  const paginate = (e) => setPage(e.target.value);

  const handleChangeNumberOfColum = (e) => {
    setPerPage(e.target.value);
    setPage(1);
  };

  const nextPage = (_page) => {
    _page++;
    _page <= lastPage && setPage(_page);
  };

  const prevPage = (_page) => {
    _page--;
    _page > 0 && setPage(_page);
  };
  // END PAGINATOR

  if (loading) return <Loading />;

  return (
    <>
      {!isEmpty(data) && (
        <div className="herramientas-administrativas-content">
          {!showAdd && !showUpdate && (
            <>
              <Card icon={<Herramienta color="#4b5056" />} title={<FormattedMessage id="herramientas-news-name" />}>
                <div className="banner-header-content">
                  <form className={`search-wrapper`} onSubmit={(e) => searchBtn(e)}>
                    <div className="d-flex align-center">
                      <div className="form-group input-content mr-2">
                        <input
                          type="text"
                          className="form-control"
                          name="search"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                        />
                        <Search search={searchBtn} />
                      </div>

                      <div className="form-group input-content">
                        <select
                          className="form-control"
                          name="type"
                          value={filters.active}
                          onChange={(e) => setFilters({ ...filters, active: e.target.value, search: search })}>
                          <option value="1">Publicadas</option>
                          <option value="0">Despublicadas</option>
                          <option value="">Todas</option>
                        </select>
                      </div>
                    </div>
                  </form>
                  <Button superBlue onClick={() => setShowAdd(true)}>
                    <FormattedMessage id="herramientas-banner-button-add" />
                  </Button>
                </div>
              </Card>
              <div className="banner-table-content">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">
                        <FormattedMessage id="global-id" />
                      </th>
                      <th scope="col">
                        <FormattedMessage id="global-name" />
                      </th>
                      <th scope="col">
                        <FormattedMessage id="global-date" />
                      </th>
                      <th scope="col">Publicado</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length > 0 && (
                      <>
                        {data.map((item, index) => {
                          return (
                            <tr key={index} className={(index + 1) % 2 ? 'impar' : 'par'}>
                              <td>{item.id}</td>
                              <td>{item.titulo}</td>
                              <td>{item.fecha}</td>
                              <td>{item.noticia_activa === 1 ? <CheckIcon cursorDefault /> : <UserBlockedIcon cursorDefault />}</td>
                              <td>
                                <div className="button-content">
                                  <Button blue onClick={(e) => showUpdateBtn(item.id)}>
                                    <FormattedMessage id="herramientas-banner-button-update" />
                                  </Button>
                                  <Button red onClick={(e) => remove(item.id)}>
                                    <FormattedMessage id="herramientas-banner-button-remove" />
                                  </Button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
              <Pagination
                handleChangeNumberOfColum={handleChangeNumberOfColum}
                pager={{ page: page, items: perPage, pages: lastPage }}
                paginate={paginate}
                prevPage={prevPage}
                nextPage={nextPage}
                total={total}
              />
            </>
          )}
          {showAdd && <Add close={close} />}
          {showUpdate && <Update noticia={noticia} close={close} />}
        </div>
      )}
    </>
  );
};

export default News;

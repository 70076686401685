import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import OlecamsUsersService from 'shared/olecams-users-service';

import { FIDELITY_TYPES } from 'libs/OlecamsUsers';

import { isEmpty } from 'libs/lodashAlt';
//Component
import Card from 'components/card';
import Loading from 'components/loading';
import Pagination from 'components/pagination';
import CustomButton from 'components/button';
import Password from './password';
import BanUserModal from './banUserModal';
import FidelityHistoryModal from './FidelityHistoryModal';
import UpdateCredits from './updateCredits';
import UserDetail from './UserDetail';
import UserStatus from './UserStatus';
import InfoBottom from './InfoBottom';

// Icons
import IconCheck from 'components/icons/check';
import IconTool from 'components/icons/herramienta';
import IconShoppingCartIcon from 'components/icons/shoppingCart';
import IconUserDeactive from 'components/icons/user/UserDeactive';
import IconKey from 'components/icons/key';
import IconCurrency from 'components/icons/currency';
import IconPadLock from 'components/icons/padlock';
import AccountQuestionIcon from 'components/icons/account-question';

//style
import '../index.scss';
import './index.scss';

const OCUserList = (props) => {
  let loading = useSelector((state) => state.loading.loading);
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const [showChangePassword, setShowChangePassword] = useState(false);
  const [showBanUserModal, setShowBanUserModal] = useState(false);
  const [showUpdateCredits, setShowUpdateCredits] = useState(false);
  const [showUserData, setShowUserData] = useState(false);
  const [showFidelityHistory, setShowFidelityHistory] = useState(false);
  const [userId, setUserId] = useState(null);
  const [userData, setUserData] = useState({});
  const [userNick, setUserNick] = useState(null);
  const [userStatus, setUserStatus] = useState(null);
  const [filters, setFilters] = useState({
    search: '',
    active: '',
    age_verified: '',
    email_validated: '',
    fidelity: '',
  });

  const [response, setResponse] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const [lastPage, setLastPage] = useState(1);

  const getList = useCallback(
    (filters) => {
      dispatch({ type: 'SET_LOADING', loading: true });
      OlecamsUsersService.adminList({ page, perPage, filters })
        .then((data) => {
          setResponse(data.message);
          setLastPage(data.message.last_page);
        })
        .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
    },
    [dispatch, page, perPage]
  );

  useEffect(() => getList(filters), [getList]);

  const getData = () => {
    setPage(1);
    dispatch({ type: 'SET_LOADING', loading: true });
    OlecamsUsersService.adminList({ page: 1, perPage, filters })
      .then((data) => {
        setResponse(data.message);
        setLastPage(data.message.last_page);
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
  };

  const searchBtn = (e) => {
    e.preventDefault();
    getData();
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const showChangePasswordEvt = (id) => {
    setShowChangePassword(true);
    setUserId(id);
  };

  const showBanUserModalEvt = (id, nick, status) => {
    setShowBanUserModal(true);
    setUserId(id);
    setUserNick(nick);
    setUserStatus(status);
  };

  const showFidelityHistoryEvt = (id, nick) => {
    setShowFidelityHistory(true);
    setUserId(id);
    setUserNick(nick);
  };

  const showUpdateCreditsEvt = (id) => {
    setShowUpdateCredits(true);
    setUserId(id);
  };

  const showUserDataEvt = (data) => {
    setShowUserData(true);
    setUserData(data);
  };

  const close = (reload = false) => {
    if (reload) getList(filters);
    setShowChangePassword(false);
    setShowBanUserModal(false);
    setShowUpdateCredits(false);
    setShowUserData(false);
    setShowFidelityHistory(false);
  };

  const activateDeactivateUser = (id, status) => {
    let msg = 'herramientas-olecams-user-deactive-alert';
    const active = status === '0' ? -1 : 0;
    if (status !== '0') msg = 'herramientas-olecams-user-active-alert';

    if (window.confirm(formatMessage({ id: msg }))) {
      dispatch({ type: 'SET_LOADING', loading: true });
      OlecamsUsersService.userManagement({ field: 'ACTIVE', value: active, user_id: id })
        .then((data) => {
          if (data.message === 1) {
            toast.success(formatMessage({ id: 'global-update-success' }));
            getList(filters);
          } else {
            toast.error(formatMessage({ id: 'herramientas-olecams-user-error-request-ban' }));
          }
        })
        .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
    }
  };

  const ageVerification = (id, status) => {
    let msg = 'herramientas-olecams-user-age-verification-remove-alert';
    const verified = status === 0 ? 1 : 0;
    if (status !== 1) msg = 'herramientas-olecams-user-age-verification-add-alert';

    if (window.confirm(formatMessage({ id: msg }))) {
      dispatch({ type: 'SET_LOADING', loading: true });
      OlecamsUsersService.adminUpdate({ ID: id, AGE_VERIFIED: verified })
        .then((data) => {
          if (data.status === 'Accepted') {
            toast.success(formatMessage({ id: 'global-update-success' }));
            getList(filters);
          }
        })
        .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
    }
  };

  /* PAGINATOR */
  const paginate = (e) => setPage(e.target.value);

  const handleChangeNumberOfColum = (e) => {
    setPerPage(e.target.value);
    setPage(1);
  };

  const nextPage = (_page) => {
    _page++;
    _page <= lastPage && setPage(_page);
  };

  const prevPage = (_page) => {
    _page--;
    _page > 0 && setPage(_page);
  };

  if (loading) return <Loading />;
  return (
    <>
      {!isEmpty(response) && (
        <div className="herramientas-administrativas-content">
          {!showUpdateCredits && !showChangePassword && !showBanUserModal && !showUserData && !showFidelityHistory && (
            <>
              <Card icon={<IconTool color="#4b5056" />} title={<FormattedMessage id="herramientas-olecams-user-name" />}>
                <div className="users-oc-header-content">
                  <form className={`search-wrapper ${props.class}`} onSubmit={searchBtn}>
                    <div className="first-filters">
                      <div className="form-group">
                        <label>
                          <FormattedMessage id="global-search" />
                        </label>
                        <Form.Control value={filters.search} name="search" placeholder="" onChange={handleOnChange} />
                      </div>
                      <div className="form-group">
                        <label>
                          <FormattedMessage id="statistics-table-stat" />
                        </label>
                        <Form.Control as="select" custom name="active" onChange={handleOnChange} value={filters.active}>
                          <option value="">Todos</option>
                          <option value="-2">Sólo Baneados</option>
                        </Form.Control>
                      </div>
                      <div className="form-group">
                        <label>Email validado</label>
                        <Form.Control as="select" custom name="email_validated" onChange={handleOnChange} value={filters.email_validated}>
                          <option value="">Todos</option>
                          <option value="1">Si</option>
                          <option value="0">No</option>
                        </Form.Control>
                      </div>
                      <div className="form-group">
                        <label>Fidelización</label>
                        <Form.Control as="select" custom name="fidelity" onChange={handleOnChange} value={filters.fidelity}>
                          <option value="">Todos</option>
                          <option value="0">Sin fidelidad</option>
                          <option value="1">Fidelidad Anónimo</option>
                        </Form.Control>
                      </div>
                      <div className="form-group">
                        <label>Edad verificada</label>
                        <Form.Control as="select" custom name="age_verified" onChange={handleOnChange} value={filters.age_verified}>
                          <option value="">Todos</option>
                          <option value="1">Si</option>
                          <option value="0">No</option>
                        </Form.Control>
                      </div>
                    </div>
                    <div className="buttons-content">
                      <CustomButton rounded onClick={searchBtn}>
                        <FontAwesomeIcon icon={faSearch} className="mr-2" /> <FormattedMessage id="global-send" />
                      </CustomButton>
                    </div>
                  </form>
                </div>
              </Card>
              <div className="olecams-user-table-content">
                <Table
                  data={response.data}
                  showUserDataEvt={showUserDataEvt}
                  showChangePasswordEvt={showChangePasswordEvt}
                  showBanUserModalEvt={showBanUserModalEvt}
                  activateDeactivateUser={activateDeactivateUser}
                  showUpdateCreditsEvt={showUpdateCreditsEvt}
                  ageVerification={ageVerification}
                  showFidelityHistoryEvt={showFidelityHistoryEvt}
                  getData={getData}
                />
              </div>
              {response && response.data && response.total > 0 && (
                <Pagination
                  handleChangeNumberOfColum={handleChangeNumberOfColum}
                  pager={{ page: page, items: perPage, pages: lastPage }}
                  paginate={paginate}
                  prevPage={prevPage}
                  nextPage={nextPage}
                  total={response.total}
                />
              )}
              <InfoBottom />
            </>
          )}
          {showChangePassword && <Password close={close} id={userId} />}
          {showBanUserModal && <BanUserModal close={close} id={userId} nick={userNick} status={userStatus} />}
          {showUpdateCredits && <UpdateCredits close={close} id={userId} />}
          {showUserData && <UserDetail close={close} data={userData} userId={userId} />}
          {showFidelityHistory && <FidelityHistoryModal close={close} id={userId} nick={userNick} />}
        </div>
      )}
    </>
  );
};

const Table = ({
  data,
  showUserDataEvt,
  showChangePasswordEvt,
  showBanUserModalEvt,
  showFidelityHistoryEvt,
  activateDeactivateUser,
  showUpdateCreditsEvt,
  ageVerification,
  getData,
}) => {
  const dispatch = useDispatch();

  const changeFidelity = async (e, item) => {
    e.preventDefault();
    dispatch({ type: 'SET_LOADING', loading: true });
    await OlecamsUsersService.adminUpdate({ ID: item.ID, FIDELITY: e.target.value })
      .then((data) => {
        toast.success('Fidelización actualizada');
        getData();
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
  };

  return (
    <table className="table">
      <thead>
        <tr>
          <th scope="col">
            <FormattedMessage id="global-id" />
          </th>
          <th scope="col">
            <FormattedMessage id="global-name" />
          </th>
          <th scope="col">
            <FormattedMessage id="herramientas-banner-table-email" />
          </th>
          <th scope="col">
            <FormattedMessage id="statistics-table-credit" />
          </th>
          <th scope="col">
            <FormattedMessage id="global-time" />
          </th>
          <th scope="col">
            <FormattedMessage id="global-active" />
          </th>
          <th>Edad Verificada</th>
          <th scope="col">
            <FormattedMessage id="global-date-register" />
          </th>
          <th scope="col">
            <FormattedMessage id="global-olecams-user-last-date" />
          </th>
          <th scope="col">
            <FormattedMessage id="global-validated-email" />
          </th>
          <th scope="col">Fidelización Anónimo</th>
          <th scope="col">Fidelización Registrado</th>
          <th scope="col">
            <FormattedMessage id="herramientas-banner-table-new-password" />
          </th>
          <th scope="col">
            <FormattedMessage id="global-ban-user" />
          </th>
          <th scope="col">
            <FormattedMessage id="global-active-deactivate-user" />
          </th>
          <th scope="col">Verificar Edad</th>
          <th scope="col">
            <FormattedMessage id="global-update-credits" />
          </th>
          <th scope="col">
            <FormattedMessage id="herramientas-olecams-user-purchase-history" />
          </th>
          {/* <th scope="col">{translate('herramientas-banner-table-billing')}</th> */}
        </tr>
      </thead>
      <tbody>
        {data.length > 0 && (
          <>
            {data.map((item, index) => {
              return (
                <tr key={index} className={(index + 1) % 2 ? 'impar' : 'par'}>
                  <td className="hover" onClick={(e) => showUserDataEvt(item)}>
                    {item.ID}
                  </td>
                  <td className="hover" onClick={(e) => showUserDataEvt(item)}>
                    {item.NICK}
                  </td>
                  <td className="hover" onClick={(e) => showUserDataEvt(item)}>
                    {item.EMAIL}
                  </td>
                  <td>{item.CREDITS}</td>
                  <td>{item.TIEMPO}</td>
                  <td>
                    <UserStatus status={item.ACTIVE} />
                  </td>
                  <td>{Boolean(Number(item.AGE_VERIFIED)) && <IconCheck />}</td>
                  <td>{new Date(item.SIGNIN_DATE).toLocaleDateString()}</td>
                  <td>{new Date(item.LAST_DATE).toLocaleDateString()}</td>
                  <td>{item.EMAIL_VALIDATED === 1 && <IconCheck cursorDefault />}</td>
                  <td>
                    <select value={item.FIDELITY} onChange={(e) => changeFidelity(e, item)} className="form-control">
                      <option value={FIDELITY_TYPES.NORMAL}>Normal</option>
                      <option value={FIDELITY_TYPES.ANONYMOUS_SPECIAL}>Anónimo especial</option>
                    </select>
                  </td>
                  <td>
                    <div className="cursorPointer" onClick={() => showFidelityHistoryEvt(item.ID, item.NICK)}>
                      {item.FIDELITY_LEVEL}
                    </div>
                  </td>
                  <td>
                    <IconKey go={showChangePasswordEvt} id={item.ID} />
                  </td>
                  <td>
                    <div onClick={(e) => showBanUserModalEvt(item.ID, item.NICK, item.ACTIVE)}>
                      <IconPadLock />
                    </div>
                  </td>
                  {/*<td><div onClick={(e) => { banUser(item.ID, item.ACTIVE)} }><PadLock /></div></td>*/}
                  <td>
                    <div onClick={(e) => activateDeactivateUser(item.ID, item.ACTIVE)}>
                      <IconUserDeactive />
                    </div>
                  </td>
                  <td>
                    <div onClick={(e) => ageVerification(item.ID, item.AGE_VERIFIED)}>
                      <AccountQuestionIcon />
                    </div>
                  </td>
                  <td>
                    <IconCurrency go={showUpdateCreditsEvt} id={item.ID} />
                  </td>
                  <td>
                    <Link
                      to={{ pathname: `/estadisticas/olecams/stats-user/${item.EMAIL}` }}
                      role="button"
                      target="_blank"
                      rel="noopener noreferrer">
                      <IconShoppingCartIcon />
                    </Link>
                  </td>
                </tr>
              );
            })}
          </>
        )}
      </tbody>
    </table>
  );
};

export default OCUserList;

import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';

import { isEmpty } from 'libs/lodashAlt';

import LandingService from 'shared/landing-service';

//Component
import Card from 'components/card';
import Search from 'components/icons/search';
import Button from 'components/button';
import Loading from 'components/loading';
import Pagination from 'components/pagination';
import Herramienta from 'components/icons/herramienta';

import IconMobile from 'components/icons/movil';
import IconDesktop from 'components/icons/computer';

import Add from './add';
import Update from './update';

//style
import '../index.scss';

const Landings = (props) => {
  let loading = useSelector((state) => state.loading.loading);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const [lastPage, setLastPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({ search: '' });

  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const [showAdd, setShowAdd] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [landing, setLanding] = useState({});

  useEffect(() => {
    setData([]);
    dispatch({ type: 'SET_LOADING', loading: true });
    LandingService.adminList({ page, perPage, filters })
      .then((responseData) => {
        responseData.status === 'Failed' && toast.error('No se pudo cargar la información');
        if (responseData.status === 'Accepted') {
          setTotal(responseData.message.total);
          setData(responseData.message.data);
          setLastPage(responseData.message.last_page);
        }
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
  }, [page, perPage, dispatch]);

  const sendSubmit = (e) => {
    e.preventDefault();
    getData();
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;

    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const getData = () => {
    setData([]);
    setPage(1);
    setTotal(0);
    dispatch({ type: 'SET_LOADING', loading: true });
    LandingService.adminList({ page: 1, perPage, filters })
      .then((responseData) => {
        responseData.status === 'Failed' && toast.error('No se pudo cargar la información');
        if (responseData.status === 'Accepted') {
          setTotal(responseData.message.total);
          setData(responseData.message.data);
          setLastPage(responseData.message.last_page);
        }
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
  };

  const showRegister = (e) => setShowAdd(true);
  const showUpdateBtn = async (id) => {
    dispatch({ type: 'SET_LOADING', loading: true });

    await LandingService.adminGet(id)
      .then((data) => {
        if (data.status !== 'Failed') setLanding(data.message.data);
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
    setShowUpdate(true);
  };

  const close = (reload = false) => {
    if (reload) getData();

    setShowAdd(false);
    setShowUpdate(false);
  };

  const remove = (id) => {
    if (window.confirm(formatMessage({ id: 'herramientas-confirm-alert' }))) {
      LandingService.adminDelete(id).then((data) => {
        if (data.status !== 'Failed') getData();
      });
    }
  };

  /* PAGINATOR */
  const paginate = (e) => setPage(e.target.value);

  const handleChangeNumberOfColum = (e) => {
    setPerPage(e.target.value);
    setPage(1);
  };

  const nextPage = (_page) => {
    _page++;
    _page <= lastPage && setPage(_page);
  };

  const prevPage = (_page) => {
    _page--;
    _page > 0 && setPage(_page);
  };

  if (loading) return <Loading />;

  return (
    <>
      <div className="herramientas-administrativas-content">
        {!showAdd && !showUpdate && (
          <>
            <Card icon={<Herramienta color="#4b5056" />} title={<FormattedMessage id="herramientas-landing-name" />}>
              <div className="banner-header-content">
                <form className={`search-wrapper ${props.class}`} onSubmit={sendSubmit}>
                  <div className="form-group input-content">
                    <input type="text" className="form-control" name="search" value={filters.search} onChange={handleOnChange} />
                    <Search search={sendSubmit} />
                  </div>
                </form>
                <Button superBlue onClick={(e) => showRegister(e)}>
                  <FormattedMessage id="herramientas-banner-button-add" />
                </Button>
              </div>
            </Card>
            {!isEmpty(data) && (
              <div className="banner-table-content">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">
                        <FormattedMessage id="global-id" />
                      </th>
                      <th scope="col">
                        <FormattedMessage id="global-url" />
                      </th>
                      <th scope="col">
                        <FormattedMessage id="global-category" />
                      </th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {data && total > 0 && (
                      <>
                        {data.map((item, index) => {
                          return (
                            <tr key={index} className={(index + 1) % 2 ? 'impar' : 'par'}>
                              <td>{item.ID}</td>
                              <td>{item.URL}</td>
                              <td>
                                <CategoryIcon category={item.CATEGORY} />
                              </td>
                              <td>
                                <div className="button-content">
                                  <Button blue onClick={(e) => showUpdateBtn(item.ID)}>
                                    <FormattedMessage id="herramientas-banner-button-update" />
                                  </Button>
                                  <Button red onClick={(e) => remove(item.ID)}>
                                    <FormattedMessage id="herramientas-banner-button-remove" />
                                  </Button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            )}
            <Pagination
              handleChangeNumberOfColum={handleChangeNumberOfColum}
              pager={{ page: page, items: perPage, pages: lastPage }}
              paginate={paginate}
              prevPage={prevPage}
              nextPage={nextPage}
              total={total}
            />
          </>
        )}
        {showAdd && <Add close={close} />}
        {showUpdate && <Update landing={landing} close={close} />}
      </div>
    </>
  );
};

const CategoryIcon = ({ category }) => {
  if (category === 'DESKTOP') return <IconDesktop />;
  if (category === 'MOBILE') return <IconMobile />;

  return <IconDesktop />; //default icon
};

export default Landings;

import axios from 'axios';

import Logger from '../libs/logger';
import { getAuth, getDevBugCookie, removeAuth } from '../libs/cookies';
import { toast } from 'react-toastify';

const logger = new Logger('ApiService');

export const getApiUrl = () => {
  const devCookie = getDevBugCookie();
  let baseHref = process.env.REACT_APP_API_URL;

  if (typeof devCookie !== 'undefined') {
    if (devCookie !== undefined && devCookie !== null && devCookie !== 'default') {
      baseHref = devCookie;
    }
  }

  return baseHref;
};
export const getClient = (config) => {
  let headers = {
    Accept: 'application/json; charset=utf-8',
    'Content-Type': 'application/json; charset=utf-8',
  };

  if (getAuth()) {
    headers['Authorization'] = `Bearer ${getAuth().token}`;
  }

  return axios.create({
    baseURL: getApiUrl(),
    headers,
    ...config,
  });
};

const request = (options, config = {}) => {
  const start = Date.now();
  //const { logout } = useAuth();

  const onSuccess = function (response) {
    if (response.data.message === 'Unauthorized - Token not exists or expired') {
      removeAuth();
      window.location.href = '/login';
    }

    logger.log(response.config.method.toUpperCase(), response.config.url, `successful in ${Date.now() - start}ms`);
    return response.data;
  };
  const onError = function (error) {
    toast.error('Ocurrió un error en el servidor, por favor ponte en contacto con el equipo de soporte');
    logger.error('Request Failed:', error.config);
    if (error.response) {
      // Request was made but server responded with something
      // other than 2xx
      logger.error('Status:', error.response.status);
      logger.error('Data:', error.response.data);
      logger.error('Headers:', error.response.headers);
    } else {
      // Something else happened while setting up the request
      // triggered the error
      logger.error('Error Message:', error.message);
    }
    return Promise.reject(error.response || error.message);
  };
  return getClient(config)(options).then(onSuccess).catch(onError);
};

export default request;

import { useSelector } from 'react-redux';
import es from 'date-fns/locale/es';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useIntl } from 'react-intl';

import { getLang } from 'libs/cookies';

import Button from 'components/button';
import translate from 'i18n/translate';

import IconCalendar from 'components/icons/calendar';

const Form = (props) => {
  const auth = useSelector((state) => state.auth.auth);
  const intl = useIntl();
  registerLocale('es', es);
  return (
    <form className="form-content">
      <div className="form-detall-content vce">
        <div className="content-one">
          <div className="form-group">
            <label>{translate('statistics-form-select-rang-vce')}</label>
            <select className="form-control" name="view" onChange={props.handleChangeRang} value={props.rang}>
              <option value="month">{intl.formatMessage({ id: 'statistics-form-select-month' })}</option>
              <option value="day">{intl.formatMessage({ id: 'statistics-form-select-day' })}</option>
            </select>
          </div>
          <div className="form-group calendar sp">
            <label>{translate('statistics-form-date')}</label>
            <div className="form-control">
              {props.rang === 'month' ? (
                <DatePicker
                  selected={props.date}
                  onChange={(date) => props.handleChangeDate(date)}
                  locale={getLang()}
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                  showFullMonthYearPicker
                  showTwoColumnMonthYearPicker
                  id="calendar-vce"
                  popperPlacement="bottom"
                />
              ) : (
                <DatePicker
                  selected={props.date}
                  onChange={(date) => props.handleChangeDate(date)}
                  locale={getLang()}
                  dateFormat="dd/MM/yyyy"
                  id="calendar-vce"
                  popperPlacement="bottom"
                />
              )}
            </div>
            <IconCalendar id="calendar-vce" />
          </div>
          <SelectPaymentType value={props.paymentType} handleChangeType={props.handleChangeType} />
        </div>
        <div className="content-two">
          <div className="form-group">
            <label>{translate('statistics-form-select-login')}</label>
            <select
              disabled={props.disabledlogin}
              className="form-control"
              name="login"
              onChange={props.handleOnChange}
              value={props.filters.login}>
              <option value="">{intl.formatMessage({ id: 'statistics-form-select-all' })}</option>
              {props.logins.map((item, index) => {
                if (item.login) {
                  return (
                    <option key={index} value={item.id}>
                      {item.login}
                    </option>
                  );
                } else return null;
              })}
            </select>
          </div>
          <div className="form-group">
            <label>{translate('statistics-form-select-login-group')}</label>
            <select
              disabled={props.disabledgroup}
              className="form-control"
              name="logingroup"
              onChange={props.handleOnChange}
              value={props.filters.logingroup}>
              <option value="">{intl.formatMessage({ id: 'statistics-form-select-all' })}</option>
              {props.loginsGroups.map((item, index) => {
                if (item.logins) {
                  return (
                    <option key={index} value={item.id_group}>
                      {item.group}
                    </option>
                  );
                } else return null;
              })}
            </select>
          </div>
          {parseInt(auth.admin) === 1 && (
            <div className="form-group">
              <label>{translate('statistics-form-id-afi')}</label>
              <input
                placeholder={intl.formatMessage({ id: 'statistics-form-select-all' })}
                type="text"
                name="affiliate_id"
                className="form-control"
                value={props.filters.affiliate_id}
                onChange={props.handleOnChange}
              />
            </div>
          )}
        </div>
        <div className="buttons-content">
          <Button rounded onClick={(e) => window.location.reload()} align="center" className="left">
            {translate('statistics-form-button-return')}
          </Button>
          <Button
            rounded
            onClick={(e) => {
              e.preventDefault();
              props.getStats(props.filters);
            }}
            align="center">
            {translate('global-send')}
          </Button>
        </div>
      </div>
    </form>
  );
};

const SelectPaymentType = (props) => {
  const intl = useIntl();
  const { value, handleChangeType } = props;

  return (
    <div className="form-group">
      <label>{translate('statistics-form-select-font')}</label>
      <select className="form-control" name="type" onChange={handleChangeType} value={value}>
        <option value="all">{intl.formatMessage({ id: 'statistics-form-select-all' })}</option>
        <option value="adsl">{intl.formatMessage({ id: 'statistics-form-select-phone' })}</option>
        <option value="visa">{intl.formatMessage({ id: 'statistics-form-select-visa' })}</option>
        <option value="sms">SMS</option>
        <option value="mobileint">Mobile Int</option>
      </select>
    </div>
  );
};

export default Form;

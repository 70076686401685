const FirstBuy = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="22" height="22" viewBox="0 0 22 22">
      <path
        fill="#A2A9B0"
        id="prefix__a"
        d="M8 5h4v10h-2V7H8V5zm2-5c2.652 0 5.196 1.054 7.071 2.929C18.946 4.804 20 7.348 20 10c0 5.523-4.477 10-10 10-2.652 0-5.196-1.054-7.071-2.929C1.054 15.196 0 12.652 0 10s1.054-5.196 2.929-7.071C4.804 1.054 7.348 0 10 0zm0 2c-4.418 0-8 3.582-8 8s3.582 8 8 8c2.122 0 4.157-.843 5.657-2.343S18 12.122 18 10c0-4.418-3.582-8-8-8z"
      />
    </svg>
  );
};

export default FirstBuy;

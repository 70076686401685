const initialState = {
  datos: {},
  tarifas: {},
  referidos: [],
  facturacion: {},
};

const AdministrationReducer = (state = initialState, action) => {
  switch (action.type) {
    // case "SET_MY_DATA":
    //   return Object.assign({}, state, {
    //     datos: action.datos,
    //   });
    case 'SET_TARIFAS':
      return Object.assign({}, state, {
        tarifas: action.tarifas,
      });
    case 'SET_REFERIDOS':
      return Object.assign({}, state, {
        referidos: action.referidos,
      });
    case 'SET_FACTURSACION':
      return Object.assign({}, state, {
        facturacion: action.facturacion,
      });
    default:
      return state;
  }
};

export default AdministrationReducer;

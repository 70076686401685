import { Card, Col, InputGroup, Form, Row, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faInstagram, faTelegram } from '@fortawesome/free-brands-svg-icons';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage } from 'react-intl';

import CustomButton from 'components/button';

const Social = ({
  twitter,
  saveTwitter,
  instagram,
  saveInstagram,
  telegram,
  saveTelegram,
  social_default_image,
  saveSocialDefaultImage,
  saveImage,
  validator,
  edit,
  edit_opengraph,
  updateOpengraphBtn,
}) => {
  return (
    <Card className="mb-3">
      <Card.Header>Social</Card.Header>
      <Card.Body>
        <Row>
          <Col xl="6">
            <Form.Group as={Row} className="mb-3" controlId="formTwitter">
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id={`tooltip-top`}>
                    <FormattedMessage id="herramientas-white-label-twitter-explanation" />
                  </Tooltip>
                }>
                <Form.Label column sm="4" className="font-weight-bold">
                  <span className="text">
                    <FontAwesomeIcon icon={faTwitter} className="mr-1" /> Twitter
                  </span>
                  <span className="help-icon float-right">
                    <FontAwesomeIcon icon={faQuestionCircle} className="mr-1" />
                  </span>
                </Form.Label>
              </OverlayTrigger>
              <Col sm="8">
                <InputGroup>
                  <InputGroup.Text>@</InputGroup.Text>
                  <Form.Control value={twitter} name="twitter" placeholder="" onChange={saveTwitter} />
                </InputGroup>
                {validator.message('twitter', twitter, 'max: 255')}
              </Col>
            </Form.Group>
          </Col>
          <Col xl="6">
            <Form.Group as={Row} className="mb-3" controlId="formInstagram">
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id={`tooltip-top`}>
                    <FormattedMessage id="herramientas-white-label-instagram-explanation" />
                  </Tooltip>
                }>
                <Form.Label column sm="4" className="font-weight-bold">
                  <span className="text">
                    <FontAwesomeIcon icon={faInstagram} className="mr-1" />
                    Instagram
                  </span>
                  <span className="help-icon float-right">
                    <FontAwesomeIcon icon={faQuestionCircle} className="mr-1" />
                  </span>
                </Form.Label>
              </OverlayTrigger>
              <Col sm="8">
                <InputGroup>
                  <InputGroup.Text>@</InputGroup.Text>
                  <Form.Control value={instagram} name="instagram" placeholder="" onChange={saveInstagram} />
                </InputGroup>
                {validator.message('instagram', instagram, 'max: 255')}
              </Col>
            </Form.Group>
          </Col>
        </Row>

        {/*<Form.Group as={Row} className="mb-3" controlId="formSocialDefaultImage">
                    <Form.Label column sm="3" className="font-weight-bold">{translate('global-default-image-url')}</Form.Label>
                    <Col sm="9">
                        <Form.Control
                            value={social_default_image}
                            name="social_default_image"
                            placeholder=""
                            onChange={saveSocialDefaultImage}
                        />
                        { validator.message(intl.formatMessage({id: 'global-default-image-url'}), social_default_image, 'max: 255') }
                    </Col>
                    
                </Form.Group>*/}
        <Row>
          <Col xl="6">
            <Form.Group as={Row} className="mb-3" controlId="formTelegram">
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id={`tooltip-top`}>
                    <FormattedMessage id="herramientas-white-label-telegram-explanation" />
                  </Tooltip>
                }>
                <Form.Label column sm="4" className="font-weight-bold">
                  <span className="text">
                    <FontAwesomeIcon icon={faTelegram} className="mr-1" />
                    Telegram
                  </span>
                  <span className="help-icon float-right">
                    <FontAwesomeIcon icon={faQuestionCircle} className="mr-1" />
                  </span>
                </Form.Label>
              </OverlayTrigger>
              <Col sm="8">
                <InputGroup>
                  <InputGroup.Text>@</InputGroup.Text>
                  <Form.Control value={telegram} name="telegram" placeholder="" onChange={saveTelegram} />
                </InputGroup>
                {validator.message('telegram', telegram, 'max: 255')}
              </Col>
            </Form.Group>
          </Col>
        </Row>

        <Form.Group as={Row} className="mb-3" controlId="formSocialDefaultImage">
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id={`tooltip-top`}>
                <FormattedMessage id="herramientas-white-label-opengraph-explanation" />
              </Tooltip>
            }>
            <Form.Label column sm="4" className="font-weight-bold">
              <span className="text">
                <FormattedMessage id="global-default-image-url" />
              </span>
              <span className="help-icon float-right">
                <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
              </span>
            </Form.Label>
          </OverlayTrigger>
          <Col sm="8">
            <Form.Control
              accept="image/*"
              className="form-control"
              type="file"
              name="favicon"
              onChange={(e) => saveImage(e, 'opengraph')}
            />
          </Col>
        </Form.Group>

        {edit && social_default_image && edit_opengraph && (
          <Row className="mb-2">
            <Col sm="4">
              <CustomButton onClick={(e) => updateOpengraphBtn()}>Actualizar Opengraph</CustomButton>
            </Col>
            <Col sm="8">
              <img style={{ maxWidth: '128px' }} src={URL.createObjectURL(social_default_image)} className="img-preview" alt="" />
            </Col>
          </Row>
        )}
        {edit && social_default_image && (
          <Form.Group as={Row} className="mb-3" controlId="formSocialDefaultImage">
            <Form.Label column sm="4" className="font-weight-bold"></Form.Label>
            <Col sm="8">
              <img style={{ maxWidth: '128px' }} src={social_default_image} className="img-preview" alt="" />
            </Col>
          </Form.Group>
        )}
      </Card.Body>
    </Card>
  );
};

export default Social;

const Currency = ({ go, id, className }) => {
  return (
    <svg onClick={(e) => go(id)} width="24px" height="24px" viewBox="0 0 24 24" className={className}>
      <path
        fill="#4b5056"
        d="M7.07,11L7,12L7.07,13H17.35L16.5,15H7.67C8.8,17.36 11.21,19 14,19C16.23,19 18.22,17.96 19.5,16.33V19.12C18,20.3 16.07,21 14,21C10.08,21 6.75,18.5 5.5,15H2L3,13H5.05L5,12L5.05,11H2L3,9H5.5C6.75,5.5 10.08,3 14,3C16.5,3 18.8,4.04 20.43,5.71L19.57,7.75C18.29,6.08 16.27,5 14,5C11.21,5 8.8,6.64 7.67,9H19.04L18.19,11H7.07Z"
      />
    </svg>
  );
};

export default Currency;

import request from './api-service';
const qs = require('qs');

const prefixUrl = '/affiliate/rates';
const prefixAdminUrl = '/admin';

const adminList = (filters) => {
  let params = { filters };
  return request({
    method: 'get',
    url: `${prefixAdminUrl}${prefixUrl}/list`,
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });
};

const adminUpdate = (data) => {
  return request({
    method: 'put',
    url: `${prefixAdminUrl}${prefixUrl}/update`,
    data: { data },
  });
};

const AffiliatesRatesService = {
  adminList,
  adminUpdate,
};

export default AffiliatesRatesService;

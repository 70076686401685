import request from './api-service';
import { obj2FormData } from 'libs/methods';
const qs = require('qs');

const prefixUrl = '/promo/whitelabel';
const prefixAdminUrl = '/admin';

const get = ({ id = null, mode = null }) => {
  let params = { id, mode };
  return request({
    method: 'get',
    url: `${prefixUrl}/get`,
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });
};

const checkDomain = (domain) => {
  return request({
    method: 'get',
    url: `${prefixUrl}/checkdomain?domain=${domain}`,
  });
};

const set = ({ config, domains, metatags, logo, favicon, social_default_image }) => {
  let formData = obj2FormData({
    config: config,
    metatags: metatags,
    domains: domains,
  });

  // Append like binary files
  formData.append('logo', logo);
  formData.append('favicon', favicon);
  formData.append('social_default_image', social_default_image);

  return request({
    method: 'post',
    url: `${prefixUrl}/set`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

const update = (config, metatags) => {
  return request({
    method: 'put',
    url: `${prefixUrl}/update`,
    data: { config, metatags },
  });
};

const remove = (id) => {
  return request({
    method: 'delete',
    url: `${prefixUrl}/delete`,
    data: { ID: id },
  });
};

const adminGet = (id) => {
  return request({
    method: 'get',
    url: `${prefixAdminUrl}${prefixUrl}/get?id=${id}`,
  });
};

const adminList = ({ page, perPage, filters }) => {
  let params = { filters };
  return request({
    method: 'get',
    url: `${prefixAdminUrl}${prefixUrl}/list?page=${page}&per_page=${perPage}`,
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });
};

const adminUpdate = (config, domains, metatags) => {
  return request({
    method: 'put',
    url: `${prefixAdminUrl}${prefixUrl}/update`,
    data: { config, domains, metatags },
  });
};

const adminUpdateLogo = (id_mb, logo) => {
  let formData = obj2FormData({ id_mb: id_mb });
  formData.append('logo', logo);
  return request({
    method: 'post',
    url: `${prefixAdminUrl}${prefixUrl}/updatelogo`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

const adminUpdateFavicon = (id_mb, favicon) => {
  let formData = obj2FormData({ id_mb: id_mb });
  formData.append('favicon', favicon);
  return request({
    method: 'post',
    url: `${prefixAdminUrl}${prefixUrl}/updatefavicon`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

const adminUpdateOpengraph = (id_mb, social_default_image) => {
  let formData = obj2FormData({ id_mb: id_mb });
  formData.append('social_default_image', social_default_image);
  return request({
    method: 'post',
    url: `${prefixAdminUrl}${prefixUrl}/updateopengraph`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

const adminValidate = (ID) => {
  return request({
    method: 'post',
    url: `${prefixAdminUrl}${prefixUrl}/validate`,
    data: { ID },
  });
};

const adminProduction = (ID) => {
  return request({
    method: 'post',
    url: `${prefixAdminUrl}${prefixUrl}/production`,
    data: { ID },
  });
};

const adminDelete = (id) => {
  return request({
    method: 'delete',
    url: `${prefixAdminUrl}${prefixUrl}/delete`,
    data: { ID: id },
  });
};

const adminClearCache = (id) => {
  return request({
    method: 'delete',
    url: `${prefixAdminUrl}${prefixUrl}/clearcache`,
    data: { ID: id },
  });
};

const adminRedirect = (ID, redirected_reason) => {
  return request({
    method: 'put',
    url: `${prefixAdminUrl}${prefixUrl}/redirect`,
    data: { ID, redirected_reason },
  });
};

const adminReject = (ID, rejected_reason) => {
  return request({
    method: 'put',
    url: `${prefixAdminUrl}${prefixUrl}/reject`,
    data: { ID, rejected_reason },
  });
};

const WhiteLabelsService = {
  get,
  checkDomain,
  set,
  update,
  remove,
  adminGet,
  adminList,
  adminUpdate,
  adminUpdateLogo,
  adminUpdateFavicon,
  adminUpdateOpengraph,
  adminValidate,
  adminProduction,
  adminDelete,
  adminClearCache,
  adminReject,
  adminRedirect,
};

export default WhiteLabelsService;

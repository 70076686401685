import { Col, Row, Form } from 'react-bootstrap';

export const Name = ({ label, value, onChange }) => {
  return (
    <FormGroup label={label}>
      <input className="form-control" type="text" name="nombre" value={value} onChange={onChange} maxLength="50" required />
    </FormGroup>
  );
};

export const Email = ({ label, value, onChange }) => {
  return (
    <FormGroup label={label}>
      <input className="form-control" type="email" name="email" value={value} onChange={onChange} maxLength="100" required />
    </FormGroup>
  );
};

export const User = ({ label, value, onChange }) => {
  return (
    <FormGroup label={label}>
      <input className="form-control" type="text" name="usuario" value={value} onChange={onChange} maxLength="20" required />
    </FormGroup>
  );
};

export const Password = ({ label, value, onChange }) => {
  return (
    <FormGroup label={label}>
      <input
        className="form-control"
        type="password"
        name="password"
        value={value}
        onChange={onChange}
        maxLength="20"
        required
        autoComplete="new-password"
      />
    </FormGroup>
  );
};

export const Url803 = ({ label, value, onChange }) => {
  return (
    <FormGroup label={label}>
      <input
        className="form-control"
        type="url"
        name="url_peticiones_803_default"
        value={value}
        onChange={onChange}
        maxLength="500"
        required
      />
    </FormGroup>
  );
};

export const Status = ({ label, value, onChange }) => {
  return (
    <FormGroup label={label}>
      <select className="form-control" name="status" value={value} onChange={onChange}>
        <option value="0">SuperAdmin</option>
        <option value="2">Usuario</option>
        <option value="99">No validado</option>
        <option value="666">Baneado</option>
        <option value="777">Baja</option>
      </select>
    </FormGroup>
  );
};

export const Audio = ({ label, value, onChange }) => {
  return (
    <FormGroup label={label}>
      <CheckBox name="audio" value={value} onChange={onChange} />
    </FormGroup>
  );
};

export const Toy = ({ label, value, onChange }) => {
  return (
    <FormGroup label={label}>
      <CheckBox name="toy" value={value} onChange={onChange} />
    </FormGroup>
  );
};

export const CreditsAudio = ({ label, value, onChange }) => {
  return (
    <FormGroup label={label}>
      <input className="form-control" type="number" name="credits_audio" value={value} onChange={onChange} min="0" max="100" required />
    </FormGroup>
  );
};

export const CreditsSpy = ({ label, value, onChange }) => {
  return (
    <FormGroup label={label}>
      <input className="form-control" type="number" name="credits_spy" value={value} onChange={onChange} min="0" max="100" required />
    </FormGroup>
  );
};

export const CreditsGroup = ({ label, value, onChange }) => {
  return (
    <FormGroup label={label}>
      <input className="form-control" type="number" name="credits_group" value={value} onChange={onChange} min="0" max="100" required />
    </FormGroup>
  );
};

export const CreditsVip = ({ label, value, onChange }) => {
  return (
    <FormGroup label={label}>
      <input className="form-control" type="number" name="credits_vip" value={value} onChange={onChange} min="0" max="100" required />
    </FormGroup>
  );
};

const CheckBox = ({ name, value, onChange }) => {
  return <input className="form-control" type="checkbox" name={name} checked={value === 1 ? true : false} onChange={onChange} />;
};

const FormGroup = ({ label, children }) => {
  return (
    <Form.Group as={Row} className="mb-3">
      <label className="font-weight-bold form-label col-form-label col-sm-6">{label}</label>
      <Col sm="6">{children}</Col>
    </Form.Group>
  );
};

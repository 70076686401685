import DatePicker, { registerLocale } from 'react-datepicker';
import { FormattedMessage, useIntl } from 'react-intl';
import es from 'date-fns/locale/es';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { Form, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';

import translate from 'i18n/translate';

import Calendar from 'components/icons/calendar';
import CustomButton from 'components/button';
import Descriptions from 'components/Discounts/Descriptions';
import UserAssignment from 'components/Discounts/UserAssignment';

// Libs
import { getLang } from 'libs/cookies';

import { CREDIT_GIFT } from 'shared/constants';

import OlecamsUsersService from 'shared/olecams-users-service';

// Hooks
import useValidator from 'hooks/useValidator';

import { cleanString } from 'libs/methods';

const CreditGiftCreateUpdateForm = ({ data, setData, createUpdate, startDate, handleStartDate, endDate, handleEndDate }) => {
  const { formatMessage } = useIntl();
  registerLocale('es', es);

  const [validator, showValidationMessage] = useValidator({}, {}, getLang());

  const createUpdateBtn = (e) => {
    validator.allValid() ? createUpdate() : showValidationMessage(true);
  };

  const handleData = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setData({
      ...data,
      [name]: checked,
    });
  };

  const checkCode = (e) => {
    setData({
      ...data,
      CODE: cleanString(e.target.value.toUpperCase()),
    });
  };

  return (
    <Row className="justify-content-between">
      <Col>
        <Form id="form" className="form">
          <Row>
            <Col xl="6">
              <Row>
                <Col xl="6">
                  <CreditGiftType data={data} setData={setData} />
                </Col>
                <Col xl="6">
                  <Form.Group as={Row} className="mb-3 required" controlId="formDiscount">
                    <Form.Label column sm="6" className="font-weight-bold">
                      Créditos a regalar
                    </Form.Label>
                    <Col sm="6">
                      <Form.Control
                        type="number"
                        min={CREDIT_GIFT.FORM.CREDITS.MIN}
                        max={CREDIT_GIFT.FORM.CREDITS.MAX}
                        value={data.CREDITS}
                        name="CREDITS"
                        placeholder=""
                        onChange={handleData}
                      />
                      {validator.message('créditos', data.CREDITS, CREDIT_GIFT.FORM.CREDITS.RULES)}
                    </Col>
                  </Form.Group>
                </Col>
              </Row>

              {data.TYPE !== CREDIT_GIFT.TYPES.GLOBAL && (
                <Form.Group as={Row} className="mb-3 required" controlId="formName">
                  <Form.Label column sm="3" className="font-weight-bold">
                    {translate('global-code')}
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control value={data.CODE} name="CODE" placeholder="" onChange={handleData} onBlur={checkCode} />
                    {validator.message(formatMessage({ id: 'global-code' }).toLowerCase(), data.CODE, CREDIT_GIFT.FORM.CODE.RULES)}
                  </Col>
                </Form.Group>
              )}
              <Descriptions data={data} validator={validator} handleData={handleData} langs={['es']} />
              {data.TYPE === CREDIT_GIFT.TYPES.CAMPAIGN && <UserTypePurchase data={data} setData={setData} />}
              {data.TYPE !== CREDIT_GIFT.TYPES.GLOBAL && (
                <LimitSection data={data} handleData={handleData} setData={setData} handleCheck={handleCheck} validator={validator} />
              )}
              {data.TYPE === CREDIT_GIFT.TYPES.INDIVIDUAL && <UserAssignment data={data} handleChange={setData} />}
            </Col>
            <Col xl="6">
              <DateRangeSection startDate={startDate} handleStartDate={handleStartDate} endDate={endDate} handleEndDate={handleEndDate} />
            </Col>
          </Row>
          <Row>
            <Col>
              <CustomButton rounded className="w-100" onClick={createUpdateBtn}>
                <FontAwesomeIcon icon={faSave} className="mr-2" />
                <span>{translate('administration-data-button')}</span>
              </CustomButton>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};

const CreditGiftType = ({ data, setData }) => {
  const changeType = (e) => {
    const { name, value } = e.target;
    let useLimit = true;
    let limitPerUser = 1;

    if (value === CREDIT_GIFT.TYPES.GLOBAL) {
      useLimit = false;
      limitPerUser = 0;
    }

    setData({
      ...data,
      [name]: value,
      USE_LIMIT: useLimit,
      USER_TYPE_PURCHASE: CREDIT_GIFT.USER_TYPE_PURCHASE.ALL,
      LIMIT_NUMBER: 0,
      LIMIT_PER_USER: limitPerUser,
    });
  };

  return (
    <Form.Group as={Row} className="mb-3 required" controlId="formType">
      <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-top`}>Tipo de Regalo de créditos, Global no tiene límites</Tooltip>}>
        <Form.Label column sm="6" className="font-weight-bold">
          {translate('global-type')} <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
        </Form.Label>
      </OverlayTrigger>
      <Col sm="6">
        <select className="form-control" onChange={changeType} name="TYPE" value={data.TYPE}>
          {/* <option value={CREDIT_GIFT.TYPES.GLOBAL}>Global</option> */}
          <FormattedMessage id="promo-campaign-name">{(msg) => <option value={CREDIT_GIFT.TYPES.CAMPAIGN}>{msg}</option>}</FormattedMessage>
          <option value={CREDIT_GIFT.TYPES.INDIVIDUAL}>Individual</option>
        </select>
      </Col>
    </Form.Group>
  );
};

const UserTypePurchase = ({ data, setData }) => {
  const changeType = (e) => {
    const { name, value } = e.target;

    setData({
      ...data,
      [name]: value,
    });
  };
  return (
    <Form.Group as={Row} className="mb-3 required" controlId="formType">
      <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-top`}>Tipo de usuario</Tooltip>}>
        <Form.Label column sm="6" className="font-weight-bold">
          Tipo de usuario <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
        </Form.Label>
      </OverlayTrigger>
      <Col sm="6">
        <select className="form-control" onChange={changeType} name="USER_TYPE_PURCHASE" value={data.USER_TYPE_PURCHASE}>
          <option value={CREDIT_GIFT.USER_TYPE_PURCHASE.ALL}>Todos</option>
          <option value={CREDIT_GIFT.USER_TYPE_PURCHASE.WITH_PURCHASE}>Con compras</option>
          <option value={CREDIT_GIFT.USER_TYPE_PURCHASE.WITHOUT_PURCHASE}>Sin compras</option>
        </select>
      </Col>
    </Form.Group>
  );
};

const LimitSection = ({ data, handleData, setData, validator }) => {
  const { formatMessage } = useIntl();

  const handleLimitCheck = (e) => {
    const { name, checked } = e.target;
    if (!checked) {
      setData({
        ...data,
        [name]: checked,
        LIMIT_NUMBER: 0,
        LIMIT_PER_USER: 0,
      });
    } else {
      setData({
        ...data,
        [name]: checked,
        LIMIT_NUMBER: 0,
        LIMIT_PER_USER: 1,
      });
    }
  };

  return (
    <>
      <Form.Group as={Row} className="mb-3 required" controlId="formCheckLimit">
        <Form.Label column sm="3" className="font-weight-bold">
          Limitar
        </Form.Label>
        <Col sm="9">
          <Form.Check type="checkbox" name="USE_LIMIT" checked={data.USE_LIMIT} onChange={handleLimitCheck} />
        </Col>
      </Form.Group>

      {data.USE_LIMIT && (
        <>
          <Row>
            {data.TYPE !== 'INDIVIDUAL' && (
              <Col>
                <Form.Group as={Row} className="mb-3 required" controlId="formUseLimit">
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id={`tooltip-top`}>{translate('global-total-limit-explanation')}</Tooltip>}>
                    <Form.Label column sm="6" className="font-weight-bold">
                      {translate('global-total-limit')} <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
                    </Form.Label>
                  </OverlayTrigger>

                  <Col sm="6">
                    <Form.Control type="number" value={data.LIMIT_NUMBER} name="LIMIT_NUMBER" placeholder="" onChange={handleData} />
                    {validator.message(
                      formatMessage({ id: 'global-limit' }).toLowerCase(),
                      data.LIMIT_NUMBER,
                      CREDIT_GIFT.FORM.LIMIT_NUMBER.RULES
                    )}
                  </Col>
                </Form.Group>
              </Col>
            )}
            <Col>
              <Form.Group as={Row} className="mb-3 required" controlId="formUseLimit">
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id={`tooltip-top`}>{translate('global-limit-per-user-explanation')}</Tooltip>}>
                  <Form.Label column sm="6" className="font-weight-bold">
                    {translate('global-limit-per-user')} <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
                  </Form.Label>
                </OverlayTrigger>
                <Col sm="6">
                  <Form.Control type="number" value={data.LIMIT_PER_USER} name="LIMIT_PER_USER" placeholder="" onChange={handleData} />
                  {validator.message(
                    formatMessage({ id: 'global-limit-per-user' }).toLowerCase(),
                    data.LIMIT_PER_USER,
                    CREDIT_GIFT.FORM.LIMIT_PER_USER.RULES
                  )}
                </Col>
              </Form.Group>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

const DateRangeSection = ({ startDate, handleStartDate, endDate, handleEndDate }) => {
  return (
    <>
      <Row className="mb-3 required form-group calendar sp">
        <Form.Label column sm="3" className="font-weight-bold">
          <FormattedMessage id="global-start-date" />
        </Form.Label>
        <Col sm="9">
          <div className="form-control">
            <DatePicker
              selected={startDate}
              onChange={(date) => handleStartDate(date)}
              showTimeSelect
              locale={getLang()}
              dateFormat="dd/MM/yyyy HH:mm"
              id="calendar-date-start"
              popperPlacement="bottom"
              minDate={new Date()}
            />
          </div>
          <Calendar id="calendar-date-start" />
        </Col>
      </Row>

      <Row className="mb-3 required form-group calendar sp">
        <Form.Label column sm="3" className="font-weight-bold">
          <FormattedMessage id="global-end-date" />
        </Form.Label>
        <Col sm="9">
          <div className="form-control">
            <DatePicker
              selected={endDate}
              onChange={(date) => handleEndDate(date)}
              showTimeSelect
              locale={getLang()}
              dateFormat="dd/MM/yyyy HH:mm"
              id="calendar-date-end"
              popperPlacement="bottom"
              minDate={new Date()}
            />
          </div>
          <Calendar id="calendar-date-end" />
        </Col>
      </Row>
    </>
  );
};

export default CreditGiftCreateUpdateForm;

import request from './api-service';
const qs = require('qs');

const rates = () =>
  request({
    method: 'get',
    url: '/management/rates',
  });

const billing = () =>
  request({
    method: 'get',
    url: '/management/billing',
  });

const referrals = (month, year) => {
  let params = { month, year };
  return request({
    method: 'get',
    url: '/management/referrals',
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    }, // ToDo why paramsSerializer need it?
  });
};

const ManagementService = {
  rates,
  billing,
  referrals,
};

export default ManagementService;

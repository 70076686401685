import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Form, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

import WhiteLabelsService from 'shared/white-labels-service';

const WhiteLabelSelector = ({ data, setData }) => {
  const [whitelabelTypeOption, setWhitelabelTypeOption] = useState('all');
  const [selectedOption, setSelectedOption] = useState(null);
  const [whiteLabels, setWhiteLabels] = useState([]);
  const { formatMessage } = useIntl();

  const whitelabelTypeOnChange = async (e) => {
    const { value } = e.target;
    setWhitelabelTypeOption(value);
    if (value === 'own-all') {
      setData({
        ...data,
        WHITELABELS: whiteLabels.filter((item) => item.affiliate === 1).map((_item) => _item.value),
      });
      setSelectedOption(whiteLabels.filter((item) => item.affiliate === 1));
    }

    if (value === 'own-anon') {
      setData({
        ...data,
        WHITELABELS: whiteLabels.filter((item) => item.affiliate === 1 && item.mode === 'ANONYMOUS').map((_item) => _item.value),
      });
      setSelectedOption(whiteLabels.filter((item) => item.affiliate === 1 && item.mode === 'ANONYMOUS'));
    }

    if (value === 'own-register') {
      setData({
        ...data,
        WHITELABELS: whiteLabels.filter((item) => item.affiliate === 1 && item.mode === 'REGISTERED').map((_item) => _item.value),
      });
      setSelectedOption(whiteLabels.filter((item) => item.affiliate === 1 && item.mode === 'REGISTERED'));
    }

    if (value === 'all') {
      setSelectedOption(null);
      setData({
        ...data,
        WHITELABELS: [],
      });
    }
  };

  const selectOnChange = (options) => {
    setData({
      ...data,
      WHITELABELS: options.map((_item) => _item.value),
    });
    setSelectedOption(options);
  };

  useEffect(() => {
    WhiteLabelsService.adminList({ page: 1, perPage: 9999 }).then((responseData) => {
      if (responseData.status === 'Accepted') {
        setWhiteLabels(
          responseData.message.data.map((item) => {
            return { value: item.ID, label: item.NAME, affiliate: item.AFFILIATE_ID, mode: item.MODE };
          })
        );
      }
    });
  }, []);

  useEffect(() => {
    if (data.WHITELABELS.length > 0) {
      setWhitelabelTypeOption('custom');
      setSelectedOption(whiteLabels.filter(({ value }) => data.WHITELABELS.includes(value)));
    }
  }, [whiteLabels]);

  return (
    <>
      <Form.Group as={Row} className="mb-3" controlId="formType">
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id={`tooltip-top`}>
              Selecciona las marcas blancas a las que aplicará la promoción. Si seleccionas "Sólo Propias" aplicará a las MBs del afiliado
              1. La opción "Personalizado" aplicará sólo a aquellas MBs que añadas en el campo inferior usando el buscador. Si no
              seleccionas ninguna se aplicará a todas{' '}
            </Tooltip>
          }>
          <Form.Label column sm="3" className="font-weight-bold">
            Marcas Blancas <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
          </Form.Label>
        </OverlayTrigger>
        <Col sm="9">
          <select className="form-control" value={whitelabelTypeOption} onChange={whitelabelTypeOnChange}>
            <option value="all">Todas</option>
            <option value="own-all">Sólo propias anónimo y registrado</option>
            <option value="own-anon">Sólo propias anónimo</option>
            <option value="own-register">Sólo propias registrado</option>
            <option value="custom">Personalizado</option>
          </select>
        </Col>
      </Form.Group>
      {whitelabelTypeOption !== 'all' && (
        <Form.Group as={Row} className="mb-3" controlId="formType">
          <Form.Label column sm="3" className="font-weight-bold"></Form.Label>
          <Col sm="9">
            <Select
              styles={{
                control: (styles) => ({
                  ...styles,
                  borderColor: '#ced4da',
                  color: '#495057',
                  fontWeight: '400',
                  fontSize: '1rem',
                }),
              }}
              placeholder="Selecciona ..."
              value={selectedOption}
              defaultValue={selectedOption}
              onChange={selectOnChange}
              options={whiteLabels}
              isMulti
              closeMenuOnSelect={false}
              isDisabled={['own-all', 'own-anon', 'own-register'].includes(whitelabelTypeOption)}
              noOptionsMessage={() => formatMessage({ id: 'promo-no-results-filters' })}
            />
          </Col>
        </Form.Group>
      )}
    </>
  );
};

export default WhiteLabelSelector;

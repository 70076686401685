const Mail = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="84" height="84" viewBox="0 0 84 84">
      <defs>
        <path
          id="maila"
          d="M77.182 8.09c0-4.2-3.437-7.635-7.637-7.635H8.455C4.255.455.818 3.89.818 8.09v45.818c0 4.2 3.437 7.636 7.637 7.636h61.09c4.2 0 7.637-3.436 7.637-7.636V8.091zm-7.637 0L39 27.183 8.455 8.09h61.09zm0 45.82H8.455V15.726L39 34.818l30.545-19.09v38.181z"
        />
      </defs>
      <g fill="none" fillRule="evenodd" opacity=".2" transform="translate(3 11)">
        <mask id="mailb" fill="#fff">
          <use xlinkHref="#maila" />
        </mask>
        <use fill="#000" fillRule="nonzero" xlinkHref="#maila" />
        <g fill="#FC531D" mask="url(#mailb)">
          <path d="M0 0H84V84H0z" transform="translate(-3 -11)" />
        </g>
      </g>
    </svg>
  );
};

export default Mail;

const FormatAfno = (props) => {
  const { afno, isAdmin } = props;
  let afnoSplit = afno.split('-');
  let result;
  let break_index = 50;

  if (isAdmin) {
    result = afno;
  } else {
    switch (afnoSplit[0]) {
      case '1':
      case '2':
        result = afno;
        break;
      case '3':
        result = afnoSplit[0] + '-' + afnoSplit[1] + '-' + afnoSplit[2];
        break;
      default:
        result = afno;
        break;
    }
  }

  if (result.length > break_index) {
    result = (
      <div>
        {result.substring(0, break_index)} <br /> {result.substring(break_index)}
      </div>
    );
  }
  return result;
};

export default FormatAfno;

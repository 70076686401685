import es from 'date-fns/locale/es';
import DatePicker, { registerLocale } from 'react-datepicker';
import { FormattedMessage } from 'react-intl';

//libs
import { getLang } from 'libs/cookies';

//components
import Button from 'components/button';
import Calendar from 'components/icons/calendar';

const Form = ({ date, handleChangeDate, getData }) => {
  registerLocale('es', es);
  return (
    <form className="form-content">
      <div className="form-detall-content vce">
        <div className="content-one">
          <div className="form-group calendar sp">
            <label>
              <FormattedMessage id="statistics-form-date" />
            </label>
            <div className="form-control">
              <DatePicker
                selected={date}
                onChange={(e) => handleChangeDate(e)}
                locale={getLang()}
                dateFormat="yyyy-MM"
                showMonthYearPicker
                showFullMonthYearPicker
                showTwoColumnMonthYearPicker
                id="calendar-vce"
                popperPlacement="bottom"
              />
            </div>
            <Calendar id="calendar-vce" />
          </div>
        </div>
        <div className="buttons-content">
          <Button rounded onClick={getData} align="center">
            <FormattedMessage id="global-calculate" />
          </Button>
        </div>
      </div>
    </form>
  );
};

export default Form;

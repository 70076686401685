import { FormattedMessage } from 'react-intl';

const Status = ({ item }) => {
  let result = 'global-active';

  if (item === 1) {
    result = 'global-blocked';
  }

  return <FormattedMessage id={result} />;
};

export default Status;

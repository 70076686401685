import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Col, Row, OverlayTrigger, Tooltip, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

import translate from 'i18n/translate';
import { getLang } from 'libs/cookies';

// Hooks
import useValidator from 'hooks/useValidator';

import Card from 'components/card';
import Button from 'components/button';

import IconData from 'components/icons/data';

const AddApp = ({ data, close, create, edit, handleOnChange, update }) => {
  const [validator, showValidationMessage] = useValidator({}, {}, getLang());
  const intl = useIntl();

  const createBtn = (e) => (validator.allValid() ? create() : showValidationMessage(true));
  const updateBtn = (e) => (validator.allValid() ? update() : showValidationMessage(true));
  let title = <FormattedMessage id={'global-new-app'} />;
  if (edit) {
    title = (
      <>
        <FormattedMessage id={'global-update-app'} /> {data.id}
      </>
    );
  }

  return (
    <div>
      <Card icon={<IconData />} title={title} modal closeModal={close}>
        <Form>
          <Row>
            <Col xl="6">
              {/*<Form.Group as={Row} className="mb-3 required" controlId="formUrlCorte">
                                <OverlayTrigger placement='top'
                                    overlay={<Tooltip id={`tooltip-top`}>{translate('global-broker-app-url-cut-explain')}</Tooltip>}
                                >
                                    <Form.Label column sm="3" className="font-weight-bold">
                                        <span className="text">{translate('global-url-cut')}</span>
                                        <span className="help-icon float-right"><FontAwesomeIcon icon={faQuestionCircle} className="mr-2" /></span>
                                    </Form.Label>
                                </OverlayTrigger>
                                
                                <Col sm="9">
                                    <InputGroup className="">
                                        <Form.Control
                                            className="w-auto f-left"
                                            value={data.corte}
                                            placeholder=""
                                            name="corte"
                                            onChange={handleOnChange}
                                        />
                                    </InputGroup>
                                    
                                    { validator.message(intl.formatMessage({id: 'global-url-cut'}).toLowerCase(), data.corte, 'max: 255|url') }
                                </Col>
                                
                            </Form.Group>*/}

              <Form.Group as={Row} className="mb-3 required" controlId="formUrlPago">
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id={`tooltip-top`}>{translate('global-broker-app-pay-url-explain')}</Tooltip>}>
                  <Form.Label column sm="3" className="font-weight-bold">
                    <span className="text">{translate('global-url-payment')}</span>
                    <span className="help-icon float-right">
                      <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
                    </span>
                  </Form.Label>
                </OverlayTrigger>

                <Col sm="9">
                  <InputGroup className="">
                    <Form.Control className="w-auto f-left" value={data.pago} placeholder="" name="pago" onChange={handleOnChange} />
                  </InputGroup>

                  {validator.message(intl.formatMessage({ id: 'global-url-payment' }).toLowerCase(), data.pago, 'max: 255|required|url')}
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3 required" controlId="formUrlCheckCreditsUser">
                <Form.Label column sm="3" className="font-weight-bold">
                  {translate('global-check-credit-user-url')}
                </Form.Label>
                <Col sm="9">
                  <InputGroup className="">
                    <Form.Control
                      className="w-auto f-left"
                      value={data.url_check_user_founds}
                      placeholder=""
                      name="url_check_user_founds"
                      onChange={handleOnChange}
                    />
                  </InputGroup>

                  {validator.message(
                    intl.formatMessage({ id: 'global-check-credit-user-url' }).toLowerCase(),
                    data.url_check_user_founds,
                    'max: 150|url'
                  )}
                </Col>
              </Form.Group>
            </Col>

            <Col xl="6">
              <Form.Group as={Row} className="mb-3 required" controlId="formEmail">
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id={`tooltip-top`}>{translate('global-broker-app-notif-email-explain')}</Tooltip>}>
                  <Form.Label column sm="3" className="font-weight-bold">
                    <span className="text">{translate('global-notification-email')}</span>
                    <span className="help-icon float-right">
                      <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
                    </span>
                  </Form.Label>
                </OverlayTrigger>

                <Col sm="9">
                  <InputGroup className="">
                    <Form.Control
                      className="w-auto f-left"
                      value={data.email_fallo}
                      placeholder=""
                      name="email_fallo"
                      onChange={handleOnChange}
                    />
                  </InputGroup>

                  {validator.message(
                    intl.formatMessage({ id: 'global-notification-email' }).toLowerCase(),
                    data.email_fallo,
                    'max: 100|email'
                  )}
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3 required" controlId="formServerIP">
                <Form.Label column sm="3" className="font-weight-bold">
                  {translate('global-server-ip')}
                </Form.Label>
                <Col sm="9">
                  <InputGroup className="">
                    <Form.Control className="w-auto f-left" value={data.ip} placeholder="" name="ip" onChange={handleOnChange} />
                  </InputGroup>

                  {validator.message(intl.formatMessage({ id: 'global-server-ip' }).toLowerCase(), data.ip, 'max: 20|ip')}
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3 required" controlId="formDomain">
                <Form.Label column sm="3" className="font-weight-bold">
                  {translate('global-domain')}
                </Form.Label>
                <Col sm="9">
                  <InputGroup className="">
                    <Form.Control className="w-auto f-left" value={data.dominio} placeholder="" name="dominio" onChange={handleOnChange} />
                  </InputGroup>

                  {validator.message(intl.formatMessage({ id: 'global-domain' }).toLowerCase(), data.dominio, 'max: 255|required|url')}
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              {edit ? (
                <Button rounded onClick={updateBtn}>
                  <FontAwesomeIcon icon={faSave} className="mr-2" /> {translate('global-update')}
                </Button>
              ) : (
                <Button rounded onClick={createBtn}>
                  <FontAwesomeIcon icon={faSave} className="mr-2" /> {translate('global-send')}
                </Button>
              )}
            </Col>
          </Row>
        </Form>
      </Card>
    </div>
  );
};

export default AddApp;

import { useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

//translate
import translate from 'i18n/translate';

import { useAuth } from 'contexts/auth';

import { PUBLIC } from 'shared/panel-links';

//libs
import { getLang } from 'libs/cookies';

//componentd
import LoginLayout from 'components/login-layout';
import IconDataOfAccess from 'components/icons/dataOfAcces';
import DevBugModal from 'components/devbug';
import Button from 'components/button';

//style
import './index.scss';
import UpdatePassword from './updatePassword';

import useValidator from 'hooks/useValidator';

const Login = ({ match }) => {
  const history = useHistory();
  const { login, logged, pwdExpired, setPwdExpired, token, blocked, notValidated, tooManyAttempts, error, setError, loading } = useAuth();

  const intl = useIntl();
  const [validator, showValidationMessage] = useValidator({}, {}, getLang());

  const [email, setEmail] = useState('');
  const [pwd, setPwd] = useState('');
  const [submit, setSubmit] = useState(false);
  const [devbug, setDevBug] = useState();

  useEffect(() => {
    //if (getAuth()) history.push('/estadisticas');
    if (logged) history.push('/estadisticas');
  }, [history, logged]);

  const loginBtn = (e) => {
    e.preventDefault();
    setSubmit(true);
    if (validator.allValid()) {
      login(email, pwd);
    } else showValidationMessage(true);
  };

  const pwdUpdated = () => {
    setPwdExpired(false);
    toast.success(translate('global-password-generated-success'));
  };

  const openChat = () => {
    var tidioScript = document.createElement('script');
    tidioScript.src = '//code.tidio.co/266vpgce72o5wg58jvjo1cpzuypwspgg.js';
    document.tidioChatLang = getLang();
    document.body.appendChild(tidioScript);
  };

  const handleOnChangeEmail = (e) => {
    const { value } = e.target;
    value === 'devbug' ? setDevBug(true) : setDevBug(false);

    setEmail(value);
    setError(false);
  };

  const closeModal = () => setDevBug(false);

  return (
    <LoginLayout>
      {devbug && <DevBugModal closeModal={closeModal} />}
      <div className="card-container">
        {!pwdExpired ? (
          <div className="card">
            <div className="card-header">
              <IconDataOfAccess />
              <h1>{translate('login-title')}</h1>
            </div>
            <div className="card-body">
              {error && (
                <div className="alert alert-danger" role="alert">
                  {translate('login-error')}
                </div>
              )}
              {blocked && (
                <div className="alert alert-danger blocked" role="alert">
                  <center>{translate('login-error-user-blocked')}</center>
                  {tooManyAttempts && <center>{translate('login-too-many-attempt')}</center>}
                  <a href="mailto:soporte@promocionesweb.com">{translate('login-contact-support')}</a>
                </div>
              )}
              {notValidated && (
                <div className="alert alert-danger blocked" role="alert">
                  {translate('login-error-user-not-validated')}
                  <a href="mailto:soporte@promocionesweb.com">{translate('login-contact-support')}</a>
                </div>
              )}
              <form onSubmit={loginBtn}>
                <div className="form-group">
                  <label>{translate('global-user-or-email')}</label>
                  <input
                    type="text"
                    name="email"
                    className={classNames('form-control', {
                      'form-input-success': submit && validator.check(email, 'required|min:5'),
                      'form-input-error': submit && !validator.check(email, 'required|min:5'),
                    })}
                    value={email}
                    onChange={handleOnChangeEmail}
                  />
                  {validator.message(intl.formatMessage({ id: 'global-user-or-email' }), email, 'required|min:5')}
                </div>
                <div className="form-group">
                  <label>{translate('login-password')}</label>
                  <input
                    type="password"
                    name="pwd"
                    className={classNames('form-control', {
                      'form-input-success': submit && validator.check(pwd, 'required'),
                      'form-input-error': submit && !validator.check(pwd, 'required'),
                    })}
                    onChange={(e) => setPwd(e.target.value)}
                    value={pwd}
                  />
                  {validator.message(intl.formatMessage({ id: 'login-password' }), pwd, 'required')}
                </div>
                <div className="recovery-remember-content">
                  <span>
                    {translate('login-rebember')}
                    <input type="checkbox" />
                  </span>
                  <NavLink className="recovery-password" to={PUBLIC.EMAIL_RECOVERY}>
                    {translate('login-recovery')}
                  </NavLink>
                </div>
                <div className="form-group button-content">
                  <Button rounded align="center" type="submit" disabled={loading}>
                    {loading ? <FontAwesomeIcon icon={faSpinner} spin transform="left-4" size="lg" /> : translate('login-button-enter')}
                  </Button>
                </div>
                <span className="chat-assistent" onClick={(e) => openChat()}>
                  {translate('login-chat-assistent')}
                </span>
              </form>
            </div>
          </div>
        ) : (
          <UpdatePassword pwdUpdated={pwdUpdated} token={token} />
        )}
      </div>
    </LoginLayout>
  );
};

export default Login;

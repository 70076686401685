import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

//actions
import BannerService from 'shared/banner-service';
import BannerCategoryService from 'shared/banner-category-service';

//libs
import { getLang } from 'libs/cookies';

// components
import Card from 'components/card';
import Button from 'components/button';
import Data from 'components/icons/data';

//style
import '../index.scss';

const Register = (props) => {
  const [data, setData] = useState({
    file: null,
    name: '',
    id_category: '0',
  });
  const [categories, setCategories] = useState([]);
  const [errorData, setErrorData] = useState(false);
  const [errorRequest, setErrorRequest] = useState(false);
  const [errorRequestExistName, setErrorRequestExistName] = useState(false);
  const [showPromoSpecial, setShowPromoSpecial] = useState(false);
  const [fileLabel, setFileLabel] = useState(<FormattedMessage id="herramientas-banner-input-file-select" />);

  const registerBanner = async () => {
    if (data.name === '' || data.file === null) {
      setErrorData(true);
    } else {
      BannerService.adminSet({ data }).then((_data) => {
        if (_data.status === 'Failed') {
          if (_data.message === 'set KO - Name Already in use') {
            setErrorRequestExistName(true);
            setErrorRequest(false);
          } else {
            setErrorRequestExistName(false);
            setErrorRequest(true);
          }
        } else props.close(true);
      });
    }
  };

  const promocionSpecial = () => {
    BannerCategoryService.adminList({ page: 1, perPage: 99, filters: [] }).then((response) => {
      if (response.status !== 'Failed') {
        setCategories(response.message.data);
        setShowPromoSpecial(true);
        setData({
          ...data,
          id_category: response.message.data[0].ID,
        });
      }
    });
  };

  const handleOnChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'file') {
      setData({ ...data, [name]: files[0] });
      setFileLabel(files[0].name);
    } else {
      if (value !== 'promo') setData({ ...data, [name]: value });
      else promocionSpecial();
    }
  };

  return (
    <div className="register-banner-modal">
      <Card icon={<Data />} title={<FormattedMessage id="herramientas-banner-name" />} modal closeModal={props.close}>
        <div className="banner-body">
          {errorData && (
            <div className="alert alert-danger" role="alert">
              <FormattedMessage id="herramientas-error-data" />
            </div>
          )}
          {errorRequest && (
            <div className="alert alert-danger" role="alert">
              <FormattedMessage id="herramientas-banner-error-request" />
            </div>
          )}
          {errorRequestExistName && (
            <div className="alert alert-danger" role="alert">
              <FormattedMessage id="herramientas-banner-error-request-exist-name" />
            </div>
          )}

          <form className="banner-modal">
            <div className="form-group">
              <label>
                <FormattedMessage id="herramientas-banner-input-name" />
              </label>
              <input type="text" className="form-control" name="name" value={data.name} onChange={handleOnChange} />
            </div>
            <div className="form-group">
              <label>
                <FormattedMessage id="herramientas-banner-input-type" />
              </label>
              <select name="id_category" className="form-control" onChange={handleOnChange}>
                <FormattedMessage id="promo-banner-banner-name">{(msg) => <option value="0">{msg}</option>}</FormattedMessage>
                <FormattedMessage id="promo-banner-especial-name">{(msg) => <option value="promo">{msg}</option>}</FormattedMessage>
              </select>
            </div>
            {showPromoSpecial && categories.length > 0 && (
              <div className="form-group">
                <label>
                  <FormattedMessage id="herramientas-banner-input-category" />
                </label>
                <select name="id_category" className="form-control" onChange={handleOnChange}>
                  {categories.map((category, index) => {
                    return (
                      <option key={index} value={category.ID}>
                        {getLang() === 'en' ? category.NAME_EN : category.NAME_ES}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}
            <div className="form-group">
              <label>
                <FormattedMessage id="herramientas-banner-input-file" />
              </label>
              <div className="custom-file">
                <input type="file" name="file" className="custom-file-input" id="customFileLang" lang="es" onChange={handleOnChange} />
                <label className="custom-file-label" htmlFor="customFileLang">
                  {fileLabel}
                </label>
              </div>
            </div>
          </form>
        </div>
        <div className="button-content">
          <Button rounded onClick={(e) => registerBanner()}>
            <FormattedMessage id="herramientas-banner-register-save" />
          </Button>
          <Button rounded onClick={(e) => props.close()} red>
            <FormattedMessage id="herramientas-banner-register-cancel" />
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default Register;

import { useEffect, useState, useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { Container, Col, Row, Table, Modal } from 'react-bootstrap';

import Layout from 'components/layout';
import Card from 'components/card';

import UserService from 'shared/user-service';
import { PUBLIC } from 'shared/panel-links';

import Button from 'components/button';

import SettingsIcon from 'components/icons/settings';
import DataIcon from 'components/icons/data';

const API = () => {
  const [data, setData] = useState({
    api_key: '',
  });
  const [loading, setLoading] = useState(false);
  const { formatMessage } = useIntl();

  const getData = useCallback(() => {
    setLoading(true);
    UserService.getProfile()
      .then(({ message }) => {
        // Hacer una copia de response.message.data excluyendo contact_info
        const { contact_info, ...dataWithoutContactInfo } = message.affiliate_data;
        setData(dataWithoutContactInfo);
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(getData, [getData]);

  const generate = async (reload = false) => {
    setLoading(true);
    await UserService.generateApiKey().then(({ message, status }) => {
      if (status !== 'Failed') {
        toast.success(formatMessage({ id: 'global-success-operation' }));
        setData({ ...data, api_key: message });
      } else toast.error(formatMessage({ id: 'herramientas-error-data' }));
      setLoading(false);
    });
  };

  return (
    <Layout>
      <Container>
        <Card icon={<DataIcon />} title={<FormattedMessage id="global-api_text" />} className="w-auto">
          <div className="explanation">
            <FormattedMessage id="global-api_explanation" />
            <br />
            <ul>
              <li>
                <FormattedMessage id="global-api_key_explanation" />
              </li>
              <li>
                <FormattedMessage id="global-api_token_explanation" />
              </li>
            </ul>
          </div>

          {data.api_key && (
            <div className="banner-body">
              <form className="banner-modal">
                <div className="form-group">
                  <label>
                    <FormattedMessage id="global-affiliate_id" />
                  </label>
                  <input type="text" className="form-control" name="id" value={data.id} disabled />
                </div>
                <div className="form-group">
                  <label>
                    <FormattedMessage id="global-api_key" />
                  </label>
                  <input type="text" className="form-control" name="api_key" value={data.api_key} disabled />
                </div>
              </form>
            </div>
          )}
          <div className="button-content">
            <Button rounded onClick={(e) => generate()} disabled={loading}>
              {loading ? (
                <FontAwesomeIcon icon={faSpinner} spin transform="left-4" size="lg" />
              ) : (
                <FormattedMessage id="global-generate_api_key" />
              )}
            </Button>
          </div>
          <hr />

          <div className="text-center font-weight-bold">
            <FormattedMessage id="global-affiliates-api-docs" />
          </div>

          <div className="button-content documentation">
            <NavLink
              exact={true}
              activeClassName="active"
              to="/docs/api_quick_start.pdf"
              rel="noreferrer noopener"
              target="_blank"
              className="button button--rounded button--min-width">
              <SettingsIcon /> <FormattedMessage id="global-api_quick_start_documentation" />
            </NavLink>
            <NavLink
              activeClassName="active"
              to={PUBLIC.API_DOCS}
              rel="noreferrer noopener"
              target="_blank"
              className="button button--rounded button--min-width">
              <SettingsIcon /> <FormattedMessage id="global-api_documentation" />
            </NavLink>
          </div>

          <hr />

          <div className="text-center font-weight-bold">
            <FormattedMessage id="global-content-api-docs" />
          </div>

          <div className="button-content documentation">
            <NavLink
              exact={true}
              activeClassName="active"
              to="/docs/vcp/vcp-api-quick-start.pdf"
              rel="noreferrer noopener"
              target="_blank"
              className="button button--rounded button--min-width">
              <SettingsIcon /> <FormattedMessage id="global-api_quick_start_documentation" />
            </NavLink>
            <NavLink
              exact={true}
              activeClassName="active"
              to={PUBLIC.VCP_API_DOCS}
              rel="noreferrer noopener"
              target="_blank"
              className="button button--rounded button--min-width">
              <SettingsIcon /> <FormattedMessage id="global-broker-see-api-documentation" />
            </NavLink>
          </div>
        </Card>
      </Container>
    </Layout>
  );
};

export default API;

import { Form } from 'react-bootstrap';

const Countries = ({ countries = [], setCountries = () => {} }) => {
  const handleCountriesOnChange = (item) => {
    setCountries(
      [...countries].map((country) => {
        if (country.value === item.value) country.checked = !country.checked;
        return country;
      })
    );
  };

  return (
    <>
      {countries &&
        Array.isArray(countries) &&
        countries.map((item, index) => {
          return (
            <Form.Check
              key={index}
              type="checkbox"
              inline
              label={item.label}
              name="groupCountries"
              id={item.value}
              onChange={(e) => handleCountriesOnChange(item)}
              checked={item.checked}
            />
          );
        })}
    </>
  );
};

export default Countries;

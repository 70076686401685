import { useIntl } from 'react-intl';

const SelectSitemode = ({ value, handleChange }) => {
  const { formatMessage } = useIntl();

  return (
    <div className="form-group">
      <label>Sitemode</label>
      <select className="form-control" name="sitemode" onChange={handleChange} defaultValue={value}>
        <option value="ALL">{formatMessage({ id: 'statistics-form-select-all' })}</option>
        <option value="REGISTERED">Registrado</option>
        <option value="ANONYMOUS">Anónimo</option>
      </select>
    </div>
  );
};

export default SelectSitemode;

import { Component } from 'react';
import PropTypes from 'prop-types';

import SwaggerUi, { presets } from 'swagger-ui';
import 'swagger-ui/dist/swagger-ui.css';
import './index.scss';

class SwaggerUI extends Component {
  componentDidMount() {
    SwaggerUi({
      dom_id: '#swaggerContainer',
      url: this.props.url,
      deepLinking: true,
      spec: this.props.spec,
      presets: [presets.apis],
      filter: true,
    });
  }

  render() {
    return <div id="swaggerContainer" />;
  }
}

SwaggerUI.propTypes = {
  url: PropTypes.string,
  spec: PropTypes.object,
};

SwaggerUI.defaultProps = {
  url: `https://devapi.oletraffic.com/docs`,
};

export default SwaggerUI;

import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Container, Col, Form, Row, Table } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faSpinner, faPlus, faTools, faEdit } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

import ArticlesService from 'shared/articles-service';

import Button from 'components/button';
import CustomCard from 'components/card';
import Pagination from 'components/pagination';
import IconData from 'components/icons/data';

import ModalAsign from './ModalAsign';

const AdminArticles = () => {
  const loading = useSelector((state) => state.loading.loading);
  const dispatch = useDispatch();

  const [showModalAsign, setShowModalAsign] = useState(false);
  const [id, setId] = useState(null);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const [lastPage, setLastPage] = useState(1);

  const [filters, setFilters] = useState({
    search: '',
  });
  const [response, setResponse] = useState([]);

  const handleOnChange = (e) => {
    const { name, value } = e.target;

    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const sendSubmit = (e) => {
    e.preventDefault();
    setResponse([]);
    setPage(1);
    dispatch({ type: 'SET_LOADING', loading: true });
    ArticlesService.adminList({ page: 1, perPage, filters })
      .then((responseData) => {
        responseData.status === 'Failed' && toast.error('No se pudo cargar la información');
        if (responseData.status === 'Accepted') {
          setResponse(responseData.message);
          setLastPage(responseData.message.last_page);
        }
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
  };

  useEffect(() => {
    setResponse([]);
    dispatch({ type: 'SET_LOADING', loading: true });
    ArticlesService.adminList({ page, perPage, filters })
      .then((responseData) => {
        responseData.status === 'Failed' && toast.error('No se pudo cargar la información');
        if (responseData.status === 'Accepted') {
          setResponse(responseData.message);
          setLastPage(responseData.message.last_page);
        }
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
  }, [page, perPage, dispatch]);

  /* PAGINATOR */
  const paginate = (e) => setPage(e.target.value);

  const handleChangeNumberOfColum = (e) => {
    setPerPage(e.target.value);
    setPage(1);
  };

  const nextPage = (_page) => {
    _page++;
    _page <= lastPage && setPage(_page);
  };

  const prevPage = (_page) => {
    _page--;
    _page > 0 && setPage(_page);
  };
  // End Paginator

  return (
    <Container className="mw-100 ha-discounts">
      <Row>
        <Col sm="12">
          <Card className="mb-2">
            <Card.Header>Editor de Artcículos </Card.Header>
            <Card.Body>
              <FormFilters filters={filters} handleOnChange={handleOnChange} sendSubmit={sendSubmit} />
              <br />
              {response && response.data && response.total > 0 ? (
                <>
                  <Row className="mb-4">
                    <Col>
                      <DataTables
                        data={response.data}
                        setData={setResponse}
                        filters={filters}
                        setShowModalAsign={setShowModalAsign}
                        setId={setId}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Pagination
                        handleChangeNumberOfColum={handleChangeNumberOfColum}
                        pager={{ page: page, items: perPage, pages: lastPage }}
                        paginate={paginate}
                        prevPage={prevPage}
                        nextPage={nextPage}
                        total={response.total}
                      />
                    </Col>
                  </Row>
                </>
              ) : (
                <>{!loading && <>No hay datos</>}</>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {showModalAsign && <ModalAsign id={id} show={showModalAsign} setShow={setShowModalAsign} />}
    </Container>
  );
};

const FormFilters = ({ filters, handleOnChange, sendSubmit }) => {
  const loading = useSelector((state) => state.loading.loading);
  const history = useHistory();
  return (
    <Row className="justify-content-between">
      <Col>
        <form className={`search-wrapper`} onSubmit={sendSubmit}>
          <div className="first-filters d-flex align-center">
            <Form.Group className="mr-3" controlId="formSearch">
              <Form.Label>Buscar por Id, nombre, etiqueta, descripción ...</Form.Label>
              <Form.Control value={filters.search} name="search" onChange={handleOnChange} />
            </Form.Group>
          </div>
          <div className="buttons-content">
            <Button rounded disabled={loading} type="submit">
              {loading ? (
                <FontAwesomeIcon icon={faSpinner} spin transform="left-4" size="lg" />
              ) : (
                <>
                  <FontAwesomeIcon icon={faSearch} className="mr-2" /> <FormattedMessage id="global-send" />
                </>
              )}
            </Button>
          </div>
        </form>
      </Col>

      <Col className="text-right align-self-center">
        <Button blue rounded className="mb-2" onClick={() => history.push('/admintools/panel-articulos/nuevo')}>
          <FontAwesomeIcon icon={faPlus} className="mr-2" />
          Crear nuevo artículo
        </Button>
        <br />
      </Col>
    </Row>
  );
};

const DataTables = ({ data, setShowModalAsign, setId }) => {
  const history = useHistory();

  const edit = (e, id) => {
    e.preventDefault();
    history.push(`/admintools/panel-articulos/${id}`);
  };
  return (
    <CustomCard icon={<IconData />} title="Artículos">
      <div className="table-content">
        <Table responsive="sm" striped bordered hover>
          <thead>
            <tr>
              <th>Id</th>
              <th>Articulo</th>
              <th>Etiqueta en log</th>
              <th>Unidad</th>
              <th>Descripción</th>
              <th>Precio PPR</th>
              <th>Precio PPA</th>
              <th>Precio PPL</th>
              <th>Modelo</th>
              <th>PVP</th>
              <th>Editar</th>
              <th>Asignar</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.id}</td>
                  <td>{item.articulo}</td>
                  <td>{item.tag_en_log}</td>
                  <td>{item.unidad}</td>
                  <td>{item.denominacion}</td>
                  <td>{item.precio_default}</td>
                  <td>{item.precio_ppa}</td>
                  <td>{item.precio_ppl}</td>
                  <td>{item.modelo}</td>
                  <td>{item.pvp}</td>
                  <td>
                    <Button onClick={(e) => edit(e, item.id)} className="mr-0">
                      <FontAwesomeIcon icon={faEdit} />
                    </Button>
                  </td>
                  <td>
                    <Button
                      onClick={() => {
                        setShowModalAsign(true);
                        setId(item.id);
                      }}
                      className="mr-0">
                      <FontAwesomeIcon icon={faTools} />
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </CustomCard>
  );
};

export default AdminArticles;

import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';

import Card from 'components/card';
import Button from 'components/button';
import Loading from 'components/loading';
import Data from 'components/icons/data';
import AffiliatesService from 'shared/affiliates-service';

const ModalRequest = ({ data, close }) => {
  let loading = useSelector((state) => state.loading.loading);
  const dispatch = useDispatch();
  const [requestsNumber, setRequestsNumber] = useState(0);

  useEffect(() => {
    AffiliatesService.adminAffiliateOcRequest(data.id)
      .then((responseData) => {
        setRequestsNumber(responseData.message);
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
  }, [dispatch, data.id]);

  const resetBtn = async () => {
    dispatch({ type: 'SET_LOADING', loading: true });
    await AffiliatesService.adminAffiliateResetOcRequest(data.id)
      .then((responseData) => {
        toast.success('Contador reseteado');
        close();
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
  };

  if (loading) return <Loading />;

  return (
    <div className="register-banner-modal">
      <Card icon={<Data />} title={'Peticiones a API OC de afiliado ' + data.empresa + ' ' + data.contacto} modal closeModal={close}>
        <Row className="w-100 text-center mb-3">
          <Col>
            <div>Número de peticiones</div>
          </Col>
          <Col>
            <div>{requestsNumber}</div>
          </Col>
        </Row>
        <Row className="w-100 text-center">
          <Col>
            <Button onClick={resetBtn} disabled={requestsNumber === 0 || loading}>
              Resetear contador
            </Button>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default ModalRequest;

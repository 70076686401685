import request from './api-service';
import { obj2FormData } from 'libs/methods';
const qs = require('qs');

const adminGet = (id) => {
  return request({
    method: 'get',
    url: `/admin/olecams/discounts/get?id=${id}`,
  });
};

const adminList = ({ page, perPage, filters }) => {
  let params = { filters };
  return request({
    method: 'get',
    url: `/admin/olecams/discounts/list?page=${page}&per_page=${perPage}`,
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });
};

const adminSet = (data) => {
  return request({
    method: 'post',
    url: '/admin/olecams/discounts/set',
    data: obj2FormData({ data: data }),
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

const adminBatch = (data, total_discounts) => {
  return request({
    method: 'post',
    url: '/admin/olecams/discounts/batch',
    data: obj2FormData({ total_discounts: total_discounts, data: data }),
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

const adminUpdate = (data) => {
  return request({
    method: 'put',
    url: '/admin/olecams/discounts/update',
    data: { data },
  });
};

const DiscountsService = {
  adminGet,
  adminSet,
  adminBatch,
  adminList,
  adminUpdate,
};

export default DiscountsService;

import { Alert, ButtonGroup, Button, Form, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

import translate from 'i18n/translate';

const ColorPickers = (props) => {
  return (
    <>
      <Alert variant="info" className="small text-center">
        {translate('promo-site-design-description')}
      </Alert>
      <Form.Group>
        <ButtonGroup aria-label="preselect-designs">
          <Button variant="warning" className="olecams-design" onClick={(e) => props.defaultDesigns('olecams')}>
            <FontAwesomeIcon icon={faEye} />
          </Button>
          <Button variant="info" onClick={(e) => props.defaultDesigns('design1')}>
            <FontAwesomeIcon icon={faEye} />
          </Button>
          <Button variant="dark" onClick={(e) => props.defaultDesigns('design2')}>
            <FontAwesomeIcon icon={faEye} />
          </Button>
          <Button className="btn-red" onClick={(e) => props.defaultDesigns('design3')}>
            <FontAwesomeIcon icon={faEye} />
          </Button>
          <Button variant="light" onClick={(e) => props.defaultDesigns('design4')}>
            <FontAwesomeIcon icon={faEye} />
          </Button>
        </ButtonGroup>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="formPageBgColor">
        <Form.Label column sm="6" className="font-weight-bold">
          {translate('promo-page-bg-color')}
        </Form.Label>
        <Col sm="6">
          <Form.Control
            type="color"
            //defaultValue={props.designForm.page_bg_color}
            name="page_bg_color"
            value={props.designForm.page_bg_color}
            title="Seleccione el color"
            onChange={(e) => props.debouncedOnChangeColor(e.nativeEvent.target.name, e.nativeEvent.target.value)}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3" controlId="formTextColor">
        <Form.Label column sm="6" className="font-weight-bold">
          {translate('promo-text-color')}
        </Form.Label>
        <Col sm="6">
          <Form.Control
            type="color"
            name="nick_color"
            //defaultValue={props.designForm.nick_color}
            value={props.designForm.nick_color}
            title="Seleccione el color"
            onChange={(e) => props.debouncedOnChangeColor(e.nativeEvent.target.name, e.nativeEvent.target.value)}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3" controlId="formButtonBgGradient">
        <Form.Label column sm="6" className="font-weight-bold">
          {translate('promo-nick-bg-color')}
        </Form.Label>
        <Col sm="6">
          <Form.Control
            type="color"
            name="nick_bg_color"
            //defaultValue={props.designForm.nick_bg_color}
            value={props.designForm.nick_bg_color}
            title="Seleccione el color"
            onChange={(e) => props.debouncedOnChangeColor(e.nativeEvent.target.name, e.nativeEvent.target.value)}
          />
        </Col>
      </Form.Group>

      {/*
        <Form.Group as={Row} className="mb-3" controlId="formBorderColor">
            <Form.Label column sm="6" className="font-weight-bold">{translate('promo-border-color')}</Form.Label>
            <Col sm="6">
                <Form.Control
                    type="color"
                    name="border_color"
                    defaultValue={border_color}
                    value={border_color}
                    title="Seleccione el color"
                    onChange={e => this.debouncedOnChangeColor(
                        e.nativeEvent.target.name,
                        e.nativeEvent.target.value
                    )}
                />
            </Col>
        </Form.Group>

        
            <Form.Group as={Row} className="mb-3" controlId="formBorder">
                <Form.Label column sm="6">Diseño del borde (opcional)</Form.Label>
                <Col sm="6">
                    <Form.Check
                        inline
                        type="radio"
                        name="groupBorde"
                        label="suavizado"
                        checked
                    />
                    <Form.Check
                        inline
                        type="radio"
                        name="groupBorde"
                        label="redondo"
                    />
                </Col>
                
            </Form.Group>
        */}

      <Form.Group as={Row} className="mb-3" controlId="formButtonTextColor">
        <Form.Label column sm="6" className="font-weight-bold">
          {translate('promo-button-text-color')}
        </Form.Label>
        <Col sm="6">
          <Form.Control
            type="color"
            name="button_text_color"
            //defaultValue={props.designForm.button_text_color}
            value={props.designForm.button_text_color}
            title="Seleccione el color"
            onChange={(e) => props.debouncedOnChangeColor(e.nativeEvent.target.name, e.nativeEvent.target.value)}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3" controlId="formButtonBgColor">
        <Form.Label column sm="6" className="font-weight-bold">
          {translate('promo-button-bg-color')}
        </Form.Label>
        <Col sm="6">
          <Form.Control
            type="color"
            name="button_bg_color"
            //defaultValue={props.designForm.button_bg_color}
            value={props.designForm.button_bg_color}
            title="Seleccione el color"
            onChange={(e) => props.debouncedOnChangeColor(e.nativeEvent.target.name, e.nativeEvent.target.value)}
          />
        </Col>
      </Form.Group>
    </>
  );
};

export default ColorPickers;

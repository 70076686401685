import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';

import { getLang } from 'libs/cookies';

import BannerCategoryService from 'shared/banner-category-service';

//Component
import Card from 'components/card';
import Search from 'components/icons/search';
import Button from 'components/button';
import Loading from 'components/loading';
import Pagination from 'components/pagination';
import Herramienta from 'components/icons/herramienta';
import Register from './register';
import Update from './update';

//style
import '../index.scss';

const Categories = (props) => {
  let loading = useSelector((state) => state.loading.loading);
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const [showAddCategory, setShowAddCategory] = useState();
  const [showUpdateCategory, setShowUpdateCategory] = useState();
  const [category, setCategory] = useState();
  const [filters, setFilters] = useState({ search: '' });
  const [data, setData] = useState([]);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const [lastPage, setLastPage] = useState(1);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    setData([]);
    dispatch({ type: 'SET_LOADING', loading: true });
    BannerCategoryService.adminList({ page, perPage, filters: { search: '' } })
      .then((responseData) => {
        responseData.status === 'Failed' && toast.error('No se pudo cargar la información');
        if (responseData.status === 'Accepted') {
          setTotal(responseData.message.total);
          setData(responseData.message.data);
          setLastPage(responseData.message.last_page);
        }
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
  }, [page, perPage, dispatch]);

  const getData = () => {
    setData([]);
    setPage(1);
    setTotal(0);
    dispatch({ type: 'SET_LOADING', loading: true });
    BannerCategoryService.adminList({ page: 1, perPage, filters })
      .then((response) => {
        response.status === 'Failed' && toast.error('No se pudo cargar la información');
        if (response.status === 'Accepted') {
          setTotal(response.message.total);
          setData(response.message.data);
          setLastPage(response.message.last_page);
        }
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
  };

  const sendSubmit = (e) => {
    e.preventDefault();
    getData();
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;

    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const showUpdate = async (id) => {
    dispatch({ type: 'SET_LOADING', loading: true });
    await BannerCategoryService.adminGet(id)
      .then((response) => {
        if (response.status !== 'Failed') setCategory(response.message.data);
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));

    setShowUpdateCategory(true);
  };

  const close = (reload = false) => {
    if (reload) getData();
    setShowAddCategory(false);
    setShowUpdateCategory(false);
  };

  const remove = (id) => {
    if (window.confirm(formatMessage({ id: 'herramientas-confirm-alert' }))) {
      BannerCategoryService.adminDelete(id).then((data) => {
        if (data.status !== 'Failed') getData();
        else if (data.message.toLowerCase().includes('category has banners'))
          toast.error(<FormattedMessage id="herramientas-category-delete-error" />);
      });
    }
  };

  /* PAGINATOR */
  const paginate = (e) => setPage(e.target.value);

  const handleChangeNumberOfColum = (e) => {
    setPerPage(e.target.value);
    setPage(1);
  };

  const nextPage = (_page) => {
    _page++;
    _page <= lastPage && setPage(_page);
  };

  const prevPage = (_page) => {
    _page--;
    _page > 0 && setPage(_page);
  };
  // END PAGINATOR

  if (loading) return <Loading />;
  return (
    <>
      <div className="herramientas-administrativas-content">
        {!showAddCategory && !showUpdateCategory && (
          <>
            <Card icon={<Herramienta color="#4b5056" />} title={<FormattedMessage id="herramientas-category-name" />}>
              <div className="banner-header-content">
                <form className={`search-wrapper ${props.class}`} onSubmit={sendSubmit}>
                  <div className="form-group input-content">
                    <input type="text" className="form-control" name="search" value={filters.search} onChange={handleOnChange} />
                    <Search search={sendSubmit} />
                  </div>
                </form>
                <Button superBlue onClick={(e) => setShowAddCategory(true)}>
                  <FormattedMessage id="herramientas-banner-button-add" />
                </Button>
              </div>
            </Card>
            {data && (
              <div className="banner-table-content">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">
                        <FormattedMessage id="global-id" />
                      </th>
                      <th scope="col">
                        <FormattedMessage id="global-name" />
                      </th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length > 0 && (
                      <>
                        {data.map((item, index) => {
                          return (
                            <tr key={index} className={(index + 1) % 2 ? 'impar' : 'par'}>
                              <td>{item.ID}</td>
                              <td>{getLang() === 'en' ? item.NAME_EN : item.NAME_ES}</td>
                              <td>
                                <div className="button-content">
                                  <Button blue onClick={(e) => showUpdate(item.ID)}>
                                    <FormattedMessage id="herramientas-banner-button-update" />
                                  </Button>
                                  <Button red onClick={(e) => remove(item.ID)}>
                                    <FormattedMessage id="herramientas-banner-button-remove" />
                                  </Button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            )}
            <Pagination
              handleChangeNumberOfColum={handleChangeNumberOfColum}
              pager={{ page: page, items: perPage, pages: lastPage }}
              paginate={paginate}
              prevPage={prevPage}
              nextPage={nextPage}
              total={total}
            />
          </>
        )}
        {showAddCategory && <Register close={close} />}
        {showUpdateCategory && <Update category={category} close={close} />}
      </div>
    </>
  );
};

export default Categories;

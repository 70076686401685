import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { Container, Col, Row, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft, faSave } from '@fortawesome/free-solid-svg-icons';

import ArticlesService from 'shared/articles-service';

import Button from 'components/button';
import { Article, TagLog, Unity, Description, PprPrice, PpaPrice, PplPrice, PvpPrice, ComisionModel } from 'components/Articles/Form';

const EditArticle = ({ id }) => {
  const { formatMessage } = useIntl();
  const history = useHistory();

  return (
    <Container className="mw-100 ha-discounts-new">
      <Row>
        <Col sm="12" className="mb-2">
          <Button rounded onClick={(e) => history.push('/admintools/panel-articulos')}>
            <FontAwesomeIcon icon={faArrowCircleLeft} className="mr-2" />
            {formatMessage({ id: 'statistics-form-button-return' })}
          </Button>
        </Col>
        <Col sm="12">
          <Card className="mb-2">
            <Card.Header>Editar artículo - {id}</Card.Header>
            <Card.Body>
              <FormArticle id={id} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

const FormArticle = ({ id }) => {
  const { formatMessage } = useIntl();
  const loading = useSelector((state) => state.loading.loading);
  const history = useHistory();
  const dispatch = useDispatch();

  const [data, setData] = useState({
    id: null,
    articulo: '',
    tag_en_log: '',
    unidad: 'u',
    denominacion: '',
    precio_default: 0.0,
    precio_ppa: 0.0,
    precio_ppl: 0.0,
    pvp: 0.0,
    modelo: 'PPR',
  });

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  useEffect(() => {
    dispatch({ type: 'SET_LOADING', loading: true });
    ArticlesService.adminGet(id)
      .then((data) => {
        data.status === 'Accepted' && setData(data.message);
        if (data.status === 'Failed') {
          toast.error('El articulo que intentas editar no existe');
          history.push('/admintools/panel-articulos');
        }
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
  }, [id, dispatch, history]);

  const submit = (e) => {
    e.preventDefault();
    // data convert number to decimals with .
    dispatch({ type: 'SET_LOADING', loading: true });
    ArticlesService.adminUpdate(data)
      .then((responseData) => {
        responseData.status === 'Failed' && toast.error(formatMessage({ id: 'global-error-try-later' }));
        if (responseData.status === 'Accepted') {
          toast.success(formatMessage({ id: 'global-success-operation' }));
          history.push('/admintools/panel-articulos');
        }
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
  };

  return (
    <form onSubmit={submit}>
      <Row>
        <Col xl="6">
          <Article label="Artículo" value={data.articulo} onChange={handleOnChange} />
        </Col>
        <Col xl="6">
          <TagLog label="Etiqueta en log" value={data.tag_en_log} onChange={handleOnChange} />
        </Col>
      </Row>
      <Row>
        <Col xl="6">
          <Unity label="Unidad" value={data.unidad} onChange={handleOnChange} />
        </Col>

        <Col xl="6">
          <Description label="Descripción" value={data.denominacion} onChange={handleOnChange} />
        </Col>
      </Row>
      <Row>
        <Col xl="6">
          <PprPrice label="PPR (pay per revenue)" value={data.precio_default} onChange={handleOnChange} />
        </Col>

        <Col xl="6">
          <PpaPrice label="PPA (pay per action)" value={data.precio_ppa} onChange={handleOnChange} />
        </Col>
      </Row>
      <Row>
        <Col xl="6">
          <PplPrice label="PPL (pay per lead)" value={data.precio_ppl} onChange={handleOnChange} />
        </Col>

        <Col xl="6">
          <PvpPrice label="PVP" value={data.pvp} onChange={handleOnChange} />
        </Col>
      </Row>
      <Row>
        <Col xl="6">
          <ComisionModel label="Modelo de comisión" value={data.modelo} onChange={handleOnChange} />
        </Col>
      </Row>
      <hr />
      <Row>
        <Col className="text-center">
          <Button rounded variant="primary" type="submit" disabled={loading}>
            <FontAwesomeIcon icon={faSave} className="mr-2" />
            <span>{formatMessage({ id: 'administration-data-button' })}</span>
          </Button>
        </Col>
      </Row>
    </form>
  );
};

export default EditArticle;

import request from './api-service';
import requestOCV2 from './api-oc-v2-service';

const qs = require('qs');

const prefixUrl = '/brokers';
const prefixAdminUrl = '/admin';

const getBrokerMB = () => {
  return request({
    method: 'get',
    url: '/brokers/app',
  });
};

const getBrokerData = () => {
  return request({
    method: 'get',
    url: 'brokers/data',
  });
};

const updateBrokerData = (data) => {
  return request({
    method: 'put',
    url: 'brokers/update_profile',
    data: { data },
  });
};

const setMB = (data) => {
  return request({
    method: 'post',
    url: 'brokers/set_mb',
    data: { data },
  });
};

const updateMB = (data) => {
  return request({
    method: 'put',
    url: 'brokers/update_mb',
    data: { data },
  });
};

const statsSummary = (filters) => {
  let params = { filters };
  return request({
    method: 'get',
    url: '/brokers/summarystats',
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });
};

const statsMinutes = (filters) => {
  let params = { filters };
  return request({
    method: 'get',
    url: '/brokers/statsminutes',
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });
};

const statsSMS = (filters) => {
  let params = { filters };
  return request({
    method: 'get',
    url: '/brokers/statssms',
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });
};

const statsADSL = (filters) => {
  let params = { filters };
  return request({
    method: 'get',
    url: '/brokers/statsadsl',
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });
};

const statsTips = (filters) => {
  let params = { filters };
  return request({
    method: 'get',
    url: '/brokers/statstips',
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });
};

const statsExtras = (filters) => {
  let params = { filters };
  return request({
    method: 'get',
    url: '/brokers/statsextras',
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });
};

const adminGet = (id) => {
  return request({
    method: 'get',
    url: `${prefixAdminUrl}${prefixUrl}/get?id=${id}`,
  });
};

const adminList = ({ page, perPage, filters }) => {
  let params = { filters };

  return request({
    method: 'get',
    url: `${prefixAdminUrl}${prefixUrl}/list?page=${page}&per_page=${perPage}`,
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: 'arrayFormat' });
    },
  });
};

const adminSet = (data) => {
  return request({
    method: 'post',
    url: `${prefixAdminUrl}${prefixUrl}/set`,
    data: { data },
  });
};

const adminUpdate = (data) => {
  return request({
    method: 'put',
    url: `${prefixAdminUrl}${prefixUrl}/update`,
    data: { data },
  });
};

const adminStatsSummary = (filters) => {
  let params = { filters };
  return request({
    method: 'get',
    url: '/admin/brokers/summary',
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });
};

const adminStatsMinutes = (filters) => {
  let params = { filters };
  return request({
    method: 'get',
    url: '/admin/brokers/statsminutes',
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });
};

const adminStatsSMS = (filters) => {
  let params = { filters };
  return request({
    method: 'get',
    url: '/admin/brokers/statssms',
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });
};

const adminStatsADSL = (filters) => {
  let params = { filters };
  return request({
    method: 'get',
    url: '/admin/brokers/statsadsl',
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });
};

const adminStatsTips = (filters) => {
  let params = { filters };
  return request({
    method: 'get',
    url: '/admin/brokers/statstips',
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });
};

const adminStatsExtras = (filters) => {
  let params = { filters };
  return request({
    method: 'get',
    url: '/admin/brokers/statsextras',
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });
};

const adminGetBrokerMB = (broker_id) => {
  return request({
    method: 'get',
    url: `/admin/brokers/mb?broker_id=${broker_id}`,
  });
};

const adminResetRequests = (id) => {
  return requestOCV2({
    method: 'DELETE',
    url: `/brokerrequestcounter/${id}`,
  });
};

const BrokerService = {
  getBrokerMB,
  getBrokerData,
  updateBrokerData,
  setMB,
  updateMB,
  statsSummary,
  statsMinutes,
  statsSMS,
  statsADSL,
  statsTips,
  statsExtras,
  adminGet,
  adminList,
  adminSet,
  adminUpdate,
  adminStatsSummary,
  adminStatsMinutes,
  adminStatsSMS,
  adminStatsADSL,
  adminStatsTips,
  adminStatsExtras,
  adminGetBrokerMB,
  adminResetRequests,
};

export default BrokerService;

import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Accordion, Button, Card, Col, Row } from 'react-bootstrap';

import { WHITELABELS } from 'shared/constants';

import SupportIcon from 'components/icons/sopport';
import NewMetatagItem from 'components/whiteLabels/NewMetatagItem';
import FilterItems from 'components/whiteLabels/FilterItems';

const NewMetatagSection = ({ sitemode, metatagsItems, setMetatagsItems }) => {
  const { formatMessage } = useIntl();
  const [selectedSection, setSelectedSection] = useState('home');
  const [selectedFilter, setSelectedFilter] = useState('');

  return (
    <Row className="mb-3">
      <Col>
        <Accordion className="metatags-accordion">
          <Card className="mb-2">
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" eventKey="0">
                Metatags
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <Row className="mb-2">
                  <Col sm="12" className="text-center">
                    <NavLink target="_blank" to="/soporte" role="button" className="underlined">
                      <FormattedMessage id="herramientas-white-label-problem-creating-support" /> <SupportIcon color="#fc531d" />
                    </NavLink>
                  </Col>
                </Row>
                <hr />

                <Row>
                  <Col sm={3}>
                    <label>
                      <FormattedMessage id="global-section" />
                      <select
                        className="form-control"
                        defaultChecked={selectedSection}
                        onChange={(e) => {
                          setSelectedSection(e.target.value);
                          setSelectedFilter('');
                        }}>
                        <option value="home">{formatMessage({ id: 'global-home' })}</option>
                        <option value="girls">{formatMessage({ id: 'global-girls' })}</option>
                        <option value="trans">{formatMessage({ id: 'global-trans' })}</option>
                        <option value="couples">{formatMessage({ id: 'global-couples' })}</option>
                        <option value="room">{formatMessage({ id: 'global-room' })}</option>
                        <option value="webcam">Webcam</option>
                        <option value="profile">{formatMessage({ id: 'global-profile' })}</option>
                      </select>
                    </label>
                    <br />
                    {sitemode === WHITELABELS.MODES.REGISTERED && (
                      <>
                        {selectedSection === 'girls' && (
                          <FilterItems segmentId={1} selectedFilter={selectedFilter} setSelectedFilter={setSelectedFilter} />
                        )}
                        {selectedSection === 'trans' && (
                          <FilterItems segmentId={2} selectedFilter={selectedFilter} setSelectedFilter={setSelectedFilter} />
                        )}
                        {selectedSection === 'couples' && (
                          <FilterItems segmentId={4} selectedFilter={selectedFilter} setSelectedFilter={setSelectedFilter} />
                        )}
                      </>
                    )}
                  </Col>
                  <Col sm={9}>
                    <NewMetatagItem
                      section={selectedSection}
                      filter={selectedFilter}
                      metatagsItems={metatagsItems}
                      setMetatagsItems={setMetatagsItems}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </Col>
    </Row>
  );
};

export default NewMetatagSection;

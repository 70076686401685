import { Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router';

import es from 'date-fns/locale/es';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faSearch } from '@fortawesome/free-solid-svg-icons';

import DatePicker, { registerLocale } from 'react-datepicker';

// Libs
import { getLang } from 'libs/cookies';

import Button from 'components/button';
import IconCalendar from 'components/icons/calendar';

const BrokersForm = ({ filters, handleOnChange, getStats, brokers, handleChangeDate, handleOnChangeStatsType, brokerMB, stats_type }) => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  registerLocale('es', es);

  const type_stat_select = [
    { title: 'global-803', value: 'adsl' },
    //{ title: 'global-sms', value: 'sms' },
    { title: 'global-broker-minutes-v1', value: 'vce_minutes' },
    { title: 'statistics-table-credit', value: 'vcp_minutes' },
    //{ title: 'statistics-oc-credits-test-room', value: 'oc_credits_test' },
    { title: 'statistics-tips', value: 'tips' },
    //{ title: 'statistics-tips-test', value: 'oc_tips_test' }
    { title: 'administration-billing-import-extra', value: 'extras' },
  ];

  //const test_study_select = [ { title: 'no', value: 0 }, { title: 'si', value: 1 } ];

  return (
    <form className={`search-wrapper`}>
      <div className="first-filters d-flex align-center">
        <div className="form-group mr-3">
          <label>
            <FormattedMessage id="statistics-form-select-rang-vce" />
          </label>
          <select className="form-control" name="view" onChange={handleOnChange} defaultValue={filters.view}>
            <option key="month" value="month">
              {formatMessage({ id: 'statistics-form-select-month' })}
            </option>
            <option key="day" value="day">
              {formatMessage({ id: 'statistics-form-select-day' })}
            </option>
          </select>
        </div>
        <div className="form-group mr-3 calendar sp">
          <label>
            <FormattedMessage id="statistics-form-date" />
          </label>
          <div className="form-control">
            {filters.view === 'month' ? (
              <DatePicker
                selected={filters.date}
                onChange={(date) => handleChangeDate(date)}
                locale={getLang()}
                dateFormat="MM/yyyy"
                showMonthYearPicker
                showFullMonthYearPicker
                showTwoColumnMonthYearPicker
                id="calendar-vce"
                popperPlacement="bottom"
                maxDate={new Date()}
                minDate={new Date('2018/11/01')}
              />
            ) : (
              <DatePicker
                selected={filters.date}
                onChange={(date) => handleChangeDate(date)}
                locale={getLang()}
                dateFormat="dd/MM/yyyy"
                id="calendar-vce"
                popperPlacement="bottom"
                maxDate={new Date()}
                minDate={new Date('2018/11/01')}
              />
            )}
          </div>
          <IconCalendar id="calendar-vce" />
        </div>

        <div className="form-group mr-3">
          <label>
            <FormattedMessage id="global-type" />
          </label>
          <select className="form-control" onChange={handleOnChangeStatsType} name="stats_type" defaultValue={stats_type}>
            {type_stat_select.map((option, index) => {
              return (
                <option key={index} value={option.value}>
                  {formatMessage({ id: option.title })}
                </option>
              );
            })}
          </select>
        </div>

        {/*<div className="form-group mr-3">
                    <label>Marca Blanca</label>
                    <select className="form-control" onChange={handleOnChange} name="id_mb">
                        <FormattedMessage id="statistics-form-select-all"> {(msg) => <option value="">{msg}</option> } </FormattedMessage>
                        <option value="">Marca Blanca 1</option>
                    </select>
                </div>
                    */}

        {brokerMB.length > 0 && stats_type !== 'adsl' && (
          <div className="form-group mr-3">
            <label>App</label>
            <select className="form-control" onChange={handleOnChange} name="id_mb" defaultValue={filters.id_mb}>
              <FormattedMessage id="statistics-form-select-all">{(msg) => <option value="">{msg}</option>}</FormattedMessage>
              {brokerMB.map((mb, index) => {
                return (
                  <option key={index} value={mb.id}>
                    {mb.id + ' - ' + mb.dominio}
                  </option>
                );
              })}
            </select>
          </div>
        )}

        {(stats_type === 'vcp_minutes' || stats_type === 'tips' || stats_type === 'extras') && (
          <div className="form-group mr-3">
            <label htmlFor="estudio_pruebas">
              <FormattedMessage id="global-test-studio" />
            </label>
            <Form.Check
              type="checkbox"
              name="estudio_pruebas"
              id="estudio_pruebas"
              //onChange={e => this.props.handleRadioOnChange(item, 'content')}
              checked={filters.estudio_pruebas}
              onChange={handleOnChange}
            />
          </div>
        )}
      </div>
      <div className="buttons-content">
        <Button rounded onClick={(e) => history.goBack()} align="center" className="left mr-2">
          <FontAwesomeIcon icon={faArrowLeft} className="mr-2" /> <FormattedMessage id="statistics-form-button-return" />
        </Button>
        <Button rounded onClick={getStats}>
          <FontAwesomeIcon icon={faSearch} className="mr-2" /> <FormattedMessage id="global-send" />
        </Button>
      </div>
    </form>
  );
};

export default BrokersForm;

import { Col, Row, Form, InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';

import { validateURL } from 'libs/methods';

const ConfigForm = ({ validator, white_labels, configForm, handleOnChange }) => {
  const { formatMessage } = useIntl();

  const { campaign, domain, afno, prefix_afno, access_url, whitelabel_url } = configForm;
  return (
    <Form>
      <Row>
        <Col md="6">
          <Form.Group as={Row} className="mb-3" controlId="formCampaign">
            <Form.Label column sm="3" className="font-weight-bold">
              <FormattedMessage id="promo-campaign-name" />
            </Form.Label>
            <Col sm="9">
              <Form.Control
                value={campaign}
                name="campaign"
                placeholder="Nombre de campaña"
                onChange={(e) => handleOnChange(e, 'configForm')}
              />
              {validator.message(formatMessage({ id: 'promo-campaign-name' }), campaign, 'min: 2|max: 255|required')}
            </Col>
          </Form.Group>
        </Col>
        {/* WHITE LABEL */}
        <Col md="6">
          <Form.Group as={Row} className="mb-3" controlId="formWhiteLabelURL">
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-disabled">
                  <FormattedMessage id="promo-roomlist-destination-url-description" />
                </Tooltip>
              }>
              <Form.Label column sm="3" className="font-weight-bold">
                <FormattedMessage id="promo-roomlist-access-url" />
              </Form.Label>
            </OverlayTrigger>
            <Col sm="9">
              <Form.Control
                as="select"
                custom
                name="whitelabel_url"
                onChange={(e) => handleOnChange(e, 'configForm')}
                value={whitelabel_url}>
                <option value={validateURL('www.olecams.com')}>Olecams.com - www.olecams.com</option>
                {white_labels &&
                  white_labels.length > 0 &&
                  white_labels.map((white_label, index) => {
                    return (
                      <option key={index} value={validateURL(white_label.DOMAINS.find((item) => item.MAIN === 1).URL)}>
                        {white_label.NAME + ' - ' + white_label.DOMAINS.find((item) => item.MAIN === 1).URL}
                      </option>
                    );
                  })}
              </Form.Control>
            </Col>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <Form.Group as={Row} className="mb-3" controlId="formDominio">
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-disabled">
                  <FormattedMessage id="promo-roomlist-config-domain-description" />
                </Tooltip>
              }>
              <Form.Label column sm="3" className="font-weight-bold">
                <FormattedMessage id="global-domain" />
              </Form.Label>
            </OverlayTrigger>
            <Col sm="9">
              <Form.Control
                value={domain}
                name="domain"
                placeholder="http://midominio.com"
                onChange={(e) => handleOnChange(e, 'configForm')}
              />
              {validator.message(formatMessage({ id: 'global-domain' }), domain, 'min: 2|max: 255|required|url')}
            </Col>
          </Form.Group>
        </Col>
        <Col md="6">
          <Form.Group as={Row} className="mb-3" controlId="formAccessURL">
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-disabled">
                  {' '}
                  <FormattedMessage id="promo-roomlist-destination-type-url-description" />
                </Tooltip>
              }>
              <Form.Label column sm="3" className="font-weight-bold">
                <FormattedMessage id="promo-roomlist-type-access-url" />
              </Form.Label>
            </OverlayTrigger>
            <Col sm="9">
              <Form.Control as="select" custom name="access_url" onChange={(e) => handleOnChange(e, 'configForm')} value={access_url}>
                <option value="home">Home</option>
                <option value="model">{formatMessage({ id: 'global-model' })}</option>
              </Form.Control>
            </Col>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <Form.Group as={Row} className="mb-3" controlId="formAfno">
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-disabled">
                  <FormattedMessage id="promo-afno-description" />
                </Tooltip>
              }>
              <Form.Label column sm="3" className="font-weight-bold">
                <FormattedMessage id="global-campaign-id" />
              </Form.Label>
            </OverlayTrigger>

            <Col sm="9">
              <InputGroup className="">
                <InputGroup.Text>{prefix_afno}</InputGroup.Text>
                <Form.Control
                  className="w-auto f-left"
                  value={afno}
                  placeholder=""
                  name="afno"
                  onChange={(e) => handleOnChange(e, 'configForm')}
                />
              </InputGroup>

              {validator.message(formatMessage({ id: 'global-campaign-id' }), afno, 'min: 2|max: 150|required')}
            </Col>
          </Form.Group>
        </Col>
      </Row>
    </Form>
  );
};

export default ConfigForm;

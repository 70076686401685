import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import VceStatsService from 'shared/vce-stats-service';
import AffiliatesService from 'shared/affiliates-service';

//translate
import translate from 'i18n/translate';

//libs
import { dateFormat } from 'libs/methods';
import { isEmpty } from 'libs/lodashAlt';

//Component
import Card from 'components/card';
import Loading from 'components/loading';
import Layout from 'components/layout';
import Pagination from 'components/pagination';

import Form from './form';
import Table from './table';
import TableVisa from './tableVisa';
import TableAdsl from './tableAdsl';
import TableSms from './tableSms';
import TableMobileint from './tableMobileint';

import IconStatsOC from 'components/icons/statsOlecams';

// style
import '../statsOlecams/index.scss';

const StatsVce = () => {
  const loading = useSelector((state) => state.loading.loading);
  const stats = useSelector((state) => state.stats.statsVce);
  const summaryVce = useSelector((state) => state.stats.summaryVce);
  const dispatch = useDispatch();

  const [filters, setFilters] = useState({
    date: dateFormat(new Date()),
    view: 'month',
    login: '',
    logingroup: '',
    affiliate_id: '',
    pager: {
      page: '1',
      items: '50',
    },
  });
  const [logins, setLogins] = useState([]);
  const [loginsGroups, setLoginsGroups] = useState([]);
  const [rang, setRang] = useState('month');
  const [disabledGroup, setDisabledGroup] = useState(false);
  const [disabledLogin, setDisabledLogin] = useState(false);
  const [paymentType, setPaymentType] = useState('all');

  const [date, setDate] = useState(new Date());

  const getData = useCallback(async () => {
    let _filters = {
      date: dateFormat(new Date()),
      view: 'month',
      login: '',
      logingroup: '',
      affiliate_id: '',
      pager: {
        page: '1',
        items: '50',
      },
    };

    dispatch({ type: 'SET_LOADING', loading: true });
    VceStatsService.getStatsSummary(_filters)
      .then((responseData) => {
        if (responseData.status === 'Accepted') {
          dispatch({ type: 'SET_STATS_VCE_SUMMARY', summaryVce: responseData.message });
        }
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));

    await AffiliatesService.getLogins(_filters.affiliate_id).then((responseData) => {
      if (responseData.status !== 'Failed') setLogins(responseData.message);
    });

    await AffiliatesService.getLoginsGroups(_filters.affiliate_id).then((responseData) => {
      if (responseData.status !== 'Failed') setLoginsGroups(responseData.message);
    });

    /* await getLogins(_filters.affiliate_id, auth.token).then((res) => {
      if (res.data.status !== 'Failed') setLogins(res.data.message);
    }); */

    /* await getLoginsGroups(_filters.affiliate_id, auth.token).then((res) => {
      if (res.data.status !== 'Failed') setLoginsGroups(res.data.message);
    }); */

    dispatch({ type: 'SET_LOADING', loading: true });
    VceStatsService.getStats(_filters)
      .then((responseData) => {
        if (responseData.status === 'Accepted') dispatch({ type: 'SET_STATS', statsVce: responseData.message });
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
  }, [dispatch]);

  useEffect(() => {
    getData();
  }, [getData]);

  const getStatsAll = (_filters) => {
    dispatch({ type: 'SET_LOADING', loading: true });
    VceStatsService.getStats(_filters)
      .then((responseData) => {
        if (responseData.status === 'Accepted') dispatch({ type: 'SET_STATS', statsVce: responseData.message });
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
  };

  const getStatsADSL = (_filters) => {
    dispatch({ type: 'SET_LOADING', loading: true });
    VceStatsService.getStatsADSL(_filters)
      .then((responseData) => {
        if (responseData.status === 'Accepted') dispatch({ type: 'SET_STATS', statsVce: responseData.message });
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
  };

  const getStatsVISA = (_filters) => {
    dispatch({ type: 'SET_LOADING', loading: true });
    VceStatsService.getStatsVISA(_filters)
      .then((responseData) => {
        if (responseData.status === 'Accepted') dispatch({ type: 'SET_STATS', statsVce: responseData.message });
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
  };

  const getStatsSMS = (_filters) => {
    dispatch({ type: 'SET_LOADING', loading: true });
    VceStatsService.getStatsSMS(_filters)
      .then((responseData) => {
        if (responseData.status === 'Accepted') dispatch({ type: 'SET_STATS', statsVce: responseData.message });
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
  };

  const getStatsMobileInt = (_filters) => {
    dispatch({ type: 'SET_LOADING', loading: true });
    VceStatsService.getStatsMobileInt(_filters)
      .then((responseData) => {
        if (responseData.status === 'Accepted') dispatch({ type: 'SET_STATS', statsVce: responseData.message });
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
  };

  const getStats = (localFilters = filters) => {
    let _filters = {
      ...localFilters,
      view: rang === filters.view ? filters.view : rang,
    };
    setFilters(_filters);

    if (paymentType === 'all') getStatsAll(_filters);
    if (paymentType === 'adsl') getStatsADSL(_filters);
    if (paymentType === 'visa') getStatsVISA(_filters);
    if (paymentType === 'sms') getStatsSMS(_filters);
    if (paymentType === 'mobileint') getStatsMobileInt(_filters);
  };

  const getStatsDay = (date) => {
    //e.preventDefault();
    let _filters = filters;
    setFilters({
      ...filters,
      view: 'day',
      date: date,
    });
    _filters.view = 'day';
    _filters.date = date;

    if (paymentType === 'all') getStatsAll(_filters);
    if (paymentType === 'adsl') getStatsADSL(_filters);
    if (paymentType === 'visa') getStatsVISA(_filters);
    if (paymentType === 'sms') getStatsSMS(_filters);
    if (paymentType === 'mobileint') getStatsMobileInt(_filters);
  };

  const handleOnChange = (e) => {
    const { value, name } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
    if (name === 'login' && value !== '') setDisabledGroup(true);
    else setDisabledGroup(false);

    if (name === 'logingroup' && value !== '') setDisabledLogin(true);
    else setDisabledLogin(false);
  };

  const handleChangeType = (e) => {
    e.preventDefault();
    let _filters = filters;

    setPaymentType(e.target.value);
    setFilters({
      ...filters,
      view: rang === filters.view ? filters.view : rang,
    });
    _filters.view = rang === filters.view ? filters.view : rang;

    if (e.target.value === 'all') getStatsAll(_filters);
    if (e.target.value === 'adsl') getStatsADSL(_filters);
    if (e.target.value === 'visa') getStatsVISA(_filters);
    if (e.target.value === 'sms') getStatsSMS(_filters);
    if (e.target.value === 'mobileint') getStatsMobileInt(_filters);
  };

  const handleChangeDate = (date) => {
    setFilters({
      ...filters,
      date: date ? dateFormat(date) : '',
    });
    setDate(date);
  };

  // PAGINATION
  const paginate = (e) => {
    let _filters = {
      ...filters,
      pager: {
        ...filters.pager,
        page: e.target.value,
      },
    };
    getStats(_filters);
  };

  const handleChangeNumberOfColum = (e) => {
    let _filters = {
      ...filters,
      pager: {
        ...filters.pager,
        items: e.target.value,
        page: 1,
      },
    };
    getStats(_filters);
  };

  const nextPage = (page) => {
    page++;
    if (page <= stats.pager.pages) {
      let _filters = {
        ...filters,
        pager: { ...filters.pager, page: page },
      };
      getStats(_filters);
    }
  };

  const prevPage = (page) => {
    page--;
    if (page > 0) {
      let _filters = {
        ...filters,
        pager: { ...filters.pager, page: page },
      };
      getStats(_filters);
    }
  };
  // END PAGINATION

  if (loading) return <Loading />;
  return (
    <Layout>
      <div className="stats">
        {!isEmpty(stats) && !isEmpty(summaryVce) && (
          <div className="statsolecams-content">
            {/* <Card icon={<IconStatsOC color="#4b5056" />} title={translate('statistics-resum-title-vce')}>
              <div className="stats-summary">
                <span>
                  <h2>{translate('statistics-resum-data-generate-last')}</h2>
                  <h1>{formatPrice(summaryVce.total_prev_month)}</h1>
                </span>
                <span>
                  <h2>{translate('statistics-resum-data-generate-day')}</h2>
                  <h1>{formatPrice(summaryVce.total_day)}</h1>
                </span>
                <span>
                  <h2>{translate('statistics-resum-data-generate-actualy')}</h2>
                  <h1>{formatPrice(summaryVce.total_current_month)}</h1>
                </span>
              </div>
            </Card> */}
            <Card icon={<IconStatsOC color="#4b5056" />} title={translate('statistics-resum-title-vce')}>
              <div className="stats-ol">
                <Form
                  handleOnChange={handleOnChange}
                  handleChangeDate={handleChangeDate}
                  handleChangeType={handleChangeType}
                  handleChangeRang={(e) => setRang(e.target.value)}
                  paymentType={paymentType}
                  getStats={getStats}
                  filters={filters}
                  date={date}
                  logins={logins}
                  loginsGroups={loginsGroups}
                  view={filters.view}
                  rang={rang}
                  disabledgroup={disabledGroup}
                  disabledlogin={disabledLogin}
                />
              </div>
            </Card>
            <div className="table-content">
              {paymentType === 'all' && <Table stats={stats} summary={summaryVce.summary} getStatsDay={getStatsDay} view={filters.view} />}
              {paymentType === 'visa' && (
                <TableVisa stats={stats} getStatsDay={getStatsDay} summary={summaryVce.summary} view={filters.view} />
              )}
              {paymentType === 'adsl' && (
                <TableAdsl stats={stats} getStatsDay={getStatsDay} summary={summaryVce.summary} view={filters.view} />
              )}
              {paymentType === 'sms' && (
                <TableSms stats={stats} getStatsDay={getStatsDay} summary={summaryVce.summary} view={filters.view} />
              )}
              {paymentType === 'mobileint' && (
                <TableMobileint stats={stats} getStatsDay={getStatsDay} summary={summaryVce.summary} view={filters.view} />
              )}
              <Pagination
                handleChangeNumberOfColum={handleChangeNumberOfColum}
                pager={stats.pager}
                paginate={paginate}
                prevPage={prevPage}
                nextPage={nextPage}
              />
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default StatsVce;

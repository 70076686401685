import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Card, Container, Col, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import BrokerService from 'shared/broker-service';

import Loading from 'components/loading';
import Button from 'components/button';
import Form from './summaryForm';

import './index.scss';

const Brokers = () => {
  let loading = useSelector((state) => state.loading.loading);
  const dispatch = useDispatch();
  const history = useHistory();

  const [brokers, setBrokers] = useState([]);
  const [summary, setSummary] = useState(null);
  const [filters, setFilters] = useState({
    date: new Date(),
    broker: null,
  });
  const [test_studio, setTestStudio] = useState(false);

  const getStats = useCallback(
    (filters) => {
      dispatch({ type: 'SET_LOADING', loading: true });
      BrokerService.adminStatsSummary(filters)
        .then((responseData) => {
          if (responseData.status === 'Accepted') setSummary(responseData.message);
        })
        .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
    },
    [dispatch, setSummary]
  );

  const getBrokersList = useCallback(() => {
    dispatch({ type: 'SET_LOADING', loading: true });
    BrokerService.adminList({ page: 1, perPage: 9999, filters: {} })
      .then((responseData) => {
        if (responseData.status === 'Accepted') setBrokers(responseData.message.data);
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
  }, [dispatch, setBrokers]);

  useEffect(getBrokersList, [getBrokersList]);

  useEffect(getStats, [getStats]);

  const handleChangeDate = (date) => {
    setFilters({
      ...filters,
      date: date,
    });

    /*let local_filters = { date: date };
        statsSummary(auth.token, local_filters, dispatch).then(response => {
            if (response.data.status === 'Accepted') {
                setSummary(response.data.message)
            }
        }).catch(error => {
            //console.log(error);
        });*/
  };

  const getSummaryBtn = () => {
    dispatch({ type: 'SET_LOADING', loading: true });
    BrokerService.adminStatsSummary(filters)
      .then((responseData) => {
        if (responseData.status === 'Accepted') setSummary(responseData.message);
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
  };

  const handleOnChangeBroker = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  if (loading) return <Loading />;
  const _class = 'brokers';

  return (
    <Container className={`mw-100 ha-brokers ${_class}`}>
      <Row>
        <Col sm="12">
          <Card className="mb-2">
            <Card.Header>
              <FormattedMessage id="statistics-resum-title" />
            </Card.Header>
            <Card.Body>
              <Form
                handleChangeDate={handleChangeDate}
                filters={filters}
                getSummaryBtn={getSummaryBtn}
                brokers={brokers}
                handleOnChangeBroker={handleOnChangeBroker}
                test_studio={test_studio}
                setTestStudio={setTestStudio}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {summary && (
        <>
          {!test_studio && (
            <>
              <Row className="mb-2">
                <Col>
                  <Card className="text-center">
                    <Card.Header className="justify-content-center font-weight-bold">803</Card.Header>
                    <Card.Body>
                      <Card.Text>
                        <span>
                          <FormattedMessage id="global-total-minutes" />{' '}
                          <span className="font-weight-bold">{summary.adsl.total_minutes}</span>
                        </span>
                        <br />
                        <span>
                          <FormattedMessage id="global-total-conn" /> <span className="font-weight-bold">{summary.adsl.total_conn}</span>
                        </span>
                        <br />
                        <span>
                          <FormattedMessage id="global-average-minutes" />{' '}
                          <span className="font-weight-bold">{summary.adsl.average_minutes}</span>
                        </span>
                        <br />
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>

                <Col>
                  <Card className="text-center">
                    <Card.Header className="justify-content-center font-weight-bold">
                      <FormattedMessage id="statistics-table-credit" />
                    </Card.Header>
                    <Card.Body>
                      <Card.Text>
                        <span>
                          <FormattedMessage id="global-total-credits" />{' '}
                          <span className="font-weight-bold">{summary.oc_credits.total_credits}</span>
                        </span>
                        <br />
                        <span>
                          <FormattedMessage id="global-total-conn" />{' '}
                          <span className="font-weight-bold">{summary.oc_credits.total_conn}</span>
                        </span>
                        <br />
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>

                {/* <Col>
                  <Card className="text-center">
                    <Card.Header className="justify-content-center font-weight-bold">
                      <FormattedMessage id="global-vce-minutes" />
                    </Card.Header>
                    <Card.Body>
                      <Card.Text>
                        <span>
                          <FormattedMessage id="global-total-minutes" /> <span className="font-weight-bold">{summary.vce.total_min}</span>
                        </span>
                        <br />
                        <span>
                          <FormattedMessage id="global-total-conn" /> <span className="font-weight-bold">{summary.vce.total_conn}</span>
                        </span>
                        <br />
                        <span>
                          <FormattedMessage id="global-average-minutes" />{' '}
                          <span className="font-weight-bold">{summary.vce.average_minutes}</span>
                        </span>
                        <br />
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col> */}
              </Row>
              <Row className="mb-2">
                <Col>
                  <Card className="text-center">
                    <Card.Header className="justify-content-center font-weight-bold">Extras</Card.Header>
                    <Card.Body>
                      <Card.Text>
                        <span>
                          <FormattedMessage id="global-total-minutes" />{' '}
                          <span className="font-weight-bold">{summary.extras.total_min}</span>
                        </span>
                        <br />
                        <span>
                          <FormattedMessage id="global-total-credits" />{' '}
                          <span className="font-weight-bold">{summary.extras.total_credits}</span>
                        </span>
                        <br />
                        <span>
                          <FormattedMessage id="global-total-conn" /> <span className="font-weight-bold">{summary.extras.total_conn}</span>
                        </span>
                        <br />
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>

                <Col>
                  <Card className="text-center">
                    <Card.Header className="justify-content-center font-weight-bold">
                      <FormattedMessage id="global-tips" />
                    </Card.Header>
                    <Card.Body>
                      <Card.Text>
                        <span>
                          <FormattedMessage id="global-total-credits" />{' '}
                          <span className="font-weight-bold">{summary.tips.total_credits}</span>
                        </span>
                        <br />
                        <span>
                          <FormattedMessage id="global-total-credits-lovense" />{' '}
                          <span className="font-weight-bold">{summary.tips.total_credits_lovense}</span>
                        </span>
                        <br />
                        <span>
                          <FormattedMessage id="global-total-conn" /> <span className="font-weight-bold">{summary.tips.total_count}</span>
                        </span>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </>
          )}
          {test_studio && (
            <Row className="mb-2">
              <Col>
                <Card className="text-center">
                  <Card.Header className="justify-content-center font-weight-bold">
                    <FormattedMessage id="statistics-oc-credits-test-room" />
                  </Card.Header>
                  <Card.Body>
                    <Card.Text>
                      <span>
                        <FormattedMessage id="global-total-credits" />{' '}
                        <span className="font-weight-bold">{summary.oc_test.total_credits}</span>
                      </span>
                      <br />
                      <span>
                        <FormattedMessage id="global-total-conn" /> <span className="font-weight-bold">{summary.oc_test.total_conn}</span>
                      </span>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>

              <Col>
                <Card className="text-center">
                  <Card.Header className="justify-content-center font-weight-bold">
                    <FormattedMessage id="statistics-tips-test" />
                  </Card.Header>
                  <Card.Body>
                    <Card.Text>
                      <span>
                        <FormattedMessage id="global-total-credits" />{' '}
                        <span className="font-weight-bold">{summary.tips_test.total_credits}</span>
                      </span>
                      <br />
                      <span>
                        <FormattedMessage id="global-total-credits-lovense" />{' '}
                        <span className="font-weight-bold">{summary.tips_test.total_credits_lovense}</span>
                      </span>
                      <br />
                      <span>
                        <FormattedMessage id="global-total-conn" />{' '}
                        <span className="font-weight-bold">{summary.tips_test.total_count}</span>
                      </span>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}
        </>
      )}
      <Row className="mb-2">
        <Col>
          <Button fullWidth onClick={(e) => history.push('/admintools/stats-brokers/detail')}>
            <FormattedMessage id="statistics-resum-button" />
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default Brokers;

import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';

import BannerCategoryService from 'shared/banner-category-service';

// components
import Card from 'components/card';
import Button from 'components/button';
import Data from 'components/icons/data';

//style
import '../index.scss';

const Update = ({ category, close }) => {
  const [data, setData] = useState({
    ID: category.ID,
    NAME_ES: category.NAME_ES,
    NAME_EN: category.NAME_EN,
    DESCRIPTION: category.DESCRIPTION,
  });

  const submit = async (e) => {
    e.preventDefault();
    BannerCategoryService.adminUpdate({ data }).then((data) => {
      if (data.status === 'Failed') toast.error(<FormattedMessage id="herramientas-category-error-request-update" />);
      else close(true);
    });
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };
  return (
    <div className="register-banner-modal">
      <Card icon={<Data />} title={<FormattedMessage id="herramientas-category-name" />} modal closeModal={close}>
        <div className="banner-body">
          <form className="banner-modal" onSubmit={submit}>
            <div className="form-group">
              <label>
                <FormattedMessage id="herramientas-banner-input-name_es" />
              </label>
              <input type="text" className="form-control" name="NAME_ES" value={data.NAME_ES} onChange={handleOnChange} />
            </div>
            <div className="form-group">
              <label>
                <FormattedMessage id="herramientas-banner-input-name_en" />
              </label>
              <input type="text" className="form-control" name="NAME_EN" value={data.NAME_EN} onChange={handleOnChange} />
            </div>
            <div className="form-group">
              <label>
                <FormattedMessage id="herramientas-banner-input-description" />
              </label>
              <textarea className="form-control" name="DESCRIPTION" value={data.DESCRIPTION} onChange={handleOnChange} />
            </div>
            <div className="button-content">
              <Button rounded type="submit">
                <FormattedMessage id="herramientas-banner-register-save" />
              </Button>
              <Button rounded onClick={(e) => close()} red>
                <FormattedMessage id="herramientas-banner-register-cancel" />
              </Button>
            </div>
          </form>
        </div>
      </Card>
    </div>
  );
};
export default Update;

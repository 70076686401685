import { FormattedMessage } from 'react-intl';
import { Col, Row, Card } from 'react-bootstrap';

const Summary = ({ data, stats_type }) => {
  return (
    <Row className="mb-2 justify-content-center">
      {stats_type === 'adsl' && (
        <>
          <Col>
            <Card className="text-center">
              <Card.Header className="justify-content-center font-weight-bold">
                <FormattedMessage id="global-total-entry-room" />
              </Card.Header>
              <Card.Body>
                <Card.Title>{data.COUNT_ROWS}</Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="text-center">
              <Card.Header className="justify-content-center font-weight-bold">
                <FormattedMessage id="statistics-table-mid" />
              </Card.Header>
              <Card.Body>
                <Card.Title>{data.AVERAGE_MINUTES}</Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="text-center ">
              <Card.Header className="justify-content-center font-weight-bold">
                <FormattedMessage id="global-total-minutes" />
              </Card.Header>
              <Card.Body>
                <Card.Title>{data.TOTAL_MINUTES}</Card.Title>
              </Card.Body>
            </Card>
          </Col>
        </>
      )}
      {/*stats_type === 'sms' && 
                <>
                    <Col md="4">
                        <Card className="text-center">
                            <Card.Header className="justify-content-center font-weight-bold">Total</Card.Header>
                            <Card.Body><Card.Title>{data.COUNT_ROWS}</Card.Title></Card.Body>
                        </Card>
                    </Col>
                </>
            */}
      {stats_type === 'vce_minutes' && (
        <>
          <Col>
            <Card className="text-center">
              <Card.Header className="justify-content-center font-weight-bold">
                <FormattedMessage id="global-total-entry-room" />
              </Card.Header>
              <Card.Body>
                <Card.Title>{data.COUNT_ROWS}</Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="text-center ">
              <Card.Header className="justify-content-center font-weight-bold">
                <FormattedMessage id="global-total-minutes" />
              </Card.Header>
              <Card.Body>
                <Card.Title>{data.TOTAL_MINUTES}</Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="text-center">
              <Card.Header className="justify-content-center font-weight-bold">
                <FormattedMessage id="statistics-table-mid" />
              </Card.Header>
              <Card.Body>
                <Card.Title>{data.AVERAGE_MINUTES}</Card.Title>
              </Card.Body>
            </Card>
          </Col>
        </>
      )}

      {stats_type === 'vcp_minutes' && (
        <>
          <Col>
            <Card className="text-center">
              <Card.Header className="justify-content-center font-weight-bold">
                <FormattedMessage id="global-total-entry-room" />
              </Card.Header>
              <Card.Body>
                <Card.Title>{data.COUNT_ROWS}</Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="text-center ">
              <Card.Header className="justify-content-center font-weight-bold">
                <FormattedMessage id="global-total-credits" />
              </Card.Header>
              <Card.Body>
                <Card.Title>{data.TOTAL_CREDITS}</Card.Title>
              </Card.Body>
            </Card>
          </Col>
        </>
      )}
      {stats_type === 'tips' && (
        <>
          <Col>
            <Card className="text-center">
              <Card.Header className="justify-content-center font-weight-bold">
                <FormattedMessage id="global-total-tips" />
              </Card.Header>
              <Card.Body>
                <Card.Title>{data.COUNT_ROWS}</Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="text-center ">
              <Card.Header className="justify-content-center font-weight-bold">
                <FormattedMessage id="global-total-credits" />
              </Card.Header>
              <Card.Body>
                <Card.Title>{data.TOTAL_CREDITS}</Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="text-center ">
              <Card.Header className="justify-content-center font-weight-bold">
                <FormattedMessage id="global-total-credits-lovense" />
              </Card.Header>
              <Card.Body>
                <Card.Title>{data.TOTAL_LOVENSE_CREDITS}</Card.Title>
              </Card.Body>
            </Card>
          </Col>
        </>
      )}
    </Row>
  );
};

export default Summary;

import { Accordion, Card, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList } from '@fortawesome/free-solid-svg-icons';

import translate from 'i18n/translate';

const StatsVisa = ({ data, email }) => {
  return (
    <Accordion className="user-stats-accordion" defaultActiveKey="0">
      <Card className="mb-2">
        <Accordion.Toggle as={Card.Header} variant="link" eventKey="0" className="pointer">
          <FontAwesomeIcon icon={faClipboardList} className="mr-2" /> {translate('global-user-movements')} &nbsp; <strong>{email}</strong>{' '}
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            <Table responsive="sm" striped bordered hover>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Webmaster</th>
                  <th>{translate('herramientas-banner-table-company')}</th>
                  <th>{translate('statistics-table-date')}</th>
                  <th>{translate('statistics-table-time')}</th>
                  <th>{translate('statistics-table-import')}</th>
                  <th>IP</th>
                  <th>{translate('statistics-table-t-sale')}</th>
                  <th>{translate('statistics-table-product')}</th>
                  <th>{translate('statistics-table-article')}</th>
                  <th>{translate('statistics-provider')}</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.id}</td>
                      <td>{item.id_webmaster}</td>
                      <td>{item.empresa}</td>
                      <td>{item.fecha}</td>
                      <td>{item.hora}</td>
                      <td>{item.importe}</td>
                      <td>{item.ip}</td>
                      <td>{item.t_venta}</td>
                      <td>{item.producto}</td>
                      <td>{item.articulo}</td>
                      <td>{item.procesadora}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default StatsVisa;

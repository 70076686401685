import { Card, Col, Form, Row, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

import translate from 'i18n/translate';

// style
import './index.scss';

const Legal = ({ domain_owner_name, saveDomainName, domain_owner_email, saveDomainEmail, validator }) => {
  const intl = useIntl();

  return (
    <Card className="mb-3">
      <Card.Header>Legal</Card.Header>
      <Card.Body>
        <Form.Group as={Row} className="mb-3 required" controlId="formOwnnerName">
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id={`tooltip-top`}>{translate('herramientas-white-label-owner-domain-explanation')}</Tooltip>}>
            <Form.Label column sm="4" className="font-weight-bold">
              <span className="text">{translate('global-domain-owner-name')}</span>
              <span className="help-icon float-right">
                <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
              </span>
            </Form.Label>
          </OverlayTrigger>
          <Col sm="8">
            <Form.Control value={domain_owner_name} name="domain_owner_name" placeholder="" onChange={saveDomainName} />
            {validator.message(intl.formatMessage({ id: 'global-domain-owner-name' }), domain_owner_name, 'max: 255|required')}
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3 required" controlId="formOwnerEmail">
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id={`tooltip-top`}>{translate('herramientas-white-label-email-owner-domain-explanation')}</Tooltip>}>
            <Form.Label column sm="4" className="font-weight-bold">
              <span className="text">{translate('global-domain-owner-email')}</span>
              <span className="help-icon float-right">
                <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
              </span>
            </Form.Label>
          </OverlayTrigger>
          <Col sm="8">
            <Form.Control value={domain_owner_email} name="domain_owner_email" placeholder="" onChange={saveDomainEmail} />
            {validator.message(intl.formatMessage({ id: 'global-domain-owner-email' }), domain_owner_email, 'max: 255|email|required')}
          </Col>
        </Form.Group>
      </Card.Body>
    </Card>
  );
};

export default Legal;

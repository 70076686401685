import { useEffect } from 'react';
import { useParams, useHistory } from 'react-router';
//import { useSelector } from 'react-redux';
import Layout from 'components/layout';
import { getAuth } from 'libs/cookies';

//Component
import Banners from './banners';
import Categorias from './categorias';
import Landings from './landings';
import News from './news';
import Afiliados from '../adminTools/Affiliates';
import UsuariosOlecams from './usuariosOlecams';
import ActiveAfiliados from '../adminTools/Affiliates/activate';
import GenerateKey from '../adminTools/Affiliates/generateKey';
import MonthBilling from './FacturacionMes';
import AffiliateBilling from './affiliateBilling';
import WhiteLabels from './whiteLabels';
import WhiteLabelEdit from './whiteLabels/edit';
import Brokers from '../adminTools/StatsBrokers';
import SummaryBrokers from '../adminTools/StatsBrokers/summary';
import VisaEvents from './visaEvents';
import VisaUser from './visaEvents/userDetail';
import Discounts from './discounts';
import NewDiscounts from './discounts/createUpdate';
import Sections from './adminSections';
import NotAllowed from 'templates/notAllowed';
import { useAuth } from 'contexts/auth';
//import auth from 'store/data/auth';

const Herramientas = () => {
  const { section, part } = useParams();
  const history = useHistory();
  const { admin } = useAuth();

  useEffect(() => {
    if (admin !== 1) history.push('/estadisticas');
  }, [admin, history]);

  // Sections
  const defaultSections = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];

  let authSections = [];

  if (getAuth().sections) {
    authSections = Object.keys(getAuth().sections).map((key, value) => {
      return defaultSections.includes(getAuth().sections[key].ID_SECTION) ? getAuth().sections[key].ID_SECTION : null;
    });
  } else {
    history.push('/');
  }

  return (
    <Layout>
      {(() => {
        switch (section) {
          case 'gestor-banners':
            return authSections.includes(1) ? <Banners /> : <NotAllowed />;

          case 'gestor-landings':
            return authSections.includes(2) ? <Landings /> : <NotAllowed />;

          case 'gestor-categorias':
            return authSections.includes(3) ? <Categorias /> : <NotAllowed />;

          case 'marcas-blancas':
            if (authSections.includes(4)) {
              return !part ? <WhiteLabels /> : <WhiteLabelEdit />;
            } else {
              return <NotAllowed />;
            }
          case 'incidencias-visa':
            if (authSections.includes(6)) {
              return !part ? <VisaEvents /> : <VisaUser />;
            } else {
              return <NotAllowed />;
            }
          case 'brokers':
            if (authSections.includes(7)) {
              return !part ? <SummaryBrokers /> : <Brokers />;
            } else {
              return <NotAllowed />;
            }

          case 'gestor-noticias':
            return authSections.includes(8) ? <News /> : <NotAllowed />;

          case 'gestor-afiliados':
            return authSections.includes(9) ? <Afiliados /> : <NotAllowed />;

          case 'facturacion-afiliado':
            return authSections.includes(9) ? <AffiliateBilling /> : <NotAllowed />;

          case 'gestor-usuarios-olecams':
            return authSections.includes(10) ? <UsuariosOlecams /> : <NotAllowed />;

          case 'activacion-afiliados':
            return authSections.includes(11) ? <ActiveAfiliados /> : <NotAllowed />;

          case 'generar-afiliado-key':
            return authSections.includes(11) ? <GenerateKey /> : <NotAllowed />;

          case 'facturacion-mensual':
            return authSections.includes(12) ? <MonthBilling /> : <NotAllowed />;

          case 'promociones':
            if (authSections.includes(13)) {
              return !part ? <Discounts /> : <NewDiscounts />;
            } else {
              return <NotAllowed />;
            }

          case 'secciones':
            return authSections.includes(14) ? <Sections /> : <NotAllowed />;
          default:
            return <></>;
        }
      })()}
    </Layout>
  );
};

export default Herramientas;

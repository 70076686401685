import { useState } from 'react';
import { toast } from 'react-toastify';
import { FormattedMessage, useIntl } from 'react-intl';
import { Card, Container, Col, Row, Form, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import DatePicker, { registerLocale } from 'react-datepicker';

import es from 'date-fns/locale/es';

import CreditGiftService from 'shared/credit-gift-service';

import { getLang } from 'libs/cookies';
import { datetimeToString } from 'libs/methods';

import { CREDIT_GIFT } from 'shared/constants';

// Hooks
import useValidator from 'hooks/useValidator';

import translate from 'i18n/translate';

import Button from 'components/button';
import Calendar from 'components/icons/calendar';
//import './index.scss';

const CreditGiftBatchModal = ({ show, setShow }) => {
  return (
    <Container className="mw-100 ha-discounts">
      <Row>
        <Col sm="12">
          <Card className="mb-2">
            <Card.Header>{translate('sidebar-setting-discounts')}</Card.Header>
            <Card.Body>
              <ModalForm show={show} setShow={setShow} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

const ModalForm = ({ show, setShow }) => {
  const { formatMessage } = useIntl();
  registerLocale('es', es);

  const [validator, showValidationMessage] = useValidator({}, {}, getLang());
  const [batchResult, setBatchResult] = useState(false);
  const [credits, setCredits] = useState(1);
  const [startDate, setStartDate] = useState(datetimeToString(new Date()));
  const [endDate, setEndDate] = useState(datetimeToString(new Date()));
  const [useLimit, setUseLimit] = useState(true);
  const [limitNumber, setLimitNumber] = useState(0);
  const [limitPerUser, setLimitPerUser] = useState(1);
  const [type] = useState(CREDIT_GIFT.TYPES.CAMPAIGN);
  const [userTypePurchase, setUserTypePurchase] = useState(false);

  // useReducer
  const [total, setTotal] = useState(1);
  const [localStartDate, setLocalStartDate] = useState(new Date());
  const [localEndDate, setLocalEndDate] = useState(new Date());

  const handleStartDate = (date) => {
    setLocalStartDate(date ? date : '');
    setStartDate(datetimeToString(date) ? datetimeToString(date) : '');
  };

  const handleEndDate = (date) => {
    setLocalEndDate(date ? date : '');
    setEndDate(datetimeToString(date) ? datetimeToString(date) : '');
  };

  const create = () => {
    if (!validator.allValid()) {
      showValidationMessage(true);
      return;
    }

    let data = {
      CREDITS: credits,
      TYPE: type,
      START_DATE: startDate,
      END_DATE: endDate,
      USER_TYPE_PURCHASE: userTypePurchase,
      USE_LIMIT: useLimit ? 1 : 0,
      LIMIT_NUMBER: useLimit ? limitNumber : 0,
      LIMIT_PER_USER: useLimit ? limitPerUser : 0,
    };

    CreditGiftService.adminBatch(data, total).then((responseData) => {
      if (responseData.status === 'Accepted') {
        toast.success(translate('global-success-operation'));
        setBatchResult(responseData.message.codes);
      } else toast.error(translate('global-error-try-later'));
    });
  };

  return (
    <Modal size="xl" show={show} onHide={() => setShow(false)} centered>
      <Modal.Header closeButton>
        <Modal.Title>Regalos por lotes</Modal.Title>
      </Modal.Header>

      <Modal.Body className="ha-discounts-new">
        <Row className="justify-content-between">
          <Col>
            {!batchResult ? (
              <Form id="form" className="form form-automatic-discounts">
                <Form.Group as={Row} className="mb-3 required justify-content-center" controlId="formDiscount">
                  <Form.Label column sm="3" className="font-weight-bold">
                    Regalos totales
                  </Form.Label>

                  <Col sm="3">
                    <Form.Control
                      type="number"
                      min={CREDIT_GIFT.FORM.TOTAL_GIFTS.MIN}
                      max={CREDIT_GIFT.FORM.TOTAL_GIFTS.MAX}
                      value={total}
                      name="total"
                      placeholder=""
                      onChange={(e) => setTotal(e.target.value)}
                    />
                    {validator.message('Regalos totales', total, CREDIT_GIFT.FORM.TOTAL_GIFTS.RULES)}
                  </Col>
                </Form.Group>
                <hr />
                <Row>
                  <Col xl="6">
                    <TypeSection type={type} />
                  </Col>

                  <Col xl="6">
                    <Form.Group as={Row} className="mb-3 required" controlId="formCredits">
                      <Form.Label column sm="6" className="font-weight-bold">
                        Créditos a regalar
                      </Form.Label>
                      <Col sm="6">
                        <Form.Control
                          type="number"
                          min={CREDIT_GIFT.FORM.CREDITS.MIN}
                          max={CREDIT_GIFT.FORM.CREDITS.MAX}
                          value={credits}
                          name="CREDITS"
                          placeholder=""
                          onChange={(e) => setCredits(e.target.value)}
                        />
                        {validator.message('Créditos a regalar', credits, CREDIT_GIFT.FORM.CREDITS.RULES)}
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <hr />
                <Row className="mb-3 required form-group calendar sp">
                  <Col xl="6">
                    <Row>
                      <Form.Label column sm="3" className="font-weight-bold">
                        <FormattedMessage id="global-start-date" />
                      </Form.Label>
                      <Col sm="9">
                        <div className="form-control">
                          <DatePicker
                            selected={localStartDate}
                            onChange={(date) => handleStartDate(date)}
                            showTimeSelect
                            locale={getLang()}
                            dateFormat="dd/MM/yyyy HH:mm"
                            id="calendar-date-start"
                            popperPlacement="bottom"
                            minDate={new Date()}
                          />
                        </div>
                        <Calendar id="calendar-date-start" />
                      </Col>
                    </Row>
                  </Col>
                  <Col xl="6">
                    <Row>
                      <Form.Label column sm="3" className="font-weight-bold">
                        <FormattedMessage id="global-end-date" />
                      </Form.Label>
                      <Col sm="9">
                        <div className="form-control">
                          <DatePicker
                            selected={localEndDate}
                            onChange={(date) => handleEndDate(date)}
                            showTimeSelect
                            locale={getLang()}
                            dateFormat="dd/MM/yyyy HH:mm"
                            id="calendar-date-end"
                            popperPlacement="bottom"
                            minDate={new Date()}
                          />
                        </div>
                        <Calendar id="calendar-date-end" />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <hr />

                <Row>
                  {type !== CREDIT_GIFT.TYPES.GLOBAL && (
                    <>
                      <Col>
                        <Form.Group as={Row} className="mb-3 required" controlId="formCheckLimit">
                          <Form.Label column sm="3" className="font-weight-bold">
                            Limitar
                          </Form.Label>
                          <Col sm="9">
                            <Form.Check
                              type="checkbox"
                              name="USE_LIMIT"
                              checked={useLimit}
                              onChange={(e) => setUseLimit(e.target.checked)}
                            />
                          </Col>
                        </Form.Group>
                      </Col>

                      {useLimit && (
                        <>
                          {type !== 'INDIVIDUAL' && (
                            <Col>
                              <Form.Group as={Row} className="mb-3 required" controlId="formUseLimit">
                                <OverlayTrigger
                                  placement="top"
                                  overlay={<Tooltip id={`tooltip-top`}>{translate('global-total-limit-explanation')}</Tooltip>}>
                                  <Form.Label column sm="6" className="font-weight-bold">
                                    {translate('global-total-limit')} <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
                                  </Form.Label>
                                </OverlayTrigger>

                                <Col sm="6">
                                  <Form.Control
                                    type="number"
                                    value={limitNumber}
                                    name="LIMIT_NUMBER"
                                    placeholder=""
                                    onChange={(e) => setLimitNumber(e.target.value)}
                                  />
                                  {validator.message(
                                    formatMessage({ id: 'global-limit' }).toLowerCase(),
                                    limitNumber,
                                    CREDIT_GIFT.FORM.LIMIT_NUMBER.RULES
                                  )}
                                </Col>
                              </Form.Group>
                            </Col>
                          )}
                          <Col>
                            <Form.Group as={Row} className="mb-3 required" controlId="formUseLimit">
                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id={`tooltip-top`}>{translate('global-limit-per-user-explanation')}</Tooltip>}>
                                <Form.Label column sm="6" className="font-weight-bold">
                                  {translate('global-limit-per-user')} <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
                                </Form.Label>
                              </OverlayTrigger>
                              <Col sm="6">
                                <Form.Control
                                  type="number"
                                  value={limitPerUser}
                                  name="LIMIT_PER_USER"
                                  placeholder=""
                                  onChange={(e) => setLimitPerUser(e.target.value)}
                                />
                                {validator.message(
                                  formatMessage({ id: 'global-limit-per-user' }).toLowerCase(),
                                  limitPerUser,
                                  CREDIT_GIFT.FORM.LIMIT_PER_USER.RULES
                                )}
                              </Col>
                            </Form.Group>
                          </Col>
                        </>
                      )}
                    </>
                  )}
                </Row>
                <hr />

                <Row>
                  <Col>
                    <UserTypePurchase userTypePurchase={userTypePurchase} setUserTypePurchase={setUserTypePurchase} />
                  </Col>
                </Row>
              </Form>
            ) : (
              <Form.Control as="textarea" value={JSON.stringify(batchResult)} rows={10} readOnly />
            )}
          </Col>
        </Row>
      </Modal.Body>

      {!batchResult && (
        <Modal.Footer className="justify-content-center">
          <Button rounded variant="primary" onClick={create}>
            <FontAwesomeIcon icon={faSave} className="mr-2" />
            <span>{translate('administration-data-button')}</span>
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

const UserTypePurchase = ({ userTypePurchase, setUserTypePurchase }) => {
  return (
    <Form.Group as={Row} className="mb-3 required" controlId="formType">
      <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-top`}>Tipo de usuario</Tooltip>}>
        <Form.Label column sm="6" className="font-weight-bold">
          Tipo de usuario <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
        </Form.Label>
      </OverlayTrigger>
      <Col sm="6">
        <select
          className="form-control"
          onChange={(e) => setUserTypePurchase(e.target.value)}
          name="USER_TYPE_PURCHASE"
          value={userTypePurchase}>
          <option value={CREDIT_GIFT.USER_TYPE_PURCHASE.ALL}>Todos</option>
          <option value={CREDIT_GIFT.USER_TYPE_PURCHASE.WITH_PURCHASE}>Con compras</option>
          <option value={CREDIT_GIFT.USER_TYPE_PURCHASE.WITHOUT_PURCHASE}>Sin compras</option>
        </select>
      </Col>
    </Form.Group>
  );
};

const TypeSection = ({ type }) => {
  return (
    <Form.Group as={Row} className="mb-3 required" controlId="formType">
      <Form.Label column sm="6" className="font-weight-bold">
        {translate('global-type')}
      </Form.Label>
      <Col sm="6">
        <select className="form-control" name="TYPE" value={type} disabled>
          <FormattedMessage id="promo-campaign-name">{(msg) => <option value={type}>{msg}</option>}</FormattedMessage>
        </select>
      </Col>
    </Form.Group>
  );
};

export default CreditGiftBatchModal;

import request from './api-service';

const set = (data) => {
  return request({
    method: 'post',
    url: '/olecams/roomlists/set',
    data: { data },
  });
};

const get = (id = null) => {
  let route = `/olecams/roomlists/get`;
  if (id) route += `?id=${id}`;
  return request({
    method: 'get',
    url: route,
  });
};

const update = (data) => {
  return request({
    method: 'put',
    url: '/olecams/roomlists/update',
    data: { data },
  });
};

const remove = (id) => {
  return request({
    method: 'delete',
    url: '/olecams/roomlists/delete',
    data: { id: id },
  });
};

const categoryList = ({ segment }) => {
  let route = `/olecams/roomlists/categorylist`;
  if (segment) route += `?segment=${segment}`;
  return request({
    method: 'get',
    url: route,
  });
};

const countriesList = ({ segment }) => {
  return request({
    method: 'get',
    url: `/olecams/roomlists/countrieslist?segment=${segment}`,
  });
};

const RoomlistService = {
  get,
  set,
  update,
  remove,
  categoryList,
  countriesList,
};

export default RoomlistService;

import { Col, Row, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

export const Description = ({ label, value, onChange }) => {
  return (
    <FormGroup label={label} tooltip="Descripción para el paquete de créditos">
      <input className="form-control" type="text" name="DESCRIPTION" value={value} onChange={onChange} maxLength="250" required />
    </FormGroup>
  );
};

export const Credits = ({ label, value, onChange }) => {
  return (
    <FormGroup label={label} tooltip="Cantidad de créditos del paquete total">
      <input className="form-control" type="number" name="CREDITS" value={value} onChange={onChange} min="1" required />
    </FormGroup>
  );
};

export const Amount = ({ label, value, onChange }) => {
  return (
    <FormGroup label={label} tooltip="Precio del paquete en €">
      <input className="form-control" type="number" name="AMOUNT" value={value} onChange={onChange} min="0" step=".01" required />
    </FormGroup>
  );
};

export const AmountUSD = ({ label, value, onChange }) => {
  return (
    <FormGroup label={label} tooltip="Precio del paquete en $">
      <input className="form-control" type="number" name="AMOUNT_USD" value={value} onChange={onChange} min="0" step=".01" required />
    </FormGroup>
  );
};

export const AmountSpecial = ({ label, value, onChange }) => {
  return (
    <FormGroup label={label} tooltip="Precio especial del paquete para usuarios fidelizados en €">
      <input className="form-control" type="number" name="AMOUNT_SPECIAL" value={value} onChange={onChange} min="0" step=".01" />
    </FormGroup>
  );
};

export const AmountUSDSpecial = ({ label, value, onChange }) => {
  return (
    <FormGroup label={label} tooltip="Precio especial del paquete para usuarios fidelizados en $">
      <input className="form-control" type="number" name="AMOUNT_USD_SPECIAL" value={value} onChange={onChange} min="0" step=".01" />
    </FormGroup>
  );
};

export const CGCode = ({ label, value, onChange }) => {
  return (
    <FormGroup label={label}>
      <input className="form-control" type="text" name="CG_CODE" value={value} onChange={onChange} maxLength="5" />
    </FormGroup>
  );
};

export const EpochCode = ({ label, value, onChange }) => {
  return (
    <FormGroup label={label}>
      <input className="form-control" type="text" name="EPOCH_CODE" value={value} onChange={onChange} maxLength="25" />
    </FormGroup>
  );
};

export const DticCode = ({ label, value, onChange }) => {
  return (
    <FormGroup label={label}>
      <input className="form-control" type="text" name="DTIC_CODE" value={value} onChange={onChange} maxLength="25" />
    </FormGroup>
  );
};

export const SofortCode = ({ label, value, onChange }) => {
  return (
    <FormGroup label={label}>
      <input className="form-control" type="text" name="SOFORT_CODE" value={value} onChange={onChange} maxLength="25" />
    </FormGroup>
  );
};

export const Type = ({ label, value, onChange }) => {
  return (
    <FormGroup label={label}>
      <select className="form-control" name="TYPE" value={value} onChange={onChange}>
        <option value="1">One Shot</option>
        <option value="2">Upsell</option>
      </select>
    </FormGroup>
  );
};

export const ArticleID = ({ label, value, onChange }) => {
  return (
    <FormGroup label={label} tooltip="ID del artículo relacionado con el paquete">
      <input className="form-control" type="number" name="ARTICLE_ID" value={value} onChange={onChange} min="1" required />
    </FormGroup>
  );
};

export const Anonymous = ({ label, value, onChange }) => {
  return (
    <FormGroup label={label} tooltip="Se mostrará en las marcas blanca con modo Anónimo">
      <CheckBox name="ANONYMOUS" value={value} onChange={onChange} />
    </FormGroup>
  );
};

export const Registered = ({ label, value, onChange }) => {
  return (
    <FormGroup label={label} tooltip="Se mostrará en las marcas blanca con modo Registrado">
      <CheckBox name="REGISTERED" value={value} onChange={onChange} />
    </FormGroup>
  );
};

export const Upsell = ({ label, value, onChange }) => {
  return (
    <FormGroup label={label} tooltip="Se mostrará en el diálogo del upsell">
      <CheckBox name="UPSELL" value={value} onChange={onChange} />
    </FormGroup>
  );
};

export const Access = ({ label, value, onChange }) => {
  return (
    <FormGroup
      label={label}
      tooltip="Se mostrará en el diálogo de access, por ejemplo https://www.chatsexo3x.com/es/access?method=CreditCard">
      <CheckBox name="ACCESS" value={value} onChange={onChange} />
    </FormGroup>
  );
};

export const AutoPromo = ({ label, value, onChange }) => {
  return (
    <FormGroup label={label} tooltip="Se mostrará en la Autopromo">
      <CheckBox name="AUTOPROMO" value={value} onChange={onChange} />
    </FormGroup>
  );
};

const CheckBox = ({ name, value, onChange }) => {
  return <input className="form-control" type="checkbox" name={name} checked={value === 1 ? true : false} onChange={onChange} />;
};

const FormGroup = ({ label, tooltip, children }) => {
  return (
    <Form.Group as={Row} className="mb-3">
      {tooltip ? (
        <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-top`}>{tooltip}</Tooltip>}>
          <label className="font-weight-bold form-label col-form-label col-sm-6">
            {label} <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
          </label>
        </OverlayTrigger>
      ) : (
        <label className="font-weight-bold form-label col-form-label col-sm-6">{label}</label>
      )}

      <Col sm="6">{children}</Col>
    </Form.Group>
  );
};

const OpenDesple = (props) => {
  return (
    <svg
      className="opendesple"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="22"
      height="22"
      viewBox="0 0 22 5">
      <path fill={props.color} id="prefix__a" d="M0 0L5 5 10 0z" />
    </svg>
  );
};

export default OpenDesple;

import PropTypes from 'prop-types';
import classNames from 'classnames';

import './index.scss';

const Button = ({
  violet,
  initial,
  superBlue,
  blue,
  gold,
  red,
  green,
  mint,
  rounded,
  minWidth,
  fullWidth,
  children,
  download,
  downloadsmall,
  className,
  ...props
}) => {
  //const { violet, initial, superBlue, blue, gold, red, green, mint, rounded, minWidth, fullWidth, children, ...props } = props;
  return (
    <button
      type={props.type}
      {...props}
      className={classNames(
        'button',
        {
          'button--violet': violet,
          'button--initial': initial,
          'button--superBlue': superBlue,
          'button--blue': blue,
          'button--gold': gold,
          'button--green': green,
          'button--red': red,
          'button--mint': mint,
          'button--rounded': rounded,
          'button--min-width': minWidth,
          'button--full-width': fullWidth,
          'button--download': download,
          'button--download-small': downloadsmall,
        },
        className
      )}>
      {children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  secondary: PropTypes.bool,
  gold: PropTypes.bool,
  green: PropTypes.bool,
  mint: PropTypes.bool,
  rounded: PropTypes.bool,
  minWidth: PropTypes.bool,
  fullWidth: PropTypes.bool,
  tag: PropTypes.oneOf(['a', 'button']),
  disabled: PropTypes.bool,
  align: PropTypes.string,
  type: PropTypes.string,
};

Button.defaultProps = {
  violet: false,
  initial: false,
  superBlue: false,
  blue: false,
  gold: false,
  green: false,
  mint: false,
  rounded: false,
  minWidth: true,
  fullWidth: false,
  disabled: false,
  align: 'start',
  download: false,
  downloadsmall: 'false',
  type: 'button',
};

export default Button;

import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import OCStatsService from 'shared/oc-stats-service';

//translate
import translate from 'i18n/translate';

//libs
import { dateFormat } from 'libs/methods';
import { isEmpty } from 'libs/lodashAlt';

//Component
import Card from 'components/card';
import Loading from 'components/loading';
import Pagination from 'components/pagination';

import Form from './form';
import Table from './table';

import IconStatsOC from 'components/icons/statsOlecams';

const StatsSms = () => {
  const loading = useSelector((state) => state.loading.loading);
  const [stats, setStats] = useState([]);
  const dispatch = useDispatch();

  const [filters, setFilters] = useState({
    date: dateFormat(new Date()),
    view: 'month',
    affiliate_id: '',
    afno: '',
    carrier: '',
    pager: {
      page: '1',
      items: '150',
    },
  });
  const [rang, setRang] = useState('month');
  const [date, setDate] = useState(new Date());

  const getData = useCallback(async () => {
    let _filters = {
      date: dateFormat(new Date()),
      view: 'month',
      affiliate_id: '',
      afno: '',
      carrier: '',
      pager: {
        page: '1',
        items: '150',
      },
    };

    dispatch({ type: 'SET_LOADING', loading: true });
    OCStatsService.smsStats(_filters)
      .then((responseData) => {
        if (responseData.status === 'Accepted') setStats(responseData.message);
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
  }, [dispatch]);

  useEffect(() => {
    getData();
  }, [getData]);

  const getStats = (localFilters = filters) => {
    let _filters = {
      ...localFilters,
      view: rang === filters.view ? filters.view : rang,
    };
    setFilters(_filters);

    dispatch({ type: 'SET_LOADING', loading: true });
    OCStatsService.smsStats(_filters)
      .then((responseData) => {
        if (responseData.status === 'Accepted') setStats(responseData.message);
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
  };

  const getStatsDay = (date) => {
    let _filters = filters;
    setFilters({
      ...filters,
      view: 'day',
      date: date,
    });
    _filters.view = 'day';
    _filters.date = date;

    setDate(dateFormat(date));
    setRang('day');

    dispatch({ type: 'SET_LOADING', loading: true });
    OCStatsService.smsStats(_filters)
      .then((responseData) => {
        if (responseData.status === 'Accepted') setStats(responseData.message);
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
  };

  const handleOnChange = (e) => {
    const { value, name } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const handleChangeDate = (date) => {
    setFilters({
      ...filters,
      date: date ? dateFormat(date) : '',
    });
    setDate(date);
  };

  // PAGINATION
  const paginate = (e) => {
    let _filters = {
      ...filters,
      pager: {
        ...filters.pager,
        page: e.target.value,
      },
    };
    getStats(_filters);
  };

  const handleChangeNumberOfColum = (e) => {
    let _filters = {
      ...filters,
      pager: {
        ...filters.pager,
        items: e.target.value,
        page: 1,
      },
    };
    getStats(_filters);
  };

  const nextPage = (page) => {
    page++;
    if (page <= stats.pager.pages) {
      let _filters = {
        ...filters,
        pager: { ...filters.pager, page: page },
      };
      getStats(_filters);
    }
  };

  const prevPage = (page) => {
    page--;
    if (page > 0) {
      let _filters = {
        ...filters,
        pager: { ...filters.pager, page: page },
      };
      getStats(_filters);
    }
  };
  // END PAGINATION

  if (loading) return <Loading />;
  return (
    <div className="stats">
      {!isEmpty(stats) && (
        <div className="statsolecams-content">
          <Card icon={<IconStatsOC color="#4b5056" />} title={translate('statistics-summary-title-oc-sms')}>
            <div className="stats-ol">
              <Form
                handleOnChange={handleOnChange}
                handleChangeDate={handleChangeDate}
                handleChangeRang={(e) => setRang(e.target.value)}
                getStats={getStats}
                filters={filters}
                date={date}
                rang={rang}
              />
            </div>
          </Card>
          <div className="table-content">
            <Table stats={stats} getStatsDay={getStatsDay} view={filters.view} />
            <Pagination
              handleChangeNumberOfColum={handleChangeNumberOfColum}
              pager={stats.pager}
              paginate={paginate}
              prevPage={prevPage}
              nextPage={nextPage}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default StatsSms;

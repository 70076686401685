import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class UserDeactive extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { cursorDefault, cursorPointer, ...props } = this.props;
    return (
      <svg
        {...props}
        className={classNames(
          'icon',
          {
            cursorDefault: this.props.cursorDefault,
            cursorPointer: this.props.cursorPointer,
          },
          this.props.className
        )}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 640 512"
        width="24"
        height="24">
        <path
          fill="#4B5056"
          d="M589.6 240l45.6-45.6c6.3-6.3 6.3-16.5 0-22.8l-22.8-22.8c-6.3-6.3-16.5-6.3-22.8 0L544 194.4l-45.6-45.6c-6.3-6.3-16.5-6.3-22.8 0l-22.8 22.8c-6.3 6.3-6.3 16.5 0 22.8l45.6 45.6-45.6 45.6c-6.3 6.3-6.3 16.5 0 22.8l22.8 22.8c6.3 6.3 16.5 6.3 22.8 0l45.6-45.6 45.6 45.6c6.3 6.3 16.5 6.3 22.8 0l22.8-22.8c6.3-6.3 6.3-16.5 0-22.8L589.6 240zM224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"
          className=""></path>
      </svg>
    );
  }
}

UserDeactive.propTypes = {
  cursorDefault: PropTypes.bool,
  cursorPointer: PropTypes.bool,
};

UserDeactive.defaultProps = {
  cursorDefault: false,
  cursorPointer: false,
};

export default UserDeactive;

import { hexToHSL } from 'libs/methods';

const UserIcon = (props) => {
  const color_darker = `hsl(${hexToHSL(props.color).h},${hexToHSL(props.color).s + '%'},calc(${hexToHSL(props.color).l + '%'} - 30%))`;
  const color_light = `hsl(${hexToHSL(props.color).h},${hexToHSL(props.color).s + '%'},calc(${hexToHSL(props.color).l + '%'} + 10%))`;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="43" height="43" viewBox="0 0 43 43">
      <defs>
        <linearGradient id="gradient-0.5038008996215053" gradientTransform="rotate(90)">
          <stop offset="0%" stopColor={color_light}></stop>
          <stop offset="100%" stopColor={props.color}></stop>
        </linearGradient>
      </defs>
      <circle cx="21.5" cy="21.5" r="20.5" fill="url(#gradient-0.5038008996215053)"></circle>
      <path
        fill={color_darker}
        fillRule="nonzero"
        d="M21.5 6.375c8.319 0 15.125 6.76 15.125 15.125a15.125 15.125 0 0 1-30.25 0c0-8.353 6.772-15.125 15.125-15.125zm0 16.486c-3.025 0-9.03 1.664-9.075 4.689a10.938 10.938 0 0 0 9.075 4.84c3.781 0 7.124-1.936 9.075-4.84-.045-3.025-6.05-4.689-9.075-4.689zm0-11.949a4.537 4.537 0 1 0 0 9.075 4.537 4.537 0 0 0 0-9.074z"></path>
    </svg>
  );
};

export default UserIcon;

import { Accordion, Card, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList } from '@fortawesome/free-solid-svg-icons';

import translate from 'i18n/translate';

const StatsVisa = ({ data, email }) => {
  return (
    <Accordion className="user-stats-accordion" defaultActiveKey="0">
      <Card className="mb-2">
        <Accordion.Toggle as={Card.Header} variant="link" eventKey="0" className="pointer">
          <FontAwesomeIcon icon={faClipboardList} className="mr-2" /> Estadísticas SMS del usuario &nbsp; <strong>{email}</strong>{' '}
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            <Table responsive="sm" striped bordered hover>
              <thead>
                <tr>
                  <th>Webmaster</th>
                  <th>{translate('statistics-table-date')}</th>
                  <th>{translate('statistics-table-time')}</th>
                  <th>Revenue</th>
                  <th>Currency</th>
                  <th>Login</th>
                  <th>{translate('statistics-table-product')}</th>
                  <th>Operador</th>
                  <th>Proveedor</th>
                  <th>Alias</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.id_webmaster}</td>
                      <td>{item.fecha}</td>
                      <td>{item.hora}</td>
                      <td>{item.revenue}</td>
                      <td>{item.currency}</td>
                      <td>{item.login}</td>
                      <td>{item.producto}</td>
                      <td>{item.operador}</td>
                      <td>{item.proveedor}</td>
                      <td>{item.alias}</td>
                      <td>{item.status}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default StatsVisa;

import { useState } from 'react';
import { Card, Col, Form, Row, Table } from 'react-bootstrap';
import translate from 'i18n/translate';
import { toast } from 'react-toastify';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEdit } from '@fortawesome/free-solid-svg-icons';

import CreditsGiftService from 'shared/credit-gift-service';

import { datetimeToString } from 'libs/methods';

import Button from 'components/button';

const CreditGiftTable = ({ data, statusSelect, refresh, editBtn }) => {
  //if (loading) return <Loading />;

  return (
    <>
      <Table responsive="sm" striped bordered hover className="discounts-table">
        <thead>
          <tr>
            <th className="text-center">ID</th>
            <th className="text-center">{translate('global-code')}</th>
            <th className="text-center">{translate('global-description')}</th>
            <th className="text-center">Créditos</th>
            <th className="text-center">Usos</th>
            <th className="text-center">{translate('global-start-date')}</th>
            <th className="text-center">{translate('global-end-date')}</th>
            <th className="text-center">{translate('global-active')}</th>
            <th className="text-center">Global</th>

            <th className="text-center">{translate('herramientas-banner-button-update')}</th>
            {/*<th className="text-center">{translate('herramientas-banner-button-remove')}</th>*/}
          </tr>
        </thead>

        <tbody>
          {data.data.map((item, index) => {
            let startDate = new Date(item.START_DATE);
            let endDate = new Date(item.END_DATE);
            let now = new Date();

            let tr_class = item.STATUS.toLowerCase();
            let expired = now.getTime() <= endDate.getTime() && now.getTime() >= startDate.getTime() ? false : true;
            if (expired && item.STATUS !== 'INACTIVE') tr_class = 'expired';

            return (
              <tr className={tr_class + ' pointer'} key={index}>
                <td>{item.ID}</td>
                <td>{item.CODE}</td>
                <td>{item.DESCRIPTION_ES}</td>
                <td>{item.CREDITS}</td>
                <td>{item.uses_count}</td>
                <td>{datetimeToString(startDate, false)}</td>
                <td>{datetimeToString(endDate, false)}</td>
                <td>
                  <StatusSelector item={item} statusSelect={statusSelect} refresh={refresh} />
                </td>
                <td className="text-center">{item.TYPE === 'GLOBAL' ? <FontAwesomeIcon icon={faCheck} /> : <></>}</td>
                <td className="text-center">
                  <Button rounded minWidth={false} onClick={(e) => editBtn(item.ID)}>
                    <FontAwesomeIcon icon={faEdit} className="ml-1" />
                  </Button>
                </td>
                {/*<td className="text-center">
                  <Button rounded red minWidth={false} onClick={(e) => deleteBtn(item.ID)}>
                    <FontAwesomeIcon icon={faEraser} className="ml-1" />
                  </Button>
                </td>*/}
              </tr>
            );
          })}
        </tbody>
      </Table>

      <Card className="mb-2">
        <Card.Body>
          <Row className="text-center">
            <Col>
              <span className="alert-success p-2">ACTIVO: Activa en producción</span>
            </Col>
            <Col>
              <span className="alert-danger p-2">INACTIVO: Desactivada</span>
            </Col>
            <Col>
              <span className="alert-primary p-2">PREPRODUCTION: Sólo entorno de pruebas</span>
            </Col>
            <Col>
              <span className="alert-warning p-2">EXPIRADO: Fuera de fecha</span>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default CreditGiftTable;

const StatusSelector = ({ item, refresh }) => {
  const [loading, setLoading] = useState(false);

  const statusSelect = (id, status) => {
    setLoading(true);
    CreditsGiftService.adminUpdate({ ID: id, STATUS: status })
      .then((data) => {
        data.status === 'Accepted'
          ? toast.success(translate('administration-success-data'))
          : toast.error(translate('global-error-deleting'));
        refresh();
      })
      .finally(() => setLoading(false));
  };
  const statusOptions = [
    { value: 'ACTIVE', title: 'ACTIVO' },
    { value: 'INACTIVE', title: 'INACTIVO' },
    { value: 'PREPRODUCTION', title: 'PREPRODUCTION' },
  ];
  return (
    <Form.Control
      as="select"
      custom
      onChange={(e) => statusSelect(item.ID, e.target.value)}
      name="STATUS"
      value={item.STATUS}
      disabled={loading}>
      {statusOptions &&
        statusOptions.length > 0 &&
        statusOptions.map((option, index) => {
          return (
            <option key={index} value={option.value}>
              {option.title}
            </option>
          );
        })}
    </Form.Control>
  );
};

import cookie from 'react-cookies';

export const setAuth = (auth) => {
  const expires = new Date();
  expires.setTime(expires.getTime() + 1000 * 60 * 60 * 24 * 7);
  cookie.save('auth', auth, {
    path: '/',
    expires: expires,
  });
};

export const setLang = (lang) => {
  const expires = new Date();
  expires.setTime(expires.getTime() + 1000 * 60 * 60 * 24 * 7);
  cookie.save('ot_lang', lang, {
    path: '/',
    expires: expires,
  });
};

export const setDevBugCookie = (data) => {
  const expires = new Date();
  expires.setTime(expires.getTime() + 1000 * 60 * 60 * 24 * 7);
  cookie.save('devbug', data, {
    path: '/',
    expires: expires,
  });
};

export const setRoomlistData = (data) => {
  const expires = new Date();
  expires.setTime(expires.getTime() + 1000 * 60 * 60 * 24 * 7);
  cookie.save('roomlist_data', data, {
    path: '/',
    expires: expires,
  });
};

export const getAuth = () => (cookie.load('auth', true) !== undefined ? cookie.load('auth') : '');

export const getLang = () => (cookie.load('ot_lang', true) !== undefined ? cookie.load('ot_lang') : 'en'); // If Lang Cookie not exists -> ES

export const getDevBugCookie = () => cookie.load('devbug', true);

export const removeAuth = () => cookie.remove('auth', { path: '/' });

export const getRoomlistData = () => (cookie.load('roomlist_data', true) !== undefined ? cookie.load('roomlist_data') : '');

export const removeRoomlistData = () => cookie.remove('roomlist_data', { path: '/' });

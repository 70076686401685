import translate from 'i18n/translate';

//libs
import { formatPrice } from 'libs/methods';

// icons
import IconMobile from 'components/icons/movil';
import IconDesktop from 'components/icons/computer';
import IconTablet from 'components/icons/tablet';

//style
import './index.scss';

const Table = ({ stats, viewType, getStatsDay }) => {
  return (
    <>
      <table className="table phone-stats">
        <thead>
          <tr>
            {viewType === 'month' && (
              <>
                <th scope="col">{translate('statistics-table-day')}</th>
                <th scope="col">{translate('statistics-table-connection')}</th>
                <th scope="col">{translate('statistics-table-mid')}</th>
                <th scope="col">{translate('statistics-table-minut')}</th>
                <th scope="col">{translate('statistics-table-import')}</th>
              </>
            )}
            {viewType === 'day' && (
              <>
                <th scope="col">{translate('statistics-table-time')}</th>
                <th scope="col">{translate('statistics-table-stat')}</th>
                <th scope="col">{translate('statistics-table-minut')}</th>
                <th scope="col">{translate('global-afno')}</th>
                <th scope="col">{translate('global-device')}</th>
                <th scope="col">{translate('statistics-table-country')}</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {viewType === 'month' &&
            stats.data.map((item, index) => {
              let tipo = (index + 1) % 2 ? 'impar' : 'par';
              if (item.status === 'ACTIVA') {
                tipo += ' active';
              }
              return (
                <tr key={index} className={tipo}>
                  <td className="hover" onClick={(e) => getStatsDay(item.dia)}>
                    {item.dia}
                  </td>
                  <td>{item.totalConexiones}</td>
                  <td>{item.mediaMinutos}</td>
                  <td>{item.totalMinutos}</td>
                  <td>{formatPrice(item.amount)}</td>
                </tr>
              );
            })}
          {viewType === 'day' &&
            stats.data.map((item, index) => {
              let tipo = (index + 1) % 2 ? 'impar' : 'par';
              let src = item.COUNTRY ? `${process.env.PUBLIC_URL}/flags/${item.COUNTRY.toLowerCase()}.png` : '';

              return (
                <tr key={index} className={tipo}>
                  <td>{item.HORA}</td>
                  <td>{item.status}</td>
                  <td>{item.totalMinutos}</td>
                  <td>{item.AFNO}</td>
                  <td>{item.DEVICE === 2 ? <IconMobile /> : item.DEVICE === 0 ? <IconDesktop /> : <IconTablet />}</td>
                  <td>
                    {item.COUNTRY ? (
                      <span className="flag">
                        <img src={src} alt="flag" />
                        {item.COUNTRY.toUpperCase()}
                      </span>
                    ) : (
                      <> -- </>
                    )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">{translate('statistics-table-connection')}</th>
            <th scope="col">{translate('statistics-table-mid')}</th>
            <th scope="col">{translate('statistics-table-minut')}</th>
            <th scope="col">{translate('statistics-table-import')}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{stats.data_summary.totalConexiones}</td>
            <td>{stats.data_summary.mediaMinutos}</td>
            <td>{stats.data_summary.totalMinutos}</td>
            <td>{formatPrice(stats.data_summary.amount)}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default Table;

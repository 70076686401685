import IconCheck from 'components/icons/check';
import IconUserDeleted from 'components/icons/user/UserDeleted';
import IconUserBlocked from 'components/icons/user/UserBlocked';

const UserStatus = ({ status }) => {
  let result;

  switch (status) {
    case '0': // Active
      result = <IconCheck cursorDefault />;
      break;
    case '-2': // Banned
      result = <IconUserBlocked cursorDefault />;
      break;
    case '-1': // Deleted
      result = <IconUserDeleted cursorDefault />;
      break;
    default: // Active
      result = <IconCheck cursorDefault />;
      break;
  }
  return result;
};

export default UserStatus;

import { FormattedMessage, useIntl } from 'react-intl';

const SelectStatus = ({ value, handleChange }) => {
  const { formatMessage } = useIntl();
  return (
    <div className="form-group">
      <label>
        <FormattedMessage id="global-purchase-status" />
      </label>
      <select className="form-control" name="status" onChange={handleChange} defaultValue={value}>
        <option value="0">{formatMessage({ id: 'statistics-pending' })}</option>
        <option value="1">{formatMessage({ id: 'statistics-completed' })}</option>
        <option value="2">{formatMessage({ id: 'statistics-rejected' })}</option>
        <option value="3">{formatMessage({ id: 'statistics-chargeback' })}</option>
        <option value="4">{formatMessage({ id: 'statistics-cancelled' })}</option>
      </select>
    </div>
  );
};

export default SelectStatus;

const initialState = {
  olecamsUsers: {},
  userData: {},
};

const OcUsersReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_USER_DATA':
      return Object.assign({}, state, {
        userData: action.userData,
      });
    case 'SET_OLECAMS_USERS':
      return Object.assign({}, state, {
        olecamsUsers: action.olecamsUsers,
      });
    default:
      return state;
  }
};

export default OcUsersReducer;

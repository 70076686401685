export const WHITELABELS = Object.freeze({
  MODES: {
    ANONYMOUS: 'ANONYMOUS',
    REGISTERED: 'REGISTERED',
    ALL: '',
  },
  STATUS: {
    VALIDATED: 'VALIDATED',
    PENDING: 'PENDING',
    REJECTED: 'REJECTED',
    PRODUCTION: 'PRODUCTION',
    REDIRECTED: 'REDIRECTED',
  },
});

export const DEFAULT_SECTIONS = Object.freeze([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16]);

export const CREDIT_GIFT = Object.freeze({
  STATUS: {
    INACTIVE: 'INACTIVE',
    ACTIVE: 'ACTIVE',
    PREPRODUCTION: 'PREPRODUCTION',
  },
  TYPES: {
    ALL: '',
    GLOBAL: 'GLOBAL',
    CAMPAIGN: 'CAMPAIGN',
    INDIVIDUAL: 'INDIVIDUAL',
  },
  DATE_RANGE_OPTIONS: {
    ALL: '',
    ON_RANGE: 'onRange',
    FINISHED: 'finished',
    UPCOMING: 'upcoming',
  },
  USER_TYPE_PURCHASE: {
    ALL: 'ALL',
    WITH_PURCHASE: 'WITH_PURCHASE',
    WITHOUT_PURCHASE: 'WITHOUT_PURCHASE',
  },
  BATCH_OPTIONS: {
    ALL: '',
    YES: 1,
    NO: 0,
  },
  FORM: {
    CREDITS: {
      MIN: 1,
      MAX: 5000,
      RULES: 'numeric|min:1,num|max:5000,num|required',
    },
    CODE: {
      MAX: 255,
      RULES: 'max: 255|required',
    },
    LIMIT_NUMBER: {
      MIN: 0,
      RULES: 'numeric|min:0,num|required',
    },
    LIMIT_PER_USER: {
      MIN: 0,
      RULES: 'numeric|min:0,num|required',
    },
    TOTAL_GIFTS: {
      MIN: 1,
      MAX: 1000,
      RULES: 'numeric|min:1,num|max:1000,num|required',
    },
  },
});

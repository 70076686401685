const Chat = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="84" height="84" viewBox="0 0 84 84">
      <defs>
        <path
          id="chata"
          fill="#FC531D"
          d="M37.09.636c19.95 0 36.274 13.67 36.274 30.546 0 16.876-16.323 30.545-36.273 30.545-4.498 0-8.814-.687-12.804-1.909C13.695 69.364.818 69.364.818 69.364c8.452-8.897 9.794-14.891 9.975-17.182-6.166-5.46-9.975-12.867-9.975-21C.818 14.305 17.141.636 37.091.636zm24.82 38.182V27.364H50.454v11.454h11.454zm-19.092 0V27.364H31.364v11.454h11.454zm-19.09 0V27.364H12.272v11.454h11.454z"
        />
      </defs>
      <g fill="none" fillRule="evenodd" opacity=".2" transform="translate(3 7)">
        <mask id="chatb" fill="#fff">
          <use xlinkHref="#chata" />
        </mask>
        <use fill="#000" fillRule="nonzero" xlinkHref="#chata" />
        <g fill="#FC531D" mask="url(#chatb)">
          <path d="M0 0H84V84H0z" transform="translate(-3 -7)" />
        </g>
      </g>
    </svg>
  );
};

export default Chat;

const Promo = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="22" height="22" viewBox="0 0 22 22">
      <path
        fill="#A2A9B0"
        id="prefix__a"
        d="M10 4H2C.895 4 0 4.895 0 6v4c0 1.105.895 2 2 2h1v4c0 .552.448 1 1 1h2c.552 0 1-.448 1-1v-4h3l5 4V0l-5 4zm9.5 4c0 1.71-.96 3.26-2.5 4V4c1.53.75 2.5 2.3 2.5 4z"
      />
    </svg>
  );
};

export default Promo;

import { useState, memo } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { load as loadRecaptcha } from 'recaptcha-v3';
import useValidator from 'hooks/useValidator';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTelegram, faSkype } from '@fortawesome/free-brands-svg-icons';
import { Form, InputGroup } from 'react-bootstrap';
import { toast } from 'react-toastify';

//libs
import countries from 'libs/countries.json';

import { PUBLIC } from 'shared/panel-links';
import UserService from 'shared/user-service';

import LoginLayout from 'components/login-layout';
import Button from 'components/button';
import Card from 'components/card';

import IconData from 'components/icons/data';
//style
import './index.scss';
import { getLang } from 'libs/cookies';

const AffiliateRegister = () => {
  let loading = useSelector((state) => state.loading.loading);
  const [validator, showValidationMessage] = useValidator({}, {}, getLang());
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const lang = getLang();

  const [data, setData] = useState({
    empresa: '',
    cif: '',
    contacto: '',
    domicilio: '',
    poblacion: '',
    provincia: '',
    cp: '',
    pais: countries[0][lang],
    telefono: '',
    email: '',
    email_facturacion: '',
    url: '',
    descripcion: '',
    boletin: 'no',
    usuario: '',
    password: '',
    referido: '',
    traffic_source_type: 'website',
  });

  //const [key, setKey] = useState('');
  const key = '';

  const [pais, setPais] = useState(countries[0][lang]);

  const [submit, setSubmit] = useState(false);
  const [cifExists, setCifExists] = useState(false);
  const [emailExists, setEmailExists] = useState(false);
  const [emailFactExists, setEmailFactExists] = useState(false);
  const [urlExists, setUrlExists] = useState(false);
  const [userExists, setUserExists] = useState(false);
  const [password, setPassword] = useState('');
  const [repeatPass, setRepeatPass] = useState('');
  const [errorRepeatpassword, setErrorRepeatpassword] = useState(false);
  const [telegram, setTelegram] = useState('');
  const [skype, setSkype] = useState('');

  const create = async (e) => {
    e.preventDefault();

    //const { affiliate, emailExist, emailFacturacionExist, cifExist, userExist, urlExits, key } = this.state;
    setSubmit(true); // Btn Disabled

    if (validator.allValid()) {
      if (password === repeatPass && !emailExists && !emailFactExists && !cifExists && !userExists && !urlExists) {
        data.pais = pais;
        data.password = password;

        const contact_info = [
          { contact_type: 'telegram', value: telegram },
          { contact_type: 'skype', value: skype },
        ];

        dispatch({ type: 'SET_LOADING', loading: true });
        // Recaptcha
        const recaptcha = await loadRecaptcha(process.env.REACT_APP_RECAPTCHA_KEY, { autoHideBadge: true });
        const tokenRecaptcha = await recaptcha.execute('action');
        // END Recaptcha

        UserService.set({ data, contact_info, key, recaptchaResponse: tokenRecaptcha })
          .then((response) => {
            if (response.status !== 'Failed') {
              window.scrollTo(0, 0);
              dispatch({ type: 'MODAL', modalDone: true });
            } else toast.error(formatMessage({ id: 'administration-data-register-error-request' }));
          })
          .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
      } else toast.error(formatMessage({ id: 'herramientas-error-data' }));
    } else showValidationMessage(true);
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleCheck = (e) => {
    if (e.target.name === 'boletin') {
      setData({
        ...data,
        boletin: data.boletin !== 'no' ? 'no' : 'si',
      });
    }
  };

  const validateUniqueFields = (type) => {
    switch (type) {
      case 'email':
        UserService.fieldExists(data.email, null, null, null).then((responseData) =>
          setEmailExists(responseData.message === 'KO - Exists' ? true : false)
        );
        //isExist(data.email, null, null, null).then((res) => setEmailExists(res.data.message === 'KO - Exists' ? true : false));
        break;
      case 'email_facturacion':
        UserService.fieldExists(data.email_facturacion, null, null, null).then((responseData) =>
          //isExist(data.email_facturacion, null, null, null).then((res) =>
          setEmailFactExists(responseData.message === 'KO - Exists' ? true : false)
        );
        break;
      case 'cif':
        UserService.fieldExists(null, data.cif, null, null).then((responseData) =>
          setCifExists(responseData.message === 'KO - Exists' ? true : false)
        );
        //isExist(null, data.cif, null, null).then((res) => setCifExists(res.data.message === 'KO - Exists' ? true : false));
        break;
      case 'user':
        UserService.fieldExists(null, null, data.usuario, null).then((responseData) =>
          setUserExists(responseData.message === 'KO - Exists' ? true : false)
        );
        //isExist(null, null, data.usuario, null).then((res) => setUserExists(res.data.message === 'KO - Exists' ? true : false));
        break;
      case 'url':
        UserService.fieldExists(null, null, null, data.url).then((responseData) =>
          setUrlExists(responseData.message === 'KO - Exists' ? true : false)
        );
        //isExist(null, null, null, data.url).then((res) => setUrlExists(res.data.message === 'KO - Exists' ? true : false));
        break;
      default:
        break;
    }
  };

  const handleRepeatPass = (e) => {
    setRepeatPass(e.target.value);
    setErrorRepeatpassword(password !== e.target.value ? true : false);
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
    setErrorRepeatpassword(repeatPass !== e.target.value ? true : false);
  };

  const traffic_sources = [
    { es: 'Página web', en: 'Website', value: 'website' },
    { es: 'Compra de tráfico', en: 'Traffic purchase', value: 'traffic_purchase' },
    { es: 'Redes sociales', en: 'Social media', value: 'social_media' },
    { es: 'Otro', en: 'Other', value: 'other' },
  ];

  return (
    <LoginLayout>
      <form onSubmit={create} className="afiliado-content">
        <div className="left">
          <Card icon={<IconData />} title={formatMessage({ id: 'administration-data-register' })}>
            <DefaultFormGroup
              inputName="empresa"
              inputValue={data.empresa}
              handleOnChange={handleOnChange}
              translatedLabel="administration-data-company"
              maxLength="80"
              disabled={loading}
              required
            />
            <div className="form-group">
              <label>
                <FormattedMessage id="administration-data-cif" />
              </label>
              <input
                type="text"
                className={classNames('form-control', {
                  'form-input-success': submit && !cifExists,
                  'form-input-error': submit && cifExists,
                })}
                name="cif"
                value={data.cif}
                onBlur={(e) => validateUniqueFields('cif')}
                onChange={handleOnChange}
                maxLength="12"
                disabled={loading}
                required
              />
              {cifExists && <span className="error-password">{formatMessage({ id: 'administration-data-register-error-cif' })}</span>}
            </div>
            <DefaultFormGroup
              inputName="contacto"
              inputValue={data.contacto}
              handleOnChange={handleOnChange}
              translatedLabel="administration-data-contact"
              maxLength="100"
              disabled={loading}
              required
            />
            <DefaultFormGroup
              inputName="domicilio"
              inputValue={data.domicilio}
              handleOnChange={handleOnChange}
              translatedLabel="administration-data-residence"
              maxLength="255"
              disabled={loading}
              required
            />
            <DefaultFormGroup
              inputName="poblacion"
              inputValue={data.poblacion}
              handleOnChange={handleOnChange}
              translatedLabel="administration-data-population"
              maxLength="100"
              disabled={loading}
              required
            />
            <DefaultFormGroup
              inputName="provincia"
              inputValue={data.provincia}
              handleOnChange={handleOnChange}
              translatedLabel="administration-data-province"
              maxLength="100"
              disabled={loading}
              required
            />
            <DefaultFormGroup
              inputName="cp"
              inputValue={data.cp}
              handleOnChange={handleOnChange}
              translatedLabel="administration-data-cp"
              maxLength="10"
              disabled={loading}
              required
            />
            <div className="form-group">
              <label>
                <FormattedMessage id="administration-data-country" />
              </label>
              <SelectCountries countries={countries} setCountry={setPais} />
            </div>
            <DefaultFormGroup
              inputName="telefono"
              inputValue={data.telefono}
              handleOnChange={handleOnChange}
              translatedLabel="administration-data-phone"
              maxLength="20"
              disabled={loading}
              required
            />

            <Form.Group>
              <label>
                <FontAwesomeIcon icon={faTelegram} className="mr-1" />
                Telegram
              </label>
              <InputGroup>
                <InputGroup.Text style={{ fontSize: '14px' }}>@</InputGroup.Text>
                <Form.Control
                  value={telegram}
                  name="telegram"
                  placeholder=""
                  onChange={(e) => setTelegram(e.target.value)}
                  minLength="3"
                  maxLength="36"
                  disabled={loading}
                />
              </InputGroup>
            </Form.Group>

            <Form.Group>
              <label>
                <FontAwesomeIcon icon={faSkype} className="mr-1" />
                Skype
              </label>

              <input
                className="form-control"
                type="text"
                name="skype"
                value={skype}
                minLength="3"
                maxLength="36"
                disabled={loading}
                onChange={(e) => setSkype(e.target.value)}
              />
            </Form.Group>

            <div className="form-group">
              <label>
                <FormattedMessage id="global-email" />
              </label>
              <input
                type="email"
                className={classNames('form-control', {
                  'form-input-success': submit && !emailExists,
                  'form-input-error': submit && emailExists,
                })}
                name="email"
                onBlur={(e) => validateUniqueFields('email')}
                value={data.email}
                onChange={handleOnChange}
                maxLength="150"
                disabled={loading}
                required
              />
              {emailExists && <span className="error-password">{formatMessage({ id: 'administration-data-register-error-email' })}</span>}
            </div>
            <div className="form-group">
              <label>
                <FormattedMessage id="global-email_facturacion" /> <FormattedMessage id="global-email_facturacion_same" />
              </label>
              <input
                type="email"
                className={classNames('form-control', {
                  'form-input-success': submit && !emailFactExists,
                  'form-input-error': submit && emailFactExists,
                })}
                name="email_facturacion"
                onBlur={(e) => validateUniqueFields('email_facturacion')}
                value={data.email_facturacion}
                onChange={handleOnChange}
                maxLength="150"
                disabled={loading}
                required
              />
              {emailFactExists && (
                <span className="error-password">{formatMessage({ id: 'administration-data-register-error-email' })}</span>
              )}
            </div>
            <div className="form-group">
              <label>
                <FormattedMessage id="global-traffic-source-label" />
              </label>
              <select
                className="form-control"
                name="traffic_source_type"
                //style={{border: (submit && !this.validator.fieldValid(formatMessage({id: 'global-traffic-source-label'}))) ? '1px solid #ff043a' : ''}}
                onChange={handleOnChange}
                disabled={loading}
                defaultValue={data.traffic_source_type}>
                {traffic_sources.map((item, index) => {
                  return (
                    <option key={index} value={item.value}>
                      {item[lang]}
                    </option>
                  );
                })}
              </select>
            </div>
            {data.traffic_source_type === 'website' && (
              <div className="form-group">
                <label>URL</label>
                <input
                  type="url"
                  className={classNames('form-control', {
                    'form-input-success': submit && !urlExists,
                    'form-input-error': submit && urlExists,
                  })}
                  name="url"
                  onBlur={(e) => validateUniqueFields('url')}
                  value={data.url}
                  placeholder="https://example.com"
                  onChange={handleOnChange}
                  maxLength="200"
                  disabled={loading}
                  required
                />
                {urlExists && <span className="error-password">{formatMessage({ id: 'administration-data-register-error-url' })}</span>}
              </div>
            )}
            <div className="form-group">
              <label>
                <FormattedMessage id="administration-data-description" />
              </label>
              <textarea
                className={classNames('form-control')}
                name="descripcion"
                value={data.descripcion}
                onChange={handleOnChange}
                maxLength="255"
                disabled={loading}
                required
              />
            </div>
            <div className="form-group">
              <input
                className="mr-2"
                type="checkbox"
                id="boletin"
                name="boletin"
                checked={data.boletin !== 'no'}
                onChange={handleCheck}
                disabled={loading}
              />
              <label htmlFor="boletin" className="d-inline">
                <FormattedMessage id="administration-data-bot" />
              </label>
            </div>
          </Card>
        </div>
        <div className="right">
          <Card icon={<IconData />} title={formatMessage({ id: 'administration-data-register-acces' })}>
            <div className="form-group">
              <label>
                <FormattedMessage id="administration-data-register-acces-user" />
              </label>
              <input
                type="text"
                className={classNames('form-control', {
                  'form-input-success': submit && !userExists,
                  'form-input-error': submit && userExists,
                })}
                name="usuario"
                value={data.usuario}
                onBlur={(e) => validateUniqueFields('user')}
                onChange={handleOnChange}
                minLength="6"
                disabled={loading}
                required
              />
              {userExists && <span className="error-password">{formatMessage({ id: 'administration-data-register-error-user' })}</span>}
            </div>
            <div className="form-group">
              <label>
                <FormattedMessage id="administration-data-register-acces-password" />
              </label>
              <input
                type="password"
                className={classNames('form-control', {
                  'form-input-success': submit && validator.check(password, 'required|password'),
                  'form-input-error': submit && !validator.check(password, 'required|password'),
                })}
                name="password"
                value={password}
                onChange={handlePassword}
                minLength="10"
                disabled={loading}
                required
              />
              {validator.message(formatMessage({ id: 'administration-data-register-acces-password' }), password, 'required|password')}
            </div>
            <div className="form-group">
              <label>
                <FormattedMessage id="administration-data-register-acces-repeat-password" />
              </label>
              <input
                type="password"
                className={classNames('form-control', {
                  'form-input-success': (submit && validator.check(repeatPass, 'required|password')) || (submit && !errorRepeatpassword),
                  'form-input-error': (submit && !validator.check(repeatPass, 'required|password')) || (submit && errorRepeatpassword),
                })}
                name="repeatpassword"
                value={repeatPass}
                onChange={handleRepeatPass}
                minLength="10"
                disabled={loading}
                required
              />
              {errorRepeatpassword && (
                <span className="error-password">{formatMessage({ id: 'administration-data-register-error-password' })} </span>
              )}
              {validator.message(
                formatMessage({ id: 'administration-data-register-acces-repeat-password' }),
                repeatPass,
                'required|password'
              )}
            </div>
          </Card>
          <div className="form-group row">
            <div className="col-sm-10 offset-sm-2">
              <span className="recaptcha-advice">
                <FormattedMessage
                  id="global-aff-register-legal-conditions"
                  values={{
                    link1: (
                      <Link to={{ pathname: PUBLIC.PRIVACY_POLICY }} role="button" target="_blank" rel="noopener noreferrer">
                        {' '}
                        <FormattedMessage id="footer-privacy-policy" />{' '}
                      </Link>
                    ),
                    link2: (
                      <Link to={{ pathname: PUBLIC.LEGAL_ADVICE }} role="button" target="_blank" rel="noopener noreferrer">
                        {' '}
                        <FormattedMessage id="global-general-conditions" />{' '}
                      </Link>
                    ),
                  }}
                />
              </span>
            </div>
          </div>
          <div className="button-content">
            <Button rounded type="submit" align="center" disabled={loading}>
              <FormattedMessage id="global-send" />
            </Button>
          </div>
        </div>
      </form>
    </LoginLayout>
  );
};

const SelectCountries = memo(({ countries, setCountry }) => {
  const _countries = countries.map((country) => country[getLang()]);
  return (
    <select
      className="form-control"
      name="pais"
      //style={{border: (submit && !this.validator.fieldValid(formatMessage({id: 'administration-data-country'}))) ? '1px solid #ff043a' : ''}}
      onChange={(e) => setCountry(e.target.value)}>
      {_countries.map((country, index) => {
        return (
          <option key={index} value={country}>
            {country}
          </option>
        );
      })}
    </select>
  );
});

const DefaultFormGroup = ({ inputName, inputValue, handleOnChange, translatedLabel, ...props }) => {
  return (
    <div className="form-group">
      <label>
        <FormattedMessage id={translatedLabel} />
      </label>
      <input type="text" className={classNames('form-control')} name={inputName} value={inputValue} onChange={handleOnChange} {...props} />
    </div>
  );
};

export default AffiliateRegister;

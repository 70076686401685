import { useEffect, useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import AsyncSelect from 'react-select/async';

import { debounce } from 'libs/lodashAlt';

import AffiliatesService from 'shared/affiliates-service';

const SelectAffiliate = ({ affiliateId, handleChange }) => {
  const { formatMessage } = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const debouncedPromiseOptions = useCallback(
    debounce((inputValue, callback) => {
      promiseOptions(inputValue).then((options) => callback(options));
    }, 250),
    []
  );

  const promiseOptions = (inputValue) => {
    return new Promise((resolve) => {
      let filters = { search: inputValue };
      let results = [];
      setIsLoading(true);

      AffiliatesService.adminList({ page: 1, perPage: 20, filters })
        .then((responseData) => {
          if (responseData.status === 'Accepted') {
            responseData.message.data.map((item, index) =>
              results.push({
                id: item.id,
                label: item.id + ' - ' + item.contacto + ' (' + item.empresa + ' )',
                value: item.id,
              })
            );
            resolve(results);
          } else resolve([]);
        })
        .finally(() => setIsLoading(false));
    });
  };

  const onChange = (option) => {
    setSelectedOption(option);
    handleChange({
      target: {
        name: 'affiliate_id',
        value: option ? option.value : '',
      },
    });
  };

  useEffect(() => {
    if (affiliateId > 0) {
      AffiliatesService.adminGet(affiliateId).then((responseData) => {
        if (responseData.status === 'Accepted') {
          setSelectedOption({
            id: responseData.message.data.id,
            label:
              responseData.message.data.id + ' - ' + responseData.message.data.contacto + ' (' + responseData.message.data.empresa + ' )',
            value: responseData.message.data.id,
          });
        }
      });
    }
  }, [affiliateId]);

  const LoadingMessage = (props) => {
    return (
      <div>
        <div {...props.innerProps} style={props.getStyles('loadingMessage', props)}>
          <FormattedMessage id="global-loading" />
        </div>
      </div>
    );
  };

  return (
    <div className="form-group">
      <label>
        <FormattedMessage id="statistics-table-afiliate" />
      </label>
      <AsyncSelect
        styles={{
          control: (styles) => ({
            ...styles,
            borderColor: '#4b5056',
            color: '#495057',
            fontWeight: '400',
            fontSize: '1rem',
          }),
        }}
        value={selectedOption}
        defaultValue={selectedOption}
        placeholder="Selecciona ..."
        loadOptions={debouncedPromiseOptions}
        onChange={onChange}
        isClearable
        noOptionsMessage={() => formatMessage({ id: 'promo-no-results-filters' })}
        isLoading={isLoading}
        components={{ LoadingMessage }}
      />
    </div>
  );
};

export default SelectAffiliate;

const Notice = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="18" viewBox="0 0 21 18">
      <path
        fill={props.color}
        d="M2 4v12h15v2H2c-2 0-2-2-2-2V4h2zm2-4v12c0 2 2 2 2 2h13c2 0 2-2 2-2V0H4zm7 9H7V3h4v6zm7 0h-5V7h5v2zm0-4h-5V3h5v2z"
      />
    </svg>
  );
};

export default Notice;

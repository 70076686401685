import { useEffect, useState } from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import Select from 'react-select';

import BrokerService from 'shared/broker-service';

export const Contact = ({ label, value, onChange }) => {
  return (
    <FormGroup label={label}>
      <input className="form-control" type="text" name="contacto" value={value} onChange={onChange} maxLength="100" required />
    </FormGroup>
  );
};

export const Email = ({ label, value, onChange }) => {
  return (
    <FormGroup label={label}>
      <input className="form-control" type="email" name="email" value={value} onChange={onChange} maxLength="250" required />
    </FormGroup>
  );
};

export const User = ({ label, value, onChange }) => {
  return (
    <FormGroup label={label}>
      <input className="form-control" type="text" name="usuario" value={value} onChange={onChange} maxLength="20" required />
    </FormGroup>
  );
};

export const Company = ({ label, value, onChange }) => {
  return (
    <FormGroup label={label}>
      <input className="form-control" type="text" name="empresa" value={value} onChange={onChange} maxLength="80" required />
    </FormGroup>
  );
};

export const Cif = ({ label, value, onChange }) => {
  return (
    <FormGroup label={label}>
      <input className="form-control" type="text" name="cif" value={value} onChange={onChange} maxLength="12" required />
    </FormGroup>
  );
};

export const Address = ({ label, value, onChange }) => {
  return (
    <FormGroup label={label}>
      <input className="form-control" type="text" name="domicilio" value={value} onChange={onChange} maxLength="250" required />
    </FormGroup>
  );
};

export const City = ({ label, value, onChange }) => {
  return (
    <FormGroup label={label}>
      <input className="form-control" type="text" name="poblacion" value={value} onChange={onChange} maxLength="100" required />
    </FormGroup>
  );
};

export const Province = ({ label, value, onChange }) => {
  return (
    <FormGroup label={label}>
      <input className="form-control" type="text" name="provincia" value={value} onChange={onChange} maxLength="100" required />
    </FormGroup>
  );
};

export const BrokerSelector = ({ brokerId, handleChange }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [brokers, setBrokers] = useState([]);
  const { formatMessage } = useIntl();

  const selectOnChange = (option) => {
    handleChange({
      target: {
        name: 'id_broker',
        value: option ? option.value : '',
      },
    });

    setSelectedOption(option);
  };

  useEffect(() => {
    // Get All brokers and if there is one asign the selected option
    BrokerService.adminList({ page: 1, perPage: 9999 }).then((responseData) => {
      if (responseData.status === 'Accepted') {
        const wl = responseData.message.data.map((item) => {
          return { value: item.BR, label: item.BR + ' - ' + item.nombre };
        });
        setBrokers(wl);
        if (brokerId > 0) setSelectedOption(wl.find((item) => item.value === brokerId));
      }
    });
  }, [brokerId]);

  return (
    <Select
      styles={{
        control: (styles) => ({
          ...styles,
          borderColor: '#4b5056',
          color: '#495057',
          fontWeight: '400',
          fontSize: '1rem',
        }),
      }}
      placeholder="Selecciona ..."
      value={selectedOption}
      defaultValue={selectedOption}
      onChange={selectOnChange}
      options={brokers}
      isClearable
      noOptionsMessage={() => formatMessage({ id: 'promo-no-results-filters' })}
      required
    />
  );
};

const FormGroup = ({ label, children }) => {
  return (
    <Form.Group as={Row} className="mb-3">
      <label className="font-weight-bold form-label col-form-label col-sm-6">{label}</label>
      <Col sm="6">{children}</Col>
    </Form.Group>
  );
};

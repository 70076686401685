const StatsOlecams = (props) => {
  return (
    <svg
      className={props.style}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="22"
      height="22"
      viewBox="0 0 22 22">
      <path fill={props.color} d="M20 18H0V0h2v16h2V7h4v9h2V3h4v13h2v-5h4z" />
    </svg>
  );
};

export default StatsOlecams;

//components
import { FormattedMessage } from 'react-intl';
import Left from '../icons/left';
import Right from '../icons/right';

//style
import './index.scss';

const Pagination = ({ pager, item, total, prevPage, nextPage, paginate, handleChangeNumberOfColum }) => {
  let paggesNumner = [];
  if (pager)
    for (let i = 1; i <= pager.pages; i++) {
      paggesNumner.push(i);
    }
  return (
    <div className="paginate-content">
      {!item && pager && (
        <div className="items">
          <FormattedMessage id="default-paginate-items" />
          <select className="select-items" onChange={handleChangeNumberOfColum} value={pager.items}>
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="150">150</option>
          </select>
        </div>
      )}
      {total && <div className="number-page">Total Items {total}</div>}
      {pager && (
        <div className="number-page">
          <Left prevPage={prevPage} page={pager.page} />
          <select className="select-pages" onChange={paginate} value={pager.page}>
            {paggesNumner.length > 0 ? (
              paggesNumner.map((number) => {
                return (
                  <option key={number} value={number}>
                    {number}
                  </option>
                );
              })
            ) : (
              <option value="1">1</option>
            )}
          </select>
          <Right nextPage={nextPage} page={pager.page} />
        </div>
      )}
    </div>
  );
};
Pagination.defaultProps = {
  item: false,
};

export default Pagination;

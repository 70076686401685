import { createStore, combineReducers } from 'redux'
import stats from './data/stats'
import auth from './data/auth'
import loading from './data/loading'
import administracion from './data/administracion'
import banners from './data/banners'
import modal from './data/modal'
import categoria from './data/categorias'
import landing from './data/landing'
import afiliados from './data/afiliados'
import olecamsUsers from './data/olecamsUsers'
import noticias from './data/noticias'
import roomlists from './data/roomlists'
import whitebrand from './data/whitebrand'

const reducers = combineReducers({
    stats,
    auth,
    loading,
    administracion,
    banners,
    modal,
    categoria,
    landing,
    afiliados,
    olecamsUsers,
    noticias,
    roomlists,
    whitebrand
})

const store = createStore(
    reducers,
    window.__REDUX_DEVTOOLS_EXTENSION__ &&  window.__REDUX_DEVTOOLS_EXTENSION__()
)

export default store

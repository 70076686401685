import request from './api-service';
const qs = require('qs');

const prefixUrl = '/promo/banner';
const prefixAdminUrl = '/admin';

const list = ({ page, perPage, filters }) => {
  let params = { filters };
  return request({
    method: 'get',
    url: `${prefixUrl}/list?page=${page}&per_page=${perPage}`,
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    }, // ToDo why paramsSerializer need it?
  });
};

const listByCategory = ({ page, perPage, filters }) => {
  let params = { filters };
  return request({
    method: 'get',
    url: `${prefixUrl}/list_by_category?page=${page}&per_page=${perPage}`,
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    }, // ToDo why paramsSerializer need it?
  });
};

const adminList = ({ page, perPage, filters }) => {
  let params = { filters };
  return request({
    method: 'get',
    url: `${prefixAdminUrl}${prefixUrl}/list?page=${page}&per_page=${perPage}`,
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    }, // ToDo why paramsSerializer need it?
  });
};

const adminSet = ({ data }) => {
  let formadata = new FormData();
  formadata.append('image', data.file);
  formadata.append('data[NAME]', data.name);
  formadata.append('data[ID_CATEGORY]', data.id_category);

  return request({
    method: 'post',
    url: `${prefixAdminUrl}${prefixUrl}/set`,
    data: formadata,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

const adminDelete = (id) =>
  request({
    method: 'delete',
    url: `${prefixAdminUrl}${prefixUrl}/delete`,
    data: { id },
  });

const BannerService = {
  list,
  listByCategory,
  adminList,
  adminSet,
  adminDelete,
};

export default BannerService;

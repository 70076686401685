import request from './api-service';
const qs = require('qs');

const prefixUrl = '/olecams/creditpacks';
const prefixAdminUrl = '/admin';

const adminGet = (id) => {
  return request({
    method: 'get',
    url: `${prefixAdminUrl}${prefixUrl}/get?id=${id}`,
  });
};

const adminList = ({ page, perPage, filters }) => {
  let params = { filters };
  return request({
    method: 'get',
    url: `${prefixAdminUrl}${prefixUrl}/list?page=${page}&per_page=${perPage}`,
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });
};

const adminSet = (data) => {
  return request({
    method: 'post',
    url: `${prefixAdminUrl}${prefixUrl}/set`,
    data: { data },
  });
};

const adminUpdate = (data) => {
  return request({
    method: 'put',
    url: `${prefixAdminUrl}${prefixUrl}/update`,
    data: { data },
  });
};

const CreditPacksService = {
  adminGet,
  adminList,
  adminSet,
  adminUpdate,
};

export default CreditPacksService;

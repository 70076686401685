import { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faServer } from '@fortawesome/free-solid-svg-icons';
import { Table } from 'react-bootstrap';

import { isEmpty } from 'libs/lodashAlt';

import AffiliatesService from 'shared/affiliates-service';

import { useAuth } from 'contexts/auth';

//Component
import Card from 'components/card';
import Loading from 'components/loading';
import Pagination from 'components/pagination';
import Password from './password';
import ModalRequest from './ModalRequest';

import IconTool from 'components/icons/herramienta';
import IconSearch from 'components/icons/search';
import IconKey from 'components/icons/key';
import IconCurrency from 'components/icons/currency';
import IconPadLock from 'components/icons/padlock';

const Affiliate = (props) => {
  let loading = useSelector((state) => state.loading.loading);
  let data = useSelector((state) => state.afiliados.afiliados);
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { checkUserToken } = useAuth();

  const [showModalRequest, setShowModalRequest] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [affiliateId, setAffiliateId] = useState(null);
  const [affiliateData, setAffiliateData] = useState(null);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [lastPage, setLastPage] = useState(1);
  const [filters, setFilters] = useState({ search: '' });

  const getList = useCallback(
    (filters) => {
      dispatch({ type: 'SET_LOADING', loading: true });
      AffiliatesService.adminList({ page, perPage, filters })
        .then((responseData) => {
          dispatch({ type: 'SET_AFILIADOS', afiliados: responseData.message });
          setLastPage(responseData.message.last_page);
        })
        .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
    },
    [dispatch]
  );

  useEffect(() => getList(filters), [getList, filters]);

  useEffect(() => {
    setAffiliateData([]);
    dispatch({ type: 'SET_LOADING', loading: true });
    AffiliatesService.adminList({ page, perPage, filters })
      .then((responseData) => {
        responseData.status === 'Failed' && toast.error('No se pudo cargar la información');
        if (responseData.status === 'Accepted') {
          dispatch({ type: 'SET_AFILIADOS', afiliados: responseData.message });
          setLastPage(responseData.message.last_page);
        }
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
  }, [page, perPage, dispatch]);

  const searchBtn = (e) => {
    e.preventDefault();
    setFilters({
      ...filters,
      search: search,
    });
    setPage(1);
  };

  const handleOnChange = (e) => setSearch(e.target.value);
  const showChangePasswordBtn = (id) => {
    setShowChangePassword(true);
    setAffiliateId(id);
  };

  const showModalRequestBtn = (data) => {
    setShowModalRequest(true);
    setAffiliateData(data);
  };

  const close = (reload = false) => {
    if (reload) getList(filters);
    setShowChangePassword(false);
    setShowModalRequest(false);
  };

  const affiliateSimulete = (id) => {
    if (window.confirm(formatMessage({ id: 'herramientas-confirm-alert-simulate' }))) {
      dispatch({ type: 'SET_LOADING', loading: true });
      AffiliatesService.adminSimulate(id)
        .then((responseData) => {
          if (responseData.status === 'Failed') {
            if (responseData.message === 'simulateAffiliate KO - Error Setting on Redis')
              toast.error(formatMessage({ id: 'herramientas-affiliate-error-request-redis' }));
            else if (responseData.message === 'simulateAffiliate KO - Parameters not provided')
              toast.error(formatMessage({ id: 'herramientas-affiliate-error-request-parameter' }));
          } else checkUserToken();
        })
        .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
    }
  };

  const unLock = (id) => {
    if (window.confirm(formatMessage({ id: 'herramientas-confirm-alert' }))) {
      dispatch({ type: 'SET_LOADING', loading: true });
      AffiliatesService.adminUpdate({
        data: {
          id: id,
          api_status: 1,
          api_login_attempt: 0,
        },
      })
        .then((responseData) => {
          if (responseData.status === 'Failed') {
            if (responseData.message === 'Failed Update') toast.error(formatMessage({ id: 'herramientas-affiliate-error-request-unlock' }));
            else if (responseData.message === 'sWhen some parameter is missing')
              toast.error(formatMessage({ id: 'herramientas-affiliate-error-request-parameter' }));
          } else toast.success(formatMessage({ id: 'herramientas-affiliate-unlock-succes' }));
        })
        .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
    }
  };

  /* PAGINATOR */
  const paginate = (e) => setPage(e.target.value);

  const handleChangeNumberOfColum = (e) => {
    setPerPage(e.target.value);
    setPage(1);
  };

  const nextPage = (_page) => {
    _page++;
    _page <= lastPage && setPage(_page);
  };

  const prevPage = (_page) => {
    _page--;
    _page > 0 && setPage(_page);
  };
  // End Paginator

  if (loading) return <Loading />;

  return (
    <>
      {!isEmpty(data) && (
        <div className="herramientas-administrativas-content">
          {!showChangePassword && !showModalRequest && (
            <>
              <Card icon={<IconTool color="#4b5056" />} title={<FormattedMessage id="herramientas-affiliate-name" />}>
                <div className="banner-header-content">
                  <form className={`search-wrapper ${props.class}`} onSubmit={(e) => searchBtn(e)}>
                    <div className="form-group input-content">
                      <input type="text" className="form-control" name="search" value={search} onChange={handleOnChange} />
                      <IconSearch search={searchBtn} />
                    </div>
                  </form>
                </div>
              </Card>

              <DataTable
                data={data.data}
                unLock={unLock}
                affiliateSimulete={affiliateSimulete}
                showChangePasswordBtn={showChangePasswordBtn}
                showModalRequestBtn={showModalRequestBtn}
              />
              <Pagination
                handleChangeNumberOfColum={handleChangeNumberOfColum}
                pager={{ page: page, items: perPage, pages: lastPage }}
                paginate={paginate}
                prevPage={prevPage}
                nextPage={nextPage}
                total={data.total}
              />
            </>
          )}
          {showChangePassword && <Password close={close} id={affiliateId} />}
          {showModalRequest && <ModalRequest close={close} data={affiliateData} />}
        </div>
      )}
    </>
  );
};

export default Affiliate;

const DataTable = ({ data, unLock, affiliateSimulete, showChangePasswordBtn, showModalRequestBtn }) => {
  const { push } = useHistory();
  const billing = (id) => push('/admintools/facturacion-afiliado/' + id);

  return (
    <Table responsive="sm" striped bordered hover className="text-center">
      <thead>
        <tr>
          <th scope="col">
            <FormattedMessage id="global-id" />
          </th>
          <th scope="col">
            <FormattedMessage id="herramientas-banner-table-company" />
          </th>
          <th scope="col">
            <FormattedMessage id="global-name" />
          </th>
          <th scope="col">
            <FormattedMessage id="herramientas-banner-table-email" />
          </th>
          <th scope="col">
            <FormattedMessage id="herramientas-banner-table-country" />
          </th>
          <th scope="col">
            <FormattedMessage id="herramientas-banner-table-new-password" />
          </th>
          <th scope="col">
            <FormattedMessage id="herramientas-banner-table-unlock" />
          </th>
          <th scope="col">
            <FormattedMessage id="herramientas-banner-table-go-to-panel" />
          </th>
          <th scope="col">
            <FormattedMessage id="herramientas-banner-table-billing" />
          </th>
          <th scope="col">Peticiones API OC</th>
        </tr>
      </thead>
      <tbody>
        {data.length > 0 && (
          <>
            {data.map((item, index) => {
              return (
                <tr key={item.id}>
                  <td onClick={() => push('/admintools/gestor-afiliados/' + item.id)} className="cursorPointer">
                    {item.id}
                  </td>
                  <td onClick={() => push('/admintools/gestor-afiliados/' + item.id)} className="cursorPointer">
                    {item.empresa}
                  </td>
                  <td onClick={() => push('/admintools/gestor-afiliados/' + item.id)} className="cursorPointer">
                    {item.contacto}
                  </td>
                  <td onClick={() => push('/admintools/gestor-afiliados/' + item.id)} className="cursorPointer">
                    {item.email}
                  </td>
                  <td onClick={() => push('/admintools/gestor-afiliados/' + item.id)} className="cursorPointer">
                    {item.pais}
                  </td>
                  <td>
                    <IconKey go={showChangePasswordBtn} id={item.id} className="cursorPointer" />
                  </td>
                  <td>
                    <div onClick={(e) => unLock(item.id)}>
                      <IconPadLock className="cursorPointer" />
                    </div>
                  </td>
                  <td>
                    <img
                      className="actions cursorPointer"
                      onClick={(e) => affiliateSimulete(item.id)}
                      src="/superlogin.png"
                      alt="user_image"
                    />
                  </td>
                  <td>
                    <IconCurrency go={billing} id={item.id} className="cursorPointer" />
                  </td>
                  <td>
                    <FontAwesomeIcon icon={faServer} className="mr-3 cursorPointer" onClick={() => showModalRequestBtn(item)} />
                  </td>
                </tr>
              );
            })}
          </>
        )}
      </tbody>
    </Table>
  );
};

import React from 'react';
import { useParams } from 'react-router';

//Component
import Layout from 'components/layout';
import Profile from './profile';
import Rates from './rates';
import Referrals from './referrals';
import Billing from './billing';

const UserManager = () => {
  const { section } = useParams();
  return (
    <Layout>
      {section === 'datos' && <Profile />}
      {section === 'tarifas' && <Rates />}
      {section === 'referidos' && <Referrals />}
      {section === 'facturacion' && <Billing />}
    </Layout>
  );
};

export default UserManager;

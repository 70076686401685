const DataOfAcces = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="22" height="22" viewBox="0 0 22 22">
      <path
        fill="#62696F"
        d="M16 1.979h-4.18C11.25.419 9.53-.381 8 .179c-.86.3-1.5.96-1.82 1.8H2c-1.105 0-2 .895-2 2v14c0 1.104.895 2 2 2h14c1.105 0 2-.896 2-2v-14c0-1.105-.895-2-2-2zm-7 0c.552 0 1 .447 1 1 0 .552-.448 1-1 1s-1-.448-1-1c0-.553.448-1 1-1zm-5 4h10v-2h2v14H2v-14h2v2zm10 4H4v-2h10v2zm-2 4H4v-2h8v2z"
      />
    </svg>
  );
};

export default DataOfAcces;

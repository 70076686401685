import request from './api-service';
const qs = require('qs');

const getStats = (filters) => {
  return request({
    method: 'post',
    url: '/statsolecams',
    data: { filters },
  });
};

const adminGetFraudRegistrationStats = ({ page, perPage, filters }) => {
  const params = { filters };
  return request({
    method: 'get',
    url: `/admin/vcp/stats/fraud-registrations?page=${page}&per_page=${perPage}`,
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });
};

const getCountries = () => {
  return request({
    method: 'get',
    url: '/countriesolecams',
  });
};

const phoneStats = (filters) => {
  return request({
    method: 'post',
    url: '/stats/olecams/phone',
    data: { filters },
  });
};

const smsStats = (filters) => {
  let params = { filters };
  return request({
    method: 'get',
    url: '/olecams/stats/sms',
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });
};

const adminSmsStats = (filters) => {
  let params = { filters };
  return request({
    method: 'get',
    url: '/admin/olecams/stats/sms',
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });
};

const adminPhoneStats = (filters) => {
  return request({
    method: 'post',
    url: '/admin/olecams/stats/phone',
    data: { filters },
  });
};

const OcStatsService = {
  getStats,
  adminGetFraudRegistrationStats,
  getCountries,
  phoneStats,
  smsStats,
  adminSmsStats,
  adminPhoneStats,
};

export default OcStatsService;

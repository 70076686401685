import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { FormattedMessage, useIntl } from 'react-intl';
import { Card, Container, Col, Row, Form, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faPlus, faMinus, faCheckSquare, faSquare, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import DatePicker, { registerLocale } from 'react-datepicker';

import es from 'date-fns/locale/es';

import DiscountsService from 'shared/discounts-service';
import CreditPacksService from 'shared/credit-packs-service';

import { getLang } from 'libs/cookies';
import { datetimeToString } from 'libs/methods';
import { TYPES, PROMO_TYPE } from 'libs/promotions';
import countries_json from 'libs/countries.json';

// Hooks
import useValidator from 'hooks/useValidator';

import translate from 'i18n/translate';

import Button from 'components/button';
import Calendar from 'components/icons/calendar';
import Countries from 'components/Discounts/Countries';
import CreditPacksSection from 'components/Discounts/CreditPacks';
import './index.scss';

const DiscountsModal = ({ show, setShow }) => {
  return (
    <Container className="mw-100 ha-discounts">
      <Row>
        <Col sm="12">
          <Card className="mb-2">
            <Card.Header>{translate('sidebar-setting-discounts')}</Card.Header>
            <Card.Body>
              <ModalForm show={show} setShow={setShow} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

const ModalForm = ({ show, setShow }) => {
  const { formatMessage } = useIntl();
  registerLocale('es', es);
  const dispatch = useDispatch();

  const [validator, showValidationMessage] = useValidator({}, {}, getLang());
  const [batchResult, setBatchResult] = useState(false);
  const [discount, setDiscount] = useState(1);
  const [creditGift, setCreditGift] = useState(0);
  const [creditFactor, setCreditFactor] = useState(1);
  const [startDate, setStartDate] = useState(datetimeToString(new Date()));
  const [endDate, setEndDate] = useState(datetimeToString(new Date()));
  const [creditPacks, setCreditPacks] = useState([]);
  const [selectedCreditPacks, setSelectedCreditPacks] = useState([]);
  const [content] = useState('all');
  const [useLimit, setUseLimit] = useState(true);
  const [limitNumber, setLimitNumber] = useState(0);
  const [limitPerUser, setLimitPerUser] = useState(1);
  const [type] = useState(TYPES.CAMPAIGN);
  const [usersWithoutPurchasesOnly, setUsersWithoutPurchasesOnly] = useState(false);

  const [promoType, setPromoType] = useState(PROMO_TYPE.DISCOUNT);

  const [countries, setCountries] = useState([
    { label_es: 'España', label_en: 'Spain', label: 'España', value: 'es', iso2: 'ES', iso3: 'ESP', phone_code: 34, checked: false },

    {
      label_es: 'Reino Unido',
      label_en: 'United Kingdom',
      label: 'Reino Unido',
      value: 'gb',
      iso3: 'GBR',
      phone_code: 44,
      checked: false,
    },
    { label_es: 'Francia', label_en: 'France', label: 'Francia', value: 'fr', iso3: 'FRA', phone_code: 33, checked: false },
    { label_es: 'Portugal', label_en: 'Portugal', label: 'Portugal', value: 'pt', iso3: 'PRT', phone_code: 351, checked: false },
    { label_es: 'Alemania', label_en: 'Germany', label: 'Alemania', value: 'de', iso3: 'DEU', phone_code: 49, checked: false },
    { label_es: 'Italia', label_en: 'Italy', label: 'Italia', value: 'it', iso3: 'ITA', phone_code: 39, checked: false },
    {
      label_es: 'Estados Unidos de América',
      label_en: 'United States of America',
      label: 'Estados Unidos de América',
      value: 'us',
      iso2: 'US',
      iso3: 'USA',
      phone_code: 1,
      checked: false,
    },
  ]);
  const [moreCountries, setMoreCountries] = useState([]);

  const [showMoreCountries, setShowMoreCountries] = useState(false);

  const checkAll = (option) => {
    let _countries = countries;
    let _moreCountries = moreCountries;
    _countries.forEach((country) => {
      option === 'check' ? (country.checked = true) : (country.checked = false);
    });
    _moreCountries.forEach((country) => {
      option === 'check' ? (country.checked = true) : (country.checked = false);
    });

    setCountries([..._countries]);
    setMoreCountries([..._moreCountries]);
  };

  const getMoreCountries = () => {
    let countries_list = [];
    let label;
    countries_json.forEach((country, index) => {
      getLang() === 'en' ? (label = country.en) : (label = country.es);

      if (
        country.iso2 !== 'DE' &&
        country.iso2 !== 'ES' &&
        country.iso2 !== 'FR' &&
        country.iso2 !== 'IT' &&
        country.iso2 !== 'GB' &&
        country.iso2 !== 'US' &&
        country.iso2 !== 'PT'
      ) {
        countries_list.push({
          label: label,
          label_es: country.es,
          label_en: country.en,
          value: country.iso2.toLowerCase(),
          checked: false,
        });
      }
    });

    return countries_list;
  };

  const getCountries = useCallback(() => {
    setMoreCountries(getMoreCountries());
  }, []);

  // useReducer
  const [totalDiscounts, setTotalDiscounts] = useState(1);
  const [localStartDate, setLocalStartDate] = useState(new Date());
  const [localEndDate, setLocalEndDate] = useState(new Date());

  const getCreditPacksList = useCallback(() => {
    dispatch({ type: 'SET_LOADING', loading: true });
    CreditPacksService.adminList({ page: 1, perPage: 999, filters: [] })
      .then((responseData) => {
        responseData.status === 'Accepted' && setCreditPacks(responseData.message.data.filter((item) => item.ID !== 10));
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
  }, [dispatch, setCreditPacks]);

  useEffect(() => getCreditPacksList([]), [getCreditPacksList]);
  useEffect(() => getCountries(), [getCountries]);

  useEffect(() => {
    setCreditPacks((currentCreditPacks) =>
      currentCreditPacks.map((pack) => {
        return { ...pack, CREDIT_GIFT: parseInt(pack.CREDITS * creditFactor) + parseInt(creditGift) };
      })
    );
  }, [creditFactor, creditGift]);

  const handleStartDate = (date) => {
    setLocalStartDate(date ? date : '');
    setStartDate(datetimeToString(date) ? datetimeToString(date) : '');
  };

  const handleEndDate = (date) => {
    setLocalEndDate(date ? date : '');
    setEndDate(datetimeToString(date) ? datetimeToString(date) : '');
  };

  const handleCreditPacks = (e) => {
    const { selectedOptions } = e.target;
    setSelectedCreditPacks(Array.from(selectedOptions, (item) => item.value));
  };

  const handlePromoType = (e) => {
    const { value } = e.target;
    setPromoType(value);

    if (value === PROMO_TYPE.DISCOUNT) {
      setDiscount(1);
      setCreditFactor(0);
      setCreditGift(0);
    }

    if (value !== PROMO_TYPE.DISCOUNT) {
      setDiscount(0);
      setCreditFactor(1);
      setCreditGift(0);
    }
  };

  const create = () => {
    if (!validator.allValid()) {
      showValidationMessage(true);
      return;
    }

    let data = {
      DISCOUNT: discount,
      CREDIT_GIFT: creditGift,
      CREDIT_FACTOR: creditFactor,
      TYPE: type,
      START_DATE: startDate,
      END_DATE: endDate,
      USERS_WITHOUT_PURCHASES_ONLY: usersWithoutPurchasesOnly ? 1 : 0,
      USE_LIMIT: useLimit ? 1 : 0,
      LIMIT_NUMBER: useLimit ? limitNumber : 0,
      LIMIT_PER_USER: useLimit ? limitPerUser : 0,
      CONTENT: content,
      COUNTRIES: collectCountries(),
      CREDIT_PACKS: selectedCreditPacks,
    };

    DiscountsService.adminBatch(data, totalDiscounts).then((responseData) => {
      if (responseData.status === 'Accepted') {
        toast.success(translate('global-success-operation'));
        setBatchResult(responseData.message.codes);
      } else toast.error(translate('global-error-try-later'));
    });
  };

  const collectCountries = () => {
    // Countries
    let _countries = [];
    countries.forEach((element) => {
      element.checked && _countries.push(element.value);
    });

    moreCountries.forEach((element) => {
      element.checked && _countries.push(element.value);
    });
    return _countries;
  };

  return (
    <Modal size="xl" show={show} onHide={() => setShow(false)} centered>
      <Modal.Header closeButton>
        <Modal.Title>{formatMessage({ id: 'herramientas-batch-discounts' })}</Modal.Title>
      </Modal.Header>

      <Modal.Body className="ha-discounts-new">
        <Row className="justify-content-between">
          <Col>
            {!batchResult ? (
              <Form id="form" className="form form-automatic-discounts">
                <Form.Group as={Row} className="mb-3 required justify-content-center" controlId="formDiscount">
                  <Form.Label column sm="3" className="font-weight-bold">
                    {formatMessage({ id: 'herramientas-total-discounts' })}
                  </Form.Label>

                  <Col sm="3">
                    <Form.Control
                      type="number"
                      min="1"
                      max="1000"
                      value={totalDiscounts}
                      name="total_discounts"
                      placeholder=""
                      onChange={(e) => setTotalDiscounts(e.target.value)}
                    />
                    {validator.message(
                      formatMessage({ id: 'herramientas-total-discounts' }).toLowerCase(),
                      totalDiscounts,
                      'numeric|min:1,num|max:1000,num|required'
                    )}
                  </Col>
                </Form.Group>

                <hr />
                <Form.Group as={Row} className="mb-3 required justify-content-center" controlId="formDiscount">
                  <Form.Label column sm="3" className="font-weight-bold">
                    Descuento (%) factor o regalo créditos
                  </Form.Label>
                  <Col sm="3">
                    <select className="form-control" value={promoType} onChange={handlePromoType}>
                      <option value={PROMO_TYPE.DISCOUNT}>Descuento</option>
                      <option value={PROMO_TYPE.CREDIT_FACTOR}>Factor de multiplicación</option>
                      <option value={PROMO_TYPE.CREDIT_GIFT}>Regalo de créditos</option>
                    </select>
                  </Col>
                </Form.Group>
                <hr />
                <Row>
                  <Col xl="6">
                    <TypeSection type={type} />
                  </Col>
                </Row>
                <Row>
                  <Col xl="6">
                    {promoType === PROMO_TYPE.DISCOUNT && (
                      <Form.Group as={Row} className="mb-3 required" controlId="formDiscount">
                        <Form.Label column sm="6" className="font-weight-bold">
                          {formatMessage({ id: 'global-discount' })} (%)
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            type="number"
                            min="0"
                            max="60"
                            value={discount}
                            name="DISCOUNT"
                            placeholder=""
                            onChange={(e) => setDiscount(e.target.value)}
                          />
                          {validator.message(
                            formatMessage({ id: 'global-discount' }).toLowerCase(),
                            discount,
                            'numeric|min:0,num|max:60,num|required'
                          )}
                        </Col>
                      </Form.Group>
                    )}

                    {promoType === PROMO_TYPE.CREDIT_FACTOR && (
                      <Form.Group as={Row} className="mb-3 required" controlId="formDiscount">
                        <Form.Label column sm="6" className="font-weight-bold">
                          Factor (*)
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            type="number"
                            min="0"
                            value={creditFactor}
                            name="CREDIT_FACTOR"
                            placeholder=""
                            onChange={(e) => setCreditFactor(e.target.value)}
                            required
                          />
                        </Col>
                      </Form.Group>
                    )}

                    {promoType === PROMO_TYPE.CREDIT_GIFT && (
                      <Form.Group as={Row} className="mb-3 required" controlId="formDiscount">
                        <Form.Label column sm="6" className="font-weight-bold">
                          Regalo de créditos
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            type="number"
                            min="1"
                            step=".01"
                            value={creditGift}
                            name="CREDIT_GIFT"
                            placeholder=""
                            onChange={(e) => setCreditGift(e.target.value)}
                            required
                          />
                        </Col>
                      </Form.Group>
                    )}
                  </Col>
                </Row>
                <hr />
                <Row className="mb-3 required form-group calendar sp">
                  <Col xl="6">
                    <Row>
                      <Form.Label column sm="3" className="font-weight-bold">
                        <FormattedMessage id="global-start-date" />
                      </Form.Label>
                      <Col sm="9">
                        <div className="form-control">
                          <DatePicker
                            selected={localStartDate}
                            onChange={(date) => handleStartDate(date)}
                            showTimeSelect
                            locale={getLang()}
                            dateFormat="dd/MM/yyyy HH:mm"
                            id="calendar-date-start"
                            popperPlacement="bottom"
                            minDate={new Date()}
                          />
                        </div>
                        <Calendar id="calendar-date-start" />
                      </Col>
                    </Row>
                  </Col>
                  <Col xl="6">
                    <Row>
                      <Form.Label column sm="3" className="font-weight-bold">
                        <FormattedMessage id="global-end-date" />
                      </Form.Label>
                      <Col sm="9">
                        <div className="form-control">
                          <DatePicker
                            selected={localEndDate}
                            onChange={(date) => handleEndDate(date)}
                            showTimeSelect
                            locale={getLang()}
                            dateFormat="dd/MM/yyyy HH:mm"
                            id="calendar-date-end"
                            popperPlacement="bottom"
                            minDate={new Date()}
                          />
                        </div>
                        <Calendar id="calendar-date-end" />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <hr />

                <Row>
                  {type !== TYPES.GLOBAL && (
                    <>
                      <Col>
                        <Form.Group as={Row} className="mb-3 required" controlId="formCheckLimit">
                          <Form.Label column sm="3" className="font-weight-bold">
                            Limitar
                          </Form.Label>
                          <Col sm="9">
                            <Form.Check
                              type="checkbox"
                              name="USE_LIMIT"
                              checked={useLimit}
                              onChange={(e) => setUseLimit(e.target.checked)}
                            />
                          </Col>
                        </Form.Group>
                      </Col>

                      {useLimit && (
                        <>
                          {type !== 'INDIVIDUAL' && (
                            <Col>
                              <Form.Group as={Row} className="mb-3 required" controlId="formUseLimit">
                                <OverlayTrigger
                                  placement="top"
                                  overlay={<Tooltip id={`tooltip-top`}>{translate('global-total-limit-explanation')}</Tooltip>}>
                                  <Form.Label column sm="6" className="font-weight-bold">
                                    {translate('global-total-limit')} <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
                                  </Form.Label>
                                </OverlayTrigger>

                                <Col sm="6">
                                  <Form.Control
                                    type="number"
                                    value={limitNumber}
                                    name="LIMIT_NUMBER"
                                    placeholder=""
                                    onChange={(e) => setLimitNumber(e.target.value)}
                                  />
                                  {validator.message(
                                    formatMessage({ id: 'global-limit' }).toLowerCase(),
                                    limitNumber,
                                    'numeric|min:0,num|required'
                                  )}
                                </Col>
                              </Form.Group>
                            </Col>
                          )}
                          <Col>
                            <Form.Group as={Row} className="mb-3 required" controlId="formUseLimit">
                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id={`tooltip-top`}>{translate('global-limit-per-user-explanation')}</Tooltip>}>
                                <Form.Label column sm="6" className="font-weight-bold">
                                  {translate('global-limit-per-user')} <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
                                </Form.Label>
                              </OverlayTrigger>
                              <Col sm="6">
                                <Form.Control
                                  type="number"
                                  value={limitPerUser}
                                  name="LIMIT_PER_USER"
                                  placeholder=""
                                  onChange={(e) => setLimitPerUser(e.target.value)}
                                />
                                {validator.message(
                                  formatMessage({ id: 'global-limit-per-user' }).toLowerCase(),
                                  limitPerUser,
                                  'numeric|min:0,num|required'
                                )}
                              </Col>
                            </Form.Group>
                          </Col>
                        </>
                      )}
                    </>
                  )}
                </Row>
                <hr />

                <Row>
                  <Col>
                    {creditPacks && creditPacks.length > 0 && (
                      <CreditPacksSection
                        data={{
                          DISCOUNT: discount,
                          CREDIT_PACKS: selectedCreditPacks,
                        }}
                        handleCreditPacks={handleCreditPacks}
                        creditPacks={creditPacks}
                        validator={validator}
                      />
                    )}
                  </Col>

                  <Col>
                    <Form.Group as={Row} className="mb-3 required" controlId="formUsersWOPurchases">
                      <Form.Label column sm="6" className="font-weight-bold">
                        {translate('global-discount-only-purchase-users')}
                      </Form.Label>
                      <Col sm="6">
                        <Form.Check
                          type="checkbox"
                          name="USERS_WITHOUT_PURCHASES_ONLY"
                          checked={usersWithoutPurchasesOnly}
                          onChange={(e) => setUsersWithoutPurchasesOnly(e.target.checked)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                <>
                  <Form.Group as={Row} className="mb-3" controlId="formCountries">
                    <Form.Label column sm="4" className="font-weight-bold">
                      {translate('global-countries')}
                    </Form.Label>
                    <Col sm="8">
                      <div className="">
                        <Countries countries={countries} setCountries={setCountries} />
                      </div>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="formCountries">
                    <Form.Label column sm="4" className="font-weight-bold">
                      {!showMoreCountries ? (
                        <Button blue rounded onClick={(e) => setShowMoreCountries(true)} className="mb-2">
                          <FontAwesomeIcon icon={faPlus} className="mr-2" />
                          {translate('global-view-more')}
                        </Button>
                      ) : (
                        <Button blue rounded onClick={(e) => setShowMoreCountries(false)} className="mb-2">
                          <FontAwesomeIcon icon={faMinus} className="mr-2" />
                          {translate('global-view-less')}
                        </Button>
                      )}

                      <Button rounded blue onClick={(e) => checkAll('check')} className="mb-2">
                        <FontAwesomeIcon icon={faCheckSquare} className="mr-2" />
                        {translate('global-check-all')}
                      </Button>
                      <Button rounded red onClick={(e) => checkAll('uncheck')} className="mb-2">
                        <FontAwesomeIcon icon={faSquare} className="mr-2" />
                        {translate('global-uncheck-all')}
                      </Button>
                    </Form.Label>
                    <Col sm="8">
                      <div>{showMoreCountries && <Countries countries={moreCountries} setCountries={setMoreCountries} />}</div>
                    </Col>
                  </Form.Group>
                </>
              </Form>
            ) : (
              <Form.Control as="textarea" value={JSON.stringify(batchResult)} rows={10} readOnly />
            )}
          </Col>
        </Row>
      </Modal.Body>

      {!batchResult && (
        <Modal.Footer className="justify-content-center">
          <Button rounded variant="primary" onClick={create}>
            <FontAwesomeIcon icon={faSave} className="mr-2" />
            <span>{translate('administration-data-button')}</span>
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default DiscountsModal;

const TypeSection = ({ type }) => {
  return (
    <Form.Group as={Row} className="mb-3 required" controlId="formType">
      <Form.Label column sm="6" className="font-weight-bold">
        {translate('global-type')}
      </Form.Label>
      <Col sm="6">
        <select className="form-control" name="TYPE" value={type} disabled>
          <FormattedMessage id="promo-campaign-name">{(msg) => <option value={type}>{msg}</option>}</FormattedMessage>
        </select>
      </Col>
    </Form.Group>
  );
};

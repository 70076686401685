import { useState } from 'react';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';

import translate from 'i18n/translate';

import AffiliatesService from 'shared/affiliates-service';

// components
import Card from 'components/card';
import Button from 'components/button';
import Data from 'components/icons/data';

const Password = (props) => {
  const [pwd, setPwd] = useState('');
  const [repeatPwd, setRepeatPwd] = useState('');
  const [errorParameter, setErrorParameter] = useState(false);
  const [errorRequest, setErrorRequest] = useState(false);
  const [errorRepeatPassword, setErrorRepeatPassword] = useState(false);
  const [errorPassworFormat, setErrorPassworFormat] = useState(false);
  const [errorSamePassword, setErrorSamePassword] = useState(false);

  const changePassword = async () => {
    if (pwd.length < 8 || !/[0-9]/.test(pwd) || !/[A-Z]/.test(pwd)) {
      setErrorPassworFormat(true);
    } else if (pwd !== repeatPwd) {
      setErrorRepeatPassword(true);
      setErrorPassworFormat(false);
      //this.setState({ errorRepeatPassword: true, errorPassworFormat: false });
    } else {
      setErrorRepeatPassword(false);
      //this.setState({ errorRepeatPassword: false });
      AffiliatesService.adminChangePassword(props.id, pwd).then((responseData) => {
        if (responseData.status === 'Failed') {
          if (responseData.message.toLowerCase().includes('password cannot be the same as previous')) setErrorSamePassword(true);
          if (responseData.message.toLowerCase().includes('password cannot be the same as email or user')) setErrorSamePassword(true);
        } else {
          toast.success(translate('herramientas-affiliate-change-password-succes'));
          props.close(true);
        }
      });

      /* changePasswordAdmin(auth.token, props.id, pwd).then((res) => {
        if (res.data.status === 'Failed') {
          if (res.data.message === 'When some parameter is missing') setErrorParameter(true);
          if (res.data.message === 'changePassword Password cannot be the same as previous') setErrorSamePassword(true);
          if (res.data.message === 'Failed Update Password') setErrorRequest(true);
        } else {
          toast.success(translate('herramientas-affiliate-change-password-succes'));
          props.close(true);
        }
      }); */
    }
  };

  return (
    <div className="register-banner-modal">
      <Card icon={<Data />} title={<FormattedMessage id="herramientas-affiliate-change-password" />} modal closeModal={props.close}>
        <div className="banner-body">
          {errorParameter && (
            <div className="alert alert-danger" role="alert">
              <FormattedMessage id="herramientas-affiliate-error-request-parameter" />
            </div>
          )}
          {errorRequest && (
            <div className="alert alert-danger" role="alert">
              <FormattedMessage id="herramientas-affiliate-change-password-error-request" />
            </div>
          )}
          {errorRepeatPassword && (
            <div className="alert alert-danger" role="alert">
              <FormattedMessage id="herramientas-affiliate-change-password-error-repeat" />
            </div>
          )}
          {errorPassworFormat && (
            <div className="alert alert-danger" role="alert">
              <FormattedMessage id="herramientas-affiliate-change-password-error-format" />
            </div>
          )}
          {errorSamePassword && (
            <div className="alert alert-danger" role="alert">
              <FormattedMessage id="herramientas-affiliate-change-password-error-same" />
            </div>
          )}
          <form className="banner-modal">
            <div className="form-group">
              <label>
                <FormattedMessage id="herramientas-affiliate-password-new" />
              </label>
              <input type="password" className="form-control" name="pwd" value={pwd} onChange={(e) => setPwd(e.target.value)} />
            </div>
            <div className="form-group">
              <label>
                <FormattedMessage id="herramientas-affiliate-repeat-password" />
              </label>
              <input
                type="password"
                className="form-control"
                name="repeatpwd"
                value={repeatPwd}
                onChange={(e) => setRepeatPwd(e.target.value)}
              />
            </div>
          </form>
        </div>
        <div className="button-content">
          <Button rounded onClick={(e) => changePassword()}>
            <FormattedMessage id="herramientas-banner-register-save" />
          </Button>
          <Button rounded onClick={(e) => props.close()} red>
            <FormattedMessage id="herramientas-banner-register-cancel" />
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default Password;

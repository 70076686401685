import translate from 'i18n/translate';

//style
import './index.scss';

const MainValuesSection = (props) => {
  return (
    <section className="values white-bg">
      <div className="container">
        <h2>{translate('public-main-values-title')}</h2>
        <p className="subtitle">{translate('public-main-values-subtitle')}</p>

        <div className="thumbs-wrapper row">
          <div className="col-10 offset-1 col-md-5 offset-md-1 thumb">
            <div className="icon animate" data-anim-type="bounceInLeft" data-anim-delay="200">
              <picture>
                <img
                  src="/images/icon-effort.png"
                  srcSet="/images/icon-effort.png 1x, /images/icon-effort@2x.png 2x, /images/icon-effort@3x.png 3x"
                  alt="A toda máquina"
                  className="img-fluid"
                />
              </picture>
            </div>
            <div className="text">
              <span className="thumb-title">{translate('public-main-values-full-speed')}</span>

              <span className="thumb-subtitle">{translate('public-main-values-text1')}</span>
            </div>
          </div>
          <div className="col-10 offset-1 col-md-5 offset-md-0 thumb">
            <div className="icon animate" data-anim-type="bounceInLeft" data-anim-delay="200">
              <picture>
                <img
                  src="/images/icon-profit.png"
                  srcSet="/images/icon-profit.png 1x, /images/icon-profit@2x.png 2x, /images/icon-profit@3x.png 3x"
                  alt="Mejora tu profit"
                  className="img-fluid"
                />
              </picture>
            </div>
            <div className="text">
              <span className="thumb-title">{translate('public-main-values-improve-profit')}</span>

              <span className="thumb-subtitle">{translate('public-main-values-text2')}</span>
            </div>
          </div>
          <div className="col-10 offset-1 col-md-5 offset-md-1 thumb">
            <div className="icon animate" data-anim-type="bounceInLeft" data-anim-delay="200">
              <picture>
                <img
                  src="/images/icon-motivate.png"
                  srcSet="/images/icon-motivate.png 1x, /images/icon-motivate@2x.png 2x, /images/icon-motivate@3x.png 3x"
                  alt="Ultimas tecnoloogías"
                  className="img-fluid"
                />
              </picture>
            </div>
            <div className="text">
              <span className="thumb-title">{translate('public-main-values-latest-tech')}</span>

              <span className="thumb-subtitle">{translate('public-main-values-text3')}</span>
            </div>
          </div>
          <div className="col-10 offset-1 col-md-5 offset-md-0 thumb">
            <div className="icon animate" data-anim-type="bounceInLeft" data-anim-delay="200">
              <picture>
                <img
                  src="/images/icon-networking.png"
                  srcSet="/images/icon-networking.png 1x, /images/icon-networking@2x.png 2x, /images/icon-networking@3x.png 3x"
                  alt="Mejor Conexion"
                  className="img-fluid"
                />
              </picture>
            </div>
            <div className="text">
              <span className="thumb-title">{translate('public-main-values-best-conn')}</span>

              <span className="thumb-subtitle">{translate('public-main-values-text4')}</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainValuesSection;

import { useIntl } from 'react-intl';

const SelectPurchaseFirst = ({ value, handleChangeFirstPurchase }) => {
  const { formatMessage } = useIntl();

  return (
    <div className="form-group">
      <label>{formatMessage({ id: 'statistics-purchase-type' })}</label>
      <select className="form-control" name="first_purchase" onChange={handleChangeFirstPurchase} value={value}>
        <option value="">{formatMessage({ id: 'statistics-form-select-all' })}</option>
        <option value="first">{formatMessage({ id: 'statistics-resum-first-buy' })}</option>
        <option value="upsell">{formatMessage({ id: 'statistics-resum-billing' })}</option>
      </select>
    </div>
  );
};

export default SelectPurchaseFirst;

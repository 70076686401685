import { useState, useEffect, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { Card, Container, Col, Row, Table, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList, faPlusSquare, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

import Loading from 'components/loading';
import Button from 'components/button';

import WhiteLabelsService from 'shared/white-labels-service';

import translate from 'i18n/translate';

import './index.scss';

const WhiteBrand = () => {
  let loading = useSelector((state) => state.loading.loading);
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();

  const [data, setData] = useState([]);

  const [showRejectedModal, setShowRejectedModal] = useState(false);
  const [rejectReason, setRejectReason] = useState('');

  const getMBData = useCallback(() => {
    dispatch({ type: 'SET_LOADING', loading: true });

    WhiteLabelsService.get({})
      .then((responseData) => {
        if (responseData.status === 'Accepted' && responseData.message.length > 0) setData(responseData.message);
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
  }, [dispatch, setData]);

  useEffect(getMBData, [getMBData]);

  //getData();

  const goToForm = () => {
    dispatch({ type: 'SET_LOADING', loading: true });
    history.push('/promocion/marca-blanca/form');
  };

  const edit = (id) => {
    dispatch({ type: 'SET_ID_EDIT_WHITEBRAND', id_edit_whitebrand: id });
    dispatch({ type: 'SET_LOADING', loading: true });
    history.push('/promocion/marca-blanca/edit');
  };

  const deleteItem = (id) => {
    let msg = 'promo-sure-delete-white_brand';

    if (window.confirm(intl.formatMessage({ id: msg }))) {
      dispatch({ type: 'SET_LOADING', loading: true });
      WhiteLabelsService.remove(id)
        .then((responseData) => {
          if (responseData.status === 'Accepted') toast.success(translate('administration-success-data'));
          else toast.error(translate('promo-error-deleting-roomlist'));
          getMBData();
        })
        .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
    }
  };

  const modalHandleOpen = (reason) => {
    setRejectReason(reason);
    setShowRejectedModal(true);
  };

  const modalHandleClose = () => {
    setRejectReason('');
    setShowRejectedModal(false);
  };

  if (loading) return <Loading />;

  return (
    <Container className="main-white-brand">
      <Row>
        <Col sm="12">
          <Card className="mb-2">
            <Card.Header>
              <FontAwesomeIcon icon={faClipboardList} className="mr-2" /> {translate('sidebar-promo-white-brand')}{' '}
            </Card.Header>
            <Card.Body>
              <Container>
                {data.length > 0 ? (
                  <>
                    <div>{translate('promo-white-brand-welcome-message')}</div>
                    <Row>
                      <Col sm="12" className="text-center my-3">
                        <Button rounded fullWidth onClick={(e) => goToForm()}>
                          <FontAwesomeIcon icon={faPlusSquare} className="mr-2" /> {translate('promo-landing-form-button')}
                        </Button>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col sm="12">
                        <Table responsive="sm" striped bordered hover>
                          <thead>
                            <tr>
                              <th>ID</th>
                              <th>{translate('global-name')}</th>
                              <th>Tracking ID</th>
                              <th>{translate('global-status')}</th>
                              <th className="text-center">{translate('herramientas-banner-button-update')}</th>
                              <th className="text-center">{translate('herramientas-banner-button-remove')}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.map((item, index) => {
                              return (
                                <tr key={index} className={item.STATUS.toLowerCase()}>
                                  <td>{item.ID}</td>
                                  <td>{item.NAME}</td>
                                  <td>{item.AFNO}</td>
                                  {item.STATUS === 'PRODUCTION' && <td>{translate('global-production')}</td>}
                                  {item.STATUS === 'VALIDATED' && <td>{translate('global-validated')}</td>}
                                  {item.STATUS === 'PENDING' && <td>{translate('administration-billing-resum-pending-name')}</td>}
                                  {item.STATUS === 'REJECTED' && (
                                    <td className="cursorPointer" onClick={(e) => modalHandleOpen(item.REJECTED_REASON)}>
                                      <strong>{translate('global-rejected')}</strong>
                                    </td>
                                  )}
                                  <td className="text-center">
                                    <Button rounded blue minWidth={false} onClick={(e) => edit(item.ID)}>
                                      <FontAwesomeIcon icon={faEdit} />
                                    </Button>
                                  </td>
                                  <td className="text-center">
                                    <Button rounded red minWidth={false} onClick={(e) => deleteItem(item.ID)}>
                                      <FontAwesomeIcon icon={faTrash} />
                                    </Button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                        <Modal show={showRejectedModal} onHide={modalHandleClose} animation={false}>
                          <Modal.Header closeButton>
                            <Modal.Title>{translate('promo-reject-reason')}</Modal.Title>
                          </Modal.Header>
                          <Modal.Body> {rejectReason} </Modal.Body>
                          <Modal.Footer>
                            <Button rounded red variant="secondary" onClick={modalHandleClose}>
                              {translate('global-close')}
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <Row className="justify-content-md-center text-center">
                    <Col>
                      <FormattedMessage
                        id="promo-whitelabel-creation-advice"
                        values={{ link: <a href="mailto:soporte@promocionesweb.com">soporte@promocionesweb.com</a> }}
                      />
                    </Col>
                  </Row>
                )}
              </Container>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default WhiteBrand;

import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class UserBlocked extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { cursorDefault, cursorPointer, ...props } = this.props;
    return (
      <svg
        {...props}
        className={classNames(
          'icon',
          {
            cursorDefault: this.props.cursorDefault,
            cursorPointer: this.props.cursorPointer,
          },
          this.props.className
        )}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        width="24"
        height="24">
        <path
          fill="#4B5056"
          d="M256 8C119.034 8 8 119.033 8 256s111.034 248 248 248 248-111.034 248-248S392.967 8 256 8zm130.108 117.892c65.448 65.448 70 165.481 20.677 235.637L150.47 105.216c70.204-49.356 170.226-44.735 235.638 20.676zM125.892 386.108c-65.448-65.448-70-165.481-20.677-235.637L361.53 406.784c-70.203 49.356-170.226 44.736-235.638-20.676z"
          className=""></path>
      </svg>
    );
  }
}

UserBlocked.propTypes = {
  cursorDefault: PropTypes.bool,
  cursorPointer: PropTypes.bool,
};

UserBlocked.defaultProps = {
  cursorDefault: false,
  cursorPointer: false,
};

export default UserBlocked;

import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';

import LandingService from 'shared/landing-service';

// components
import Card from 'components/card';
import Button from 'components/button';
import Data from 'components/icons/data';

//style
import '../index.scss';

const Update = (props) => {
  const intl = useIntl();
  const [landingData, setLandingData] = useState({
    ID: props.landing.ID,
    URL: props.landing.URL,
    PUBLIC: props.landing.PUBLIC,
    CATEGORY: props.landing.CATEGORY,
  });

  const [errorData, setErrorData] = useState(false);
  const [errorRequest, setErrorRequest] = useState(false);
  const [publicType, setPublicType] = useState(props.landing.PUBLIC === 1 ? true : false);

  const update = async () => {
    let data = landingData;
    data.PUBLIC = publicType === true ? 1 : 0;
    if (data.URL === null || data.URL === '') setErrorData(true);
    else {
      LandingService.adminUpdate(data).then((data) => {
        if (data.status === 'Failed') setErrorRequest(true);
        else props.close(true);
      });
    }
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setLandingData({
      ...landingData,
      [name]: value,
    });
  };

  return (
    <div className="register-banner-modal">
      <Card icon={<Data />} title={<FormattedMessage id="herramientas-category-name" />} modal closeModal={props.close}>
        <div className="banner-body">
          {errorData && (
            <div className="alert alert-danger" role="alert">
              <FormattedMessage id="herramientas-error-data" />
            </div>
          )}
          {errorRequest && (
            <div className="alert alert-danger" role="alert">
              <FormattedMessage id="herramientas-landing-error-request-update" />
            </div>
          )}
          <form className="banner-modal">
            <div className="form-group">
              <label>
                <FormattedMessage id="global-url" />
              </label>
              <input type="text" className="form-control" name="URL" value={landingData.URL} onChange={handleOnChange} />
            </div>
            <div className="form-group">
              <label className="swith-label">
                <FormattedMessage id="herramientas-landing-input-public" />
              </label>
              <Form.Check type="switch" id="landing_type" checked={publicType} onChange={(e) => setPublicType(!publicType)} />
            </div>
            <div className="form-group">
              <label>
                <FormattedMessage id="herramientas-banner-input-category" />
              </label>
              <select name="CATEGORY" className="form-control" onChange={handleOnChange} value={landingData.CATEGORY}>
                <option value="DESKTOP">{intl.formatMessage({ id: 'herramientas-landing-desktop' })}</option>
                <option value="MOBILE">{intl.formatMessage({ id: 'herramientas-landing-mobile' })}</option>
              </select>
            </div>
          </form>
        </div>
        <div className="button-content">
          <Button rounded onClick={(e) => update()}>
            <FormattedMessage id="herramientas-banner-register-save" />
          </Button>
          <Button rounded onClick={(e) => props.close()} red>
            <FormattedMessage id="herramientas-banner-register-cancel" />
          </Button>
        </div>
      </Card>
    </div>
  );
};
export default Update;

import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// Bootstrap
import { Accordion, Card, Container, Col, Row } from 'react-bootstrap';

import Loading from 'components/loading';
import translate from 'i18n/translate';
import { useParams, useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft, faClipboardList } from '@fortawesome/free-solid-svg-icons';
import Button from 'components/button';

import BillingService from 'shared/billing-service';

//libs
import { formatPrice } from 'libs/methods';

import StatsTable from './statsTable';

import './index.scss';

const AffiliateBilling = () => {
  let loading = useSelector((state) => state.loading.loading);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth.auth);
  const history = useHistory();
  const [data, setData] = useState(null);
  const [summary, setSummary] = useState(null);
  const { part } = useParams();
  const id = part;

  const getData = () => {
    dispatch({ type: 'SET_LOADING', loading: true });
    BillingService.adminGet(id)
      .then((responseData) => {
        if (responseData.status === 'Accepted') {
          setData(responseData.message.results);
          setSummary(responseData.message.summary);
        } else {
          toast.error('No se encontró el afiliado');
          history.push('/admintools/gestor-afiliados');
        }
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
  };

  useEffect(() => {
    if (id) {
      BillingService.adminGet(id).then((responseData) => {
        if (responseData.status === 'Accepted') {
          setData(responseData.message.results);
          setSummary(responseData.message.summary);
        } else {
          //this.goToForm();
          toast.error('No se encontró el afiliado');
          history.push('/admintools/gestor-afiliados');
        }
      });
    } else {
      history.push('/admintools/gestor-afiliados');
    }
  }, [auth.token, dispatch, id, history]);

  if (loading) return <Loading />;
  const _class = 'affiliate-billing';
  return (
    <Container className={`mw-100 ha-aff-billing ${_class}`}>
      {summary && (
        <Row>
          <Col sm="12" className="mb-2">
            <Button rounded onClick={(e) => history.push('/admintools/gestor-afiliados')}>
              <FontAwesomeIcon icon={faArrowCircleLeft} className="mr-2" />
              {translate('statistics-form-button-return')}
            </Button>
          </Col>
          <Col sm="12">
            <Accordion className="current-month-accordion" defaultActiveKey="0">
              <Card className="mb-2">
                <Accordion.Toggle as={Card.Header} variant="link" eventKey="0" className="pointer">
                  <FontAwesomeIcon icon={faClipboardList} className="mr-2" /> {translate('administration-billing-resum-resum')}
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <Row>
                      <Col>
                        <Card className="text-center">
                          <Card.Header className="justify-content-center font-weight-bold">
                            {translate('administration-billing-resum-pending-name')}{' '}
                          </Card.Header>
                          <Card.Body>
                            <Card.Text>
                              <div>
                                Total <span className="font-weight-bold">{formatPrice(summary.pending.total)}</span>
                              </div>
                              <div>
                                {translate('global-periods')} <span className="font-weight-bold">{summary.pending.items}</span>
                              </div>
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>

                      <Col>
                        <Card className="text-center">
                          <Card.Header className="justify-content-center font-weight-bold">
                            {translate('administration-billing-resum-receiced-name')}
                          </Card.Header>
                          <Card.Body>
                            <Card.Text>
                              <div>
                                Total <span className="font-weight-bold">{formatPrice(summary.received.total)}</span>
                              </div>
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>

                      <Col>
                        <Card className="text-center">
                          <Card.Header className="justify-content-center font-weight-bold">
                            {translate('administration-billing-resum-paid-name')}
                          </Card.Header>
                          <Card.Body>
                            <Card.Text>
                              <div>
                                Total <span className="font-weight-bold">{formatPrice(summary.payed.total)}</span>
                              </div>
                              <div>
                                {translate('global-periods')} <span className="font-weight-bold">{summary.payed.items}</span>
                              </div>
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Col>
        </Row>
      )}
      {data && data.pending && data.pending.length > 0 && (
        <StatsTable data={data.pending} title="administration-billing-resum-pending-name" getData={getData} />
      )}
      {data && data.payed && data.payed.length > 0 && (
        <StatsTable data={data.payed} title="administration-billing-resum-paid-name" getData={getData} />
      )}
    </Container>
  );
};

export default AffiliateBilling;

import { NavLink } from 'react-router-dom';

import translate from 'i18n/translate';

import { PUBLIC } from 'shared/panel-links';

import Layout from 'components/public-layout';
import MainValuesSection from '../MainValuesSection';

import './index.scss';

const WhiteBrand = () => {
  return (
    <Layout cssClass="white_brand">
      <section className="main">
        <div className="main-content">
          <div className="container">
            <div className="landing-sample white-bg">
              <div className="content-wrapper">
                <div className="sample-header clearfix">
                  <div className="sample-logo"></div>
                  <div className="sample-menu"></div>
                </div>

                <div className="laurel">
                  <h2>
                    {translate('public-wb-title1')}
                    <br />
                    {translate('public-wb-title2')}
                  </h2>
                  <p className="subtitle">{translate('public-wb-subtitle')}</p>

                  <div className="btn-wrapper text-center">
                    <NavLink to={PUBLIC.REGISTER} className="btn btn-default">
                      {translate('public-home-lets-start')}
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="control white-bg text-left">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-6 align-self-center">
              <picture>
                <img
                  src="/images/control.svg"
                  className="control-icon img-fluid animate"
                  alt="toma el control absoluto"
                  data-anim-type="bounceInLeft"
                  data-anim-delay="200"
                />
              </picture>
            </div>
            <div className="col-12 col-sm-6">
              <h2>{translate('public-wb-take-control')}</h2>
              <p>{translate('public-wb-take-control-text')}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 text-center">
              <NavLink to={PUBLIC.REGISTER} className="btn btn-default">
                {translate('public-start-creating')}
              </NavLink>
            </div>
          </div>
        </div>
      </section>

      <section className="description">
        <div className="container">
          <h3>{translate('public-wb-descr-subtitle')}</h3>

          <div className="boxes-wrapper row">
            <div className="col-12 col-md">
              <div className="box animate" data-anim-type="zoomIn" data-anim-delay="200">
                <div className="box-order">1</div>
                <div className="box-content">
                  <span>
                    <b>{translate('public-site')}</b> <br /> {translate('public-settings')}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12 col-md">
              <div className="box animate" data-anim-type="zoomIn" data-anim-delay="300">
                <div className="box-order">2</div>
                <div className="box-content">
                  <span>
                    <b>{translate('public-site')}</b> <br /> {translate('public-design')}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12 col-md">
              <div className="box animate" data-anim-type="zoomIn" data-anim-delay="400">
                <div className="box-order">3</div>
                <div className="box-content">
                  <span>
                    <b>{translate('public-site')}</b> <br /> {translate('public-autopromo')}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="btn-wrapper text-center">
            <NavLink to={PUBLIC.REGISTER} className="btn btn-default">
              {translate('public-lets-go')}
            </NavLink>
          </div>
        </div>
      </section>

      <MainValuesSection />
    </Layout>
  );
};

export default WhiteBrand;

import { Fragment } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import Card from 'components/card';
import Important from 'components/icons/important';

import './index.scss';

const InfoDetail = (props) => {
  const { formatMessage } = useIntl();
  const statsDetall = useSelector((state) => state.stats.statsDetall);
  return (
    <div className="infoDetall-content">
      <Card icon={<Important />} title={formatMessage({ id: 'notice-name' })} modal closeModal={props.closeModal}>
        <div className="detall">
          {Object.entries(statsDetall).map((object, index) => {
            return (
              <>
                {Array.isArray(object[1]) && object[1].length > 0 ? (
                  <span key={index}>
                    {Object.entries(object[1]).map((child, childIndex) => {
                      return (
                        <Fragment key={childIndex}>
                          <h1>LAST_BAN_REASON:</h1>
                          <p>{child[1].REASON}</p>
                        </Fragment>
                      );
                    })}
                  </span>
                ) : (
                  <span key={index}>
                    <h1>{object[0]}:</h1>
                    <p>{object[1]}</p>
                  </span>
                )}
              </>
            );
          })}
        </div>
      </Card>
    </div>
  );
};

export default InfoDetail;

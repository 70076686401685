import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import translate from 'i18n/translate';

import Card from 'components/card';
import Button from 'components/button';

import IconData from 'components/icons/data';

import UserService from 'shared/user-service';

import { getLang } from 'libs/cookies';
import useValidator from 'hooks/useValidator';

// style
import './index.scss';

const UpdatePassword = ({ oldPwd, setOldPwd, pwd, setPwd, repeatPwd, setRepeatPwd }) => {
  const intl = useIntl();
  const [validator, showValidationMessage] = useValidator({}, {}, getLang());
  const [submit, setSubmit] = useState(false);
  const [errorRepeatPwd, setErrorRepeatPwd] = useState(false);

  const [errorOldPassword, setErrorOldPassword] = useState(false);
  const [errorRequest, setErrorRequest] = useState(false);
  const [errorSamePassword, setErrorSamePassword] = useState(false);

  const [disableBtn, setDisableBtn] = useState(false);

  const cleanErrors = () => {
    setErrorRequest(false);
    setErrorOldPassword(false);
    setErrorSamePassword(false);
  };

  const changePasswordEvt = () => {
    setSubmit(true);
    cleanErrors();
    if (validator.allValid()) {
      setDisableBtn(true);
      //if (!/[0-9]/.test(pwd) || !/[A-Z]/.test(pwd)) setErrorPwdFormat(true);
      //else {
      //setErrorPwdFormat(false);
      UserService.updatePassword(pwd, oldPwd)
        .then((data) => {
          if (data.status === 'Failed') {
            if (data.message === 'changeOwnPassword Password cannot be the same as previous') setErrorSamePassword(true);
            if (data.message === 'Failed Update Password') setErrorRequest(true);
            if (data.message === 'changeOwnPassword KO - Old Password MUST be correct') setErrorOldPassword(true);
          } else {
            cleanErrors();
            toast.success(translate('herramientas-affiliate-change-password-succes'));
          }
        })
        .finally(() => setDisableBtn(false));
    } else showValidationMessage(true);
  };

  const handlePassword = (e) => {
    setPwd(e.target.value);
    setErrorRepeatPwd(repeatPwd !== e.target.value ? true : false);
  };

  const handleRepeatPass = (e) => {
    setRepeatPwd(e.target.value);
    setErrorRepeatPwd(pwd !== e.target.value ? true : false);
  };

  return (
    <Card icon={<IconData />} title={<FormattedMessage id="herramientas-affiliate-change-password" />}>
      <div className="banner-body">
        {errorRequest && (
          <div className="alert alert-danger" role="alert">
            {translate('herramientas-affiliate-change-password-error-request')}
          </div>
        )}
        {errorSamePassword && (
          <div className="alert alert-danger" role="alert">
            {translate('herramientas-affiliate-change-password-error-same')}
          </div>
        )}
        {errorOldPassword && (
          <div className="alert alert-danger" role="alert">
            {translate('herramientas-affiliate-error-request-old-password')}
          </div>
        )}
        <form className="banner-modal">
          <div className="form-group">
            <label>{translate('herramientas-affiliate-password-old')}</label>
            <input
              type="password"
              className={classNames('form-control', {
                'form-input-success': submit && validator.check(oldPwd, 'required|min:8'),
                'form-input-error': submit && !validator.check(oldPwd, 'required|min:8'),
              })}
              name="oldPwd"
              value={oldPwd}
              onChange={(e) => setOldPwd(e.target.value)}
            />
            {validator.message(intl.formatMessage({ id: 'herramientas-affiliate-password-old' }), oldPwd, 'required|min:8')}
          </div>
          <div className="form-group">
            <label>{translate('herramientas-affiliate-password-new')}</label>
            <input
              type="password"
              className={classNames('form-control', {
                'form-input-success': submit && validator.check(pwd, 'required|password'),
                'form-input-error': submit && !validator.check(pwd, 'required|password'),
              })}
              name="pwd"
              value={pwd}
              onChange={handlePassword}
            />
            {validator.message(intl.formatMessage({ id: 'herramientas-affiliate-password-new' }), pwd, 'required|password')}
          </div>
          <div className="form-group">
            <label>{translate('herramientas-affiliate-repeat-password')}</label>
            <input
              type="password"
              className={classNames('form-control', {
                'form-input-success': submit && validator.check(repeatPwd, 'required|password'),
                'form-input-error': submit && !validator.check(repeatPwd, 'required|password'),
              })}
              name="repeatPwd"
              value={repeatPwd}
              onChange={handleRepeatPass}
            />
            {errorRepeatPwd && <span className="srv-validation-message">{translate('administration-data-register-error-password')} </span>}
            {validator.message(intl.formatMessage({ id: 'herramientas-affiliate-repeat-password' }), repeatPwd, 'required|password')}
          </div>
        </form>
      </div>
      <div className="button-content">
        <Button rounded onClick={(e) => changePasswordEvt()} disabled={disableBtn}>
          {disableBtn ? <FontAwesomeIcon icon={faSpinner} spin transform="left-4" size="lg" /> : translate('administration-data-button')}
        </Button>
      </div>
    </Card>
  );
};

export default UpdatePassword;

import { useState } from 'react';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { FormattedMessage, useIntl } from 'react-intl';

import UserService from 'shared/user-service';
import AuthService from 'shared/auth-service';
import { useAuth } from 'contexts/auth';

// components
import Card from '../card';
import IconImportant from '../icons/important';
import Button from '../button';

//style
import './index.scss';

const Alert = (props) => {
  const { idContract, setIdContract } = useAuth();
  const { formatMessage } = useIntl();
  const [urlContract, setUrlContract] = useState('');
  const [showContract, setShowContract] = useState(false);

  const getContract = async () => {
    await UserService.getContractApi(idContract).then((data) => {
      if (data.status !== 'Failed') {
        const url = window.URL.createObjectURL(data);
        setUrlContract(url);
        setShowContract(true);
      }
    });
  };

  const acceptContract = async () => {
    await UserService.acceptContractApi(idContract).then((data) => {
      if (data.status !== 'Failed') {
        toast.success(<FormattedMessage id="global-success-operation" />);
        AuthService.checkUsertoken().then((res) => {
          if (res.message.id_contract > 0) setIdContract(res.message.id_contract);
          else setIdContract(0);
          // Check if there is another contract to show
          props.closeModal();
        });
      } else toast.error(formatMessage({ id: 'global-error-try-later' }));
    });
  };

  return (
    <>
      <div className="background-modal w-100 h-100 d-flex" />
      <div className="contract-modal w-100 h-100 d-flex">
        <Card icon={<IconImportant />} title={formatMessage({ id: 'notice-name' })} modal closeModal={props.closeModal}>
          <div className={classNames('text-content', { contract: showContract })}>
            {showContract && <iframe title="Contract" src={urlContract} frameBorder="0" />}
            {!showContract && (
              <div className="text-notice">
                <h1>{formatMessage({ id: 'notice-title' })}</h1>
                <p>{formatMessage({ id: 'notice-text-one' })}</p>
                <p>{formatMessage({ id: 'notice-text-two' })}</p>
              </div>
            )}
          </div>
          <div className="button-content">
            {showContract && (
              <Button rounded onClick={(e) => acceptContract()} violet>
                {formatMessage({ id: 'notice-button-acepted' })}
              </Button>
            )}
            {!showContract && (
              <Button rounded onClick={(e) => getContract()} superBlue>
                {formatMessage({ id: 'notice-button-contract' })}
              </Button>
            )}
          </div>
        </Card>
      </div>
    </>
  );
};

export default Alert;

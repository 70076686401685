import { useState } from 'react';
import { toast } from 'react-toastify';
import { Tabs, Tab } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import OlecamsUsersService from 'shared/olecams-users-service';

// components
import Card from 'components/card';
import Button from 'components/button';
import IconData from 'components/icons/data';

//style
import '../index.scss';

const UpdateCredits = ({ id, close }) => {
  const { formatMessage } = useIntl();
  const [credits, setCredits] = useState('');
  const [time, setTime] = useState('');
  const [errorParameter, setErrorParameter] = useState(false);
  const [errorRequest, setErrorRequest] = useState(false);
  const [errorCreditFormat, setErrorCreditFormat] = useState(false);
  const [errorTimeFormat, setErrorTimeFormat] = useState(false);

  const [loading, setLoading] = useState(false);

  const updateUserCredits = async () => {
    if (!/[0-9]/.test(credits)) setErrorCreditFormat(true);
    else {
      setLoading(true);
      OlecamsUsersService.adminUpdateCredits(id, credits)
        .then((data) => {
          if (data.status === 'Failed') {
            if (data.message === 'When some parameter is missing') setErrorParameter(true);
            if (data.message === 'Failed Update Credits') setErrorRequest(true);
          } else {
            toast.success(<FormattedMessage id="global-updatedcredits-success" />);
            close(true);
          }
        })
        .finally(() => setLoading(false));
    }
  };

  const updateUserTime = async () => {
    if (!/[0-9]/.test(time)) setErrorTimeFormat(true);
    else {
      setLoading(true);
      OlecamsUsersService.adminUpdateTime(id, time)
        .then((data) => {
          if (data.status === 'Failed') {
            if (data.message === 'When some parameter is missing') setErrorParameter(true);
            if (data.message === 'Failed Update Time') setErrorRequest(true);
          } else {
            toast.success(<FormattedMessage id="global-updated-time-success" />);
            close(true);
          }
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <div className="register-banner-modal">
      <Card icon={<IconData />} title={<FormattedMessage id="global-update-credits" />} modal closeModal={close}>
        <div className="banner-body">
          {errorParameter && (
            <div className="alert alert-danger" role="alert">
              <FormattedMessage id="herramientas-affiliate-error-request-parameter" />
            </div>
          )}
          {errorRequest && (
            <div className="alert alert-danger" role="alert">
              <FormattedMessage id="herramientas-olecams-user-updatecredits-error-request" />
            </div>
          )}
          {errorCreditFormat && (
            <div className="alert alert-danger" role="alert">
              <FormattedMessage id="herramientas-olecams-user-updatecredit-error-format" />
            </div>
          )}
          <Tabs defaultActiveKey="credits" id="uncontrolled-tab-example">
            <Tab eventKey="credits" title={formatMessage({ id: 'statistics-table-credit' })}>
              <form className="banner-modal my-4">
                <div className="form-group">
                  <label>
                    <FormattedMessage id="herramientas-olecams-user-text-modal-updatecredits" />
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    name="credits"
                    pattern="[0-9]{0,5}"
                    value={credits}
                    onChange={(e) => setCredits(e.target.value)}
                  />
                </div>
              </form>
              <div className="button-content">
                <Button rounded onClick={(e) => updateUserCredits()} disabled={loading}>
                  {loading ? (
                    <FontAwesomeIcon icon={faSpinner} spin transform="left-4" size="lg" />
                  ) : (
                    <FormattedMessage id="herramientas-banner-register-save" />
                  )}
                </Button>
                <Button rounded onClick={close} red disabled={loading}>
                  {loading ? (
                    <FontAwesomeIcon icon={faSpinner} spin transform="left-4" size="lg" />
                  ) : (
                    <FormattedMessage id="herramientas-banner-register-cancel" />
                  )}
                </Button>
              </div>
            </Tab>
            <Tab eventKey="time" title={formatMessage({ id: 'global-time' })}>
              <form className="banner-modal my-4">
                <div className="form-group">
                  <label>Tiempo (segundos) - Introducir 0 para quitarlos todos</label>
                  <input
                    type="number"
                    className="form-control"
                    name="time"
                    pattern="[0-9]{0,5}"
                    value={time}
                    onChange={(e) => setTime(e.target.value)}
                  />
                </div>
              </form>
              <div className="button-content">
                <Button rounded onClick={(e) => updateUserTime()} disabled={loading}>
                  {loading ? (
                    <FontAwesomeIcon icon={faSpinner} spin transform="left-4" size="lg" />
                  ) : (
                    <FormattedMessage id="herramientas-banner-register-save" />
                  )}
                </Button>
                <Button rounded onClick={close} red disabled={loading}>
                  {loading ? (
                    <FontAwesomeIcon icon={faSpinner} spin transform="left-4" size="lg" />
                  ) : (
                    <FormattedMessage id="herramientas-banner-register-cancel" />
                  )}
                </Button>
              </div>
            </Tab>
          </Tabs>
        </div>
      </Card>
    </div>
  );
};
export default UpdateCredits;

import { useEffect, useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import AsyncSelect from 'react-select/async';
import { Form, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

import { debounce } from 'libs/lodashAlt';

import AffiliatesService from 'shared/affiliates-service';

const AffiliatesSelector = ({ data, setData }) => {
  const [affiliatesTypeOption, setAffiliatesTypeOption] = useState('all');
  const { formatMessage } = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const affiliatesTypeOnChange = async (e) => {
    const { value } = e.target;
    setAffiliatesTypeOption(value);

    if (value === 'all') {
      setSelectedOption(null);
      setData({
        ...data,
        AFFILIATES: [],
      });
    }
  };

  const debouncedPromiseOptions = useCallback(
    debounce((inputValue, callback) => {
      promiseOptions(inputValue).then((options) => callback(options));
    }, 250),
    []
  );

  const promiseOptions = (inputValue) => {
    return new Promise((resolve) => {
      let filters = { search: inputValue };
      let results = [];
      setIsLoading(true);

      AffiliatesService.adminList({ page: 1, perPage: 20, filters })
        .then((responseData) => {
          if (responseData.status === 'Accepted') {
            responseData.message.data.map((item, index) =>
              results.push({
                id: item.id,
                label: item.id + ' - ' + item.contacto + ' (' + item.empresa + ' )',
                value: item.id,
              })
            );
            resolve(results);
          } else resolve([]);
        })
        .finally(() => setIsLoading(false));
    });
  };

  const onChange = (options) => {
    setSelectedOption(options);
    setData({
      ...data,
      AFFILIATES: options.map((_item) => _item.value),
    });
  };

  useEffect(() => {
    if (data.AFFILIATES && data.AFFILIATES.length > 0) {
      setAffiliatesTypeOption('custom');
      AffiliatesService.adminList({ page: 1, perPage: 999, filters: { ids: data.AFFILIATES } }).then((responseData) => {
        setSelectedOption(
          responseData.message.data.map((item) => {
            return {
              id: item.id,
              label: item.id + ' - ' + item.contacto + ' (' + item.empresa + ' )',
              value: item.id,
            };
          })
        );
      });
    }
  }, [data.AFFILIATES]);

  const LoadingMessage = (props) => {
    return (
      <div>
        <div {...props.innerProps} style={props.getStyles('loadingMessage', props)}>
          <FormattedMessage id="global-loading" />
        </div>
      </div>
    );
  };

  return (
    <>
      <Form.Group as={Row} className="mb-3" controlId="formType">
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id={`tooltip-top`}>
              Selecciona los afiliados a las que aplicará la promoción. La opción "Personalizado" aplicará sólo a aquellos afiliados que
              añadas en el campo inferior usando el buscador. Si no seleccionas ninguno se aplicará a todos{' '}
            </Tooltip>
          }>
          <Form.Label column sm="3" className="font-weight-bold">
            Afiliados <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
          </Form.Label>
        </OverlayTrigger>
        <Col sm="9">
          <select className="form-control" value={affiliatesTypeOption} onChange={affiliatesTypeOnChange}>
            <option value="all">Todos</option>
            <option value="custom">Personalizado</option>
          </select>
        </Col>
      </Form.Group>
      {affiliatesTypeOption !== 'all' && (
        <Form.Group as={Row} className="mb-3" controlId="formType">
          <Form.Label column sm="3" className="font-weight-bold"></Form.Label>
          <Col sm="9">
            <AsyncSelect
              styles={{
                control: (styles) => ({
                  ...styles,
                  borderColor: '#4b5056',
                  color: '#495057',
                  fontWeight: '400',
                  fontSize: '1rem',
                }),
              }}
              value={selectedOption}
              defaultValue={selectedOption}
              placeholder="Selecciona ..."
              loadOptions={debouncedPromiseOptions}
              onChange={onChange}
              isClearable
              isMulti
              closeMenuOnSelect={false}
              noOptionsMessage={() => formatMessage({ id: 'promo-no-results-filters' })}
              isLoading={isLoading}
              components={{ LoadingMessage }}
            />
          </Col>
        </Form.Group>
      )}
    </>
  );
};

export default AffiliatesSelector;

import classNames from 'classnames';

//components
import Close from '../icons/close';
//style
import './index.scss';

const Card = (props) => {
  return (
    <div className={classNames('card', { table: props.table })}>
      {props.header && (
        <div className="card-header">
          {props.icon}
          <h1>{props.title}</h1>
          {props.modal && (
            <span className="close-modal" onClick={(e) => props.closeModal()}>
              <Close color="#4B5056" />
            </span>
          )}
        </div>
      )}
      <div className="card-body">{props.children}</div>
    </div>
  );
};

Card.defaultProps = {
  header: true,
  modal: false,
  table: false,
};

export default Card;

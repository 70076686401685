import request from './api-service';

const getStats = (filters) => {
  return request({
    method: 'post',
    url: '/stats/all',
    data: { filters },
  });
};

const getStatsSummary = (filters) => {
  return request({
    method: 'post',
    url: '/stats/summary',
    data: { filters },
  });
};

const getStatsADSL = (filters) => {
  return request({
    method: 'post',
    url: '/stats/adsl',
    data: { filters },
  });
};

const getStatsVISA = (filters) => {
  return request({
    method: 'post',
    url: '/stats/visa',
    data: { filters },
  });
};

const getStatsSMS = (filters) => {
  return request({
    method: 'post',
    url: '/stats/sms',
    data: { filters },
  });
};

const getStatsMobileInt = (filters) => {
  return request({
    method: 'post',
    url: '/stats/mobileint',
    data: { filters },
  });
};

const VceStatsService = {
  getStats,
  getStatsSummary,
  getStatsADSL,
  getStatsVISA,
  getStatsSMS,
  getStatsMobileInt,
};

export default VceStatsService;

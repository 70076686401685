const initialState = {
  actAfiliados: {},
  afiliados: {},
};

const AffiliateReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ACT_AFILIADOS':
      return Object.assign({}, state, {
        actAfiliados: action.actAfiliados,
      });
    case 'SET_AFILIADOS':
      return Object.assign({}, state, {
        afiliados: action.afiliados,
      });
    default:
      return state;
  }
};
export default AffiliateReducer;

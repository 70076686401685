import { Card, Col, Row, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';

import translate from 'i18n/translate';

const FiltersForm = (props) => {
  const { contents, countries, categories, age_categories, chat_type } = props;
  return (
    <Card>
      <Card.Header>
        <FontAwesomeIcon icon={faFilter} className="mr-2" /> {translate('promo-config-filters-site')}
      </Card.Header>
      <Card.Body>
        <Form>
          {contents && Array.isArray(contents) && (
            <Form.Group as={Row} className="mb-3" controlId="formContent">
              <Form.Label column sm="3" className="font-weight-bold">
                {translate('global-contents')}
              </Form.Label>
              <Col sm="9">
                <Card className="p-2">
                  {/*<Row>
                        <Col sm="6">
                            <CustomButton rounded blue className="mb-3 w-100" onClick={e => this.props.handleCheckAll('contents', true)}>
                                <FontAwesomeIcon icon={faCheckSquare} className="mr-2" />{translate('global-all')}
                            </CustomButton>
                        </Col>
                        <Col sm="6">
                            <CustomButton rounded red className="mb-3 w-100" onClick={e => this.props.handleCheckAll('contents', false)}>
                                <FontAwesomeIcon icon={faSquare} className="mr-2" />{translate('global-clear')}
                            </CustomButton>
                        </Col>
                    </Row>*/}
                  <div className="">
                    {contents.map((item, index) => {
                      return (
                        <Form.Check
                          key={index}
                          type="radio"
                          inline
                          label={item.label}
                          name="groupContents"
                          id={item.value}
                          onChange={(e) => props.handleRadioOnChange(item, 'content')}
                          checked={item.checked}
                        />
                      );
                    })}
                  </div>
                </Card>
              </Col>
            </Form.Group>
          )}

          {chat_type && Array.isArray(chat_type) && (
            <Form.Group as={Row} className="mb-3" controlId="formChatType">
              <Form.Label column sm="3" className="font-weight-bold">
                {translate('promo-roomlist-chat-type')}
              </Form.Label>
              <Col sm="9">
                <Card className="p-2">
                  <div className="">
                    {chat_type.map((item, index) => {
                      return (
                        <Form.Check
                          key={index}
                          type="radio"
                          inline
                          label={item.label}
                          name="groupChatType"
                          id={item.value}
                          onChange={(e) => props.handleRadioOnChange(item, 'chat_type')}
                          checked={item.checked}
                        />
                      );
                    })}
                  </div>
                </Card>
              </Col>
            </Form.Group>
          )}

          {countries && Array.isArray(countries) && (
            <Form.Group as={Row} className="mb-3" controlId="formCountries">
              <Form.Label column sm="3" className="font-weight-bold">
                {translate('promo-models-countries')}
              </Form.Label>
              <Col sm="9">
                <Card className="p-2">
                  {/*<Row>
                        <Col sm="6">
                            <CustomButton rounded blue className="mb-3 w-100" onClick={e => this.props.handleCheckAll('countries', true)}>
                                <FontAwesomeIcon icon={faCheckSquare} className="mr-2" />{translate('global-all')}
                            </CustomButton>
                        </Col>
                        <Col sm="6">
                            <CustomButton rounded red className="mb-3 w-100" onClick={e => this.props.handleCheckAll('countries', false)}>
                                <FontAwesomeIcon icon={faSquare} className="mr-2" />{translate('global-clear')}
                            </CustomButton>
                        </Col>
                    </Row>*/}
                  <div className="">
                    {countries.map((item, index) => {
                      return (
                        <Form.Check
                          key={index}
                          type="radio"
                          inline
                          label={item.label}
                          name="groupCountries"
                          id={item.value}
                          onChange={(e) => props.handleRadioOnChange(item, 'countries')}
                          checked={item.checked}
                        />
                      );
                    })}
                  </div>
                </Card>
              </Col>
            </Form.Group>
          )}

          {categories && Array.isArray(categories) && (
            <Form.Group as={Row} className="mb-3" controlId="formCategories">
              <Form.Label column sm="3" className="font-weight-bold">
                {translate('global-categories')}
              </Form.Label>
              <Col sm="9">
                <Card className="p-2">
                  {/*<Row>
                        <Col sm="6">
                            <CustomButton blue rounded className="mb-3 w-100" onClick={e => this.props.handleCheckAll('categories', true)}>
                                <FontAwesomeIcon icon={faCheckSquare} className="mr-2" />{translate('global-all')}
                            </CustomButton>
                        </Col>
                        <Col sm="6">
                            <CustomButton red rounded className="mb-3 w-100" onClick={e => this.props.handleCheckAll('categories', false)}>
                                <FontAwesomeIcon icon={faSquare} className="mr-2" />{translate('global-clear')}
                            </CustomButton>
                        </Col>
                    </Row>*/}
                  <div className="">
                    {categories.map((item, index) => {
                      return (
                        <>
                          {item.show && (
                            <Form.Check
                              key={index}
                              type="radio"
                              inline
                              label={item.label}
                              name="groupCategories"
                              onChange={(e) => props.handleRadioOnChange(item, 'categories')}
                              id={item.value}
                              checked={item.checked}
                            />
                          )}
                        </>
                      );
                    })}
                  </div>
                </Card>
              </Col>
            </Form.Group>
          )}

          {age_categories && Array.isArray(age_categories) && (
            <Form.Group as={Row} className="mb-3" controlId="formAges">
              <Form.Label column sm="3" className="font-weight-bold">
                {translate('global-age-categories')}
              </Form.Label>
              <Col sm="9">
                <Card className="p-2">
                  {/*<Row>
                        <Col sm="6">
                            <CustomButton rounded blue className="mb-3 w-100" onClick={e => this.props.handleCheckAll('age_categories', true)}>
                                <FontAwesomeIcon icon={faCheckSquare} className="mr-2" />{translate('global-all')}
                            </CustomButton>
                        </Col>
                        <Col sm="6">
                            <CustomButton rounded red className="mb-3 w-100" onClick={e => this.props.handleCheckAll('age_categories', false)}>
                                <FontAwesomeIcon icon={faSquare} className="mr-2" />{translate('global-clear')}
                            </CustomButton>
                        </Col>
                    </Row>*/}
                  <div className="">
                    {age_categories.map((item, index) => {
                      return (
                        <Form.Check
                          key={index}
                          type="radio"
                          className="age-checks"
                          inline
                          label={item.label}
                          name="groupAges"
                          id={item.value}
                          checked={item.checked}
                          onChange={(e) => props.handleRadioOnChange(item, 'age_categories')}
                        />
                      );
                    })}
                  </div>
                </Card>
              </Col>
            </Form.Group>
          )}
        </Form>
      </Card.Body>
    </Card>
  );
};

export default FiltersForm;

import { useState } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';

import { NavLink } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

//translate
import translate from 'i18n/translate';
import { getLang } from 'libs/cookies';

import UserService from 'shared/user-service';

import useValidator from 'hooks/useValidator';

//componentd
import Button from 'components/button';
import LoginLayout from 'components/login-layout';

import IconDataOfAccess from 'components/icons/dataOfAcces';

const Recovery = () => {
  const intl = useIntl();
  const lang = getLang();
  const [validator, showValidationMessage] = useValidator({}, {}, lang);
  const [submit, setSubmit] = useState(false);
  const [email, setEmail] = useState('');
  const [errorEmail, setErrorEmail] = useState(null);
  const [sendMail, setSendMail] = useState(false);

  const sendMailEvt = (e) => {
    e.preventDefault();
    setSubmit(true);
    if (validator.allValid()) {
      if (!sendMail) {
        UserService.sendEmailPassword(email, process.env.REACT_APP_ENV).then((responseData) => {
          if (responseData.status !== 'Failed') {
            setErrorEmail(false);
            setSendMail(true);
          } else setErrorEmail(true);
        });
      }
    } else showValidationMessage(true);
  };

  const emailRules = 'required|min:5|max: 255';
  return (
    <LoginLayout>
      <div className="card-container">
        <div className="card">
          <div className="card-header d-flex justify-content-between">
            <div className="d-flex">
              <IconDataOfAccess />
              <h1>{translate('login-recovery-title')}</h1>
            </div>
            <NavLink to="/login" className="font-weight-bold back-button">
              <FontAwesomeIcon icon={faArrowLeft} transform="left-4" size="lg" />
              {translate('statistics-form-button-return')}
            </NavLink>
          </div>
          <div className="card-body">
            {errorEmail === true && (
              <div className="alert alert-danger" role="alert">
                {translate('login-recovery-email-error')}
              </div>
            )}
            {errorEmail === false && (
              <div className="alert alert-success" role="alert">
                {translate('login-recovery-email-succes')}
              </div>
            )}
            <form onSubmit={sendMailEvt}>
              <div className="form-group">
                <label> {translate('login-recovery-email-label')}</label>
                <input
                  type="email"
                  name="email"
                  className={classNames('form-control', {
                    'form-input-success': submit && validator.check(email, emailRules),
                    'form-input-error': submit && !validator.check(email, emailRules),
                  })}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {validator.message(intl.formatMessage({ id: 'login-recovery-email-label' }), email, emailRules)}
              </div>
              <div className="form-group button-content">
                <Button rounded onClick={sendMailEvt} align="center" type="submit" disabled={sendMail}>
                  {translate(!sendMail ? 'global-send' : 'global-done')}
                </Button>
              </div>

              <div className="text-suport-content">
                <p>{translate('login-recovery-support')}</p>
                <a href="mailto:soporte@promocionesweb.com">soporte@promocionesweb.com</a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </LoginLayout>
  );
};

export default Recovery;

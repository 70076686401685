import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { load as loadRecaptcha } from 'recaptcha-v3';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

// Api Service
import ContactService from 'shared/contact-service';

import { PUBLIC } from 'shared/panel-links';

import Button from 'components/button';
import Loading from 'components/loading';

import Layout from 'components/public-layout';
import { getLang } from 'libs/cookies';
// Hooks
import useValidator from 'hooks/useValidator';

import './index.scss';

const Contact = () => {
  let loading = useSelector((state) => state.loading.loading);
  const [validator, showValidationMessage] = useValidator({}, {}, getLang());
  const { formatMessage } = useIntl();
  const [localLoading, setLocalLoading] = useState(false);
  const [contact, setContact] = useState({
    name: '',
    email: '',
    comments: '',
    communications: false,
    privacy: false,
  });

  const send = async () => {
    if (validator.allValid()) {
      // RECAPTCHA CHECKING
      //setRecaptchaValidating(true);
      //SEND CONTACT FORM
      setLocalLoading(true);

      // Recaptcha
      const recaptcha = await loadRecaptcha(process.env.REACT_APP_RECAPTCHA_KEY, { autoHideBadge: true });
      const tokenRecaptcha = await recaptcha.execute('action');
      // END Recaptcha

      ContactService.sendPublicContact(contact, tokenRecaptcha)
        .then((res) => {
          if (res.status === 'Accepted') {
            setContact({
              name: '',
              email: '',
              comments: '',
              privacy: false,
              communications: false,
            });
            toast.success(formatMessage({ id: 'support-contact-done' }));
          } else toast.error(formatMessage({ id: 'support-contact-error-send' }));
        })
        .finally(() => setLocalLoading(false));
    } else showValidationMessage(true);
  };

  const handleOnChange = (e) => {
    const { value, name } = e.target;
    setContact({
      ...contact,
      [name]: value,
    });
  };

  const handleChecked = (e) => {
    if (e.target.name === 'privacy') {
      setContact({
        ...contact,
        privacy: contact.privacy !== false ? false : true,
      });
    } else {
      setContact({
        ...contact,
        communications: contact.communications !== false ? false : true,
      });
    }
  };

  return (
    <Layout cssClass="contact">
      {loading && <Loading />}
      <section className="main">
        <div className="main-content">
          <div className="container"></div>
        </div>
      </section>
      <section>
        <div className="landing-sample white-bg container">
          <div className="content-wrapper">
            <div className="sample-header clearfix"></div>

            <div>
              <h2>
                <FormattedMessage id="public-contact-title" />
              </h2>
              <p className="subtitle">
                <FormattedMessage id="public-contact-subtitle" />
              </p>
              <form className="form-horizontal">
                <div className="form-group row required">
                  <label htmlFor="user" className="col-sm-2 col-form-label col-form-label-sm">
                    <FormattedMessage id="public-contact-form-contact" />
                  </label>
                  <div className="col-sm-10">
                    <input
                      type="text"
                      className={classNames(
                        'form-control' /*{
                        'error-input': validator.fieldValid('name'),
                      }*/
                      )}
                      name="name"
                      value={contact.name}
                      onChange={handleOnChange}
                    />
                    {validator.message(
                      formatMessage({ id: 'public-contact-form-contact' }).toLowerCase(),
                      contact.name,
                      'max: 255|required'
                    )}
                  </div>
                </div>
                <div className="form-group row required">
                  <label htmlFor="email" className="col-sm-2 col-form-label col-form-label-sm">
                    <FormattedMessage id="public-contact-form-email" />
                  </label>
                  <div className="col-sm-10">
                    <input
                      type="email"
                      name="email"
                      className={classNames('form-control' /*{ 'error-input': error.email }*/)}
                      value={contact.email}
                      onChange={handleOnChange}
                    />
                    {validator.message(
                      formatMessage({ id: 'public-contact-form-email' }).toLowerCase(),
                      contact.email,
                      'max: 255|required|email'
                    )}
                  </div>
                </div>
                <div className="form-group row required">
                  <label htmlFor="comments" className="col-sm-2 col-form-label col-form-label-sm">
                    <FormattedMessage id="public-contact-form-comments" />
                  </label>
                  <div className="col-sm-10">
                    <textarea
                      name="comments"
                      rows="3"
                      className={classNames('form-control' /*{ 'error-input': error.message }*/)}
                      value={contact.comments}
                      onChange={handleOnChange}></textarea>
                    {validator.message(
                      formatMessage({ id: 'public-contact-form-comments' }).toLowerCase(),
                      contact.comments,
                      'max: 512|required'
                    )}
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-sm-10 offset-sm-2">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="privacy"
                        id="privacy"
                        checked={contact.privacy !== false}
                        onChange={handleChecked}
                      />
                      <label className="form-check-label" htmlFor="privacy">
                        <FormattedMessage id="public-contact-privacy-policy1" />
                        <Link to={{ pathname: PUBLIC.PRIVACY_POLICY }} role="button" target="_blank" rel="noopener noreferrer">
                          {' '}
                          <FormattedMessage id="public-contact-privacy-policy2" />
                        </Link>
                      </label>
                      {validator.message(
                        formatMessage({ id: 'public-contact-privacy-policy1' }).toLowerCase(),
                        contact.privacy,
                        'required|accepted'
                      )}
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-sm-10 offset-sm-2">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="communications"
                        id="communications"
                        checked={contact.communications !== false}
                        onChange={handleChecked}
                      />
                      <label className="form-check-label" htmlFor="communications">
                        <FormattedMessage id="public-contact-commercial-comm" />
                      </label>
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-sm-10 offset-sm-2">
                    <span className="recaptcha-advice">
                      <FormattedMessage
                        id="global-aff-register-legal-conditions"
                        values={{
                          link1: (
                            <Link to={{ pathname: PUBLIC.PRIVACY_POLICY }} role="button" target="_blank" rel="noopener noreferrer">
                              {' '}
                              <FormattedMessage id="footer-privacy-policy" />{' '}
                            </Link>
                          ),
                          link2: (
                            <Link to={{ pathname: PUBLIC.LEGAL_ADVICE }} role="button" target="_blank" rel="noopener noreferrer">
                              {' '}
                              <FormattedMessage id="global-general-conditions" />{' '}
                            </Link>
                          ),
                        }}
                      />
                    </span>
                  </div>
                </div>

                <div className="btn-wrapper text-center">
                  <Button rounded onClick={(e) => send()} align="center" disabled={localLoading}>
                    {localLoading ? (
                      <FontAwesomeIcon icon={faSpinner} spin transform="left-4" size="lg" />
                    ) : (
                      <FormattedMessage id="public-lets-go" />
                    )}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Contact;

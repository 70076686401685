import { Accordion, Card, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList } from '@fortawesome/free-solid-svg-icons';

import translate from 'i18n/translate';

const StatsPasswords = ({ data, email }) => {
  return (
    <Accordion className="current-pwd-logs-accordion" defaultActiveKey="0">
      <Card className="mb-2">
        <Accordion.Toggle as={Card.Header} variant="link" eventKey="0" className="pointer">
          <FontAwesomeIcon icon={faClipboardList} className="mr-2" /> {translate('global-user-pwd-logs')} &nbsp; <strong>{email}</strong>{' '}
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            <Table responsive="sm" bordered hover>
              <thead>
                <tr>
                  <th>{translate('global-generated-date')}</th>
                  <th>{translate('global-used-date')}</th>
                  <th>Hash</th>
                  <th>{translate('global-ip-generated')}</th>
                  <th>{translate('global-ip-used')}</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => {
                  return (
                    <tr key={index} className={'alert-' + item.generated_date_type}>
                      <td>{item.generated_date}</td>
                      <td>{item.used_date}</td>
                      <td onClick={() => alert('http://miembros.videochaterotico.com/password-recovery.php?hash=' + item.hash)}>
                        {item.hash}
                      </td>
                      <td>{item.ip_generated}</td>
                      <td>{item.ip_used}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default StatsPasswords;

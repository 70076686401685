import { FormattedMessage } from 'react-intl';
import { NavLink, Link } from 'react-router-dom';
import classNames from 'classnames';

import { PUBLIC } from 'shared/panel-links';

//libs
import { setLang, getLang } from 'libs/cookies';

import './index.scss';

const Footer = () => {
  const changeLang = (lang) => {
    setLang(lang);
    window.location.reload();
  };
  return (
    <footer className="footer mt-auto">
      <div className="container pb-5">
        <NavLink exact activeClassName="active" to="/" role="button">
          <img src="/images/logo.svg" alt="logo" />
        </NavLink>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-12 align-self-center order-3 order-md-2">
            <div className="row">
              <div className="col-12 col-lg-12 copy-menu align-self-center">
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-12">
                    <div className="copy">
                      <span>&copy; {new Date().getFullYear()} OléTraffic</span>
                    </div>
                  </div>
                  <div className="col-12 col-md-12 col-lg-12 ">
                    <div className="menufooter">
                      <Link to={{ pathname: PUBLIC.LEGAL_ADVICE }} role="button" target="_blank" rel="noopener noreferrer">
                        {' '}
                        <FormattedMessage id="public-footer-legal" />{' '}
                      </Link>
                      <span className="sep d-none d-sm-inline-block"> | </span>
                      <Link to={{ pathname: PUBLIC.PRIVACY_POLICY }} role="button" target="_blank" rel="noopener noreferrer">
                        {' '}
                        <FormattedMessage id="public-footer-privacy-policy" />{' '}
                      </Link>
                      <span className="sep d-none d-sm-inline-block"> | </span>
                      <Link to={{ pathname: PUBLIC.COOKIE_POLICY }} role="button" target="_blank" rel="noopener noreferrer">
                        {' '}
                        <FormattedMessage id="public-footer-cookies-policy" />{' '}
                      </Link>
                      <span className="sep d-none d-sm-inline-block"> | </span>
                      <NavLink to={PUBLIC.LEGAL_RECORD} role="button" target="_blank" rel="noopener noreferrer">
                        {' '}
                        <FormattedMessage id="public-footer-text" />{' '}
                      </NavLink>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-12 footer-idiomas">
                    <span className={classNames('en', { active: getLang() === 'en' })} onClick={(e) => changeLang('en')}>
                      <a href="#en">
                        <FormattedMessage id="footer-lang-en" />
                      </a>
                    </span>
                    |
                    <span className={classNames('es', { active: getLang() === 'es' })} onClick={(e) => changeLang('es')}>
                      <a href="#es">
                        <FormattedMessage id="footer-lang-es" />
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;

import { Col, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

export default function CnameDns() {
  return (
    <Row>
      <Col className="text-center">
        <span className="font-weight-bold">
          <FormattedMessage id="herramientas-white-label-dns-domain" />
        </span>
        <br />
        <span>ns1.videochatprovider.com</span>
        <br />
        <span>ns2.videochatprovider.com</span>
      </Col>
      <Col className="text-center">
        <span className="font-weight-bold">
          <FormattedMessage id="herramientas-white-label-cname-subdomain" />
        </span>
        <br />
        <span>TTL: 14400</span>
        <br />
        <span>TYPE: CNAME</span>
        <br />
        <span>RECORD: front.videochatprovider.com</span>
      </Col>
    </Row>
  );
}

import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { Container, Col, Row, Card, Form, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft, faSave, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faTelegram, faSkype } from '@fortawesome/free-brands-svg-icons';

import AffiliatesService from 'shared/affiliates-service';

import Button from 'components/button';
import { Address, BrokerSelector, City, Contact, Company, Cif, Email, User, Province } from 'components/Affiliates/Form';

// #region Edit
export default function Edit({ id }) {
  const { goBack } = useHistory();
  const dispatch = useDispatch();

  const [data, setData] = useState(null);
  const [contactInfo, setContactInfo] = useState([
    {
      id_afiliado: id,
      contact_type: 'telegram',
      value: '',
    },
    {
      id_afiliado: id,
      contact_type: 'skype',
      value: '',
    },
  ]);

  useEffect(() => {
    dispatch({ type: 'SET_LOADING', loading: true });
    AffiliatesService.adminGet(id)
      .then((response) => {
        if (response.status === 'Accepted') {
          // Hacer una copia de response.message.data excluyendo contact_info
          const { contact_info, ...dataWithoutContactInfo } = response.message.data;
          setData(dataWithoutContactInfo);
          setContactInfo(response.message.data.contact_info);
        }

        if (response.status === 'Failed') {
          toast.error('El registro no existe');
          goBack();
        }
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
  }, [id, dispatch, goBack]);

  return (
    <Container className="mw-100 ha-discounts-new">
      <Row>
        <Col sm="12" className="mb-2">
          <Button rounded onClick={goBack}>
            <FontAwesomeIcon icon={faArrowCircleLeft} className="mr-2" />
            <FormattedMessage id="statistics-form-button-return" />
          </Button>
        </Col>
        <Col sm="12">
          <Card className="mb-2">
            <Card.Header>Editar {id}</Card.Header>
            <Card.Body>{data && <MainForm data={data} contactInfo={contactInfo} />}</Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

// #region MainForm
const MainForm = ({ data: _data, contactInfo }) => {
  const dispatch = useDispatch();
  let loading = useSelector((state) => state.loading.loading);
  const [data, setData] = useState(_data);
  const [isBroker, setIsBroker] = useState(_data.id_broker > 0 ? true : false);
  const [telegram, setTelegram] = useState('');
  const [skype, setSkype] = useState('');

  useEffect(() => {
    if (contactInfo && contactInfo.length > 0) {
      // Filtrar datos válidos
      const filteredData = contactInfo.filter((item) => item.contact_type && item.value);

      // Establecer valores iniciales para telegram y skype si existen
      const telegramItem = filteredData.find((item) => item.contact_type === 'telegram');
      if (telegramItem) setTelegram(telegramItem.value);

      const skypeItem = filteredData.find((item) => item.contact_type === 'skype');
      if (skypeItem) setSkype(skypeItem.value);
    }
  }, [contactInfo]);

  const onChange = (e) => {
    const { name, value, type, checked } = e.target;
    let fieldValue = value;

    if (type === 'checkbox') fieldValue = checked ? 1 : 0;

    setData({
      ...data,
      [name]: fieldValue,
    });
  };

  const brokerChange = () => {
    setIsBroker(!isBroker);
    setData({ ...data, id_broker: null });
  };

  const submit = (e) => {
    e.preventDefault();
    dispatch({ type: 'SET_LOADING', loading: true });

    const contact_info = [
      {
        id_afiliado: data.id,
        contact_type: 'telegram',
        value: telegram,
      },
      {
        id_afiliado: data.id,
        contact_type: 'skype',
        value: skype,
      },
    ];

    AffiliatesService.adminUpdate({ data, contact_info })
      .then((response) => {
        response.status === 'Failed' && toast.error(<FormattedMessage id="global-error-try-later" />);
        if (response.status === 'Accepted') {
          toast.success(<FormattedMessage id="global-success-operation" />);
        }
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
  };

  return (
    <form onSubmit={submit}>
      <Row>
        <Col xl="6">
          <Company label={<FormattedMessage id="administration-data-company" />} value={data.empresa} onChange={onChange} />
        </Col>
        <Col xl="6">
          <Cif label={<FormattedMessage id="administration-data-cif" />} value={data.cif} onChange={onChange} />
        </Col>
      </Row>
      <Row>
        <Col xl="6">
          <Contact label={<FormattedMessage id="administration-data-contact" />} value={data.contacto} onChange={onChange} />
        </Col>
        <Col xl="6">
          <Email label="Email" value={data.email} onChange={onChange} />
        </Col>
      </Row>
      <Row>
        <Col xl="6">
          <User label={<FormattedMessage id="global-user" />} value={data.usuario} onChange={onChange} />
        </Col>
        <Col xl="6">
          <Address label={<FormattedMessage id="administration-data-residence" />} value={data.domicilio} onChange={onChange} />
        </Col>
      </Row>
      <Row>
        <Col xl="6">
          <City label={<FormattedMessage id="administration-data-population" />} value={data.poblacion} onChange={onChange} />
        </Col>
        <Col xl="6">
          <Province label={<FormattedMessage id="administration-data-province" />} value={data.provincia} onChange={onChange} />
        </Col>
      </Row>
      <hr />
      <Row>
        <Col xl="6">
          <Form.Group as={Row} className="mb-3">
            <label className="font-weight-bold form-label col-form-label col-sm-6">
              <FontAwesomeIcon icon={faTelegram} className="mr-1" />
              Telegram
            </label>
            <Col sm="6">
              <InputGroup>
                <InputGroup.Text style={{ fontSize: '14px' }}>@</InputGroup.Text>
                <Form.Control
                  value={telegram}
                  name="telegram"
                  placeholder=""
                  onChange={(e) => setTelegram(e.target.value)}
                  minLength="3"
                  maxLength="36"
                />
              </InputGroup>
            </Col>
          </Form.Group>
        </Col>
        <Col xl="6">
          <Form.Group as={Row} className="mb-3">
            <label className="font-weight-bold form-label col-form-label col-sm-6">
              <FontAwesomeIcon icon={faSkype} className="mr-1" />
              Skype
            </label>
            <Col sm="6">
              <input
                className="form-control"
                type="text"
                name="skype"
                value={skype}
                minLength="3"
                maxLength="36"
                onChange={(e) => setSkype(e.target.value)}
              />
            </Col>
          </Form.Group>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col xl="6">
          <div>
            <label>Broker</label>
            <input className="ml-2" type="checkbox" checked={isBroker} onChange={brokerChange} style={{ width: '20px', height: '20px' }} />
          </div>

          {isBroker && <BrokerSelector brokerId={data.id_broker} handleChange={onChange} />}
        </Col>
      </Row>
      <hr />
      <Row>
        <Col className="text-center">
          <Button rounded type="submit" disabled={loading}>
            {loading ? (
              <FontAwesomeIcon icon={faSpinner} spin transform="left-4" size="lg" />
            ) : (
              <>
                <FontAwesomeIcon icon={faSave} className="mr-2" />
                <FormattedMessage id="administration.data-button" />
              </>
            )}
          </Button>
        </Col>
      </Row>
    </form>
  );
};

import { FormattedMessage } from 'react-intl';

import IconCheck from 'components/icons/check';
import IconUserDeleted from 'components/icons/user/UserDeleted';
import IconUserBlocked from 'components/icons/user/UserBlocked';

const InfoBottom = () => {
  return (
    <div className="info-content">
      <div className="info-one">
        <span className="margin">
          <IconCheck /> <FormattedMessage id="global-active-user" />
        </span>
        <span className="margin">
          <IconUserBlocked /> <FormattedMessage id="global-banned-status" />
        </span>
        <span className="margin">
          <IconUserDeleted /> <FormattedMessage id="global-deactivate-deleted-status" />
        </span>
      </div>
    </div>
  );
};

export default InfoBottom;

import { useSelector } from 'react-redux';

import translate from 'i18n/translate';

//libs
import { formatPrice } from 'libs/methods';

// icons
import IconMobile from 'components/icons/movil';
import IconDesktop from 'components/icons/computer';
import IconTablet from 'components/icons/tablet';

//style
import './index.scss';

const Table = (props) => {
  const { admin } = useSelector((state) => state.auth.auth);
  return (
    <>
      <table className="table phone-stats">
        <thead>
          <tr>
            {props.view === 'month' && (
              <>
                <th scope="col">{translate('statistics-table-day')}</th>
                <th scope="col">{translate('statistics-table-connection')}</th>
                <th scope="col">{translate('statistics-table-mid')}</th>
                <th scope="col">{translate('statistics-table-minut')}</th>
                <th scope="col">{translate('statistics-table-import')}</th>
              </>
            )}
            {props.view === 'day' && (
              <>
                <th scope="col">{translate('statistics-table-time')}</th>
                <th scope="col">{translate('statistics-table-stat')}</th>
                <th scope="col">{translate('statistics-table-minut')}</th>
                {parseInt(admin) === 1 && (
                  <>
                    <th scope="col">{translate('global-afno')}</th>
                    <th scope="col">{translate('statistics-form-id-afi')}</th>
                  </>
                )}
                <th scope="col">{translate('global-device')}</th>
                <th scope="col">{translate('statistics-table-country')}</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {props.view === 'month' &&
            props.stats.data.map((stats, index) => {
              let tipo = (index + 1) % 2 ? 'impar' : 'par';
              return (
                <tr key={index} className={tipo}>
                  <td className="hover" onClick={(e) => props.getStatsDay(stats.dia)}>
                    {stats.dia}
                  </td>
                  <td>{stats.totalConexiones}</td>
                  <td>{stats.mediaMinutos}</td>
                  <td>{stats.totalMinutos}</td>
                  <td>{formatPrice(stats.amount)}</td>
                </tr>
              );
            })}
          {props.view === 'day' &&
            props.stats.data.map((stats, index) => {
              let tipo = (index + 1) % 2 ? 'impar' : 'par';
              let src;
              if (stats.COUNTRY) src = `${process.env.PUBLIC_URL}/flags/${stats.COUNTRY.toLowerCase()}.png`;
              if (stats.status === 'ACTIVA') tipo += ' active';

              return (
                <tr key={index} className={tipo}>
                  <td>{stats.HORA}</td>
                  <td>{stats.status}</td>
                  <td>{stats.totalMinutos}</td>
                  {parseInt(admin) === 1 && (
                    <>
                      <td>{stats.AFNO}</td>
                      <td>{stats.ID_WEBMASTER}</td>
                    </>
                  )}
                  <td>{stats.DEVICE === 2 ? <IconMobile /> : stats.DEVICE === 0 ? <IconDesktop /> : <IconTablet />}</td>
                  <td>
                    {stats.COUNTRY && (
                      <span className="flag">
                        <img src={src} alt="flag" />
                        {stats.COUNTRY}
                      </span>
                    )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">{translate('statistics-table-connection')}</th>
            <th scope="col">{translate('statistics-table-mid')}</th>
            <th scope="col">{translate('statistics-table-minut')}</th>
            <th scope="col">{translate('statistics-table-import')}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{props.stats.data_summary.totalConexiones}</td>
            <td>{props.stats.data_summary.mediaMinutos}</td>
            <td>{props.stats.data_summary.totalMinutos}</td>
            <td>{formatPrice(props.stats.data_summary.amount)}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default Table;

import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

//components
import Button from 'components/button';
import SelectPaymentType from 'templates/statsOlecams/formComponents/SelectPaymentType';
import SelectStatus from 'templates/statsOlecams/formComponents/SelectStatus';

const Form = (props) => {
  const auth = useSelector((state) => state.auth.auth);
  const intl = useIntl();
  const history = useHistory();
  return (
    <form className="form-content" onSubmit={props.getStats}>
      <div className="form-detall-content user-detall">
        <div className="content-one">
          <div className="form-group">
            <label>
              <FormattedMessage id="statistics-form-id-afi" />
            </label>
            <input
              placeholder={intl.formatMessage({ id: 'statistics-form-select-all' })}
              type="text"
              name="affiliate_id"
              className="form-control"
              onChange={(e) => props.setAffiliateId(e.target.value)}
              value={props.affiliateId}
            />
          </div>
          <SelectPaymentType value={props.filters.payment_type} handleChangePay={(e) => props.setPaymentType(e.target.value)} />
          {parseInt(auth.admin) === 1 && (
            <SelectStatus value={props.filters.status} handleChange={(e) => props.setStatusPurchase(e.target.value)} />
          )}
        </div>
        <div className="buttons-content">
          <Button rounded onClick={(e) => history.goBack()} align="center" className="left">
            <FormattedMessage id="statistics-form-button-return" />
          </Button>
          <Button rounded onClick={props.getStats} align="center" type="submit">
            <FormattedMessage id="global-send" />
          </Button>
        </div>
      </div>
    </form>
  );
};

export default Form;

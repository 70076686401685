const Computer = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="22" height="22" viewBox="0 0 22 22">
      <path
        fill="#A2A9B0"
        id="prefix__a"
        d="M4 2h14v10H4V2zm14.333 12c1.013 0 1.834-.895 1.834-2V2c0-1.11-.825-2-1.834-2H3.667C2.649 0 1.833.89 1.833 2v10c0 1.105.821 2 1.834 2H0v2h22v-2h-3.667z"
      />
    </svg>
  );
};

export default Computer;

const Download = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
      <path
        fill="#FFF"
        d="M18 0c9.9 0 18 8.1 18 18s-8.1 18-18 18S0 27.9 0 18 8.1 0 18 0zM8.526 29.842h18.948v-3.6H8.526v3.6zm18.948-14.495h-5.921V5.874h-7.106v9.473h-5.92L18 24.821l9.474-9.474z"
      />
    </svg>
  );
};

export default Download;

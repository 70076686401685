const initialState = {
  demo_roomlist: '',
  roomlist_data: {},
  id_edit_roomlist: '',
};

const RoomlistReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ROOMLIST_DATA':
      return Object.assign({}, state, {
        roomlist_data: action.roomlist_data,
      });
    case 'SET_ID_EDIT_ROOMLIST':
      return Object.assign({}, state, {
        id_edit_roomlist: action.id_edit_roomlist,
      });
    case 'SET_ROOMLIST_DEMO':
      return Object.assign({}, state, {
        demo_roomlist: action.demo_roomlist,
      });
    default:
      return state;
  }
};

export default RoomlistReducer;

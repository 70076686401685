import { useState, useEffect, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Container, Col, Row, Card } from 'react-bootstrap';

import { dateFormat } from 'libs/methods';
import { STATUS, BATCH_OPTIONS, DATE_RANGE_OPTIONS } from 'libs/promotions.js';

import DiscountsService from 'shared/discounts-service';

import Table from './table.js';
import Form from './form';
import Modal from './modal';

import Pagination from 'components/pagination';

import './index.scss';

const Discounts = () => {
  const history = useHistory();
  const { formatMessage } = useIntl();
  //let loading = useSelector((state) => state.loading.loading);
  const [search, setSearch] = useState('');
  const [type, setType] = useState('');
  const [order, setOrder] = useState('desc');
  const [batch, setBatch] = useState(BATCH_OPTIONS.NO);
  const [status, setStatus] = useState('');
  const [onDate, setOnDate] = useState(DATE_RANGE_OPTIONS.ON_RANGE);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const [date, setDate] = useState();
  const [filters, setFilters] = useState({
    type: '',
    status: '',
    batch: BATCH_OPTIONS.NO,
    on_date: DATE_RANGE_OPTIONS.ON_RANGE,
    order: 'desc',
  });

  const [response, setResponse] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const [lastPage, setLastPage] = useState(1);

  const getList = useCallback(
    (filters) => {
      setResponse([]);
      dispatch({ type: 'SET_LOADING', loading: true });
      DiscountsService.adminList({ page, perPage, filters })
        .then((responseData) => {
          if (responseData.status === 'Accepted') {
            setResponse(responseData.message);
            setLastPage(responseData.message.last_page);
          }
        })
        .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
    },
    [dispatch, page, perPage]
  );

  useEffect(() => getList(filters), [getList, filters]);

  const refresh = () => getList(filters);

  const handleChangeDate = (date) => {
    setDate(date);
    setFilters({
      filters: {
        ...filters,
        date: date ? dateFormat(date) : '',
      },
    });
  };

  const sendSearch = (e) => {
    e.preventDefault();
    setFilters({
      ...filters,
      search: search,
      type: type,
      batch: batch,
      status: status,
      on_date: onDate,
      order: order,
    });

    setPage(1);
  };

  const editBtn = (id) => {
    history.push('/admintools/promociones/' + id);
  };

  /* PAGINATOR */
  const paginate = (e) => setPage(e.target.value);

  const handleChangeNumberOfColum = (e) => {
    setPerPage(e.target.value);
    setPage(1);
  };

  const nextPage = (_page) => {
    _page++;
    _page <= lastPage && setPage(_page);
  };

  const prevPage = (_page) => {
    _page--;
    _page > 0 && setPage(_page);
  };

  return (
    <Container className="mw-100 ha-discounts">
      <Row>
        <Col sm="12">
          <Card className="mb-2">
            <Card.Header>{formatMessage({ id: 'sidebar-setting-discounts' })}</Card.Header>
            <Card.Body>
              <Form
                date={date}
                search={search}
                type={type}
                setType={setType}
                batch={batch}
                setBatch={setBatch}
                order={order}
                setOrder={setOrder}
                status={status}
                setStatus={setStatus}
                onDate={onDate}
                setOnDate={setOnDate}
                filters={filters}
                handleChangeDate={handleChangeDate}
                setSearch={setSearch}
                sendSearch={sendSearch}
                setShowModal={setShowModal}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col sm="12">
          {response && response.data && response.total > 0 ? (
            <Table data={response.data} refresh={refresh} editBtn={editBtn} />
          ) : (
            <>No hay promociones</>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          {response && response.data && response.total > 0 && (
            <Pagination
              handleChangeNumberOfColum={handleChangeNumberOfColum}
              pager={{ page: page, items: perPage, pages: lastPage }}
              paginate={paginate}
              prevPage={prevPage}
              nextPage={nextPage}
              total={response.total}
            />
          )}
        </Col>
      </Row>
      {showModal && <Modal show={showModal} setShow={setShowModal} />}
    </Container>
  );
};

export default Discounts;

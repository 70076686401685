import request from './api-service';
const qs = require('qs');

const prefixUrl = '/promo/news';
const prefixAdminUrl = '/admin';

const get = (id) => {
  return request({
    method: 'get',
    url: `${prefixUrl}/get?id=${id}`,
  });
};

const list = ({ page, perPage, filters }) => {
  let params = { filters };
  return request({
    method: 'get',
    url: `${prefixUrl}/list?page=${page}&per_page=${perPage}`,
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    }, // ToDo why paramsSerializer need it?
  });
};

const adminGet = (id) => {
  return request({
    method: 'get',
    url: `${prefixAdminUrl}${prefixUrl}/get?id=${id}`,
  });
};

const adminList = ({ page, perPage, filters }) => {
  let params = { filters };
  return request({
    method: 'get',
    url: `${prefixAdminUrl}/${prefixUrl}/list?page=${page}&per_page=${perPage}`,
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    }, // ToDo why paramsSerializer need it?
  });
};

const adminSet = ({ data }) => {
  let formdata = new FormData();

  data.image && formdata.append('image', data.image);
  formdata.append('data[titulo]', data.titulo);
  formdata.append('data[texto]', data.texto);
  formdata.append('data[noticia_activa]', data.noticia_activa);

  return request({
    method: 'post',
    url: `${prefixAdminUrl}${prefixUrl}/set`,
    data: formdata,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

const adminUpdate = (noticia, oldNoticia) => {
  let formdata = new FormData();
  noticia.image && formdata.append('image', noticia.image);
  if (noticia.titulo !== oldNoticia.titulo) formdata.append('data[titulo]', noticia.titulo);
  if (noticia.texto !== oldNoticia.texto) formdata.append('data[texto]', noticia.texto);
  if (noticia.noticia_activa !== oldNoticia.noticia_activa) formdata.append('data[noticia_activa]', noticia.noticia_activa);

  formdata.append('data[id]', oldNoticia.id);

  return request({
    method: 'post',
    url: `${prefixAdminUrl}/${prefixUrl}/update`,
    data: formdata,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

const adminDelete = (id) =>
  request({
    method: 'delete',
    url: `${prefixAdminUrl}/${prefixUrl}/delete`,
    data: { id },
  });

const NewsService = {
  get,
  list,
  adminGet,
  adminList,
  adminSet,
  adminUpdate,
  adminDelete,
};

export default NewsService;

const PaidTwo = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="22" height="22" viewBox="0 0 22 22">
      <defs>
        <path
          id="paida"
          d="M0 0h18v12H0V0zm9 3c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3zM4 2c0 1.105-.895 2-2 2v4c1.105 0 2 .895 2 2h10c0-1.105.895-2 2-2V4c-1.105 0-2-.895-2-2H4z"
        />
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(2 6)">
        <mask id="paidb" fill="#fff">
          <use xlinkHref="#paida" />
        </mask>
        <use fill="#000" fillRule="nonzero" xlinkHref="#paida" />
        <g fill="#4B5056" mask="url(#paidb)">
          <path d="M0 0H22V22H0z" transform="translate(-2 -6)" />
        </g>
      </g>
    </svg>
  );
};

export default PaidTwo;

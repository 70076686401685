import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGift, faUserTag } from '@fortawesome/free-solid-svg-icons';

//libs
import { formatPrice } from 'libs/methods';

import FormatAfno from '../FormatAfno';
import { getProvider } from '../payment_provider';

import IconCard from 'components/icons/tarjeta';
import IconPayPal from 'components/icons/payPal';
import IconFirstBuy from 'components/icons/firstBuy';
import IconRefactor from 'components/icons/refactor';
import IconMobile from 'components/icons/movil';
import IconComputer from 'components/icons/computer';
import IconTablet from 'components/icons/tablet';
import IconPlus from 'components/icons/plus';
import IconUserDetail from 'components/icons/userDetail';
import IconTransfer from 'components/icons/transfer';
import IconLocalPay from 'components/icons/localpay';
import IconCrypto from 'components/icons/crypto';
import IconUserDesktop from 'components/icons/userDesktop';
import IconAccountStar from 'components/icons/account-star';
import IconIncognito from 'components/icons/incognito';
import ModalAttempt from 'templates/statsOlecams/statsDay/ModalAttempt';

//style
import '../index.scss';

const Table = ({ stats, getStatDetall, getUserInfo, date }) => {
  const auth = useSelector((state) => state.auth.auth);
  const [showModalAttempt, setShowModalAttempt] = useState(false);
  const [item, setItem] = useState(null);
  const openAttemptModal = ({ e, item }) => {
    e.preventDefault();
    setItem(item);
    setShowModalAttempt(true);
  };

  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">
              <FormattedMessage id="statistics-table-time" />
            </th>
            <th scope="col">
              <FormattedMessage id="statistics-table-pay" />
            </th>
            {parseInt(auth.admin) === 1 && (
              <th scope="col">
                <FormattedMessage id="statistics-provider" />
              </th>
            )}
            <th scope="col">
              <FormattedMessage id="statistics-table-buy" />
            </th>
            {parseInt(auth.admin) === 1 && <th scope="col">Intentos</th>}
            <th scope="col">
              <FormattedMessage id="statistics-table-article" />
            </th>
            <th scope="col">
              <FormattedMessage id="statistics-table-import" />
            </th>
            {parseInt(auth.admin) === 1 && (
              <th scope="col">
                <FormattedMessage id="sidebar-setting-discounts" />
              </th>
            )}
            {parseInt(auth.admin) === 1 && <th scope="col">Modo</th>}
            <th scope="col">Marca Blanca</th>
            {parseInt(auth.admin) === 1 && (
              <>
                <th scope="col">
                  <FormattedMessage id="statistics-table-user" />
                </th>
                <th scope="col">Fidelity</th>
              </>
            )}
            <th scope="col">
              <FormattedMessage id="statistics-table-afiliate" />
            </th>
            <th scope="col">
              <FormattedMessage id="statistics-table-country" />
            </th>
            <th scope="col">
              <FormattedMessage id="statistics-table-font" />
            </th>
          </tr>
        </thead>
        <tbody>
          {stats.results.map((stats, index) => {
            let tipo = (index + 1) % 2 ? 'impar' : 'par';
            let src = stats.COUNTRY ? `${process.env.PUBLIC_URL}/flags/${stats.COUNTRY.toLowerCase()}.png` : '';

            return (
              <tr key={index} className={tipo}>
                <td>{stats.TIME_ONLY}</td>
                <td>
                  <PaymentIcon type={stats.PAYMENT_TYPE} />
                </td>
                {parseInt(auth.admin) === 1 && <td>{getProvider(stats.PROVIDER)}</td>}
                <td>{stats.FIRST_PURCHASE === 1 ? <IconFirstBuy /> : <IconRefactor />}</td>
                {parseInt(auth.admin) === 1 && (
                  <td>
                    {stats.ATTEMPT > 1 ? (
                      <span className="cursorPointer" onClick={(e) => openAttemptModal({ e, item: stats })}>
                        {stats.ATTEMPT}
                      </span>
                    ) : (
                      <></>
                    )}
                  </td>
                )}
                <td>{stats.ARTICLE_NAME}</td>
                <td>{formatPrice(stats.AMOUNT)}</td>
                {parseInt(auth.admin) === 1 && (
                  <td>
                    <TypePromotion item={stats} />
                  </td>
                )}
                {parseInt(auth.admin) === 1 && <td>{stats.SITEMODE}</td>}
                <td>{stats.NAME_MB}</td>
                {parseInt(auth.admin) === 1 && <td>{stats.EMAIL}</td>}
                {parseInt(auth.admin) === 1 && <td>{<FidelityIcon type={stats.FIDELITY} />}</td>}
                <td>
                  <FormatAfno afno={stats.AFNO} isAdmin={parseInt(auth.admin) === 1} />
                </td>
                <td>
                  <span className="flag">
                    {stats.COUNTRY ? (
                      <>
                        <img src={src} alt="flag" /> {stats.COUNTRY.toUpperCase()}
                      </>
                    ) : (
                      <> -- </>
                    )}
                  </span>
                </td>
                <td>
                  {stats.DEVICE === 2 ? <IconMobile /> : stats.DEVICE === 0 ? <IconComputer /> : <IconTablet />}
                  {parseInt(auth.admin) === 1 && (
                    <>
                      <span title="Detalles de la compra" data-toggle="tooltip">
                        <IconPlus getStatDetall={getStatDetall} id={stats.ID} provider={stats.PROVIDER} />
                      </span>
                      <span title="Compras del usuario" data-toggle="tooltip">
                        <IconUserDetail email={stats.EMAIL} />
                      </span>
                      <span title="Detalles del usuario" data-toggle="tooltip" onClick={() => getUserInfo(stats.PROFILE_ID)}>
                        <IconUserDesktop color="#A2A9B0" />
                      </span>
                    </>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <th scope="col" colSpan="3">
              <FormattedMessage id="statistics-table-total" />
            </th>
            {parseInt(auth.admin) === 1 && (
              <>
                <th></th>
                <th></th>
              </>
            )}
            <th>{stats.summary.count}</th>
            <th>{formatPrice(stats.summary.amount)}</th>
            <th>{parseInt(auth.admin) === 1 && stats.summary.discounts}</th>
            <th></th>
            {parseInt(auth.admin) === 1 && (
              <>
                <th />
                <th />
              </>
            )}
            <th />
            <th />
            <th />
            {parseInt(auth.admin) === 1 && (
              <>
                <th />
                <th />
              </>
            )}
          </tr>
        </tfoot>
      </table>
      {showModalAttempt && <ModalAttempt show={showModalAttempt} setShow={setShowModalAttempt} item={item} date={date} />}
    </>
  );
};

const PaymentIcon = ({ type }) => {
  switch (type) {
    case 'CC':
    case 'PV':
    default:
      return <IconCard />;
    case 'PP':
      return <IconPayPal />;
    case 'BANK':
      return <IconTransfer />;
    case 'LOCAL_PAYMENT':
      return <IconLocalPay />;
    case 'CRYPTO':
      return <IconCrypto />;
  }
};

const TypePromotion = ({ item }) => {
  const results = [];
  if (item.DISCOUNT_ID) {
    results.push(
      <Link to={'/admintools/promociones/' + item.DISCOUNT_ID} role="button" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon size="lg" icon={faGift} />
      </Link>
    );
  }

  if (!item.DISCOUNT_ID && item.ANONYMOUS_FIDELITY) {
    results.push(
      <IconIncognito
        style={{
          width: '1.5rem',
          height: '1.5rem',
        }}
      />
    );
  }

  if (!item.DISCOUNT_ID && item.REGISTERED_FIDELITY_LEVEL) {
    results.push(
      <>
        <IconAccountStar
          style={{
            width: '1.5rem',
            height: '1.5rem',
          }}
        />
        {item.REGISTERED_FIDELITY_LEVEL}%
      </>
    );
  }

  return <>{results}</>;
};

const FidelityIcon = ({ type = null }) => {
  switch (type) {
    case 1:
      return <FontAwesomeIcon size="lg" icon={faUserTag} />;
    default:
      return <></>;
  }
};

export default Table;

import Card from '../card';

//style
import './index.scss';

const ShowImg = (props) => {
  return (
    <div className="img-modal">
      <Card modal closeModal={props.closeModal}>
        <div className="img-content">
          <img src={props.img} alt="banner" />
        </div>
      </Card>
    </div>
  );
};

export default ShowImg;

import { FormattedMessage } from 'react-intl';
import { Form, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faPlus } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router';
import { /*DatePicker,*/ registerLocale } from 'react-datepicker';

import { CREDIT_GIFT } from 'shared/constants';

import translate from 'i18n/translate';
import es from 'date-fns/locale/es';

import Button from 'components/button';

const CreditGiftForm = ({
  search,
  setSearch,
  sendSearch,
  type,
  setType,
  status,
  setStatus,
  onDate,
  setOnDate,
  batch,
  setBatch,
  setShowModal,
}) => {
  const history = useHistory();
  registerLocale('es', es);

  return (
    <Row className="justify-content-between">
      <Col>
        <form className={`search-wrapper`} onSubmit={(e) => sendSearch(e)}>
          <div className="first-filters d-flex align-center">
            <Form.Group className="mr-3" controlId="formSearch">
              <Form.Label>{translate('global-search')}</Form.Label>
              <Form.Control value={search} name="search" placeholder="" onChange={(e) => setSearch(e.target.value)} />
            </Form.Group>
            <Type type={type} setType={setType} />
            <Status status={status} setStatus={setStatus} />
            <DateRange onDate={onDate} setOnDate={setOnDate} />
            <BatchOption batch={batch} setBatch={setBatch} />
          </div>
          <div className="buttons-content">
            <Button rounded onClick={sendSearch}>
              <FontAwesomeIcon icon={faSearch} className="mr-2" /> <FormattedMessage id="global-send" />
            </Button>
          </div>
        </form>
      </Col>
      <Col className="text-right align-self-center">
        <Button blue rounded className="mb-2" onClick={() => history.push('/admintools/regalo-creditos/nuevo')}>
          <FontAwesomeIcon icon={faPlus} className="mr-2" />
          Nuevo regalo de créditos
        </Button>
        <br />

        <Button rounded className="mb-2" onClick={() => setShowModal(true)}>
          <FontAwesomeIcon icon={faPlus} className="mr-2" />
          Regalos por lotes
        </Button>
      </Col>
    </Row>
  );
};

export default CreditGiftForm;

const Type = ({ type, setType }) => {
  return (
    <Form.Group className="mr-3" controlId="formStatus">
      <Form.Label>{translate('global-type')}</Form.Label>
      <select className="form-control" onChange={(e) => setType(e.target.value)} name="type" defaultValue={type}>
        <option value={CREDIT_GIFT.TYPES.ALL}>Todas</option>
        <option value={CREDIT_GIFT.TYPES.GLOBAL}>Global</option>
        <FormattedMessage id="promo-campaign-name">{(msg) => <option value={CREDIT_GIFT.TYPES.CAMPAIGN}>{msg}</option>}</FormattedMessage>
        <option value={CREDIT_GIFT.TYPES.INDIVIDUAL}>Individual</option>
      </select>
    </Form.Group>
  );
};

const BatchOption = ({ batch, setBatch }) => {
  return (
    <Form.Group className="mr-3" controlId="formStatus">
      <Form.Label>Automatizados</Form.Label>
      <select className="form-control" onChange={(e) => setBatch(e.target.value)} name="type" defaultValue={batch}>
        <option value={CREDIT_GIFT.BATCH_OPTIONS.ALL}>Todas</option>
        <option value={CREDIT_GIFT.BATCH_OPTIONS.YES}>Si</option>
        <option value={CREDIT_GIFT.BATCH_OPTIONS.NO}>No</option>
      </select>
    </Form.Group>
  );
};

const Status = ({ status, setStatus }) => {
  return (
    <Form.Group className="mr-3" controlId="formStatus">
      <Form.Label>Status</Form.Label>
      <select className="form-control" onChange={(e) => setStatus(e.target.value)} name="status" defaultValue={status}>
        <option value="">Todas</option>
        <option value={CREDIT_GIFT.STATUS.INACTIVE}>Inactivas</option>
        <option value={CREDIT_GIFT.STATUS.ACTIVE}>Activas</option>
        <option value={CREDIT_GIFT.STATUS.PREPRODUCTION}>Preproducción</option>
      </select>
    </Form.Group>
  );
};

const DateRange = ({ onDate, setOnDate }) => {
  return (
    <Form.Group className="mr-3" controlId="formOnDateRange">
      <Form.Label>Rango de fechas</Form.Label>
      <select className="form-control" onChange={(e) => setOnDate(e.target.value)} name="onDateRange" defaultValue={onDate}>
        <option value={CREDIT_GIFT.DATE_RANGE_OPTIONS.ALL}>Todas</option>
        <option value={CREDIT_GIFT.DATE_RANGE_OPTIONS.ON_RANGE}>En fecha actual</option>
        <option value={CREDIT_GIFT.DATE_RANGE_OPTIONS.FINISHED}>Terminadas</option>
        <option value={CREDIT_GIFT.DATE_RANGE_OPTIONS.UPCOMING}>Próximas</option>
      </select>
    </Form.Group>
  );
};

import { Card, Col, Row, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPalette } from '@fortawesome/free-solid-svg-icons';

import { debounce } from 'libs/lodashAlt';

import translate from 'i18n/translate';

import IframeSize from './design/iframe_size';
import ThumbSize from './design/thumb_size';
import ColorPickers from './design/color_pickers';

const DesignForm = (props) => {
  const debouncedOnChangeColor = debounce((name, value) => {
    props.handleOnChange({ target: { name, value } }, 'designForm');
  }, 150);

  const { roomlist_limit, roomlist_limit_count, thumb_size } = props.designForm;

  return (
    <Card className="design-form-card">
      <Card.Header>
        <FontAwesomeIcon icon={faPalette} className="mr-2" /> {translate('promo-site-design')}
      </Card.Header>
      <Card.Body>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
          }}>
          <ColorPickers
            designForm={props.designForm}
            defaultDesigns={props.defaultDesigns}
            debouncedOnChangeColor={debouncedOnChangeColor}
          />

          <hr />
          <IframeSize designForm={props.designForm} handleOnChange={props.handleOnChange} />
          <Form.Group as={Row} className="mb-3" controlId="formSizes">
            <Form.Label column sm="6" className="font-weight-bold">
              {translate('global-thumb-size')}
            </Form.Label>
            <Col sm="6">
              <ThumbSize thumb_size={thumb_size} handleOnChange={props.handleOnChange} />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" controlId="formRoomlistLimit">
            <Form.Label column sm="6" className="font-weight-bold">
              {translate('promo-rooms-limit')}
            </Form.Label>
            <Col sm="3">
              <Form.Check
                type="checkbox"
                inline
                name="roomlist_limit"
                value={roomlist_limit}
                onChange={(e) => props.handleCheckbox(e)}
                checked={roomlist_limit}
              />
            </Col>
            {roomlist_limit && (
              <Col sm="3">
                <Form.Control
                  as="input"
                  type="number"
                  className=""
                  placeholder="24"
                  name="roomlist_limit_count"
                  value={roomlist_limit_count}
                  onChange={(e) => props.handleOnChange(e, 'designForm')}
                  min="1"
                  max="200"
                />
              </Col>
            )}
          </Form.Group>

          {/*this.props.languages_list && Array.isArray(this.props.languages_list) &&
                <Form.Group as={Row} className="mb-3" controlId="formLangs">
                    <Form.Label column sm="3" className="font-weight-bold">{translate('global-language')}</Form.Label>
                    <Col sm="9">
                    <Card className="p-2">
                        <div className="">
                            {
                                this.props.languages_list.map((item, index) => {
                                    return (
                                        <Form.Check
                                            key={index}
                                            type="radio"
                                            className="lang-checks"
                                            inline
                                            label={item.label}
                                            name="groupLangs"
                                            id={item.value}
                                            checked={item.checked}
                                            onChange={e => this.props.handleRadioOnChange(item)}
                                        />
                                    )
                                })
                            }
                        </div>
                    </Card>
                    </Col>
                </Form.Group>*/}
        </Form>
      </Card.Body>
    </Card>
  );
};

export default DesignForm;

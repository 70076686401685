import request from './api-service';
const qs = require('qs');

const prefixUrl = '/affiliate';
const prefixAdminUrl = '/admin';

const changePassword = (token, pwd, old_pwd) => {
  return request({
    method: 'post',
    url: `${prefixUrl}/changepassword`,
    data: { token, pwd, old_pwd },
  });
};

const getLogins = (affiliate_id) => {
  return request({
    method: 'post',
    url: `${prefixUrl}/logins`,
    data: { affiliate_id },
  });
};

const getLoginsGroups = (affiliate_id) => {
  return request({
    method: 'post',
    url: `${prefixUrl}/loginsgroups`,
    data: { affiliate_id },
  });
};

const adminGet = (id) => {
  return request({
    method: 'get',
    url: `${prefixAdminUrl}${prefixUrl}/get?id=${id}`,
  });
};

const adminList = ({ page, perPage, filters }) => {
  let params = { filters };

  return request({
    method: 'get',
    url: `${prefixAdminUrl}${prefixUrl}/list?page=${page}&per_page=${perPage}`,
    params: params,
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'arrayFormat' }),
  });
};

const adminUpdate = ({ data, contact_info }) => {
  return request({
    method: 'post',
    url: `${prefixAdminUrl}${prefixUrl}/update`,
    data: { data, contact_info },
  });
};

const adminSimulate = (id) => {
  return request({
    method: 'post',
    url: `${prefixAdminUrl}${prefixUrl}/simulate`,
    data: { id },
  });
};

const adminChangePassword = (id, pwd) => {
  return request({
    method: 'post',
    url: `${prefixAdminUrl}${prefixUrl}/changepassword`,
    data: { id, pwd },
  });
};

const adminAffiliateOcRequest = (id) => {
  return request({
    method: 'get',
    url: `${prefixAdminUrl}${prefixUrl}/ocrequests?id=${id}`,
  });
};

const adminAffiliateResetOcRequest = (id) => {
  return request({
    method: 'delete',
    url: `${prefixAdminUrl}${prefixUrl}/ocrequests?id=${id}`,
  });
};

const AffiliatesService = {
  changePassword,
  getLogins,
  getLoginsGroups,
  adminGet,
  adminList,
  adminUpdate,
  adminSimulate,
  adminChangePassword,
  adminAffiliateOcRequest,
  adminAffiliateResetOcRequest,
};

export default AffiliatesService;

const initialState = {
  stats: {},
  statsDay: {},
  statsRegister: {},
  statsUser: {},
  statsDetall: {},
  statsOCFilters: {},
  statsVce: {},
  countries: [],
  summary: {},
  summaryVce: {},
};

const StatsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_STATS':
      return Object.assign({}, state, {
        stats: action.stats !== undefined ? action.stats : {},
        statsDay: action.statsDay !== undefined ? action.statsDay : {},
        statsRegister: action.statsRegister !== undefined ? action.statsRegister : {},
        statsUser: action.statsUser !== undefined ? action.statsUser : {},
        statsVce: action.statsVce !== undefined ? action.statsVce : {},
        statsOCFilters: action.statsOCFilters !== undefined ? action.statsOCFilters : {},
      });
    case 'SET_STATS_DETALL':
      return Object.assign({}, state, {
        statsDetall: action.statsDetall,
      });
    case 'SET_STATS_OLECAMS_COUNTRY':
      return Object.assign({}, state, {
        countries: action.countries,
      });
    case 'SET_STATS_SUMMARY':
      return Object.assign({}, state, {
        summary: action.summary,
      });
    case 'SET_STATS_VCE_SUMMARY':
      return Object.assign({}, state, {
        summaryVce: action.summaryVce,
      });
    default:
      return state;
  }
};

export default StatsReducer;

import { useState } from 'react';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage } from 'react-intl';

import Card from 'components/card';
import Button from 'components/button';

import IconData from 'components/icons/data';

import UserService from 'shared/user-service';

// style
import './index.scss';

const BankData = ({ data }) => {
  const [bankData, setBankData] = useState(data);
  const [disableBtn, setDisableBtn] = useState(false);

  const handleOnChange = (e) => {
    const { value, name } = e.target;
    setBankData({
      ...bankData,
      [name]: value,
    });
  };

  const saveData = () => {
    setDisableBtn(true);
    UserService.updateProfile({ data: bankData }).then((response) => {
      if (response.status === 'Accepted') toast.success(<FormattedMessage id="administration-success-data" />);
      else toast.error(<FormattedMessage id="global-error-try-later" />);

      setDisableBtn(false);
    });
  };

  return (
    <Card icon={<IconData />} title={<FormattedMessage id="administration-data-bank" />}>
      <form>
        <div className="form-group">
          <label>
            <FormattedMessage id="administration-data-name" />
          </label>
          <input type="text" className="form-control" name="bnombre" value={bankData.bnombre} onChange={handleOnChange} />
        </div>
        <div className="form-group">
          <label>
            <FormattedMessage id="administration-data-address" />
          </label>
          <input type="text" className="form-control" name="bdireccion" value={bankData.bdireccion} onChange={handleOnChange} />
        </div>
        <div className="form-group">
          <label>
            <FormattedMessage id="administration-data-account" />
          </label>
          <input type="text" className="form-control" name="bcuenta" value={bankData.bcuenta} onChange={handleOnChange} />
        </div>
        <div className="form-group">
          <label>
            <FormattedMessage id="administration-data-swift" />
          </label>
          <input type="text" className="form-control" name="swift" value={bankData.swift} onChange={handleOnChange} />
        </div>
        <div className="button-content">
          <Button rounded onClick={saveData} align="center" disabled={disableBtn}>
            {disableBtn ? (
              <FontAwesomeIcon icon={faSpinner} spin transform="left-4" size="lg" />
            ) : (
              <FormattedMessage id="administration-data-button" />
            )}
          </Button>
        </div>
      </form>
    </Card>
  );
};

export default BankData;

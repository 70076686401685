const Sopport = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="22" height="22" viewBox="0 0 22 22">
      <path
        fill={props.color}
        d="M16.72 11.84c.35-.85.54-1.76.54-2.76 0-.72-.11-1.41-.3-2.05-.65.15-1.33.23-2.04.23A9.07 9.07 0 0 1 7.5 3.42 9.21 9.21 0 0 1 2.77 8.3c-.04.25-.04.52-.04.78A7.27 7.27 0 0 0 10 16.35c1.05 0 2.06-.23 2.97-.64.57 1.09.83 1.63.81 1.63-1.64.55-2.91.82-3.78.82-2.42 0-4.73-.95-6.43-2.66a9.03 9.03 0 0 1-2.24-3.69H0V7.26h1.09a9.09 9.09 0 0 1 15.33-4.6 8.991 8.991 0 0 1 2.47 4.6H20v4.55h-.06l-3.56 3.27-5.3-.6v-1.67h4.83l.81-.97zM7.27 8.85c.3 0 .59.12.8.34a1.136 1.136 0 0 1 0 1.6c-.21.21-.5.33-.8.33-.63 0-1.14-.5-1.14-1.13 0-.63.51-1.14 1.14-1.14zm5.45 0c.63 0 1.13.51 1.13 1.14 0 .63-.5 1.13-1.13 1.13-.63 0-1.14-.5-1.14-1.13 0-.63.51-1.14 1.14-1.14z"
      />
    </svg>
  );
};

export default Sopport;

import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Card, Col, Form, Row, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo, faTimes } from '@fortawesome/free-solid-svg-icons';

import { getLang } from 'libs/cookies';
import translate from 'i18n/translate';
import Button from 'components/button';
// Hooks
import useValidator from 'hooks/useValidator';

import VisaUserService from 'shared/visa-user-service';

import './index.scss';

const RecoverUserModal = ({ show, setShow }) => {
  const dispatch = useDispatch();
  const [validator, showValidationMessage] = useValidator({}, {}, getLang());

  //const [modalShow, setModalShow] = useState(false);
  const [email, setEmail] = useState('');

  const handleOnChange = (e) => {
    const { value } = e.target;
    setEmail(value);
  };

  const recoverBtn = (e) => {
    if (validator.allValid()) {
      dispatch({ type: 'SET_LOADING', loading: true });
      VisaUserService.adminRecoverUser(email)
        .then((responseData) => {
          if (responseData.status === 'Accepted') {
            toast.success(translate('promo-load-data-success'));
            setShow(false);
          } else toast.error(translate('global-update-failed'));
        })
        .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
    } else showValidationMessage(true);
  };

  return (
    <Modal show={show} onHide={() => setShow(false)} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{translate('global-recover-user-desc')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card className="mb-2">
          <Card.Body>
            <Form id="form" className="form">
              <Row>
                <Col>
                  <Form.Group as={Row} className="mb-3 required" controlId="formEmail">
                    <Form.Label column sm="3" className="font-weight-bold">
                      Email
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={email} name="email" placeholder="email@email.com" onChange={handleOnChange} />
                      {validator.message('email', email, 'max: 255|required|email')}
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button orange rounded onClick={recoverBtn}>
          <FontAwesomeIcon icon={faUndo} className="mr-2" /> {translate('global-user-recover')}
        </Button>
        <Button red rounded onClick={() => setShow(false)}>
          <FontAwesomeIcon icon={faTimes} className="mr-2" /> {translate('herramientas-banner-register-cancel')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RecoverUserModal;

const Important = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="22" height="22" viewBox="0 0 22 16">
      <path
        fill="#4b5056"
        id="prefix__a"
        d="M18 8V3h2v5h-2zm0 4v-2h2v2h-2zM8 9c2.67 0 8 1.34 8 4v3H0v-3c0-2.66 5.33-4 8-4zm0-9c2.21 0 4 1.79 4 4s-1.79 4-4 4-4-1.79-4-4 1.79-4 4-4zm0 10.9c-2.97 0-6.1 1.46-6.1 2.1v1.1h12.2V13c0-.64-3.13-2.1-6.1-2.1zm0-9c-1.16 0-2.1.94-2.1 2.1 0 1.16.94 2.1 2.1 2.1 1.16 0 2.1-.94 2.1-2.1 0-1.16-.94-2.1-2.1-2.1z"
      />
    </svg>
  );
};

export default Important;

import { FormattedMessage, useIntl } from 'react-intl';
import { Card, Col, Form, Row, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

import Cname from 'components/whiteLabels/cnameDns';

//import { fab } from '@fortawesome/free-brands-svg-icons';
//import { faClipboardList } from "@fortawesome/free-solid-svg-icons";

const Domains = ({ domain, setDomain, validator }) => {
  const { formatMessage } = useIntl();
  //const saveDomain = (e) => setDomain(e.target.value);
  const onKeyDownHandler = (e) => saveDomainOnList(e.target.value);
  //const saveDomainOnBlur = (e) => (domain !== '') && saveDomainOnList(e.target.value);

  const saveDomainOnList = (domain) => {
    setDomain(domain);
  };

  return (
    <Card className="mb-2">
      <Card.Header>
        <FormattedMessage id="global-domain" />s
      </Card.Header>
      <Card.Body>
        <Form.Group as={Row} className="mb-3 required" controlId="formDomains">
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id={`tooltip-top`}>
                <FormattedMessage id="herramientas-white-label-domains-explanation" />
              </Tooltip>
            }>
            <Form.Label column sm="3" className="font-weight-bold">
              <span className="text">
                <FormattedMessage id="global-domain" />
              </span>
              <span className="help-icon float-right">
                <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
              </span>
            </Form.Label>
          </OverlayTrigger>
          <Col sm="9" className="d-flex">
            <Form.Control value={domain} name="domains" placeholder="www.domain.com" onChange={onKeyDownHandler} />
          </Col>
        </Form.Group>
        <Row>
          <Col>{validator.message(formatMessage({ id: 'global-domain' }), domain, 'max: 255|domain|required')}</Col>
        </Row>

        <hr />
        <Row>
          <Col sm="12" className="text-center">
            <p>
              <FormattedMessage id="herramientas-white-label-add-domains-subdomains" />
            </p>
            <Row>
              <Col className="text-center">
                <span className="font-weight-bold">
                  <FormattedMessage id="herramientas-white-label-example-domain" />
                </span>
                <br />
                <div className="underlined orange font-weight-bold">www.example.com</div>
              </Col>
              <Col className="text-center">
                <span className="font-weight-bold">
                  <FormattedMessage id="herramientas-white-label-example-subdomain" />
                </span>
                <br />
                <div className="underlined orange font-weight-bold">name.example.com</div>
              </Col>
              <br />
            </Row>

            <br />

            <p>
              <FormattedMessage id="herramientas-white-label-remember-cname" />
            </p>
            <Cname />
          </Col>
        </Row>
      </Card.Body>
      {/*<Card.Footer><small><FormattedMessage id='promo-domains-as-needed'/></small></Card.Footer>*/}
    </Card>
  );
};

export default Domains;

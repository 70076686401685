import { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import translate from 'i18n/translate';

import { getLang } from 'libs/cookies';

import ContactService from 'shared/contact-service';

import useValidator from 'hooks/useValidator';

import Layout from 'components/layout';
import Card from 'components/card';
import Loading from 'components/loading';
import Button from 'components/button';

import IconSupport from 'components/icons/sopport';
import IconWarning from 'components/icons/warning';
import IconChat from 'components/icons/chat';
import IconMail from 'components/icons/mail';
import IconOpenDesple from 'components/icons/opendesple';

//style
import './index.scss';

const Support = () => {
  let loading = useSelector((state) => state.loading.loading);
  const intl = useIntl();
  const [validator, showValidationMessage] = useValidator({}, {}, getLang());

  const [showFormContact, setShowFormContact] = useState(false);
  const [showChatOnline, setShowChatOnline] = useState(false);
  const [contact, setContact] = useState({});
  const [localLoading, setLocalLoading] = useState(false);

  const handleOnChange = (e) => {
    const { value, name } = e.target;
    setContact({
      ...contact,
      [name]: value,
    });
  };

  const showChat = () => {
    var tidioScript = document.createElement('script');
    tidioScript.src = '//code.tidio.co/266vpgce72o5wg58jvjo1cpzuypwspgg.js';
    document.tidioChatLang = getLang();
    document.body.appendChild(tidioScript);
  };

  const changeShowFormContact = () => {
    setShowFormContact(!showFormContact);
  };

  const send = () => {
    if (validator.allValid()) {
      setLocalLoading(true);
      ContactService.sendContact(contact)
        .then((data) => {
          if (data.status !== 'Failed') {
            setContact({
              name: '',
              email: '',
              subject: '',
              message: '',
            });

            changeShowFormContact();
            toast.success(translate('support-contact-done'));
          } else toast.error(translate('support-contact-error-send'));
        })
        .finally(() => setLocalLoading(true));
    } else showValidationMessage(true);
  };

  if (loading) return <Loading />;

  return (
    <Layout>
      {!showFormContact && (
        <Card icon={<IconSupport color="#4b5056" />} title={translate('support-name')}>
          <div className="support-content">
            <div className="title-text-content">
              <p>
                <IconWarning color="#4B5056" /> {translate('support-warning')}
              </p>
            </div>
            <div className="support-card-content">
              <Card icon={<IconOpenDesple color="#4b5056" />} title={translate('support-service-title')}>
                <div className="text-content d-flex flex-column justify-content-between">
                  <p>{translate('support-service-text')}</p>
                  <p></p>
                  <IconChat />
                  <Button rounded align="center" onClick={(e) => setShowChatOnline(true)}>
                    {translate('support-service-button')}
                  </Button>
                </div>
              </Card>
              {/*
                            <Card icon={<OpenDesple color="#4b5056" />} title={translate('support-asist-title')}>
                                <div className="text-content d-flex flex-column justify-content-between">
                                    <p>{translate('support-asist-text-one')}</p>
                                    <h2>+34 931 205 636</h2>
                                    <p className="footer-text">{translate('support-asist-text-two')}</p>
                                </div>
                            </Card>
                            */}
              <Card icon={<IconOpenDesple color="#4b5056" />} title={translate('support-attent-title')}>
                <div className="text-content d-flex flex-column justify-content-between">
                  <p>{translate('support-attent-text')}</p>
                  <IconMail />
                  <Button rounded onClick={(e) => changeShowFormContact()} align="center">
                    {translate('support-attent-button')}
                  </Button>
                </div>
              </Card>
            </div>
          </div>
        </Card>
      )}
      {showFormContact && (
        <div className="form-content-content">
          <Card
            icon={<IconSupport color="#4b5056" />}
            title={translate('support-contact-title')}
            modal
            closeModal={(e) => changeShowFormContact()}>
            <form>
              <div className="form-group">
                <label>{translate('support-contact-name')}</label>
                <input type="text" className="form-control" name="name" value={contact.name} onChange={handleOnChange} />
              </div>
              {validator.message(intl.formatMessage({ id: 'global-name' }).toLowerCase(), contact.name, 'max: 150|required')}
              <div className="form-group">
                <label>{translate('support-contact-mail')}</label>
                <input type="email" className="form-control" name="email" value={contact.email} onChange={handleOnChange} />
              </div>
              {validator.message(
                intl.formatMessage({ id: 'support-contact-mail' }).toLowerCase(),
                contact.email,
                'max: 150|email|required'
              )}
              <div className="form-group">
                <label>{translate('support-contact-subject')}</label>
                <input type="text" className="form-control" name="subject" value={contact.subject} onChange={handleOnChange} />
                {validator.message(
                  intl.formatMessage({ id: 'support-contact-subject' }).toLowerCase(),
                  contact.subject,
                  'max: 200|required'
                )}
              </div>
              <div className="form-group">
                <label>{translate('support-contact-message')}</label>
                <textarea className="form-control" name="message" value={contact.message} onChange={handleOnChange} />
              </div>
              {validator.message(
                intl.formatMessage({ id: 'support-contact-message' }).toLowerCase(),
                contact.message,
                'max: 1000|required'
              )}
              <div className="button-content">
                <Button rounded onClick={(e) => send()} align="center" disabled={localLoading}>
                  {localLoading ? (
                    <FontAwesomeIcon icon={faSpinner} spin transform="left-4" size="lg" />
                  ) : (
                    translate('support-contact-button')
                  )}
                </Button>
              </div>
            </form>
          </Card>
        </div>
      )}
      {showChatOnline && showChat()}
    </Layout>
  );
};

export default Support;

import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

//libs
import { getLang } from 'libs/cookies';
import { isEmpty } from 'libs/lodashAlt';

import BannerService from 'shared/banner-service';

//Component
import Card from 'components/card';
import Warning from 'components/icons/warning';
import Loading from 'components/loading';

import SummaryIcon from 'components/icons/resum';
import CloseIcon from 'components/icons/closeDesple';
import ListBanners from './listBanners';

// style
import './index.scss';

const BannersHome = () => {
  const categories = Object.freeze({
    BANNER: 'banner',
    SPECIAL: 'special',
  });

  let loading = useSelector((state) => state.loading.loading);
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [view, setView] = useState(categories.BANNER);
  const [banner, setBanner] = useState('');
  const [special, setSpecial] = useState('');
  const [filters, setFilters] = useState({
    search: '',
    type: '',
    lang: getLang(),
  });

  const getData = useCallback(() => {
    dispatch({ type: 'SET_LOADING', loading: true });
    BannerService.listByCategory({ page: 1, perPage: 99, filters })
      .then((data) => {
        if (data.status !== 'Failed') setData(data.message);
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
  }, [dispatch, filters]);

  useEffect(() => {
    getData();
  }, [getData, filters]);

  const showBanner = (type) => setBanner(type === banner ? '' : type);
  const showSpecial = (type) => setSpecial(type === special ? '' : type);

  if (loading) return <Loading />;

  return (
    <>
      {!isEmpty(data) && (
        <div className="banners-content">
          <div className="bannerr-cont-top">
            <div className="text-content">
              <p>
                <Warning color="#4B5056" /> {<FormattedMessage id="promo-banner-text-title" />}
              </p>
            </div>
          </div>
          <Card header={false}>
            <div className="view-content">
              <span onClick={() => setView(categories.BANNER)} className={classNames({ active: view === categories.BANNER })}>
                <FormattedMessage id="promo-banner-banner-name" />
              </span>
              <span onClick={() => setView(categories.SPECIAL)} className={classNames({ active: view === categories.SPECIAL })}>
                <FormattedMessage id="promo-banner-especial-name" />
              </span>
            </div>
            {data && view === categories.BANNER && (
              <div className="banners-card-content">
                {Object.keys(data).map((key) => {
                  // Verify if items match condition
                  if (data[key].some((item) => item.ID_CATEGORY === 0)) {
                    return (
                      <div key={key} className={classNames('banner-card', { active: banner === key })}>
                        <div className="banner-card-header" onClick={() => showBanner(key)}>
                          <SummaryIcon /> <h1>{key}</h1>
                          <CloseIcon color="#4B5056" />
                        </div>
                        <div className={classNames('banner-card-body', { active: banner === key })}>
                          <div className="banner">
                            <ListBanners data={data[key]} category={0} />
                          </div>
                        </div>
                      </div>
                    );
                  }
                  return null;
                })}
              </div>
            )}

            {data && view === categories.SPECIAL && (
              <div className="banners-card-content">
                {Object.keys(data).map((key) => {
                  // Verify if items match condition
                  if (data[key].some((item) => item.ID_CATEGORY === 1)) {
                    return (
                      <div key={key} className={classNames('banner-card', { active: special === key })}>
                        <div className="banner-card-header" onClick={() => showSpecial(key)}>
                          <SummaryIcon /> <h1>{key}</h1>
                          <CloseIcon color="#4B5056" />
                        </div>
                        <div className={classNames('banner-card-body', { active: special === key })}>
                          <div className="banner">
                            <ListBanners data={data[key]} category={1} />
                          </div>
                        </div>
                      </div>
                    );
                  }
                  return null;
                })}
              </div>
            )}
          </Card>
        </div>
      )}
    </>
  );
};

export default BannersHome;

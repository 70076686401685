import { Card, Container, Col, Row, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose, faUsers, faUser, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import ArticlesService from 'shared/articles-service';

import Button from 'components/button';
import { toast } from 'react-toastify';

const ModalAsign = ({ id, show, setShow }) => {
  return (
    <Container className="mw-100 ha-discounts">
      <Row>
        <Col sm="12">
          <Card className="mb-2">
            <Card.Header>
              <FormattedMessage id="sidebar-setting-discounts" />
            </Card.Header>
            <Card.Body>
              <ModalForm id={id} show={show} setShow={setShow} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

const ModalForm = ({ id, show, setShow }) => {
  const dispatch = useDispatch();

  const asign = (range) => {
    dispatch({ type: 'SET_LOADING', loading: true });
    ArticlesService.adminAsign({ id, range })
      .then((data) => {
        if (data.status === 'Accepted') {
          toast.success('Se asignó correctamente');
          setShow(false);
        } else toast.error('Ocurrió un error');
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
  };

  return (
    <Modal size="xl" show={show} onHide={() => setShow(false)} centered>
      <Modal.Header closeButton>
        <Modal.Title>Actualizar Afiliados</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
          <span className="font-weight-bold">Elige qué quieres actualizar.</span>
          <br />
          Todos los afiliados o solamente los que tienen tarifa estándar (sin personalizar).
        </div>
        <br />
        <p className="font-italic">Nota: si se trata de un nuevo artículo, elige Todos</p>
      </Modal.Body>
      <Modal.Footer>
        <Row className="w-100 text-center">
          <Col className="">
            <Button onClick={() => asign('')}>
              <FontAwesomeIcon icon={faUsers} className="mr-2" /> Todos
            </Button>
          </Col>
          <Col>
            <Button onClick={() => asign('standard')}>
              <FontAwesomeIcon icon={faUser} className="mr-2" />
              Estándar
            </Button>
          </Col>
          <Col>
            <Button red onClick={() => setShow(false)}>
              <FontAwesomeIcon icon={faWindowClose} className="mr-2" /> <FormattedMessage id="herramientas-banner-register-cancel" />
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalAsign;

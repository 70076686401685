import { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import OcStatsService from 'shared/oc-stats-service';

const SelectCountry = (props) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { value, handleChangeCountry } = props;
  const countries = useSelector((state) => state.stats.countries);

  const getData = useCallback(() => {
    OcStatsService.getCountries().then((responseData) => {
      dispatch({ type: 'SET_STATS_OLECAMS_COUNTRY', countries: responseData.message });
    });
  }, [dispatch]);

  useEffect(getData, [getData]);
  return (
    <>
      {countries && (
        <div className="form-group">
          <label>
            <FormattedMessage id="statistics-form-select-country" />
          </label>
          <select className="form-control" name="country" onChange={handleChangeCountry} value={value}>
            <option value="">{formatMessage({ id: 'statistics-form-select-all' })}</option>
            {countries.map((country, index) => {
              return (
                <option key={index} value={country.code}>
                  {country.name}
                </option>
              );
            })}
          </select>
        </div>
      )}
    </>
  );
};

export default SelectCountry;

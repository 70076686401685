import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { setLang, getLang } from 'libs/cookies';

import { PUBLIC } from 'shared/panel-links';

import './index.scss';

const Footer = () => {
  const changeLang = (lang) => {
    setLang(lang);
    window.location.reload();
  };

  return (
    <footer id="footer">
      <div className="footer-links">
        <p>&copy; {new Date().getFullYear()} OléTraffic</p>
        <span className="text-off-app">
          |
          <Link to={{ pathname: PUBLIC.LEGAL_ADVICE }} role="button" target="_blank" rel="noopener noreferrer" className="legal">
            {' '}
            <FormattedMessage id="footer-legal" />{' '}
          </Link>
          |
          <Link to={{ pathname: PUBLIC.PRIVACY_POLICY }} role="button" target="_blank" rel="noopener noreferrer" className="legal">
            {' '}
            <FormattedMessage id="footer-privacy-policy" />{' '}
          </Link>
          |
          <Link to={{ pathname: PUBLIC.COOKIE_POLICY }} role="button" target="_blank" rel="noopener noreferrer" className="legal">
            {' '}
            <FormattedMessage id="footer-cookie" />{' '}
          </Link>
        </span>
      </div>
      <div className="footer-idiomas">
        <span className={classNames('en', { active: getLang() === 'en' })} onClick={(e) => changeLang('en')}>
          <FormattedMessage id="footer-lang-en" />
        </span>
        |
        <span className={classNames('es', { active: getLang() === 'es' })} onClick={(e) => changeLang('es')}>
          <FormattedMessage id="footer-lang-es" />
        </span>
      </div>
    </footer>
  );
};

export default Footer;

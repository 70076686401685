import { useState } from 'react';
import { Form } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

//translate
import { FormattedMessage, useIntl } from 'react-intl';

import NewsService from 'shared/news-service';

// components
import Card from 'components/card';
import Button from 'components/button';
import IconData from 'components/icons/data';

//style
import '../index.scss';

const Add = (props) => {
  const intl = useIntl();
  const [newData, setNewData] = useState({
    image: null,
    titulo: '',
    texto: '',
    noticia_activa: 0,
  });

  const [active, setActive] = useState(false);
  const [errorData, setErrorData] = useState(false);
  const [errorRequest, setErrorRequest] = useState(false);
  const [fileLabel, setFileLabel] = useState(<FormattedMessage id="herramientas-banner-input-file-select" />);

  const add = async () => {
    let data = newData;
    data.noticia_activa = active === true ? 1 : 0;
    if (data.titulo === '' || data.texto === '') setErrorData(true);
    else {
      NewsService.adminSet({ data }).then((responseData) => {
        if (responseData.status === 'Failed') setErrorRequest(true);
        else props.close(true);
      });
    }
  };

  const cancel = () => {
    if (window.confirm(intl.formatMessage({ id: 'global-sure-cancel-action' }))) props.close();
  };

  const handleOnChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'image') {
      setNewData({
        ...newData,
        [name]: files[0],
      });
      setFileLabel(files[0].name); // Breaks if file is webp
    } else {
      setNewData({
        ...newData,
        [name]: value,
      });
    }
  };

  const onChangeText = (content, delta, source, editor) => {
    setNewData({
      ...newData,
      texto: editor.getHTML(content),
    });
  };

  return (
    <div className="register-banner-modal">
      <Card icon={<IconData />} title={<FormattedMessage id="herramientas-news-name" />} modal closeModal={props.close}>
        <div className="banner-body">
          {errorData && (
            <div className="alert alert-danger" role="alert">
              <FormattedMessage id="herramientas-error-data" />
            </div>
          )}
          {errorRequest && (
            <div className="alert alert-danger" role="alert">
              <FormattedMessage id="herramientas-news-error-request" />
            </div>
          )}
          <form className="banner-modal">
            <div className="form-group">
              <label>
                <FormattedMessage id="herramientas-news-input-title" />
              </label>
              <input type="text" className="form-control" name="titulo" value={newData.titulo} onChange={handleOnChange} />
            </div>
            <div className="form-group">
              <label>
                <FormattedMessage id="herramientas-banner-input-file" />
              </label>
              <div className="custom-file">
                <input type="file" name="image" className="custom-file-input" id="customFileLang" lang="es" onChange={handleOnChange} />
                <label className="custom-file-label" htmlFor="customFileLang">
                  {fileLabel}
                </label>
              </div>
            </div>
            <div className="form-group">
              <label>
                <FormattedMessage id="herramientas-news-input-text" />
              </label>
              <ReactQuill name="texto" theme="snow" value={newData.texto} onChange={onChangeText} />
            </div>
            <div className="form-group">
              <label className="swith-label">
                <FormattedMessage id="herramientas-news-input-active" />
              </label>
              <Form.Check type="switch" id="active" checked={active} onChange={(e) => setActive(!active)} />
            </div>
          </form>
        </div>
        <div className="button-content">
          <Button rounded onClick={(e) => cancel()} red>
            <FormattedMessage id="herramientas-banner-register-cancel" />
          </Button>
          <Button rounded onClick={(e) => add()}>
            <FormattedMessage id="herramientas-banner-register-save" />
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default Add;

import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { getLang } from 'libs/cookies';

import Menu from './menu';
import Content from './content';
import Footer from './footer';

//style
import './index.scss';

const PublicLayout = (props) => {
  useEffect(() => {
    // Anything in here is fired on component mount.
    // TIDIO CHAT
    var tidioScript = document.createElement('script');
    tidioScript.src = '//code.tidio.co/266vpgce72o5wg58jvjo1cpzuypwspgg.js';
    document.tidioChatLang = getLang();
    document.body.appendChild(tidioScript);

    return () => {
      // Anything in here is fired on component unmount.
    };
  }, []);

  return (
    <div className="public-layout content d-flex flex-column min-vh-100 is-open container-fluid p-0">
      <ToastContainer autoClose={6000} />
      <Menu />
      <Content cssClass={props.cssClass}>{props.children}</Content>
      <Footer />
    </div>
  );
};

export default PublicLayout;

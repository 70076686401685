import { useState } from 'react';

//libs
import { devbugData } from 'libs/methods';
import { setDevBugCookie } from 'libs/cookies';

// components
import Card from '../card';
import Button from '../button';

//style
import './index.scss';

const DevbugModal = (props) => {
  const [apiUrl, setApiUrl] = useState('');

  const saveChange = () => {
    apiUrl === '' ? setDevBugCookie(process.env.REACT_APP_API_URL) : setDevBugCookie(apiUrl);
    props.closeModal();
  };

  return (
    <div className="devbug-content">
      <Card title="DEBUG MODE" modal closeModal={props.closeModal}>
        <div className="alert alert-success" role="alert">
          API actual: {apiUrl === '' ? devbugData() : apiUrl}
        </div>
        <div className="form-group">
          <label>Seleccionar api url</label>
          <select className="form-control" onChange={(e) => setApiUrl(e.target.value)}>
            <option value={process.env.REACT_APP_API_URL}>Según variable de entorno</option>
            <option value="https://api.promocionesweb.com">Forzar API</option>
            <option value="https://devapi.promocionesweb.com">Forzar DEVAPI</option>
          </select>
        </div>
        <div className="button-content">
          <Button rounded onClick={(e) => saveChange()}>
            Guardar
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default DevbugModal;

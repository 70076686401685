import { FormattedMessage } from 'react-intl';

const Suscription = ({ item }) => {
  let result = 'global-active';
  let today = new Date();
  let item_date = new Date(item);
  if (item) {
    if (item_date >= today) {
      result = 'global-active';
    } else if (item_date < today) {
      result = 'global-expired';
    } else if (item === '0000-00-00 00:00:00') {
      result = 'global-inactive';
    }
  } else {
    //if date = null
    result = 'global-inactive';
  }

  return <FormattedMessage id={result} />;
};

export default Suscription;

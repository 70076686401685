const initialState = {
  adminCategorias: {},
};

const BannerCategoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ADMIN_CATEGORIAS':
      return Object.assign({}, state, {
        adminCategorias: action.adminCategorias,
      });
    case 'SET_BANNERS':
      return Object.assign({}, state, {
        banners: action.banners,
      });
    default:
      return state;
  }
};

export default BannerCategoriesReducer;

import axios from 'axios';
import { FormattedMessage } from 'react-intl';

import Button from 'components/button';

import DownloadIcon from 'components/icons/download';

const BannerItem = ({ data }) => {
  const downloadBanner = ({ url, name }) => {
    axios({
      url: url,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', name);
      document.body.appendChild(link);
      link.click();
    });
  };
  return (
    <div className="banner-img-content">
      {data.WIDTH > 250 ? (
        <Button download onClick={() => downloadBanner({ url: data.URL, name: data.FILE_NAME })}>
          <DownloadIcon /> <FormattedMessage id="promo-banner-download" />
        </Button>
      ) : (
        <Button downloadsmall onClick={() => downloadBanner({ url: data.URL, name: data.FILE_NAME })}>
          <DownloadIcon />
        </Button>
      )}
      <img src={data.URL} alt="banner" />
      <p>{data.NAME}</p>
    </div>
  );
};

export default BannerItem;

import request from './api-service';

const adminGetMonthBilling = (monthyear) => {
  return request({
    method: 'get',
    url: `/admin/billing/getmonth?monthyear=${monthyear}`,
  });
};

const adminConfirmMonth = (monthyear) => {
  return request({
    method: 'post',
    url: `/admin/billing/confirmmonth`,
    data: { monthyear },
  });
};

const adminSendEmails = (monthyear) => {
  return request({
    method: 'get',
    url: `/admin/billing/emailbillingmmonth?monthyear=${monthyear}`,
  });
};

const adminGet = (id) => {
  return request({
    method: 'get',
    url: `/admin/billing/get?id=${id}`,
  });
};

const adminUpdateStatus = (data) => {
  return request({
    method: 'put',
    url: `/admin/billing/update_status`,
    data: { id: data.id, idwebmaster: data.idwebmaster, estado: data.estado },
  });
};

const BillingService = {
  adminGetMonthBilling,
  adminConfirmMonth,
  adminSendEmails,
  adminGet,
  adminUpdateStatus,
};

export default BillingService;

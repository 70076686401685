const initialState = {
  adminLandings: {},
  landings: {},
};

const LandingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ADMIN_LANDINGS':
      return Object.assign({}, state, {
        adminLandings: action.adminLandings,
      });
    case 'SET_LANDINGS':
      return Object.assign({}, state, {
        landings: action.landings,
      });
    default:
      return state;
  }
};

export default LandingsReducer;

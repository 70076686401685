import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'libs/lodashAlt';
import { toast } from 'react-toastify';

import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import { Container, Col, Row } from 'react-bootstrap';

//translate
import translate from 'i18n/translate';

import UserService from 'shared/user-service';

//Component
import CustomCard from 'components/card';
import Search from 'components/icons/search';
import Button from 'components/button';
import Loading from 'components/loading';
import Pagination from 'components/pagination';
import IconHerramienta from 'components/icons/herramienta';

import GenerateKey from './generateKey';

const Activation = (props) => {
  let loading = useSelector((state) => state.loading.loading);
  const dispatch = useDispatch();
  const intl = useIntl();
  const [data, setData] = useState(null);
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState({
    search: '',
  });

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [lastPage, setLastPage] = useState(1);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    getData();
  }, [page, perPage]);

  const getData = async () => {
    dispatch({ type: 'SET_LOADING', loading: true });
    const { message } = await UserService.adminListNotValidated({
      page,
      perPage,
      filters,
    });

    setData(message.data);
    setLastPage(message.last_page);
    setTotal(message.total);
    dispatch({ type: 'SET_LOADING', loading: false });
  };

  const submit = (e) => {
    e.preventDefault();
    setPage(1);
    setTotal(0);
    getData();
  };

  const onChange = (e) => {
    const { value, name } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const validate = (id) => {
    if (window.confirm(intl.formatMessage({ id: 'herramientas-confirm-alert' }))) {
      dispatch({ type: 'SET_LOADING', loading: true });
      UserService.validate(id)
        .then(({ status }) => {
          if (status !== 'Failed') getData();
          else toast.error(translate('herramientas-affiliate-error-request'));
        })
        .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
    }
  };

  const decline = (id) => {
    if (window.confirm(intl.formatMessage({ id: 'herramientas-confirm-alert' }))) {
      dispatch({ type: 'SET_LOADING', loading: true });
      UserService.decline(id)
        .then(({ status }) => {
          if (status !== 'Failed') getData();
          else toast.error(translate('herramientas-affiliate-error-request'));
        })
        .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
    }
  };

  // #region PAGINATION
  const paginate = (e) => setPage(e.target.value);

  const changeNumberOfColumn = (e) => {
    setPerPage(e.target.value);
    setPage(1);
  };

  const nextPage = (_page) => {
    _page++;
    _page <= lastPage && setPage(_page);
  };

  const prevPage = (_page) => {
    _page--;
    _page > 0 && setPage(_page);
  };
  // #region PAGINATION END
  const _class = 'validate_aff';

  if (loading) return <Loading />;

  return (
    <Container className="mw-100 ha-discounts">
      <Row>
        <Col sm="12">
          <GenerateKey />
        </Col>
      </Row>
      <Row>
        <Col sm="12">
          <div className={`herramientas-administrativas-content ${_class}`}>
            <CustomCard icon={<IconHerramienta color="#4b5056" />} title={translate('herramientas-affiliate-active-name')}>
              <form onSubmit={submit}>
                <div className="form-group input-content">
                  <label>
                    <FormattedMessage id="global-search" />
                    <br />
                    <input name="search" value={filters.search} onChange={onChange} className="p-2" />
                  </label>
                  <br />
                  <Button type="submit" rounded>
                    <FormattedMessage id="global-send" />
                  </Button>
                </div>
              </form>
            </CustomCard>
            {data && data.length > 0 ? (
              <>
                <div className="banner-table-content">
                  <Table data={data} validate={validate} decline={decline} />
                </div>
                <Pagination
                  handleChangeNumberOfColum={changeNumberOfColumn}
                  pager={{ page: page, items: perPage, pages: lastPage }}
                  paginate={paginate}
                  prevPage={prevPage}
                  nextPage={nextPage}
                  total={total}
                />
              </>
            ) : (
              <>No existen resultados</>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Activation;

const Table = ({ data, validate, decline }) => {
  return (
    <table className="table">
      <thead>
        <tr>
          <th scope="col">{translate('global-id')}</th>
          <th scope="col">{translate('global-name')}</th>
          <th scope="col">{translate('herramientas-banner-table-email')}</th>
          <th scope="col">{translate('global-url')}</th>
          <th scope="col">{translate('global-type')}</th>
          <th scope="col">{translate('global-description')}</th>
          <th scope="col">{translate('herramientas-banner-table-country')}</th>
          <th scope="col">{translate('global-date-register')}</th>
          <th scope="col">{translate('herramientas-affiliate-button-active')}</th>
          <th scope="col">{translate('global-decline')}</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => {
          return (
            <tr key={index} className={(index + 1) % 2 ? 'impar' : 'par'}>
              <td>{item.id}</td>
              <td>{item.usuario}</td>
              <td>{item.email}</td>
              <td>
                <Link to={{ pathname: item.url }} target="_blank" rel="noopener noreferrer">
                  {item.url}
                </Link>
              </td>
              <td>{item.traffic_source_type}</td>
              <td>{item.descripcion}</td>
              <td>{item.pais}</td>
              <td>{item.fecha.split('-').reverse().join('-')}</td>
              <td>
                <div className="button-content">
                  <Button onClick={(e) => validate(item.id)}>{translate('herramientas-affiliate-button-active')}</Button>
                </div>
              </td>
              <td>
                <div className="button-content">
                  <Button red onClick={(e) => decline(item.id)}>
                    {translate('global-decline')}
                  </Button>
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

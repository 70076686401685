const Tarjeta = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="22" height="22" viewBox="0 0 22 22">
      <path
        fill="#A2A9B0"
        id="prefix__a"
        d="M18 4H2V2h16v2zm0 10H2V8h16v6zm0-14H2C.89 0 0 .89 0 2v12c0 1.105.895 2 2 2h16c1.105 0 2-.895 2-2V2c0-1.11-.9-2-2-2z"
      />
    </svg>
  );
};

export default Tarjeta;

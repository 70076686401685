const Menu = (props) => {
  return (
    <svg
      className="menu-movil"
      onClick={(e) => props.showSidebar()}
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink">
      <defs>
        <path
          d="M20,-1.02140518e-14 L20,2.33333333 L-1.77635684e-15,2.33333333 L-1.77635684e-15,-1.02140518e-14 L20,-1.02140518e-14 Z M20,5.83333333 L20,8.16666667 L-1.77635684e-15,8.16666667 L-1.77635684e-15,5.83333333 L20,5.83333333 Z M-1.77635684e-15,11.6666667 L20,11.6666667 L20,14 L-1.77635684e-15,14 L-1.77635684e-15,11.6666667 Z"
          id="path-menu"></path>
      </defs>
      <g id="Welcome" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="UI-Style-Guide" transform="translate(-70.000000, -284.000000)">
          <g id="01-Icons" transform="translate(70.000000, 162.000000)">
            <g id="icon/hamburguer" transform="translate(2.000000, 127.000000)">
              <mask id="mask-2" fill="white">
                <use xlinkHref="#path-4"></use>
              </mask>
              <use id="tiu-menu" fill="#fff" fillRule="nonzero" xlinkHref="#path-menu"></use>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Menu;

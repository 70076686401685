import { useDispatch } from 'react-redux';
import translate from 'i18n/translate';
import { useIntl } from 'react-intl';

import { toast } from 'react-toastify';

import BillingService from 'shared/billing-service';

const ChangeStatus = ({ item, getData }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const status_select = [
    { title: 'administration-billing-resum-pending-name', value: 1 },
    { title: 'administration-billing-resum-paid-name', value: 2 },
    { title: 'administration-billing-resum-under-min-name', value: 3 },
    { title: 'administration-billing-resum-receiced-name', value: 4 },
  ];

  const handleOnChange = (status, item) => {
    let msg = 'herramientas-sure-change-billing-status';

    if (window.confirm(intl.formatMessage({ id: msg }))) {
      let data = {
        id: item.id,
        idwebmaster: item.idwebmaster,
        estado: status,
      };

      dispatch({ type: 'SET_LOADING', loading: true });
      BillingService.adminUpdateStatus(data)
        .then((responseData) => {
          if (responseData.status === 'Accepted') {
            toast.success(translate('global-update-success'));
            //history.go(0);
            getData();
          } else {
            msg = 'global-error-try-later';
            if (responseData.message === 'update KO - cannot update status on payed billing') {
              msg = 'global-error-payed-billing-cannot-change';
            }
            toast.error(translate(msg));
          }
        })
        .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
    }
  };
  let disabled = false;
  if (item.estado === 2) disabled = true;
  return (
    <select
      className="form-control"
      disabled={disabled}
      onChange={(e) => handleOnChange(e.target.value, item)}
      name="estado"
      defaultValue={item.estado}>
      {status_select.map((option, index) => {
        return (
          <option key={index} value={option.value}>
            {intl.formatMessage({ id: option.title })}
          </option>
        );
      })}
    </select>
  );
};

export default ChangeStatus;

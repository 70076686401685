const Transfer = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="22" height="22" viewBox="0 0 22 22">
      <path
        fill="#A2A9B0"
        id="prefix__a"
        d="M13 10V7h3V5l4 3.5-4 3.5v-2h-3zm-1-6.3V5H0V3.7L6 0l6 3.7zM5 6h2v5H5V6zM1 6h2v5H1V6zm10 0v2.5l-2 1.8V6h2zm-3.9 6l-.6.5L8.2 14H0v-2h7.1zm7.9-1v3h-3v2l-4-3.5L12 9v2h3z"
      />
    </svg>
  );
};

export default Transfer;

const Personal = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="23" height="22" viewBox="0 0 23 22">
      <path
        fill="#4B5056"
        d="M8 7c1.105 0 2 .895 2 2s-.895 2-2 2-2-.895-2-2 .895-2 2-2zm4 8H4v-1c0-1.33 2.67-2 4-2s4 .67 4 2v1zm8-9h-6v2h6V6zm0 4h-6v2h6v-2zm0 4h-6v2h6v-2zm2-12h-8v2h8v14H2V4h8V2H2C.895 2 0 2.895 0 4v14c0 1.105.895 2 2 2h20c1.105 0 2-.895 2-2V4c0-1.105-.895-2-2-2zm-9 2h-2V0h2v4z"
      />
    </svg>
  );
};

export default Personal;

import translate from 'i18n/translate';

import { formatPrice } from 'libs/methods';

//style
import '../statsOlecams/index.scss';

const Table = (props) => {
  return (
    <>
      <table className="table">
        <thead>
          <tr>
            {props.view === 'month' && (
              <>
                <th scope="col">{translate('statistics-table-day')}</th>
                <th scope="col">{translate('statistics-table-sale')}</th>
                <th scope="col">{translate('statistics-table-import')}</th>
              </>
            )}
            {props.view === 'day' && (
              <>
                <th scope="col">{translate('statistics-table-login')}</th>
                <th scope="col">{translate('statistics-table-product')}</th>
                <th scope="col">{translate('statistics-table-time')}</th>
                <th scope="col">{translate('statistics-table-country')}</th>
                <th scope="col">{translate('statistics-table-t-sale')}</th>
                <th scope="col">{translate('statistics-table-import')}</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {props.view === 'month' &&
            props.stats.data.map((stats, index) => {
              let tipo = (index + 1) % 2 ? 'impar' : 'par';
              return (
                <tr key={index} className={tipo}>
                  <td className="hover" onClick={(e) => props.getStatsDay(stats.dia)}>
                    {stats.dia}
                  </td>
                  <td>{stats.totalVentas}</td>
                  <td>{formatPrice(stats.totalImporte)}</td>
                </tr>
              );
            })}
          {props.view === 'day' &&
            props.stats.data.map((stats, index) => {
              let tipo = (index + 1) % 2 ? 'impar' : 'par';
              let src = stats.pais ? `${process.env.PUBLIC_URL}/flags/${stats.pais.toLowerCase()}.png` : '--';
              return (
                <tr key={index} className={tipo}>
                  <td>{stats.login}</td>
                  <td>{stats.producto}</td>
                  <td>{stats.hora}</td>
                  <td>
                    <span className="flag">
                      {src !== '' && <img src={src} alt="flag" />}
                      {stats.pais}
                    </span>
                  </td>
                  <td>{stats.TVenta}</td>
                  <td>{formatPrice(stats.pvp)}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">{translate('statistics-table-sale')}</th>
            <th scope="col">{translate('statistics-table-import')}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{props.stats.data_summary.totalVentas}</td>
            <td>{formatPrice(props.stats.data_summary.totalImporte)}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default Table;

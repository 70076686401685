import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
// Bootstrap
import { Card, Container, Col, Form, Row, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faUndo, faSearch } from '@fortawesome/free-solid-svg-icons';

import translate from 'i18n/translate';

import { useIntl, FormattedMessage } from 'react-intl';

import VisaUserService from 'shared/visa-user-service';

import Button from 'components/button';
import Pagination from 'components/pagination';
import Loading from 'components/loading';

import Suscription from './suscription';
import Status from './status';
import ModalNewUser from './newUserModal';
import ModalRecoverUser from './recoverUserModal';
import './index.scss';

const VisaEvents = () => {
  const history = useHistory();
  let loading = useSelector((state) => state.loading.loading);
  const dispatch = useDispatch();
  const intl = useIntl();
  const [recoverModalShow, setRecoverModalShow] = useState(false);
  const [newUserModalShow, setNewUserModalShow] = useState(false);

  const [data, setData] = useState([]);
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState({
    search: '',
    order_by: 'id',
    order_type: 'desc',
    pager: {
      page: '1',
      items: '25',
    },
  });

  const order_by_select = [
    { title: 'id', value: 'id' },
    { title: 'email', value: 'default' },
    { title: intl.formatMessage({ id: 'statistics-table-minut' }).toLowerCase(), value: 'tiempo' },
    { title: 'login', value: 'login' },
    { title: 'ip', value: 'ip' },
  ];

  const order_type_select = [
    { title: 'global-asc', value: 'asc' },
    { title: 'global-desc', value: 'desc' },
  ];

  const getList = useCallback(
    (filters) => {
      dispatch({ type: 'SET_LOADING', loading: true });
      VisaUserService.adminList(filters)
        .then((responseData) => {
          if (responseData.status === 'Accepted') setData(responseData.message);
        })
        .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
    },
    [dispatch, setData]
  );

  useEffect(() => {
    getList(filters);
  }, [getList, filters]);

  const paginate = (e) => {
    setFilters({
      ...filters,
      pager: {
        ...filters.pager,
        page: e.target.value,
      },
    });
  };

  const handleChangeNumberOfColum = (e) => {
    setFilters({
      ...filters,
      pager: {
        ...filters.pager,
        items: e.target.value,
        page: 1,
      },
    });
  };

  const nextPage = (page) => {
    page++;
    if (page <= data.pager.pages) {
      setFilters({
        ...filters,
        pager: {
          ...filters.pager,
          page: page,
        },
      });
    }
  };

  const prevPage = (page) => {
    page--;
    if (page > 0) {
      setFilters({
        ...filters,
        pager: {
          ...filters.pager,
          page: page,
        },
      });
    }
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const sendSearch = (e) => {
    e.preventDefault();

    setFilters({
      ...filters,
      search: search,
      pager: {
        ...filters.pager,
        page: 1,
      },
    });
  };

  if (loading) return <Loading />;
  const _class = 'visa_incidents';
  return (
    <Container className="mw-100 ha-visa-incidents">
      <Row>
        <Col sm="12">
          <Card className="mb-2">
            <Card.Header>{translate('sidebar-visa-events')}</Card.Header>
            <Card.Body>
              <Row className="justify-content-between">
                <Col>
                  <form className={`search-wrapper ${_class}`} onSubmit={(e) => sendSearch(e)}>
                    <div className="first-filters d-flex align-center">
                      <Form.Group className="mr-3" controlId="formSearch">
                        <Form.Label>{translate('global-search')}</Form.Label>
                        <Form.Control value={search} name="search" placeholder="" onChange={(e) => setSearch(e.target.value)} />
                      </Form.Group>
                      <Form.Group className="mr-3" controlId="formOrderBy">
                        <Form.Label>{translate('global-order-by')}</Form.Label>

                        <select className="form-control" onChange={handleOnChange} name="order_by" defaultValue={filters.order_by}>
                          {order_by_select.map((option, index) => {
                            return (
                              <option key={index} value={option.value}>
                                {option.title}
                              </option>
                            );
                          })}
                        </select>
                      </Form.Group>

                      <Form.Group className="mr-3" controlId="formOrderType">
                        <Form.Label>{translate('global-order-type')}</Form.Label>

                        <select className="form-control" onChange={handleOnChange} name="order_type" defaultValue={filters.order_type}>
                          {order_type_select.map((option, index) => {
                            return (
                              <option key={index} value={option.value}>
                                {intl.formatMessage({ id: option.title }).toLowerCase()}
                              </option>
                            );
                          })}
                        </select>
                      </Form.Group>
                    </div>
                    <div className="buttons-content">
                      <Button rounded onClick={sendSearch}>
                        <FontAwesomeIcon icon={faSearch} className="mr-2" /> <FormattedMessage id="global-send" />
                      </Button>
                    </div>
                  </form>
                </Col>
                <Col className="text-right">
                  <Button blue rounded className="mb-2" onClick={() => setNewUserModalShow(true)}>
                    <FontAwesomeIcon icon={faPlus} className="mr-2" /> {translate('global-new-user')}
                  </Button>
                  <br />
                  <Button blue rounded onClick={() => setRecoverModalShow(true)}>
                    <FontAwesomeIcon icon={faUndo} className="mr-2" /> {translate('global-user-recover')}
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col sm="12">
          {data.results && data.total > 0 && (
            <>
              <Table responsive="sm" striped bordered hover>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Email / {translate('administration-data-register-acces-user')}</th>
                    <th>{translate('statistics-table-minut')}</th>
                    <th>{translate('global-status')}</th>
                    {/*<th>{translate('global-subscribed-vce')}</th>*/}
                    <th>{translate('global-subscribed-adp')}</th>
                    <th>Login</th>
                    <th>IP</th>
                  </tr>
                </thead>
                <tbody>
                  {data.results.map((item, index) => {
                    let email_username = item.email;
                    if (item.username && item.email === ' ' && item.username !== '') {
                      email_username = item.username;
                    } else if (item.username && (item.email === ' ' || item.email === '') && item.username !== '') {
                      email_username = item.username;
                    } else if (item.username && item.email && item.email !== item.username && item.email !== ' ') {
                      email_username = item.email + ' / ' + item.username;
                    } else if (!item.username && item.email && item.email !== item.username && item.username === '') {
                      email_username = item.email;
                    }
                    return (
                      <tr className="pointer" key={index} onClick={(e) => history.push(`/admintools/incidencias-visa/${item.id}`)}>
                        <td>{item.id}</td>
                        <td>{email_username}</td>
                        <td>{Number(item.tiempo / 60).toFixed(0)}</td>
                        <td>
                          <Status item={item.locked} />
                        </td>
                        {/*<td><Suscription item={item.suscrito_vce} /></td>*/}
                        <td>
                          <Suscription item={item.suscrito_adp} />
                        </td>
                        <td>{item.login}</td>
                        <td>{item.ip}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <Pagination
                handleChangeNumberOfColum={handleChangeNumberOfColum}
                pager={data.pager}
                paginate={paginate}
                prevPage={prevPage}
                nextPage={nextPage}
                total={data.total}
              />
            </>
          )}
          {data.results && data.results.length === 0 && <div className="text-center mt-2 h3">Sin resultados</div>}

          <ModalNewUser show={newUserModalShow} setShow={setNewUserModalShow} />
          <ModalRecoverUser show={recoverModalShow} setShow={setRecoverModalShow} />
        </Col>
      </Row>
    </Container>
  );
};

export default VisaEvents;
